import React from 'react'
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip ,Legend } from "recharts";

const TimeLinePerformance = ({ data }) => {
    return (
        <div style={{ width: '100%', height: 200 }}>
            <ResponsiveContainer>
                <AreaChart width={730} height={250} data={data}
                    margin={{
                            top: 5, right: 30, left: -30, bottom: 5,
                        }}
                        stackOffset={'wiggle'}>
                    <XAxis tickLine={{stroke: '#ededed'}} axisLine={false} dataKey="month" />
                    <YAxis tickLine={{ stroke: '#ededed' }} axisLine={false} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend verticalAlign='top' height={36} />
                    <Area type="monotone" dataKey="current" name="Ano Atual" strokeWidth={2} fill={"#82ca9d"}  stroke="#82ca9d" fillOpacity={0.5} />

                    <Area type="monotone" dataKey="last" name="Último Ano" strokeWidth={2} fill={"#8884d8"} stroke={"#8884d8"} fillOpacity={0.7} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default TimeLinePerformance