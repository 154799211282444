import React, {Component} from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactTableCommon from '../components/balance/reactTableBalances';
import {insertEmitBilletForm} from '../actions/form_actions';
import {getAllInvoices, loadBaseList, clearInvoices} from '../actions/base_actions';
import moment from 'moment';
import MaskedInput from '../components/common/MaskedInput';
import {selectCustomStyle} from '../utils/SelectCustomStyle';

import {
  sendIndividualEmail,
  billetButtonActions,
  sendNewBillet,
  InvoiceConstants,
  closeInvoice,
} from '../components/balance/balanceActions';
import ReactTooltip from 'react-tooltip';
import InvoiceCard from '../components/balance/invoiceCard';
import Select from 'react-select';
import {getCompanies} from '../actions/relatorio_actions';
import NewInvoiceModal from '../components/invoices/NewInvoiceModal';
import {
  doOpenModal,
  doCloseModal,
  CustomModalWithoutTabIndex,
} from '../components/common/CustomModal';
import DateTime from 'react-datetime';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import './AllInvoices.css';
import {
  uploadBilletNf,
  buildInvoiceNF,
  cancelInvoiceNF,
  atualizarInvoiceNF,
} from '../api/invoices_helper';
import SendInvoiceModal from '../components/invoices/SendInvoiceModal';
import {toastSuccess, toastError} from '../actions/toast_actions';
import EmitInvoiceModal from '../components/invoices/EmitInvoiceModal';
import VirtualizedSelect from '../components/virtualizedSelect';
import swal from 'sweetalert2';
import {hasReboqueMeProfile} from '../utils/check_permission';

const EMIT_BILLET = 'EMIT_BILLET_INVOICES';

const typeList = [
  {value: 'INVOICEPARCEIRO', label: 'Fechamento Parceiro', color: '#28a745'},
  {value: 'INVOICEEMPRESA', label: 'Fechamento Empresa', color: '#28a745'},
];

const serviceList = [
  {value: '1', label: 'Intermediação de Negócios', color: '#28a745'},
  {value: '2', label: 'Licenciamento de Software', color: '#28a745'},
  {value: '3', label: 'Serviço de Guincho', color: '#28a745'},
  {value: '4', label: 'Serviço de Call Center', color: '#28a745'},
];

const movesList = [
  {value: 'MOVIMENTOCREDITO', label: 'Compra de Crédito', color: '#28a745'},
  {value: 'MOVIMENTOARECEBER', label: 'Conta a Receber', color: '#28a745'},
  {value: 'MOVIMENTOAPAGAR', label: 'Conta a Pagar', color: '#28a745'},
];

const downloadList = [
  {value: 'nao', label: 'Não'},
  {value: 'sim', label: 'Sim'},
];

class AllInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredBaseBalance: [],
      baseBalance: [],
      pay: 0,
      receive: 0,
      total: 0,
      tipoVeiculo: [],
      baixado: [],
      company: [],
      endDate: moment(),
      startDate: moment(),
      movimento: [],
      bases: [],
      closeDate: new Date(),
      desconto: 9.7,
      acrescimo: 0,
      copyText: '',
    };
    this.getTable = this.getTable.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.typeIcon = this.typeIcon.bind(this);
    this.valueFiled = this.valueFiled.bind(this);
    this.cumulativeFiled = this.cumulativeFiled.bind(this);
    this.services = this.services.bind(this);
    this.doneButton = this.doneButton.bind(this);
    this.invoiceButton = this.invoiceButton.bind(this);
    this.billetButton = this.billetButton.bind(this);
    this.pdfButton = this.pdfButton.bind(this);
    this.seenField = this.seenField.bind(this);
    this.getHeader = this.getHeader.bind(this);
    this.codigoFechamentoField = this.codigoFechamentoField.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeBaixado = this.onChangeBaixado.bind(this);
    this.handleBegin = this.handleBegin.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.onChangeMovimento = this.onChangeMovimento.bind(this);
    this.onChangeBases = this.onChangeBases.bind(this);
    this.hasPermission = this.hasPermission.bind(this);
    this.handleCloseDate = this.handleCloseDate.bind(this);
    this.handleDesapprove = this.handleDesapprove.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.setCopyText = this.setCopyText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {getBaseBalance, baseCode, baseBalance} = nextProps;

    if (baseCode !== this.props.baseCode) getBaseBalance(baseCode);

    if (baseBalance && baseBalance.length > 0) {
      this.getHeader(this.getData(baseBalance));
      this.setState({
        baseBalance: this.getData(baseBalance),
        filteredBaseBalance: this.getData(baseBalance),
      });
    } else {
      this.setState({filteredBaseBalance: []});
    }
  }

  componentWillUnmount() {
    this.props.clearInvoices();
  }

  componentDidMount() {
    if (hasReboqueMeProfile()) {
      this.props.getCompanies();
      this.props.loadBaseList(10);
    } else {
      this.props.getAllInvoices();
    }
  }

  getHeader(baseBalance) {
    let pay = 0,
      receive = 0,
      total = 0,
      services = 0;
    baseBalance.forEach((b, i) => {
      if (Number.isInteger(b.atendimentos)) services += b.atendimentos;
      if (!b.concluido) {
        if (b.valor > 0) pay += b.valor;
        else if (b.valor < 0) receive += b.valor;
      }
    });
    receive = receive * -1;
    total = receive + pay;
    total = receive - pay;
    this.setState({pay, receive, total, services});
  }

  getData(baseBalance) {
    if (baseBalance) {
      return baseBalance.map((b) => ({
        type: b.base ? 'guincho' : 'empresa',
        code: b.codigo ? b.codigo : '',
        email: b.base ? b.base.email : b.empresa.email,
        nomeFantasia: b.base ? b.base.nomeFantasia : b.empresa.nomeFantasia,
        dataMovimento: b.lancamentoFinanceiro
          ? moment(b.lancamentoFinanceiro.dataMovimento, 'DD/MM/YYYY HH:mm').format(
              'DD/MM/YYYY',
            )
          : '',
        dataCadastro: b.lancamentoFinanceiro
          ? moment(b.lancamentoFinanceiro.dataCadastro, 'DD/MM/YYYY HH:mm').format(
              'DD/MM/YYYY',
            )
          : '',
        valor: b.valor ? parseFloat(b.valor) : '',
        valorNotaFiscal: b.valorNotaFiscal ? parseFloat(b.valorNotaFiscal) : null,
        acumulado: b.acumulado,
        valorAcumulado: b.valorAcumulado,
        atendimentos: b.atendimentos ? b.atendimentos : '',
        concluido: b.lancamentoFinanceiro.processada ? b.lancamentoFinanceiro.processada : '',
        billetLink: b.lancamentoFinanceiro.linkBoleto
          ? b.lancamentoFinanceiro.linkBoleto
          : null,
        nfLink: b.linkNotaFiscal ? b.linkNotaFiscal : null,
        seen: b.visualizada ? b.visualizada : null,
        emailSent: b.emailEnviado ? b.emailEnviado : null,
        emailSentDate: b.dataEmailEnviado ? b.dataEmailEnviado : null,
        seenDate: b.dataVisualizada ? b.dataVisualizada : null,
        lancamentoId: b.lancamentoFinanceiro.codigo,
        balanceId: b.codigoFechamento,
        descricao: b.lancamentoFinanceiro.descricao,
        dataVencimento: b.lancamentoFinanceiro.dataVencimento,
        notaStatus: b.notaFisca ? b.notaFisca.status : null,
        categoriaInvoice: b.categoriaInvoice,
        notaFiscal: b.notaFisca ? b.notaFisca : null,
      }));
    } else return [];
  }

  clearSearch() {
    const {invoices} = this.state;
    this.setState({
      filteredInvoices: invoices,
    });
  }
  typeIcon(cell, row) {
    return (
      <span>
        <i className={row.type === 'empresa' ? 'fas fa-building' : 'fas fa-truck'} />
      </span>
    );
  }
  valueFiled(cell, row) {
    return (
      <div
        className="invoice-values"
        style={row.valor > 0 ? {color: '#4ab858'} : {color: 'red'}}>
        {parseFloat(row.valor).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    );
  }
  cumulativeFiled(cell, row) {
    return (
      <div
        className="invoice-values"
        style={row.valorAcumulado > 0 ? {color: '#4ab858'} : {color: 'red'}}>
        {parseFloat(row.valorAcumulado).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    );
  }
  services(cell, row) {
    return <div className="align-right">{row.atendimentos}</div>;
  }
  billetButton(cell, row) {
    /* const { billetButtonActions, sendNewBillet } = this.props;
    const { balanceId } = row;
    const { baseBalance } = this.state;
    const billetButtonList = [
      {
        label: "Visualisar",
        func: () => window.open(row.billetLink, "blank"),
        icon: "fas fa-eye"
      },
      // { label: 'Segunda Via', func: () => billetButtonActions(balanceId, row.code, row.billetLink), icon: 'fas fa-retweet' },
      {
        label: "Reenviar",
        func: () => sendNewBillet(row.lancamentoId),
        icon: "far fa-share-square"
      }
    ];
    const last = baseBalance[baseBalance.length - 1].code === row.code;
    return row.billetLink ? (
      <SideDropDown buttonList={billetButtonList} last={last} />
    ) : null; */

    return (
      <button
        className="btn btn-default"
        onClick={() => {
          window.open(row.billetLink, 'blank');
        }}>
        <i className="fas fa-barcode" />
      </button>
    );
  }

  confirmCancelNf = (row) => {
    swal
      .fire({
        title: 'Deseja cancelar a nota fiscal?',
        showCancelButton: true,
        input: 'textarea',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        inputValidator: (value) => {
          const maxCharacteres = 255;
          const maxCharacteresText = `O motivo do cancelamento deve ter no máximo ${maxCharacteres} caracteres`;
          if (!value) return 'Você precisa informar o motivo do cancelamento';
          if (value.lenght >= maxCharacteres) return maxCharacteresText;
        },
        inputPlaceholder: 'Informe o motivo do cancelamento',
      })
      .then(({value}) => {
        if (value) this.handleCancelNF(row.code, value);
      });
  };

  confirmBuildNF = (code) => {
    swal
      .fire({
        title: 'Deseja gerar uma Nota Fiscal?',
        showCancelButton: true,
        icon: 'warning',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Gerar',
      })
      .then(
        (value) => {
          if (value.dismiss === 'cancel') return;
          this.handleBuildNF(code);
        },
        () => {},
      );
  };

  buildBilletButton = (cell, row) => {
    const showGerarNF =
      row.categoriaInvoice.codigo !== 'INVOICECATEGORIACOMPRACREDITO' &&
      row.valorNotaFiscal > 0;

    if (row.notaStatus === InvoiceConstants.INVOICE_STATUS.ISSUED) {
      return (
        <div>
          <button
            data-tip="Cancelar"
            onClick={() => this.confirmCancelNf(row)}
            data-for="nota-tooltip-delete"
            className="btn btn-default">
            <i className="fas fa-times" />
          </button>
          <ReactTooltip id="nota-tooltip-delete" />
        </div>
      );
    } else {
      return (
        <div>
          <button
            data-tip="Gerar"
            onClick={() => {
              const formValues = {
                codigo: row.code,
                iss: row.notaFiscal.valorIss,
                pis: row.notaFiscal.valorPis,
                baseCalculo: row.notaFiscal.valorBaseCalculo,
                issRetido: row.notaFiscal.issRetido,
                ir: row.notaFiscal.valorIr,
                csll: row.notaFiscal.valorCsll,
                deducoes: row.notaFiscal.valorDeducoes,
                valor: row.valor,
                cofins: row.notaFiscal.valorCofins,
                codigoNotaFiscal: row.notaFiscal.codigo,
              };

              this.props.insertEmitBilletForm(formValues);

              doOpenModal(EMIT_BILLET);
            }}
            data-for="nota-tooltip-build"
            className={classnames({
              'btn btn-default': true,
              hide: !showGerarNF || row.valorNotaFiscal <= 0,
            })}>
            <i className="fas fa-plus-square" />
          </button>
          <ReactTooltip id="nota-tooltip-build" />
        </div>
      );
    }
  };

  doneButton(cell, row) {
    return (
      <button
        className={`btn btn-sm btn-default`}
        style={
          row.concluido
            ? {color: '#4ab858', textAlign: 'center'}
            : {color: '#c1c1c1', textAlign: 'center'}
        }
        onClick={() => {
          row.categoriaInvoice.codigo === 'INVOICECATEGORIAAVULSA'
            ? this.setState(
                {
                  modal: 'close',
                  selectedRow: row,
                },
                () => doOpenModal('CONTEXT_BASE'),
              )
            : null;
        }}>
        {<i className="fas fa-check" />}
      </button>
    );
  }
  invoiceButton(cell, row) {
    return row.nfLink ? (
      <button
        data-tip="Ver nota fiscal"
        className={`btn btn-sm btn-default`}
        onClick={() => window.open(row.nfLink)}>
        <i className="fas fa-file-invoice-dollar" />
      </button>
    ) : null;
  }

  vencimentos = (cell, row) => {
    const date = row.dataVencimento
      ? moment(row.dataVencimento, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
      : '-';

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <span>{date}</span>
      </div>
    );
  };

  hasAccessForReport = ({categoriaInvoice}) => {
    if (!categoriaInvoice) return false;
    return categoriaInvoice.codigo === 'INVOICECATEGORIAFECHAMENTO';
  };

  pdfButton(cell, row) {
    const {balanceId} = row;

    return (
      <button
        data-tip="Ver relatórioo"
        className={classnames({
          'btn btn-sm btn-default': true,
          hide: !this.hasAccessForReport(row),
        })}
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_V2_URL}resume?resume=${balanceId}&invoice=${row.code}`,
            '_blank',
          )
        }>
        <i className="far fa-file-pdf" />
      </button>
    );
  }

  codigoFechamentoField(cell, row) {
    const {descricao} = row;
    return <p>{descricao}</p>;
  }

  onChangeBaixado(event) {
    const {value} = event.target;
    this.setState({...this.state, baixado: value});
  }

  onChangeBases(text) {
    this.setState({...this.state, bases: text});
  }

  seenField(cell, row) {
    const {sendIndividualEmail, emailSentId} = this.props;
    const {balanceId} = row;
    let comp = '';
    let title = '';
    if (row.seen) {
      comp = <i className="fas fa-eye" />;
      title = row.seenDate;
    } else if (row.emailSent || emailSentId === row.code) {
      comp = <i className="fas fa-share-square" />;
      title = row.emailSentDate;
    } else {
      comp = <i className="fas fa-envelope" />;
      title = 'Enviar email';
    }

    return (
      <button
        className={`btn btn-sm btn-default`}
        data-tip={title}
        onClick={() => {
          // sendIndividualEmail(balanceId, row.code, row.nomeFantasia, row.email);
        }}>
        {comp}
      </button>
    );
  }

  notaStatus = (cell, row) => {
    if (!row.notaStatus) return null;
    if (
      !row.categoriaInvoice ||
      row.categoriaInvoice.codigo === 'INVOICECATEGORIACOMPRACREDITO'
    )
      return null;
    if (!InvoiceConstants.INVOICE_STATUS_CHIPS[row.notaStatus]) return null;

    const {title = 'Não definido', color} = InvoiceConstants.INVOICE_STATUS_CHIPS[
      row.notaStatus
    ];

    if (row.notaFiscal && row.notaFiscal.status === 'INVOICE.NOT_AUTHORIZED') {
      return (
        <h2>
          <span
            data-tip={row.notaFiscal.mensagemRetorno}
            data-for="retorno"
            className={`badge ${color}`}>
            {title}
          </span>
          <ReactTooltip id="retorno" />
        </h2>
      );
    }

    return (
      <h2>
        <span className={`badge ${color}`}>{title}</span>
      </h2>
    );
  };

  hasAccessToBuildNF = () => {
    const {profiles} = this.props;
    return hasReboqueMeProfile() && profiles.some((p) => p.id === 6);
  };

  onSearchChange = (input) => {
    let {baseBalance, filteredBaseBalance} = this.state;
    if (input) {
      filteredBaseBalance = baseBalance.filter((row) => {
        return row.code.toLowerCase().includes(input.toLowerCase());
      });
      this.setState({
        filteredBaseBalance,
      });
    } else {
      this.setState({
        filteredBaseBalance: baseBalance,
      });
    }
  };
  getTable() {
    const {baseBalanceFetching} = this.props;
    const {filteredBaseBalance} = this.state;
    return (
      <ReactTableCommon
        data={filteredBaseBalance}
        onSearchChange={this.onSearchChange}
        isFetching={baseBalanceFetching}
        clearSearch={this.clearSearch}
        hideSearch={hasReboqueMeProfile()}>
        <TableHeaderColumn
          dataField={'type'}
          dataSort={true}
          dataFormat={this.typeIcon}
          width="30px"
        />

        <TableHeaderColumn width="110px" dataField={'dataCadastro'} dataSort={true} isKey>
          Lançamento
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataFormat={this.codigoFechamentoField}>
          Descrição
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField={'valor'}
          dataSort={true}
          width="100px"
          dataFormat={this.valueFiled}>
          Valor
        </TableHeaderColumn>
        <TableHeaderColumn
          width="110px"
          dataField="dataVencimento"
          dataFormat={this.vencimentos}>
          Vencimento
        </TableHeaderColumn>
        <TableHeaderColumn width="160px" dataField={'code'} dataSort={true}>
          Código
        </TableHeaderColumn>
        {this.hasAccessToBuildNF() && (
          <TableHeaderColumn width="100px" dataFormat={this.notaStatus} />
        )}
        <TableHeaderColumn
          width="50px"
          dataFormat={this.doneButton}
          dataSort={true}
          dataField={'concluido'}
        />
        {this.hasAccessToBuildNF() && (
          <TableHeaderColumn
            dataField="button"
            columnClassName="billet-class"
            dataFormat={this.buildBilletButton}
            width="50px"
          />
        )}
        <TableHeaderColumn dataField="button" dataFormat={this.invoiceButton} width="50px" />
        <TableHeaderColumn
          dataField="button"
          columnClassName="billet-class"
          dataFormat={this.billetButton}
          width="50px"
        />
        <TableHeaderColumn dataField="button" dataFormat={this.pdfButton} width="50px" />
        {/* <TableHeaderColumn
          width="50px"
          dataField={"seen"}
          dataSort={true}
          dataFormat={this.seenField}
        ></TableHeaderColumn> */}
      </ReactTableCommon>
    );
  }

  toReceive(baseBalance) {
    return baseBalance.reduce((total, current) =>
      total + current.concluido ? 0 : current.valor,
    );
  }

  searchInvoice = () => {
    const data = {};

    if (this.state.company.length > 0) {
      data._empresa = this.state.company.map((company) => company.value).join(',');
    }

    if (this.state.tipoVeiculo.length > 0) {
      data._tipo = this.state.tipoVeiculo.map((vehicle) => vehicle.value).join(',');
    }

    if (this.state.bases.length > 0) {
      data._base = this.state.bases.map((vehicle) => vehicle.value).join(',');
    }

    if (this.state.movimento.length > 0) {
      data._movimento = this.state.movimento.map((move) => move.value).join(',');
    }

    if (this.state.baixado.length > 0) {
      data._processada = this.state.baixado.map((baixado) => baixado.value).join(',');
    }

    data._createdAtFrom = this.state.startDate.format('YYYY-MM-DD 00:00');
    data._createdAtTo = this.state.endDate.format('YYYY-MM-DD 23:59');

    this.props.getAllInvoices(data);
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.searchInvoice();
  };

  buildCompaniesOptions = () => {
    return this.props.companies.map((c) => ({
      value: c.codigo,
      label: c.nomeFantasia,
      color: c.statusEmpresa.descricao === 'Ativo' ? '#5a5a5a' : '#cecece',
    }));
  };

  buildBaseOptions = () => {
    return this.props.baseList.map((c) => ({
      value: c.codigo,
      label: c.nomeFantasia,
    }));
  };

  onChangeType(event) {
    this.setState({...this.state, tipoVeiculo: event});
  }

  onChangeMovimento(event) {
    this.setState({...this.state, movimento: event});
  }

  handleBegin(date) {
    this.setState({
      startDate: date,
    });
  }

  handleEnd(date) {
    this.setState({
      endDate: date,
    });
  }

  hasPermission() {
    const {profiles} = this.props;
    return hasReboqueMeProfile() && profiles.some((p) => p.id === 6);
  }

  isTableEmpty = () => {
    const {filteredBaseBalance} = this.state;
    return filteredBaseBalance.length === 0;
  };

  handleBuildNF = (codeInvoice) => {
    buildInvoiceNF(codeInvoice)
      .then((res) => {
        if (res.data.sucesso) {
          toastSuccess(res.data.mensagemRetorno);
        } else {
          toastError(res.data.mensagemRetorno);
        }
      })
      .catch((err) => toastError(err.mensagemRetorno))
      .finally(() => {
        doCloseModal(EMIT_BILLET);
        this.searchInvoice();
      });
  };

  handleCancelNF = (codeInvoice, cause) => {
    cancelInvoiceNF(codeInvoice, cause)
      .then((res) => {
        if (res.data.sucesso) {
          toastSuccess(res.data.mensagemRetorno);
        } else {
          toastError(res.data.mensagemRetorno);
        }
      })
      .catch((err) => toastError(err.mensagemRetorno))
      .finally(() => this.searchInvoice());
  };

  handleUpdatelNF = (codeInvoice, data) => {
    atualizarInvoiceNF(codeInvoice, data)
      .then((res) => {
        if (res.data.sucesso) {
          toastSuccess(res.data.mensagemRetorno);
        } else {
          toastError(res.data.mensagemRetorno);
        }
      })
      .catch((err) => toastError(err.mensagemRetorno))
      .finally(() => this.searchInvoice());
  };

  uploadBillet = (event, code) => {
    uploadBilletNf(code, event.target.files[0])
      .then((res) => {
        toastSuccess(res.data.mensagemRetorno);
        this.handleSearch(event);
      })
      .catch((err) => toastError(err.message));
  };

  handleCloseDate(closeDate) {
    this.setState({closeDate});
  }

  setCopyText(copyText) {
    this.setState({copyText}, () => this.doCopyText());
  }

  async handleApprove(codigo) {
    if (codigo) {
      await doPut(`invoices/${codigo}/aprovarNf`, {
        approved: true,
      })
        .then(() => {
          this.setState({filteredInvoices: []});
          toastSuccess('Aprovado com sucesso');
          this.setState({afterAction: true, afterActionApproved: true});
          this.props.getInvoices(this.id);
        })
        .catch((error) => {
          toastError(error.mensagemCliente || error.status);
        });
    }
  }

  handleDesapprove(codigo) {
    MySwal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja reprovar a nota fiscal ?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo da rejeição',
      icon: 'warning',
      width: '80',
      showCancelButton: true,
      confirmButtonText: 'Reprovar',
      confirmButtonColor: '#25f4af',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      preConfirm: (item) => {
        if (!item) {
          MySwal.showValidationMessage(`Por favor preencha o motivo da rejeição.`);
        }
      },
      inputAttributes: {
        style: 'font-size: 14px;',
      },
    }).then((result) => {
      if (result.value) {
        doPut(`invoices/${codigo}/aprovarNf`, {
          approved: false,
          motivoRecusa: result.value,
        })
          .then(() => {
            toastSuccess('Reprovado com sucesso');
            this.setState({afterAction: true});
            this.props.getInvoices(this.id);
          })
          .catch((error) => {
            toastError(error.mensagemCliente || error.status);
          });
      }
    });
  }

  getTotal() {
    const {selectedRow, desconto, acrescimo} = this.state;
    const {baseBalance} = this.props;
    const closeInfo = baseBalance.find((base) => selectedRow.code === base.codigo);
    const {valor} = closeInfo;
    const {lancamentoFinanceiro} = closeInfo;
    const {processada} = lancamentoFinanceiro;

    if (processada) {
      return lancamentoFinanceiro.total.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      if (valor > 0) {
        return parseFloat(valor - desconto + acrescimo).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return parseFloat(valor + (desconto - acrescimo)).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
  }

  getClosesModal() {
    const {selectedRow, closeDate, desconto, acrescimo} = this.state;
    const {baseBalance, closeInvoice} = this.props;
    const closeInfo = baseBalance.find((base) => selectedRow.code === base.codigo);
    const {
      valor,
      valorNotaFiscalTomada,
      linkNotaFiscalTomada,
      notaFiscalAprovada,
      codigo,
    } = selectedRow;
    const {
      dataMovimento,
      dataVencimento,
      tipoMovimento,
      descricao,
      processada,
      descontos,
      acrescimos,
      dataProcessada,
    } = closeInfo.lancamentoFinanceiro;
    let bankName = '',
      bankNumber = '',
      agencyNumber = '',
      agencyCheckNumber = '',
      accountNumber = '',
      accountCheckNumber = '',
      holderFullname = '',
      holderTypeDocument = '',
      holderNumberDocument = '',
      chavePix = '';

    let baseOrCompanyDocument = '';
    let baseOrCompanyType = '';
    let pendenciaFiscal = '';

    if (closeInfo.empresa) {
      baseOrCompanyDocument = closeInfo.empresa.cnpj;
      baseOrCompanyType = 'CNPJ';
    } else if (closeInfo.base) {
      baseOrCompanyType = closeInfo.base.tipoDocumento;
      baseOrCompanyDocument = closeInfo.base.documento;
      pendenciaFiscal = closeInfo.base.pendenciaFiscal;
    }

    if (closeInfo.base && closeInfo.base.contaBancaria) {
      bankName = closeInfo.base.contaBancaria.bankName;
      bankNumber = closeInfo.base.contaBancaria.bankNumber;
      (agencyNumber = closeInfo.base.contaBancaria.agencyNumber),
        (agencyCheckNumber = closeInfo.base.contaBancaria.agencyCheckNumber);
      accountNumber = closeInfo.base.contaBancaria.accountNumber;
      accountCheckNumber = closeInfo.base.contaBancaria.accountCheckNumber;
      holderFullname = closeInfo.base.contaBancaria.holderFullname;
      holderTypeDocument = closeInfo.base.contaBancaria.holderTypeDocument;
      holderNumberDocument = closeInfo.base.contaBancaria.holderNumberDocument;
      chavePix = closeInfo.base.contaBancaria.chavePix;
    }

    const tipoBase = closeInfo.base ? 'base' : 'empresa';
    const baseName = closeInfo.base
      ? closeInfo.base.nomeFantasia
      : closeInfo.empresa.nomeFantasia;
    const notaFiscalPrestador = valorNotaFiscalTomada;
    const valorAcumulado = closeInfo.valorAcumulado;

    this.footer = (
      <div className="form-group">
        <button
          className="btn btn-primary"
          onClick={() =>
            closeInvoice(
              closeInfo.lancamentoFinanceiro.codigo,
              moment(closeDate).format('YYYY-MM-DD HH:mm:ss'),
              this.state.balanceId,
              this.state.desconto,
              this.state.acrescimo,
            )
          }
          type="submit">
          Dar Baixa
        </button>
      </div>
    );
    return (
      <div>
        <div className="col-md-8" style={{borderRight: '1px solid #e5e5e5'}}>
          <div className="form-group custom-tab">
            <label>Descricao</label>
            <br />
            <span>{descricao ? descricao : 'Sem descricao'}</span>
          </div>
          <div className="row">
            <div className="col-md-6 form-group custom-tab">
              <label>Base</label>
              <br />
              <span>{baseName ? baseName : 'Sem descricao'}</span>
            </div>
            <div className="col-md-6 form-group custom-tab">
              <label>CNPJ/CPF</label>
              <br />
              <span>
                {baseOrCompanyDocument && baseOrCompanyType === 'CNPJ' ? (
                  <MaskedInput
                    className="no-border copy-btn"
                    value={baseOrCompanyDocument}
                    mask="99.999.999/9999-99"
                  />
                ) : (
                  <MaskedInput
                    className="no-border copy-btn"
                    value={baseOrCompanyDocument}
                    mask="999.999.999-99"
                  />
                )}
              </span>
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-6">
              <label>Tipo</label>
              <span className="form-control">
                {tipoMovimento ? tipoMovimento.descricao : 'Sem tipo'}
              </span>
            </div>
            <div className="form-group custom-tab col-md-6">
              <label>Data Movimento</label>
              <span className="form-control">
                {dataMovimento
                  ? moment(dataMovimento, 'DD/MM/YYYY HH:mm')
                      .local()
                      .format('DD/MM/YYYY HH:mm')
                  : 'Sem data movimento'}
              </span>
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-6">
              <label>Data Vencimento</label>
              <span className="form-control">
                {dataVencimento
                  ? moment(dataVencimento, 'DD/MM/YYYY HH:mm')
                      .local()
                      .format('DD/MM/YYYY HH:mm')
                  : 'Sem data vencimento'}
              </span>
            </div>
            <div className="form-group custom-tab col-md-6">
              <label>Data da Baixa</label>
              <DateTime
                onChange={this.handleCloseDate}
                value={
                  processada
                    ? moment(dataProcessada, 'DD/MM/YY HH:mm')
                        .local()
                        .format('DD/MM/YYYY HH:mm')
                    : moment(closeDate, 'DD/MM/YY HH:mm').local().format('DD/MM/YYYY HH:mm')
                }
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-4">
              <label>Descontos / Taxas</label>
              <input
                type="number"
                disabled={processada}
                value={processada ? descontos : this.state.desconto}
                onChange={(e) => this.setState({desconto: parseFloat(e.target.value) || 0})}
                className="form-control"
              />
            </div>
            <div className="form-group custom-tab col-md-4">
              <label>Juros / Multa</label>
              <input
                type="number"
                disabled={processada}
                value={processada ? acrescimos : this.state.acrescimo}
                onChange={(e) => this.setState({acrescimo: parseFloat(e.target.value) || 0})}
                className="form-control"
              />
            </div>
            <div className="form-group custom-tab col-md-4">
              <div>
                <label>Nota Fiscal Prestador</label>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                  <input
                    type="number"
                    disabled
                    value={notaFiscalPrestador || 0}
                    onChange={(e) =>
                      this.setState({
                        desconto: parseFloat(e.target.value) || 0,
                      })
                    }
                    className="form-control"
                  />
                  <button
                    disabled={!linkNotaFiscalTomada}
                    onClick={() => window.open(linkNotaFiscalTomada, '_blank')}
                    style={
                      this.state.afterActionApproved || notaFiscalAprovada
                        ? {color: '#4ab858'}
                        : {marginLeft: '10px'}
                    }
                    className="btn btn-default">
                    <i className="fas fa-file-invoice" />
                  </button>
                </div>
              </div>
            </div>
            {tipoBase === 'base' &&
              !this.state.afterAction &&
              !notaFiscalAprovada &&
              linkNotaFiscalTomada && (
                <div
                  className="form-group custom-tab col-md-4"
                  style={{
                    float: 'right',
                    width: '100%',
                    maxWidth: '155px',
                    marginRight: '20px',
                    padding: 0,
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <button
                      style={{margin: '10px'}}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleApprove(codigo);
                      }}
                      className="btn btn-primary">
                      <i className="fas fa-check" />
                    </button>
                    <button
                      style={{margin: '10px'}}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleDesapprove(codigo);
                      }}
                      className="btn btn-danger">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group custom-tab col-md-6">
            <label>Valor</label>
            <br />
            <h2 style={{margin: '5px 0px -10px'}}>{`${valor.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</h2>
          </div>
          <div className="form-group custom-tab col-md-6">
            <label>Total</label>
            <br />
            <h2
              className="copy-btn"
              onClick={() => this.copyTotal()}
              ref={(r) => (this.totalContainer = r)}
              style={{margin: '5px 0px -10px'}}>{`${this.getTotal()}`}</h2>
          </div>
          {valorAcumulado !== 0 && (
            <div className="form-group custom-tab col-md-12">
              <p className="text-danger">* Possui um valor acumulado.</p>
            </div>
          )}
          {pendenciaFiscal && (
            <div className="form-group custom-tab col-md-12">
              <p className="text-danger">* Possui Pendência Envio de NF</p>
            </div>
          )}

          {chavePix && (
            <div className="form-group custom-tab col-md-12">
              <label>Chave Pix</label>
              <br />
              <span>{chavePix}</span>
            </div>
          )}

          <div className="form-group custom-tab col-md-12">
            <label>Banco</label>
            <br />
            <span>{`${bankNumber} - ${bankName}`}</span>
          </div>

          <div className="form-group custom-tab col-md-6">
            <label>Agência</label>
            <br />
            <span className="copy-btn" onClick={() => this.setCopyText(agencyNumber)}>
              {`${agencyNumber} - ${agencyCheckNumber}`}
            </span>
          </div>
          <div className="form-group custom-tab col-md-6">
            <label>Nº Conta</label>
            <br />
            <span
              className="copy-btn"
              onClick={() => this.setCopyText(`${accountNumber}${accountCheckNumber}`)}>
              {`${accountNumber} - ${accountCheckNumber}`}
            </span>
          </div>
          <div className="form-group custom-tab col-md-12">
            <label>Nome Favorecido</label>
            <br />
            <span className="copy-btn" onClick={() => this.setCopyText(holderFullname)}>
              {holderFullname}
            </span>
          </div>

          <div
            className="form-group custom-tab col-md-12 copy-btn"
            onClick={() => this.setCopyText(holderNumberDocument)}>
            <label>CPF/ CNPJ Favorecido</label>
            <br />
            <div className="copy-btn" onClick={() => this.setCopyText(holderNumberDocument)}>
              {holderTypeDocument === 'CNPJ' ? (
                <MaskedInput
                  className="no-border copy-btn"
                  value={holderNumberDocument}
                  mask="99.999.999/9999-99"
                />
              ) : (
                <MaskedInput
                  className="no-border copy-btn"
                  value={holderNumberDocument}
                  mask="999.999.999-99"
                />
              )}
            </div>
          </div>
        </div>
        <input
          value={this.state.copyText}
          type="text"
          hidden
          ref={(copyArea) => (this.copyArea = copyArea)}
        />
      </div>
    );
  }

  getModalBody() {
    const {modal} = this.state;
    if (modal === 'close') return this.getClosesModal();
  }

  render() {
    const {pay, receive, total, services, modal} = this.state;
    const companiesOptions = this.buildCompaniesOptions();
    const basesOptions = this.buildBaseOptions();
    const {profileId} = this.props;

    const classesForSendInvoice = {
      'btn btn-primary': true,
      hide: !this.hasPermission() || this.isTableEmpty(),
      'mr-15': true,
    };

    const modalBody = this.getModalBody();

    const titleMap = {
      close: 'Baixa Lançamento',
    };

    return (
      <div>
        <CustomModalWithoutTabIndex
          title={titleMap[modal]}
          context={'CONTEXT_BASE'}
          customClass="big-modal"
          footer={this.footer}>
          {modalBody}
        </CustomModalWithoutTabIndex>
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <ReactTooltip id="base-balance-tip" multiline={true} html={true} />
            <div className="ibox">
              <div className="ibox-content" style={{padding: '20px 5px 20px 5px'}}>
                <div className="cards-container">
                  <InvoiceCard
                    icon={'fas fa-truck-moving'}
                    value={services}
                    title={'Atendimentos'}
                    cClass={'navy-bg'}
                  />
                  <InvoiceCard
                    icon={'fas fa-plus'}
                    value={receive.toFixed(2).replace('.', ',')}
                    title={'A Receber (R$)'}
                    cClass={'green-bg'}
                  />
                  <InvoiceCard
                    icon={'fas fa-minus'}
                    value={pay.toFixed(2).replace('.', ',')}
                    title={'A Pagar (R$)'}
                    cClass={'red-bg'}
                  />
                  <InvoiceCard
                    icon={'fas fa-equals'}
                    value={total.toFixed(2).replace('.', ',')}
                    title={'Total (R$)'}
                    cClass={total >= 0 ? 'green-bg' : 'red-bg'}
                  />
                </div>
                <div className={hasReboqueMeProfile() ? 'ibox-content' : 'hidden--element'}>
                  <EmitInvoiceModal
                    title="Impostos"
                    confirmBuildNF={this.handleBuildNF}
                    handleUpdateNF={this.handleUpdatelNF}
                    context={EMIT_BILLET}
                  />
                  <NewInvoiceModal
                    companiesOptions={companiesOptions}
                    title="Criar invoice"
                    context="CREATE_INVOICE"
                    typeList={typeList}
                    refreshInvoice={this.searchInvoice}
                    serviceList={serviceList}
                    invoices={this.state.filteredBaseBalance}
                    movesList={movesList}
                  />
                  <SendInvoiceModal
                    title="Enviar nota fiscal"
                    context="SEND_INVOICE"
                    handleInvoiceInput={this.uploadBillet}
                    invoices={this.state.filteredBaseBalance}
                  />
                  <div className="row">
                    <div className="float-right">
                      <button
                        onClick={() => doOpenModal('SEND_INVOICE')}
                        className={classnames(classesForSendInvoice)}>
                        Enviar Nota Fiscal
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          doOpenModal('CREATE_INVOICE');
                        }}>
                        <i
                          style={{marginRight: '5px'}}
                          className="fas fa-file-invoice-dollar"
                        />
                        Criar invoice
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="font-normal p-w-xs">Tipo</label>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={this.onChangeType}
                        value={this.state.tipoVeiculo}
                        options={typeList}
                        styles={selectCustomStyle}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="font-normal p-w-xs">Movimento</label>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        value={this.state.movimento}
                        onChange={this.onChangeMovimento}
                        options={movesList}
                        styles={selectCustomStyle}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Baixado</label>
                      <Select
                        options={downloadList}
                        value={this.state.baixado}
                        onChange={(text) => {
                          this.setState({...this.state, baixado: text});
                        }}
                        isMulti
                        styles={selectCustomStyle}
                      />
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '18px'}}>
                    <div className="col-md-8">
                      <label>Empresas</label>
                      <Select
                        placeholder={'Todas as empresas'}
                        closeMenuOnSelect={false}
                        options={companiesOptions}
                        onChange={(text) => {
                          this.setState({...this.state, company: text});
                        }}
                        value={this.state.company}
                        isMulti
                        styles={selectCustomStyle}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Bases</label>
                      <VirtualizedSelect
                        closeMenuOnSelect={false}
                        onChange={this.onChangeBases}
                        value={this.state.bases}
                        options={basesOptions}
                        isMulti
                        styles={selectCustomStyle}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8" style={{marginTop: '15px'}}>
                      <label className="font-normal p-w-xs">Período</label>
                      <form className="form-inline">
                        <div className="form-group">
                          <DateTime
                            onChange={this.handleBegin}
                            value={moment(this.state.startDate)
                              .local()
                              .format('DD/MM/YYYY 00:00')}
                            closeOnSelect
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Até</label>
                        </div>
                        <div className="form-group">
                          <DateTime
                            onChange={this.handleEnd}
                            value={moment(this.state.endDate)
                              .local()
                              .format('DD/MM/YYYY 23:59')}
                            closeOnSelect
                          />
                        </div>
                        <div className="form-group p-w-xs">
                          <button className="btn btn-primary" onClick={this.handleSearch}>
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {this.getTable()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AllInvoices.defaultProps = {
  baseList: [],
};

const mapStateToProps = (state) => ({
  baseBalance: state.base.baseBalance,
  baseBalanceFetching: state.base.baseBalanceFetching,
  companies: state.relatorio.companies,
  profileId: state.login.user_info.usuario.tipoUsuario,
  profiles: state.login.profiles,
  baseList: state.base.baseList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendIndividualEmail,
      billetButtonActions,
      sendNewBillet,
      getAllInvoices,
      getCompanies,
      loadBaseList,
      insertEmitBilletForm,
      clearInvoices,
      closeInvoice,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AllInvoices);
