import React from 'react';

import IconBadge from '../common/IconBadge';
import DialAgent from '../common/dialAgent/DialAgent';
import CopyToClipboard from '../common/copyToClipboard/CopyToClipboard';
import {ServiceTypes, StatusTypes} from '../../config';

const TenderItem = ({tender, onClick, openWpp}) => {
  const {nome, valorServico, tipoServico, usuario, dataCadastro, marca, modelo, telefone} =
    tender || {};
  const type = {
    color: 'primary',
    icon: 'fas fa-dollar-sign',
    description: valorServico,
  };
  return (
    <li
      className={`list-group-item solicitation-item`}
      onClick={() => onClick(tender)}
      style={{
        backgroundColor: `${tender.usuario.nome === 'WebApp Reboque.Me' ? '#f5ebeb' : ''}`,
        minHeight: `100px`,
      }}>
      <IconBadge customType={type} />
      <i className={ServiceTypes[tipoServico || 'REBOQUE'].icon} /> <strong>{nome}</strong>
      <br />
      <br />
      {telefone && (
        <small className="pull-right">
          <DialAgent phoneNumber={telefone} />
          <CopyToClipboard
            textToBeCopied={telefone}
            title={telefone}
            style={{marginLeft: 5}}
          />
        </small>
      )}
      {marca} {modelo}
      <br />
      {usuario ? usuario.nome : ''}
      <small className="pull-right">Criada em {dataCadastro}</small>
      <br />
    </li>
  );
};

export default TenderItem;
