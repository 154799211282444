import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import TenderItem from './TenderItem';
import NewSolicitationButton from './NewSolicitationButton';
import ListSearch from '../common/ListSearch';
import webSound from '../../vendor/sounds/capisci.mp3';
import {getTenderList, startMonitoringTender} from '../../actions/service_actions';
import {openWhatsApp} from '../../actions/followup_actions';

class Tender extends Component {
  constructor(props) {
    super(props);
    this.state = {filterInput: '', limit: 10};
    this.handleSearch = this.handleSearch.bind(this);
    this.loadOnScroll = this.loadOnScroll.bind(this);
    this.audio = new Audio(webSound);
  }

  componentDidMount() {
    const {startMonitoringTender} = this.props;
    startMonitoringTender();
  }
  componentDidUpdate(prevProps) {
    const {tenderList} = this.props;
    this.shouldNotify(tenderList, prevProps.tenderList);
  }
  shouldNotify(tenderList, oldTender) {
    if (tenderList.length > 0 && oldTender.length > 0) {
      if (tenderList[0].id > oldTender[0].id) {
        if (tenderList[0].usuario.nome === 'WebApp Reboque.Me') {
          this.notify();
        }
      }
    }
  }

  renderItems() {
    const {tenderList, onItemClick, openWhatsApp} = this.props;
    const {filterInput} = this.state;
    const filteredTenderList =
      tenderList.length > 0
        ? tenderList.filter((t) => {
            return (
              t.nome.toUpperCase().includes(filterInput.toUpperCase()) ||
              t.modelo.toUpperCase().includes(filterInput.toUpperCase()) ||
              t.marca.toUpperCase().includes(filterInput.toUpperCase())
            );
          })
        : [];
    return filteredTenderList.map((t, i) => (
      <TenderItem key={`tender${i}`} tender={t} onClick={onItemClick} openWpp={openWhatsApp} />
    ));
  }

  notify() {
    this.audio.play();
  }
  handleSearch(filterInput) {
    this.setState({filterInput});
  }
  loadOnScroll(e) {
    const {getTenderList} = this.props;
    const {limit} = this.state;
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 2;
    if (bottom) {
      getTenderList(limit);

      this.setState({limit: limit + 10});
    }
  }
  render() {
    return (
      <div className="full-height-scroll ">
        <ListSearch onFilter={this.handleSearch} />
        <div className="ibox ibox-content no-padding tender-list" onScroll={this.loadOnScroll}>
          <ul className="list-group">{this.renderItems()}</ul>
        </div>
        <audio id="sound" preload="auto">
          <source
            src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/sounds/sound.mp3"
            type="audio/mpeg"
          />
          <source
            src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/sounds/sound.ogg"
            type="audio/ogg"
          />
        </audio>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenderList: state.service.tenderList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTenderList,
      openWhatsApp,
      startMonitoringTender,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Tender);
