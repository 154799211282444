import React, {Component} from 'react';

import {Link} from 'react-router';
import {connect} from 'react-redux';
import moment from 'moment';

import FinishServiceModal from '../containers/FinishServiceModal';
import AlterScheduledModal from '../containers/AlterScheduleModal';
import {doOpenModal} from '../common/CustomModal';
import {
  hasAssistanceAccess,
  hasAcionamentoProfile,
  hasCompanyProfile,
  hasMAPFREProfile,
  hasProfiles,
  hasReboqueMeProfile,
  hasTegmaProfile,
  hasMonitoringAccess,
} from '../../utils/check_permission';
import {APP_PROFILES} from '../../reducers/constants';
import {EMATENDIMENTO, AGUARDANDOPROPOSTA, AGENDADA} from '../../config.js';
import {toastError} from '../../actions/toast_actions';
import {getClosestPartners} from '../../actions/address_actions';

class FollowUpItemButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
      showReopen: false,
      showAccept: false,
      showLoading: false,
      showChange: false,
    };
  }

  componentWillMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps({solicitation}) {
    if (
      this.props.solicitation.statusSolicitacao == EMATENDIMENTO &&
      solicitation.statusSolicitacao == AGUARDANDOPROPOSTA
    ) {
      toastError('Guincheiro cancelou o antendimento, solicitação reaberta.');
    }

    if (solicitation.statusSolicitacao == AGUARDANDOPROPOSTA) {
      this.setState({
        showCancel: false,
        showReopen: false,
        showAccept: false,
        showLoading: true,
      });
    } else if (this.props.solicitation.statusSolicitacao == EMATENDIMENTO) {
      this.setState({
        showCancel: true,
        showReopen: false,
        showAccept: false,
        showLoading: false,
      });
    } else if (this.props.solicitation.statusSolicitacao == AGENDADA) {
      this.setState({
        showCancel: true,
        showReopen: false,
        showAccept: false,
        showLoading: false,
      });
    } else {
      this.setState({
        showCancel: false,
        showReopen: false,
        showAccept: false,
        showLoading: false,
      });
    }
  }

  handleStartScheduled(solicitationCode) {
    swal(
      {
        title: 'Deseja iniciar a solicitação?',
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
        type: 'warning',
      },
      () => this.props.startScheduledSolicitation(solicitationCode),
    );
  }

  render() {
    const {
      solicitation,
      onCancel,
      onCancelCustomized,
      onReopen,
      onSolve,
      onSelectReason,
      onInformArrival,
      openModal,
      onChangeValues,
      onChangeDestination,
      onResponsavelUpdate,
      onResponsavelRemove,
      profileId,
      login,
    } = this.props;

    const onlyProfileOne = {
      display: hasReboqueMeProfile() || hasTegmaProfile() ? 'block' : 'none',
    };

    const onlyProfileOneAndTwo = {
      display:
        hasReboqueMeProfile() || hasTegmaProfile() || hasMAPFREProfile() ? 'block' : 'none',
    };

    const {showReopen, showAccept} = this.state;
    let requestOrigin = null;
    if (this.props.solicitation && this.props.solicitation.enderecoOrigem) {
      requestOrigin = {
        lat: this.props.solicitation.enderecoOrigem.latitude,
        lng: this.props.solicitation.enderecoOrigem.longitude,
      };
    }
    return (
      <div className="row-fluid" style={this.props.style}>
        <div style={{display: showReopen ? 'block' : 'none'}}>
          <h3>Ação tomada</h3>
          <select
            className="form-control m-b col-md-12"
            name="solutionCode"
            id="solutionCode"
            onChange={onSelectReason}>
            <option value="SOLUCAO1">Solução 1</option>
            <option value="SOLUCAO2">Solução 2</option>
            <option value="SOLUCAO3">Solução 3</option>
            <option value="SOLUCAO4">Solução 4</option>
          </select>
          <button className="btn btn-w-m btn-default col-md-12" onClick={onSolve}>
            Resolver Solicitação
          </button>
          <button className="btn btn-w-m btn-primary col-md-12" onClick={onReopen}>
            Resolver e Recriar Solicitação
          </button>
        </div>

        <button
          className="btn btn-w-m btn-primary col-md-12"
          onClick={() => null}
          style={{display: showAccept ? 'block' : 'none'}}>
          Aguardando aceite
        </button>

        <div
          className="form-group"
          style={{
            display:
              this.props.solicitation.statusSolicitacao == EMATENDIMENTO ? 'block' : 'none',
          }}>
          <div>
            {(hasMonitoringAccess() || hasTegmaProfile()) && (
              <div className={'col-md-12'} style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                <button
                  className="btn btn-block btn-warning"
                  onClick={() => {
                    openModal('CHECKLIST_MODAL');
                  }}>
                  <small>Fazer Checklist</small>
                </button>
              </div>
            )}

            <div className={'col-md-12'} style={{paddingLeft: `0px`, paddingRight: `2px`}}>
              <button
                className="btn btn-block btn-primary"
                style={{
                  display: hasReboqueMeProfile() || hasCompanyProfile() ? 'block' : 'none',
                }}
                onClick={() => {
                  openModal('CONTEXT_ADDIMG');
                }}>
                <small>Ver Checklist</small>
              </button>
            </div>

            {solicitation.codigoAtendimento && hasAssistanceAccess() ? (
              <div className="col-md-12" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                <Link
                  className="btn btn-block btn-primary"
                  to={`/assistance/${solicitation.codigoAtendimento}/details`}>
                  <small>Ver Atendimento</small>
                </Link>
              </div>
            ) : null}
            <div className={'col-md-12'} style={{paddingLeft: `0px`, paddingRight: `2px`}}>
              <button
                className="btn btn-block btn-primary"
                onClick={() => {
                  openModal('CONTEXT_FOLLOWUP');
                }}>
                <small>Histórico</small>
              </button>
            </div>

            {(hasMonitoringAccess() || hasTegmaProfile()) && (
              <span>
                <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                  <button
                    className="btn btn-w-m btn-block btn-primary"
                    onClick={() => {
                      openModal('CONTEXT_EDITINFO');
                    }}>
                    <small>Alterar Valores</small>
                  </button>
                </div>
                <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                  <button className="btn btn-block btn-primary" onClick={onInformArrival}>
                    <small>Inf. Chegada</small>
                  </button>
                </div>
                <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                  <button
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      doOpenModal('CONCLUSAO_SERVICO');
                    }}>
                    <small>Concluir Servico</small>
                  </button>
                  <FinishServiceModal
                    context="CONCLUSAO_SERVICO"
                    options={[
                      {
                        label: 'Prestador não encontrou o Cliente',
                        value: 'MANUALCLIENTENAOENCONTRADO',
                      },
                      {
                        label: 'Prestador não consegue concluir no App',
                        value: 'MANUALPRESTADORSEMINTERNET',
                      },
                      {
                        label: 'Atendimento cancelado',
                        value: 'CONCLUSAOMANUALATENDIMENTOCANCELADO',
                      },
                    ]}
                  />
                </div>

                <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                  <button
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      this.props.onRestartSolicitation(solicitation.codigo);
                    }}>
                    <small>Reiniciar Solicitação</small>
                  </button>
                </div>

                <div className="col-md-12" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                  <button
                    className="btn btn-w-m btn-block btn-primary"
                    onClick={() => {
                      openModal('ALTER_DESTINATION');
                    }}>
                    <small>Alterar Destino</small>
                  </button>
                </div>
                {!solicitation.usuarioResponsavelMonitoramento && (
                  <div className="col-md-12" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => onResponsavelUpdate()}>
                      <small>Pegar Monitoramento</small>
                    </button>
                  </div>
                )}

                {solicitation.usuarioResponsavelMonitoramento &&
                  hasReboqueMeProfile() &&
                  hasProfiles([APP_PROFILES.ManagerMonitoramento]) && (
                    <div
                      className="col-md-12"
                      style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                      <button
                        className="btn btn-block btn-warning"
                        onClick={() => onResponsavelRemove()}>
                        <small>Remover Responsável</small>
                      </button>
                    </div>
                  )}
                <div className="col-md-12" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                  <button
                    className="btn btn-block btn-danger"
                    onClick={() => onCancel(undefined, undefined, solicitation)}>
                    <small>Cancelar Solicitação</small>
                  </button>
                </div>
              </span>
            )}
          </div>
        </div>
        <div
          className="form-group"
          style={{
            display: this.props.solicitation.statusSolicitacao === AGENDADA ? 'block' : 'none',
          }}>
          <div>
            {hasReboqueMeProfile() && (
              <div>
                <AlterScheduledModal
                  context="ALTER_SCHEDULE_MODAL"
                  finishDate={moment(this.props.solicitation.dataAgendada, 'DD/MM/YYYY HH:mm')}
                  renderListItem={this.props.renderListItem}
                />
                <div className="col-md-12">
                  <div
                    className={
                      solicitation.codigoAtendimento && hasAssistanceAccess()
                        ? 'col-md-6'
                        : null
                    }
                    style={
                      solicitation.codigoAtendimento && hasAssistanceAccess()
                        ? {paddingLeft: `0px`, paddingRight: `2px`}
                        : null
                    }>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        doOpenModal('ALTER_SCHEDULE_MODAL');
                        this.props.loadClosestPartners(requestOrigin);
                      }}>
                      <small>Alterar Agendamento</small>
                    </button>
                  </div>
                  {solicitation.codigoAtendimento && hasAssistanceAccess() ? (
                    <div
                      className="col-md-6"
                      style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                      <Link
                        className="btn btn-block btn-primary"
                        to={`/assistance/${solicitation.codigoAtendimento}/details`}>
                        <small>Ver Atendimento</small>
                      </Link>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => onChangeDestination()}>
                      <small>Alterar Destino</small>
                    </button>
                  </div>

                  <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => onChangeValues()}>
                      <small>Alterar Valores</small>
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        doOpenModal('CONTEXT_FOLLOWUP');
                      }}>
                      <small>Histórico</small>
                    </button>
                  </div>

                  <div className="col-md-6" style={{paddingLeft: `0px`, paddingRight: `2px`}}>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => this.handleStartScheduled(solicitation.codigo)}>
                      <small>Acordar</small>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {(hasReboqueMeProfile() || hasCompanyProfile()) && (
              <div className="col-md-12">
                <button
                  className="btn btn-block btn-danger"
                  onClick={(e) => onCancelCustomized(e, 'agendado', solicitation)}>
                  <small>Cancelar Solicitação</small>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadClosestPartners: (location) => {
    dispatch(getClosestPartners(location));
  },
});

const mapStateToProps = (state, ownProps) => ({
  login: state.login,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpItemButtons);
