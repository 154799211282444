import React, {Component} from 'react';

import toastr from 'toastr';
import {reduxForm} from 'redux-form';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {Tab, Tabs} from 'react-bootstrap';
import moment from 'moment';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import _ from 'lodash';
import chroma from 'chroma-js';

import SearchField from './SearchField';
import RelatoriosModalBody from './RelatoriosModalBody';
import Rate from './Rate';
import DialAgent from './dialAgent/DialAgent';
import DateFilter from './DataFilter';
import CustomModal, {doOpenModal} from './CustomModal';
import CsvExport from './CsvExport';
import CopyToClipboard from './copyToClipboard/CopyToClipboard';
import {statusCircle} from '../../utils/status_helper';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';
import {
  hasBaseProfile,
  hasCompanyProfile,
  hasKoviProfile,
  isTester,
  hasProfiles,
  hasReboqueMeProfile,
  hasSoonProfileAndLocalizaAccess,
} from '../../utils/check_permission';
import {
  reportCsvHeaders,
  reportCsvHeadersKovi,
  CODIGO_LOCALIZA,
} from '../../reducers/constants';
import {toastError} from '../../actions/toast_actions';

const statusList = [
  {value: 'CONCLUIDA', label: 'Concluida', color: '#28a745'},
  {value: 'EXPIRADA', label: 'Expirada', color: '#ffc107'},
  {value: 'CANCELADA', label: 'Cancelada', color: '#dc3545'},
  {value: 'INTERROMPIDA', label: 'Interrompida', color: '#dc3545'},
  {value: 'EMATENDIMENTO', label: 'Em atendimento', color: '#5c40ff'},
];

const conditionList = [
  {value: 'CAPOTADO', label: 'Capotado', color: '#28a745'},
  {value: 'GARAGEM', label: 'Garagem', color: '#dc3545'},
  {value: 'BLINDADO', label: 'Blindado', color: '#ffc107'},
  {value: 'SEMRODAS', label: 'Sem rodas', color: '#dc3545'},
  {value: 'RODASTRAVADAS', label: 'Rodas travadas (patins)', color: '#5c40ff'},
  {value: 'SEMCHAVES', label: 'Sem chaves', color: '#28a745'},
];

const manualConclusionList = [
  {
    value: 'MANUALCLIENTENAOENCONTRADO',
    label: 'Cliente não encontrado',
    color: '#ffc107',
  },
  {
    value: 'MANUALPRESTADORSEMINTERNET',
    label: 'Prestador sem internet',
    color: '#ffc107',
  },
  {
    value: 'CONCLUSAOMANUALATENDIMENTOCANCELADO',
    label: 'Atendimento Cancelado',
    color: '#ffc107',
  },
];

const servicosList = [
  {value: 'REBOQUE', label: 'Reboque', color: '#5c40ff'},
  {value: 'TROCAPNEU', label: 'Troca de Pneu', color: '#5c40ff'},
  {value: 'PANESECA', label: 'Pane Seca', color: '#5c40ff'},
  {value: 'BATERIA', label: 'Bateria', color: '#5c40ff'},
  {value: 'SUBSOLO', label: 'Subsolo', color: '#5c40ff'},
  {value: 'MECANICO', label: 'Mecânico', color: '#5c40ff'},
  {value: 'CHAVEIRO', label: 'Chaveiro', color: '#5c40ff'},
  {value: 'TAXI', label: 'Transporte de Passageiro', color: '#5c40ff'},
  {value: 'VIDROSAUTO', label: 'Vidraceiro Automotivo', color: '#5c40ff'},
  {value: 'CARRORESERVA', label: 'Carro Reserva', color: '#5c40ff'},
  {value: 'CHAVEIROHOME', label: 'Chaveiro Residencial', color: '#5c40ff'},
  {value: 'HIDRAULICAHOME', label: 'Hidráulica Residencial', color: '#5c40ff'},
  {value: 'ELETRICAHOME', label: 'Elétrica Residencial', color: '#5c40ff'},
  {value: 'VIDROSHOME', label: 'Vidraceiro Residencial', color: '#5c40ff'},
];

const PERFIL_SOON_ADMINISTRADOR = 1;
const PERFIL_SOON_FINANCEIRO = 6;
const PERFIL_SOON_SUPERVISOR_ACIONAMENTO = 10;
const PERFIL_SOON_SUPERVISOR_ATENDIMENTO = 11;
const PERFIL_SOON_SUPERVISOR_REDE = 13;

export const colourStyles = {
  control: (styles) => ({...styles, backgroundColor: 'white'}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles, {data}) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

class ReactTableCommon extends Component {
  constructor(props) {
    super(props);
    this.createCustomToolBar = this.createCustomToolBar.bind(this);
    this.createCustomButtonGroup = this.createCustomButtonGroup.bind(this);
    this.renderCustomSearchPanel = this.renderCustomSearchPanel.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.setTableLoader = this.setTableLoader.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.footer = this.footer.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.preLoadData = this.preLoadData.bind(this);
    this.loadNewData = this.loadNewData.bind(this);
    this.rateStars = this.rateStars.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.searchByCode = this.searchByCode.bind(this);
    this.searchByownId = this.searchByownId.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideFooter = this.hideFooter.bind(this);
    this.showFooter = this.showFooter.bind(this);
    this.handleVehicleConditions = this.handleVehicleConditions.bind(this);
    this.getCVSLinkData = this.getCVSLinkData.bind(this);
    // this.handleSizePerPageChange = this.handleSizePerPageChange.bind(this);

    this.state = {
      page: 1,
      sizePerPage: 10,
      data: [],
      dataCVSExport: [],
      selectedRows: [],
      isFetching: false,
      selectedRowCode: 0,
      companies: [],
      vehicleCondition: [],
      originalData: [],
      veiculo: null,
      parceiro: null,
      options: [],
      codigo: null,
      key: 1,
      currentSelectedCompanies: [],
      dataForReload: {},
      isFetchingCVS: false,
      showFooter: true,
      ownId: null,
      resetKeyCodigo: 0,
      resetKeyOwnId: 0,
      resetKeyVeiculo: 0,
      hasBase: false,
    };
  }

  onChangeParceiro(value) {
    this.setState({
      parceiro: value,
    });
  }

  handleTabs(key) {
    this.setState({
      key,
      page: 1,
    });
  }

  footer() {
    const {sendEmail} = this.props;
    return (
      <div style={{padding: '8px'}}>
        {hasReboqueMeProfile() && (
          <button
            className="btn btn-primary"
            onClick={() => sendEmail(this.state.selectedRowCode)}>
            Enviar Resumo
          </button>
        )}
      </div>
    );
  }

  hideFooter() {
    this.setState({showFooter: false});
  }

  showFooter() {
    this.setState({showFooter: true});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
      isFetching: nextProps.isFetching,
      isFetchingCVS: nextProps.isFetchingCVS,
      originalData: nextProps.data,
      dataCVSExport: nextProps.dataCVSExport,
    });
  }

  handleVehicleConditions(e) {
    this.setState({vehicleCondition: e.map((condition) => condition.value).toString()});
  }

  createPartnerOptions() {
    return this.props.allParceiros.map((partner) => {
      return {
        value: partner.code,
        label: `${partner.name} - ${partner.cellPhone}`,
        color: partner.status.description === 'Inativo' ? '#cecece' : '#5a5a5a',
      };
    });
  }

  createBaseOptions() {
    return this.props.allBases.map((partner) => {
      return {
        value: partner.code,
        baseid: partner.id,
        label: `${partner.tradingName} - ${partner.contact}`,
        color: partner.statusId === 75 ? '#cecece' : '#5a5a5a',
      };
    });
  }

  searchByownId(e) {
    if (!this.state.ownId) {
      toastError('Favor informar um ownId');
    } else {
      this.setState(
        (prevState) => ({
          veiculo: null,
          codigo: null,
          resetKeyCodigo: prevState.resetKeyCodigo + 1,
          resetKeyVeiculo: prevState.resetKeyVeiculo + 1,
        }),
        () => {
          this.preLoadData(undefined, undefined, true, true, 1, 10, false, false, true);
        },
      );
    }
  }

  searchByCode(e) {
    if (!this.state.codigo) {
      toastError('Favor informar um protocolo');
    } else {
      this.setState(
        (prevState) => ({
          ownId: null,
          veiculo: null,
          resetKeyOwnId: prevState.resetKeyOwnId + 1,
          resetKeyVeiculo: prevState.resetKeyVeiculo + 1,
        }),
        () => {
          this.preLoadData(undefined, undefined, true, false, 1, 10, true);
        },
      );
    }
  }

  searchByPlateNumber() {
    if (!this.state.veiculo) {
      toastError('Favor informar uma placa');
    } else {
      this.setState(
        (prevState) => ({
          ownId: null,
          codigo: null,
          resetKeyVeiculo: prevState.resetKeyVeiculo + 1,
          resetKeyOwnId: prevState.resetKeyOwnId + 1,
        }),
        () => {
          this.preLoadData(undefined, undefined, false, true, 1, 10, false, true);
        },
      );
    }
  }

  handleParceiros(selectedOptions) {
    this.setState({selectedPartners: selectedOptions});
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.placa &&
      this.props.location.state.placa !== this.state.veiculo &&
      this.props.location.state.solicitationCode &&
      this.props.location.state.solicitationCode !== this.state.codigo &&
      this.state.codigo === null &&
      (hasCompanyProfile() || (this.props.companies && this.props.companies.length > 0))
    ) {
      const plate = this.props.location.state.placa;
      const solicitationCode = this.props.location.state.solicitationCode;
      this.setState(
        {
          veiculo: plate,
          codigo: solicitationCode,
          key: 2,
        },
        () => {
          this.searchByPlateNumber();
        },
      );
      const {selectSolicitation} = this.props;
      this.setState({selectedRowCode: solicitationCode});
      doOpenModal('RELATORIO_MODAL');
      selectSolicitation(solicitationCode);
    }
    if (prevProps.bases.length !== this.props.bases.length) {
      this.setState({hasBase: this.props.bases.length > 0});
    }
  }

  createCustomToolBar(props) {
    const emp = props.usuarios.map((c) => ({
      value: c.id,
      label: c.nome,
      color: c.statusUsuario.descricao === 'Ativo' ? '#5a5a5a' : '#cecece',
    }));

    const partners = this.createPartnerOptions();
    const bases = this.createBaseOptions();

    return (
      <div>
        <Tabs id="daf-dashboard-tabs" activeKey={this.state.key} onSelect={this.handleTabs}>
          <Tab
            eventKey={1}
            title="Filtro por código"
            style={this.state.key == 1 ? {display: 'flex', alignItems: 'end'} : null}>
            <div className="col-md-3" style={{marginTop: '15px'}}>
              <label className="font-normal p-w-xs">Código</label>
              <InputMask
                key={this.state.resetKeyCodigo}
                value={this.state.codigo}
                onChange={(e) => this.setState({codigo: e.target.value})}
                onKeyDown={(e) => e.key === 'Enter' && this.searchByCode(e)}
                className="form-control"
                type="number"
                placeholder="2632820000006954125"
              />
            </div>
            <div className="col-md-1">
              <button className="btn btn-primary" onClick={() => this.searchByCode()}>
                <i className="fa fa-search" />
              </button>
            </div>
          </Tab>
          <Tab
            eventKey={4}
            title="Filtro por protocolo"
            style={this.state.key == 4 ? {display: 'flex', alignItems: 'end'} : null}>
            <div className="col-md-3" style={{marginTop: '15px'}}>
              <label className="font-normal p-w-xs">Protocolo</label>
              <InputMask
                key={this.state.resetKeyOwnId}
                value={this.state.ownId}
                onChange={(e) => this.setState({ownId: e.target.value})}
                onKeyDown={(e) => e.key === 'Enter' && this.searchByownId(e)}
                className="form-control"
                type="text"
                placeholder="2KNM52/30"
              />
            </div>
            <div className="col-md-1">
              <button className="btn btn-primary" onClick={() => this.searchByownId()}>
                <i className="fa fa-search" />
              </button>
            </div>
          </Tab>
          <Tab
            eventKey={2}
            title="Filtro por placa/chassi"
            style={this.state.key == 2 ? {display: 'flex', alignItems: 'end'} : null}>
            <div className="col-md-3" style={{marginTop: '15px'}}>
              <label className="font-normal p-w-xs">Placa/chassi</label>
              <InputMask
                key={this.state.resetKeyVeiculo}
                value={this.state.veiculo}
                onChange={(e) => this.setState({veiculo: e.target.value.replace(/-/g, '')})}
                onKeyDown={(e) => e.key === 'Enter' && this.searchByPlateNumber(e)}
                className="form-control"
                type="text"
                placeholder="Placa ou chassi do veiculo"
              />
            </div>
            <div className="col-md-1">
              <button className="btn btn-primary" onClick={() => this.searchByPlateNumber()}>
                <i className="fa fa-search" />
              </button>
            </div>
          </Tab>
          {!hasReboqueMeProfile() ||
          hasProfiles([
            PERFIL_SOON_ADMINISTRADOR,
            PERFIL_SOON_FINANCEIRO,
            PERFIL_SOON_SUPERVISOR_ACIONAMENTO,
            PERFIL_SOON_SUPERVISOR_ATENDIMENTO,
            PERFIL_SOON_SUPERVISOR_REDE,
          ]) ? (
            <Tab eventKey={3} title="Outros filtros">
              {hasReboqueMeProfile() ? (
                <div className="col-md-4" style={{marginTop: '15px'}}>
                  <label className="font-normal p-w-xs">Empresas</label>
                  {props.components.btnGroup}
                </div>
              ) : null}

              <div>
                {(hasReboqueMeProfile() || hasCompanyProfile()) && (
                  <div className="col-md-4" style={{marginTop: '15px'}}>
                    <label className="font-normal p-w-xs">Usuários</label>
                    <Select
                      placeholder={'Todos os usuários'}
                      closeMenuOnSelect={false}
                      onChange={this.props.handleUsuarios}
                      isMulti
                      options={emp}
                      styles={{...colourStyles, ...selectCustomStyle}}
                    />
                  </div>
                )}

                <div className="col-md-4" style={{marginTop: '15px'}}>
                  <label className="font-normal p-w-xs">Status</label>
                  <Select
                    closeMenuOnSelect={false}
                    onChange={this.props.handleStatus}
                    isMulti
                    defaultValue={statusList}
                    options={statusList}
                    styles={{...colourStyles, ...selectCustomStyle}}
                  />
                </div>

                <div className="col-md-4" style={{marginTop: '15px'}}>
                  <label className="font-normal p-w-xs">Condições do veículo</label>
                  <Select
                    closeMenuOnSelect={false}
                    onChange={this.handleVehicleConditions}
                    isMulti
                    defaultValue={[]}
                    options={conditionList}
                    styles={{...colourStyles, ...selectCustomStyle}}
                  />
                </div>

                {hasReboqueMeProfile() && (
                  <div className="col-md-4" style={{marginTop: '15px'}}>
                    <label className="font-normal p-w-xs">Conclusão Manual</label>
                    <Select
                      closeMenuOnSelect={false}
                      onChange={this.props.handleConclusaoManual}
                      isMulti
                      options={manualConclusionList}
                      styles={{...colourStyles, ...selectCustomStyle}}
                    />
                  </div>
                )}
                {hasReboqueMeProfile() && (
                  <div className="col-md-4" style={{marginTop: '15px'}}>
                    <label className="font-normal p-w-xs">Base</label>
                    <Select
                      closeMenuOnSelect={false}
                      onChange={this.props.handleBases}
                      isMulti
                      options={bases}
                      styles={{...colourStyles, ...selectCustomStyle}}
                    />
                  </div>
                )}
                {this.state.hasBase && hasReboqueMeProfile() && (
                  <div className="col-md-4" style={{marginTop: '15px'}}>
                    <label className="font-normal p-w-xs">Parceiro</label>
                    <Select
                      closeMenuOnSelect={false}
                      onChange={this.props.handleParceiros}
                      isMulti
                      defaultValue={partners}
                      options={partners}
                      styles={{...colourStyles, ...selectCustomStyle}}
                    />
                  </div>
                )}

                <div className="col-md-12" style={{marginTop: '15px'}}>
                  <label className="font-normal p-w-xs">Serviços</label>
                  <Select
                    closeMenuOnSelect={false}
                    onChange={this.props.handleServicos}
                    isMulti
                    defaultValue={servicosList}
                    options={servicosList}
                    styles={{...colourStyles, ...selectCustomStyle}}
                  />
                </div>

                <div className="col-md-12" style={{marginTop: '15px'}}>
                  <label className="font-normal p-w-xs">Período</label>
                  <DateFilter loadData={this.preLoadData} />
                </div>
              </div>
            </Tab>
          ) : null}
        </Tabs>
      </div>
    );
  }

  loadNewData(page, sizePerPage) {
    const {
      dataForReload: {startDate, endDate, ignoreVeiculo, ignoreCodigo},
    } = this.state;

    this.preLoadData(startDate, endDate, ignoreVeiculo, ignoreCodigo, page, sizePerPage);
  }

  loadDataCVSExport(
    startDate = moment(),
    endDate = moment(),
    ignoreVeiculo = false,
    ignoreCodigo = false,
    page = 1,
    sizePerPage = 10,
  ) {
    const {
      loadCVSExportData,
      compiledCompanies,
      allCompanies,
      parceiros,
      compiledUsuarios,
      servicos,
      conclusaoManual,
      bases,
    } = this.props;
    const status = this.props.status;
    const vehicleCondition = this.state.vehicleCondition;
    const {veiculo, codigo} = this.state;
    let parceiroParam;
    let companies = compiledCompanies !== '' ? compiledCompanies : allCompanies;
    const allStatus = statusList.map(({value}) => value).join(',');
    const allConditions = conditionList.map(({value}) => value).join(',');

    let newStatus = status !== '' ? status : allStatus;
    let newConditions = vehicleCondition !== '' ? vehicleCondition : allConditions;

    const servicosSelect = servicosList.map((servico) => servico.value);
    let newServico = servicos !== '' ? servicos : servicosSelect;

    let usuario = compiledUsuarios !== '' ? compiledUsuarios : null;

    // Sem permissão nenhuma companhia é mandada por parâmetro
    if (!hasReboqueMeProfile()) companies = null;

    if (parceiros) {
      parceiroParam = parceiros;
    }

    this.props.resetSolicitatonsListCsvExport();

    loadCVSExportData(
      startDate,
      endDate,
      sizePerPage,
      companies,
      newStatus,
      usuario,
      ignoreVeiculo ? null : veiculo,
      parceiroParam,
      newServico,
      bases,
      conclusaoManual,
      ignoreCodigo ? null : codigo,
      page,
      newConditions,
    );
  }

  preLoadData(
    startDate,
    endDate,
    ignoreVeiculo = false,
    ignoreCodigo = false,
    page = 1,
    sizePerPage = 10,
    codeSearch = false,
    plateSearch = false,
    ownIdSearch = false,
  ) {
    this.setState({
      dataForReload: {
        startDate,
        endDate,
        ignoreVeiculo,
        ignoreCodigo,
      },
    });
    const {
      loadData,
      compiledCompanies,
      allCompanies,
      parceiros,
      bases,
      compiledUsuarios,
      servicos,
      conclusaoManual,
    } = this.props;
    const status = this.props.status;
    const vehicleCondition = this.state.vehicleCondition;

    const {veiculo, codigo, ownId} = this.state;

    const allStatus = statusList.map(({value}) => value).join(',');

    const servicosSelect = servicosList.map((servico) => servico.value);

    const defaultParams = {
      startDate: startDate,
      endDate: endDate,
      sizePerPage: sizePerPage,
      companies: compiledCompanies !== '' ? compiledCompanies : allCompanies,
      status: status !== '' ? status : allStatus,
      vehicleCondition: vehicleCondition.length ? vehicleCondition : '',
      usuario: compiledUsuarios !== '' ? compiledUsuarios : null,
      veiculo: ignoreVeiculo ? null : veiculo,
      parceiro: parceiros,
      bases: bases,
      servico: servicos !== '' ? servicos : servicosSelect,
      conclusaoManual: conclusaoManual,
      codigo: ignoreCodigo ? null : codigo,
      page: page,
    };

    if (codeSearch) {
      loadData(
        undefined,
        undefined,
        sizePerPage,
        null,
        '',
        null,
        null,
        null,
        undefined,
        '',
        false,
        codigo,
        page,
      );
    } else if (plateSearch) {
      loadData(
        undefined,
        undefined,
        sizePerPage,
        null,
        '',
        null,
        veiculo,
        null,
        undefined,
        '',
        false,
        undefined,
        page,
      );
    } else if (ownIdSearch) {
      loadData(
        undefined,
        undefined,
        sizePerPage,
        null,
        '',
        null,
        veiculo,
        null,
        undefined,
        '',
        false,
        undefined,
        page,
        ownId,
      );
    } else {
      loadData(
        defaultParams.startDate,
        defaultParams.endDate,
        defaultParams.sizePerPage,
        defaultParams.companies,
        defaultParams.status,
        defaultParams.usuario,
        defaultParams.veiculo,
        defaultParams.parceiro,
        defaultParams.bases,
        defaultParams.servico,
        defaultParams.conclusaoManual,
        defaultParams.codigo,
        defaultParams.page,
        undefined,
        defaultParams.vehicleCondition,
      );
    }
    this.setState({
      veiculo: null,
      codigo: null,
      ownId: null,
    });
  }

  getCVSLinkData() {
    const {
      dataForReload: {startDate, endDate, ignoreVeiculo, ignoreCodigo},
    } = this.state;

    // prepareDataForCVSExport funtion has been moved to the CSVExport.js file
    this.loadDataCVSExport(startDate, endDate, ignoreVeiculo, ignoreCodigo);
  }

  createCustomButtonGroup(props) {
    const comp = props.companies.map((c) => ({
      value: c.codigo,
      label: c.nomeFantasia,
      color: c.statusEmpresa.descricao === 'Ativo' ? '#5a5a5a' : '#cecece',
    }));

    return (
      hasReboqueMeProfile() && (
        <div className="row">
          <div className="col-md-12">
            {/* <label className="font-normal p-w-xs">Empresas</label> */}
            <Select
              placeholder={'Todas as empresas'}
              closeMenuOnSelect={false}
              onChange={(e) => {
                this.props.handleCompanies(e);
                this.handleCompanyChange(e);
              }}
              isMulti
              options={comp}
              styles={{...colourStyles, ...selectCustomStyle}}
            />
          </div>
        </div>
      )
    );
  }

  handleCompanyChange(e) {
    if (e) {
      this.setState({currentSelectedCompanies: e.map((company) => company.value)});
    }
  }

  createCustomClearButton(onClick) {
    return (
      <button className="btn btn-sm btn-primary" onClick={onClick}>
        Limpar
      </button>
    );
  }

  renderCustomSearchPanel(props) {
    /**
     *  This function only pass one argument, is props object has following properties
     *  {
     *    searchField,  // the search input field JSX
     *    clearBtn,  // clear search button JSX
     *    defaultValue,  // the default value for searching
     *    placeholder,  // placeholder for search input field
     *    clearBtnClick,  // the callback for triggering clear button click event
     *    search  // the callback function for triggering search event, call it with a string text for searching
     *  }
     ***/
    return props.searchField;
  }

  checkUserBaseIncludes(e, searchText) {
    const codigo =
      e.codigo !== undefined && e.codigo.toString().toUpperCase().includes(searchText);

    const nome =
      e.cliente !== undefined && e.cliente.nome.toString().toUpperCase().includes(searchText);

    const modelo =
      e.veiculoCliente !== undefined &&
      e.veiculoCliente.modelo !== undefined &&
      e.veiculoCliente.modelo.toString().toUpperCase().includes(searchText);

    const placa =
      e.veciculoCliente !== undefined &&
      e.veiculoCliente.place !== undefined &&
      e.veiculoCliente.placa.toString().toUpperCase().includes(searchText);

    const parceiroNomeFantasia =
      e.respostaAceita !== undefined &&
      e.respostaAceita.parceiro !== undefined &&
      e.respostaAceita.nomeFantasia !== undefined &&
      e.respostaAceita.parceiro.nomeFantasia.toString().toUpperCase().includes(searchText);

    return codigo || nome || modelo || placa || parceiroNomeFantasia;
  }

  onSearchChange(searchText, colInfos, multiColumnSearch) {
    if (searchText) {
      searchText = searchText.toUpperCase();
      this.setState({
        data: this.state.originalData.filter((e) => {
          if (e.respostaAceita) {
            if (hasBaseProfile()) {
              return this.checkUserBaseIncludes(e, searchText);
            }

            return (
              e.codigo.toString().toUpperCase().includes(searchText) ||
              e.cliente.nome.toString().toUpperCase().includes(searchText) ||
              e.veiculoCliente.modelo.toString().toUpperCase().includes(searchText) ||
              e.veiculoCliente.placa.toString().toUpperCase().includes(searchText) ||
              e.respostaAceita.parceiro.nomeFantasia
                .toString()
                .toUpperCase()
                .includes(searchText)
            );
          } else {
            if (hasBaseProfile()) {
              return this.checkUserBaseIncludes(e, searchText);
            }

            return (
              e.codigo.toString().toUpperCase().includes(searchText) ||
              e.cliente.nome.toString().toUpperCase().includes(searchText) ||
              e.veiculoCliente.modelo.toString().toUpperCase().includes(searchText) ||
              e.veiculoCliente.placa.toString().toUpperCase().includes(searchText)
            );
          }
        }),
      });
    } else this.setState({data: this.state.originalData});
  }

  handlePageChange(page, sizePerPage) {
    this.setState({page: page, sizePerPage: sizePerPage});
    this.loadNewData(page, sizePerPage);
  }

  handleRowSelect(row, isSelected, e) {
    const {selectedRows} = this.state;

    if (isSelected === true) {
      selectedRows.push(row);
      this.setState({
        selectedRows,
      });
    } else {
      const allRows = () =>
        selectedRows.filter((stateRow) => {
          return stateRow.codigo !== row.codigo;
        });
      this.setState({
        selectedRows: allRows(),
      });
    }
  }

  handleSelectAll(isSelected, rows) {
    const {selectedRows} = this.state;

    if (isSelected === true) {
      selectedRows.push(...rows);
      this.setState({
        selectedRows,
      });
    } else {
      this.setState({
        selectedRows: [],
      });
    }
  }

  setTableLoader() {
    if (this.state.isFetching === false) {
      return `Nenhum registro encontrado`;
    } else {
      return (
        <div className="spiner-example">
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1" />
            <div className="sk-double-bounce2" />
          </div>
        </div>
      );
    }
  }

  onRowClick(row) {
    if (isTester()) {
      const newUrl = `relatorios?solicitation=${row.codigo}&tab=solicitation`;
      // Verifica se o iframe pode acessar a janela pai (same-origin)
      if (window.parent) {
        window.parent.history.pushState({}, '', newUrl); // Altera a URL da janela pai sem recarregar
      } else {
        console.error(
          'Acesso negado à janela pai (provavelmente devido à política de mesma origem)',
        );
      }
      // Dispara um evento `popstate` para forçar o React Router a re-renderizar
      const popStateEvent = new PopStateEvent('popstate', {state: {}});
      window.parent.dispatchEvent(popStateEvent);
    } else {
      const {selectSolicitation} = this.props;
      this.setState({selectedRowCode: row.codigo});
      doOpenModal('RELATORIO_MODAL');
      selectSolicitation(row.codigo);
    }
  }

  clearSearch() {
    this.setState({data: this.state.originalData});
  }

  rateStars(cell, row) {
    if (!!row.avaliacaoCliente)
      return (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
          }}>
          <button
            onClick={(e) => {
              e.preventDefault();
              this.onRowClick(row);
            }}
            data-tip="Ver detalhes do atendimento"
            data-for="info"
            className={`btn btn-sm btn-primary`}>
            <i className="fas fa-info-circle" />
          </button>
          <div style={{width: '18%'}}>
            <Rate
              avaliado={!!row.avaliacaoCliente}
              stars={row.avaliacaoCliente ? row.avaliacaoCliente.nota : 0}
            />
          </div>
        </div>
      );
    else {
      return (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
          }}>
          <button
            onClick={(e) => {
              e.preventDefault();
              this.onRowClick(row);
            }}
            data-tip="Ver detalhes do atendimento"
            data-for="info"
            className={`btn btn-sm btn-primary`}
            style={{marginRight: '.3em'}}>
            <i className="fas fa-info-circle" />
          </button>

          {(hasReboqueMeProfile() || hasCompanyProfile()) && (
            <div style={{marginLeft: '10px'}}>
              <DialAgent phoneNumber={row.cliente.telefoneCelular} />
              <CopyToClipboard
                textToBeCopied={row.cliente.telefoneCelular}
                title={row.cliente.telefoneCelular}
                style={{marginLeft: 5}}
              />
            </div>
          )}
          <ReactTooltip id="info" />
        </div>
      );
    }
  }

  render() {
    const {originalData, page} = this.state;
    const {
      selectedSolicitationFetching,
      selectedSolicitation,
      solicitationUpdated,
      solicitationUpdateFetching,
      updateSolicitation,
      profileId,
    } = this.props;
    const options = {
      onRowDoubleClick: (row) => this.onRowClick(row),
      clearSearch: true,
      clearBtn: this.createCustomClearButton,
      toolBar: (e) =>
        this.createCustomToolBar({
          ...e,
          companies: this.props.companies,
          usuarios: this.props.usuarios,
          profileId,
        }),
      searchPosition: `right`,
      btnGroup: (props) =>
        this.createCustomButtonGroup({
          companies: this.props.companies,
          profileId,
        }),
      searchField: (props) => (
        <SearchField
          {...props}
          placeholder="Filtrar registros..."
          search={this.onSearchChange}
          clearSearch={this.clearSearch}
        />
      ),
      page: page,
      onPageChange: this.handlePageChange,
      searchPanel: this.renderCustomSearchPanel,
      noDataText: this.setTableLoader(),
      hideSizePerPage: true,
    };

    function nameFormat(cell, row) {
      return <div>{row.cliente.nome}</div>;
    }

    function ssPDFFormat(cell, row) {
      if (row.ssPdf) {
        return <span className="badge badge-success">Sim</span>;
      }
      return <span className="badge badge-danger">Não</span>;
    }

    function servicoFormat(cell, row) {
      return <div>{row.tipoServico.codigo}</div>;
    }

    function valorFormat(cell, row) {
      if (hasReboqueMeProfile() || hasCompanyProfile()) {
        const valor = row.valorCorrida;
        return <div>{valor ? `R$ ${valor.toFixed(2).replace('.', ',')}` : 'R$ 0,00'}</div>;
      } else if (hasBaseProfile()) {
        const valor = row.valorParceiro;
        return <div>{valor ? `R$ ${valor.toFixed(2).replace('.', ',')}` : 'R$ 0,00'}</div>;
      }
    }
    function veiculoformat(cell, row) {
      return (
        <div>
          {`${row.veiculoCliente.placa} - ${row.veiculoCliente.marca} ${row.veiculoCliente.modelo}`}
        </div>
      );
    }
    function parceiroFormat(cell, row) {
      return <div>{`${row.respostaAceita ? row.respostaAceita.parceiro.contato : ''}`}</div>;
    }

    function ssLink(cell, row) {
      function getCopyLink() {
        return row.ownId ? `${row.ownId}` : '';
      }

      return (
        <div>
          {row.ownId ? (
            <CopyToClipboard
              textToBeCopied={getCopyLink()}
              title={row.ownId}
              dataTip="Link copiado!"
              dataPlace="right"
              icon="far fa-copy"
            />
          ) : (
            <span>-</span>
          )}
        </div>
      );
    }

    const getCSVHeaders = () => {
      if (hasKoviProfile()) return reportCsvHeadersKovi;
      return reportCsvHeaders;
    };

    const {
      startDate: startDateReload,
      endDate: endDateReload,
      ignoreVeiculo: ignoreVeiculoReload,
      ignoreCodigo: ignoreCodigoReload,
    } = this.state.dataForReload;

    const {totalItems, fetchingCVSProgress} = this.props;
    const {data, dataCVSExport, isFetchingCVS} = this.state;

    return (
      <div>
        <CustomModal
          context={'RELATORIO_MODAL'}
          footer={this.state.showFooter ? this.footer() : null}
          customClass="report-modal">
          <RelatoriosModalBody
            loadData={() =>
              this.preLoadData(
                startDateReload,
                endDateReload,
                ignoreVeiculoReload,
                ignoreCodigoReload,
              )
            }
            hideFooter={this.hideFooter}
            showFooter={this.showFooter}
            row={selectedSolicitation}
            isFetching={selectedSolicitationFetching}
            solicitationUpdated={solicitationUpdated}
            solicitationUpdateFetching={solicitationUpdateFetching}
            updateSolicitation={updateSolicitation}
          />
        </CustomModal>
        <BootstrapTable
          ref={(tableRefs) => (this.refs = tableRefs)}
          data={data}
          search
          multiColumnSearch
          options={
            options //selectRow={ selectRow }
          }
          fetchInfo={{dataTotalSize: totalItems}}
          remote
          pagination
          striped
          hover
          condensed
          bordered={false}>
          <TableHeaderColumn
            dataField={'status'}
            dataSort={true}
            dataFormat={statusCircle}
            width="80"
          />

          <TableHeaderColumn isKey dataFormat={nameFormat} width="150">
            Cliente
          </TableHeaderColumn>
          <TableHeaderColumn isKey dataFormat={veiculoformat} width="180">
            Veículo
          </TableHeaderColumn>
          <TableHeaderColumn isKey dataFormat={valorFormat} dataAlign="center" width="120">
            Valor
          </TableHeaderColumn>
          <TableHeaderColumn isKey dataFormat={servicoFormat} width="100">
            Serviço
          </TableHeaderColumn>
          <TableHeaderColumn isKey dataField="dataCadastro" width="150">
            Abertura
          </TableHeaderColumn>
          {this.state.currentSelectedCompanies.length === 1 &&
            this.state.key === 3 &&
            this.state.currentSelectedCompanies.includes(CODIGO_LOCALIZA) &&
            hasSoonProfileAndLocalizaAccess(this.state.currentSelectedCompanies[0]) && (
              <TableHeaderColumn dataFormat={ssPDFFormat} width="150" dataAlign="center">
                SS Atribuido
              </TableHeaderColumn>
            )}

          <TableHeaderColumn dataFormat={ssLink} width="150">
            Protocolo
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={parceiroFormat}>Parceiro</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.rateStars} />
        </BootstrapTable>
        {originalData.length > 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {totalItems && (
              <span className="ordersTotal">
                Total: <strong>{totalItems}</strong>
              </span>
            )}
            <div>
              {(hasReboqueMeProfile() || hasCompanyProfile()) && (
                <CsvExport
                  data={dataCVSExport}
                  isFetchingCVS={isFetchingCVS}
                  totalItems={totalItems}
                  fetchingCVSProgress={fetchingCVSProgress}
                  headers={getCSVHeaders()}
                  asyncGetData={this.getCVSLinkData}
                  startDate={this.state.dataForReload.startDate}
                  endDate={this.state.dataForReload.endDate}
                  filename={`relatorio-${Date.now()}.csv`}
                  className="btn btn-success btn-soon">
                  <i className="fas fa-file-export" />
                  <span style={{marginLeft: '5px'}}>Exportar .csv</span>
                </CsvExport>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default reduxForm({
  form: 'relatorio',
})(ReactTableCommon);
