import React, {Component} from 'react';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './style.css';
import {Tabs, Tab} from 'react-bootstrap';

import TimelineTab from './TimelineTab';
import EditInfo from '../../followup/EditInfo';
import {
  getDocumentsToApproval,
  getDocumentAssetsFromSolicitation,
} from '../../dashBoard/dashboardActions';
import Rate from '../../common/Rate';

import {bindActionCreators} from 'redux';

import DialAgent from '../../common/dialAgent/DialAgent';
import {CustomModalWithoutTabIndex} from '../../common/CustomModal';
import CopyToClipboard from '../../common/copyToClipboard/CopyToClipboard';
import {hasReboqueMeProfile} from '../../../utils/check_permission';

function getIaStatus(IAValidation) {
  switch (IAValidation) {
    case null:
      return {
        IaColor: '#d9d9d9',
        tooltipText: 'Não validado pela IA.',
      };
    case false:
      return {
        IaColor: '#f5222d',
        tooltipText: 'Recusado pela IA.',
      };
    default:
      return {
        IaColor: '#d9d9d9',
        tooltipText: 'Não validado pela IA.',
      };
  }
}

function parseDescription(rawDescription) {
  let description = rawDescription ? rawDescription.split(';') : [];
  description = description.map((text) => {
    if (text.includes('http')) {
      return {type: 'toolsLink', content: text.replace(/urlTolls:/gm, '')};
    }
    return {type: 'text', content: text};
  });
  return description;
}
class ChoiceModal extends Component {
  constructor(props) {
    super(props);
    this.handleTabs = this.handleTabs.bind(this);
    this.handleAfterSolicitationValues = this.handleAfterSolicitationValues.bind(this);
  }

  handleTabs(key) {
    this.props.handleChangeTabIndex(key);
  }

  handleAfterSolicitationValues() {
    alert('HandleAfterSolicitation');
  }

  render() {
    const {
      choices,
      handleDecision,
      approveDocument,
      tabIndex,
      row,
      documentsFetching,
    } = this.props;

    const {
      cliente,
      empresa,
      enderecoDestino,
      enderecoOrigem,
      formaPagamento,
      usuario,
      codigo,
      respostaAceita,
      veiculoCliente,
      avaliacaoCliente,
    } = row;

    return (
      <CustomModalWithoutTabIndex
        title={this.props.title}
        customClass="modal-dialog-document"
        context={this.props.context}>
        <Tabs id="documentos-aprovacao-tabs" activeKey={tabIndex} onSelect={this.handleTabs}>
          <Tab eventKey={1} title="Documentos">
            {tabIndex === 1 && (
              <div className="document--container">
                {choices.map((choice) => {
                  const iaStatus = getIaStatus(choice.IAValidation);
                  return (
                    <div key={choice.codigo} className="card choice-card" style={{width: 350}}>
                      <div className="card__header">
                        <span
                          title={iaStatus.tooltipText}
                          className="card__header__icon"
                          style={{color: iaStatus.IaColor}}>
                          <i className="fas fa-robot"></i>
                        </span>

                        <div className="card__header__titleContainer">
                          <p className="card__header__title">
                            {choice.tipoDocumento.descricao}
                          </p>
                          <p className="card__header__date">
                            {choice.dataCadastro.split(' ')[0]}
                          </p>
                        </div>
                      </div>
                      <a href={choice.urlLocation} target="_blank" rel="noreferrer">
                        <img
                          className="card-img-top card-image"
                          style={{width: 348}}
                          src={choice.urlLocation}
                          alt="Card image cap"
                        />
                      </a>

                      <div className="card-body choice-card-body">
                        {parseDescription(choice.IADescription).map(({type, content}) => {
                          if (type === 'toolsLink') {
                            return (
                              <a href={content} target="_blank" key={content} rel="noreferrer">
                                Link pedágio
                              </a>
                            );
                          }
                          return (
                            <p key={content} className="card-text">
                              {content}
                            </p>
                          );
                        })}
                        <hr></hr>
                        <button
                          onClick={() => approveDocument(choice.codigo)}
                          className="btn btn-primary">
                          <i className="fas fa-check" />
                        </button>
                        <button
                          onClick={() => handleDecision(choice.codigo, false)}
                          style={{width: 40, marginLeft: 16}}
                          className="btn btn-danger">
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Tab>
          <Tab eventKey={2} title="Solicitação">
            {tabIndex === 2 && row && (
              <section>
                {documentsFetching ? (
                  <div className="spiner-example">
                    <div className="sk-spinner sk-spinner-double-bounce">
                      <div className="sk-double-bounce1" />
                      <div className="sk-double-bounce2" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="col-md-8" style={{borderRight: '1px solid #e5e5e5'}}>
                      <div className="row form-group">
                        <div className="form-group custom-tab col-md-3">
                          <label>Nome do Cliente</label>
                          <br />
                          <span>{cliente.nome}</span>
                        </div>
                        <div className="form-group custom-tab col-md-3">
                          <label>Telefone</label>
                          <br />
                          {hasReboqueMeProfile() && cliente.telefoneCelular ? (
                            <span>
                              <DialAgent phoneNumber={cliente.telefoneCelular} />
                              <CopyToClipboard
                                textToBeCopied={cliente.telefoneCelular}
                                title={cliente.telefoneCelular}
                                style={{marginLeft: 5}}
                              />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="form-group custom-tab col-md-3">
                          <label>CPF</label>
                          <br />
                          {hasReboqueMeProfile() && <span>{cliente.cpf}</span>}
                        </div>
                        <div className="form-group custom-tab col-md-3">
                          <label>Veículo</label>
                          <br />
                          <span>{`${veiculoCliente.modelo} / ${veiculoCliente.placa}`}</span>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="form-group custom-tab col-md-6">
                          <label>Endereço de Origem</label>
                          <br />
                          <span>
                            {`${enderecoOrigem.logradouro},
                                ${enderecoOrigem.numero} - 
                                ${enderecoOrigem.bairro},
                                ${enderecoOrigem.cidade}`}
                          </span>
                        </div>
                        <div className="form-group custom-tab col-md-6">
                          <label>Endereço de Destino</label>
                          <br />
                          {enderecoDestino ? (
                            <span>
                              {`${enderecoDestino.logradouro},
                                ${enderecoDestino.numero} - 
                                ${enderecoDestino.bairro},
                                ${enderecoDestino.cidade}`}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <EditInfo
                        solicitation={row}
                        afterSolicitationValues={() => {
                          this.props.getDocumentsToApproval(this.props.resetRow);
                          this.props.getDocumentAssetsFromSolicitation(codigo);
                        }}
                      />
                    </div>
                    <div className="col-md-4" style={{borderLeft: '1px solid #e5e5e5'}}>
                      <div
                        className="row"
                        style={{
                          borderBottom: '1px solid #e5e5e5',
                          marginLeft: '0px',
                        }}>
                        <div className="form-group custom-tab col-md-12">
                          <span>{row.codigo}</span>
                        </div>
                        {row.ownId && (
                          <div className="form-group custom-tab col-md-12">
                            <span>Protocolo: {row.ownId}</span>
                          </div>
                        )}

                        <div className="form-group custom-tab col-md-12">
                          <label>Empresa</label>
                          <br />
                          <span>{empresa.nomeFantasia}</span>
                        </div>
                        <div className="form-group custom-tab col-md-6">
                          <label>Atendente</label>
                          <br />
                          <span className="">{usuario}</span>
                        </div>
                        <div className="form-group custom-tab col-md-6">
                          <label>F.Pag</label>
                          <br />
                          <span className="">{formaPagamento.descricao}</span>
                        </div>
                      </div>

                      <div className="row" style={{marginLeft: '0px'}}>
                        <div className="form-group custom-tab col-md-12">
                          <label>Nome Prestador</label>
                          <br />
                          <span>
                            {respostaAceita ? respostaAceita.parceiro.nomeFantasia : '-'}
                          </span>
                        </div>
                        {hasReboqueMeProfile() && (
                          <div className="form-group custom-tab col-md-12">
                            <label>Telefone</label>
                            <br />

                            {respostaAceita.parceiro.telefoneCelular ? (
                              <span>
                                <DialAgent
                                  phoneNumber={respostaAceita.parceiro.telefoneCelular}
                                />
                                <CopyToClipboard
                                  textToBeCopied={respostaAceita.parceiro.telefoneCelular}
                                  title={respostaAceita.parceiro.telefoneCelular}
                                  style={{marginLeft: 5}}
                                />
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                        <div className="form-group custom-tab col-md-12">
                          <label>Avaliação</label>
                          <br />

                          <Rate
                            avaliado={!!avaliacaoCliente}
                            stars={avaliacaoCliente ? avaliacaoCliente.nota : 0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            )}
          </Tab>
          <Tab eventKey={3} title="Timeline">
            {tabIndex === 3 && (
              <div>
                <TimelineTab row={row} />
              </div>
            )}
          </Tab>
        </Tabs>
      </CustomModalWithoutTabIndex>
    );
  }
}

ChoiceModal.defaultProps = {
  title: 'Autorizar documento',
  tabIndex: 1,
  row: {},
};

ChoiceModal.propTypes = {
  handleDecision: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  handleChangeTabIndex: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  choices: state.dashboard.documentsAssets,
  documentsFetching: state.dashboard.documentsFetching,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDocumentsToApproval,
      getDocumentAssetsFromSolicitation,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceModal);
