import {
  BASE_LIST_CHANGED,
  FILTER_BASE_TEXT,
  BASE_HISTORY_FETCHED,
  BASE_BALANCE_FETCHED,
  BASE_BALANCE_FETCHING,
  BASE_LAST_CONCLUDED_FETCHED,
  BASE_LAST_CONCLUDED_FETCHING,
  BASE_LAST_LOST_FETCHED,
  BASE_LAST_LOST_FETCHING,
  BASE_IMAGES_FETCHED,
  BASE_IMAGES_FETCHING,
  CURRENT_BASE_FETCHED,
  FILTER_TEXT_SAVED,
  BASE_LIST_IS_FETCHING,
  BASE_COLLECTION_CHANGED,
  NEW_INVOICE_REQUESTED,
  CHANGE_BASE_ADDRESS,
  RESET_CURRENT_BASE,
  CURRENT_BASE_IS_FETCHING,
} from '../actions/base_actions';

const initialState = {
  baseBalance: [],
  baseBalanceFetching: false,
  baseListFetching: false,
  baseLastConcluded: [],
  baseLastConcludedFetching: false,
  baseLastLost: [],
  baseLastLostFetching: false,
  baseImagesFetching: false,
  baseImages: [],
  currentBase: {
    contato: null,
    nomeFantasia: null,
    razaoSocial: null,
    documento: null,
    tipoDocumento: null,
    telefoneFixo: null,
    telefoneCelular: null,
    codigo: null,
    email: null,
    login: null,
    chavePix: null,
    status: {
      codigo: null,
      descricao: null,
    },
    endereco: {
      logradouro: null,
      complemento: null,
      bairro: null,
      cidade: null,
      numero: null,
      cep: null,
      pais: null,
      estado: null,
      longitude: 0,
      latitude: 0,
    },
    contaBancaria: {
      bankName: null,
      bankNumber: null,
      agencyNumber: null,
      agencyCheckNumber: null,
      accountNumber: null,
      accountCheckNumber: null,
      holderFullname: null,
      holderTypeDocument: null,
      holderNumberDocument: null,
    },
    tototalcount: null,
  },
  isfetchingNewInvoice: false,
};

const base_reducer = (state = initialState, action) => {
  switch (action.type) {
    case BASE_LIST_CHANGED:
      return {...state, baseList: action.value};
    case FILTER_BASE_TEXT:
      return {...state, baseFilter: action.value};
    case BASE_HISTORY_FETCHED:
      return {...state, baseHistory: action.value};
    case BASE_BALANCE_FETCHED:
      return {...state, baseBalance: action.value};
    case BASE_BALANCE_FETCHING:
      return {...state, baseBalanceFetching: action.value};
    case BASE_LAST_CONCLUDED_FETCHED:
      return {...state, baseLastConcluded: action.value};
    case BASE_LAST_CONCLUDED_FETCHING:
      return {...state, baseLastConcludedFetching: action.value};
    case BASE_LAST_LOST_FETCHED:
      return {...state, baseLastLost: action.value};
    case BASE_LAST_LOST_FETCHING:
      return {...state, baseLastLostFetching: action.value};
    case BASE_IMAGES_FETCHED:
      return {...state, baseImages: action.value};
    case BASE_IMAGES_FETCHING:
      return {...state, baseImagesFetching: action.value};
    case BASE_LIST_IS_FETCHING:
      return {...state, baseListFetching: action.value};
    case BASE_COLLECTION_CHANGED:
      return {...state, baseCollection: action.value};
    case RESET_CURRENT_BASE:
      return {...state, currentBase: {...initialState.currentBase}};
    case CURRENT_BASE_IS_FETCHING:
      return {...state, currentBase: {...initialState.currentBase, isFetching: true}};
    case CURRENT_BASE_FETCHED:
      return {
        ...state,
        currentBase: {...initialState.currentBase, ...action.value, isFetching: false},
      };
    case FILTER_TEXT_SAVED:
      return {...state, savedFilter: action.value};
    case CHANGE_BASE_ADDRESS:
      return {...state, currentBase: {...state.currentBase, ...action.value}};

    /*
        case BASE_INSTANCE_CHANGED:
          let newList = state.baseList.map( oldBase => {
            return oldBase.codigo === action.baseId? action.baseData : oldBase
          });

          return {
            ...state,
            baseList: newList
          }
    */
    case NEW_INVOICE_REQUESTED:
      return {...state, isfetchingNewInvoice: action.value};
    default:
      return state;
  }
};

export default base_reducer;
