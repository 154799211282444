import React from "react";
import { Field } from "redux-form";
import { renderField } from "./FormValidation";

const SelectField = ({
  name,
  label,
  validate,
  placeholder,
  icon,
  children,
}) => {
  return (
    <Field
      name={name}
      type="select"
      component={renderField}
      label={label}
      validate={validate}
      placeholder={placeholder}
      icon={icon}
    >
      {children}
    </Field>
  );
};
export default SelectField;
