import React, { Component } from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

const InputGroup = ({ icon, children }) => {
  return (
    <div className="input-group">
      <span className="input-group-addon">
        <i className={icon} />
      </span>
      {children}
    </div>
  );
};

const renderDatePicker = ({
  input,
  label,
  icon,
  meta: { touched, error, warning },
}) => {
  if (typeof input.value === 'string') {
    input.value = input.value.includes(' ')
      ? input.value.split(' ')[0]
      : input.value;
  }

  return (
    <div
      className={classnames({
        'has-error': touched && error,
        'has-success': touched && !warning && !error,
      })}
    >
      {label && <label>{label}</label>}
      {icon ? (
        <InputGroup icon={icon}>
          <InputMask {...input} className="form-control" mask="99/99/9999" />
        </InputGroup>
      ) : (
        <InputGroup>
          <InputMask {...input} className="form-control" mask="99/99/9999" />
        </InputGroup>
      )}
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};

const DateField = (props) => {
  return <Field {...props} component={renderDatePicker} />;
};

export default DateField;
