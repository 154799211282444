import { doGet, doPost, doDelete } from "../api/base/base_api_helper";
import { BASE_URL } from "../config";
import axios from "axios";
import { toastError, toastSuccess } from "./toast_actions";

export function postImage(solicitationCode, files, typeDocument) {
  return dispatch => {
    if (!files) return;

    const promiseChain = [];
    files.forEach((f, i) => {
      let formData = new FormData();
      formData.append("image", f, f.name);
      promiseChain.push(
        axios.post(
          `${BASE_URL}solicitations/${solicitationCode}/documents/${typeDocument}`,
          formData,
          {
            headers: {
              "Content-Type":
                "multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E"
            },
            auth: {
              username: localStorage.getItem("username"),
              password: localStorage.getItem("password")
            }
          }
        )
      );
    });

    Promise.all(promiseChain)
      .then(resp => {
        resp.forEach(item => {
          if (!item.data) return;
          if (item.data.sucesso) {
            toastSuccess(item.data.mensagemCliente)
          } else {
            toastError(item.data.mensagemCliente)
          }
        })
     
        dispatch(getSolicitationDocuments(solicitationCode));
      })
      .catch(e => {
        toastError("Erro ao enviar as imagens.");
        console.log(e);
      });
  };
}


export function addImageToChecklist(solicitationCode, files, typeDocument, typeChecklist) {
  return dispatch => {
    if (!files) return;

    const promiseChain = [];
    files.forEach((f, i) => {
      let formData = new FormData();
      formData.append("image", f, f.name);
      promiseChain.push(
        axios.post(
          `${BASE_URL}documents/checklist/${solicitationCode}/${typeDocument}/${typeChecklist}`,
          formData,
          {
            headers: {
              "Content-Type":
                "multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E"
            },
            auth: {
              username: localStorage.getItem("username"),
              password: localStorage.getItem("password")
            }
          }
        )
      );
    });

    return Promise.all(promiseChain)
      .then(resp => {
        return resp.map(item => {
          if (!item.data) return;
          if (item.data.sucesso) {
            toastSuccess(item.data.mensagemCliente)
          } else {
            toastError(item.data.mensagemCliente)
          }
          return item.data;
        })
      })
      .catch(e => {
        toastError("Erro ao enviar as imagens.");
        console.log(e);
      });
  };
}

export const ChecklistTypes = {
  "ENTREGA": "CHECKLISTENTREGA",
  "RETIRADA": "CHECKLISTRETIRADA"
}

export function clearChecklistDocuments() {
  return {
    type: "CLEAR_CHECKLIST_DOCUMENTS"
  }
}

export function getChecklistByType(solicitationCode, checklistType) {
  return dispatch => {
    doGet(`documents/checklist/${solicitationCode}/${checklistType}`)
      .then(resp => dispatch({ 
        type: 'CHECKLIST_DOCUMENTS_FETCHED',
        checklistType,
        value: resp.listChecklist? resp.listChecklist: []
      }))
      .catch(e => toastError("Um erro acessar as checklist"));

  }
}


export function getSolicitationDocuments(solicitationCode) {
  return dispatch => {
    if (!solicitationCode) return;

    dispatch({
      type: "SOLICITATION_IMAGES_FETCHING",
      payload: true
    });
    doGet(`solicitations/${solicitationCode}/documents/`)
      .then(resp => {
        dispatch([
          {
            type: "SOLICITATION_IMAGES_FETCHING",
            payload: false
          },
          {
            type: "SOLICITATION_IMAGES_FETCHED",
            payload: resp.listDocumentos
          }
        ]);
      })
      .catch(e => {
        toastError("Erro acessar as imagens no servidor");
      });
  };
}

export function deleteSolicitationDocuments(solicitationCode, documentCode) {
  return (dispatch, getState) => {
    let codigo = "";
    if (getState().followup.currentSolicitation)
      codigo = getState().followup.currentSolicitation.codigo;
    else if (getState().relatorio.selectedSolicitation)
      codigo = getState().relatorio.selectedSolicitation.codigo;
    doDelete(`solicitations/${codigo}/documents/${documentCode}`)
      .then(resp => {
        toastSuccess("Imagem deletada com sucesso");
        dispatch(getSolicitationDocuments(solicitationCode));
      })
      .catch(e => {
        toastError(e.mensagemCliente);
        console.log(e);
      });
  };
}
