import {PARTNER_LIST_CHANGED, PARTNER_INSTANCE_CHANGED, PARTNER_EDIT, GET_CLOSEST_PARTNERS} from '../actions/partner_actions'

const INITIAL_STATE = {
  isLoading: true,
  currentPartner:{
    veiculo:{
      listaServicos:[]
    }
  },
  fantasmas: false
}

const partner_reducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case PARTNER_LIST_CHANGED:
      return {
        ...state,
        partnerList: action.value
      }
    case PARTNER_EDIT:
      return {
        ...state,
        partner_edit: action.value
      }
    case GET_CLOSEST_PARTNERS:
      return {
        ...state,
        closestPartners: action.value,
        isLoading: false
      }
    case `LOADING_CLOSEST_PARTNERS`:
      return {
        ...state,
        isLoading: action.value
      }
    case `REMOVE_CLOSEST_PARTNERS`:
      if (!state.closestPartners) return state;

      return {
        ...state,
        closestPartners: state.closestPartners.filter(partner => partner.associado.codigo !== action.value),
      }
    case `PARTNER_CLICK`:
      return{
        ...state,
        partner: action.value 
      }
    case `CURRENT_PARTNER_FETCHED`:
      return{
        ...state,
        currentPartner: action.value 
      }
    
    case `TOGGLE_PHANTOM_STATE`:
      return {
        ...state,
        fantasmas: action.value
      }
      /*
    case PARTNER_INSTANCE_CHANGED:
      let newList = state.partnerList.map( oldPartner => {
        return oldPartner.codigo === action.partnerId? action.partnerData : oldPartner
      });

      return {
        ...state,
        partnerList: newList
      }
*/

    default:
      return state
  }
}

export default  partner_reducer
