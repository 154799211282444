import React, { Component, PureComponent } from 'react'
import FileUploader from '../fileUploader/FileUploader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { postImage, getBaseDocuments, deleteBaseDocuments } from "../../../actions/base_actions";
import { Tabs, Tab } from 'react-bootstrap'


class BaseUpload extends Component {
    constructor(props) {
        super(props)
        this.state = { key: 1 }
        this.handleFile = this.handleFile.bind(this)
        this.handleTabs = this.handleTabs.bind(this)
        this.getDocumentListByType = this.getDocumentListByType.bind(this)
        this.deleteDocument = this.deleteDocument.bind(this)
    }
    componentDidMount() {
        const { context, baseCode, getBaseDocuments } = this.props
        getBaseDocuments(baseCode)
    }

    handleFile(files, type) {
        const { postImage, baseCode } = this.props
        postImage(baseCode, files, type)
    }

    handleTabs(key) {
        this.setState({
            key
        })
    }
    getDocumentListByType(baseImages, type) {
        return (baseImages.filter((d, i) => d.tipoDocumento.codigo === type))
    }

    deleteDocument(documentConde) {
        const { baseCode, deleteBaseDocuments } = this.props
        deleteBaseDocuments(baseCode, documentConde)
    }

    render() {
        const { baseCode, baseImages, baseImagesFetching } = this.props
        return (
            <div>
                <Tabs className="checklistTab" id="service-tabs" activeKey={this.state.key} onSelect={this.handleTabs}>
                    <Tab eventKey={1} title="Documento Base">
                        <FileUploader
                            key={`baseUploader-DOCUMENTOSBASE`}
                            upload={this.handleFile}
                            type={'DOCUMENTOSBASE'}
                            documentList={this.getDocumentListByType(baseImages, 'DOCUMENTOSBASE')}
                            baseImagesFetching={baseImagesFetching}
                            deleteImage={this.deleteDocument}
                        />
                    </Tab>
                    <Tab eventKey={2} title="Documento Veículos">
                        <FileUploader
                            key={`baseUploader-DOCUMENTOSVEICULOSBASE`}
                            upload={this.handleFile}
                            type={'DOCUMENTOSVEICULOSBASE'}
                            documentList={this.getDocumentListByType(baseImages, 'DOCUMENTOSVEICULOSBASE')}
                            baseImagesFetching={baseImagesFetching}
                            deleteImage={this.deleteDocument}
                        />
                    </Tab>

                    <Tab eventKey={3} title="Documento Motoristas">
                        <FileUploader
                            key={`baseUploader-DOCUMENTOSMOTORISTASBASE`}
                            upload={this.handleFile}
                            type={'DOCUMENTOSMOTORISTASBASE'}
                            documentList={this.getDocumentListByType(baseImages, 'DOCUMENTOSMOTORISTASBASE')}
                            baseImagesFetching={baseImagesFetching}
                            deleteImage={this.deleteDocument}
                        />
                    </Tab>
                    <Tab eventKey={4} title="Foto Veículos">
                        <FileUploader
                            key={`baseUploader-FOTOSVEICULOSBASE`}
                            upload={this.handleFile}
                            type={'FOTOSVEICULOSBASE'}
                            documentList={this.getDocumentListByType(baseImages, 'FOTOSVEICULOSBASE')}
                            baseImagesFetching={baseImagesFetching}
                            deleteImage={this.deleteDocument}
                        />
                    </Tab>                    
                </Tabs>
            </div>)
    }
}

const mapStateToProps = state => (
    {
        baseImages: state.base.baseImages,
        baseImagesFetching: state.base.baseImagesFetching
    }
)

const mapDispatchToProps = dispatch => bindActionCreators({ postImage, getBaseDocuments, deleteBaseDocuments }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BaseUpload)