import React from 'react';

const NumberBadge = ({number, color}) => {
    return (
      <span className={"animated tada label pull-right label-"+color} style={{display: (number > 0 ? "block" : "none")}}>
        {number}
      </span>
    )
}

export default NumberBadge
