import React, { Component } from 'react'

import _ from 'underscore'

import { connect } from 'react-redux'

import { loadBaseList } from '../../actions/base_actions';


class GriddlePager extends Component {
    constructor( props ) {
        super( props )
        this.pageChange = this.pageChange.bind( this )
        this.state = {
            page: 0
        }
    }
    pageChange (event){
        const { loadBaseList } = this.props
        const { page } = this.state
        const nextPage = page + 1
        this.props.setPage(nextPage)
        loadBaseList(nextPage)
    }
    render (){
        let previous = <li key="griddleLi" onClick={() => {
            this.props.previous()
            this.props.loadBaseList( this.state.page )
            }} className="previous page-item disabled"><a className="page-link">{this.props.previousText}</a></li>;
        let next = "";

        if(this.state.page > 0){
            previous = <li onClick={() => {
                this.props.previous()
                this.state.page !== 1
                ? this.props.loadBaseList( this.state.page -1 )
                : null
                this.state.page !== 1
                ? this.setState({
                    page: this.state.page - 1
                })
                : null
                }} className="previous page-item"><a className="page-link">{this.props.previousText}</a></li>
        }

            next = <li onClick={( ) => {
                //this.pageChange()           
                this.props.next()
                this.props.loadBaseList( this.state.page + 1 )
                this.setState({
                    page: this.state.page + 1
                })
                }} className="next page-item"><a className="page-link">{this.props.nextText}</a></li>

            const options = [<li key="griddleName" className={`active page-item`}><a className="page-link" >{this.state.page + 1}</a></li>]

        return (
            <div className="row">
                
                <ul className="col-xs-8-offset-2 pagination">
                    {previous}
                    {options}
                    {next}
                </ul>
            </div>
        )
    }
}

const mapDispatchToProps = ( dispatch, ownProps ) => {
    return {
      loadBaseList: ( pageNumber ) => {
        dispatch( load( pageNumber ) )
      }
    }
  }
  
  const mapStateToProps = (state, ownProps) => {
    return  {
      ...ownProps,
      baseList: state.base.baseList
    }
  }

export default connect( mapStateToProps, mapDispatchToProps ) (GriddlePager)