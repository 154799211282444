import { BASE_URL } from "../../config";
import request from "superagent";
import { doGet, doPost, doPut } from "../../api/base/base_api_helper";
import { doCloseModal } from "../common/CustomModal";
import moment from "moment";
import swal from "sweetalert";
import { toastError, toastSuccess } from "../../actions/toast_actions";
import axios from "axios";

export async function createBalance(body) {
  return async (dispatch) => {
    return doPost("fechamentos", body)
      .then((balance) => {
        if (balance.mensagemCliente !== "Sucesso")
          dispatch([
            { type: "BALANCE_DETAILS_ERROR", payload: balance.mensagemCliente },
            toastError(balance.mensagemCliente || e.status),
          ]);
        else {
          dispatch([
            { type: "BALANCE_ID_FETCHED", payload: balance.fechamento.codigo },
            toastSuccess("Fechamento criado"),
          ]);
        }
      })
      .finally(() => {
        dispatch([getAllBalances(), closeModal("CONTEXT_BASE")]);
      });
  };
}
function closeModal(context) {
  doCloseModal(context);
}

export function clearInvoices() {
  return {
    type: "BALANCE_INVOICES_FETCHED",
    payload: [],
  };
}

export function clearBalanceResume() {
  return { type: "BALANCE_RESUME_CLEAR" };
}

export function getInvoices(id) {
  return (dispatch) => {
    dispatch({ type: "BALANCE_INVOICES_FETCHING", payload: true });
    doGet("fechamentos/" + id)
      .then((balanceDetails) => {
        dispatch([
          {
            type: "BALANCE_INVOICES_FETCHED",
            payload: balanceDetails.fechamento.listInvoices,
          },
          {
            type: "BALANCE_RESUME_FETCHED",
            payload: getResume(balanceDetails.fechamento),
          },
          { type: "BALANCE_INVOICES_FETCHING", payload: false },
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: "BALANCE_INVOICES_FETCHING", payload: false },
          { type: "BALANCE_INVOICES_ERROR", payload: e },
          toastError(e.mensagemCliente || e.status),
        ]);
      });
  };
}
function getResume(balance) {
  return {
    atendimentos: balance.atendimentos,
    boletoGerado: balance.boletoGerado,
    resultado: balance.resultado,
    valorAPagar: balance.valorAPagar,
    valorAReceber: balance.valorAReceber,
  };
}
export function getAllBalances() {
  return (dispatch) => {
    doGet("fechamentos/")
      .then((balancesList) => {
        if (!balancesList.sucesso)
          dispatch([
            { type: "BALANCES_ERROR", payload: balancesList.mensagemCliente },
            toastError(e.mensagemCliente || e.status),
          ]);
        else {
          dispatch({
            type: "BALANCES_FETCHED",
            payload: balancesList.listFechamentos.sort((a, b) => {
              const dateA = a.periodoInicio.substr(0, 10).split("/");
              const dateB = b.periodoInicio.substr(0, 10).split("/");
              return (
                new Date(dateB[2], dateB[1] - 1, dateB[0]) -
                new Date(dateA[2], dateA[1] - 1, dateA[0])
              );
            }),
          });
        }
      })
      .catch((e) => {
        dispatch([
          { type: "BALANCES_ERROR", payload: e.mensagemCliente },
          toastError(e.mensagemCliente || e.status),
        ]);
      });
  };
}

export function setInvoiceBilletLink(linkBoleto, balanceId, invoiceId) {
  return (dispatch) => {
    doPut(
      "fechamentos/" + balanceId + "/invoices/" + invoiceId + "/updateLinks",
      { linkBoleto }
    )
      .then((resp) => {
        dispatch([
          { type: "INVOICE_BILLET_LINK_PUT", payload: invoiceId },
          toastSuccess("Inserido com sucesso"),
          getInvoices(balanceId),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: "INVOICE_BILLET_LINK_PUT_ERROR", payload: e },
          toastError(e.mensagemCliente || e.status),
        ]);
      });
  };
}

export function setInvoiceNfLink(image, balanceId, invoiceId) {
  return (dispatch) => {
    if (!image) return;
    let formData = new FormData();
    formData.append("image", image, image.name);
    axios
      .put(
        BASE_URL +
          "fechamentos/" +
          balanceId +
          "/invoices/" +
          invoiceId +
          "/uploadNF",
        formData,
        {
          headers: {
            "Content-Type":
              "multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E",
          },
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((resp) => {
        dispatch([
          { type: "INVOICE_NOTA_LINK_PUT", payload: resp },
          toastSuccess("Inserido com sucesso"),
          getInvoices(balanceId),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: "INVOICE_NOTA_LINK_PUT_ERROR", payload: resp },
          toastError(e.mensagemCliente || e.status),
        ]);
      });
  };
}

export const InvoiceConstants = {
  NF_SERVICO: "servico",
  NF_PRESTADOR: "prestador",
  INVOICE_STATUS: {
    IN_PROGRESS: "INVOICE.IN_PROGRESS",
    WAITING_SEND: "INVOICE.WAITING_SEND",
    NOT_AUTHORIZED: "INVOICE.NOT_AUTHORIZED",
    ISSUED: "INVOICE.ISSUED",
    CANCELLED: "INVOICE.CANCELLED",
    NOT_CANCELLED: "INVOICE.NOT_CANCELLED",
  },
  INVOICE_STATUS_CHIPS: {
    "INVOICE.IN_PROGRESS": { title: "Em progresso", color: "badge-primary" },
    "INVOICE.WAITING_SEND": { title: "Aguarda envio", color: "badge-warning" },
    "INVOICE.ISSUED": { title: "Emitido", color: "badge-primary" },
    "INVOICE.CANCELLED": { title: "Cancelado", color: "badge-danger" },
    "INVOICE.NOT_AUTHORIZED": { title: "Não autorizada", color: "badge-danger" },
    "INVOICE.NOT_CANCELLED": {
      title: "Não foi cancelado",
      color: "badge-primary",
    },
  },
};

function setTipoNFparam(tipoNF) {
  if (tipoNF === InvoiceConstants.NF_SERVICO) {
    return "/PRESTADA";
  } else if (tipoNF === InvoiceConstants.NF_PRESTADOR) {
    return "/TOMADA";
  } else {
    return "/TOMADA";
  }
}

export function dismissNotaFiscal(balanceId, invoiceId, tipoNF) {
  return (dispatch) => {
    let tipo = setTipoNFparam(tipoNF);
    const url = `${BASE_URL}fechamentos/${balanceId}/invoices/${invoiceId}/uploadNF${tipo}`;
    axios
      .put(
        url,
        {},
        {
          headers: {
            "Content-Type":
              "multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E",
          },
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((resp) => {
        dispatch([
          { type: "INVOICE_NOTA_LINK_PUT", payload: resp },
          toastSuccess("A Nota Fiscal foi recusada."),
          getInvoices(balanceId),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: "INVOICE_NOTA_LINK_PUT_ERROR", payload: resp },
          toastError(e.mensagemCliente || e.status),
        ]);
      });
  };
}

export function setInvoiceNfLinkAdapter(image, balanceId, invoiceId, tipoNF) {
  return (dispatch) => {
    if (!image) return;

    let formData = new FormData();

    if (image != null) {
      formData.append("image", image, image.name);
    }

    let tipo = setTipoNFparam(tipoNF);

    axios
      .put(
        BASE_URL +
          "fechamentos/" +
          balanceId +
          "/invoices/" +
          invoiceId +
          "/uploadNF" +
          tipo,
        formData,
        {
          headers: {
            "Content-Type":
              "multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E",
          },
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((resp) => {
        dispatch([
          { type: "INVOICE_NOTA_LINK_PUT", payload: resp },
          toastSuccess("Inserido com sucesso"),
          getInvoices(balanceId),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: "INVOICE_NOTA_LINK_PUT_ERROR", payload: resp },
          toastError(e.mensagemCliente || e.status),
        ]);
      });
  };
}

export function sendEmails(balanceId) {
  return (dispatch) => {
    swal(
      {
        title: "Deseja enviar os emails?",
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
        showLoaderOnConfirm: true,
        type: "warning",
      },
      () => {
        doPut("fechamentos/" + balanceId + "/send")
          .then((resp) => {
            dispatch([
              { type: "EMAILS_SENT", payload: resp },
              () => {
                swal({
                  title: `Emails Enviados com sucesso`,
                  closeOnConfirm: false,
                  showLoaderOnConfirm: true,
                  type: "success",
                });
              },
              getInvoices(balanceId),
            ]);
          })
          .catch((e) => {
            dispatch([
              { type: "EMAILS_SENT_ERROR", payload: e },
              toastError(e.mensagemCliente || e.status),
            ]);
          });
      }
    );
  };
}

export function sendIndividualEmail(balanceId, invoiceId, nomeFantasia, email) {
  return (dispatch, getState) => {
    swal(
      {
        title: `Deseja enviar email?`,
        text: `Nome: ${nomeFantasia}\n Email: ${email}`,
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        type: "warning",
      },
      () => {
        doPut(`fechamentos/${balanceId}/invoices/${invoiceId}/send`)
          .then((resp) => {
            const invoices = getState().balances.invoices;
            invoices.forEach((invoice, i) => {
              if (invoice.codigo === invoiceId) invoices[i].emailEnviado = true;
            });
            dispatch([
              { type: "EMAIL_SENT", payload: invoiceId },
              { type: "BALANCE_INVOICES_FETCHED", payload: invoices },
              toastSuccess("Email enviado com sucesso"),
            ]);
          })
          .catch((e) => {
            dispatch([
              { type: "EMAIL_SENT_ERROR", payload: e },
              toastError(e.message || e.status),
            ]);
          })
          .finally(() => {
            swal.close();
          });
      }
    );
  };
}

export function closeInvoice(
  invoiceId,
  data,
  balanceId,
  descontos,
  acrescimos
) {
  return (dispatch) => {
    doPut(`lancamentos/${invoiceId}/processed`, { data, descontos, acrescimos })
      .then((resp) =>
        dispatch([
          { type: "INVOICE_CLOSED", payload: invoiceId },
          doCloseModal("CONTEXT_BASE"),
          toastSuccess("Sucesso"),
          getInvoices(balanceId),
        ])
      )
      .catch((e) =>
        dispatch([
          { type: "INVOICE_CLOSED_ERROR", payload: invoiceId },
          toastError(e.mensagemCliente || "Error"),
        ])
      );
  };
}

export function billetButtonActions(balanceId, invoiceId, billetLink) {
  return (dispatch) =>
    swal(
      {
        title: "Informe a quantidade de dias",
        type: "input",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        animation: "slide-from-top",
        inputPlaceholder: "Número de dias",
      },
      (inputValue) => {
        if (inputValue === false) return false;

        if (inputValue === "") {
          swal.showInputError("Campo obrigatório");
          return false;
        }
        doPut(
          "fechamentos/" +
            balanceId +
            "/invoices/" +
            invoiceId +
            "/boleto/" +
            inputValue
        )
          .then((resp) =>
            swal(
              "Novo boleto gerado!",
              "acrescentados " + inputValue + " dias",
              "success"
            )
          )
          .catch((e) => {
            console.error(e);
            swal("Erro", e.mensagemCliente, "error");
          });
      }
    );
}

export function sendNewBillet(lancamentoId) {
  return (dispatch) =>
    swal(
      {
        title: "Enviar segunda via do boleto?",
        type: "warning",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        animation: "slide-from-top",
      },
      (inputValue) => {
        if (!inputValue) return false;

        doPut("lancamentos/" + lancamentoId + "/sendBoleto/")
          .then((resp) => {
            console.log(resp);
            if (resp.codigoRetorno === 200)
              swal("Boleto Enviado!", resp.mensagemCliente, "success");
            //toastSuccess('sucesso')
            else swal("Atenção!", resp.mensagemCliente, "warning");
            //toastError("Error")
          })

          .catch((e) => {
            console.error(e);
            swal("Erro", e.mensagemCliente, "error");
          });
      }
    );
}
export function generateAllBillets(balanceId) {
  return (dispatch) =>
    swal(
      {
        title: "Deseja gerar todos os boletos?",
        type: "warning",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        animation: "slide-from-top",
      },
      () => {
        doPut("fechamentos/" + balanceId + "/boletos/")
          .then((resp) => {
            console.log(resp);
            if (resp.codigoRetorno === 200)
              swal("Boleto gerados!", resp.mensagemCliente, "success");
            else swal("Atenção!", resp.mensagemCliente, "danger");
          })

          .catch((e) => {
            console.error(e);
            swal("Erro", e.mensagemRetorno, "error");
          });
      }
    );
}
