import React from "react";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { CustomModalWithoutTabIndex } from "../common/CustomModal";
import {
  getChecklistByType,
  ChecklistTypes,
  clearChecklistDocuments,
} from "../../actions/checkListUploadActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChecklistTab from "./ChecklistTab";

class ChecklistModal extends React.Component {
  fetchCheckList = () => {
    const { getChecklistByType, solicitationCode } = this.props;

    getChecklistByType(solicitationCode, ChecklistTypes.ENTREGA);
    getChecklistByType(solicitationCode, ChecklistTypes.RETIRADA);
  };

  componentDidMount() {
    this.fetchCheckList();
  }

  componentWillUnmount() {
    this.props.clearChecklistDocuments();
  }

  render() {
    const {
      CHECKLISTENTREGA,
      CHECKLISTRETIRADA,
    } = this.props.checklistDocuments;

    return (
      <CustomModalWithoutTabIndex
        context={this.props.context}
        className="tabs-container"
        title={this.props.title}
      >
        <Tabs>
          <TabList>
            <Tab>Check-in</Tab>
            <Tab>Check-out</Tab>
          </TabList>
          <TabPanel>
            <ChecklistTab
              checklist={CHECKLISTRETIRADA}
              type={ChecklistTypes.RETIRADA}
              solicitationCode={this.props.solicitationCode}
            />
          </TabPanel>
          <TabPanel>
            <ChecklistTab
              checklist={CHECKLISTENTREGA}
              type={ChecklistTypes.ENTREGA}
              solicitationCode={this.props.solicitationCode}
            />
          </TabPanel>
        </Tabs>
      </CustomModalWithoutTabIndex>
    );
  }
}

const mapStateToProps = (state) => ({
  checklistDocuments: state.checkList.checklistDocuments,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getChecklistByType, clearChecklistDocuments }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistModal);
