
export function onOptionSelected(context, selectedValue, doAfterSuccess = ()=>{} ) {
    return (dispatch, getState) => {
      dispatch( {type: INPUT_OPTION_SELECTED, context: context, value: selectedValue} )
      dispatch( {type: INPUT_OPTIONS_CHANGED, context: context, value: [] } )
      doAfterSuccess(selectedValue)
    }
}

export function onInputCleared(context, doAfterSuccess = ()=>{} ) {
  return (dispatch, getState) => {
    dispatch( {type: INPUT_CLEARED, context: context} )
    dispatch({ type: SERVICE_VEHICLE_SELECTED, veiculo: {}} )
    doAfterSuccess()
  }
}

export const INPUT_OPTION_SELECTED = 'INPUT_OPTION_SELECTED'
export const INPUT_CLEARED = 'INPUT_CLEARED'
export const INPUT_VALUE_CHANGED = 'INPUT_VALUE_CHANGED'
export const INPUT_OPTIONS_CHANGED = 'INPUT_OPTIONS_CHANGED'
export const INPUT_INTERNAL_FIELD_CHANGED = "INPUT_INTERNAL_FIELD_CHANGED"
export const SERVICE_VEHICLE_SELECTED = "SERVICE_VEHICLE_SELECTED"
