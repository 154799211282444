
import {getCustomerVehicles} from '../api/customer_helper'
import {INPUT_VALUE_CHANGED, INPUT_OPTIONS_CHANGED} from './autocomplete_actions'


export function onValueChanged(context, customerCode, newPlate, doAfterSuccess = ()=>{}, auth ) {
  return (dispatch, getState) => {
    dispatch( {type: INPUT_VALUE_CHANGED, context: context, value: newPlate} )
    doAfterSuccess(newPlate)

    if(customerCode)
    {
      getCustomerVehicles({id: customerCode}, auth).then( (results) => {
        let vehicleList =results.listVehicles ? results.listVehicles.map((vehicle) => {
          return {codigo: vehicle.codigo, name: vehicle.placa, ...vehicle}
        }):[]

        dispatch( {type: INPUT_OPTIONS_CHANGED, context: context, value: vehicleList} )
      })
    }
    else {
      dispatch( {type: INPUT_OPTIONS_CHANGED, context: context, value: []} )
    }
  }
}
