import React from 'react'

const mapData = (data) => (
    data.map((d, i) => (
        <tr key={i}>
            <td className="text-center">{i + 1}</td>
            <td> {d.city}</td>
            <td> {d.amount}</td>
            <td className="text-center"><span className="label label-primary" style={{
                color: "black",
            }}>{d.percentage}%</span></td>
        </tr>
    ))
)

const BasicList = ({ data }) => {

    return (
        <table className="table table-hover margin bottom">
            <thead>
                <tr>
                    <th style={{ width: "1%" }} className="text-center">No.</th>
                    <th>Cidade</th>
                    <th className="text-center">Quantidade</th>
                    <th className="text-center">Porcentagem</th>
                </tr>
            </thead>
            <tbody>{
                mapData(data)
            }
            </tbody>
        </table>)
}

export default BasicList