import {NOTIFICATIONS_UPDATE} from '../actions/notification_actions'

const notification_reducer = (state = {pending: []}, action) => {
  switch(action.type){
    case NOTIFICATIONS_UPDATE:
      return {
        ...state,
        pendingNotifications: action.pendingNotifications
      }
    default:
      return state
  }
}

export default  notification_reducer
