import React, { Component } from 'react'
import { reduxForm, Field, change } from 'redux-form'
import { renderField, cepV } from "./FormValidation";
import cep from 'cep-promise'



export default class CepField extends Component {

    constructor(props) {
        super(props) 
        this.getAddress = this.getAddress.bind(this)       
    }

    getAddress(e){
        const { street, neighborhood, city, state, change } = this.props
        let { value } = e.target
        value = value.match(/[0-9]/g) ? value.match(/[0-9]/g).join('') : ''
        if(value.length === 8 ){
            cep(value).then(resp=>{
                change(street, resp.street)
                change(neighborhood, resp.neighborhood)
                change(city, resp.city)
                change(state, resp.state)
            })
        }
    }

    render() {
        const { name, label, validate, placeholder, icon } = this.props

        return (
            <Field
                name={name}
                type='tel'
                component={renderField}
                label={label}
                validate={[...validate, cepV]}
                placeholder={placeholder}
                icon={icon}
                mask={'99.999-999'}
                onChange={this.getAddress}
            />)
    }
}




