import React from 'react';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import PropTypes from 'prop-types';
import moment from 'moment';

import RouteMap from '../../common/RouteMap';
import ClocklIndicator from '../../common/ClockIndicator';

const iconEnabled = {
  background: '#25f4af',
  color: '#fff',
};

const iconDisabled = {
  background: '#5c40ff',
  color: '#fff',
};

function TimelineTab({row}) {
  function calcularDiferencaEmMinutos(data1, data2) {
    const dataInicial = moment(data1, 'DD/MM/YYYY HH:mm:ss');
    const dataFinal = moment(data2, 'DD/MM/YYYY HH:mm:ss');
    // Verifica se as datas são iguais
    if (dataFinal.isSame(dataInicial)) {
      return 0;
    }
    // Calcula a diferença em minutos
    const diffMinutos = dataFinal.diff(dataInicial, 'minutes');
    return diffMinutos;
  }
  const agendadoDiff =
    row.dataCadastroAgendamento && row.dataCadastro
      ? calcularDiferencaEmMinutos(row.dataCadastroAgendamento, row.dataCadastro)
      : null;
  const aceitoAbertoDiff =
    row.respostaAceita && row.dataCadastro
      ? calcularDiferencaEmMinutos(row.dataCadastro, row.respostaAceita.dataCadastro)
      : '-';
  const chegadaAceitoDiff =
    row.dataChegadadoParceiro && row.respostaAceita
      ? calcularDiferencaEmMinutos(row.respostaAceita.dataCadastro, row.dataChegadadoParceiro)
      : '';
  const conclusaoChegadaDiff =
    row.dataConcluida && row.dataChegadadoParceiro
      ? calcularDiferencaEmMinutos(row.dataChegadadoParceiro, row.dataConcluida)
      : '-';
  const requestOrigin =
    row.enderecoOrigem && row.enderecoOrigem.latitude
      ? {
          lat: parseFloat(row.enderecoOrigem.latitude),
          lng: parseFloat(row.enderecoOrigem.longitude),
        }
      : null;
  const requestDestination =
    row.enderecoDestino && row.enderecoDestino.latitude
      ? {
          lat: parseFloat(row.enderecoDestino.latitude),
          lng: parseFloat(row.enderecoDestino.longitude),
        }
      : null;

  return (
    <div>
      <div className="col-md-8" style={{padding: '8px'}}>
        <RouteMap
          id="relatorioMap"
          requestOrigin={requestOrigin}
          requestDestination={requestDestination}
          style={{height: '380px'}}
          partnerLocation={
            row.respostaAceita && row.respostaAceita.latitude
              ? {
                  lat: row.respostaAceita.latitude,
                  lng: row.respostaAceita.longitude,
                }
              : null
          }
        />
      </div>
      <div className="col-md-4" style={{marginTop: '45px'}}>
        <VerticalTimeline layout="1-column">
          {agendadoDiff ? (
            <span>
              <ClocklIndicator top={'30px'} text={agendadoDiff} />
              <ClocklIndicator top={'60px'} text={aceitoAbertoDiff} />
              <ClocklIndicator top={'120px'} text={chegadaAceitoDiff} />
              <ClocklIndicator top={'193px'} text={conclusaoChegadaDiff} />
            </span>
          ) : (
            <span>
              <ClocklIndicator top={'30px'} text={aceitoAbertoDiff} />
              <ClocklIndicator top={'90px'} text={chegadaAceitoDiff} />
              <ClocklIndicator top={'163px'} text={conclusaoChegadaDiff} />{' '}
            </span>
          )}

          {row.dataCadastroAgendamento && (
            <VerticalTimelineElement
              className="vertical-timeline-element--work time-line-enabled"
              iconStyle={iconEnabled}>
              <span>
                <strong>Aberto em</strong>
              </span>
              <br />
              <span>{`${row.dataCadastroAgendamento}`}</span>
            </VerticalTimelineElement>
          )}

          <VerticalTimelineElement
            className="vertical-timeline-element--work time-line-enabled"
            iconStyle={iconEnabled}>
            <span>
              {row.dataCadastroAgendamento ? (
                <strong>Agendado para</strong>
              ) : (
                <strong>Aberto em</strong>
              )}
            </span>
            <br />
            <span>{`${row.dataCadastro}`}</span>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work time-line-enabled"
            iconStyle={iconEnabled}>
            <span>
              <strong>Aceito em</strong>
            </span>
            <br />
            <span>{row.respostaAceita ? row.respostaAceita.dataCadastro : '-'}</span>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={
              row.dataChegadadoParceiro
                ? row.dataChegadadoParceiro
                  ? iconEnabled
                  : iconDisabled
                : iconDisabled
            }>
            <span>
              <strong>Chegada do Parceiro</strong>
            </span>
            <br />
            <span>
              {row.respostaAceita
                ? row.dataChegadadoParceiro
                  ? row.dataChegadadoParceiro
                  : `Prévia: ${row.respostaAceita.tempoEspera} minutos`
                : '-'}
            </span>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={iconEnabled}>
            <span>
              <strong>Conclusão</strong>
            </span>
            <br />
            <span>{row.dataConcluida}</span>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

TimelineTab.defaultProps = {
  row: {
    respostaAceita: null,
    enderecoDestino: null,
    enderecoOrigem: null,
    dataCadastro: '',
    dataChegadadoParceiro: null,
    dataConcluida: '',
  },
};

TimelineTab.propTypes = {
  row: PropTypes.shape({
    respostaAceita: PropTypes.shape({
      tempoEspera: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      dataCadastro: PropTypes.string,
    }),
    enderecoDestino: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    enderecoOrigem: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    dataChegadadoParceiro: PropTypes.string,
    dataConcluida: PropTypes.string,
  }),
};

export default TimelineTab;
