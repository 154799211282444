import React from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';
import CustomModal from '../common/CustomModal';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {connect} from 'react-redux';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';
import {doCloseModal} from '../common/CustomModal';
import {serviceCompleted} from '../../api/solicitation_helper';
import {toastError, toastSuccess} from '../../actions/toast_actions';

class FinishServiceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finishDate: this.props.finishDate || moment(),
      motivoConclusao: null,
    };
  }

  onChangeFinishDate = (e) => {
    this.setState({
      ...this.state,
      finishDate: e,
    });
  };

  reset = () => {
    this.setState({
      finishDate: this.props.finishDate || moment(),
      motivoConclusao: null,
    });
  };

  onChangeMotivoConclusao = (motivoConclusao) => {
    this.setState({
      ...this.state,
      motivoConclusao,
    });
  };

  handleFinishService = async (e) => {
    e.preventDefault();

    try {
      const {
        currentSolicitation: {codigo},
        context,
      } = this.props;
      const {motivoConclusao} = this.state;

      const finishDate = moment(this.state.finishDate).format('YYYY-MM-DD HH:mm:ss');

      if (motivoConclusao === null) {
        toastError('Motivo da conclusão do serviço é obrigatório!');
        return;
      }

      const response = await serviceCompleted(codigo, finishDate, motivoConclusao.value);

      if (response.sucesso) {
        toastSuccess(response.mensagemRetorno);
        doCloseModal(context);
        this.props.loadInProgressSolicitations();
      } else {
        toastError(response.mensagemRetorno);
      }
    } catch (err) {
      toastError(err.mensagemCliente || err.mensagemRetorno);
    }
  };

  render() {
    return (
      <CustomModal title="Concluir Serviço" context={this.props.context}>
        <form onSubmit={this.handleFinishService}>
          <div className="form-group">
            <label className="form-label">Data e hora da conclusão</label>
            <DateTime
              className="form-control"
              onChange={this.onChangeFinishDate}
              value={this.state.finishDate}
              timeFormat="HH:mm"
              dateFormat="DD-MM-YYYY"
              closeOnSelect
            />
          </div>
          <div className="form-group">
            <label className="form-label">Motivo da conclusão manual</label>
            <Select
              options={this.props.options}
              value={this.state.motivoConclusao}
              onChange={this.onChangeMotivoConclusao}
              required
              styles={selectCustomStyle}
            />
          </div>

          <div>
            <button className="btn btn-block btn-primary" type="submit">
              <small>Concluir Serviço</small>
            </button>
          </div>
        </form>
      </CustomModal>
    );
  }
}

FinishServiceModal.propTypes = {
  context: PropTypes.string.isRequired,
  options: PropTypes.array,
};

const mapStateToProps = (state) => ({
  currentSolicitation: state.followup.currentSolicitation,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadInProgressSolicitations: () => {
      dispatch(loadInProgressSolicitationsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FinishServiceModal);
