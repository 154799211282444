import React from 'react';
import {required} from './FormValidation';
import SelectField from './SelectField';
import {selectCustomStyle} from '../../../utils/SelectCustomStyle';
import TextField from './TextField';

export const renderPriceTable = ({fields, meta: {error}}) => (
  <ul>
    <li>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => fields.push({kmBase: 40})}
        style={{marginBottom: '14px'}}>
        <i className="fa fa-plus" />
      </button>
    </li>
    {fields.map((tabela, index) => (
      <li key={index}>
        <div className="col-sm-3">
          <SelectField
            name={`${tabela}.servico.codigo`}
            label={'Tipo de Serviço'}
            icon={'fas fa-align-left'}
            validate={[required]}
            styles={selectCustomStyle}>
            <option>Selecionar</option>
            <option value="REBOQUE">Reboque</option>
            <option value="TROCAPNEU">Troca de pneu</option>
            <option value="PANESECA">Pane Seca</option>
            <option value="BATERIA">Bateria</option>
            <option value="SUBSOLO">Guincho Subsolo</option>
          </SelectField>
        </div>
        <div className="col-sm-3">
          <SelectField
            name={`${tabela}.tipoVeiculo.codigo`}
            label={'Tipo de veículo'}
            icon={'fas fa-align-left'}
            styles={selectCustomStyle}
            validate={[required]}>
            <option>Selecionar</option>
            <option value="MOTO">Moto</option>
            <option value="AUTOMOVEL">Automóvel</option>
            <option value="SUV">SUV</option>
            <option value="VAN">Van/Outros</option>
            <option value="PESADO">Pesado</option>
            <option value="EXTRAPESADO">Extra Pesado</option>
          </SelectField>
        </div>
        <div className="col-sm-1">
          <TextField
            type="number"
            name={`${tabela}.kmBase`}
            label={'Raio'}
            placeholder={''}
            validate={[required]}
            disabled
          />
        </div>
        <div className="col-sm-2">
          <TextField
            type="number"
            name={`${tabela}.valorSaida`}
            icon={'fas fa-money-bill-alt'}
            label={'Valor Saída'}
            placeholder={''}
            validate={[required]}
          />
        </div>
        <div className="col-sm-2">
          <TextField
            type="number"
            name={`${tabela}.valorKMExcedente`}
            label={'Km Excedente'}
            icon={'fas fa-money-bill-alt'}
            placeholder={''}
            validate={[required]}
          />
        </div>
        <div className="col-sm-1" style={{height: '56.5px'}}>
          <button
            className="btn btn-sm btn-danger"
            type="button"
            style={{marginRight: '5px', position: 'absolute', bottom: '0'}}
            title="Remover preço"
            onClick={() => fields.remove(index)}>
            <i className="fa fa-times" />
          </button>
        </div>
      </li>
    ))}
    {error && <li className="error">{error}</li>}
  </ul>
);
