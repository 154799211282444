import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getBaseLastLost } from '../../actions/base_actions';
import { hasBaseProfile } from '../../utils/check_permission';
import ReactTableCommon from '../balance/reactTableBalances';



class LastLost extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filteredbaseLastLost: [],
            baseLastLost: []
        }
    }

    componentDidMount() {
        const { baseCode, getBaseLastLost } = this.props
        getBaseLastLost(baseCode)
        this.getTable = this.getTable.bind(this)
        this.getData = this.getData.bind(this)
    }


    componentWillReceiveProps(nextProps) {
        const { getBaseLastLost, baseCode, baseLastLost } = nextProps

        if (baseCode !== this.props.baseCode)
            getBaseLastLost(baseCode)

        if (baseLastLost && baseLastLost.length > 0) {
            this.setState({ baseLastLost: baseLastLost, filteredbaseLastLost: baseLastLost })
        }
    }
    nameFormat(cell, row) {
        return (
            <div>
                {row.cliente.nome} - {row.cliente.telefoneCelular}
            </div>
        )
    }

    servicoFormat(cell, row) {
        return (
            <div>
                {row.tipoServico.codigo}
            </div>
        )
    }
    valorFormat(cell, row) {
        return (
            <div>
                {`R$ ${row.valorParceiro}`}
            </div>
        )
    }
    veiculoformat(cell, row) {
        return (
            <div>
                {`${row.veiculoCliente.marca} ${row.veiculoCliente.modelo} - ${row.veiculoCliente.placa}`}{row.veiculoCliente.cor ? ` - ${row.veiculoCliente.cor}` : ''}
            </div>
        )
    }
    parceiroFormat(cell, row) {
        return (
            <div>
                {`${row.respostaAceita ? row.respostaAceita.parceiro.contato : ''} - ${row.respostaAceita ? row.respostaAceita.parceiro.nomeFantasia : ''}`}
            </div>
        )
    }

    componentDidMount() {
        const { getBaseLastLost, baseCode } = this.props
        if (baseCode) {
            getBaseLastLost(baseCode)
        }
    }

    setTableLoader() {
        if (this.state.isFetching === false) {
            return `Nenhum registro encontrado`
        } else {
            return (
                <div className="spiner-example">
                    <div className="sk-spinner sk-spinner-double-bounce">
                        <div className="sk-double-bounce1"></div>
                        <div className="sk-double-bounce2"></div>
                    </div>
                </div>
            )
        }
    }
    statusIcon(cell, row) {
        let status = row.statusSolicitacao
        let className = 'redFlag'
        if (status === "Concluída")
            className = 'concludedFlag'
        else if (status === "Expirada")
            className = 'expiredFlag'
        return (
            <div className={`statusFlag ${className}`}></div>
        )
    }

    getTable() {
        const { baseLastLostFetching } = this.props
        const { filteredbaseLastLost } = this.state
        return (
            <ReactTableCommon data={filteredbaseLastLost}
                onSearchChange={this.onSearchChange}
                isFetching={baseLastLostFetching}
                clearSearch={this.clearSearch}
            >
                <TableHeaderColumn dataField={'status'} dataSort={true} dataFormat={this.statusIcon} width="30px"></TableHeaderColumn>
                {!hasBaseProfile() && <TableHeaderColumn isKey dataFormat={this.nameFormat} width='230'>Cliente</TableHeaderColumn>}
                <TableHeaderColumn isKey dataFormat={this.veiculoformat} width='200'>Veículo</TableHeaderColumn>
                <TableHeaderColumn isKey dataFormat={this.valorFormat} dataAlign='center' width='120'>Valor</TableHeaderColumn>
                <TableHeaderColumn isKey dataFormat={this.servicoFormat}>Tipo de Serviço</TableHeaderColumn>
                <TableHeaderColumn isKey dataField='dataCadastro'>Criação</TableHeaderColumn>
                <TableHeaderColumn dataField='dataConcluida'>Conclusão</TableHeaderColumn>
                {!hasBaseProfile() && <TableHeaderColumn dataFormat={this.parceiroFormat}>Parceiro</TableHeaderColumn>}
                <TableHeaderColumn dataField='codigo'>Solicitação</TableHeaderColumn>
            </ReactTableCommon>
        )
    }
    render() {
        return (
            <div className="">
                    {/* <div className="ibox-title">
                            <Link to="/balance"> <h5>Fechamento / </h5></Link><h5>{this.id}</h5>
                            <div className="ibox-tools">
                            </div>
                        </div> */}
                <div className="ibox-content" style={{ padding: "20px 5px 20px 5px" }}>
                        {/* <div className="cards-container">
                            <InvoiceCard icon={"fas fa-truck-moving"} value={services} title={"Atendimentos"} cClass={"navy-bg"} />
                            <InvoiceCard icon={"fas fa-plus"} value={receive.toFixed(2)} title={"A Receber (R$)"} cClass={"green-bg"} />
                            <InvoiceCard icon={"fas fa-minus"} value={pay.toFixed(2)} title={"A Pagar (R$)"} cClass={"red-bg"} />
                            <InvoiceCard icon={"fas fa-equals"} value={total.toFixed(2)} title={"Total (R$)"}
                                cClass={total >= 0 ? "green-bg" : "red-bg"} />
                        </div> */}
                        {
                            this.getTable()
                        }
                    </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        baseLastLostFetching: state.base.baseLastLostFetching,
        baseLastLost: state.base.baseLastLost
    }
)

const mapDispatchToProps = dispatch => bindActionCreators({
    getBaseLastLost
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LastLost)