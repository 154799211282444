import React from 'react';
import CustomModal from '../../common/CustomModal';
import {connect} from 'react-redux';
import './closestPartnersModal.css';
import Spinner from 'react-spinkit';
import ReactTooltip from 'react-tooltip';
import Toggle from 'react-toggle-button';
import {sortBy} from 'underscore';
import AsyncSelect from 'react-select/lib/Async';
import classnames from 'classnames';
import {doGet} from '../../../api/base/base_api_helper';
import {toastError} from '../../../actions/toast_actions';

const styleMap = {
  height: '100%',
  width: '100%',
};

class ClosestPartnersModal extends React.Component {
  constructor(props) {
    super(props);
    this.showContextMenu = this.showContextMenu.bind(this);
    this.handlePartnerIcon = this.handlePartnerIcon.bind(this);
    this.getPartnerInfo = this.getPartnerInfo.bind(this);
    this.buildPartner = this.buildPartner.bind(this);
    this.createMarker = this.createMarker.bind(this);
    this.handleChangeFantasma = this.handleChangeFantasma.bind(this);
    this.createElementWitText = this.createElementWitText.bind(this);
    this.handleCallMostClosest = this.handleCallMostClosest.bind(this);
    this.getMaxWidth = this.getMaxWidth.bind(this);
    this.onChangePartnerWanted = this.onChangePartnerWanted.bind(this);
    this.handleCallPartnerWanted = this.handleCallPartnerWanted.bind(this);
    this.togglePartnerWanted = this.togglePartnerWanted.bind(this);
    this.handleDirections = this.handleDirections.bind(this);

    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true,
    });

    this.getOtherOptions = this.getOtherOptions.bind(this);
    this.footer = this.footer.bind(this);
    this.infowindow = new google.maps.InfoWindow();
    this.partnersMarkers = [];
    this.state = {
      fantasma: false,
      showSearch: true,
      partnerWanted: '',
    };
  }

  formatNotEqualZeroClosest = (partner) => {
    let output = '';

    if (partner.associado.veiculo.asaDelta === 1) {
      output += '| Com ASA ';
    }

    if (partner.associado.veiculo.patins !== 0) {
      output += `| ${partner.associado.veiculo.patins} Patin(s)`;
    }

    if (partner.associado.veiculo.tamanhoPlataforma !== 0) {
      const {tamanhoPlataforma} = partner.associado.veiculo;
      output += `| ${tamanhoPlataforma.toString().replace('.', ',')} Metro(s)`;
    }

    return output;
  };

  handlePartnerIcon(partner) {
    const inactivity = this.handleTimeInactivity(partner.dataUltimaPosicao);
    if (inactivity && inactivity >= 30)
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png';
    else if (partner.status.codigo === 'PARCEIROATIVO')
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carBlue.png';
    else if (partner.status.codigo === 'PARCEIROEMATENDIMENTO')
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carGreen.png';
    else if (partner.status.codigo === 'PARCEIROEMDESCANSO')
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carYellow.png';
    else return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png';
  }

  componentDidMount() {
    const {enderecoOrigem, codigoSolicitacao, handleClosestPartners} = this.props;

    try {
      handleClosestPartners(codigoSolicitacao);
      this.mapRef.parentNode.classList.add('modal-body-p0');
      this.map = new google.maps.Map(this.mapRef, {
        center: enderecoOrigem
          ? {lat: enderecoOrigem.latitude, lng: enderecoOrigem.longitude}
          : {lat: -22.915, lng: -43.197},
        zoom: 13,
        gestureHandling: 'greedy',
        controlSize: 24,
        disableDefaultUI: true,
      });

      var overlay = new google.maps.OverlayView();
      overlay.draw = function () {
        this.getPanes().markerLayer.id = 'markerLayer';
      };
      overlay.setMap(this.map);

      this.showContextMenu();
      this.directionsDisplay.setMap(this.map);
    } catch (err) {
      console.error(err);
    }

    google.maps.event.addListener(this.map, 'click', () => {
      this.infowindow.close();
    });
  }

  createElementWitText(elem, textContent) {
    const node = document.createElement(elem);
    node.textContent = textContent;
    return node;
  }

  getMaxWidth(partner) {
    return (
      partner.veiculo.patins > 0 &&
      partner.veiculo.asaDelta > 0 &&
      partner.veiculo.capacidadeCarga > 0 &&
      partner.veiculo.tamanhoPlataforma > 0
    );
  }

  getPartnerInfo(partner) {
    const root = document.createElement('div');

    const {associado} = partner;
    root.style.width = this.getMaxWidth(associado) ? '200px' : '160px';

    const distanceLabel = document.createElement('h3');
    distanceLabel.innerHTML = `<i class="far fa-map"></i> ${partner.distancia} km`;

    root.appendChild(distanceLabel);

    const title = document.createElement('h3');
    title.innerHTML = `<i class="fas fa-clock"></i> ${partner.tempo} minutos`;

    root.appendChild(title);

    const accessorios = document.createElement('div');
    accessorios.innerHTML = this.acessoriesTab(associado);
    root.appendChild(accessorios);

    const nameContact = document.createElement('p');
    nameContact.innerHTML = `${associado.contato}<br>${associado.nomeFantasia}`;
    nameContact.style.marginBottom = '0px';
    root.appendChild(nameContact);

    const phone = document.createElement('div');
    phone.innerHTML =
      `<a href="http://api.whatsapp.com/send?l=pt_br&phone=55${associado.telefoneCelular
        .match(/[0-9]/g)
        .join('')}" target="blank"> <i class="fab fa-whatsapp"></i> ` +
      associado.telefoneCelular +
      '</a>';

    root.appendChild(phone);

    if (associado.pendenciaFiscal === true) {
      const pendenciaAlert = this.createElementWithText('p', '* Possui Pendência Envio de NF');
      pendenciaAlert.classList.add('text-danger');
      root.appendChild(pendenciaAlert);
    }

    if (associado.inadimplencia === true) {
      const pendenciaAlert = this.createElementWithText('p', '* Está em inadimplência.');
      pendenciaAlert.classList.add('text-danger');
      root.appendChild(pendenciaAlert);
    }

    const button = document.createElement('button');
    button.classList.add('btn', 'btn-primary');
    button.style.marginTop = '5px';
    button.textContent = 'Acionar';

    button.addEventListener('click', () => {
      const {codigoSolicitacao, handleCallPartner} = this.props;

      const requestedPartner = {
        value: {
          codigo: partner.associado.codigo,
        },
        distance: partner.distancia,
      };

      handleCallPartner(codigoSolicitacao, requestedPartner);
    });

    let buttonAtd;
    buttonAtd = document.createElement('button');
    buttonAtd.classList.add('btn', 'btn-primary');
    buttonAtd.style.marginTop = '5px';
    buttonAtd.style.marginLeft = '5px';
    buttonAtd.style.padding = '6px 4px';
    buttonAtd.textContent = 'Disponível?';

    buttonAtd.addEventListener('click', () => {
      const {handlePushNotificationPartner} = this.props;

      handlePushNotificationPartner(
        partner.associado.codigo,
        'Esta disponivel? Chamado proximo a voce.',
      );
    });

    root.appendChild(button);
    root.appendChild(buttonAtd);

    return root;
  }

  buildPartner(partner) {
    let newPartner = new google.maps.Marker({
      position: {
        lat: partner.associado.latitude,
        lng: partner.associado.longitude,
      },
      map: this.map,
      title: partner.associado.contato,
      icon: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carBlue.png',
    });

    newPartner.addListener('click', () => {
      this.infowindow.setContent(this.getPartnerInfo(partner));
      this.infowindow.open(this.map, newPartner);
    });

    return newPartner;
  }

  createMarker(partner) {
    let createdPartner = this.buildPartner(partner);
    this.partnersMarkers.push(createdPartner);
  }

  showContextMenu() {
    let bounds = new google.maps.LatLngBounds();
    if (this.props.enderecoOrigem) {
      const {enderecoOrigem, enderecoDestino} = this.props;

      const requestMarker = new google.maps.Marker({
        position: {
          lat: enderecoOrigem.latitude,
          lng: enderecoOrigem.longitude,
        },
        map: this.map,
        title: 'Solicitação',
        icon: {
          url: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',
        },
      });
      bounds.extend(requestMarker.position);

      if (enderecoDestino) {
        const destinationMarker = new google.maps.Marker({
          position: {
            lat: enderecoDestino.latitude,
            lng: enderecoDestino.longitude,
          },
          map: this.map,
          title: 'Solicitação',
          icon: 'http://icons.iconarchive.com/icons/fatcow/farm-fresh/24/flag-finish-icon.png',
          animation: google.maps.Animation.BOUNCE,
          draggable: true,
        });
        bounds.extend(destinationMarker.position);
        this.handleDirections(this.directionsService, this.directionsDisplay);
      }
    }
  }

  handleDirections(service, display) {
    const {enderecoOrigem, enderecoDestino} = this.props;
    service.route(
      {
        origin: {
          lat: enderecoOrigem.latitude,
          lng: enderecoOrigem.longitude,
        },
        destination: {
          lat: enderecoDestino.latitude,
          lng: enderecoDestino.longitude,
        },
        travelMode: 'DRIVING',
        avoidTolls: false,
      },
      (response, status) => {
        if (status === 'OK') {
          const stepMiddle = Math.round(response.routes[0].legs[0].steps.length / 2) - 1;
          this.infowindow.setContent(
            response.routes[0].legs[0].distance.text +
              '<br>' +
              response.routes[0].legs[0].duration.text +
              ' ',
          );
          this.infowindow.setPosition(
            response.routes[0].legs[0].steps[stepMiddle].end_location,
          );
          this.infowindow.open(this.map);

          display.setDirections(response);
        } else {
          toastError(`Erro ao montar rota\n${status}`);
        }
      },
    );
  }

  acessoriesTab = (partner) =>
    `${
      partner.veiculo.patins ||
      partner.veiculo.asaDelta ||
      partner.veiculo.capacidadeCarga ||
      partner.veiculo.tamanhoPlataforma
        ? `<div class='acessoriesTab'>
        ${
          partner.veiculo.patins > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner.veiculo.patins}</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/patins.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.asaDelta > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner.veiculo.asaDelta}</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/asa-delta.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.capacidadeCarga > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.capacidadeCarga / 1000
              }k</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carga.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.tamanhoPlataforma > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner.veiculo.tamanhoPlataforma}</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/tamanho.png"/></div>`
            : '<div></div>'
        }
      </div>`
        : '<div></div>'
    }`;

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.closestPartners !== this.props.closestPartners) {
      this.partnersMarkers.forEach((partner) => partner.setMap(null));
      this.partnersMarkers = [];

      nextProps.closestPartners.forEach((partner) => {
        let createdPartner = this.buildPartner(partner);
        this.partnersMarkers.push(createdPartner);
      });
    }
  }

  componentWillUnmount() {
    this.partnersMarkers.forEach((partner) => partner.setMap(null));
    this.props.toggleFantasma(false);
  }

  handleChangeFantasma() {
    const {codigoSolicitacao, handleClosestPartners} = this.props;
    handleClosestPartners(codigoSolicitacao, !this.props.fantasmas);
    this.props.toggleFantasma(!this.props.fantasmas);
  }

  handleCallMostClosest() {
    const {codigoSolicitacao, handleCallPartner} = this.props;
    const closestDistance = sortBy(this.props.closestPartners, 'distancia');

    if (closestDistance.length === 0) {
      toastError('Nenhum parceiro próximo foi encontrado.');
      return;
    }

    const partner = closestDistance[0];

    const requestedPartner = {
      value: {
        codigo: partner.associado.codigo,
      },
      distance: partner.distancia,
    };

    handleCallPartner(codigoSolicitacao, requestedPartner);
  }

  handleCallPartnerWanted() {
    if (this.state.partnerWanted === '') return;

    const {codigoSolicitacao, handleCallPartner} = this.props;

    handleCallPartner(codigoSolicitacao, this.state.partnerWanted);
    this.setState({
      ...this.state,
      partnerWanted: '',
    });
  }

  getOtherOptions(input) {
    const {options} = this.state;
    if (!input) {
      return Promise.resolve(options);
    }
    return doGet(`partners?_filter=${input}&&_status=PARCEIROATIVO,PARCEIROEMDESCANSO`)
      .then((res) => {
        if (res.sucesso === true) {
          if (res.listPartners) {
            return res.listPartners.map((partner) => {
              const formatNotEqualZero = () => {
                let output = '';

                if (partner.veiculo.patins !== 0) {
                  output += `| ${partner.veiculo.patins} Patin(s)`;
                }

                if (partner.veiculo.tamanhoPlataforma !== 0) {
                  const {tamanhoPlataforma} = partner.veiculo;
                  output += `| ${tamanhoPlataforma.toString().replace('.', ',')} Metro(s)`;
                }

                return output;
              };

              return {
                value: partner,
                label: `${partner.contato} - ${partner.nomeFantasia} ${
                  partner.veiculo.asaDelta === 1 ? '| Com ASA' : ''
                } ${formatNotEqualZero()}`,
              };
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  footer() {
    return (
      <div className="closest-footer">
        <div className="fantasma-left">
          <h4
            data-tip="Prestadores desconectados do App há mais de 2 dias"
            data-for="fantasma">
            <i style={{marginRight: '5px'}} className="fas fa-question-circle" />
            Fantasmas?
          </h4>
          <ReactTooltip id="fantasma" />
          <Toggle
            colors={{
              activeThumb: {
                base: `#FFF`,
              },
              active: {
                base: `#5c40ff`,
              },
              inactiveThumb: {
                base: `#FFF`,
              },
              inactive: {
                base: `#FFF`,
              },
            }}
            inactiveLabelStyle={{
              color: `#2f4050`,
            }}
            value={this.props.fantasmas}
            thumbStyle={{
              borderRadius: 2,
              borderColor: `#5c40ff`,
            }}
            onToggle={this.handleChangeFantasma}
            trackStyle={{borderRadius: 2}}
          />
        </div>
        {this.props.isLoading ? (
          <div className="fantasma-right">
            <Spinner name="ball-beat" />
          </div>
        ) : null}
        <div>
          <button onClick={this.handleCallMostClosest} className="btn btn-primary">
            Chamar próximo
          </button>
        </div>
      </div>
    );
  }

  onChangePartnerWanted(value) {
    this.setState({
      ...this.state,
      partnerWanted: value,
    });
  }

  togglePartnerWanted(event) {
    event.preventDefault();

    this.setState({...this.state, showSearch: !this.state.showSearch});
  }

  render() {
    return (
      <CustomModal
        context={this.props.context}
        footer={this.footer()}
        title={this.props.isLoading ? 'Carregando...' : this.props.title}>
        <div className="closest-search-container">
          <div
            className={classnames({
              'container-search-form': true,
              hide: this.state.showSearch,
            })}>
            <div className="col-xs-9">
              <AsyncSelect
                placeholder=""
                loadOptions={this.getOtherOptions}
                value={this.state.partnerWanted}
                onChange={this.onChangePartnerWanted}
                defaultOptions
                isClearable
              />
            </div>
            <button
              disabled={this.state.partnerWanted === ''}
              onClick={this.handleCallPartnerWanted}
              className="btn btn-primary">
              Acionar parceiro
            </button>
          </div>
          <div className="closest-search-right">
            <button
              onClick={this.togglePartnerWanted}
              className="btn btn-sm btn-default float-right">
              <i className="fas fa-truck" />
            </button>
          </div>
        </div>
        <div
          className="closest-map"
          ref={(ref) => {
            this.mapRef = ref;
          }}
          id={this.props.id}
          style={styleMap}
        />
      </CustomModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleClosestPartners: (id, fantasmas) => {
      dispatch({
        type: `HANDLE_CLOSEST_PHANTOM_PARTNERS`,
        value: {id, fantasmas},
      });
    },
    toggleFantasma: (actualState) => {
      dispatch({
        type: `TOGGLE_PHANTOM_STATE`,
        value: actualState,
      });
    },
    handleCallPartner: (solicitationCode, partner) => {
      dispatch({
        type: `HANDLE_CALL_PARTNER`,
        solicitation: solicitationCode,
        partner: partner,
      });
    },
    handlePushNotificationPartner: (partnerCode, message) => {
      dispatch({
        type: `HANDLE_PUSH_NOTIFICATION_PARTNER`,
        partnerCode,
        message,
      });
    },
  };
};

ClosestPartnersModal.defaultProps = {
  closestPartners: [],
};

const mapStateToProps = (state) => ({
  enderecoOrigem: state.followup.currentSolicitation.enderecoOrigem,
  enderecoDestino: state.followup.currentSolicitation.enderecoDestino,
  codigoSolicitacao: state.followup.currentSolicitation.codigo,
  closestPartners: state.partner.closestPartners,
  isLoading: state.partner.isLoading,
  fantasmas: state.partner.fantasmas,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosestPartnersModal);
