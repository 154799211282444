import React from 'react';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import PropTypes from 'prop-types';

function setTableLoader(isFetching) {
  if (isFetching === false) {
    return `Nenhum registro encontrado`;
  } else {
    return (
      <div className="spiner-example">
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      </div>
    );
  }
}

function BaseTable(props) {
  const updateUrl = (baseCode) => {
    const newUrl = `bases?baseCode=${baseCode}&baseModalTab=Base`;

    if (window.parent) {
      window.parent.history.pushState({}, '', newUrl);
    } else {
      console.error(
        'Acesso negado à janela pai (provavelmente devido à política de mesma origem)',
      );
    }

    const popStateEvent = new PopStateEvent('popstate', {state: {}});
    window.parent.dispatchEvent(popStateEvent);
  };

  const options = {
    noDataText: setTableLoader(props.isFetching),
    page: props.page,
    sizePerPageList: [10, 25, 30, 50],
    sizePerPage: props.sizePerPage || 10,
    paginationShowsTotal: true,
    onPageChange: props.onPageChange,
  };

  const actionColumn = (row, cel) => {
    // const whatsapp = `55` + cel.telefoneCelular.replace(/[^0-9.]/g, '');

    return (
      // <div>
      <button
        className="btn btn-sm btn-primary"
        style={{marginRight: '5px'}}
        onClick={() => updateUrl(cel.codigo)}>
        <i className="fa fa-truck"></i>
      </button>
      /* <button
          className="btn btn-sm btn-danger"
          style={{marginRight: '5px'}}
          onClick={() => props.deleteBase(cel)}>
          <i className="fa fa-times"></i>
        </button>
        <Link
          className="btn btn-sm green-bg"
          style={{marginRight: '5px', color: 'white'}}
          to={{pathname: `https://wa.me/${whatsapp}`}}
          target="_blank">
          <i className="fas fa-comment-alt"></i>
        </Link> */
      // </div>
    );
  };
  const totalSize =
    props.baseList && props.baseList.length > 0 ? props.baseList[0].tototalcount : 0;
  return (
    <div className="ibox-content">
      <BootstrapTable
        options={options}
        data={props.baseList}
        pagination
        fetchInfo={{dataTotalSize: totalSize}}
        remote
        condensed
        bordered={false}
        hover
        striped>
        <TableHeaderColumn dataSort width="25"></TableHeaderColumn>
        <TableHeaderColumn dataField="dataCadastro">Cadastro</TableHeaderColumn>
        <TableHeaderColumn dataField="contato">Contato</TableHeaderColumn>
        <TableHeaderColumn isKey dataField="razaoSocial">
          Razão Social
        </TableHeaderColumn>
        <TableHeaderColumn dataField="nomeFantasia">Nome Fantasia</TableHeaderColumn>
        <TableHeaderColumn dataField="telefoneCelular">Telefone Celular</TableHeaderColumn>
        <TableHeaderColumn dataField="documento">CPF / CNPJ</TableHeaderColumn>
        <TableHeaderColumn dataField="status">Status</TableHeaderColumn>
        <TableHeaderColumn dataFormat={actionColumn}></TableHeaderColumn>
      </BootstrapTable>
      {props.baseList ? `${props.baseList.length} linhas` : `0 linha`}
    </div>
  );
}

BaseTable.propTypes = {
  baseList: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  showHistory: PropTypes.func.isRequired,
  showTruck: PropTypes.func.isRequired,
  deleteBase: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default BaseTable;
