import React, {Component} from 'react';

import DateTime from 'react-datetime';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: moment(),
      startDate: moment(),
      sizePerPage: 10,
      searchWasClicked: false,
    };
    this.handleBegin = this.handleBegin.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
  }
  handleBegin(date) {
    this.setState({
      startDate: date,
    });
  }
  handleEnd(date) {
    this.setState({
      endDate: date,
    });
  }
  render() {
    const {loadData} = this.props;
    const {startDate, endDate} = this.state;
    return (
      <form className="form-inline">
        <div className="form-group">
          <DateTime
            onChange={this.handleBegin}
            value={moment(this.state.startDate).local().format('DD/MM/YYYY 00:00')}
            closeOnSelect
          />
        </div>
        <div className="form-group">
          <label className="font-normal p-w-xs">Até</label>
        </div>
        <div className="form-group p-w-xs">
          <DateTime
            onChange={this.handleEnd}
            value={moment(this.state.endDate).local().format('DD/MM/YYYY 24:00')}
            closeOnSelect
          />
        </div>
        <div className="form-group p-w-xs">
          <button
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              this.setState({searchWasClicked: true});
              loadData(startDate, endDate, true, true);
            }}>
            <i className="fa fa-search"></i>
          </button>
        </div>
        {this.state.searchWasClicked ? (
          <div className="form-group p-w-xs">{this.props.searchPanel}</div>
        ) : null}
      </form>
    );
  }
}

export default DateFilter;
