const _estagios = [
  {
    label: 'Todos os estágios',
    value: undefined,
  },
  {
    label: 'Validado',
    value: 'ESTAGIOVALIDADO',
  },
  { label: 'Operador', value: 'ESTAGIOOPERADOR' },
  { label: 'Teleconferência', value: 'ESTAGIOTELECONFERENCIA' },
  {
    label: 'Mecânico',
    value: 'ESTAGIOMECANICO',
  },
  {
    label: 'Guincho',
    value: 'ESTAGIOGUINCHO',
  },
  {
    label: 'Concluído',
    value: 'CONCLUIDO',
  },
];

export const getEstagios = () => {
  return JSON.parse(JSON.stringify(_estagios));
};
