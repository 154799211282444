import { push } from 'react-router-redux';
import {
  getPartners,
  getAllPartners,
  getSinglePartner,
  createPartner,
  updatePartner,
  deletePartner,
  resetPassword,
  resetLocation,
  getPartnerApiValues,
  getClosestPartners,
} from '../api/partner_helper';
import { onFormError, onFormSuccess, setFormValues } from './form_actions';
import { onOptionSelected } from './autocomplete_actions';
import { toastError, toastSuccess } from './toast_actions';
import swal from 'sweetalert';
import { doGet } from '../api/base/base_api_helper';

export function loadPartnerList(link) {
  return (dispatch) => {
    getPartners(link)
      .then((results) => {
        dispatch({ type: PARTNER_LIST_CHANGED, value: results.listPartners });
      })
      .catch((error) => {
        console.log(
          'Exception on partner_actions.loadPartnerList(...): ' + error.message
        );
      });
  };
}
export function partnerClick(partner) {
  return (dispatch) => {
    dispatch({ type: `PARTNER_CLICK`, value: partner });
  };
}

export function loadAllPartners() {
  return (dispatch) => {
    getAllPartners()
      .then((results) => {
        dispatch({ type: PARTNER_LIST_CHANGED, value: results.listPartners });
      })
      .catch((error) => {
        console.log(
          'Exception on partner_actions.loadPartnerList(...): ' + error.message
        );
      });
  };
}

export function setPartnerFormDetails(formContext, partnerCode) {
  return (dispatch, getState) => {
    getSinglePartner(partnerCode)
      .then((result) => {
        dispatch(setFormValues(formContext, result.cliente));
        dispatch(onFormSuccess(formContext));
      })
      .catch((error) => {
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function setPartnerInputDetails(inputContext, partnerCode) {
  return (dispatch, getState) => {
    getSinglePartner(partnerCode)
      .then((result) => {
        dispatch(onOptionSelected(inputContext, result.cliente));
      })
      .catch((error) => {
        console.log(
          'Exception on partner_actions.setPartnerInputDetails(...): ' +
            error.message
        );
      });
  };
}

export function createNewPartner(link, doAfterSuccess) {
  return (dispatch, getState) => {
    let partnerData = getState().form['registerPartner'].values;
    let partnerServices = getState().services.serviceList;

    createPartner(partnerData, partnerServices, link)
      .then((result) => {
        dispatch([loadPartnerList(link), doAfterSuccess()]);

        toastSuccess('Cadastro Concluido!');
      })
      .catch((error) => {
        toastError(error.message);
        //dispatch( onFormError(formContext, error.message || error) )
      });
  };
}

export function updateExistingPartner(link, doAfterSuccess) {
  return (dispatch, getState) => {
    let codigo = getState().form['registerPartner'].values.codigo;
    let partnerData = getState().form['registerPartner'].values;

    let partnerServices = getState().services.serviceList;

    updatePartner(codigo, partnerData, partnerServices, link)
      .then((result) => {
        dispatch([
          loadPartnerList(link),
          doAfterSuccess(),
          toastSuccess('Cadastro Concluido!'),
        ]);
      })
      .catch((error) => {
        toastError(error.message);
        console.log(error);
        //dispatch( onFormError(formContext, error.message || error) )
      });
  };
}

export function deleteExistingPartner(partner, doAfterSuccess) {
  return (dispatch, getState) => {
    deletePartner(partner.codigo)
      .then((result) => {
        //dispatch ({type: PARTNER_INSTANCE_CHANGED, partnerId: partnerData.codigo, partnerData: getPartnerApiValues(partnerData)})
        dispatch(loadPartnerList());
        //doAfterSuccess()
      })
      .catch((error) => {
        toastError(error.message || error); //dispatch( onFormError(context, error.message) )
      });
  };
}

export function editPartner(partner, update) {
  return (dispatch) => {
    dispatch({ type: PARTNER_EDIT, value: partner });
    dispatch(update);
  };
}

export function resetPartnerPassword(partnerCode) {
  return (dispatch) => {
    swal(
      {
        title: 'Deseja resetar a senha?',
        text: 'A nova senha será igual ao login do usuário.',
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
        type: 'warning',
      },
      () => {
        resetPassword(partnerCode)
          .then((resp) => {
            toastSuccess('Senha resetada com sucesso.');
          })
          .catch((e) => {
            toastError(e.mensagemRetorno || e.status);
          })
          .finally(() => {
            swal.close();
          });
      }
    );
  };

  //'partners/code/setDefaultPassword'
}
export function resetPartnerLocation(partnerCode, baseLocation) {
  return (dispatch) => {
    swal(
      {
        title: 'Deseja resetar a localização do parceiro?',
        text: 'A nova localização será igual a localização da base.',
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
        type: 'warning',
      },
      () => {
        resetLocation(partnerCode)
          .then((resp) => {
            toastSuccess('Senha resetada com sucesso.');
          })
          .catch((e) => {
            toastError(e.mensagemRetorno || e.message || e.status);
          })
          .finally(() => {
            swal.close();
          });
      }
    );
  };

  //'partners/code/setDefaultPassword'
}

/*export function loadClosestPartners ( code ) {
  return ( dispatch ) => {
    getClosestPartners( code ).then( ( result ) => {
      dispatch({
        type: GET_CLOSEST_PARTNERS, value: result.partners
      })
    } )
  }
}*/

export const PARTNER_EDIT = 'PARTNER_EDIT';
export const PARTNER_LIST_CHANGED = 'PARTNER_LIST_CHANGED';
export const PARTNER_INSTANCE_CHANGED = 'PARTNER_INSTANCE_CHANGED';
export const GET_CLOSEST_PARTNERS = `GET_CLOSEST_PARTNERS`;
