import debounce from 'lodash/debounce';

import {
  INPUT_VALUE_CHANGED,
  INPUT_OPTIONS_CHANGED,
  onOptionSelected,
} from './autocomplete_actions';
import {getModelPredictions} from '../api/model_helper';

const debouncedGetModelPredictions = debounce((description, auth, dispatch, context) => {
  getModelPredictions(description, auth).then((results) => {
    const modelList = results.listVehicleModel
      ? results.listVehicleModel.map((model) => {
          return {value: model.marca + ' ' + model.modelo, ...model};
        })
      : [];
    dispatch({type: INPUT_OPTIONS_CHANGED, context: context, value: modelList});
  });
}, 300);

export function onValueChanged(
  context,
  description,
  newPlate,
  doAfterSuccess = () => {},
  auth,
) {
  return (dispatch, getState) => {
    dispatch({type: INPUT_VALUE_CHANGED, context: context, value: newPlate});
    doAfterSuccess(newPlate);

    if (description) {
      debouncedGetModelPredictions(description, auth, dispatch, context);
    } else {
      dispatch({type: INPUT_OPTIONS_CHANGED, context: context, value: []});
    }
  };
}
export function setMarcaModeloInputDetails(formContext, selectedValue) {
  return (dispatch) => {
    dispatch(onOptionSelected(formContext, selectedValue));
  };
}
