import React, {Component} from 'react';

import {connect} from 'react-redux';

import _ from 'underscore';

import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import {onPartnerChange} from '../../actions/partner_service_action';

import {toastSuccess, toastError} from '../../actions/toast_actions';

import {doGet, doPost} from '../../api/base/base_api_helper';

import AsyncSelect from 'react-select/lib/Async';

const placeHolder = (
  <div className="sk-spinner sk-spinner-double-bounce p-xs">
    <div className="sk-double-bounce1"></div>
    <div className="sk-double-bounce2"></div>
  </div>
);

class SelectPartnerSolicitation extends Component {
  constructor(props) {
    super(props);
    this.getOtherOptions = this.getOtherOptions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      multi: false,
      options: [],
      value: '',
      isReady: true,
    };
  }
  onChange(value) {
    this.setState({
      value: value,
    });
    //this.props.updatePartner(value)
  }

  handleClick() {
    const {codigo} = this.props;
    const {value} = this.state.value;
    const distancia = value.distancia * 1000;
    doPost(`solicitations/${codigo}/callPartner/${value.codigo}/${distancia}`)
      .then((res) => {
        res.mensagemCliente ? this.props.onSuccessMessage(res.mensagemCliente) : false;
      })
      .catch((error) => {
        error && error.mensagemCliente
          ? this.props.onErrorMessage(error.mensagemCliente || 'Erro ao enviar solicitação.')
          : null;
      });
  }

  formatNotEqualZeroClosest = (partner) => {
    let output = '';

    if (partner.associado.veiculo.asaDelta === 1) {
      output += '| Com ASA ';
    }

    if (partner.associado.veiculo.patins !== 0) {
      output += `| ${partner.associado.veiculo.patins} Patin(s)`;
    }

    if (partner.associado.veiculo.tamanhoPlataforma !== 0) {
      const {tamanhoPlataforma} = partner.associado.veiculo;
      if (tamanhoPlataforma !== undefined) {
        output += '| '.concat(tamanhoPlataforma.toString().replace('.', ','), ' Metro(s)');
      }
    }

    return output;
  };

  componentWillReceiveProps(nextProps) {
    let {options} = this.state;
    options.splice(0, options.length);

    if (nextProps.closestPartners) {
      _.map(nextProps.closestPartners, (closestPartner) => {
        const patinsInfo = this.formatNotEqualZeroClosest(closestPartner);
        options.push({
          value: closestPartner.associado,
          distance: closestPartner.distancia,
          label: `${closestPartner.associado.contato} -  ${closestPartner.distancia} KM(s) próximos - ${closestPartner.associado.nomeFantasia}  ${patinsInfo}`,
          color: '#51A351',
        });
        this.setState({
          options,
          isReady: true,
        });
      });
    }
    if (
      nextProps.closestPartners ||
      (!nextProps.closestPartners && nextProps.partnerLoading === false)
    ) {
      this.setState({
        isReady: true,
      });
    }

    if (nextProps.partnerLoading === true && this.props.closestPartners) {
      this.setState({
        isReady: false,
      });
    }
  }

  valueComponent(option) {
    return <span style={{color: option.color}}>{option.label}</span>;
  }

  renderValue(option) {
    return <span style={{color: option.color}}>{option.label}</span>;
  }

  getOtherOptions(input) {
    const {options} = this.state;
    if (!input) {
      return Promise.resolve(options);
    }
    return doGet(`partners?_filter=${input}&&_status=PARCEIROATIVO,PARCEIROEMDESCANSO`)
      .then((res) => {
        if (res.sucesso === true) {
          if (res.listPartners) {
            return res.listPartners.map((partner) => {
              const formatNotEqualZero = () => {
                let output = '';

                if (partner.veiculo.patins !== 0) {
                  output += `| ${partner.veiculo.patins} Patin(s)`;
                }

                if (partner.veiculo.tamanhoPlataforma !== 0) {
                  const {tamanhoPlataforma} = partner.veiculo;
                  output += `| ${tamanhoPlataforma.toString().replace('.', ',')} Metro(s)`;
                }

                return output;
              };

              return {
                value: partner,
                label: `${partner.contato} - ${partner.nomeFantasia} ${
                  partner.veiculo.asaDelta === 1 ? '| Com ASA' : ''
                } ${formatNotEqualZero()}`,
              };
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const {placeholder, loadingPlaceholder, codigo} = this.props;
    const {closests} = this.state;
    //const { codigo } = this.props
    const partner = this.state.value;
    return (
      <div className="form-group">
        <div className="p-xs">
          <ReactPlaceholder
            type="text"
            ready={this.state.isReady && !this.props.isLoading}
            customPlaceholder={placeHolder}
            showLoadingAnimation>
            <AsyncSelect
              loadOptions={this.getOtherOptions}
              onChange={this.onChange}
              value={this.state.value}
              defaultOptions
              isClearable
            />
          </ReactPlaceholder>
        </div>
        <div className="col-md-6 p-xs">
          <button
            type="submit"
            className="btn btn-block btn-primary"
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                {
                  isReady: false,
                  closests: true,
                },
                () => {
                  this.props.handleClosestPartners(codigo);
                },
              );
            }}>
            Parceiros Próximos
          </button>
        </div>
        <div className="col-md-6 p-xs">
          <button
            type="submit"
            className="btn btn-block btn-primary"
            onClick={() => {
              this.props.handleCallPartner(codigo, partner);
            }}>
            Acionar Prestador
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    partners: state,
    closestPartners: state.partner.closestPartners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePartner: (value) => {
      dispatch(onPartnerChange(value));
    },
    onSuccessMessage: (message) => {
      dispatch(toastSuccess(message));
    },
    onErrorMessage: (message) => {
      dispatch(toastError(message));
    },
    handleCallPartner: (solicitationCode, partner) => {
      dispatch({
        type: `HANDLE_CALL_PARTNER`,
        solicitation: solicitationCode,
        partner: partner,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPartnerSolicitation);
