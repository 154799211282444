import React from 'react';

const FastButton = (props) => {
  const {labels, btnClick} = props;
  const sortedLabels = labels.sort((a, b) => a.nivelPrioridade - b.nivelPrioridade);
  const getTitleFromPriority = (priority) => {
    switch (priority) {
      case 0:
        return 'Contato Principal';
      case 1:
        return 'Contato 2';
      case 2:
        return 'Contato 3';
      default:
        return `Contato ${priority + 1}`;
    }
  };
  return (
    <div>
      {sortedLabels.map((label, i) => (
        <p
          key={'fastButton' + i}
          className={`badge badge-${label.class}`}
          style={{cursor: 'pointer', margin: '3px'}}
          onClick={() => btnClick(label.value)}>
          {label.nivelPrioridade !== undefined && (
            <strong>{getTitleFromPriority(label.nivelPrioridade)}</strong>
          )}{' '}
          {label.jobTitle ? `${label.jobTitle}: ${label.value}` : label.value}
        </p>
      ))}
    </div>
  );
};

export default FastButton;
