import React, {Component} from 'react';

import {connect} from 'react-redux';

import {Link} from 'react-router';

import axios from 'axios';

import {BASE_URL} from '../../config.js';

import RouteNames from '../../utils/route_names';

import {clearFormValues, setFormValues, setEditingMode} from '../../actions/form_actions';

import {
  loadPartnerList,
  createNewPartner,
  updateExistingPartner,
  deleteExistingPartner,
  partnerClick,
  resetPartnerPassword,
  resetPartnerLocation,
} from '../../actions/partner_actions';

import TableWrapper, {TableButton} from './TableWrapper';

import CustomModal, {
  ModalButton,
  doOpenModal,
  doCloseModal,
  onModalClose,
} from './CustomModal';

import {toastSuccess} from '../../actions/toast_actions';

import PartnerForm from '../forms/PartnerFormStateFull';

import Partner from './partnerAccordion';

import {Tabs, Tab} from 'react-bootstrap';
import {
  putBaseHistory,
  getBaseHistory,
  getCurrentBase,
  filterBaseList,
} from '../../actions/base_actions';

import {onServicesNext} from '../../actions/partner_service_action';
import BaseBalance from './BaseBalance.jsx';
import RelatoriosLog from './RelatoriosLog.js';
import LastConcluded from './LastConcluded';
import LastLost from './LastLost.jsx';
import CheckListUpload from './checkListUpload/CheckListUpload.jsx';
import BaseUpload from './baseUpload/BaseUpload.jsx';
import RegisterBase from './registerBase';
import towImg from '../../assets/img/towimg.png';
import {filterBases} from '../../api/base_helper.js';

const CONTEXT_PARTNER = 'CONTEXT_PARTNER';

class ListPartners extends Component {
  constructor(props) {
    super(props);
    this.handlePartners = this.handlePartners.bind(this);
    this.getBaseRoute = this.getBaseRoute.bind(this);
    this.handleRoutes = this.handleRoutes.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.renderComponent = this.renderComponent.bind(this);

    this.handleLog = this.handleLog.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.prePutLog = this.prePutLog.bind(this);

    this.state = {
      route_name: '',
      route: '',
      baseName: '',
      key: 2,
      renderBalance: false,
      log: '',
    };
  }

  componentDidMount() {
    this.props.loadPartnerList(this.props.location.pathname);
    this.handleRoutes(this.props.route);
    // this.props.filterBases(this.props.savedFilter);
  }
  prePutLog() {
    const response = this.props.putLog(this.state.baseCode, this.state.log);

    this.setState({log: ''});

    return response;
  }
  handleKeyUp(e) {
    const {putLog} = this.props;
    const {log} = this.state;
    if (e.key === 'Enter' && e.ctrlKey) {
      putLog(this.state.baseCode, log);
      this.setState({log: ''});
    }
  }
  handleLog(e) {
    this.setState({log: e.target.value});
  }

  handleTabs(key) {
    this.setState({
      key,
    });
  }
  handlePartners(partners) {
    return (
      <Partner
        partners={partners}
        onClickFunction={this.props.showExistingPartnerForm}
        onClickResetFunction={this.props.resetPasswod}
        onClickResetLocationFunction={this.props.resetLocation}
        base={this.props.currentBase}
      />
    );
  }

  getBaseRoute(baseID) {
    const {getCurrentBase} = this.props;
    getCurrentBase(baseID);
  }

  handleRoutes(route) {
    let path = route || '/';
    path = path.split('/');
    const route_name = path && path[1];

    if (this.state.baseCode !== path[2]) this.props.preGetbaseHistory(path[2]);

    this.setState({baseCode: path[2]});

    if (path.length > 2 && path[1] === 'bases') {
      this.getBaseRoute(path[2]);
    }
    this.setState({
      route_name,
      route,
    });
  }

  renderComponent(component) {
    this.setState({[component]: true});
  }
  noPartners() {
    return (
      <div style={{textAlign: 'center', padding: 50}}>
        <img width={300} style={{opacity: 0.2}} src={towImg} />
        <h1 style={{marginTop: 20}} className="weakTitle">
          Ainda não há motoristas cadastrados
        </h1>
      </div>
    );
  }

  render() {
    const {partnerList, location, currentBase, isEditing} = this.props;
    const {route_name, route, baseCode, renderBalance, key} = this.state;
    if (currentBase.isFetching) {
      return (
        <div className="spiner-example">
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1" />
            <div className="sk-double-bounce2" />
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <CustomModal title="Detalhes do Parceiro" context={CONTEXT_PARTNER} width={'60%'}>
          <PartnerForm link={location.pathname} isEditing={isEditing} />
        </CustomModal>
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div
                className="ibox-title"
                style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <Link to="/bases">
                    {' '}
                    <h5>Base </h5>
                  </Link>
                  <h5>{`/ ${currentBase.nomeFantasia}`}</h5>
                </div>
                {key === 2 && (
                  <div className="ibox-tools">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        this.props.showNewPartnerForm({});
                      }}>
                      <i className="fa fa-plus" />
                    </button>
                  </div>
                )}
              </div>
              <div className="ibox-content">
                <Tabs
                  id="general-base-tabs"
                  activeKey={this.state.key}
                  onSelect={this.handleTabs}>
                  <Tab eventKey={1} title="Cadastro">
                    {key === 1 && <RegisterBase type="update" />}
                  </Tab>
                  <Tab eventKey={2} title="Frota">
                    {partnerList && partnerList.length > 0
                      ? this.handlePartners(partnerList)
                      : this.noPartners()}
                  </Tab>
                  <Tab
                    onSelect={() => this.renderComponent('renderBalance')}
                    eventKey={3}
                    title="Fechamento">
                    {key === 3 && <BaseBalance baseCode={baseCode} />}
                  </Tab>
                  <Tab eventKey={4} title="Últimas Concluídas">
                    {key === 4 && <LastConcluded baseCode={baseCode} />}
                  </Tab>
                  <Tab eventKey={5} title="Últimas Perdidas">
                    {key === 5 && <LastLost baseCode={baseCode} />}
                  </Tab>
                  <Tab eventKey={6} title="Documentos">
                    {key === 6 && (
                      <div className="ibox-content" style={{overflow: 'auto'}}>
                        <BaseUpload baseCode={baseCode} />
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey={7} title="Histórico">
                    {key === 7 && (
                      <div className="ibox-content" style={{overflow: 'auto'}}>
                        <RelatoriosLog
                          logList={this.props.logList}
                          handleLog={this.handleLog}
                          log={this.state.log}
                          putLog={this.prePutLog}
                          onKeyUp={this.handleKeyUp}
                          profileId={this.props.login.user_info.usuario.tipoUsuario}
                        />
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetPasswod: (partner) => {
      dispatch(resetPartnerPassword(partner.codigo));
    },
    resetLocation: (partner, baseLocation) => {
      dispatch(resetPartnerLocation(partner.codigo, baseLocation));
    },
    doAfterUpdate: (link) => {
      dispatch(loadPartnerList(link));
      doCloseModal(CONTEXT_PARTNER);
      toastSuccess('Parceiro atualizado com sucesso');
    },
    doAfterCreate: (link) => {
      dispatch(loadPartnerList(link));
      doCloseModal(CONTEXT_PARTNER);
      toastSuccess('Parceiro adicionado com sucesso');
    },
    loadPartnerList: (link) => {
      dispatch(loadPartnerList(link));
    },
    showNewPartnerForm: (partner) => {
      //dispatch(clearFormValues(CONTEXT_PARTNER))
      dispatch(setEditingMode(CONTEXT_PARTNER, false));
      dispatch(partnerClick(partner));
      doOpenModal(CONTEXT_PARTNER);
    },
    showExistingPartnerForm: (data) => {
      dispatch(onServicesNext(false));
      dispatch(setEditingMode(CONTEXT_PARTNER, true));
      dispatch({type: 'CURRENT_PARTNER_FETCHED', value: data});
      dispatch(setFormValues(CONTEXT_PARTNER, data));
      doOpenModal(CONTEXT_PARTNER);
    },
    deletePartner: (partner) => {
      let doAfterDelete = () => toastSuccess('Parceiro removido com sucesso');
      dispatch(deleteExistingPartner(partner, doAfterDelete));
    },
    showPartnerCars: (partnerId) => {
      dispatch(loadPartnerVehicles(partnerId));
      doOpenModal(CONTEXT_VEHICLES);
    },
    clearForm: () => {
      dispatch(clearFormValues(CONTEXT_PARTNER));
    },
    putLog: (codigo, log) => {
      dispatch(putBaseHistory(codigo, log));
    },
    preGetbaseHistory: (baseCode) => {
      dispatch(getBaseHistory(baseCode));
    },
    getCurrentBase: (baseCode) => dispatch(getCurrentBase(baseCode)),

    filterBases: (filter) => dispatch(filterBaseList(filter)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    partnerList: state.partner.partnerList,
    route: state.routing.locationBeforeTransitions.pathname,
    logList: state.base.baseHistory,
    currentBase: state.base.currentBase,
    isEditing: state.forms['CONTEXT_PARTNER']
      ? state.forms['CONTEXT_PARTNER'].isEditing
      : false,
    savedFilter: state.base.savedFilter,
    login: state.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPartners);
