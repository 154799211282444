import React, {Component} from 'react';

import {reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import Toggle from 'react-toggle-button';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Tabs, Tab} from 'react-bootstrap';
import moment from 'moment';
import CustonTitleButton from '../common/titleButtons/CustonTitleButton';
import RouteMap from './RouteMap';
import RelatoriosLog from './RelatoriosLog';
import Rate from './Rate';
import ClocklIndicator from './ClockIndicator';
import {doGet} from '../../api/base/base_api_helper';
import CheckListUpload from './checkListUpload/CheckListUpload';
import ObservacaoTab from '../solicitacao/observacoes/observacoesTab.jsx';
import AtendimentoTab from '../solicitacao/atendimentoTab';
import EditInfo from '../followup/EditInfo';
import AddressAutoComplete from '../containers/AddressAutoComplete';
import DialAgent from '../common/dialAgent/DialAgent';
import CustomModal, {onModalClose, doOpenModal, doCloseModal} from '../common/CustomModal';
import CopyToClipboard from '../common/copyToClipboard/CopyToClipboard';
import {
  isFromCompany,
  hasReboqueMeProfile,
  hasCompanyProfile,
} from '../../utils/check_permission';
import {getFavoriteDestinations} from '../../api/address_helper';
import {
  CODIGO_DAF,
  CODIGO_GRINGO,
  CODIGO_JUSTOS,
  CODIGO_KAVAK,
  CODIGO_KOVI,
  CODIGO_LOCALIZA,
  CODIGO_MAPFRE,
  HISTORICO_MODO_PRIVADO,
  HISTORICO_MODO_PUBLICO,
} from '../../reducers/constants/index';
import AlterDestination from '../../components/followup/followUpItemButton/alterDestination';
import {toastError, toastSuccess} from '../../actions/toast_actions';
import {hasActivationAccess} from '../../utils/check_permission';
import {putLog} from '../../actions/relatorio_actions';
import {openWhatsApp} from '../../actions/followup_actions';
import {onAddressChange} from '../../actions/address_actions';
import {
  getPriceAndTimePredictionModal,
  sendSolicitationModal,
  onFieldChange,
  callPartners,
} from '../../actions/service_actions';
import AsyncSelect from 'react-select/lib/Async';
const iconEnabled = {
  background: '#25f4af',
  color: '#fff',
};
const iconDisabled = {
  background: '#5c40ff',
  color: '#fff',
};

class RelatoriosModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      solicitationObservation: '',
      currentSolicitationCode: null,
      options: [],
      value: '',
      callPartner: false,
      step: 1,
      enderecoDestino: '',
      favoriteDestinations: [],
      motivoPatio: '',
      motivoPatioError: false,
      callEmail: false,
      email: '',
    };
    this.handleTabs = this.handleTabs.bind(this);
    this.state = {
      key: 1,
      log: '',
      visibility: hasCompanyProfile() ? HISTORICO_MODO_PUBLICO : HISTORICO_MODO_PRIVADO,
    };
    // this.handleGeocoding = this.handleGeocoding.bind(this)
    this.handleLog = this.handleLog.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.prePutLog = this.prePutLog.bind(this);
    this.handleWpp = this.handleWpp.bind(this);
    this.onChangeVisibility = this.onChangeVisibility.bind(this);
    this.toggleStartCallPartner = this.toggleStartCallPartner.bind(this);
    this.toggleStartCallEmail = this.toggleStartCallEmail.bind(this);
  }

  onCheckChange = (name, value) => {
    this.props.onFieldChange(name, value);
  };
  toggleStartCallPartner = (value) => {
    this.setState({
      callPartner: !value,
    });
    this.onCheckChange('callPartner', !value);
  };
  handleTabs(key) {
    this.setState({
      key,
    });
  }
  prePutLog() {
    const {
      row: {codigo},
    } = this.props;
    this.props.putLog(this.state.log, codigo, this.state.visibility);
    this.setState({log: '', visibility: HISTORICO_MODO_PRIVADO});
  }
  truncateAddress = (address, maxLen) => {
    return address && address.length > maxLen ? address.substring(0, maxLen) + '...' : address;
  };
  handleWpp(e, number) {
    e.preventDefault();
    const {openWhatsApp} = this.props;
    if (number) openWhatsApp(number);
    else toastError('Número inválido');
  }
  handleLog(e) {
    this.setState({...this.state, log: e.target.value});
  }

  onChangeVisibility(event) {
    this.setState({visibility: event.target.value});
  }
  onChange = (value) => {
    this.setState({
      value: value,
    });
  };

  getClientNameOptionsDebounced = _.debounce((input, resolve, reject) => {
    const {options} = this.state;
    if (!input) {
      return Promise.resolve(options);
    }
    return doGet(`partners?_filter=${input}&&_status=PARCEIROATIVO,PARCEIROEMDESCANSO`)
      .then((res) => {
        if (res.sucesso === true) {
          if (res.listPartners) {
            return res.listPartners.map((partner) => {
              const formatNotEqualZero = () => {
                let output = '';

                if (partner.veiculo.patins !== 0) {
                  output += `| ${partner.veiculo.patins} Patin(s)`;
                }

                if (
                  partner.veiculo.tamanhoPlataforma &&
                  partner.veiculo.tamanhoPlataforma !== 0
                ) {
                  const {tamanhoPlataforma} = partner.veiculo;
                  output += `| ${tamanhoPlataforma.toString().replace('.', ',')} Metro(s)`;
                }

                return output;
              };

              return {
                value: partner,
                label: `${partner.contato} - ${partner.nomeFantasia} ${
                  partner.veiculo.asaDelta === 1 ? '| Com ASA' : ''
                } ${formatNotEqualZero()}`,
              };
            });
          }
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  }, 300);

  getClientNameOptions = (input) => {
    return new Promise((resolve, reject) => {
      this.getClientNameOptionsDebounced(input, resolve, reject);
    });
  };

  loadFavoriteDestinations = () => {
    const {row} = this.props;
    getFavoriteDestinations(row.empresa.codigo)
      .then((results) => {
        this.setState({
          favoriteDestinations: results.listFavoriteDestinations,
        });
      })
      .catch((error) => {
        console.log('Erro ao carregar lista de Endereços Favoritos: ' + error.message);
      });
  };

  componentDidMount() {
    this.loadFavoriteDestinations();
    onModalClose('CONTEXT_BASE', () => this.setState({key: 1}));
    onModalClose('CONTEXT_NOVA_SOLICITACAO', this.props.showFooter);
  }

  componentDidUpdate(prevProps) {
    if (this.props.row !== prevProps.row && this.props.row) {
      if (this.props.row.empresa.codigo) {
        this.loadFavoriteDestinations(this.props.row.empresa.codigo);
      }
    }
  }
  handleKeyUp(e) {
    const {putLog} = this.props;
    const {log} = this.state;
    if (e.key === 'Enter' && e.ctrlKey) {
      putLog(log);
      this.setState({log: ''});
    }
  }
  nextStep = () => {
    this.setState({step: this.state.step + 1});
  };
  handleAddres = (request_info) => {
    if (!this.props.logradouro) {
      return toastError('Insira um destino');
    }
    const enderecoDestino = {
      bairro: this.props.bairro,
      cep: this.props.cep,
      cidade: this.props.cidade,
      estado: this.props.estado,
      latitude: this.props.latDest,
      logradouro: this.props.logradouro,
      longitude: this.props.longDest,
      numero: this.props.numero,
      pais: this.props.pais,
    };
    request_info.enderecoOrigem = request_info.enderecoDestino;
    request_info.enderecoDestino = enderecoDestino;
    const auth = {
      password: null,
      username: null,
    };
    const sliderValue = this.state.sliderValue;
    getPriceAndTimePredictionModal(request_info, auth, sliderValue)
      .then((data) => {
        this.setState({
          price: data.price,
          time: data.time,
          cupomValido: data.cupomValido,
          originalPrice: data.originalPrice,
        });
      })
      .catch((error) => {
        console.log('Erro:', error);
      });
    this.nextStep();
  };
  toggleStartCallEmail = (value) => {
    this.setState({
      callEmail: !value,
    });
  };
  handleEmailChange = (event) => {
    this.setState({email: event.target.value});
  };
  isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email.trim());
  }
  toastInvalidEmail() {
    toastError('E-mail inválido.');
  }

  handleEmailClick = (emailValue) => {
    let currentEmail = this.state.email;
    if (currentEmail) {
      currentEmail += `;${emailValue}`;
    } else {
      currentEmail = emailValue;
    }

    this.setState({
      email: currentEmail,
    });
  };
  pressSolicitation = async (event, callPartner, selectedPartner, motivoPatio) => {
    const {originalPrice, email, callEmail} = this.state;
    event.valorCorrida = originalPrice;
    event.callPartner = callPartner;
    if (motivoPatio) {
      event.motivoPatio = motivoPatio;
    }

    const auth = {
      password: null,
      username: null,
    };

    if (email) {
      event.emailRecusa = email;
    }
    if (callEmail) {
      event.recusa = callEmail;
    }

    let partnerCode = selectedPartner ? selectedPartner.value.codigo : null;
    sendSolicitationModal(event, auth)
      .then((result) => {
        doCloseModal('CONTEXT_NOVA_SOLICITACAO');
        toastSuccess('Solicitação criada com sucesso!');
        if (partnerCode) {
          callPartners(partnerCode, result.solicitacao.codigo, true)
            .then((resp) => {
              toastSuccess('Parceiro acionado com sucesso!');
            })
            .catch((error) => {
              toastError(error.message);
            });
        }
      })
      .catch((error) => {
        if (error.campoErro === 'motivoPatio') {
          this.setState({motivoPatioError: true});
          toastError(error.message);
        } else {
          toastError(error.message);
        }
      });
  };
  render() {
    const {key} = this.state;
    const {
      row,
      isFetching,
      solicitationUpdated,
      solicitationUpdateFetching,
      handleSubmit,
      updateSolicitation,
      initialize,
      initialValues,
      putLog,
      propsLogList,
      login,
      newDestination,
      loadData,
      solicitationSelected,
    } = this.props;

    const {
      valorAcessorios,
      valorCorrida,
      valorHoraParada,
      valorHoraTrabalhada,
      valorParceiro,
      valorPedagio,
      valorServico,
      valorServicoParceiro,
      comissaoReboqueme,
    } = initialValues;

    const {
      cliente,
      empresa,
      enderecoDestino,
      enderecoOrigem,
      formaPagamento,
      usuario,
      respostaAceita,
      veiculoCliente,
      historico,
      codigo,
      avaliado,
      avaliacaoCliente,
      observacao,
      situacaoVeiculo,
      motivoConclusaoManual,
    } = row;

    const isOrigemPatio = solicitationSelected.origemPatio;
    const isDestinoPatio = solicitationSelected.destinoPatio;
    const formatEndereco = (endereco) => {
      let enderecoCompleto = `${endereco.logradouro}, ${endereco.numero}`;
      if (endereco.bairro) {
        enderecoCompleto += ` - ${endereco.bairro}`;
      }
      enderecoCompleto += `, ${endereco.cidade}`;
      return enderecoCompleto;
    };
    function calcularDiferencaEmMinutos(data1, data2) {
      const dataInicial = moment(data1, 'DD/MM/YYYY HH:mm:ss');
      const dataFinal = moment(data2, 'DD/MM/YYYY HH:mm:ss');
      // Verifica se as datas são iguais
      if (dataFinal.isSame(dataInicial)) {
        return 0;
      }
      // Calcula a diferença em minutos
      const diffMinutos = dataFinal.diff(dataInicial, 'minutes');
      return diffMinutos;
    }
    const agendadoDiff =
      row &&
      (row.dataCadastroAgendamento || row.dataAgendada) &&
      row.dataCadastro &&
      row.respostaAceita
        ? calcularDiferencaEmMinutos(row.dataAgendada, row.respostaAceita.dataCadastro)
        : null;

    const aceitoAbertoDiff =
      row.respostaAceita && row.dataCadastro
        ? calcularDiferencaEmMinutos(row.dataCadastro, row.respostaAceita.dataCadastro)
        : '-';

    const chegadaAceitoDiff =
      row.dataChegadadoParceiro && row.respostaAceita
        ? calcularDiferencaEmMinutos(
            row.respostaAceita.dataCadastro,
            row.dataChegadadoParceiro,
          )
        : '-';
    const conclusaoChegadaDiff =
      row.dataConcluida && row.dataChegadadoParceiro
        ? calcularDiferencaEmMinutos(row.dataChegadadoParceiro, row.dataConcluida)
        : '-';
    const requestOrigin =
      row.enderecoOrigem && row.enderecoOrigem.latitude
        ? {
            lat: parseFloat(row.enderecoOrigem.latitude),
            lng: parseFloat(row.enderecoOrigem.longitude),
          }
        : null;
    const requestDestination =
      row.enderecoDestino && row.enderecoDestino.latitude
        ? {
            lat: parseFloat(row.enderecoDestino.latitude),
            lng: parseFloat(row.enderecoDestino.longitude),
          }
        : null;

    const parceiroAcionado =
      row.respostaAceita && row.respostaAceita.acionamento
        ? row.respostaAceita.acionamento.filter(
            (item) => item.parceiro.codigo === row.respostaAceita.parceiro.codigo,
          )[0]
        : null;

    const statusSolicitacao = row.status && row.status.codigo ? row.status.codigo : null;
    return (
      <div className="modal-body">
        <CustomModal context={'ALTER_DESTINATION'} title="Alterar Destino">
          <AlterDestination
            newDestination={newDestination}
            service={(function () {
              return {
                ...row,
                atualizarPreco: true,
                codigoSolicitacao: row.codigo,
              };
            })()}
            context={'ALTER_DESTINATION'}
            handleRefresh={(novoValorCorrida) => {
              row.enderecoDestino = newDestination;
              row.valorCorrida = novoValorCorrida;
              this.forceUpdate();
            }}
            handleClose={(context) => {
              this.forceUpdate();
              doCloseModal(context);
            }}
            valueActual={row.valorCorrida}
            destinationActual={row.enderecoDestino}
          />
        </CustomModal>
        {isFetching ? (
          <div className="spiner-example">
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1" />
              <div className="sk-double-bounce2" />
            </div>
          </div>
        ) : (
          <div>
            <Tabs id="relatorios-tabs" activeKey={this.state.key} onSelect={this.handleTabs}>
              <Tab eventKey={1} title="Solicitação">
                {key === 1 && (
                  <div>
                    <div className="col-md-8" style={{borderRight: '1px solid #e5e5e5'}}>
                      <div className="row form-group">
                        <div className="form-group custom-tab col-md-3">
                          <label>Nome do Cliente</label>
                          <br />
                          <span>{cliente.nome}</span>
                        </div>
                        <div className="form-group custom-tab col-md-3">
                          <label>Telefone</label>
                          <br />
                          {login.user_info.usuario.tipoUsuario === 1 &&
                            cliente.telefoneCelular && (
                              <span>
                                <DialAgent phoneNumber={cliente.telefoneCelular} />
                                <CopyToClipboard
                                  textToBeCopied={cliente.telefoneCelular}
                                  title={cliente.telefoneCelular}
                                  style={{marginLeft: 5}}
                                />
                              </span>
                            )}
                        </div>
                        <div className="form-group custom-tab col-md-3">
                          <label>CPF</label>
                          <br />
                          {login.user_info.usuario.tipoUsuario === 1 && (
                            <span>{cliente.cpf}</span>
                          )}
                        </div>
                        <div className="form-group custom-tab col-md-3">
                          <label>Veículo</label>
                          <br />
                          <span>{`${veiculoCliente.modelo} / ${
                            veiculoCliente.placa
                              ? veiculoCliente.placa
                              : veiculoCliente.chassis
                          }`}</span>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="form-group custom-tab col-md-6">
                          <label>Endereço de Origem</label>

                          <br />
                          <span>
                            {`${enderecoOrigem.logradouro},
                                ${enderecoOrigem.numero} -
                                ${enderecoOrigem.bairro},
                                ${enderecoOrigem.cidade}`}
                            {isOrigemPatio && (
                              <div
                                title="No Pátio"
                                style={{marginLeft: '5px'}}
                                className={`label badge-primary-adapted`}>
                                Pátio
                              </div>
                            )}
                          </span>
                        </div>
                        <div className="form-group custom-tab col-md-6">
                          <div className="row">
                            <div className="col-md-7">
                              <label>Endereço de Destino </label>
                            </div>
                            {login.user_info.usuario.tipoUsuario === 1 && (
                              <div className="col-md-5">
                                <a
                                  onClick={() => {
                                    doOpenModal('ALTER_DESTINATION');
                                  }}>
                                  Alterar Destino
                                </a>
                              </div>
                            )}
                          </div>

                          {enderecoDestino ? (
                            <span>
                              {formatEndereco(enderecoDestino)}
                              {isDestinoPatio && (
                                <div
                                  title="No Pátio"
                                  style={{marginLeft: '5px'}}
                                  className={`label badge-primary-adapted`}>
                                  Pátio
                                </div>
                              )}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <EditInfo
                        solicitation={row}
                        reloadData={loadData}
                        showFooter={this.props.showFooter}
                        hideFooter={this.props.hideFooter}
                      />

                      <CustomModal
                        title="Nova Solicitação"
                        context={'CONTEXT_NOVA_SOLICITACAO'}>
                        <div className="col-md-12">
                          {this.state.step === 1 ? (
                            <div>
                              <label>Destino</label>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{flexGrow: 1}}>
                                  <AddressAutoComplete
                                    initialValue={this.state.enderecoDestino}
                                    favoriteDestinations={this.state.favoriteDestinations}
                                    placeholder="Endereço de Destino"
                                    id="enderecoDestino"
                                    onOptionSelected={(endereco) =>
                                      this.setState({enderecoDestino: endereco})
                                    }
                                  />
                                </div>
                                {row.ssPDF && (
                                  <a
                                    href={row.ssPDF}
                                    target="_blank"
                                    style={{marginLeft: '10px'}}
                                    className="btn btn-custonmod">
                                    Acessar ssPDF
                                  </a>
                                )}
                              </div>

                              <div
                                className="form-group"
                                style={{
                                  marginTop: '10px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                <button
                                  className="btn btn-block btn-primary"
                                  type="submit"
                                  onClick={() => this.handleAddres(row)}>
                                  Avançar para calcular preço
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}>
                                <label>Novo Endereço</label>
                                <p>{this.truncateAddress(this.props.logradouro, 75)}</p>
                                <label>Valor atual</label>
                                <p style={{fontSize: '25px'}}> R${this.state.originalPrice}</p>
                              </div>

                              <div className="form-group">
                                <label className="form-label">Parceiro</label>
                                <AsyncSelect
                                  loadOptions={this.getClientNameOptions}
                                  onChange={this.onChange}
                                  value={this.state.value}
                                  defaultOptions
                                  isClearable
                                />
                              </div>

                              <div className="form-group toggle-group">
                                <label style={{marginBottom: '14px'}}>
                                  Acionamento Automático
                                </label>
                                <Toggle
                                  inactiveLabel={'Não'}
                                  activeLabel={'Sim'}
                                  colors={{
                                    activeThumb: {base: `#FFF`},
                                    active: {base: `#5c40ff`},
                                    inactiveThumb: {base: `#FFF`},
                                    inactive: {base: `#FFF`},
                                  }}
                                  inactiveLabelStyle={{color: `#2f4050`}}
                                  value={this.state.callPartner}
                                  thumbStyle={{
                                    borderRadius: 2,
                                    borderColor: `#5c40ff`,
                                  }}
                                  trackStyle={{borderRadius: 2}}
                                  onToggle={this.toggleStartCallPartner}
                                />
                              </div>
                              {this.state.motivoPatioError && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                  }}>
                                  <label style={{textAlign: 'center'}}>
                                    Informe o motivo Pátio
                                  </label>
                                  <textarea
                                    value={this.state.motivoPatio}
                                    onChange={(e) =>
                                      this.setState({motivoPatio: e.target.value})
                                    }
                                    style={{
                                      width: '100%',
                                      marginBottom: '10px',
                                      border: '1px solid hsl(0, 0%, 80%)',
                                    }}
                                  />
                                </div>
                              )}
                              {this.state.motivoPatioError && (
                                <div className="form-group toggle-group">
                                  <label style={{marginBottom: '14px'}}>Email Recusa</label>
                                  <Toggle
                                    inactiveLabel={'Não'}
                                    activeLabel={'Sim'}
                                    colors={{
                                      activeThumb: {base: `#FFF`},
                                      active: {base: `#5c40ff`},
                                      inactiveThumb: {base: `#FFF`},
                                      inactive: {base: `#FFF`},
                                    }}
                                    inactiveLabelStyle={{color: `#2f4050`}}
                                    value={this.state.callEmail}
                                    thumbStyle={{
                                      borderRadius: 2,
                                      borderColor: `#5c40ff`,
                                    }}
                                    trackStyle={{borderRadius: 2}}
                                    onToggle={this.toggleStartCallEmail}
                                  />
                                </div>
                              )}
                              {this.state.callEmail && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginBottom: '15px',
                                  }}>
                                  <label>Email</label>
                                  <input
                                    type="text"
                                    placeholder="Digite o e-mail"
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                    className="form-control"
                                  />
                                  <p
                                    className={`badge badge`}
                                    style={{cursor: 'pointer', marginTop: '10px'}}
                                    onClick={() =>
                                      this.handleEmailClick('agendamento.reboque@localiza.com')
                                    }>
                                    agendamento.reboque@localiza.com
                                  </p>
                                  <p
                                    className={`badge badge`}
                                    style={{cursor: 'pointer'}}
                                    onClick={() =>
                                      this.handleEmailClick('daniel.freitas@localiza.com')
                                    }>
                                    daniel.freitas@localiza.com
                                  </p>
                                </div>
                              )}
                              <div className="form-group">
                                <button
                                  className="btn btn-block btn-primary"
                                  type="submit"
                                  onClick={() =>
                                    this.pressSolicitation(
                                      row,
                                      this.state.callPartner,
                                      this.state.value,
                                      this.state.motivoPatio,
                                    )
                                  }>
                                  Criar Solitação
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </CustomModal>
                    </div>
                    <div className="col-md-4" style={{borderLeft: '1px solid #e5e5e5'}}>
                      <div
                        className="row form-group"
                        style={{
                          borderBottom: '1px solid #e5e5e5',
                          marginLeft: '0px',
                        }}>
                        <div className="form-group custom-tab col-md-12">
                          <span>{row.codigo}</span>
                        </div>
                        {row.ownId && (
                          <div className="form-group custom-tab col-md-12">
                            <span>Protocolo: {row.ownId}</span>
                          </div>
                        )}

                        <div className="form-group custom-tab col-md-12">
                          <label>Empresa</label>
                          <br />
                          <span>{empresa.nomeFantasia}</span>
                        </div>
                        <div className="form-group custom-tab col-md-6">
                          <label>Atendente</label>
                          <br />
                          <span className="">{usuario}</span>
                        </div>
                        <div className="form-group custom-tab col-md-6">
                          <label>F.Pag</label>
                          <br />
                          <span className="">{formaPagamento.descricao}</span>
                        </div>
                      </div>

                      <div className="row form-group" style={{marginLeft: '0px'}}>
                        {!hasCompanyProfile() && (
                          <div className="form-group custom-tab col-md-12">
                            <label>Nome Prestador</label>
                            <br />
                            <span>
                              {respostaAceita ? respostaAceita.parceiro.nomeFantasia : '-'}
                            </span>
                          </div>
                        )}
                        {login.user_info.usuario.tipoUsuario === 1 &&
                          statusSolicitacao === 'CONCLUIDA' &&
                          parceiroAcionado &&
                          parceiroAcionado.usuario &&
                          parceiroAcionado.usuario.nome && (
                            <div className="form-group custom-tab col-md-12">
                              <label>Acionado por</label>
                              <br />
                              <span>{parceiroAcionado.usuario.nome}</span>
                            </div>
                          )}
                        {login.user_info.usuario.tipoUsuario === 1 &&
                          respostaAceita &&
                          respostaAceita.parceiro &&
                          !hasCompanyProfile() && (
                            <div className="form-group custom-tab col-md-12">
                              <label>Telefone</label>
                              <br />
                              {respostaAceita.parceiro.telefoneCelular ? (
                                <span>
                                  <DialAgent
                                    phoneNumber={respostaAceita.parceiro.telefoneCelular}
                                  />
                                  <CopyToClipboard
                                    textToBeCopied={respostaAceita.parceiro.telefoneCelular}
                                    title={respostaAceita.parceiro.telefoneCelular}
                                    style={{marginLeft: 5}}
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        {respostaAceita &&
                        respostaAceita.parceiro.linkCnh &&
                        !hasCompanyProfile() ? (
                          <div className="form-group custom-tab col-md-12">
                            <label>CNH</label>
                            <a
                              href={respostaAceita.parceiro.linkCnh}
                              target="_blank"
                              style={{display: 'block'}}
                              rel="noreferrer">
                              Ver CNH
                            </a>
                          </div>
                        ) : null}
                        <div className="form-group custom-tab col-md-12">
                          <label>Avaliação</label>
                          <br />

                          <Rate
                            avaliado={!!avaliacaoCliente}
                            stars={avaliacaoCliente ? avaliacaoCliente.nota : 0}
                          />
                        </div>
                      </div>
                      {hasActivationAccess() && (
                        <button
                          className="btn btn-primary btn-edit-info"
                          style={{marginRight: '40px', marginTop: '4px'}}
                          onClick={() => {
                            this.setState(
                              {
                                step: 1,
                                value: '',
                                price: null,
                                time: null,
                                originalPrice: null,
                                enderecoDestino: '',
                                callPartner: false,
                                motivoPatioError: false,
                                callEmail: false,
                                email: '',
                                motivoPatio: '',
                              },
                              () => {
                                doOpenModal('CONTEXT_NOVA_SOLICITACAO');
                                this.props.hideFooter();
                              },
                            );
                          }}>
                          Destino Pátio
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </Tab>
              <Tab eventKey={2} title="Complemento">
                {key === 2 && (
                  <ObservacaoTab
                    observacao={observacao}
                    situacaoVeiculo={situacaoVeiculo}
                    motivoConclusaoManual={motivoConclusaoManual}
                  />
                )}
              </Tab>
              {row && row.atendimento && (
                <Tab eventKey={3} title="Atendimento">
                  {key === 3 && <AtendimentoTab atendimento={row.atendimento} />}
                </Tab>
              )}

              {(hasReboqueMeProfile() || hasCompanyProfile()) &&
                !isFromCompany(CODIGO_DAF) &&
                !isFromCompany(CODIGO_KOVI) &&
                row.respostaAceita && (
                  <Tab eventKey={4} title="Timeline">
                    {key === 4 && (
                      <div>
                        <div className="col-md-8" style={{padding: '5px'}}>
                          {/* <SolicitationMap
                                                    id="relatoriosMap"
                                                    style={{ height: '380px' }}
                                                    requestOrigin={requestOrigin}
                                                    requestDestination={requestDestination}
                                                    tipoServico={row.tipoServico ? row.tipoServico.codigo : 'REBOQUE'}
                                                    zoom={20}
                                                    partnerLocation={{ lat: row.respostaAceita.latitude, lng: row.respostaAceita.longitude }}
                                                    mapType={'RELATORIOS'}
                                                /> */}
                          <RouteMap
                            id="relatorioMap"
                            requestOrigin={requestOrigin}
                            requestDestination={requestDestination}
                            style={{height: '380px'}}
                            partnerLocation={
                              row.respostaAceita.latitude
                                ? {
                                    lat: row.respostaAceita.latitude,
                                    lng: row.respostaAceita.longitude,
                                  }
                                : {lat: 0, lng: 0}
                            }
                          />
                        </div>
                        <div className="col-md-4" style={{marginTop: '45px'}}>
                          <VerticalTimeline layout="1-column">
                            {agendadoDiff ? (
                              <span>
                                <ClocklIndicator top={'95px'} text={agendadoDiff} />
                                <ClocklIndicator top={'170px'} text={chegadaAceitoDiff} />
                                <ClocklIndicator top={'235px'} text={conclusaoChegadaDiff} />
                              </span>
                            ) : (
                              <span>
                                <ClocklIndicator top={'30px'} text={aceitoAbertoDiff} />
                                <ClocklIndicator top={'90px'} text={chegadaAceitoDiff} />
                                <ClocklIndicator
                                  top={'163px'}
                                  text={conclusaoChegadaDiff}
                                />{' '}
                              </span>
                            )}

                            {row.dataCadastroAgendamento && (
                              <VerticalTimelineElement
                                className="vertical-timeline-element--work time-line-enabled"
                                iconStyle={iconEnabled}>
                                <span>
                                  <strong>Aberto em</strong>
                                </span>
                                <br />
                                <span>{`${row.dataCadastroAgendamento}`}</span>
                              </VerticalTimelineElement>
                            )}

                            <VerticalTimelineElement
                              className="vertical-timeline-element--work time-line-enabled"
                              iconStyle={iconEnabled}>
                              <span>
                                <strong>Aberto em</strong>
                              </span>
                              <br />
                              <span>{`${row.dataCadastro}`}</span>
                            </VerticalTimelineElement>

                            {row.dataAgendada && (
                              <VerticalTimelineElement
                                className="vertical-timeline-element--work time-line-enabled"
                                iconStyle={iconEnabled}>
                                <span>
                                  <strong>Agendado para</strong>
                                </span>
                                <br />
                                <span>{`${row.dataAgendada}`}</span>
                              </VerticalTimelineElement>
                            )}
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work time-line-enabled"
                              iconStyle={iconEnabled}>
                              <span>
                                <strong>Aceito em</strong>
                              </span>
                              <br />
                              <span>
                                {row.respostaAceita ? row.respostaAceita.dataCadastro : '-'}
                              </span>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work"
                              iconStyle={
                                row.dataChegadadoParceiro
                                  ? row.dataChegadadoParceiro
                                    ? iconEnabled
                                    : iconDisabled
                                  : iconDisabled
                              }>
                              <span>
                                <strong>Chegada do Parceiro</strong>
                              </span>
                              <br />
                              <span>
                                {row.respostaAceita
                                  ? row.dataChegadadoParceiro
                                    ? row.dataChegadadoParceiro
                                    : `Prévia: ${row.respostaAceita.tempoEspera} minutos`
                                  : '-'}
                              </span>
                            </VerticalTimelineElement>

                            <VerticalTimelineElement
                              className="vertical-timeline-element--work"
                              iconStyle={iconEnabled}>
                              <span>
                                <strong>Conclusão</strong>
                              </span>
                              <br />
                              <span>{row.dataConcluida}</span>
                            </VerticalTimelineElement>
                          </VerticalTimeline>
                        </div>
                      </div>
                    )}
                  </Tab>
                )}
              {
                <Tab eventKey={5} title="Histórico">
                  {key === 5 && (
                    <RelatoriosLog
                      logList={propsLogList.length > 0 ? propsLogList : historico}
                      handleLog={this.handleLog}
                      log={this.state.log}
                      putLog={this.prePutLog}
                      onKeyUp={this.handleKeyUp}
                      visibility={this.state.visibility}
                      onChangeVisibility={this.onChangeVisibility}
                      profileId={login.user_info.usuario.tipoUsuario}
                    />
                  )}
                </Tab>
              }
              <Tab eventKey={6} title="Documentos">
                {key === 6 && (
                  <CheckListUpload solicitationCode={codigo} context="RELATORIO_MODAL" />
                )}
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newDestination: state.service.enderecoDestino,
    propsLogList: state.relatorio.log,
    solicitationSelected: state.relatorio.selectedSolicitation,
    logradouro: state.service.enderecoDestino.logradouro,
    latDest: state.service.enderecoDestino.latitude,
    longDest: state.service.enderecoDestino.longitude,
    bairro: state.service.enderecoDestino.bairro,
    cep: state.service.enderecoDestino.cep,
    cidade: state.service.enderecoDestino.cidade,
    estado: state.service.enderecoDestino.estado,
    numero: state.service.enderecoDestino.numero,
    pais: state.service.enderecoDestino.pais,
    initialValues: {
      valorServico: state.relatorio.selectedSolicitation.valorServico,
      valorServicoParceiro: state.relatorio.selectedSolicitation.valorServicoParceiro,
      valorParceiro: state.relatorio.selectedSolicitation.valorParceiro,
      acessorios: state.relatorio.selectedSolicitation.valorAcessorios,
      valorPedagio: state.relatorio.selectedSolicitation.valorPedagio,
      horaParada: state.relatorio.selectedSolicitation.valorHoraParada,
      horaTrabalhada: state.relatorio.selectedSolicitation.valorHoraTrabalhada,
      valorCorrida: state.relatorio.selectedSolicitation.valorCorrida,
      comissaoReboqueme: state.relatorio.selectedSolicitation.comissaoReboqueme,
      observacao: state.relatorio.selectedSolicitation.observacao,
    },
    login: state.login,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAddressChange,
      putLog,
      openWhatsApp,
      onFieldChange,
    },
    dispatch,
  );

RelatoriosModalBody = reduxForm({
  enableReinitialize: true,
  form: 'relatorio',
})(RelatoriosModalBody);

export default connect(mapStateToProps, mapDispatchToProps)(RelatoriosModalBody);
