import React, {Component} from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactTableCommon from '../balance/reactTableBalances';
import {getBaseBalance} from '../../actions/base_actions';
import moment from 'moment';
import SideDropDown from '../balance/sideDropDown';
import {
  sendIndividualEmail,
  billetButtonActions,
  sendNewBillet,
} from '../balance/balanceActions';
import ReactTooltip from 'react-tooltip';
import InvoiceCard from '../balance/invoiceCard';

class BaseBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredBaseBalance: [],
      baseBalance: [],
      pay: 0,
      receive: 0,
      total: 0,
    };
    this.getTable = this.getTable.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.typeIcon = this.typeIcon.bind(this);
    this.valueFiled = this.valueFiled.bind(this);
    this.cumulativeFiled = this.cumulativeFiled.bind(this);
    this.services = this.services.bind(this);
    this.doneButton = this.doneButton.bind(this);
    this.invoiceButton = this.invoiceButton.bind(this);
    this.billetButton = this.billetButton.bind(this);
    this.pdfButton = this.pdfButton.bind(this);
    this.seenField = this.seenField.bind(this);
    this.getHeader = this.getHeader.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {getBaseBalance, baseCode, baseBalance} = nextProps;

    if (baseCode !== this.props.baseCode) getBaseBalance(baseCode);

    if (baseBalance && baseBalance.length > 0) {
      this.getHeader(this.getData(baseBalance));
      this.setState({
        baseBalance: this.getData(baseBalance),
        filteredBaseBalance: this.getData(baseBalance),
      });
    }
  }
  componentDidMount() {
    const {getBaseBalance, baseCode} = this.props;
    if (baseCode) {
      getBaseBalance(baseCode);
    }
  }

  getHeader(baseBalance) {
    let pay = 0,
      receive = 0,
      total = 0,
      services = 0;
    baseBalance.forEach((b, i) => {
      services += b.atendimentos;
      if (!b.concluido) {
        if (b.valor > 0) pay += b.valor;
        else if (b.valor < 0) receive += b.valor;
      }
    });
    receive = receive * -1;
    total = receive + pay;
    total = receive - pay;
    this.setState({pay, receive, total, services});
  }

  getData(baseBalance) {
    if (baseBalance) {
      return baseBalance.map((b) => ({
        type: b.base ? 'guincho' : 'empresa',
        code: b.codigo ? b.codigo : '',
        email: b.base ? b.base.email : b.empresa.email,
        nomeFantasia: b.base ? b.base.nomeFantasia : b.empresa.nomeFantasia,
        dataMovimento: b.lancamentoFinanceiro
          ? moment(b.lancamentoFinanceiro.dataMovimento, 'DD/MM/YYYY HH:mm').format(
              'DD/MM/YYYY',
            )
          : '',
        valor: b.valor ? parseFloat(b.valor) : '',
        acumulado: b.acumulado,
        valorAcumulado: b.valorAcumulado,
        atendimentos: b.atendimentos ? b.atendimentos : '',
        concluido: b.lancamentoFinanceiro.processada ? b.lancamentoFinanceiro.processada : '',
        billetLink: b.lancamentoFinanceiro.linkBoleto
          ? b.lancamentoFinanceiro.linkBoleto
          : null,
        nfLink: b.linkNotaFiscal ? b.linkNotaFiscal : null,
        seen: b.visualizada ? b.visualizada : null,
        emailSent: b.emailEnviado ? b.emailEnviado : null,
        emailSentDate: b.dataEmailEnviado ? b.dataEmailEnviado : null,
        seenDate: b.dataVisualizada ? b.dataVisualizada : null,
        lancamentoId: b.lancamentoFinanceiro.codigo,
        balanceId: b.codigoFechamento,
        linkNotaFiscalTomada: b.linkNotaFiscalTomada,
        dataRecebimentoNotaFiscalTomada: b.dataRecebimentoNotaFiscalTomada,
      }));
    } else return [];
  }

  handleNotaFiscalPrestador = (row, cel) => {
    return cel.linkNotaFiscalTomada ? (
      <button
        data-tip={cel.dataRecebimentoNotaFiscalTomada || ''}
        onClick={() => window.open(cel.linkNotaFiscalTomada, '_blank')}
        className="btn btn-sm btn-default">
        <i className="fas fa-file-invoice" />
      </button>
    ) : null;
  };

  clearSearch() {
    const {invoices} = this.state;
    this.setState({
      filteredInvoices: invoices,
    });
  }

  typeIcon(cell, row) {
    return (
      <span>
        <i className={row.type === 'empresa' ? 'fas fa-building' : 'fas fa-truck'} />
      </span>
    );
  }

  valueFiled(cell, row) {
    return (
      <div
        className="invoice-values"
        style={row.valor > 0 ? {color: '#4ab858'} : {color: 'red'}}>
        {parseFloat(row.valor).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    );
  }

  cumulativeFiled(cell, row) {
    return (
      <div
        className="invoice-values"
        style={row.valorAcumulado > 0 ? {color: '#4ab858'} : {color: 'red'}}>
        {parseFloat(row.valorAcumulado).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    );
  }

  services(cell, row) {
    return <div className="align-right">{row.atendimentos}</div>;
  }

  billetButton(cell, row) {
    const {billetButtonActions, sendNewBillet} = this.props;
    const {balanceId} = row;
    const {baseBalance} = this.state;
    const billetButtonList = [
      {
        label: 'Vizualisar',
        func: () => window.open(row.billetLink, 'blank'),
        icon: 'fas fa-eye',
      },
      // { label: 'Segunda Via', func: () => billetButtonActions(balanceId, row.code, row.billetLink), icon: 'fas fa-retweet' },
      {
        label: 'Reenviar',
        func: () => sendNewBillet(row.lancamentoId),
        icon: 'far fa-share-square',
      },
    ];
    const last = baseBalance[baseBalance.length - 1].code === row.code;
    return row.billetLink ? <SideDropDown buttonList={billetButtonList} last={last} /> : null;
  }

  doneButton(cell, row) {
    return (
      <button
        className={`btn btn-sm btn-default`}
        style={
          row.concluido
            ? {color: '#4ab858', textAlign: 'center'}
            : {color: '#c1c1c1', textAlign: 'center'}
        }
        // onClick={() => {
        //     this.setState({ modal: 'close', closeId: this.props.invoices.filter(invoice => invoice.codigo === row.code)[0].lancamentoFinanceiro.codigo },
        //         () => doOpenModal('CONTEXT_BASE'))
        // }}
      >
        {<i className="fas fa-check" />}
      </button>
    );
  }

  invoiceButton(cell, row) {
    return row.nfLink ? (
      <button
        data-tip="Ver nota fiscal"
        className={`btn btn-sm btn-default`}
        onClick={() => window.open(row.nfLink)}>
        <i className="fas fa-file-invoice-dollar" />
      </button>
    ) : null;
  }

  pdfButton(cell, row) {
    const {balanceId} = row;
    return (
      <button
        data-tip="Ver relatório"
        className={`btn btn-sm btn-default`}
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_V2_URL}resume?resume=${balanceId}&invoice=${row.code}&internal=true`,
            '_blank',
          )
        }>
        <i className="far fa-file-pdf" />
      </button>
    );
  }
  seenField(cell, row) {
    const {sendIndividualEmail, emailSentId} = this.props;
    const {balanceId} = row;
    let comp = '';
    let title = '';
    if (row.seen) {
      comp = <i className="fas fa-eye" />;
      title = row.seenDate;
    } else if (row.emailSent || emailSentId === row.code) {
      comp = <i className="fas fa-share-square" />;
      title = row.emailSentDate;
    } else {
      comp = <i className="fas fa-envelope" />;
      title = 'Enviar email';
    }

    return (
      <button
        className={`btn btn-sm btn-default`}
        data-tip={title}
        onClick={() => {
          sendIndividualEmail(balanceId, row.code, row.nomeFantasia, row.email);
        }}>
        {comp}
      </button>
    );
  }

  onSearchChange = (input) => {
    let {baseBalance, filteredBaseBalance} = this.state;
    if (input) {
      filteredBaseBalance = baseBalance.filter((row) => {
        return row.code.toLowerCase().includes(input.toLowerCase());
      });
      this.setState({
        filteredBaseBalance,
      });
    } else {
      this.setState({
        filteredBaseBalance: baseBalance,
      });
    }
  };
  getTable() {
    const {baseBalanceFetching} = this.props;
    const {filteredBaseBalance} = this.state;
    console.log('filteredBaseBalance', filteredBaseBalance);
    return (
      <ReactTableCommon
        data={filteredBaseBalance}
        onSearchChange={this.onSearchChange}
        isFetching={baseBalanceFetching}
        clearSearch={this.clearSearch}>
        <TableHeaderColumn
          dataField={'type'}
          dataSort={true}
          dataFormat={this.typeIcon}
          width="50px"
        />
        <TableHeaderColumn dataField={'dataMovimento'} dataSort={true}>
          Data
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField={'valor'}
          dataSort={true}
          width="100px"
          dataFormat={this.valueFiled}>
          Valor
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField={'valorAcumulado'}
          dataSort={true}
          width="100px"
          dataFormat={this.cumulativeFiled}>
          Acumulado
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField={'atendimentos'}
          dataSort={true}
          dataFormat={this.services}
          width="100px">
          Serviços
        </TableHeaderColumn>
        <TableHeaderColumn isKey={true} dataField={'code'}>
          Codigo
        </TableHeaderColumn>
        <TableHeaderColumn
          width="50px"
          dataFormat={this.doneButton}
          dataSort={true}
          dataField={'concluido'}
        />
        <TableHeaderColumn
          dataField="button"
          dataFormat={this.handleNotaFiscalPrestador}
          width="50px"
        />
        <TableHeaderColumn dataField="button" dataFormat={this.invoiceButton} width="50px" />
        <TableHeaderColumn
          dataField="button"
          columnClassName="billet-class"
          dataFormat={this.billetButton}
          width="50px"
        />
        <TableHeaderColumn dataField="button" dataFormat={this.pdfButton} width="50px" />
        <TableHeaderColumn
          width="50px"
          dataField={'seen'}
          dataSort={true}
          dataFormat={this.seenField}
        />
      </ReactTableCommon>
    );
  }

  toReceive(baseBalance) {
    return baseBalance.reduce((total, current) =>
      total + current.concluido ? 0 : current.valor,
    );
  }

  render() {
    const {pay, receive, total, services} = this.state;

    return (
      <div className="">
        <ReactTooltip id="base-balance-tip" multiline={true} html={true} />
        <div className="ibox">
          {/* <div className="ibox-title">
                            <Link to="/balance"> <h5>Fechamento / </h5></Link><h5>{this.id}</h5>
                            <div className="ibox-tools">
                            </div>
                        </div> */}
          <div className="ibox-content" style={{padding: '20px 5px 20px 5px'}}>
            <div className="cards-container">
              <InvoiceCard
                icon={'fas fa-truck-moving'}
                value={services}
                title={'Atendimentos'}
                cClass={'navy-bg'}
              />
              <InvoiceCard
                icon={'fas fa-plus'}
                value={receive.toFixed(2)}
                title={'A Receber (R$)'}
                cClass={'green-bg'}
              />
              <InvoiceCard
                icon={'fas fa-minus'}
                value={pay.toFixed(2)}
                title={'A Pagar (R$)'}
                cClass={'red-bg'}
              />
              <InvoiceCard
                icon={'fas fa-equals'}
                value={total.toFixed(2)}
                title={'Total (R$)'}
                cClass={total >= 0 ? 'green-bg' : 'red-bg'}
              />
            </div>
            {this.getTable()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  baseBalance: state.base.baseBalance,
  baseBalanceFetching: state.base.baseBalanceFetching,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBaseBalance,
      sendIndividualEmail,
      billetButtonActions,
      sendNewBillet,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BaseBalance);
