import React from "react";

export const UtilityBox = ({ deleteImage, image }) => {
  const { codigo } = image;
  return (
    <div style={{ position: "absolute" }}>
      <div
        className="remove-image"
        onClick={(e) => {
          e.stopPropagation();
          deleteImage(codigo);
        }}
      >
        <i className="fas fa-times-circle" />
      </div>
    </div>
  );
};
