import React, { Component } from "react";
import { Field, change } from "redux-form";
import { renderField, cpf } from "./FormValidation";
import axios from "axios";
import jsonp from "fetch-jsonp";
import { toastError } from "../../../actions/toast_actions";

export default class CpfField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mask: "999.999.999-99",
    };
    this.getDocumentMask = this.getDocumentMask.bind(this);
    this.returnAllInfo = this.returnAllInfo.bind(this);
  }
  componentDidMount() {
    const { type } = this.props;
    if (!type) return;
    if (type === "CPF") this.setState({ mask: "999.999.999-99" });
    else if (type === "CNPJ") this.setState({ mask: "99.999.999/9999-99" });
  }

  getDocumentMask(value) {
    const { change, fieldName, company, type } = this.props;

    value = value.match(/[0-9]/g) ? value.match(/[0-9]/g).join("") : "";
    if (!company) {
      if (value.length < 11) this.setState({ mask: "999.999.999-99" });
      else if (value.length === 11) {
        if (fieldName) {
          change(fieldName, "CPF");
        }
        this.setState({ mask: "999.999.999-999" });
      } else {
        if (fieldName) {
          change(fieldName, "CNPJ");
        }
        this.setState({ mask: "99.999.999/9999-99" });
      }
    } else {
      this.setState({ mask: "99.999.999/9999-99" });
    }

    if (company && value.length === 14) {
      this.returnAllInfo(change, value);
    }
  }

  async returnAllInfo(change, cnpj) {
    jsonp(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "ERROR") {
          toastError(data.message);
        } else {
          change("nomeFantasia", data.fantasia);
          change("razaoSocial", data.nome);

          if (data.qsa && data.qsa.length > 0) {
            change("contato", data.qsa[0].nome);
          }

          change("email", data.email);
          change("logradouro", data.logradouro);
          change("complemento", data.complemento);
          change("bairro", data.bairro);
          change("cidade", data.municipio);
          change("numero", data.numero);
          change("cep", data.cep.replace(/\D/g, ""));
          change("estado", data.uf);
        }
      });
    // request
    //   .get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
    //   .end((error, result) => {
    //     if (result.body.status === "ERROR") toastError(result.body.message);
    //     else {
    //       let jsonResult = JSON.parse(result.text);
    //       const companyCnpj = jsonResult;
    //       change("nomeFantasia", companyCnpj.fantasia);
    //       change("razaoSocial", companyCnpj.nome);
    //       change("contato", companyCnpj.qsa[0].nome);
    //       change("email", companyCnpj.email);
    //       change("logradouro", companyCnpj.logradouro);
    //       change("complemento", companyCnpj.complemento);
    //       change("bairro", companyCnpj.bairro);
    //       change("cidade", companyCnpj.municipio);
    //       change("numero", companyCnpj.numero);
    //       change("cep", companyCnpj.cep.replace(/\D/g, ""));
    //       change("estado", companyCnpj.uf);
    //     }
    //   });
  }

  render() {
    const { mask } = this.state;
    const { name, label, validate, placeholder, icon } = this.props;

    return (
      <Field
        name={name}
        type="tel"
        component={renderField}
        label={label}
        validate={[...validate, cpf]}
        placeholder={placeholder}
        icon={icon}
        mask={mask}
        onChange={(e) => this.getDocumentMask(e.target.value)}
      />
    );
  }
}
