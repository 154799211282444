import {doGet, doPost, doPut, doDelete} from './base/base_api_helper';

export function getFavoriteDestinations(companyCode) {
  let endpoint = 'companies/favoriteDestinations';

  if (companyCode) {
    endpoint += `?_codigoEmpresa=${companyCode}`;
  }
  return doGet(endpoint);
}

export function getFavoriteDestinationsFromCompany(companyCode) {
  return doGet(`companies/favoriteDestinations?_codigoEmpresa=${companyCode}`);
}

export function updateFavoriteDestination(codeAtendimento, favoriteAddress) {
  return doPut(`atendimentos/${codeAtendimento}/updateFavoriteAddress`, favoriteAddress);
}

export function updateDealership(atendimento, enderecoFavorito) {
  const payload = {
    atendimento,
    enderecoFavorito,
  };

  return doPut('atendimentos', payload);
}

export function updateRequestDestiny(atendimento, endereco) {
  const payload = {
    atendimento,
    endereco,
  };
  return doPut('atendimentos', payload);
}

export function getClosestPartnersCall(location) {
  const {lat, lng} = location;
  return doGet(`partners/closestPartners/${lat}/${lng}`);
}

export function createFavoriteDestination(destinationData) {
  return doPost('companies/favoriteDestinations', destinationData);
}

export function createCompaniesFavoriteDestination(destinationData) {
  return doPost('companies/favoriteDestinations', destinationData);
}

// TODO - CRIAR API PARA UPDATE E DELETE
// export function updateFavoriteDestination(destinationCode, destinationData) {
//   return doPut(`companies/favoriteDestinations/${destinationCode}`, getFavoriteDestinationApiValues(destinationData) )
// }

export function deleteFavoriteDestination(destinationCode) {
  return doDelete(`companies/favoriteDestinations/${destinationCode}`);
}

// export function getFavoriteDestinationApiValues(destination = {}) {
//   return {
//       logradouro: destination.logradouro,
//       complemento: destination.complemento,
//       bairro: destination.bairro,
//       cidade	: destination.cidade,
//       numero: destination.numero,
//       cep: destination.cep,
//       pais: destination.pais,
//       estado: destination.estado,
//       latitude: destination.latitude,
//       longitude: destination.longitude,
//     }
// }
