import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {changeProfileImage} from '../../actions/login_actions'


class ProfileImageModal extends Component {
    

    render() {
        const {changeProfileImage} = this.props
        return (
            <div>
                <input type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(e)=>changeProfileImage(e.target.files[0])}/> 
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({ changeProfileImage }, dispatch)


export default connect(null, mapDispatchToProps)(ProfileImageModal)
