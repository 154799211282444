import React, { Component } from "react";

import { connect } from "react-redux";

//import HeatMap from '../components/common/heatMap'
import HeatMap from "../components/common/copyOfHeatMap";

import Spinner from "react-spinkit";

import { loadHeatMapData, loadPartnersData } from "../actions/heatMapAction";
import { getAllSolicitations, sendMapNotification } from "../api/heatMapHelper";

const styleMap = {
  height: "90vh",
  width: "100%"
};

const defaultCenter = [-23.5489, -46.6388];

const overlayStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 10,
  border: `2px solid #fff`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0,0,0,.3)`,
  cursor: `pointer`,
  textAlign: `center`,
  zIndex: 9999,
  position: `relative`,
  marginTop: `-90vh`,
  marginLeft: `30vw`,
  marginRight: `30vw`
};

const overlayButtonStyle = {
  background: ``
};

class RedeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentWillMount() {
    $("body").addClass("mini-navbar");
    const { loadPartners } = this.props;
    loadPartners();
    //getAllPartners( loadPartners ) // busca todos os parceiros
    //getAllSolicitations( loadData ) // busca todas as solicitações
  }

  componentDidMount() {
    const { loadPartners } = this.props;
    this.trackingTick = setInterval(() => {
      loadPartners();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.trackingTick);
  }

  render() {
    const {
      location,
      partnersTracking,
      loadPartners,
      solicitationToPin,
      pinSolicitation,
      clearSolicitationToPin,
      login: {
        user_info: {
          usuario: { tipoUsuario }
        }
      }
    } = this.props;

    const { isLoading } = this.state;
    return (
      <div className="row Notop">
        {isLoading === true && !partnersTracking ? (
          <div style={{ textAlign: `center`, marginTop: `40vh` }}>
            <Spinner name="three-bounce" color="#23c6c8" />
            <br />
            <h5>Carregando Parceiros</h5>
            {loadPartners()}
          </div>
        ) : (
          <HeatMap
            id="redeMap"
            style={styleMap}
            center={defaultCenter}
            partnersTracking={partnersTracking}
            zoom={10}
            overlayStyle={overlayStyle}
            solicitationToPin={solicitationToPin}
            pinSolicitation={pinSolicitation}
            clearSolicitationToPin={clearSolicitationToPin}
            profileId={tipoUsuario}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    heatMapData: state.heatMapData,
    partnersTracking: state.partnersTracking.partnersTracking,
    solicitationToPin: state.heatMapData.solicitationToPin,
    pinSolicitation: state.heatMapData.pinSolicitation,
    login: state.login
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadData: data => {
      dispatch(loadHeatMapData(data));
    },
    loadPartners: () => {
      dispatch(loadPartnersData());
    },
    clearSolicitationToPin: () => {
      dispatch({
        type: `CLEAR_SOLICITATION_TO_PIN`
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedeView);
