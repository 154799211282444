import React from 'react';

import store from 'store';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {Dropdown} from 'react-bootstrap';

import NumberBadge from './NumberBadge';
import AccountListView from './AccountListView';
import {smoothlyMenu} from '../layouts/Helpers';
import CustomModal, {doOpenModal, doCloseModal} from '../common/CustomModal';
import {APP_PROFILES} from '../../reducers/constants';
import {startTimer} from '../../actions/timer_actions';
import {loadNotifications} from '../../actions/notification_actions';
import {LOGOUT, doLogout} from '../../actions/login_actions';
import {addAccount, getAccountList, removeAccount} from '../../actions/accountActions';

const NOTIFICATION_TIMER = 'NOTIFICATION_TIMER';

class TopHeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.openAccountModal = this.openAccountModal.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handleName = this.handleName.bind(this);
  }

  toggleNavigation(e) {
    e.preventDefault();
    $('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  componentDidMount() {
    const {getAccountList} = this.props;
    getAccountList();
  }
  openAccountModal() {
    doOpenModal('CONTEXT_ACCOUNT');
  }
  handleUsername(username) {
    this.setState({username});
  }
  handlePassword(password) {
    this.setState({password});
  }
  handleName(name) {
    this.setState({name});
  }

  canViewUserManager() {
    const user_info = JSON.parse(localStorage.getItem('user_info'));

    return (
      user_info &&
      user_info.usuario &&
      user_info.usuario.perfis &&
      user_info.usuario.perfis.some(
        (item) =>
          item.id === APP_PROFILES.Administrador || item.id === APP_PROFILES.CompanyManager,
      )
    );
  }

  render() {
    const {addAccount, accountList, removeAccount} = this.props;
    return (
      <div className="row border-bottom">
        <nav
          className="navbar navbar-fixed-top white-bg"
          role="navigation"
          style={{marginBottom: 0}}>
          <div className="navbar-header">
            <a
              onClick={this.toggleNavigation}
              className="navbar-minimalize minimalize-styl-2 btn btn-primary"
              href="#">
              <i className="fa fa-bars" />{' '}
            </a>
          </div>
          <ul className="nav navbar-top-links navbar-right">
            <li>
              <span className="m-r-sm text-muted welcome-message">Bem-vindo à reboque.me</span>
            </li>
            {this.canViewUserManager() ? (
              <li>
                <a href={`${process.env.REACT_APP_URL}/settings`}>
                  <i className="fas fa-cog" /> Configurações
                </a>
              </li>
            ) : null}
            <li>
              <Link onClick={this.props.onLogout}>
                <i className="fas fa-sign-out-alt" /> Sair
              </Link>
            </li>
          </ul>
        </nav>
        <CustomModal context={'CONTEXT_ACCOUNT'} title="Adicione sua nova conta">
          <div className="form-group">
            <input
              onChange={(e) => this.handleName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Nome"
            />
          </div>
          <div className="form-group">
            <input
              onChange={(e) => this.handleUsername(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Usuário"
            />
          </div>
          <div className="form-group">
            <input
              onChange={(e) => this.handlePassword(e.target.value)}
              type="password"
              className="form-control"
              placeholder="Senha"
            />
          </div>
          <button
            className="btn btn-primary pull-right"
            onClick={() =>
              addAccount(this.state.name, this.state.username, this.state.password)
            }>
            Adicionar
          </button>
        </CustomModal>
      </div>
    );
  }
}

{
  /* <li className="dropdown">
<a href="#" className="dropdown-toggle count-info" data-toggle="dropdown">
  <i className="fa fa-user-plus"></i>
  {/* <NumberBadge number={this.props.notifications.length} color="primary"/> 
</a>
<AccountListView removeAccount={removeAccount} accountList={accountList} openAccountModal={this.openAccountModal} />
</li> */
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    accountList: state.account.accountList || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLogout: () => {
      dispatch(doLogout());
    },
    addAccount: (name, username, password) => {
      dispatch([addAccount(name, username, password), doCloseModal('CONTEXT_ACCOUNT')]);
    },
    getAccountList: () => {
      dispatch(getAccountList());
    },
    removeAccount: (index) => dispatch(removeAccount(index)),
  };
};

const TopHeader = connect(mapStateToProps, mapDispatchToProps)(TopHeaderView);

export default TopHeader;
