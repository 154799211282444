import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doLogout, LOGIN_DONE } from '../../actions/login_actions';
import { postLogin } from '../../api/login_helper';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalToGenerateNewSessionIfExpired: null,
      session: null,
    };
  }

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (userInfo) {
      this.startIntervalToGenerateNewSessionIfExpired(userInfo);
    }
  }

  componentWillUnmount() {
    if (this.state.intervalToGenerateNewSessionIfExpired)
      clearInterval(this.state.intervalToGenerateNewSessionIf);
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.session !== this.state.session &&
      this.state.intervalToGenerateNewSessionIfExpired
    ) {
      clearInterval(this.state.intervalToGenerateNewSessionIfExpired);
      this.startIntervalToGenerateNewSessionIfExpired(this.state.session);
    }
  }

  getAuthDateExpiration = () => {
    const authDateExpiration = new Date();
    authDateExpiration.setHours(authDateExpiration.getHours() + 4);

    return authDateExpiration;
  };

  isSessionHasExpired = (session) => {
    let expiredSession = false;

    const now = new Date();
    if (
      !session.authDateExpiration ||
      new Date(session.authDateExpiration) < now
    ) {
      expiredSession = true;
    }

    return expiredSession;
  };

  generateNewSessionIfExpired = async (userInfo) => {
    if (userInfo && this.isSessionHasExpired(userInfo)) {
      try {
        const newSession = await postLogin(
          localStorage.getItem('username'),
          localStorage.getItem('password')
        );
        newSession.authDateExpiration = this.getAuthDateExpiration();

        localStorage.setItem('user_info', JSON.stringify(newSession));
        this.setState({ session: newSession });
        this.props.doneLogin(newSession);
      } catch (error) {
        if (this.state.intervalToGenerateNewSessionIfExpired) {
          clearInterval(this.state.intervalToGenerateNewSessionIfExpired);
        }

        this.props.doLogout();
      }
    }
  };

  startIntervalToGenerateNewSessionIfExpired = (userInfo) => {
    const interval = setInterval(() => {
      this.generateNewSessionIfExpired(userInfo);
    }, 30000);

    this.setState({ intervalToGenerateNewSessionIfExpired: interval });
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doneLogin: (newSession) => {
      dispatch({ type: LOGIN_DONE, user_info: newSession });
    },
    doLogout: () => {
      dispatch(doLogout());
    },
  };
};

const AuthConnect = connect(
  null,
  mapDispatchToProps
)(Auth);

export default AuthConnect;
