import React from "react";
import { Typeahead } from "react-typeahead";
import autoBind from "react-autobind";

import _ from "underscore";

export default class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getFormStateStyle() {
    if (this.props.selectedItem) return "input-group success-custom";

    if (this.props.itemList == 0) return "input-group has-warning";

    return "input-group";
  }

  getClearButton(containsItem) {
    if (containsItem && !this.props.disabled)
      return (
        <span className="input-group-btn">
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.props.onClear}
            style={{ marginBottom: "0px" }}
          >
            <i className="fa fa-eraser"></i>
          </button>
        </span>
      );

    return <span></span>;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.inputValue !== nextProps.inputValue) {
      this.refs.typeahead.setState({ entryValue: nextProps.inputValue });
    }
  }

  render() {
    let clearButton = this.getClearButton(this.props.selectedItem);
    return (
      <div className={this.getFormStateStyle()}>
        <span className="input-group-addon">
          <i className={this.props.iconName}></i>
        </span>

        <Typeahead
          ref="typeahead"
          value={this.props.inputValue}
          inputProps={{
            autoFocus: this.props.focus,
            autoComplete: "off",
            id: this.props.id,
            disabled: this.props.disabled,
            readOnly: this.props.selectedItem,
            value: this.props.value
          }}
          onOptionSelected={this.props.onOptionSelected}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          filterOption={options => {
            if (this.props.filterOption) {
              return this.props.filterOption;
            } else {
              return "name";
            }
          }}
          displayOption={options => {
            let name; //return options.name.replace(/undefined/g, '')
            if (this.props.filterOption === `value`) {
              return options.value;
            } else {
              return options.name ? options.name.replace(/undefined/g, "") : "";
            }
          }}
          defaultClassNames={false}
          customClasses={{
            input: "form-control",
            results:
              "typeahead dropdown-menu autocomplete-options customer--marcamodelo-input",
            hover: "active",
            listAnchor: "dropdown-item"
          }}
          options={this.props.itemList}
          maxVisible={this.props.maxVisible ? this.props.maxVisible : 3}
          onFocus={this.props.onFocus}
        />

        {clearButton}
      </div>
    );
  }
}
