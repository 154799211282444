import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import RegisterBase from './registerBase';
import {Link} from 'react-router';
class CreateLead extends Component {
  constructor(props) {
    super(props);
    this.state = {key: 0};
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div
                className="ibox-title"
                style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  {' '}
                  <h5>Base </h5>
                  <h5>{`/ Nova Base`}</h5>
                </div>
              </div>
              <div className="ibox-content">
                <Tabs
                  id="general-base-tabs"
                  activeKey={this.state.key}
                  onSelect={this.handleTabs}>
                  <Tab eventKey={0} title="Frota">
                    <RegisterBase type="createLead" />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateLead;
