import {connect} from 'react-redux';
import AutoComplete from '../common/AutoComplete';
import {onOptionSelected, onInputCleared, onInternalFieldChanged} from '../../actions/autocomplete_actions'
import {onValueChanged} from '../../actions/vehicle_autocomplete_actions'

const mapStateToProps = (state, ownProps) => {
  let localState = state.autocomplete[ownProps.context]

  return {
    ...ownProps,
    ...localState,
    inputValue: (localState && localState.selectedItem && localState.selectedItem.placa) || ''
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onOptionSelected: (newValue) => {
      dispatch( onOptionSelected(ownProps.context, newValue, ownProps.afterOptionSelected) )
    },
    onClear: () => {
      dispatch( onInputCleared(ownProps.context, ownProps.afterClear) )
    },
    onChange: (event) => {
      let customerCode = ownProps.currentCustomer && ownProps.currentCustomer.codigo
      dispatch(onValueChanged(ownProps.context, customerCode, event.target.value, ownProps.afterValueChanged, ownProps.auth) )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete)
