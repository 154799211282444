import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import RegisterAttendence from './RegisterAttendence';
import {Link} from 'react-router';

class CreateAttendence extends Component {
  constructor(props) {
    super(props);
    this.state = {key: 0};
  }

  handleTabs = () => {};

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div
                className="ibox-title"
                style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <h5>{`Novo Atendimento`}</h5>
                </div>
              </div>
              <div className="ibox-content">
                <Tabs
                  id="general-base-tabs"
                  activeKey={this.state.key}
                  onSelect={this.handleTabs}>
                  <Tab eventKey={0} title="Atendimento">
                    <RegisterAttendence type="create" />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAttendence;
