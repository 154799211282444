import request from 'superagent';
import {BASE_URL} from '../../config';

export function doPost(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .post(BASE_URL + resource_path)
      .auth(auth.username, auth.password)
      .send(body)
      .end((error, result) => {
        if (error) reject(error);
        else {
          let jsonResult = JSON.parse(result.text);
          jsonResult.sucesso
            ? resolve(jsonResult)
            : reject({
                ...jsonResult,
                message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
              });
        }
      });
  });
}

export function doPostRelatorioFile(resource_path, file) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .post(BASE_URL + resource_path)
      .auth(auth.username, auth.password)
      .attach('ssPDF', file)
      .end((error, result) => {
        if (error) reject(error);
        else {
          let jsonResult = JSON.parse(result.text);
          jsonResult.sucesso
            ? resolve(jsonResult)
            : reject({
                ...jsonResult,
                message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
              });
        }
      });
  });
}

export function doGet(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .get(BASE_URL + resource_path)
      .auth(auth.username, auth.password)
      .query(body)
      .end((error, result) => {
        if (error) reject(error);
        else {
          let jsonResult = JSON.parse(result.text);
          jsonResult.sucesso
            ? resolve(jsonResult)
            : reject({
                ...jsonResult,
                message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
              });
        }
      });
  });
}

export function doPut(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .put(BASE_URL + resource_path)
      .auth(auth.username, auth.password)
      .send(body)
      .end((error, result) => {
        if (error) reject(error);
        else {
          let jsonResult = JSON.parse(result.text);
          jsonResult.sucesso
            ? resolve(jsonResult)
            : reject({
                ...jsonResult,
                message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
              });
        }
      });
  });
}

export function doDelete(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .delete(BASE_URL + resource_path)
      .auth(auth.username, auth.password)
      .send(body)
      .end((error, result) => {
        if (error) reject(error);
        else {
          let jsonResult = JSON.parse(result.text);
          jsonResult.sucesso
            ? resolve(jsonResult)
            : reject({
                ...jsonResult,
                message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
              });
        }
      });
  });
}
