import React from "react";
import MaskedInput from "../MaskedInput";
export const required = (value) =>
  value || typeof value === "number" ? undefined : "Campo Obrigatório";
export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `Deve conter ${max} caracteres ou menos`
    : undefined;
export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Deve Conter ${min} caracteres o mais`
    : undefined;
export const phoneValidator = (value) =>
  unmaskValue(value).length < 11 ? `Telefone incompleto` : undefined;
export const cpf = (value) =>
  value && unmaskValue(value).length < 11 ? "Documento Incompleto" : undefined;
export const cepV = (value) =>
  value && unmaskValue(value).length !== 8 ? "Cep Incompleto " : undefined;

const unmaskValue = (value) =>
  value && value.match(/[0-9]/g) ? value.match(/[0-9]/g).join("") : "";

export const renderField = ({
  input,
  label,
  type,
  placeholder,
  icon,
  mask,
  children,
  meta: { touched, error, warning },
  ...otherProps
}) => (
  <div
    className={`form-group ${touched && error ? "has-error" : ""}
          ${touched && warning ? "has-warning" : ""} ${
      touched && !warning && !error ? "has-success" : ""
    }`}
  >
    <div>
      <label>{label}</label>
      <div className="input-group">
        {icon && (
          <span className="input-group-addon">
            <i className={icon} />
          </span>
        )}

        {children ? (
          <select
            {...otherProps}
            autoComplete="new-password"
            className="form-control"
            {...input}
            placeholder={placeholder}
            type={type}
          >
            {children}
          </select>
        ) : mask ? (
          <MaskedInput
            {...otherProps}
            alwaysShowMask={false}
            mask={mask}
            className="form-control"
            {...input}
            placeholder={placeholder}
            type={type}
          />
        ) : (
          <input
            {...otherProps}
            autoComplete="new-password"
            className="form-control"
            {...input}
            placeholder={placeholder}
            type={type}
          />
        )}
      </div>
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);
