import React, {Component} from 'react';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {sendNewPassword, loginFormUpdated} from '../actions/login_actions';
import logoLg from '../assets/img/soon-logo-purple.png';

class ForgotPassView extends Component {
  render() {
    return (
      <div className="passwordBox animated fadeInDown">
        <div
          className={
            this.props.errorMessage ? 'alert alert-danger animated flipInX' : 'hidden'
          }>
          {this.props.errorMessage}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="ibox-content" style={{textAlign: 'center'}}>
              <img src={logoLg} className="logo-img" width={450}></img>
              <h2 className="font-bold">Esqueci minha senha!</h2>
              <p>Informe seu email e uma nova senha será enviada a você.</p>
              <div className="row">
                <div className="col-md-12">
                  <form className="m-t" role="form" action="index.html">
                    <div className="form-group">
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Email cadastrado"
                        required=""
                        onChange={this.props.onChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary block full-width m-b"
                      onClick={this.props.doSubmit}>
                      Enviar nova senha
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.login.email,
    errorMessage: state.login.errorMessage,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: (event) => {
      dispatch(loginFormUpdated(event.target.id, event.target.value));
    },
    doSubmit: (event) => {
      event.preventDefault();
      dispatch(sendNewPassword());
    },
  };
};

const ForgotPass = connect(mapStateToProps, mapDispatchToProps)(ForgotPassView);

export default ForgotPass;
