import React from 'react';
import {connect} from 'react-redux'
import {changePassword} from '../../actions/login_actions';
import {doCloseModal} from '../common/CustomModal'
import {toastSuccess} from '../../actions/toast_actions';

class ChangePasswordFormView extends React.Component {
  constructor(props){
    super(props)
    this.updateField = this.updateField.bind(this)
    this.canSubmit = this.canSubmit.bind(this)
    this.isSenhaConfirmada = this.isSenhaConfirmada.bind(this)
    this.submit = this.submit.bind(this)
    this.clearAll = this.clearAll.bind(this)
    this.state = {}
  }

  componentWillReceiveProps(nextProps){
    this.clearAll()
  }

  updateField(event){
    this.setState({
      ...this.state,
      [event.target.id] : event.target.value
    })
  }

  clearAll(event){
    event && event.preventDefault()
    this.setState({})
    this.refs.form && this.refs.form.reset()
  }

  isSenhaConfirmada(){
    return this.state.novaSenha == this.state.confirmacaoSenha
  }

  canSubmit(){
    return (
      this.isSenhaConfirmada()
      &&
      this.state.novaSenha && this.state.novaSenha.length > 0
    )
  }

  submit(event){
    event.preventDefault()
    if(this.isSenhaConfirmada()){
      this.props.onSubmit(this.state)
      this.clearAll()
    }
  }

  render() {
    return (
      <div className="row-fluid" style={{minHeight: "120px"}}>
        <form ref="form" className="form-horizontal" onSubmit={this.submit}>
          <div className="alert alert-danger alert-dismissable" style={{display: (this.props.errorMessage ? "block" : "none")}}>
              {this.props.errorMessage}
          </div>

          <div className="form-group">
            <label className="col-xs-4 control-label">Informe a senha atual</label>
            <div className="col-xs-8">
              <input className="form-control" id="senhaAtual" type="password" onChange={this.updateField}/>
            </div>
          </div>

          <div className="form-group">
            <label className="col-xs-4 control-label">Informe a nova senha</label>
            <div className="col-xs-8">
              <input className="form-control" id="novaSenha" type="password" onChange={this.updateField}/>
            </div>
          </div>

          <div className={this.isSenhaConfirmada()? "form-group" : "form-group has-error"}>
            <label className="col-xs-4 control-label">Confirme a nova senha</label>
            <div className="col-xs-8">
              <input className="form-control" id="confirmacaoSenha" type="password" onChange={this.updateField}/>
              <span className={this.isSenhaConfirmada()? "hidden" : "help-block m-b-none animated flipInX"}>As senhas digitadas não são exatamente iguais</span>
            </div>
          </div>

          <div className="row">
            <button className="btn btn-warning col-xs-5" onClick={this.clearAll} type="button"><i className="fa fa-eraser"></i> Limpar</button>
            <button className="btn btn-primary col-xs-5 col-xs-offset-2" disabled={!this.canSubmit()} type="submit"><i className="fa fa-check"></i> Enviar</button>
          </div>
        </form>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    errorMessage: state.login.errorMessage
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...ownProps,
    onSubmit: (state) => {
      dispatch(changePassword(state.senhaAtual, state.novaSenha, () => {
        doCloseModal("changePassword")

      }))
    }
  }
}

const ChangePasswordForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordFormView)

export default ChangePasswordForm
