import React, { Component } from "react";
import { connect } from "react-redux";
import { addImageToChecklist } from "../../actions/checkListUploadActions";
import { bindActionCreators } from "redux";

export class ChecklistItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualTypeDocument: null,
      isFetching: false,
      isUploaded: false,
    };
    this.handleUpload = this.handleUpload.bind(this);
  }

  async uploadFiles(tipoDocumento, files, checklistType) {
    const { addImageToChecklist, solicitationCode } = this.props;

    this.setState({ isFetching: true });

    const response = await addImageToChecklist(
      solicitationCode,
      files,
      tipoDocumento,
      checklistType
    );

    this.setState({ isFetching: false });

    if (response[0].sucesso) {
      this.setState({ isUploaded: true });
    }
  }

  handleUpload(tipoDocumento) {
    this.fileInput.click();
    this.setState({ actualTypeDocument: tipoDocumento });
  }

  onChangeFiles = (event) => {
    const { actualTypeDocument } = this.state;
    const files = Array.from(event.target.files);
    this.uploadFiles(actualTypeDocument, files, this.props.type);
  };

  render() {
    const { item } = this.props;
    const { isUploaded, isFetching } = this.state;
    return (
      <li
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="list-group-item"
      >
        {item.descricao}
        <div style={{ display: "flex", alignItems: "center" }}>
          {item.obrigatorio ? (
            <div style={{ marginRight: "10px" }}>
              <span className="text-danger">*</span>
            </div>
          ) : null}
          {item.enviado || isUploaded ? (
            <div style={{ marginRight: "10px" }}>
              <i className="fas fa-check" />
            </div>
          ) : null}
          {isFetching ? <div className="loader" /> : null}

          <button
            onClick={() => this.handleUpload(item.tipoDocumento.codigo)}
            className="btn btn-sm btn-default"
          >
            <i className="fa fa-search" aria-hidden="true" />
          </button>
          <input
            type="file"
            data-document={item.tipoDocumento.codigo}
            onChange={this.onChangeFiles}
            ref={(input) => {
              this.fileInput = input;
            }}
            style={{ display: "none" }}
          />
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addImageToChecklist }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(ChecklistItem);
