const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
}
const thumb = {    
    borderRadius: 2,
    height: '150px',
    margin: '10px'
    
}
const img = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: '10px',
    maxHeight:'150px'
};

const baseStyle = {
    width: '100%',
    height: '360px',
    borderWidth: 2,
    
    borderRadius: 5
};
const activeStyle = {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee'
};
const rejectStyle = {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee'
};
const dropzoneContainer={
    margin:'10px',
    float: 'left',
    height: '370px',
    width: '100%',
    overflowY:'auto',
    borderColor: '#ddd',
    borderStyle: 'dashed',
    borderWidth: '2px'
}

export { thumbsContainer, thumb, img, baseStyle, activeStyle, rejectStyle, dropzoneContainer }