import {doGetV2} from './base/base_api_v2_helper';
import {doGet, doPost, doPut, doDelete} from './base/base_api_helper';

export function getBases(filter = 0) {
  let _start = 0;
  const _limit = 10;
  if (filter > 0) {
    _start = _limit * filter;
  }
  return doGet(`bases?_limit=${_limit}&_start=${_start}`);
}

export function setBaseHistory(baseCode, log) {
  return doPut(`bases/${baseCode}/historico/${log}`);
}

export function newInvoice(attributes) {
  return doPost(`invoices`, attributes);
}

export function filterBases(filter, attributes, page, limit) {
  let params = {};

  if (filter) {
    params.tradingName = filter;
  }

  if (attributes && attributes._status) {
    const statusValues = attributes._status.split(',');
    const formattedStatus = statusValues
      .map((status) => {
        switch (status) {
          case 'BASEATIVA':
            return 74;
          case 'BASEPENDENTEATIVACAO':
            return 143;
          case 'BASEINATIVA':
            return 75;
          default:
            return null;
        }
      })
      .filter((status) => status !== null)
      .join(',');

    if (formattedStatus) {
      params.statusIds = formattedStatus;
    }
  }
  if (attributes && attributes._tipoVeiculo) {
    const vehicleStatus = attributes._tipoVeiculo.split(',');
    const formatVehicle = vehicleStatus
      .map((status) => {
        switch (status) {
          case 'VEICULOPARCEIROMOTOCICLETA':
            return 83;
          case 'VEICULOPARCEIROPESADO':
            return 86;
          case 'VEICULOPARCEIROLEVE':
            return 84;
          case 'VEICULOPARCEIROUTILITARIO':
            return 85;
          case 'VEICULOPARCEIROLANCA':
            return 87;
          case 'VEICULOPARCEIROAUTOMOVEL':
            return 998;
          default:
            return null;
        }
      })
      .filter((status) => status !== null)
      .join(',');

    params.vehicleTypeIds = formatVehicle;
  }

  if (attributes._tipoDocumento) {
    const documentStatus = attributes._tipoDocumento.split(',');
    const formatDocument = documentStatus.map((status) => {
      switch (status) {
        case 'cnpj':
          return 'CNPJ';
        case 'cpf':
          return 'CPF';
        default:
          return null;
      }
    });
    params.documentType = formatDocument;
  }

  if (attributes._radius && attributes._lat && attributes._long) {
    params.radius = attributes._radius;
    params.latitude = attributes._lat;
    params.longitude = attributes._long;
  }

  if (attributes._inadimplente) {
    params.isDefaulting = attributes._inadimplente;
  }

  if (attributes._tamanhoPlataforma) {
    params.platformSize = attributes._tamanhoPlataforma;
  }

  if (attributes._patins) {
    params.skates = attributes._patins;
  }

  if (attributes && attributes._asaDelta) {
    const hangGlidingStatus = attributes._asaDelta.split(',');
    const formatVehicle = hangGlidingStatus
      .map((status) => {
        switch (status) {
          case 'sim':
            return 1;
          case 'nao':
            return 0;

          default:
            return null;
        }
      })
      .filter((status) => status !== null)
      .join(',');
    params.hangGliding = formatVehicle;
  }
  params.limit = limit;
  params.page = page - 1;
  return doGetV2(`bases`, params);
}
export function getSingleBase(baseCode) {
  return doGet(`bases/${baseCode}`);
}

export function createBase(baseData) {
  return doPost('bases', getBaseApiValues(baseData));
}

export function createLead(baseData) {
  return doPost('bases/lead', getBaseApiValues(baseData));
}

export function updateBase(baseCode, baseData, initialBaseData) {
  return doPut(`bases/${baseCode}`, getBaseApiValues(baseData, initialBaseData));
}

export function resetPassword(baseCode) {
  return doPut(`bases/${baseCode}/setDefaultPassword`);
}

export function deleteBase(baseCode) {
  return doDelete(`bases/${baseCode}`);
}

export function getBaseVehicles(base) {
  return doGet(`bases/${(base && base.id) || '0'}/vehicles`);
}

export function getBaseApiValues(base = {}, initialBase) {
  let endereco;
  let contaBancaria;

  if (base.endereco) {
    endereco = {
      logradouro: base.endereco.logradouro ? base.endereco.logradouro : base.logradouro,
      complemento: base.complemento ? base.complemento : base.endereco.complemento,
      bairro: base.endereco.bairro ? base.endereco.bairro : base.bairro,
      cidade: base.endereco.cidade ? base.endereco.cidade : base.cidade,
      numero: base.endereco.numero ? base.endereco.numero : base.numero,
      cep: base.endereco.cep ? base.endereco.cep : base.cep,
      pais: base.endereco.pais ? base.endereco.pais : base.pais,
      estado: base.endereco.estado ? base.endereco.estado : base.estado,
      latitude: base.endereco.latitude ? base.endereco.latitude : 0.0,
      longitude: base.endereco.longitude ? base.endereco.longitude : 0.0,
    };
  }
  if (!base.endereco) {
    endereco = {
      logradouro: base.logradouro || '',
      complemento: base.complemento || '',
      bairro: base.bairro || '',
      cidade: base.cidade || '',
      numero: base.numero || '',
      cep: base.cep || '',
      pais: base.pais || '',
      estado: base.estado || '',
      latitude: 0.0,
      longitude: 0.0,
    };
  }
  if (base.contaBancaria) {
    contaBancaria = {
      bankName: base.bankName ? base.bankName : base.contaBancaria.bankName,
      bankNumber: base.bankNumber ? base.bankNumber : base.contaBancaria.bankNumber,
      agencyNumber: base.agencyNumber ? base.agencyNumber : base.contaBancaria.agencyNumber,
      agencyCheckNumber: base.agencyCheckNumber
        ? base.agencyCheckNumber
        : base.contaBancaria.agencyCheckNumber,
      accountNumber: base.accountNumber
        ? base.accountNumber
        : base.contaBancaria.accountNumber,
      accountCheckNumber: base.accountCheckNumber
        ? base.accountCheckNumber
        : base.contaBancaria.accountCheckNumber,
      holderFullname: base.holderFullname
        ? base.holderFullname
        : base.contaBancaria.holderFullname,
      holderTypeDocument: base.holderTypeDocument
        ? base.holderTypeDocument
        : base.contaBancaria.holderTypeDocument,
      holderNumberDocument: base.holderNumberDocument
        ? base.holderNumberDocument.replace(/[^0-9]/g, '')
        : base.contaBancaria.holderNumberDocument.replace(/[^0-9]/g, ''),
    };
  }
  if (!base.contaBancaria) {
    contaBancaria = {
      bankName: base.bankName || null,
      bankNumber: base.bankNumber || null,
      agencyNumber: base.agencyNumber || null,
      agencyCheckNumber: base.agencyCheckNumber || null,
      accountNumber: base.accountNumber || null,
      accountCheckNumber: base.accountCheckNumber || null,
      holderFullname: base.holderFullname || null,
      holderTypeDocument: base.holderTypeDocument || null,
      holderNumberDocument: base.holderTypeDocument
        ? base.holderNumberDocument.replace(/[^0-9]/g, '')
        : null,
      chavePix: base.chavePix ? base.chavePix : undefined,
    };
  }
  function accountExists(account) {
    for (let key in account) {
      if (account[key] !== null) return account;
    }
    return null;
  }

  if (initialBase && JSON.stringify(initialBase.tabela) === JSON.stringify(base.tabela)) {
    return {
      contato: base.contato,
      razaoSocial: base.razaoSocial,
      nomeFantasia: base.nomeFantasia,
      telefoneCelular: base.telefoneCelular,
      telefoneFixo: base.telefoneFixo === '---' ? null : base.telefoneFixo,
      documento: base.documento && base.documento.replace(/[^0-9]/g, ''),
      tipoDocumento: base.tipoDocumento,
      endereco: endereco,
      contaBancaria: accountExists(contaBancaria),
      status: base.codigoStatus,
      email: base.email,
      login: base.login,
      senha: base.senha,
    };
  }

  return {
    contato: base.contato,
    razaoSocial: base.razaoSocial,
    nomeFantasia: base.nomeFantasia,
    telefoneCelular: base.telefoneCelular,
    telefoneFixo: base.telefoneFixo === '---' ? null : base.telefoneFixo,
    documento: base.documento && base.documento.replace(/[^0-9]/g, ''),
    tipoDocumento: base.tipoDocumento,
    endereco: endereco,
    contaBancaria: accountExists(contaBancaria),
    status: base.codigoStatus,
    email: base.email,
    login: base.login,
    senha: base.senha,
    tabela: base.tabela,
  };
}
