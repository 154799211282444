import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {
  LOGOUT,
  forgotPassword,
  doLogout,
  doLogin,
  goToRegisterCompany,
  loginFormUpdated,
} from '../actions/login_actions';
import logoLg from '../assets/img/soon-logo-purple.png';

class LoginView extends Component {
  render() {
    return (
      <div className="login-wrapper gray-bg">
        <div className="middle-box text-center loginscreen animated fadeInDown">
          <div>
            <div>
              <img src={logoLg} className="logo-img" width={450} />
            </div>
            <p>
              Uma nova forma de pensar: Assistência! Surpreenda seus clientes
              quando eles mais precisam de você.
            </p>
            <div
              className={
                this.props.errorMessage
                  ? 'alert alert-danger animated flipInX'
                  : 'hidden'
              }
            >
              {this.props.errorMessage}
            </div>
            <div
              className={
                this.props.successMessage
                  ? 'alert alert-success animated flipInX'
                  : 'hidden'
              }
            >
              {this.props.successMessage}
            </div>
            <form
              style={{ marginTop: '35px' }}
              className="m-t"
              role="form"
              action="index.html"
            >
              <div className="form-group">
                <input
                  name="username"
                  id="username"
                  type="email"
                  className="form-control"
                  placeholder="Usuário"
                  required=""
                  onChange={this.props.onUpdate}
                />
              </div>
              <div className="form-group">
                <input
                  name="password"
                  id="password"
                  type="password"
                  className="form-control"
                  placeholder="Senha"
                  required=""
                  onChange={this.props.onUpdate}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-soon block full-width m-b"
                onClick={this.props.doLogin}
              >
                Login
              </button>
              {/* <button
                type="submit"
                className="btn btn-success btn-soon block full-width m-b"
              >
                Cadastrar Empresa
              </button> */}

              <Link onClick={this.props.forgotPassword}>
                <small>Esqueci minha senha!</small>
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    username: state.login.username,
    password: state.login.password,
    successMessage: state.login.successMessage,
    errorMessage: state.login.errorMessage,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    forgotPassword: () => {
      dispatch(forgotPassword());
    },
    doLogin: (event) => {
      event.preventDefault();
      dispatch(doLogin());
    },
    goToRegisterCompany: (event) => {
      event.preventDefault();
      dispatch(goToRegisterCompany());
    },
    onUpdate: (event) => {
      dispatch(loginFormUpdated(event.target.id, event.target.value));
    },
  };
};

const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginView);

export default Login;
