import React from "react";
import "../../assets/styles/custom.css";
import ChecklistItem from "./ChecklistItem";


class ChecklistTab extends React.Component {


  render() {
    const { checklist} = this.props;
    if (!checklist) return null;

    return <ul className="list-group list-group-flush">
        {checklist.map((item, index) => (
          <ChecklistItem
            key={item.codigo || index}
            item={item}
            solicitationCode={this.props.solicitationCode}
            type={this.props.type}
          />
        ))}
      </ul>;
  }
}

export default ChecklistTab;
