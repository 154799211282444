import React, {Component} from 'react';

export default class RouteMap extends Component {
  constructor(props) {
    super(props);
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
    this.handleDirections = this.handleDirections.bind(this);
    this.infowindow0 = new google.maps.InfoWindow();

    this.map = null;
    this.state = {
      requestOrigin: null,
      requestDestination: null,
      partnerLocation: null,
    };
  }

  componentDidMount() {
    this.initMap();
    this.directionsDisplay.setMap(this.map);
  }
  initMap() {
    const {id, requestOrigin} = this.props;
    this.map = new google.maps.Map(document.getElementById(id), {
      center: requestOrigin ? requestOrigin : {lat: -22.915, lng: -43.197},
      zoom: 13,
      gestureHandling: 'greedy',
      controlSize: 24,
      disableDefaultUI: true,
    });
    var overlay = new google.maps.OverlayView();
    overlay.draw = function () {
      this.getPanes().markerLayer.id = 'markerLayer';
    };
    overlay.setMap(this.map);
  }
  handleDirections(requestOrigin, partnerLocation, requestDestination) {
    if (requestDestination) {
      this.directionsService.route(
        {
          origin: {...partnerLocation},
          destination: {...partnerLocation},
          waypoints: [
            {
              location: new google.maps.LatLng(requestOrigin.lat, requestOrigin.lng),
              stopover: true,
            },
            {
              location: new google.maps.LatLng(requestDestination.lat, requestDestination.lng),
              stopover: true,
            },
          ],
          travelMode: 'DRIVING',
          avoidTolls: false,
        },
        (response, status) => {
          if (status === 'OK') {
            this.directionsDisplay.setDirections(response);
            const stepMiddle0 = Math.round(response.routes[0].legs[0].steps.length / 2) - 1;
            this.infowindow0.setContent(
              '<span><b>início ao cliente</b><span/><br>' +
                response.routes[0].legs[0].distance.text +
                '<br>' +
                '<span><b>Cliente ao destino</b><span/><br>' +
                response.routes[0].legs[1].distance.text +
                '<br>' +
                '<span><b>Destino ao início</b><span/><br>' +
                response.routes[0].legs[2].distance.text +
                '<br>' +
                '<span><b>Total: </b><span/><br>' +
                (response.routes[0].legs[0].distance.value +
                  response.routes[0].legs[1].distance.value +
                  response.routes[0].legs[2].distance.value) /
                  1000 +
                ' Km <br>',
            );

            this.infowindow0.setPosition(
              response.routes[0].legs[0].steps[stepMiddle0].end_location,
            );
            this.infowindow0.open(this.map);
          } else {
            this.props.routeErr('Erro ao montar rota', status);
          }
        },
      );
    } else {
      this.directionsService.route(
        {
          origin: {...partnerLocation},
          destination: {...partnerLocation},
          waypoints: [
            {
              location: new google.maps.LatLng(requestOrigin.lat, requestOrigin.lng),
              stopover: true,
            },
          ],
          travelMode: 'DRIVING',
          avoidTolls: false,
        },
        (response, status) => {
          if (status === 'OK') {
            this.directionsDisplay.setDirections(response);
            const stepMiddle0 = Math.round(response.routes[0].legs[0].steps.length / 2) - 1;
            this.infowindow0.setContent(
              '<span><b>início ao cliente</b><span/><br>' +
                response.routes[0].legs[0].distance.text +
                '<br>' +
                '<span><b>Cliente ao início</b><span/><br>' +
                response.routes[0].legs[1].distance.text +
                '<br>' +
                '<span><b>Total: </b><span/><br>' +
                (response.routes[0].legs[0].distance.value +
                  response.routes[0].legs[1].distance.value) /
                  1000 +
                ' Km <br>',
            );

            this.infowindow0.setPosition(
              response.routes[0].legs[0].steps[stepMiddle0].end_location,
            );
            this.infowindow0.open(this.map);
          } else {
            this.props.routeErr('Erro ao montar rota', status);
          }
        },
      );
    }
  }
  componentWillReceiveProps(nextProps) {
    const {requestOrigin, requestDestination, partnerLocation} = nextProps;
    let bounds = new google.maps.LatLngBounds();

    const originMarker = new google.maps.Marker({
      position: new google.maps.LatLng(requestOrigin.lat, requestOrigin.lng),
      map: this.map,
      icon: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',
    });
    bounds.extend(originMarker.position);

    if (requestDestination) {
      const destinationMarker = new google.maps.Marker({
        position: new google.maps.LatLng(requestDestination.lat, requestDestination.lng),
        map: this.map,
        icon: 'https://icons.iconarchive.com/icons/fatcow/farm-fresh/24/flag-finish-icon.png',
      });
      bounds.extend(destinationMarker.position);
    }
    if (partnerLocation) {
      const partnerMarker = new google.maps.Marker({
        position: new google.maps.LatLng(partnerLocation.lat, partnerLocation.lng),
        map: this.map,
        icon: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carGreen.png',
      });
      bounds.extend(partnerMarker.position);
    }
    this.map.fitBounds(bounds);

    this.handleDirections(requestOrigin, partnerLocation, requestDestination);
  }

  render() {
    return <div className="routeMap" id={this.props.id} style={this.props.style} />;
  }
}
