import React from 'react';

const CustonTitleButton = ({active, onClick, label, type}) => {
  return (
    <button
      className={`btn btn-xs btn-primary ${active && 'active'}`}
      type="button"
      onClick={() => onClick(type)}>
      {label}
    </button>
  );
};

export default CustonTitleButton;
