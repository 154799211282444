/* 
Distance between two locations
*/
export const getDistance = (latLong1, latLong2, unit = "K") => {
  const radlat1 = (Math.PI * latLong1.latitude) / 180;
  const radlat2 = (Math.PI * latLong2.latitude) / 180;
  const theta = latLong1.longitude - latLong2.longitude;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist = dist * 1.609344;
  } else if (unit === "N") {
    dist = dist * 0.8684;
  }
  return Math.round(dist * 10) / 10;
};

export const getTruckIcon = (partner) => {
  const tipoVeiculo = partner.veiculo.tipoVeiculo
    ? partner.veiculo.tipoVeiculo.codigo
    : "";
  const statusCode = partner.status.codigo;
  const icon = {
    name: "medium-truck",
    color: "blue",
    size: 40,
    fileName: "",
  };

  switch (statusCode) {
    case "PARCEIROATIVO":
      icon.color = "blue";
      break;

    case "PARCEIROINATIVO":
      icon.color = "red";
      break;

    case "PARCEIROEMDESCANSO":
      icon.color = "yellow";
      break;

    case "PARCEIROEMATENDIMENTO":
      icon.color = "green";
      break;
  }

  switch (tipoVeiculo) {
    case "VEICULOPARCEIROMOTOCICLETA":
      icon.name = "moto";
      icon.size = 60;
      break;

    case "VEICULOPARCEIROLEVE":
      icon.name = "small-truck";
      icon.size = 30;
      break;

    case "VEICULOPARCEIROUTILITARIO":
      icon.name = "medium-truck";
      icon.size = 50;
      break;

    case "VEICULOPARCEIROPESADO":
      icon.name = "big-truck";
      icon.size = 100;
      break;

    case "VEICULOPARCEIROLANCA": // TODO
      icon.name = "big-truck";
      icon.size = 100;
      break;
  }

  icon.fileName = `${icon.name}-${icon.color}.png`;

  return icon;
};
