
const INITIAL_STATE = {
    accountList: [],
    accountAdded : false,
    auth:{
        user:'',
        password:''
    }
}
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'ACCOUNT_ADDED':
            return { ...state, accountAdded: action.payload }
        case 'ACCOUNT_LIST_FETCHED':
            return { ...state, accountList: action.payload }
        case 'AUTH_INFO_CHANGED':
            return { ...state, auth: action.payload }
        default:
            return state
    }
}