import {WINDOW_RESIZED} from '../actions/window_actions'

const notifications_reducer = (state = {}, action) => {
  switch(action.type){
    case WINDOW_RESIZED:
      return {
        ...state,
        innerHeight: window.innerHeight
      }
    default:
      return state
  }
}

export default  notifications_reducer
