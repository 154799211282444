import React, { Component } from "react";

export default class OperationalForm extends Component {
  render() {
    return (
      <div>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdCFcglSjwDfItaNI1UaoeX3r1Wrni7TSFwTXKl9gai_7VjcQ/viewform?embedded=true"
          height="2942px"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          align="left"
          width="680px"
        >
          carregando...
        </iframe>
      </div>
    );
  }
}
