import {FORM_SELECT_CHANGED,
    FORM_PLAN_CUSTOMER_CHANGED,
  FORM_FIELD_CHANGED, FORM_ERROR, FORM_SUCCESS, FORM_SET_VALUES, CLEAR_FORM_VALUES, FORM_SET_EDITING, FORM_SET_READONLY, 
  FORM_EMIT_BILLET_INSERTED, CLEAR_FORM_EMIT_BILLET } from '../actions/form_actions'

const initialState = {
  BASE_SEARCH_FORM: {},
  CREATE_DETAILED_VEHICLE: {},
  PLAN_CUSTOMER: {
    produto: null,
    dataInicio: null,
    dataTermino: null,
  },
  VEHICLE_PLAN_COSTUMER: {
    produto: null,
    dataInicio: null,
    dataTermino: null,
  },
  COMPANY_TAB: {
    empresa: null,
  },
  EMIT_BILLET_INVOICES: {
    codigo: null,
    valor: "",
    deducoes: "",
    baseCalculo: "",
    ir: "",
    pis: "",
    cofins: "",
    csll: "",
    iss: "",
    issRetido: false
  }
}

const form_reducer = (state = initialState, action) => {
  switch(action.type){
    case FORM_FIELD_CHANGED:
      return {
        ...state,
        [action.context]: { ...state[action.context], [action.field]: action.value }
      }
    case FORM_PLAN_CUSTOMER_CHANGED:
      return {
        ...state,
        PLAN_CUSTOMER: {
          ...state.PLAN_CUSTOMER,
          [action.field]: action.value,
        }
      }
    case FORM_ERROR:
      return {
        ...state,
        [action.context]: { ...state[action.context], errorMessage: action.errorMessage }
      }

    case FORM_SUCCESS:
      return {
        ...state,
        [action.context]: { ...state[action.context], errorMessage: undefined }
      }

    case CLEAR_FORM_VALUES:
      return {
        ...state,
        [action.context]: initialState[action.context]
      }
    case FORM_EMIT_BILLET_INSERTED:
      return {
        ...state,
        EMIT_BILLET_INVOICES: action.value
      }

    case CLEAR_FORM_EMIT_BILLET:
      return {
        ...state,
        EMIT_BILLET_INVOICES: initialState.EMIT_BILLET_INVOICES
      }
    case FORM_SET_VALUES:
      return {
        ...state,
        [action.context]: { ...state[action.context], ...action.values }
      }

    case FORM_SET_EDITING:
      return {
        ...state,
        [action.context]: { ...state[action.context], isEditing: action.value }
      }

      case FORM_SET_READONLY:
        return {
          ...state,
          [action.context]: { ...state[action.context], readonlyNames: action.value, readonlyDetails: action.value }
        }

    default:
      return state
  }
}

export default  form_reducer
