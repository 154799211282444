import React from 'react'

const ClocklIndicator = ({text, top}) =>
    <div style={{
        position: 'absolute',
        left: '-20px',
        top: top,
        textAlign:'center'
    }}>
        <span style={{ display: 'block', marginBottom: '-23px' }}><strong>{text}</strong></span><br/>
    <span>Min</span> 
    </div>

export default ClocklIndicator