import React, {PureComponent} from 'react';
import './styles.css';

export default class AtendimentoTab extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {atendimento} = this.props;
    const {
      empresa,
      tipoRequisicao,
      tipoMotivo,
      origemRequisicao,
      contato,
      telefone,
      kmAtual,
      tipoCarga,
      requisicao,
      cliente,
      veiculo,
    } = {...atendimento};

    return (
      <div>
        <div className="infoContainer">
          <h3>Informações Gerais</h3>
          {empresa && empresa.nomeFantasia && (
            <span className="info">
              <strong> Empresa: </strong>
              {empresa.nomeFantasia}
            </span>
          )}
          {tipoRequisicao && tipoRequisicao.descricao && (
            <span className="info">
              <strong> Tipo de Requisição: </strong>
              {tipoRequisicao.descricao}
            </span>
          )}
          {tipoMotivo && tipoMotivo.descricao && (
            <span className="info">
              <strong>Tipo de Assistencia: </strong>
              {tipoMotivo.descricao}
            </span>
          )}
          {origemRequisicao && origemRequisicao.descricao && (
            <span className="info">
              <strong>Origem: </strong>
              {origemRequisicao.descricao}
            </span>
          )}
          {contato && (
            <span className="info">
              <strong>Contato: </strong>
              {contato}
            </span>
          )}
          {telefone && (
            <span className="info">
              <strong>Telefone: </strong>
              {telefone}
            </span>
          )}
        </div>
        <hr className="divisorTab" />
        {((veiculo && veiculo.dataFinalGarantia) ||
          (cliente && cliente.premium !== null && cliente.premium !== undefined) ||
          (veiculo &&
            veiculo.enderecoFavorito &&
            veiculo.enderecoFavorito.descricao &&
            veiculo.enderecoFavorito.endereco &&
            veiculo.enderecoFavorito.endereco.logradouro) ||
          (veiculo && veiculo.chassis)) && (
          <div>
            <div className="infoContainer">
              <h3>Validação</h3>
              {veiculo && veiculo.dataFinalGarantia && (
                <span className="info">
                  <strong>Data Final Garantia: </strong>
                  {veiculo.dataFinalGarantia}
                </span>
              )}
              {cliente && cliente.premium !== null && cliente.premium !== undefined && (
                <span className="info">
                  <strong>Prime: </strong>
                  {cliente.premium ? 'Sim' : 'Não'}
                </span>
              )}
              {veiculo &&
                veiculo.enderecoFavorito &&
                veiculo.enderecoFavorito.endereco &&
                veiculo.enderecoFavorito.descricao &&
                veiculo.enderecoFavorito.endereco.logradouro && (
                  <span className="info">
                    <strong>Concessionária de Compra: </strong>
                    {`${veiculo.enderecoFavorito.descricao} | ${veiculo.enderecoFavorito.endereco.logradouro}`}
                  </span>
                )}
              {veiculo && veiculo.chassis && (
                <span className="info">
                  <strong>Chassis: </strong>
                  {veiculo.chassis}
                </span>
              )}
            </div>
            <hr className="divisorTab" />
          </div>
        )}
        {(kmAtual || tipoCarga || requisicao) && (
          <div className="infoContainer">
            <h3>Detalhes</h3>
            {kmAtual && (
              <span className="info">
                <strong>km Atual: </strong>
                {kmAtual}
              </span>
            )}
            {tipoCarga && (
              <span className="info">
                <strong>Tipo de Carga: </strong>
                {tipoCarga}
              </span>
            )}
            {requisicao && (
              <span className="info">
                <strong>Descrição: </strong>
                {requisicao}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}
