import React, { Component } from "react";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  let keyFieldName = ownProps.keyField || "currentOption";

  return {
    ...ownProps,
    currentOption: state[ownProps.context][keyFieldName],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onOptionChanged: (event, doAfterChange = () => {}) => {
      dispatch([ownProps.onOptionChanged(ownProps.optionKey), doAfterChange()]);
    },
  };
};

const OptionChooserView = ({
  context,
  currentOption,
  optionKey,
  icon,
  text,
  onOptionChanged,
  doAfterChange,
  disabled = false,
}) => {
  const getclassName = () => {
    let base = "btn btn-sm ";
    return currentOption == optionKey
      ? base + "btn-success"
      : base + "btn-default";
  };

  return (
    <button
      className={getclassName()}
      style={{ marginRight: "6px" }}
      disabled={disabled}
      onClick={(e) => {
        onOptionChanged(e, doAfterChange);
      }}
    >
      <i className={icon} /> {text}
    </button>
  );
};
const OptionChooser = connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionChooserView);

export default OptionChooser;
