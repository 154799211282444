import React from 'react';

const doOpenModal = (context) => {
  $('#' + context).modal('show');
};

const doCloseModal = (context) => {
  $('#' + context).modal('hide');
};
const onModalClose = (context, doOnClose) => {
  $(document).on('hide.bs.modal', '#' + context, function () {
    doOnClose();
  });
};
const onModalOpen = (context, doOnOpen) => {
  $(document).on('show.bs.modal', '#' + context, function () {
    doOnOpen();
  });
};

const ModalButton = (props) => {
  return (
    <button
      type="button"
      className={props.className}
      onClick={(e) => {
        e.preventDefault();
        props.extraClick();
      }}
      data-toggle="modal"
      data-target={'#' + props.context}>
      <i className={props.icon} /> {props.text}
    </button>
  );
};

const ModalLink = (props) => {
  return (
    <a data-toggle="modal" data-target={'#' + props.context}>
      {props.children}
    </a>
  );
};

const CustomModal = (props) => {
  return (
    <div
      className="modal fade"
      id={props.context}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="customerModalLabel"
      data-backdrop={props.disableBackdropClose ? 'static' : true}
      data-keyboard={!props.disableBackdropClose}>
      <div
        className={`modal-dialog ${props.customClass}`}
        role="document"
        style={{width: props.width ? props.width : null}}>
        <div
          className="modal-content"
          style={{borderRadius: props.footer ? '4px 4px 0 0' : '4px'}}>
          {props.title ? (
            <div className="modal-header">
              {!props.hideCloseButton && (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  data-target={'#' + props.context}
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
              <h3 className="modal-title" id="customerModalLabel">
                {props.title}
              </h3>
            </div>
          ) : null}

          <div
            className={`clearfix ${props.hideClass ? '' : 'modal-body'}`}
            style={props.hideClass && {padding: '15px'}}>
            {props.children}
          </div>

          {props.footer ? (
            <div className="col-md-12 modal-footer" style={{borderRadius: '0 0 4px 4px'}}>
              {props.footer}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

const CustomModalWithoutTabIndex = (props) => {
  return (
    <div
      className="modal fade"
      id={props.context}
      role="dialog"
      aria-labelledby="customerModalLabel">
      <div className={`modal-dialog ${props.customClass}`} role="document">
        <div className="modal-content">
          {props.title ? (
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                data-target={'#' + props.context}
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h3 className="modal-title" id="customerModalLabel">
                {props.title}
              </h3>
            </div>
          ) : null}

          <div
            className={`clearfix ${props.hideClass ? '' : 'modal-body'}`}
            style={props.hideClass && {}}>
            {props.children}
          </div>

          {props.footer ? (
            <div className="col-md-12 modal-footer">{props.footer}</div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export {
  CustomModal as default,
  ModalButton,
  ModalLink,
  doOpenModal,
  doCloseModal,
  onModalClose,
  onModalOpen,
  CustomModalWithoutTabIndex,
};
