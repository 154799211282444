import moment from 'moment';

import {doGetV2} from './base/base_api_v2_helper';
import {doGet, doPost, doPut} from './base/base_api_helper';

/**
 *  Cria um novo atendimento na aplicação
 * @param {string} companyCode - código da empresa
 * @param {{requisicao: string, origemRequisicao: string, tipoRequisicao: string, tipoReclamacao: string}} requestInfo
 * @param {{ telefone: string, contato: string }} contact
 * @param {string} vehicle - código do veículo
 * @param {object} address
 */
export function createAttendence(companyCode, requestInfo, contact, vehicle, address, others) {
  const {telefone = '', contato = ''} = contact;
  const {
    requisicao,
    tipoRequisicao,
    tipoReclamacao,
    origemRequisicao,
    tipoMotivo,
  } = requestInfo;
  const body = {};

  if (companyCode) body.empresa = companyCode;
  if (requisicao) body.requisicao = requisicao;
  if (origemRequisicao) body.origemRequisicao = origemRequisicao;
  if (tipoMotivo) body.tipoMotivo = tipoMotivo;
  if (tipoReclamacao) body.tipoReclamacao = tipoReclamacao;
  if (tipoRequisicao) body.tipoRequisicao = tipoRequisicao;
  if (contato) body.contato = contato;
  if (telefone) body.telefone = telefone;
  if (vehicle) body.veiculo = vehicle;
  if (address) body.endereco = address;

  if (others) {
    const {
      kmAtual,
      tipoCarga,
      codigoTipoCarga,
      enderecoFavorito,
      distanciaAteoCliente,
      tempoAteoCliente,
      tipoRevisao,
      codigoAtendimentoInconformidade,
      tipoReclamacao,
    } = others;
    if (kmAtual) body.kmAtual = kmAtual;
    if (tipoCarga) body.tipoCarga = tipoCarga;
    if (codigoTipoCarga) body.codigoTipoCarga = codigoTipoCarga;
    if (enderecoFavorito) body.enderecoFavorito = enderecoFavorito;
    if (distanciaAteoCliente) body.distanciaAteoCliente = distanciaAteoCliente / 1000;
    if (tempoAteoCliente) body.tempoAteoCliente = tempoAteoCliente;
    if (tipoRevisao) body.tipoRevisao = tipoRevisao;
    if (codigoAtendimentoInconformidade)
      body.codigoAtendimentoInconformidade = codigoAtendimentoInconformidade;
    if (tipoReclamacao) body.tipoReclamacao = tipoReclamacao;
  }

  return doPost('atendimentos', body);
}

/**
 * Retorna um atendimento baseado no código informado
 * @param {string} code
 * @returns{Promise}
 */
export function findOneAttendence(code) {
  return doGet(`atendimentos/${code}`);
}

/**
 * Retorna todos os atendimentos baseado nos filtros selecionados
 * @param {{
 *  _origemRequisicao: string,
 *  _tipoRequisicao: string,
 *  _createdAtFrom: string,
 *  _createdAtTo: string,
 *  _empresa: string,
 *  _status: string,
 *  _veiculo: string
 *  _estagio: string
 * _cliente: string
 * }} filters
 * @returns {Promise}
 */
export function getAttendances(filters, page, limit) {
  const queries = [
    '_origemRequisicao',
    '_tipoRequisicao',
    '_createdAtFrom',
    '_createdAtTo',
    '_empresa',
    '_status',
    '_veiculo',
    '_estagio',
    '_protocolo',
    '_placa',
    '_cliente',
  ];

  const params = queries.reduce((acum, current) => {
    if (filters.hasOwnProperty(current)) {
      acum[current] = filters[current];
    } else if (filters.hasOwnProperty(current.slice(1))) {
      acum[current] = filters[current.slice(1)];
    }

    return acum;
  }, {});

  if (params && params._status) {
    const statusValues = params._status.split(',');
    const formattedStatus = statusValues
      .map((status) => {
        switch (status) {
          case 'STATUSATENDIMENTOABERTO':
            return 140;
          case 'STATUSATENDIMENTOPROGRAMADO':
            return 141;
          case 'STATUSATENDIMENTOFECHADO':
            return 142;
          default:
            return null;
        }
      })
      .filter((status) => status !== null)
      .join(',');

    if (formattedStatus) {
      params.statusIds = formattedStatus;
    }
  }

  if (params && params._estagio !== undefined) {
    const estagioValues = params._estagio.split(',');
    const formattedEstagio = estagioValues
      .map((status) => {
        switch (status) {
          case 'ESTAGIOVALIDADO':
            return 146;
          case 'ESTAGIOOPERADOR':
            return 147;
          case 'ESTAGIOTELECONFERENCIA':
            return 223;
          case 'ESTAGIOMECANICO':
            return 148;
          case 'ESTAGIOGUINCHO':
            return 149;
          case 'CONCLUIDO':
            return 149;
          case 'ESTAGIOCONCLUIDO':
            return 151;
          default:
            return null;
        }
      })
      .filter((status) => status !== null)
      .join(',');

    if (formattedEstagio) {
      params.stage = formattedEstagio;
    }
  }

  if (params && params._tipoRequisicao) {
    const tipoRequisicaoValues = params._tipoRequisicao.split(',');
    const formattedRequisição = tipoRequisicaoValues
      .map((status) => {
        switch (status) {
          case 'REQUISICAOTIPOINFORMACAO':
            return 137;
          case 'REQUISICAOTIPOASSISTENCIA':
            return 138;
          case 'REQUISICAOTIPOAGENDAMENTO':
            return 139;
          case 'REQUISICAOTIPORECUSA':
            return 393;
          case 'REQUISICAOTIPOSUPORTE':
            return 1390;
          default:
            return null;
        }
      })
      .filter((status) => status !== null)
      .join(',');

    if (formattedRequisição) {
      params.requestType = formattedRequisição;
    }
  }

  if (params && params._origemRequisicao !== undefined) {
    const origemReqValues = params._origemRequisicao.split(',');
    const formattedOrigem = origemReqValues
      .map((status) => {
        switch (status) {
          case 'REQUISICAOORIGEMLIGACAO':
            return 132;
          case 'REQUISICAOORIGEMAPLICATIVO':
            return 133;
          case 'REQUISICAOORIGEMCHAT':
            return 134;
          case 'REQUISICAOORIGEMWHATSAPP':
            return 135;
          case 'REQUISICAOORIGEMCHATBOT':
            return 136;
          default:
            return null;
        }
      })
      .filter((status) => status !== null)
      .join(',');

    if (formattedOrigem) {
      params.requestOrigin = formattedOrigem;
    }
  }
  if (params && params._empresa !== undefined) {
    params.company = params._empresa;
  }
  if (params && params._placa !== undefined) {
    params.plate = params._placa;
  }
  if (params && params._createdAtFrom !== undefined) {
    params.createdAtFrom = params._createdAtFrom;
  }
  if (params && params._createdAtTo !== undefined) {
    params.createdAtTo = params._createdAtTo;
  }
  if (params && params._cliente !== undefined) {
    params.customer = params._cliente;
  }
  if (params && params._veiculo !== undefined) {
    params.vehicle = params._veiculo;
  }
  if (params && params._protocolo !== undefined) {
    params.code = params._protocolo;
  }
  params._limit = 3000;
  params.limit = limit;
  params.page = page - 1;
  return doGetV2('attendances', params);
}

export function getAttendancesCsv(filters) {
  const queries = [
    '_origemRequisicao',
    '_tipoRequisicao',
    '_createdAtFrom',
    '_createdAtTo',
    '_empresa',
    '_status',
    '_veiculo',
    '_estagio',
    '_protocolo',
    '_placa',
    '_cliente',
  ];

  const params = queries.reduce((acum, current) => {
    if (filters.hasOwnProperty(current)) {
      acum[current] = filters[current];
    } else if (filters.hasOwnProperty(current.slice(1))) {
      acum[current] = filters[current.slice(1)];
    }

    return acum;
  }, {});

  params._limit = 3000;
  return doGet('atendimentos', params);
}

export function addAttendanceHistory(
  codeAtendimento,
  log,
  visibilidade,
  sendMail,
  email,
  estagio,
  adormecerAte,
  dataAgendado,
  kmAtualValidado,
  kmTotalRodadoMecanico,
) {
  const reqPayload = {
    codeAtendimento,
    log,
    visibilidade,
    kmAtualValidado,
    kmTotalRodadoMecanico,
  };

  if (sendMail) {
    reqPayload.sendMail = sendMail;
  }

  if (estagio) {
    reqPayload.estagio = estagio;
  }

  if (dataAgendado) {
    reqPayload.dataAgendado = moment(dataAgendado).format('YYYY-MM-DD HH:mm:ss');
  }

  if (adormecerAte) {
    reqPayload.adormecerAte = moment(adormecerAte).format('YYYY-MM-DD HH:mm:ss');
  }

  if (email) {
    reqPayload.email = email;
  }

  return doPut(`atendimentos/${codeAtendimento}/historico`, reqPayload);
}
/**
 * Obtém solicitações por atendimento
 * @param {string} codeAtendimento
 */
export function getAttendancesSolicitation(codeAtendimento) {
  return doGet(`atendimentos/${codeAtendimento}/solicitations`);
}

export function createAttendanceSolicitation(codeAtendimento, pedido) {
  const {
    enderecoOrigem,
    enderecoDestino,
    veiculoCliente,
    observacao,
    ownId,
    codigoCliente,
    situacaoVeiculo,
    codigoEmpresa,
    tipoServico,
    price,
    agendado,
    dataAgendada,
    ssPDF,
    motivoPatio,
    usarFranquia,
    enderecoDestinoAposPatio,
  } = pedido;

  const body = {usarFranquia};
  if (enderecoDestinoAposPatio) body.enderecoDestinoAposPatio = enderecoDestinoAposPatio;
  if (ownId) body.ownId = ownId;
  if (observacao) body.observacao = observacao;
  if (veiculoCliente) body.veiculoCliente = {codigo: veiculoCliente};
  if (enderecoOrigem) body.enderecoOrigem = enderecoOrigem;
  if (enderecoDestino) body.enderecoDestino = enderecoDestino;
  if (situacaoVeiculo) body.situacaoVeiculo = situacaoVeiculo;
  if (codigoEmpresa) body.codigoEmpresa = codigoEmpresa;
  if (codigoCliente) body.cliente = {codigo: codigoCliente};
  if (codeAtendimento) body.codigoAtendimento = codeAtendimento;
  if (tipoServico) body.tipoServico = tipoServico;
  if (price !== null && price !== undefined) body.valorCorrida = price;
  if (agendado) body.agendado = agendado;
  if (dataAgendada) body.dataAgendada = dataAgendada;
  if (motivoPatio) body.motivoPatio = motivoPatio;
  return doPost(`solicitations`, body);
}

/**
 *
 * @param {string} attendanceCode
 * @param {{
 *  dataAgendado: string
 *  kmAtualValidado: string
 * kmTotalRodadoMecanico: string
 * }} stats
 */
export function closeAttendence(attendanceCode, log, email, rest = {}) {
  return doPut(`atendimentos/${attendanceCode}/close`, {log, email, ...rest});
}

/**
 *
 * @param {string} codigoEmpresa
 */
export function getFavoriteAttendanceDestinations(codigoEmpresa) {
  let url = 'companies/favoriteDestinations';
  if (codigoEmpresa) url += `?_codigoEmpresa=${codigoEmpresa}`;
  return doGet(url);
}
