import React from 'react';

const IconBadge = ({typeList, type, handlePress}) => {
  let typeInfo = typeList[type] || {
    color: 'danger',
    icon: 'trash',
    description: type
  }

  return (
    <span className={"badge badge-"+typeInfo.color}>
      <button onClick={handlePress} style={{background: 'transparent', border: '0px', outline: '0px'}}>
        <i className={"fa fa-"+typeInfo.icon}> </i>
      </button>
    </span>
  )
}

export default IconBadge
