import swal from 'sweetalert';
import {push, replace} from 'react-router-redux';
import {browserHistory} from 'react-router';
import moment from 'moment';

import {toastError, toastSuccess} from './toast_actions';
import {startTimer, stopTimer} from './timer_actions';
import {confirmDialog} from './confirm_actions';
import {fullTextSearch} from '../utils/json_helper';
import {
  EMATENDIMENTO,
  AGUARDANDOACEITE,
  TIMER_MONITORING,
  PENDING_SOLICITATIONS,
  IN_PROGRESS_SOLICITATIONS,
  SCHEDULED_SOLICITATIONS,
  AGUARDANDOPROPOSTA,
  TIMER_CHECK_RESPONSE,
  TIMER_LIST,
  SOLICITACOES,
} from '../config';
import {
  acceptSolicitationResponse,
  getSolicitation,
  getPendingSolicitations,
  getSolicitationResponse,
  monitorSolicitation,
  reopenSolicitation,
  cancelSolicitation,
  solveSolicitation,
  getInProgressSolicitations,
  getScheduledSolicitations,
  getCalledPartner,
  serviceCompleted,
  updateResponsavelMonitoramento,
  removeResponsavelMonitoramento,
  updateResponsavelAcionamento,
  removeResponsavelAcionamento,
  informArrival,
  getConcludedSolicitations,
  updatePrevia,
  sendSMSPartnerWebapp,
  sendEMAILPartnerWebapp,
  sendCHATPartnerWebapp,
} from '../api/solicitation_helper';

export function loadPendingSolicitationsList() {
  //console.log('Update pending solicitations...')
  return (dispatch, getState) => {
    /*
    getPendingSolicitations().then((result) => {
      dispatch({type: PENDING_SOLICITATIONS_CHANGED, solicitationList: result.listSolicitations ? result.listSolicitations : []})
      dispatch(onSolicitationFilter(getState().followup.pendingSolicitationQuery, PENDING_SOLICITATIONS))
    })*/
    dispatch({
      type: `LOAD_PENDING_SOLICITATION`,
    });
  };
}

export function onSolicitationFilter(query, type) {
  return (dispatch, getState) => {
    if (type === PENDING_SOLICITATIONS) {
      const filteredSolicitations = fullTextSearch(
        getState().followup.pendingSolicitations,
        query,
      );
      dispatch({
        type: PENDING_SOLICITATIONS_FILTERED,
        solicitationList: filteredSolicitations,
        solicitationQuery: query,
      });
      dispatch({
        type: SET_STABLE_FILTERED_SOLICITATIONS,
        solicitationList: filteredSolicitations,
      });
    } else if (type === IN_PROGRESS_SOLICITATIONS) {
      const filteredSolicitations = fullTextSearch(
        getState().followup.inProgressSolicitations,
        query,
        true,
      );
      dispatch({
        type: IN_PROGRESS_SOLICITATIONS_FILTERED,
        solicitationList: filteredSolicitations,
        solicitationQuery: query,
      });
      dispatch({
        type: SET_STABLE_FILTERED_SOLICITATIONS,
        solicitationList: filteredSolicitations,
        query,
      });
    } else if (type === SCHEDULED_SOLICITATIONS) {
      const filteredSolicitations = fullTextSearch(
        getState().followup.scheduledSolicitations,
        query,
      );
      dispatch({
        type: SCHEDULED_SOLICITATIONS_FILTERED,
        solicitationList: filteredSolicitations,
        solicitationQuery: query,
      });
    } else {
      const filteredSolicitations = fullTextSearch(
        getState().followup.concludedSolicitations,
        query,
      );
      dispatch({
        type: CONCLUDED_SOLICITATIONS_FILTERED,
        solicitationList: filteredSolicitations,
        solicitationQuery: query,
      });
    }
  };
}

function formatterInProgressSolicitations(result) {
  const formattedInProgressSolicitations = result.map((solicitation) => {
    let priority = 0;

    const forecastInMs = moment(
      solicitation.respostaAceita.dataPrevisaoChegada,
      'DD/MM/YYYY hh:mm:ss',
    ).valueOf();
    const registeredInMs = moment().valueOf();
    const forecastInMin = moment.duration(forecastInMs - registeredInMs).asMinutes();

    if (solicitation.dataChegadadoParceiro) {
      const providerArrivalDateInMs = moment(
        solicitation.dataChegadadoParceiro,
        'DD/MM/YYYY hh:mm:ss',
      ).valueOf();
      const nowInMs = moment().valueOf();
      const minutesProviderArrived = moment
        .duration(nowInMs - providerArrivalDateInMs)
        .asMinutes();

      if (!solicitation.fezChecklist && minutesProviderArrived <= 10) {
        // N FEZ CHECKLIST
        priority = 1;
      } else if (!solicitation.fezChecklist && minutesProviderArrived > 10) {
        priority = 4;
      }
    } else if (forecastInMin < 0) {
      // ATRASADO
      priority = 5;
    } else if (forecastInMin > 0 && forecastInMin <= 10) {
      // POSSÍVEL ATRASO
      priority = 3;
    } else if (forecastInMin > 10 && forecastInMin <= 20) {
      // ATENÇÃO
      priority = 2;
    }

    return {
      ...solicitation,
      priority,
    };
  });

  return formattedInProgressSolicitations.sort(
    (a, b) => new Date(b.dataCadastro) - new Date(a.dataCadastro),
  );
}

export function loadInProgressSolicitationsList() {
  return (dispatch, getState) => {
    const user = getState().login.user_info.usuario.tipoUsuario;

    getInProgressSolicitations().then((result) => {
      let formattedResult;

      if (user === 1) {
        formattedResult = formatterInProgressSolicitations(result || []);
      } else {
        formattedResult = result || [];
      }

      dispatch({
        type: IN_PROGRESS_SOLICITATIONS_CHANGED,
        solicitationList: formattedResult,
      });

      dispatch(
        onSolicitationFilter(
          getState().followup.inProgressSolicitationQuery,
          IN_PROGRESS_SOLICITATIONS,
        ),
      );
    });
  };
}
export function loadConcludedSolicitationsList(
  startDate,
  endDate,
  sizePerPage,
  empresa,
  status,
  usuario,
  veiculo,
  parceiro,
  bases,
  servicos,
  conclusaoManual,
  codigo,
  page,
  ownId,
  vehicleCondition,
) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOAD_CONCLUDED_SOLICITATION`,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: sizePerPage,
      empresa: empresa,
      status: status,
      usuario: usuario,
      veiculo: veiculo,
      parceiro: parceiro,
      bases: bases,
      servicos,
      conclusaoManual,
      codigo,
      page,
      ownId,
      vehicleCondition,
    });
  };
}

export function loadConcludedSolicitationsListCVSExport(
  startDate,
  endDate,
  sizePerPage,
  empresa,
  status,
  usuario,
  veiculo,
  parceiro,
  servicos,
  bases,
  conclusaoManual,
  codigo,
  page,
  vehicleCondition,
) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOAD_CONCLUDED_SOLICITATION_CVS_EXPORT`,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: sizePerPage,
      empresa: empresa,
      status: status,
      usuario: usuario,
      veiculo: veiculo,
      parceiro: parceiro,
      servicos,
      bases,
      conclusaoManual,
      codigo,
      page,
      vehicleCondition,
    });
  };
}

export function loadScheduledSolicitationsList() {
  return (dispatch, getState) => {
    getScheduledSolicitations().then((result) => {
      dispatch({
        type: SCHEDULED_SOLICITATIONS_CHANGED,
        solicitationList: result.listSolicitations ? result.listSolicitations : [],
      });
      dispatch(
        onSolicitationFilter(
          getState().followup.scheduledSolicitationQuery,
          SCHEDULED_SOLICITATIONS,
        ),
      );
    });
    // dispatch({
    //   type: `LOAD_SCHEDULED_SOLICITATION`
    // })
  };
}

export function sendPartnerWebappSMS(telefone) {
  const formatted = '+55' + telefone.match(/\d+/g).join('');
  return (dispatch, getState) => {
    sendSMSPartnerWebapp(getState().followup.currentSolicitation.codigo, formatted)
      .then((result) => {
        if (result.codigoRetorno < 299) {
          toastSuccess(result.mensagemCliente);
        } else {
          toastError(result.mensagemCliente);
        }
      })
      .catch((error) => toastError(error.mensagemCliente));
  };
}
export function sendPartnerWebappChat() {
  return (dispatch, getState) => {
    sendCHATPartnerWebapp(getState().followup.currentSolicitation.codigo)
      .then((result) => {
        if (result.codigoRetorno < 299) {
          toastSuccess(result.mensagemCliente);
        } else {
          toastError(result.mensagemCliente);
        }
      })
      .catch((error) => toastError(error.mensagemCliente));
  };
}

export function sendPartnerWebappEmail(email) {
  const formatted = email.trim();
  return (dispatch, getState) => {
    sendEMAILPartnerWebapp(getState().followup.currentSolicitation.codigo, formatted)
      .then((result) => {
        if (result.codigoRetorno < 299) {
          toastSuccess(result.mensagemCliente);
        } else {
          toastError(result.mensagemCliente);
        }
      })
      .catch((error) => toastError(error.mensagemCliente));
  };
}

export function copyWebAppLinkToClipboard() {
  return (dispatch, getState) => {
    const codigo = getState().followup.currentSolicitation.codigo;
    navigator.clipboard
      .writeText(`https://prestador.soon.com.vc/${codigo}`)
      .then(() => {
        toastSuccess('Link copiado para a área de transferência');
      })
      .catch(() => {
        toastError('Não foi possível copiar o link para a área de transferência');
      });
  };
}

export function loadCalledPartnersList(solicitationId) {
  return (dispatch, getState) => {
    /*
    getCalledPartner(solicitationId).then((result) => {
      dispatch({type: CALLED_PARTINERS_CHANGED, calledPartners: result.listCalledPartners})
    })*/
    dispatch({
      type: `GET_CALLED_PARTNERS`,
      value: solicitationId,
    });
  };
}

export function onSolicitationSelect(solicitationId) {
  return (dispatch) => {
    dispatch({
      type: `FOLLOWUP_SINGLE_SOLICITATION`,
      value: solicitationId,
    });
  };
  /*
  return (dispatch, getState) => {
    getSolicitation(solicitationId).then((result) => {
      dispatch( replace('/followup/' + result.solicitacao.codigo) )
      dispatch({type: SOLICITATION_SELECT, solicitation: result.solicitacao})
      dispatch(loadCalledPartnersList(solicitationId))
      dispatch(startSolicitationMonitoring(result.solicitacao))
      dispatch(stopTimer(SOLICITACOES))

    })
  }*/
}

export function startSolicitationMonitoring(solicitation) {
  return (dispatch, getState) => {
    if (
      solicitation.statusSolicitacao == AGUARDANDOACEITE ||
      solicitation.statusSolicitacao == AGUARDANDOPROPOSTA
    ) {
      dispatch(
        startTimer(AGUARDANDOACEITE, TIMER_CHECK_RESPONSE, () => {
          dispatch(checkSolicitationResponse(solicitation.codigo));
        }),
      );
    } else if (solicitation.statusSolicitacao == EMATENDIMENTO) {
      dispatch(
        startTimer(EMATENDIMENTO, TIMER_MONITORING, () => {
          dispatch(monitorSolicitationStatus(solicitation.codigo));
        }),
      );
    }
  };
}

export function checkSolicitationResponse(solicitationId) {
  return (dispatch, getState) => {
    getSolicitationResponse(solicitationId)
      .then((result) => {
        dispatch({type: SOLICITATION_RESPONSE, response: result.proposta});
      })
      .catch((result) => {
        if (result.codigoRetorno == 423) dispatch({type: SOLICITATION_AGUARDANDOPROPOSTA});
        if (result.codigoRetorno == 445) dispatch(stopTimer(AGUARDANDOACEITE));
        dispatch(onSolicitationSelect(solicitationId));
        dispatch(
          startTimer(EMATENDIMENTO, TIMER_MONITORING, () => {
            dispatch(monitorSolicitationStatus(solicitationId));
          }),
        );
      });
  };
}

export function monitorSolicitationStatus(solicitationId) {
  return (dispatch, getState) => {
    monitorSolicitation(solicitationId)
      .then((result) => {
        dispatch({
          type: SOLICITATION_MONITORING,
          partnerStatus: result.solicitacao,
        });
        dispatch({
          type: SOLICITATION_SELECT,
          solicitation: result.solicitacao,
        });
      })
      .catch((result) => {
        if (result.codigoRetorno == 423) dispatch({type: SOLICITATION_AGUARDANDOPROPOSTA});
        else if (result.codigoRetorno == 422) {
          stopTimer(EMATENDIMENTO);
          dispatch(onSolicitationUnselect());
        }
      });
  };
}

export function onAcceptSolicitationResponse() {
  return (dispatch, getState) => {
    let response_code = getState().followup.currentResponse.codigo;
    let solicitation_code = getState().followup.currentSolicitation.codigo;

    acceptSolicitationResponse(solicitation_code, response_code).then((result) => {
      dispatch(stopTimer(AGUARDANDOACEITE));
      dispatch(onSolicitationSelect(solicitation_code));
    });
  };
}

export function onSolicitationUnselect() {
  return (dispatch, getState) => {
    dispatch(
      startTimer(SOLICITACOES, TIMER_LIST, () => {
        dispatch(loadPendingSolicitationsList());
      }),
    );
    dispatch(stopTimer(AGUARDANDOACEITE));
    dispatch(stopTimer(EMATENDIMENTO));
    dispatch({type: SOLICITATION_UNSELECT});
    dispatch(push('/followup'));
    dispatch({type: 'SOLICITATION_LOG_FETCHED', payload: []});
  };
}

export function onSolicitationSolutionChanged(field, value) {
  return {type: SOLICITATION_SOLUTION_CHANGED, field: field, value: value};
}

export function onSolicitationReopen() {
  return (dispatch, getState) => {
    reopenSolicitation(getState().followup.currentSolicitation.codigo)
      .then((result) => {
        dispatch(onSolicitationSolve());
        dispatch(onSolicitationSelect(result.solicitacao.codigo));
        dispatch(loadPendingSolicitationsList());
        dispatch(loadScheduledSolicitationsList());
        toastSuccess('Solicitação recriada com sucesso.');
      })
      .catch((error) => {
        toastError(error.mensagemRetorno);
      });
  };
}

export function onResponsavelMonitoramentoUpdate(body = {}) {
  return (dispatch, getState) => {
    updateResponsavelMonitoramento(getState().followup.currentSolicitation.codigo, body)
      .then((result) => {
        dispatch(onSolicitationSelect(result.solicitacao.codigo));
        dispatch(loadPendingSolicitationsList());
        toastSuccess('Responsável definido com sucesso.');
      })
      .catch((error) => {
        toastError(error.mensagemRetorno);
      });
  };
}

export function onResponsavelAcionamentoUpdate(body = {}) {
  return (dispatch, getState) => {
    updateResponsavelAcionamento(getState().followup.currentSolicitation.codigo, body)
      .then((result) => {
        dispatch(onSolicitationSelect(result.solicitacao.codigo));
        dispatch(loadPendingSolicitationsList());
        toastSuccess('Responsável definido com sucesso.');
      })
      .catch((error) => {
        toastError(error.mensagemRetorno);
      });
  };
}

export function onResponsavelMonitoramentoRemove(body = {}) {
  return (dispatch, getState) => {
    removeResponsavelMonitoramento(getState().followup.currentSolicitation.codigo, body).then(
      (result) => {
        dispatch(onSolicitationSelect(result.solicitacao.codigo));
        dispatch(loadPendingSolicitationsList());
        toastSuccess('Responsável removido com sucesso.');
      },
    );
  };
}

export function onResponsavelAcionamentoRemove(body = {}) {
  return (dispatch, getState) => {
    removeResponsavelAcionamento(getState().followup.currentSolicitation.codigo, body).then(
      (result) => {
        dispatch(onSolicitationSelect(result.solicitacao.codigo));
        dispatch(loadPendingSolicitationsList());
        toastSuccess('Responsável removido com sucesso.');
      },
    );
  };
}

export function onSolicitationCancel(body = {}) {
  return (dispatch, getState) => {
    cancelSolicitation(getState().followup.currentSolicitation.codigo, body).then((result) => {
      dispatch(onSolicitationUnselect());
      dispatch(loadPendingSolicitationsList());
      dispatch(loadInProgressSolicitationsList());
      dispatch(loadScheduledSolicitationsList());
      toastSuccess('Solicitação cancelada com sucesso.');
    });
  };
}

export function onSolicitationSolve() {
  return (dispatch, getState) => {
    solveSolicitation(
      getState().followup.currentSolicitation.codigo,
      getState().followup.solutionCode,
    ).then((result) => {
      dispatch(onSolicitationUnselect());
      dispatch(loadPendingSolicitationsList());
    });
  };
}

export function stopAllTimers() {
  return (dispatch, getState) => {
    /*
    dispatch(stopTimer(AGUARDANDOACEITE) )
    dispatch(stopTimer(EMATENDIMENTO) )
    dispatch(stopTimer(SOLICITACOES) )*/
    dispatch({
      type: `STOP_ALL_TIMERS`,
    });
  };
}
export function openWhatsApp(number, msg) {
  return (dispatch) => {
    if (msg)
      window.open(
        'https://web.whatsapp.com/send?l=pt_br&phone=55' +
          number.match(/[0-9]/g).join('') +
          '&text=' +
          msg,
        '_blank',
      );
    else
      window.open(
        'https://web.whatsapp.com/send?l=pt_br&phone=55' + number.match(/[0-9]/g).join(''),
        '_blank',
      );
  };
}

export function onServiceCompleted() {
  return (dispatch, getState) => {
    swal(
      {
        title: 'Concluir Serviço',
        text: 'Data e hora da conclusão:',
        type: 'input',
        inputType: 'datetime-local',
        inputValue: moment().local().format('YYYY-MM-DDTHH:mm'),
        showCancelButton: true,
        animation: 'slide-from-top',
      },
      (inputValue) => {
        if (inputValue === false) return false;

        if (inputValue === '') {
          swal.showInputError('You need to write something!');
          return false;
        }
        serviceCompleted(
          getState().followup.currentSolicitation.codigo,
          moment(inputValue).format('YYYY-MM-DD HH:mm:ss'),
        )
          .then(() => {
            toastSuccess('Sucesso');
            dispatch([onSolicitationUnselect()]);
          })
          .catch((err) => {
            console.log(err);
            toastError(err.mensagemCliente);
            //swal("Algo de errado aconteceu!", err.mensagemCliente, "error");
          });
      },
    );
  };
}

export function onInformArrival() {
  return (dispatch, getState) => {
    swal(
      {
        title: 'Informar chegada ao cliente',
        text: 'Data e hora da chegada:',
        type: 'input',
        inputType: 'datetime-local',
        inputValue: moment().local().format('YYYY-MM-DDTHH:mm'),
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
      },
      (inputValue) => {
        if (inputValue === false) return false;

        if (inputValue === '') {
          swal.showInputError('You need to write something!');
          return false;
        }
        informArrival(
          getState().followup.currentSolicitation.codigo,
          moment(inputValue).format('YYYY-MM-DD HH:mm:ss'),
        )
          .then(() => {
            swal(
              'Sucesso!',
              'Chegada ao cliente em: ' + moment(inputValue).format('YYYY-MM-DD HH:mm:ss'),
              'success',
            );
          })
          .catch((err) => {
            console.log(err);
            swal('Algo de errado aconteceu!', err.mensagemCliente, 'error');
          });
      },
    );
  };
}

export function onUpdatePrevia() {
  return (dispatch, getState) => {
    swal(
      {
        title: 'Informar nova prévia de chegada do parceiro',
        text: 'Data e hora da previsão:',
        type: 'input',
        inputType: 'datetime-local',
        inputValue: moment().local().format('YYYY-MM-DDTHH:mm'),
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
      },
      (inputValue) => {
        if (inputValue === false) return false;

        if (inputValue === '') {
          swal.showInputError('You need to write something!');
          return false;
        }
        updatePrevia(
          getState().followup.currentSolicitation.codigo,
          moment(inputValue).format('YYYY-MM-DD HH:mm:ss'),
        )
          .then(() => {
            swal(
              'Sucesso!',
              'Nova prévia: ' + moment(inputValue).format('YYYY-MM-DD HH:mm:ss'),
              'success',
            );
            dispatch(onSolicitationSelect(getState().followup.currentSolicitation.codigo));
          })
          .catch((err) => {
            console.log(err);
            swal('Algo de errado aconteceu!', err.mensagemCliente, 'error');
          });
      },
    );
  };
}

export const SOLICITATION_SELECT = 'SOLICITATION_SELECT';
export const SOLICITATION_UNSELECT = 'SOLICITATION_UNSELECT';

export const PENDING_SOLICITATIONS_CHANGED = 'PENDING_SOLICITATIONS_CHANGED';
export const PENDING_SOLICITATIONS_FILTERED = 'PENDING_SOLICITATIONS_FILTERED';
export const SET_STABLE_FILTERED_SOLICITATIONS = 'SET_STABLE_FILTERED_SOLICITATIONS';

export const UPDATE_RESPONSAVEL = 'UPDATE_RESPONSAVEL';

export const IN_PROGRESS_SOLICITATIONS_CHANGED = 'IN_PROGRESS_SOLICITATIONS_CHANGED';
export const IN_PROGRESS_SOLICITATIONS_FILTERED = 'IN_PROGRESS_SOLICITATIONS_FILTERED';

export const CONCLUDED_SOLICITATIONS = 'CONCLUDED_SOLICITATIONS';
export const CONCLUDED_SOLICITATIONS_CHANGED = 'CONCLUDED_SOLICITATIONS_CHANGED';
export const CONCLUDED_SOLICITATIONS_FILTERED = 'CONCLUDED_SOLICITATIONS_FILTERED';
export const CONCLUDED_SOLICITATIONS_CVS_CHANGED = 'CONCLUDED_SOLICITATIONS_CVS_CHANGED';
export const CONCLUDED_SOLICITATIONS_CVS_PROGRESS = 'CONCLUDED_SOLICITATIONS_CVS_PROGRESS';
export const CONCLUDED_SOLICITATIONS_CVS_CLEAR = 'CONCLUDED_SOLICITATIONS_CVS_CLEAR';

export const SCHEDULED_SOLICITATIONS_CHANGED = 'SCHEDULED_SOLICITATIONS_CHANGED';
export const SCHEDULED_SOLICITATIONS_FILTERED = 'SCHEDULED_SOLICITATIONS_FILTERED';

export const SOLICITATION_SOLUTION_CHANGED = 'SOLICITATION_SOLUTION_CHANGED';
export const SOLICITATION_RESPONSE = 'SOLICITATION_RESPONSE';
export const SOLICITATION_MONITORING = 'SOLICITATION_MONITORING';
export const SOLICITATION_AGUARDANDOPROPOSTA = 'SOLICITATION_AGUARDANDOPROPOSTA';
export const CALLED_PARTINERS_CHANGED = 'CALLED_PARTINERS_CHANGED';

export const FOLLOWUP_SINGLE_SOLICITATION = `FOLLOWUP_SINGLE_SOLICITATION`;
