import {push} from 'react-router-redux';
import axios from 'axios';

import {toastError, toastSuccess} from './toast_actions';
import {onFormError, onFormSuccess, setFormValues, clearFormValues} from './form_actions';
import {onOptionSelected} from './autocomplete_actions';
import {baseTranslationMap, translateObjectKeys} from '../utils/translationUtils';
import {BASE_URL} from '../config';
import {doGet, doDelete, doPost} from '../api/base/base_api_helper';
import {
  getBases,
  getSingleBase,
  createBase,
  updateBase,
  deleteBase,
  getBaseApiValues,
  filterBases,
  setBaseHistory,
  resetPassword,
  createLead,
} from '../api/base_helper';
import {result} from 'lodash';

const getStatus = (statusCode) => {
  let status;
  switch (statusCode) {
    case 'BASEATIVA':
      status = 'Ativa';
      break;

    case 'BASEINATIVA':
      status = 'Inativo';
      break;

    case 'BASEPENDENTEATIVACAO':
      status = 'Aguardando';
      break;
  }

  return status;
};

export function clearBaseList() {
  return (dispatch, getState) => {
    dispatch({type: BASE_LIST_CHANGED, value: []});
  };
}

export function loadBaseList(pageNumber) {
  return (dispatch, getState) => {
    dispatch({type: BASE_LIST_IS_FETCHING, value: true});
    const filter = getState().base.baseFilter;
    if (filter && filter.length > 0) {
      dispatch(filterBaseList(filter));
    } else {
      dispatch(
        getBases(pageNumber)
          .then((results) => {
            dispatch([
              {
                type: BASE_LIST_CHANGED,
                value: results.listBase.map((b) => {
                  return {
                    contato: b.contato,
                    nomeFantasia: b.nomeFantasia,
                    razaoSocial: b.razaoSocial,
                    documento: b.documento,
                    tipoDocumento: b.tipoDocumento,
                    telefoneCelular: b.telefoneCelular,
                    codigo: b.codigo,
                    email: b.email,
                    login: b.login,
                    status: getStatus(b.status.codigo),
                    endereco: b.endereco,
                    visualizar: b.visualizar,
                    telefoneFixo: b.telefoneFixo,
                    history: '',
                    historico: b.historico,
                    contaBancaria: b.contaBancaria,
                  };
                }),
              },
              {type: BASE_LIST_IS_FETCHING, value: false},
            ]);
          })
          .catch((error) => {
            console.log('Exception on base_actions.loadBaseList(...): ' + error.message);
            dispatch({type: BASE_LIST_IS_FETCHING, value: false});
          }),
      );
    }
  };
}
export function saveFilter() {
  return (dispatch, getState) => {
    dispatch({
      type: 'FILTER_TEXT_SAVED',
      value: getState().base.baseFilter,
    });
  };
}

export function filterBaseList(filter, attributes) {
  return (dispatch) => {
    dispatch({
      type: FILTER_BASE_TEXT,
      value: filter,
    });
    filterBases(filter, attributes)
      .then((results) => {
        //dispatch ({type: BASE_LIST_CHANGED, value: results.listBase})
        dispatch([
          {
            type: BASE_LIST_CHANGED,
            value: results.listBase
              ? results.listBase.map((b) => ({
                  contato: b.contato,
                  nomeFantasia: b.nomeFantasia,
                  razaoSocial: b.razaoSocial,
                  documento: b.documento,
                  tipoDocumento: b.tipoDocumento,
                  telefoneCelular: b.telefoneCelular,
                  codigo: b.codigo,
                  email: b.email,
                  login: b.login,
                  status: getStatus(b.status.codigo),
                  endereco: b.endereco,
                  visualizar: b.visualizar,
                  telefoneFixo: b.telefoneFixo,
                  history: '',
                  historico: b.historico,
                  contaBancaria: b.contaBancaria,
                  dataCadastro: b.dataCadastro,
                }))
              : [],
          },
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function filterBaseCollection(filter, attributes, page, limit) {
  return (dispatch) => {
    dispatch({
      type: FILTER_BASE_TEXT,
      value: filter,
    });
    dispatch({type: BASE_LIST_IS_FETCHING, value: true});
    filterBases(filter, attributes, page, limit)
      .then((results) => {
        const data = results.bases.map((result) =>
          translateObjectKeys(result, baseTranslationMap),
        );

        dispatch([
          {
            type: BASE_COLLECTION_CHANGED,
            value: data
              ? data.map((b) => ({
                  contato: b.contato,
                  nomeFantasia: b.nomeFantasia,
                  razaoSocial: b.razaoSocial,
                  documento: b.documento,
                  tipoDocumento: b.tipoDocumento,
                  telefoneCelular: b.telefoneCelular,
                  codigo: b.codigo,
                  email: b.email,
                  login: b.login,
                  status: getStatus(b.statusId),
                  endereco: b.endereco,
                  visualizar: b.visualizar,
                  telefoneFixo: b.telefoneFixo,
                  history: '',
                  historico: b.historico,
                  contaBancaria: b.contaBancaria,
                  dataCadastro: b.dataCadastro,
                  tototalcount: results.totalCount,
                }))
              : [],
          },
          dispatch({type: BASE_LIST_IS_FETCHING, value: false}),
        ]);
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: BASE_LIST_IS_FETCHING, value: false});
      });
  };
}

export function getBasesToActive() {}

export function getBaseHistory(baseCode) {
  return (dispatch, getState) => {
    getSingleBase(baseCode)
      .then((resp) => {
        dispatch({
          type: BASE_HISTORY_FETCHED,
          value: resp.base.historico || [],
        });
      })
      .catch((e) => {
        toastError('Error');
        console.log(e);
      });
  };
}

export function getCurrentBase(baseCode) {
  return (dispatch, getState) => {
    getSingleBase(baseCode)
      .then((resp) => {
        dispatch({type: CURRENT_BASE_FETCHED, value: resp.base});
      })
      .catch((e) => {
        toastError('Error');
        console.log(e);
      });
  };
}

export function putBaseHistory(baseCode, log) {
  return (dispatch) => {
    setBaseHistory(baseCode, log)
      .then((resp) => {
        dispatch({type: BASE_HISTORY_FETCHED, value: resp.historico});
      })
      .catch((e) => {
        toastError('Erro ao resgitrar histórico da base');
        console.log(e);
      });
  };
}

export function setBaseFormDetails(formContext, baseCode) {
  return (dispatch, getState) => {
    getSingleBase(baseCode)
      .then((result) => {
        dispatch(setFormValues(formContext, result.cliente));
        dispatch(onFormSuccess(formContext));
      })
      .catch((error) => {
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function setBaseInputDetails(inputContext, baseCode) {
  return (dispatch, getState) => {
    getSingleBase(baseCode)
      .then((result) => {
        dispatch(onOptionSelected(inputContext, result.cliente));
      })
      .catch((error) => {
        console.log('Exception on base_actions.setBaseInputDetails(...): ' + error.message);
      });
  };
}

export function createNewBase(formContext, doAfterSuccess) {
  return (dispatch, getState) => {
    let baseData = getState().form['registerBase'].values;
    const baseAddress = getState().service.baseEndereco;

    baseData.endereco = {...baseData.endereco, ...baseAddress};

    createBase(baseData)
      .then((result) => {
        dispatch([onFormSuccess(formContext), clearFormValues(formContext)]);
        toastSuccess('Cadastro Concluido!');
        doAfterSuccess(result.base.codigo);
      })
      .catch((error) => {
        toastError(error.message);
        if (error.message === `Cannot read property 'replace' of undefined`) {
          dispatch(onFormSuccess(formContext));
          doAfterSuccess();
        }
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function createNewLead(formContext, doAfterSuccess) {
  return (dispatch, getState) => {
    let baseData = getState().form['registerBase'].values;
    const baseAddress = getState().service.baseEndereco;

    baseData.endereco = {...baseData.endereco, ...baseAddress};

    createLead(baseData)
      .then((result) => {
        dispatch([onFormSuccess(formContext), clearFormValues(formContext)]);
        toastSuccess('Cadastro Concluido!');
        doAfterSuccess();
      })
      .catch((error) => {
        toastError(error.message);
        if (error.message === `Cannot read property 'replace' of undefined`) {
          dispatch(onFormSuccess(formContext));
          doAfterSuccess();
        }
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function resetBasePassword(baseCode) {
  return (dispatch) => {
    swal(
      {
        title: 'Deseja resetar a senha?',
        text: 'A nova senha será igual ao login da base.',
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
        type: 'warning',
      },
      () => {
        resetPassword(baseCode)
          .then(({mensagemRetorno}) => {
            toastSuccess(mensagemRetorno || 'Senha resetada com sucesso.');
          })
          .catch((e) => {
            toastError(e.mensagemRetorno || e.status);
          })
          .finally(() => {
            swal.close();
          });
      },
    );
  };

  //'partners/code/setDefaultPassword'
}

export function updateExistingBase(formContext, doAfterSuccess) {
  return (dispatch, getState) => {
    let baseData = getState().form['registerBase'].values;
    const initialBaseData = getState().form['registerBase'].initial;
    const baseAddress = getState().service.baseEndereco;

    baseData.endereco = {...baseData, ...baseAddress};

    updateBase(baseData.codigo, baseData, initialBaseData)
      .then((result) => {
        toastSuccess('Cadastro Atualizado!');
        dispatch({type: CURRENT_BASE_FETCHED, value: result.base});
        if (result.base.usuarioAtivacao) {
          doAfterSuccess(result.base.codigo, result.base.usuarioAtivacao.nome);
        } else {
          doAfterSuccess(result.base.codigo, null);
        }
      })
      .catch((error) => {
        toastError(error.message);
        if (error.message === `Cannot read property 'replace' of undefined`) {
          dispatch(onFormSuccess(formContext));
        }
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function deleteExistingBase(base, doAfterSuccess) {
  return (dispatch, getState) => {
    deleteBase(base.codigo)
      .then((result) => {
        //dispatch ({type: BASE_INSTANCE_CHANGED, baseId: baseData.codigo, baseData: getBaseApiValues(baseData)})
        dispatch(loadBaseList());
        doAfterSuccess();
      })
      .catch((error) => {
        toastError(error.message || error); //dispatch( onFormError(context, error.message) )
      });
  };
}

export function getBaseBalance(baseCode) {
  return (dispatch) => {
    dispatch({type: BASE_BALANCE_FETCHING, value: true});
    doGet(`bases/${baseCode}/invoices`)
      .then((resp) => {
        dispatch([
          {type: BASE_BALANCE_FETCHED, value: resp.listInvoice},
          {type: BASE_BALANCE_FETCHING, value: false},
        ]);
      })
      .catch((e) => {
        dispatch({type: BASE_BALANCE_FETCHING, value: false});
        console.log(e);
      });
  };
}

export function newInvoice(attributes) {
  return (dispatch) => {
    doPost(`invoices`, attributes)
      .then((resp) => {
        toastSuccess(resp.mensagemRetorno);
      })
      .catch((err) => toastError(err.mensagemRetorno));
  };
}

export function clearInvoices() {
  return {
    type: BASE_BALANCE_FETCHED,
    value: [],
  };
}

export function getAllInvoices(attributes) {
  return (dispatch) => {
    dispatch([
      {type: BASE_BALANCE_FETCHED, value: []},
      {type: BASE_BALANCE_FETCHING, value: true},
    ]);

    doGet(`invoices`, attributes)
      .then((resp) => {
        const {listInvoice = []} = resp;

        dispatch([
          {type: BASE_BALANCE_FETCHED, value: listInvoice},
          {type: BASE_BALANCE_FETCHING, value: false},
        ]);
      })
      .catch((e) => {
        dispatch([
          {type: BASE_BALANCE_FETCHING, value: false},
          {type: BASE_BALANCE_FETCHED, value: []},
        ]);
        console.log(e);
      });
  };
}

export function getBaseLastConcluded(baseCode) {
  return (dispatch) => {
    dispatch({type: BASE_LAST_CONCLUDED_FETCHING, value: true});
    doGet(`bases/${baseCode}/ultimasConcluidas`)
      //doGet(`bases/${baseCode}/ultimasPerdidas`)
      .then((resp) => {
        dispatch([
          {
            type: BASE_LAST_CONCLUDED_FETCHED,
            value: resp.listSolicitations || [],
          },
          {type: BASE_LAST_CONCLUDED_FETCHING, value: false},
        ]);
      })
      .catch((e) => {
        dispatch({type: BASE_LAST_CONCLUDED_FETCHING, value: false});
        console.log(e);
      });
  };
}
export function getBaseLastLost(baseCode) {
  return (dispatch) => {
    dispatch({type: BASE_LAST_LOST_FETCHING, value: true});
    doGet(`bases/${baseCode}/ultimasPerdidas`)
      .then((resp) => {
        dispatch([
          {type: BASE_LAST_LOST_FETCHED, value: resp.listSolicitations},
          {type: BASE_LAST_LOST_FETCHING, value: false},
        ]);
      })
      .catch((e) => {
        dispatch({type: BASE_LAST_LOST_FETCHING, value: false});
        console.log(e);
      });
  };
}

export function postImage(baseId, files, typeDocument) {
  return (dispatch) => {
    let errorMsg = 'Uma ou mais imagens apresentaram erro : ';
    let error = false;

    if (!files) return;

    const promiseChain = [];
    files.forEach((f, i) => {
      let formData = new FormData();
      formData.append('image', f, f.name);
      promiseChain.push(
        axios.post(`${BASE_URL}bases/${baseId}/documents/${typeDocument}`, formData, {
          headers: {
            'Content-Type': 'multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E',
          },
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }),
      );
    });

    Promise.all(promiseChain)
      .then((resp) => {
        resp.forEach((r, i) => {
          if (!r.data.sucesso) {
            error = true;
            errorMsg += '\n' + r.data.mensagemRetorno;
          }
        });

        if (error) toastError(errorMsg);
        else toastSuccess('Imagens enviadas com sucesso!');

        dispatch(getBaseDocuments(baseId));
      })
      .catch((e) => {
        toastError('Erro ao enviar as imagens.');
        console.log(e);
      });
  };
}

export function getBaseDocuments(baseId) {
  return (dispatch) => {
    if (!baseId) return;

    dispatch({
      type: 'BASE_IMAGES_FETCHING',
      payload: true,
    });
    doGet(`bases/${baseId}/documents/`)
      .then((resp) => {
        dispatch([
          {
            type: 'BASE_IMAGES_FETCHED',
            value: resp.listDocumentos || [],
          },
          {
            type: 'BASE_IMAGES_FETCHING',
            value: false,
          },
        ]);
      })
      .catch((e) => {
        toastError('Erro acessar as imagens no servidor');
      });
  };
}

export function deleteBaseDocuments(baseId, documentCode) {
  return (dispatch) => {
    doDelete(`bases/${baseId}/documents/${documentCode}`)
      .then((resp) => {
        toastSuccess('Imagem deletada com sucesso');
        dispatch(getBaseDocuments(baseId));
      })
      .catch((e) => {
        toastError('Erro ao deletar imagem');
        console.log(e);
      });
  };
}

export function setBaseFetching() {
  return {type: CURRENT_BASE_IS_FETCHING};
}

export function resetCurrentBase() {
  return {type: RESET_CURRENT_BASE};
}

export function changeBaseAddress(address) {
  return (dispatch, getState) => {
    const complemento = getState().base.currentBase.endereco.complemento;
    const endereco = {
      logradouro: address.label,
      complemento,
      bairro: address.area,
      cidade: address.city,
      numero: address.number,
      cep: address.postal_code,
      pais: address.country,
      estado: address.province,
      latitude: address.location.lat,
      longitude: address.location.lng,
    };

    return dispatch({type: CHANGE_BASE_ADDRESS, value: {endereco}});
  };
}

export const BASE_LIST_CHANGED = 'BASE_LIST_CHANGED';
export const BASE_INSTANCE_CHANGED = 'BASE_INSTANCE_CHANGED';
export const FILTER_BASE_TEXT = 'FILTER_BASE_TEXT';
export const BASE_HISTORY_FETCHED = 'BASE_HISTORY_FETCHED';
export const BASE_BALANCE_FETCHED = 'BASE_BALANCE_FETCHED';
export const BASE_BALANCE_FETCHING = 'BASE_BALANCE_FETCHING';
export const BASE_LAST_CONCLUDED_FETCHED = 'BASE_LAST_CONCLUDED_FETCHED';
export const BASE_LAST_CONCLUDED_FETCHING = 'BASE_LAST_CONCLUDED_FETCHING';
export const BASE_LAST_LOST_FETCHED = 'BASE_LAST_LOST_FETCHED';
export const BASE_LAST_LOST_FETCHING = 'BASE_LAST_LOST_FETCHING';
export const BASE_IMAGES_FETCHING = 'BASE_IMAGES_FETCHING';
export const BASE_IMAGES_FETCHED = 'BASE_IMAGES_FETCHED';
export const CURRENT_BASE_FETCHED = 'CURRENT_BASE_FETCHED';
export const FILTER_TEXT_SAVED = 'FILTER_TEXT_SAVED';
export const BASE_LIST_IS_FETCHING = 'BASE_LIST_IS_FETCHING';
export const BASE_COLLECTION_CHANGED = 'BASE_COLLECTION_CHANGED';
export const NEW_INVOICE_REQUESTED = 'NEW_INVOICE_REQUESTED';
export const CHANGE_BASE_ADDRESS = 'CHANGE_BASE_ADDRESS';
export const RESET_CURRENT_BASE = 'RESET_CURRENT_BASE';
export const CURRENT_BASE_IS_FETCHING = 'CURRENT_BASE_IS_FETCHING';
