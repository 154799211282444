import React from 'react'

const ListSearch = ({ onFilter }) => {
    return (
        <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-search"></i></span>
            <input className="form-control" type="text" autoFocus onChange={(e)=>onFilter(e.target.value)} />
        </div>
    )
}

export default ListSearch