import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import TextField from "./formComponents/TextField";
import logoLg from "../../assets/img/soon-logo-purple.png";

import {
  maxLength,
  minLength,
  required,
  phoneValidator
} from "./formComponents/FormValidation";
import PhoneField from "./formComponents/PhoneField";
import CpfField from "./formComponents/CpfField";
import SelectField from "./formComponents/SelectField";
import CepField from "./formComponents/CepField";
import Wizard, { Step } from "./Wizard";
import { registerCompany } from "../../actions/companyActions";
import { Link } from "react-router";

class RegisterCompany extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { index: 0 };
  }

  handleSubmit(values) {
    const { registerCompany } = this.props;
    registerCompany(values);
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      change,
      cnpj,
      companyRegisterFetching
    } = this.props;
    const { index } = this.state;
    return (
      <div className="login-wrapper gray-bg">
        <div className="ibox">
          <Link to="login">
            <span className="backArrow">
              <i className="fas fa-arrow-left"></i>
            </span>
          </Link>
          <div className="ibox-content" style={{ overflow: "auto" }}>
            <form onSubmit={handleSubmit(v => this.handleSubmit(v))}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6 col-sm-offset-3">
                    <div className="text-center">
                      <div>
                        <img
                          src={logoLg}
                          className="logo-img"
                          width={250}
                        ></img>
                      </div>
                      <h2
                        className="skin-1"
                        style={{ marginTop: "10px", marginBottom: "25px" }}
                      >
                        Cadastro de Empresas
                      </h2>

                      <div
                        className={
                          this.props.errorMessage
                            ? "alert alert-danger animated flipInX"
                            : "hidden"
                        }
                      >
                        {this.props.errorMessage}
                      </div>
                      <div
                        className={
                          this.props.successMessage
                            ? "alert alert-success animated flipInX"
                            : "hidden"
                        }
                      >
                        {this.props.successMessage}
                      </div>
                    </div>
                    <Wizard
                      contentColor="#FFF"
                      disabled={!cnpj || cnpj.length < 14}
                      index={index}
                      handleTab={index => this.setState({ index })}
                    >
                      <Step title="CNPJ">
                        <div className="col-sm- base-register-container">
                          <div className="col-sm-12">
                            <CpfField
                              type="number"
                              name={"cnpj"}
                              company
                              label={"CNPJ"}
                              validate={[required, minLength(9)]}
                              icon={"fas fa-id-card"}
                              placeholder={"99.999.999/9999-99"}
                              change={change}
                              fieldName="cnpj"
                              type={"cnpj"}
                            />
                          </div>
                        </div>
                      </Step>
                      <Step title="Informações da Empresa">
                        <div className="col-sm-12">
                          <TextField
                            name={"nomeFantasia"}
                            label={"Nome Fantasia"}
                            icon={"fas fa-id-card"}
                            placeholder={"Reboque.Me"}
                            validate={[required]}
                            maxLength={100}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"razaoSocial"}
                            label={"Razão Social"}
                            icon={"fas fa-id-card"}
                            placeholder={"Reboque Soluções"}
                            validate={[required]}
                            maxLength={100}
                          />
                        </div>
                        <div className="col-sm-12">
                          <PhoneField
                            name={"telefoneCelular"}
                            label={"Telefone Celular"}
                            validate={[required, phoneValidator]}
                            icon={"fas fa-mobile"}
                            placeholder={"(99) 9 9999-9999"}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"contato"}
                            label={"Nome do Responsável"}
                            icon={"fas fa-id-card"}
                            placeholder={"José da Silva"}
                            validate={[required]}
                            maxLength={100}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"email"}
                            label={"Email"}
                            icon={"far fa-envelope-open"}
                            placeholder={"reboqueme@reboque.me"}
                            type="email"
                            validate={[required]}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"senha"}
                            label={"Senha"}
                            icon={"fas fa-key"}
                            type="password"
                            validate={[required]}
                          />
                        </div>
                      </Step>
                      <Step title="Endereço">
                        <div className="col-sm-12">
                          <CepField
                            name={"cep"}
                            label={"CEP"}
                            icon={"fas fa-id-card"}
                            placeholder={"30.421-102"}
                            street="logradouro"
                            neighborhood="bairro"
                            city="cidade"
                            state="estado"
                            change={change}
                            validate={[required, minLength(8)]}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"logradouro"}
                            label={"Logradouro"}
                            validate={[required]}
                            icon={"fas fa-id-card"}
                            placeholder={"Avenida Paulista"}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"bairro"}
                            label={"Bairro"}
                            validate={[required]}
                            icon={"fas fa-id-card"}
                            placeholder={"Centro"}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"cidade"}
                            label={"Cidade"}
                            validate={[required]}
                            icon={"fas fa-id-card"}
                            placeholder={"São Paulo"}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"estado"}
                            label={"Estado"}
                            validate={[required, minLength(2)]}
                            icon={"fas fa-id-card"}
                            placeholder={"SP"}
                            maxLength={2}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            type="number"
                            name={"numero"}
                            label={"Numero"}
                            validate={[required]}
                            icon={"fas fa-id-card"}
                            placeholder={""}
                          />
                        </div>
                        <div className="col-sm-12">
                          <TextField
                            name={"complemento"}
                            label={"Complemento"}
                            icon={"fas fa-id-card"}
                            placeholder={"Ap 302"}
                          />
                        </div>
                      </Step>
                    </Wizard>
                    <div
                      className="control-buttons col-md-3"
                      style={{ marginRight: "46px" }}
                    >
                      {index < 2 ? (
                        <button
                          onClick={() => {
                            this.setState({
                              index: index < 2 ? index + 1 : index
                            });
                          }}
                          className="btn btn-block btn-success"
                          disabled={!cnpj || cnpj.length < 14}
                        >
                          Avançar
                        </button>
                      ) : (
                        <div>
                          {companyRegisterFetching ? (
                            <div className="sk-spinner sk-spinner-double-bounce">
                              <div className="sk-double-bounce1"></div>
                              <div className="sk-double-bounce2"></div>
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-block btn-success"
                              disabled={pristine || submitting}
                            >
                              Cadastrar
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  initialValues: {
    contato: state.base.currentBase.contato,
    nomeFantasia: state.base.currentBase.nomeFantasia,
    razaoSocial: state.base.currentBase.razaoSocial,
    telefoneCelular: state.base.currentBase.telefoneCelular,
    email: state.base.currentBase.email,
    logradouro: state.base.currentBase.endereco.logradouro,
    complemento: state.base.currentBase.endereco.complemento,
    bairro: state.base.currentBase.endereco.bairro,
    cidade: state.base.currentBase.endereco.cidade,
    numero: state.base.currentBase.endereco.numero,
    cep: state.base.currentBase.endereco.cep,
    estado: state.base.currentBase.endereco.estado
  },
  companyRegisterFetching: state.company.companyRegisterFetching,
  errorMessage: state.company.errorMessage,
  cnpj:
    state.form.registerCompany &&
    state.form.registerCompany.values &&
    state.form.registerCompany.values.cnpj
      ? state.form.registerCompany.values.cnpj.replace(/\D/g, "")
      : null
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerCompany
    },
    dispatch
  );

RegisterCompany = reduxForm({
  form: "registerCompany"
})(RegisterCompany);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCompany);
