import React, {Component} from 'react';
import Select from 'react-select';

import {connect} from 'react-redux';
import _ from 'lodash';
import AssistanceTable from '../common/AssistanceTable';
import {clearFormValues} from '../../actions/form_actions';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';

import {loadBaseList} from '../../actions/base_actions';
import {doGet} from '../../api/base/base_api_helper';
import {doGetV2} from '../../api/base/base_api_v2_helper';
import DataFilter from '../common/DataFilter';
import {filterAttendences, setAttendences} from '../../actions/attendence_actions';
import {getCompanies, CompaniesContracts} from '../../actions/relatorio_actions';

import {Link} from 'react-router';
import {onFormFieldChange} from '../../actions/form_actions';
import AsyncSelect from 'react-select/lib/Async';
import moment from 'moment';

import {colourStyles} from '../common/ReactTable';
import {getEstagios} from '../../utils/attendance_helper';
import {APP_PROFILES, USUARIO_TIPO_REBOQUEME} from '../../reducers/constants';

const statusList = [
  {value: 'STATUSATENDIMENTOABERTO', label: 'Aberto', color: '#ffc107'},
  {value: 'STATUSATENDIMENTOFECHADO', label: 'Fechado', color: '#28a745'},
  {
    value: 'STATUSATENDIMENTOPROGRAMADO',
    label: 'Adormecido',
    color: '#E74C3C',
  },
];

const origemList = [
  {
    value: 'REQUISICAOORIGEMLIGACAO',
    label: 'Ligação Telefônica',
    color: '#28a745',
  },
  {
    value: 'REQUISICAOORIGEMAPLICATIVO',
    label: 'Aplicativo',
    color: '#ffc107',
  },
  {value: 'REQUISICAOORIGEMCHAT', label: 'Chat no Site', color: '#ffc107'},
  {value: 'REQUISICAOORIGEMWHATSAPP', label: 'WhatsApp', color: '#ffc107'},
  {value: 'REQUISICAOORIGEMCHATBOT', label: 'ChatBot', color: '#ffc107'},
];

const requestTypeList = [
  {
    value: 'REQUISICAOTIPOINFORMACAO',
    label: 'Requisição de Informação',
    color: '#28a745',
  },
  {
    value: 'REQUISICAOTIPOASSISTENCIA',
    label: 'Requisição de Assistência',
    color: '#28a745',
  },
  {
    value: 'REQUISICAOTIPOAGENDAMENTO',
    label: 'Requisição de Agendamento',
    color: '#28a745',
  },
  {
    value: 'REQUISICAOTIPORECUSA',
    label: 'Requisição de inconformidade',
    color: '#28a745',
  },
  {
    value: 'REQUISICAOTIPOSUPORTE',
    label: 'Requisição de Suporte',
    color: '#28a745',
  },
];

export const ASSISTANCE_FORM_FILTERS = 'ASSISTANCE_FORM_FILTERS';

class AssistantTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: this.props.form.filtro ? this.props.form.filtro : '',
      log: '',
      status: this.props.form.status ? this.props.form.status : [statusList[0], statusList[2]],
      asaDelta: this.props.form.asaDelta ? this.props.form.asaDelta : '',
      tamanhoPlataforma: this.props.form.tamanhoPlataforma
        ? this.props.form.tamanhoPlataforma
        : '',
      origemRequisicao: this.props.form.origemRequisicao
        ? this.props.form.origemRequisicao
        : '',
      tipoRequisicao: this.props.form.tipoRequisicao ? this.props.form.tipoRequisicao : '',
      inadimplencia: this.props.form.inadimplencia ? this.props.form.inadimplencia : '',
      tipoBase: this.props.form.tipoBase ? this.props.form.tipoBase : '',
      company: this.props.form.company ? this.props.form.company : '',
      veiculoCliente: this.props.form.veiculoCliente ? this.props.form.veiculoCliente : '',
      protocolo: '',
      placa: '',
      cliente: [],
      optionsVeiculoClient: [],
      currentPage: 1,
      sizePerPage: 10,
      saveCreateAt: '',
      saveCreatTo: '',
      filtros: [],
    };

    this.handleRedirect = this.handleRedirect.bind(this);
    this.hasCreateAssistance = this.hasCreateAssistance.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  updateTableState({data, currentPage}) {
    this.setState({baseList: data, currentPage});
  }

  componentWillMount() {
    const {FREQUENCIA, GESTAO} = CompaniesContracts;
    if (this.props.login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME)
      return this.props.getAllCompanies(`${FREQUENCIA},${GESTAO}`);
  }

  handleRedirect(id) {
    const url = `/assistance/${id}/details`;
    window.open(url);
  }

  componentWillUnmount() {
    this.props.clearValues();
    this.props.clearAttendances();
  }

  formatMultipleFilter = (attribute) => {
    return this.state[attribute].map((item) => item.value).join(',');
  };

  handleSearch = (startDate, endDate) => {
    const multiples = ['status', 'origemRequisicao', 'tipoRequisicao'];
    const attributes = multiples.reduce((acum, item) => {
      if (this.state[item] !== '') {
        const value = this.formatMultipleFilter(item);
        if (value) acum['_' + item] = value;
      }
      return acum;
    }, {});
    if (this.state.estagio) attributes._estagio = this.state.estagio.value;
    if (this.state.company) attributes._empresa = this.state.company.value;
    if (this.state.veiculoCliente)
      attributes._veiculo = this.state.veiculoCliente.value.codigo;
    if (startDate)
      attributes._createdAtFrom = moment(startDate).local().format('YYYY-MM-DD 00:00');
    if (endDate) attributes._createdAtTo = moment(endDate).local().format('YYYY-MM-DD 24:00');
    if (this.state.protocolo) attributes._protocolo = this.state.protocolo;
    if (this.state.placa) attributes._placa = this.state.placa;
    if (this.state.cliente && this.state.cliente.length > 0) {
      attributes._cliente = this.state.cliente.map((c) => c.value).join(', ');
    }
    const page = this.state.currentPage;
    const limit = this.state.sizePerPage;
    this.setState({
      saveCreateAt: moment(startDate).local().format('YYYY-MM-DD 00:00'),
      saveCreatTo: moment(endDate).local().format('YYYY-MM-DD 24:00'),
      filtros: attributes,
    });

    this.props.searchAttendances(attributes, page, limit);
  };

  handlePageChange = (page, sizePerPage) => {
    const {saveCreateAt, saveCreatTo} = this.state;
    console.log(page, sizePerPage);
    this.setState({currentPage: page, sizePerPage}, () => {
      this.handleSearch(saveCreateAt, saveCreatTo);
    });
  };

  onChangeFilter = (e) => {
    this.setState({...this.state, filterText: e.target.value});
    this.props.onFieldChange(ASSISTANCE_FORM_FILTERS, 'filtro', e.target.value);
  };

  onChangeSelect = (field, value) => {
    this.setState({...this.state, [field]: value});
    this.props.onFieldChange(ASSISTANCE_FORM_FILTERS, field, value);
    this.setState({[field]: value});
  };

  buildCompaniesOptions = () => {
    return this.props.companies.map((c) => ({
      value: c.codigo,
      label: c.nomeFantasia,
      color: c.statusEmpresa.descricao === 'Ativo' ? '#5a5a5a' : '#cecece',
    }));
  };

  resolveClientOptions = (result) => {
    if (result.sucesso) {
      const customers = result.listVehicles.map((customer) => {
        const vehiclePlateOrChassi = customer.placa ? customer.placa : customer.chassis;

        return {
          value: customer,
          label: `${customer.cliente.nome} | ${vehiclePlateOrChassi}`,
        };
      });
      return customers;
    }
  };

  handleShowDetails = (cel) => {
    this.handleRedirect(cel.codigo);
  };

  getClientOptionsDebounced = _.debounce((input, resolve, reject) => {
    if (!input) return resolve(this.state.optionsVeiculoClient);

    let url = `subscriptions?_filter=${input}`;
    if (this.state.company) {
      url += `&_empresa=${this.state.company.value.trim()}`;
    }

    doGet(url)
      .then(this.resolveClientOptions)
      .then(resolve)
      .catch((err) => {
        console.error('erro:', err);
        reject(err);
      });
  }, 300);
  getClientOptions = (input) => {
    return new Promise((resolve, reject) => {
      this.getClientOptionsDebounced(input, resolve, reject);
    });
  };

  resolveClientNameOptions = (result) => {
    if (result) {
      const nomesClientes = result.map((cliente) => {
        return {
          value: cliente.code,
          label: cliente.name,
        };
      });
      return nomesClientes;
    }
  };

  getClientNameOptionsDebounced = _.debounce((input, resolve, reject) => {
    const clientName = encodeURIComponent(input.trim());
    let url;

    if (this.state.company && this.state.company.value) {
      const companyCode = this.state.company.value.trim();
      url = `attendances/company/clients?companyCode=${companyCode}&clientName=${clientName}`;
    } else {
      url = `attendances/company/clients?clientName=${clientName}`;
    }

    doGetV2(url)
      .then((result) => {
        resolve(this.resolveClientNameOptions(result));
      })
      .catch((err) => {
        console.error('erro:', err);
        reject(err);
      });
  }, 300);

  getClientNameOptions = (input) => {
    return new Promise((resolve, reject) => {
      this.getClientNameOptionsDebounced(input, resolve, reject);
    });
  };

  hasCreateAssistance() {
    const {login} = this.props;

    const tipoUsuario = login.user_info.usuario.tipoUsuario;
    const profileAtendente =
      login.profiles && login.profiles.some((p) => p.id === APP_PROFILES.Atendente);

    if (tipoUsuario === USUARIO_TIPO_REBOQUEME && profileAtendente) {
      return true;
    }
    return true;
  }

  isKovi() {
    const {login} = this.props;
    const koviId = '2200248530130441273';
    return login.user_info.empresa.codigo === koviId;
  }

  render() {
    const companiesOptions = this.buildCompaniesOptions();
    const {login, attendancesList} = this.props;
    const {filtros} = this.state;

    const tipoUsuario = login.user_info.usuario.tipoUsuario;
    const url = window.location.pathname;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Atendimentos</h5>
                {(tipoUsuario === USUARIO_TIPO_REBOQUEME || this.isKovi()) &&
                  url !== '/assistance/relatorios' && (
                    <div className="ibox-tools">
                      <Link to={'/assistance/create'}>
                        <button type="button" className="btn btn-primary btn-sm">
                          <i className="fa fa-plus" />
                        </button>
                      </Link>
                    </div>
                  )}
              </div>
              <div className="ibox-content">
                <div className="row">
                  {tipoUsuario === USUARIO_TIPO_REBOQUEME && (
                    <div className="col-md-3">
                      <label className="font-normal p-w-xs">Empresa</label>
                      <Select
                        options={companiesOptions}
                        placeholder="Todas as empresas"
                        closeMenuOnSelect={false}
                        onChange={(company) => {
                          this.onChangeSelect('company', company);
                        }}
                        value={this.state.company}
                        styles={selectCustomStyle}
                      />
                    </div>
                  )}
                  <div
                    className={
                      tipoUsuario === USUARIO_TIPO_REBOQUEME ? 'col-md-3' : 'col-md-6'
                    }>
                    <label>Protocolo</label>
                    <input
                      value={this.props.protocolo}
                      onChange={(event) => this.setState({protocolo: event.target.value})}
                      className="form-control"
                      id="protocolo"
                    />
                  </div>
                  <div className={'col-md-3'}>
                    <label>Placa/Chassi</label>
                    <input
                      value={this.props.placa}
                      onChange={(event) => this.setState({placa: event.target.value})}
                      className="form-control"
                      id="placa"
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="font-normal p-w-xs">Status</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={statusList}
                      AssistantTableContainer
                      isMulti
                      onChange={(value) => this.onChangeSelect('status', value)}
                      value={this.state.status}
                      styles={{...colourStyles, ...selectCustomStyle}}
                    />
                  </div>
                </div>
                <div className="row" style={{marginTop: '10px'}}>
                  <div className="col-md-3">
                    <label className="font-normal p-w-xs">Tipo de Requisição</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={requestTypeList}
                      value={this.state.tipoRequisicao}
                      onChange={(value) => this.onChangeSelect('tipoRequisicao', value)}
                      isMulti
                      styles={selectCustomStyle}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="font-normal p-w-xs">Origem</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={origemList}
                      isMulti
                      onChange={(value) => this.onChangeSelect('origemRequisicao', value)}
                      value={this.state.origemRequisicao}
                      styles={selectCustomStyle}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="font-normal p-w-xs">Veículo/Cliente</label>
                    <AsyncSelect
                      value={this.state.veiculoCliente}
                      loadOptions={this.getClientOptions}
                      styles={selectCustomStyle}
                      onChange={(value) => this.onChangeSelect('veiculoCliente', value)}
                      isClearable
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="font-normal p-w-xs">Estágio</label>
                    <Select
                      options={getEstagios()}
                      defaultValue={getEstagios()[0]}
                      closeMenuOnSelect={false}
                      onChange={(option) => this.onChangeSelect('estagio', option)}
                      styles={selectCustomStyle}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="font-normal p-w-xs" style={{marginTop: '5px'}}>
                      Cliente
                    </label>
                    <AsyncSelect
                      value={this.state.cliente}
                      loadOptions={this.getClientNameOptions}
                      styles={selectCustomStyle}
                      onChange={(value) => this.onChangeSelect('cliente', value)}
                      isClearable
                      isMulti
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}>
                  <div className="col-md-8">
                    <DataFilter loadData={this.handleSearch} />
                  </div>
                </div>
              </div>
              <AssistanceTable
                showDetails={this.handleShowDetails}
                assistanceList={this.props.attendancesList}
                isFetching={this.props.fetchingAttendances}
                onPageChange={this.handlePageChange}
                filtros={filtros}
                page={this.state.currentPage}
                sizePerPage={this.state.sizePerPage}
                profileId={this.props.login.user_info.usuario.tipoUsuario}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AssistantTableContainer.defaultProps = {
  form: {},
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAllCompanies: (contracts) => {
      dispatch(getCompanies(contracts));
    },
    clearValues: () => {
      dispatch(clearFormValues(ASSISTANCE_FORM_FILTERS));
    },
    clearAttendances: () => {
      dispatch(setAttendences([]));
    },
    loadBaseList: () => {
      dispatch(loadBaseList());
    },
    onFieldChange: (context, id, value) => {
      dispatch(onFormFieldChange(context, id, value));
    },
    searchAttendances: (filters, page, limit) => {
      dispatch(filterAttendences(filters, page, limit));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    attendancesList: state.attendance.attendances,
    fetchingAttendances: state.attendance.fetchingAttendances,
    companies: state.relatorio.companies,
    form: state.forms[ASSISTANCE_FORM_FILTERS],
    login: state.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssistantTableContainer);
