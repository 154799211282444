export function onSelectChange( service ){
  return{type: SELECT_FIELD_CHANGED, service}
}
export const SELECT_FIELD_CHANGED = 'SELECT_FIELD_CHANGED'

export function onPartnerChange( partner ){
  return{type: PARTNER_FIELD_CHANGED, partner}
}
export const PARTNER_FIELD_CHANGED = 'PARTNER_FIELD_CHANGED'

export function onServicesNext(next){
  return {type: "PARTNER_SERVICES_NEXT", next}
}
