import React, {Component} from 'react';
import Select from 'react-select';
import MenuList from './MenuList';

export default class VirtualizedSelect extends Component {
  render() {
    const {options, closeMenuOnSelect, onChange, value, isMulti, styles} = this.props;
    return (
      <Select
        options={options}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={onChange}
        value={value}
        isMulti={isMulti}
        components={{MenuList}}
        styles={styles}
      />
    );
  }
}
