import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

export default class PaymentMethod extends Component {
  render() {
    const { method, style } = this.props;
    if (!method) return <div></div>;
    if (method === "DINHEIRO") {
      return (
        <i
          className="fa fa-money-bill-alt"
          style={style}
          data-tip={`Pagamento: ${method}`}
        ></i>
      );
    } else if (method === "CREDITO") {
      return (
        <i
          className="fas fa-credit-card"
          style={style}
          data-tip={`Pagamento: ${method}`}
        ></i>
      );
    } else if (method === "FATURADO") {
      return (
        <i
          className="fas fa-file-invoice-dollar"
          style={style}
          data-tip={`Pagamento: ${method}`}
        ></i>
      );
    } else
      return (
        <i
          className="fas fa-file-invoice-dollar"
          style={style}
          data-tip={`Pagamento: ${method}`}
        ></i>
      );
  }
}
