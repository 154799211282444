import {
  getCustomers,
  getSingleCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerApiValues,
  editCompanyofCustomer,
  editCustomerDetails,
  editVehicleOfCustomer,
  editCoverPlan,
} from '../api/customer_helper';
import { onFormError, onFormSuccess, setFormValues } from './form_actions';
import { onOptionSelected } from './autocomplete_actions';
import { toastError, toastSuccess } from './toast_actions';

export function loadCustomerList() {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_CUSTOMER_CHANGED, value: true });
    getCustomers('', getState().login.user_info.empresa.codigo)
      .then((results) => {
        dispatch([
          { type: CUSTOMER_LIST_CHANGED, value: results.listCustomers },
          { type: FETCHING_CUSTOMER_CHANGED, value: false },
        ]);
      })
      .catch((error) => {
        console.log(
          'Exception on customer_actions.loadCustomerList(...): ' +
            error.message
        );
        dispatch({ type: FETCHING_CUSTOMER_CHANGED, value: false });
      })
      .catch((error) => {
        console.log(
          'Exception on customer_actions.loadCustomerList(...): ' +
            error.message
        );
        dispatch({ type: FETCHING_CUSTOMER_CHANGED, value: false });
      });
  };
}

export function loadVehicleCustomerList() {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_CUSTOMER_CHANGED, value: true });
    getVehicleCustomer(getState().login.user_info.empresa.codigo)
      .then((results) => {
        dispatch([
          { type: CUSTOMER_LIST_CHANGED, value: results.listCustomers },
          { type: FETCHING_CUSTOMER_CHANGED, value: false },
        ]);
      })
      .catch((error) => {
        console.log(
          'Exception on customer_actions.loadCustomerList(...): ' +
            error.message
        );
        dispatch({ type: FETCHING_CUSTOMER_CHANGED, value: false });
      })
      .catch((error) => {
        console.log(
          'Exception on customer_actions.loadCustomerList(...): ' +
            error.message
        );
        dispatch({ type: FETCHING_CUSTOMER_CHANGED, value: false });
      });
  };
}

export function setCustomerFormDetails(formContext, customerCode) {
  return (dispatch, getState) => {
    getSingleCustomer(customerCode)
      .then((result) => {
        dispatch(setFormValues(formContext, result.cliente));
        dispatch(onFormSuccess(formContext));
      })
      .catch((error) => {
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function setCustomerInputDetails(inputContext, customerCode, auth) {
  return (dispatch, getState) => {
    getSingleCustomer(customerCode, auth)
      .then((result) => {
        dispatch(onOptionSelected(inputContext, result.cliente));
      })
      .catch((error) => {
        console.log(
          'Exception on customer_actions.setCustomerInputDetails(...): ' +
            error.message
        );
      });
  };
}

export function createNewCustomer(formContext, doAfterSuccess, auth) {
  return (dispatch, getState) => {
    let customerData = getState().forms[formContext];

    createCustomer(customerData, getState().service.company.value)
      .then((result) => {
        dispatch(onFormSuccess(formContext));
        doAfterSuccess(result, auth);
      })
      .catch((error) => {
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function updateExistingCustomer(formContext, doAfterSuccess, auth) {
  return (dispatch, getState) => {
    let customerData = getState().forms[formContext];

    updateCustomer(
      getState().service.cliente.codigo,
      customerData,
      getState().service.company.value
    )
      .then((result) => {
        dispatch(onFormSuccess(formContext));
        doAfterSuccess(result);
      })
      .catch((error) => {
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function deleteExistingCustomer(customer, doAfterSuccess) {
  return (dispatch, getState) => {
    deleteCustomer(customer.codigo)
      .then((result) => {
        //dispatch ({type: CUSTOMER_INSTANCE_CHANGED, customerId: customerData.codigo, customerData: getCustomerApiValues(customerData)})
        dispatch(loadCustomerList());
        doAfterSuccess();
      })
      .catch((error) => {
        toastError(error.message || error); //dispatch( onFormError(context, error.message) )
      });
  };
}

export function updateCompanyOfCustomer(params) {
  return async (dispatch, getState) => {
    const resp = await editCompanyofCustomer(params);
    toastSuccess(resp.mensagemCliente);
  };
}

export function updateCustomerDetails(params) {
  return async (dispatch, getState) => {
    try {
      const resp = await editCustomerDetails(params);
      toastSuccess(resp.mensagemCliente);
    } catch (err) {
      toastError(err.mensagemCliente || err.mensagemRetorno);
    }
  };
}

export function updateVehicleOfCustomer(codigoCustomer, codeVehicle, params) {
  return async (dispatch, getState) => {
    try {
      const resp = await editVehicleOfCustomer(
        codigoCustomer,
        codeVehicle,
        params
      );
      toastSuccess(resp.mensagemCliente);
    } catch (err) {
      toastError(err.mensagemCliente || err.mensagemRetorno);
    }
  };
}

export function editPlanOfCustomer(params) {
  return async (dispatch, getState) => {
    try {
      const resp = await editCoverPlan(params);
      toastSuccess(resp.mensagemCliente);
    } catch (err) {
      toastError(err.mensagemCliente || err.mensagemRetorno);
    }
  };
}

export const CUSTOMER_LIST_CHANGED = 'CUSTOMER_LIST_CHANGED';
export const CUSTOMER_INSTANCE_CHANGED = 'CUSTOMER_INSTANCE_CHANGED';
export const FETCHING_CUSTOMER_CHANGED = 'FETCHING_CUSTOMER_CHANGED';
