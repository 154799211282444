import React from 'react';

import {doCloseModal} from '../common/CustomModal';

class VehicleStatusForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="row-fluid">
        <form ref="form" className="form-horizontal" onSubmit={this.onClose}>
          <h4>Situação do veículo</h4>
          <div className="form-group col-xs-6">
            <div className="">
              <span>
                <input
                  type="checkbox"
                  checked={this.props.semChaves}
                  onChange={(e) => this.props.onCheckboxChange(e.target.id, e.target.checked)}
                  id="semChaves"
                />{' '}
                <label htmlFor="semChaves"> Sem chave? </label>
              </span>
            </div>
            <div className="form-group col-xs-6">
              <span>
                <input
                  type="checkbox"
                  checked={this.props.rodasTravadas}
                  onChange={(e) => this.props.onCheckboxChange(e.target.id, e.target.checked)}
                  id="rodasTravadas"
                />{' '}
                <label htmlFor="rodasTravadas"> Rodas travadas? </label>
              </span>
            </div>
            {this.props.rodasTravadas && (
              <div className="">
                <label htmlFor="quantidadeRodasTravadas">Quantidade de rodas travadas:</label>{' '}
                <select
                  type="select"
                  value={this.props.quantidadeRodasTravadas || 1}
                  onChange={(e) => {
                    this.props.onRollerChange(e.target.id, parseInt(e.target.value));
                  }}
                  id="quantidadeRodasTravadas">
                  <option value={1} selected>
                    1
                  </option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>
            )}
          </div>

          <div className="form-group col-xs-6">
            <span>
              <input
                type="checkbox"
                checked={this.props.blindado}
                onChange={(e) => this.props.onCheckboxChange(e.target.id, e.target.checked)}
                id="blindado"
              />{' '}
              <label htmlFor="blindado"> Blindado? </label>
            </span>
          </div>
          <div className="col-xs-12" style={{height: 1}}>
            {' '}
          </div>
        </form>
      </div>
    );
  }
}

export default VehicleStatusForm;
