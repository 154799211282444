import { Link } from 'react-router'
import React from 'react'

const AccountListView = props => {
  const { openAccountModal, accountList, removeAccount } = props
  return (
    <ul className="dropdown-menu dropdown-alerts">
      {accountList.length > 0 ? accountList.map((account, index) =>
        <li key={index} style={{ padding: "10px", borderBottom: '1px solid #e5e6e7' }}>
          <div>
            <i className="fas fa-user"></i> {account.name}
            <a href="#" onClick={(e) => { e.preventDefault(); removeAccount(index) }}>
              <span className="pull-right text-muted small">
                <i className="fas fa-trash"></i>
              </span>
            </a>
          </div>
        </li>
      ) :
        <li>
          <div>
            <span className="text-muted small pull-center">Nenhuma conta extra adicionada</span>
          </div>
        </li>
      }
      <li style={{ padding: '10px' }}>
        <div style={{ textAlign: 'center' }}>
          <a href="#" onClick={(e) => { e.preventDefault(); openAccountModal() }}><span><i className="fa fa-plus"></i> Adicionar Conta</span></a>
        </div>
      </li>
    </ul>
  )
}

export default AccountListView
