import React from 'react';

import {updateDeductible} from '../../../actions/solicitations_actions';

class FranchiseEditor extends React.Component {
  constructor(props) {
    super(props);

    this.updateData = this.updateData.bind(this);
    this.state = {franchise: props.defaultValue ? 'Sim' : 'Não', isLoading: false};
  }

  async updateData() {
    this.setState({isLoading: true});
    try {
      const deductible = this.state.franchise === 'Sim' ? true : false;
      await updateDeductible({solicitationCode: this.props.row.codigo, deductible});
      this.props.onUpdate(deductible);
    } finally {
      this.setState({isLoading: false});
    }
  }

  render() {
    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}>
        <select
          value={this.state.franchise}
          onKeyDown={this.props.onKeyDown}
          disabled={this.state.isLoading}
          onChange={(ev) => {
            this.setState({franchise: ev.currentTarget.value});
          }}>
          <option key={'Sim'} value={'Sim'}>
            Sim
          </option>
          <option key={'Não'} value={'Não'}>
            Não
          </option>
        </select>
        <button
          className="btn btn-primary btn-xs textarea-save-btn"
          onClick={this.updateData}
          disabled={this.state.isLoading}>
          Salvar
        </button>
      </span>
    );
  }
}

export const createFranchiseEditor = (onUpdate, props) => (
  <FranchiseEditor onUpdate={onUpdate} {...props} />
);
