import request from 'superagent';

import {BASE_URL_V2} from '../../config';
import {toastError, toastSuccess} from '../../actions/toast_actions.js';

export function doPostV2(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .post(BASE_URL_V2 + resource_path)
      .auth(auth.username, auth.password)
      .send(body)
      .end((error, result) => {
        if (error) reject(error);
        else {
          let jsonResult = JSON.parse(result.text);
          jsonResult.sucesso
            ? resolve(jsonResult)
            : reject({
                ...jsonResult,
                message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
              });
        }
      });
  });
}

export function doGetV2(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .get(BASE_URL_V2 + resource_path)
      .auth(auth.username, auth.password)
      .query(body)
      .end((error, result) => {
        if (error) {
          reject(error);
        } else {
          let jsonResult = JSON.parse(result.text);
          resolve(jsonResult);
        }
      });
  });
}

export function doPutV2(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .put(BASE_URL_V2 + resource_path)
      .auth(auth.username, auth.password)
      .send(body)
      .end((error, result) => {
        if (error) {
          try {
            let jsonResult = JSON.parse(result.text);
            reject(jsonResult);
          } catch (catchError) {
            reject(error);
          }
        } else {
          if (!result.text || result.text === '') {
            resolve({sucesso: true});
          } else {
            let jsonResult = JSON.parse(result.text);
            jsonResult.sucesso
              ? resolve(jsonResult)
              : reject({
                  ...jsonResult,
                  message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
                });
          }
        }
      });
  });
}

export function doDeleteV2(resource_path, body) {
  const auth = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  };
  return new Promise((resolve, reject) => {
    request
      .delete(BASE_URL_V2 + resource_path)
      .auth(auth.username, auth.password)
      .send(body)
      .end((error, result) => {
        if (error) reject(error);
        if (!result.text || result.text === '') {
          resolve({sucesso: true});
        } else {
          let jsonResult = JSON.parse(result.text);
          jsonResult.sucesso
            ? resolve(jsonResult)
            : reject({
                ...jsonResult,
                message: jsonResult.mensagemCliente || jsonResult.mensagemRetorno,
              });
        }
      });
  });
}
