import { doGet, doPost, doPut, doDelete } from './base/base_api_helper';
import { BASE_URL } from '../config';
import axios from 'axios';

export function buildInvoiceNF(codeInvoice) {
  // gerar nf automática
  return axios.put(
    `${BASE_URL}invoices/${codeInvoice}/gerarNF`,
    {},
    {
      auth: {
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password'),
      },
    }
  );
}

export function cancelInvoiceNF(codeInvoice, cause) {
  return axios.put(
    `${BASE_URL}invoices/${codeInvoice}/cancelarNF`,
    {
      justificativa: cause,
    },
    {
      auth: {
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password'),
      },
    }
  );
}

export function atualizarInvoiceNF(codeInvoice, data) {
  return axios.put(`${BASE_URL}invoices/${codeInvoice}/atualizarNF`, data, {
    auth: {
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
    },
  });
}

export function uploadBilletNf(invoiceId, file) {
  let formData = new FormData();
  formData.append('image', file, file.name);
  return axios.put(BASE_URL + 'invoices/' + invoiceId + '/uploadNf', formData, {
    headers: {
      'Content-Type':
        'multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E',
    },
    auth: {
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
    },
  });
}
