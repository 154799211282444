import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';

import AddressAutoComplete from '../../../components/containers/AddressAutoComplete';
import {toastError, toastWarning} from '../../../actions/toast_actions.js';

import {getPriceAlterPrediction, putAlterDestination} from '../../../api/solicitation_helper';

import {loadFavoriteDestiantionList, onAddressChange} from '../../../actions/address_actions';
import {getFavoriteDestinations} from '../../../api/address_helper.js';

class AlterDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: null,
      newService: null,
      changeWasPressed: false,
      favoriteDestinations: [],
    };

    this.props.loadFavoriteDestinationList();
  }

  componentDidUpdate() {
    loadFavoriteDestinationList();
    this.focusAddressInput();
  }

  focusAddressInput() {
    $('#enderecoDestino').focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.service !== this.props.service) {
      this.setState({newValue: null, newService: null});
    }
    if (this.props.service !== prevProps.service && this.props.service) {
      if (this.props.service.empresa && this.props.service.empresa.codigo) {
        if (this.props.row && this.props.row.empresa && this.props.row.empresa.codigo) {
          this.loadFavoriteDestinations(this.props.row.empresa.codigo);
        }
      }
    }
  }

  _hanndeChange = async () => {
    const {service, newDestination} = this.props;

    let newService = service;

    const auth = {
      login: '',
      password: '',
    };

    if (!newDestination.logradouro || !newDestination.bairro || !newDestination.cidade) {
      toastError('Preencha as informações corretamente !!!');
    } else {
      newService.enderecoDestino = {
        logradouro: newDestination.logradouro,
        bairro: newDestination.bairro,
        cidade: newDestination.cidade,
        longitude: newDestination.longitude,
        latitude: newDestination.latitude,
        numero: newDestination.numero,
      };

      if (service.statusSolicitacao === 'Agendada') {
        newService.departureTime = moment(service.dataAgendada, 'DD/MM/YYYY hh:mm').format(
          'YYYY-MM-DD HH:mm:ss',
        );
      }

      newService.tipoServico = service.tipoServico.codigo
        ? service.tipoServico.codigo
        : service.tipoServico;

      newService['company'] = {value: service.empresa.codigo};

      if (typeof newService.atualizarPreco !== 'boolean') {
        newService.atualizarPreco = service.statusSolicitacao === 'Em Atendimento';
      }

      if (!newService.codigoSolicitacao && service.statusSolicitacao === 'Em Atendimento') {
        newService.codigoSolicitacao = service.codigo;
      }

      const newValue = await getPriceAlterPrediction(newService, auth);

      this.setState({newValue: newValue, newService: newService});
    }
  };

  _submitAlter = async () => {
    try {
      const {newValue, newService} = this.state;

      if (!newValue || !newService.enderecoDestino || !newService.codigo) {
        toastError('Preencha as informações corretamente !!!');
      } else if (newService.tipoServico !== 'REBOQUE') {
        toastError(`Não é possível alterar destino de serviço: ${newService.tipoServico}`);
      } else {
        await putAlterDestination(
          newService.enderecoDestino,
          newValue.valor,
          newService.codigo,
        );

        this.props.handleRefresh(newValue.valor);
        this.props.handleClose(this.props.context);
      }
    } catch (err) {
      toastError(err.mensagemCliente);
    }
  };
  componentDidMount() {
    this.loadFavoriteDestinations();
  }
  loadFavoriteDestinations = () => {
    const {service} = this.props;
    getFavoriteDestinations(service.empresa.codigo)
      .then((results) => {
        this.setState({
          favoriteDestinations: results.listFavoriteDestinations,
        });
      })
      .catch((error) => {
        console.log('Erro ao carregar lista de Endereços Favoritos: ' + error.message);
      });
  };

  _submitCustomized = async () => {
    const {valueActual, service, newDestination} = this.props;

    try {
      if (!newDestination || !service.codigo) {
        toastError('Preencha as informações corretamente !!!');
      } else if (service.tipoServico.codigo !== 'REBOQUE') {
        toastError(`Não é possível alterar destino de serviço: ${service.tipoServico.codigo}`);
      } else {
        const newEnderecoDestino = {
          enderecoDestino: {
            logradouro: newDestination.logradouro,
            bairro: newDestination.bairro,
            cidade: newDestination.cidade,
            longitude: newDestination.longitude,
            latitude: newDestination.latitude,
            numero: newDestination.numero,
          },
        };

        await putAlterDestination(
          newEnderecoDestino.enderecoDestino,
          valueActual,
          service.codigo,
        );

        this.props.handleRefresh(valueActual);
        this.props.handleClose(this.props.context);
      }
    } catch (err) {
      toastError(err.mensagemCliente);
    }
  };

  render() {
    const {valueActual, service} = this.props;
    return (
      <div>
        <div className="form-group">
          <div className="row">
            <div
              className={
                service.statusSolicitacao !== 'Em Atendimento' ? 'col-sm-9' : 'col-sm-12'
              }>
              <div className="input-group">
                <span className="input-group-btn">
                  <button type="button" className="btn btn-default">
                    <i className="fa fa-flag-checkered" />
                  </button>
                </span>

                <AddressAutoComplete
                  placeholder="Digite o novo endereço de Destino"
                  favoriteDestinations={this.state.favoriteDestinations}
                  id="enderecoDestino"
                  placeholder="Novo Endereço de Destino"
                />

                <span className="input-group-btn">
                  <button type="button" className="btn btn-danger">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      xmlns="http://www.w3.org/2000/svg">
                      <title>switch-x</title>
                      <path
                        d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
                        fill="#fff"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>

            {service.statusSolicitacao !== 'Em Atendimento' && (
              <div className="col-sm-2">
                <button type="submit" className="btn btn-info" onClick={this._hanndeChange}>
                  Ver Preço
                </button>
              </div>
            )}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="text-center">
            <div
              className={
                service.statusSolicitacao !== 'Em Atendimento' ? 'col-sm-6' : 'col-sm-12'
              }>
              <label>Valor Atual</label>
              <span className="relatorio-title font-bold block">
                <small>R$</small>
                {`${valueActual},00`}
              </span>
            </div>
            {service.statusSolicitacao !== 'Em Atendimento' && (
              <div className="col-sm-6">
                <label>Novo Valor</label>
                <span className="relatorio-title font-bold block">
                  <small>R$</small>
                  {this.state.newValue ? ` ${this.state.newValue.valor},00` : '--'}
                </span>
              </div>
            )}
          </div>
        </div>
        <br />
        <div className="text-center">
          {this.state.changeWasPressed ? (
            <span>
              <button
                className="btn btn-danger"
                onClick={() =>
                  this.setState({
                    changeWasPressed: false,
                  })
                }>
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                style={{marginLeft: '10px'}}
                onClick={
                  service.statusSolicitacao !== 'Em Atendimento'
                    ? this._submitAlter
                    : this._submitCustomized
                }>
                Confirmar Alteração
              </button>
            </span>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() =>
                this.setState({
                  changeWasPressed: true,
                })
              }>
              Alterar Destino
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadFavoriteDestinationList: () => {
      dispatch(loadFavoriteDestiantionList());
    },
    onAddressChange: (id, address) => dispatch([onAddressChange(id, address)]),
  };
};

const DestinationPage = connect(null, mapDispatchToProps)(AlterDestination);
export default DestinationPage;
