import "rc-slider/assets/index.css";

import React, { Component } from "react";
import Slider from "rc-slider";

const style = { width: "90%", margin: "15px 30px 30px 30px" };
const marks = {
  "-30": "-30%",
  "-20": "-20%",

  "-10": "-10%",
  "-5": "-5%",
  0: "0%",
  5: "5%",
  10: "10%",

  20: "20%",
  30: "30%",
};

class PriceSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceDiscount: 0,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({ priceDiscount: event });
  }

  render() {
    const { priceDiscount } = this.state;
    const { onAfterChange } = this.props;
    return (
      <div className="widget-text-box" style={{ padding: "0" }}>
        <div style={{ width: "100%", clear: "both" }}>
          <div style={style}>
            <Slider
              onAfterChange={onAfterChange}
              value={priceDiscount}
              step={0.1}
              onChange={(event) => this.onChange(event)}
              min={-30}
              max={30}
              marks={marks}
              included={false}
              defaultValue={0}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PriceSlider;
