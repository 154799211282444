import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getAllBalances, createBalance} from './balanceActions';
import ReactTable from './reactTableBalances';
import CustomModal, {doOpenModal} from '../common/CustomModal';
import BalanceForm from './balanceForm';
import {TableHeaderColumn} from 'react-bootstrap-table';
import {browserHistory} from 'react-router';

class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      recordCount: 0,
      balances: [],
      balancesList: [],
      filteredBalancesList: [],
    };

    this.getData = this.getData.bind(this);
    this.createBalance = this.createBalance.bind(this);
    this.search = this.search.bind(this);
    this.searchButton = this.searchButton.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toDate = this.toDate.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }
  searchButton(cell, row) {
    return (
      <button className={`btn btn-sm btn-default`} onClick={() => this.search(cell, row)}>
        <i className="fa fa-search" />
      </button>
    );
  }

  search(cell, row) {
    browserHistory.push({
      pathname: `/balance/${row.code}/`,
      state: row.desc,
    });
  }

  componentWillMount() {
    const {getAllBalances} = this.props;
    getAllBalances();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.balancesList)
      this.setState({
        balancesList: this.getData(nextProps.balancesList),
        filteredBalancesList: this.getData(nextProps.balancesList),
      });
  }

  async createBalance(createdAtFrom, createdAtTo, descricao, vencimentoInvoiceParceiro) {
    await this.props.createBalance({
      createdAtFrom: createdAtFrom.format('YYYY-MM-DD 00:00'),
      createdAtTo: createdAtTo.format('YYYY-MM-DD 23:59'),
      descricao,
      vencimentoInvoiceParceiro: vencimentoInvoiceParceiro.format('YYYY-MM-DD 23:59'),
    });
  }

  getData(balancesList) {
    if (balancesList) {
      return balancesList.map((balance) => ({
        code: balance.codigo,
        desc: balance.descricao,
        startDate: this.toDate(balance.periodoInicio.substr(0, 10)).toLocaleDateString(),
        endDate: this.toDate(balance.periodoFim.substr(0, 10)).toLocaleDateString(),
      }));
    } else reuturn([]);
  }
  toDate(date) {
    const newDate = date.split('/');
    return new Date(newDate[2], newDate[1] - 1, newDate[0]);
  }
  onSearchChange(input) {
    let {balancesList, filteredBalancesList} = this.state;
    if (input) {
      filteredBalancesList = balancesList.filter((row) => {
        return row.code.includes(input) || row.desc.includes(input);
      });
      this.setState({
        filteredBalancesList,
      });
    } else {
      this.setState({
        filteredBalancesList: balancesList,
      });
    }
  }
  clearSearch() {
    const {balancesList} = this.state;
    this.setState({
      filteredBalancesList: balancesList,
    });
  }
  render() {
    return (
      <div className="row">
        <CustomModal title="Criar Fechamento" context={'CONTEXT_BASE'}>
          <BalanceForm createBalance={this.createBalance} />
        </CustomModal>
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Fechamento</h5>
                <div className="ibox-tools">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => doOpenModal('CONTEXT_BASE')}>
                    <i className="fa fa-plus" />
                  </button>
                </div>
              </div>
              <div className="ibox-content">
                <ReactTable
                  data={this.state.filteredBalancesList}
                  onSearchChange={this.onSearchChange}
                  clearSearch={this.clearSearch}>
                  <TableHeaderColumn isKey={true} dataField={'code'}>
                    Code
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField={'desc'}>Descrição</TableHeaderColumn>
                  <TableHeaderColumn dataField={'startDate'} dataSort={true}>
                    Data inicial
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField={'endDate'} dataSort={true}>
                    Data Final
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="button" dataFormat={this.searchButton} />
                </ReactTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  balancesList: state.balances.balancesList,
  invoiceId: state.balances.invoiceId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({getAllBalances, createBalance}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
