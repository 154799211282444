/* global google */
import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, browserHistory} from 'react-router';
import {syncHistoryWithStore, routerReducer} from 'react-router-redux';
import {routerMiddleware, push} from 'react-router-redux';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import bootstrap from 'bootstrap';
import rootSaga from './sagas/rootSaga';
import multi from 'redux-multi';
import promiseMiddleware from 'redux-promise';
import DevTools from './views/DevTools';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import _ from 'lodash';
import metismenu from 'metismenu';
import routes from './config/routes';
import allReducers from './reducers/all_reducers';
import './../node_modules/animate.css/animate.min.css';
import './assets/styles/style.css';
import './assets/styles/jquery.steps.css';
import './assets/styles/toastr.min.css';
import './assets/styles/sweetalert.css';
import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-free/css/all.css';

import {checkLogin} from './actions/login_actions';
import {windowResized} from './actions/window_actions';

const sagaMiddleware = createSagaMiddleware();

export let store = null;

class MainComponent extends React.Component {
  store;
  history;
  constructor(props) {
    super(props);

    const devTools =
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    let middleware = routerMiddleware(browserHistory);
    this.store = applyMiddleware(
      multi,
      thunk,
      promiseMiddleware,
      middleware,
      sagaMiddleware,
    )(createStore)(allReducers, devTools);

    this.history = syncHistoryWithStore(browserHistory, this.store);
    this.render = this.render.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentWillMount() {
    this.updateDimensions();
    this.store.dispatch(checkLogin());
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    store = this.store;
  }

  updateDimensions() {
    this.store.dispatch(windowResized());
  }

  render() {
    sagaMiddleware.run(rootSaga);
    return (
      <Provider store={this.store}>
        <div>
          <Router history={this.history}>{routes}</Router>
        </div>
      </Provider>
    );
  }
}

ReactDOM.render(<MainComponent />, document.getElementById('root'));
