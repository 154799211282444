import React, { PureComponent } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, linearGradiente
} from 'recharts';


const CustomizedLabel = React.createClass({

    render() {
        const { x, y, fill, value, data, payload } = this.props;

        return (
            <g transform={`translate(${this.props.x - 17},${this.props.y})`}>
                <clipPath id="clipCircle">
                    <circle r="13" cx="15" cy="15" />
                </clipPath>
                <image
                    clipPath="url(#clipCircle)"
                    xlinkHref={payload.value}
                    x={0}
                    y={0}
                    height="30px"
                    textAnchor="middle"
                    fill="#666" />
            </g>)
    }
});
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
        return (
            <div className="custom-tooltip-dashboard">
                <p className=""><strong>{`${payload[0].payload.name}`}</strong></p>
                <p className=""><strong>{`Índice: ${((payload[1].value / payload[0].value)*100).toFixed(2)}%`}</strong></p>
                <p className="intro">{`Orçamentos: ${payload[0].value}`}</p>
                <p className="desc">{`Solicitações: ${payload[1].value}`}</p>
            </div>
        );
    }

    return null;
};

export default class UserPerformance extends PureComponent {

    render() {
        const { data } = this.props

        return (
            <div style={{ width: '100%', height: 200 }}>
                <ResponsiveContainer>
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5, right: 30, left: -30, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis tickLine={{stroke: '#ededed'}} axisLine={false} dataKey="link" interval={0} tick={<CustomizedLabel />} />
                        <YAxis tickLine={{ stroke: '#ededed' }} axisLine={false}/>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign='top' height={36} />
                        <Bar fillOpacity={0.5} dataKey="tenders" name="Orçamentos" nameKey="Orçamentos" fill="#25f4af" stroke="#25f4af"/>
                        <Bar fillOpacity={0.5} dataKey="solicitations" name="Solicitações" nameKey="Solicitações" fill="#b5b8cf" stroke="#b5b8cf" />

                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
