const INITIAL_STATE = {
  selectedSolicitation: {
    cliente: {},
    empresa: {},
    enderecoDestino: {
      bairro: '-',
      cidade: '-',
      logradouro: '-',
      numero: '-',
    },
    enderecoOrigem: {
      bairro: '-',
      cidade: '-',
      logradouro: '-',
      numero: '-',
    },
    respostaAceita: {
      parceiro: {},
    },
    formaPagamento: '',
    usuario: '',
    veiculoCliente: {},
    historico: [],
  },
  selectedSolicitationFetching: false,
  solicitationUpdated: false,
  solicitationUpdateFetching: false,
  companies: [],
  usuarios: [],
  parceiros: [],
  bases: [],
  log: [],
  valuesUpdated: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SELECTED_SOLICITATION_FETCHED':
      return {...state, selectedSolicitation: action.payload};
    case 'SELECTED_SOLICITATION_FETCHING':
      return {...state, selectedSolicitationFetching: action.payload};
    case 'SOLICITATION_VALUES_UPDATE_FETCHING':
      return {...state, solicitationUpdateFetching: action.payload};
    case 'SOLICITATION_VALUES_UPDATED':
      return {...state, solicitationUpdated: action.payload};
    case 'COMPANIES_FETCHED':
      return {...state, companies: action.payload};
    case 'SOLICITATION_LOG_UPDATED':
      return {...state, log: action.payload};
    case 'SOLICITATION_CLOSE_MODAL':
      return {...state, valuesUpdated: action.payload};
    case 'USUARIOS_RELATORIOS_FETCHED':
      return {...state, usuarios: action.payload};
    case 'PRESTADORES_RELATORIOS_FETCHED':
      return {...state, parceiros: action.payload};
    case 'BASE_RELATORIOS_FETCHED':
      return {...state, bases: action.payload};
    default:
      return state;
  }
}
