import React from 'react'

import {doCloseModal} from '../common/CustomModal'

import SelectHookTable from '../common/selectPartnerSolicitation'

import { doGet } from '../../api/base/base_api_helper'

class CallPartnerForm extends React.Component {
  constructor(props){
    super(props)
    this.showForm = this.showForm.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  showForm(event){
    this.props.onChange({target:{id: event.target.id, value: event.target.checked}})
  }

  onClose(event){
    event.preventDefault()
    doCloseModal(this.props.context)
  }


  render() {
    //const { allPartners, closestPartners } = this.state
    const { codigo } = this.props.solicitation
    return (
      <div className="row-fluid" style={{minHeight: "120px"}}>
        <form ref="form" className="form-horizontal" onSubmit={this.onClose}>
        <SelectHookTable       
          handleClosestPartners={this.props.handleClosestPartners}
          codigo={codigo}
          partnerLoading={this.props.partnerLoading}
          placeholder='Selecione ou pesquise um parceiro...'
          loadingPlaceholder='Buscando parceiro...'
        />
        </form>

      </div>

    )
  }
}

export default CallPartnerForm
