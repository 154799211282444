import {push} from 'react-router-redux';
// import {getBusiness, getSingleBusiness, createBusiness, updateBusiness, deleteBusiness, getBusinessApiValues, filterBusiness} from '../api/business_helper'
import {onFormError, onFormSuccess, setFormValues} from './form_actions';
import {onOptionSelected} from './autocomplete_actions';
import {toastError} from './toast_actions';

export function loadBusinessList(pageNumber) {
  return (dispatch) => {
    getBusiness(pageNumber)
      .then((results) => {
        dispatch({type: BUSINESS_LIST_CHANGED, value: results.listBusiness});
      })
      .catch((error) => {
        console.log('Exception on business_actions.loadBusinessList(...): ' + error.message);
      });
  };
}

export function filterBusinessList(filter) {
  return (dispatch) => {
    filterBusiness(filter)
      .then((results) => {
        dispatch({type: BUSINESS_LIST_CHANGED, value: results.listBusiness});
        dispatch({
          type: FILTER_BUSINESS_TEXT,
          value: filter,
        });
      })
      .catch((error) => {
        console.log('Exception on business_actions.filterBusinessList(...): ' + error.message);
      });
  };
}

// export function setBusinessFormDetails(formContext, businessCode) {

//   return (dispatch, getState) => {
//     getSingleBusiness(businessCode).then((result) =>{
//       dispatch( setFormValues(formContext, result.cliente) )
//       dispatch( onFormSuccess(formContext) )
//     }).catch((error) => {
//       dispatch( onFormError(formContext, error.message || error) )
//     })
//   }
// }

// export function setBusinessInputDetails(inputContext, businessCode) {

//   return (dispatch, getState) => {
//     getSingleBusiness(businessCode).then((result) =>{
//       dispatch( onOptionSelected(inputContext, result.cliente) )
//     }).catch((error) => {
//       console.log("Exception on business_actions.setBusinessInputDetails(...): " + error.message)
//     })
//   }
// }

// export function createNewBusiness(formContext, doAfterSuccess) {

//   return (dispatch, getState) => {
//     let businessData = getState().forms[formContext] // aqui

//     createBusiness( businessData ).then((result) =>{
//       let link = '/business'
//       dispatch(onFormSuccess(formContext))
//       doAfterSuccess( link )
//     }).catch((error) => {
//       if ( error.message === `Cannot read property 'replace' of undefined` ){
//         dispatch(onFormSuccess(formContext))
//         doAfterSuccess()
//       }
//       dispatch( onFormError(formContext, error.message || error) )
//     })
//   }
// }

// export function updateExistingBusiness(formContext, doAfterSuccess) {

//   return (dispatch, getState) => {
//     let businessData = getState().forms[formContext]
//     let link = '/business'

//     updateBusiness(businessData.codigo, businessData).then((result) =>{
//       dispatch(onFormSuccess(formContext))
//       doAfterSuccess( link )
//     }).catch((error) => {
//       if ( error.message === `Cannot read property 'replace' of undefined` ){
//         dispatch(onFormSuccess(formContext))
//         doAfterSuccess()
//       }
//       dispatch( onFormError(formContext, error.message || error) )
//     })
//   }
// }

// export function deleteExistingBusiness(business, doAfterSuccess) {

//   return (dispatch, getState) => {
//     deleteBusiness(business.codigo).then((result) =>{
//       //dispatch ({type: BUSINESS_INSTANCE_CHANGED, businessId: businessData.codigo, businessData: getBusinessApiValues(businessData)})
//       dispatch( loadBusinessList() )
//       doAfterSuccess()
//     }).catch((error) => {
//       toastError(error.message || error) //dispatch( onFormError(context, error.message) )
//     })
//   }
// }

export const BUSINESS_LIST_CHANGED = 'BUSINESS_LIST_CHANGED';
export const BUSINESS_INSTANCE_CHANGED = 'BUSINESS_INSTANCE_CHANGED';
export const FILTER_BUSINESS_TEXT = 'FILTER_BUSINESS_TEXT';
