import React, {Component} from 'react';
import Card from '../common/Card';
import FollowUpItemButtons from './FollowUpItemButtons';
import RenderList from '../common/RenderList';
import {getSolicitationResponse} from '../../api/solicitation_helper';
import FollowUpPartnerItem from './FollowUpPartnerItem';
import {ServiceTypes} from '../../config';
import FollowUpExtendTime from './FollowUpExtendTime';
import './style/followUpStyle.css';

import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import CustomModal, {ModalButton, doOpenModal} from '../common/CustomModal';
import CallPartnerForm from '../forms/callPartnerForm';

const MODAL_CALL_PARTNER = 'MODAL_CALL_PARTNER';

const placeHolder = (
  <div className="ibox-content">
    <div className="spiner-example">
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-bounce1" />
        <div className="sk-double-bounce2" />
      </div>
    </div>
  </div>
);

export default class FollowUpScheduledDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: undefined,
      extraTime: '',
      enderecoDestino: this.props.solicitation.enderecoDestino,
    };
  }

  getParceiro = () => {
    let parceiro = <span />;
    let response = this.props.response || this.props.solicitation.respostaAceita;
    if (response && response.parceiro) {
      parceiro = (
        <span>
          <div className="text-center">
            {/*<i className="fa fa-user-circle-o fa-5x"></i>
            <br/>*/}
            <br />
            {response.parceiro.nomeFantasia}({response.parceiro.contato}) /{' '}
            {response.parceiro.telefoneCelular}
            <br />
            <strong>
              Tempo de Espera: {response.tempoEspera} min
              <br />
              {/*Valor: R$ {(response.valorCorrida || 0).toFixed(2)}*/}
            </strong>
            <br />
            <br />
          </div>
        </span>
      );
    }
    return parceiro;
  };

  renderListItem = (partner, key) => {
    return (
      <FollowUpPartnerItem
        partner={partner}
        key={key}
        showNotification={this.props.solicitation.statusSolicitacao == 'Aguardando Proposta'}
        notifyPartner={this.props.notifyPartner}
        removeNotify={this.props.removeNotify}
        solicitationCode={this.props.solicitation.codigo}
        login={this.props.login}
      />
    );
  };
  onChangeExtraTime = (event) => {
    this.setState({extraTime: event.target.value});
  };

  handlePressExtraTime = () => {
    this.props.extendSolicitationTime(this.state.extraTime);
    this.setState({extraTime: ''});
  };

  onChangeValues() {
    doOpenModal('CONTEXT_EDITINFO');
  }

  onAlterDestination() {
    doOpenModal('CONTEXT_EDITINFO');
  }

  render() {
    const parceiro = this.getParceiro();
    const {
      solicitation,
      height,
      onClose,
      calledPartners,
      onFilter,
      onItemSelect,
      onSolve,
      onCancel,
      onReopen,
      onAccept,
      onSelectReason,
      isFetchingSolicitation,
    } = this.props;
    const {
      cliente,
      veiculoCliente,
      enderecoOrigem,
      statusSolicitacao,
      situacaoVeiculo,
    } = solicitation;

    const {enderecoDestino} = this.state;

    const response = this.props.response || this.props.solicitation.respostaAceita;
    const situacoes = solicitation.situacaoVeiculo
      ? Object.values(solicitation.situacaoVeiculo)
      : [];
    return (
      <div className="full-height-scroll animated fadeIn marginScheduled">
        <Card
          title={`Solicitação: ${isFetchingSolicitation === true ? '' : solicitation.codigo}`}
          contentClassName="no-padding"
          onClose={onClose}>
          <ReactPlaceholder
            type="text"
            rows={15}
            ready={!isFetchingSolicitation}
            customPlaceholder={placeHolder}
            showLoadingAnimation>
            <ul
              className="list-group "
              style={{
                height: 500 + 'px',
                overflow: 'auto',
                display: 'table-cell',
                verticalAlign: 'middle',
                marginTop: '0px',
              }}>
              <li className="list-group-item ">
                <div style={{backgroundColor: 'white'}}>
                  <div className="row">
                    <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                      <div className="row">
                        <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <address>
                              <i
                                className="fa fa-user fa-2x"
                                style={{float: 'left', marginTop: '-5px'}}
                              />
                              <p className="text-center">
                                <strong>
                                  {cliente.nome} {cliente.sobreNome}
                                </strong>
                              </p>
                              <p className="text-center">{cliente.telefoneCelular}</p>
                              <p className="text-center">{cliente.email}</p>
                            </address>
                          </div>
                        </div>
                        <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <div className="text-center" style={{lineHeight: '40px'}}>
                              <address>
                                <i
                                  className="fa fa-car fa-2x"
                                  style={{float: 'left', marginTop: '-5px'}}
                                />
                                <p>
                                  <strong>
                                    {veiculoCliente.marca} {veiculoCliente.modelo}
                                  </strong>
                                </p>
                                <p>{veiculoCliente.placa}</p>
                                <p>
                                  {veiculoCliente.cor} / {veiculoCliente.ano}
                                </p>
                              </address>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <address>
                              <i
                                className="fa fa-map-marker fa-2x"
                                style={{float: 'left', marginTop: '-5px'}}
                              />
                              <p className="text-center">
                                <strong>{'Localização'}</strong>
                              </p>
                              <p className="text-center">{`${enderecoOrigem.logradouro}`}</p>
                              <p className="text-center">{`Número: ${enderecoOrigem.numero}`}</p>
                            </address>
                          </div>
                        </div>
                        {enderecoDestino ? (
                          <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                            <div style={{width: '100%', clear: 'both'}}>
                              <div className="text-center" style={{lineHeight: '40px'}}>
                                <address>
                                  <i
                                    className="fa fa-map fa-2x"
                                    style={{float: 'left', marginTop: '-5px'}}
                                  />
                                  <p className="text-center">
                                    <strong>{'Destino'}</strong>
                                  </p>

                                  <p className="text-center">{`${enderecoDestino.logradouro}`}</p>
                                  <p className="text-center">{`Número: ${enderecoDestino.numero}`}</p>
                                </address>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-12 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <div className="text-center" style={{lineHeight: '40px'}}>
                              <address>
                                <h2 className="text-center">
                                  <strong>R$ {`${solicitation.valorCorrida},00`}</strong>
                                </h2>
                                {solicitation.observacao ? (
                                  <p className="text-center">{`${solicitation.observacao}`}</p>
                                ) : (
                                  false
                                )}
                                <br />
                                {situacoes.indexOf(true) === -1 ? (
                                  <span>Normal</span>
                                ) : (
                                  <div className="text-center">
                                    <ul id="situacaoAuto">
                                      {solicitation.situacaoVeiculo.capotado ? (
                                        <li>
                                          <img className="marginSituacao" src={capotado} />
                                          Capotado
                                        </li>
                                      ) : null}

                                      {solicitation.situacaoVeiculo.garagem ? (
                                        <li>
                                          <img className="marginSituacao" src={garagem} />
                                          <span className="marginItem"> Garagem</span>
                                        </li>
                                      ) : null}
                                      {solicitation.situacaoVeiculo.semRodas ? (
                                        <li>
                                          <img className="marginSituacao" src={semRodas} />
                                          <span className="marginItem"> Sem Rodas</span>
                                        </li>
                                      ) : null}
                                      {solicitation.situacaoVeiculo.rodasTravadas ? (
                                        <li>
                                          <img className="marginSituacao" src={rodaTravada} />
                                          <span className="marginItem"> Rodas Travadas</span>
                                        </li>
                                      ) : null}
                                      {solicitation.situacaoVeiculo.blindado ? (
                                        <li>
                                          <img className="marginSituacao" src={blindado} />
                                          <span className="marginItem"> Blindado</span>
                                        </li>
                                      ) : null}
                                    </ul>
                                  </div>
                                )}
                              </address>
                            </div>
                          </div>
                        </div>
                        {statusSolicitacao == 'Aguardando Proposta' ? (
                          <div className="row">
                            <div className="col-md-12" style={{float: 'left'}}>
                              <FollowUpExtendTime
                                {...this.props}
                                handlePress={this.handlePressExtraTime}
                                extraTime={this.state.extraTime}
                                onFieldChange={this.onChangeExtraTime}
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-12 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <div className="text-center" style={{lineHeight: '40px'}}>
                              <address>
                                {response && response.parceiro ? (
                                  <h4 className="text-center">
                                    <strong> Parceiro</strong>
                                  </h4>
                                ) : (
                                  false
                                )}
                                <FollowUpItemButtons
                                  solicitation={solicitation}
                                  onSolve={onSolve}
                                  onCancel={onCancel}
                                  onReopen={onReopen}
                                  onAccept={onAccept}
                                  onSelectReason={onSelectReason}
                                  onChangeValues={this.onChangeValues}
                                  onChangeDestination={this.onChangeValues}
                                  renderListItem={this.renderListItem}
                                />
                                {parceiro}
                              </address>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-7 col-sm-5 col-xs-12">
                      <h4>{`Guinchos Acionados: ${calledPartners.length}`}</h4>
                      <CustomModal title="Acionar Parceiro" context={MODAL_CALL_PARTNER}>
                        <CallPartnerForm
                          onChange={this.props.onFieldChange}
                          context={MODAL_CALL_PARTNER}
                          solicitation={solicitation}
                        />
                      </CustomModal>
                      <RenderList
                        hideSearch
                        msgEmpty="Nenhum guincheiro foi acionado para essa solicitação."
                        renderListItem={this.renderListItem}
                        height={'80vh'}
                        onFilter={onFilter}
                        list={calledPartners}
                      />
                    </div>
                    {/*<div className="col-md-6 col-md-7 col-sm-5 col-xs-12">
                    <ModalButton className="btn btn-block btn-primary"
                    context={MODAL_CALL_PARTNER}
                    icon="fa fa-truck"
                    text="Acionar Parceiro" />
                  </div>*/}

                    {/*MODAL*/}
                  </div>
                </div>
              </li>
            </ul>
          </ReactPlaceholder>
        </Card>
      </div>
    );
  }
}
