import {LOGIN_DONE, LOGIN_FORM_UPDATED, LOGOUT, LOGIN_ERROR, LOGIN_SUCCESS} from '../actions/login_actions'

const login_reducer = (state = {}, action) => {
  switch(action.type){
    case LOGIN_DONE:
      return {
        ...state,
        user_info: action.user_info,
        loggedUser: action.user_info.usuario.nome,
        loggedUserCompany: action.user_info.empresa ? action.user_info.empresa.nomeFantasia : 'Administrador',
        profiles: action.user_info.usuario.perfis,
        userPhoto: action.user_info.usuario.linkFotoPerfil,
        errorMessage: ''
      }
    case LOGIN_FORM_UPDATED:
      return {
        ...state,
        [action.name]: action.value,
        errorMessage: ''
      }
    case LOGIN_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        successMessage: ''
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        successMessage: action.message,
        errorMessage: ''
      }
    case LOGOUT:
      return {}
    default:
      return state
  }
}

export default  login_reducer
