import React, { Component } from 'react';
import { connect } from 'react-redux';
import { onFormFieldChange } from '../../actions/form_actions';
import { onModalClose, onModalOpen } from '../common/CustomModal'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'



import MaskedInput from '../common/MaskedInput'

import { Tabs, Tab } from 'react-bootstrap'
import TextField from '../common/formComponents/TextField';
const initialState = {
  key: 1,
  logradouro: '',
  complemento: '',
  bairro: '',
  cidade: '',
  numero: '',
  cep: '',
  pais: '',
  bankNumber: '',
  bankName: '',
  agencyNumber: '',
  agencyCheckNumber: '',
  accountNumber: '',
  accountCheckNumber: '',
  holderFullname: '',
  holderTypeDocument: '',
  holderNumberDocument: '',
  isModalOpen: false
}
class BaseFormView extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleTabs = this.handleTabs.bind(this)
    this.handleTabsNav = this.handleTabsNav.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.resetState = this.resetState.bind(this)
    this.updateState = this.updateState.bind(this)
  }

  componentWillMount() {
    onModalClose('CONTEXT_BASE', this.resetState)
    onModalOpen('CONTEXT_BASE', () => this.setState({ isModalOpen: true }))
    if (this.state.isModalOpen)
      this.updateState();
  }
  updateState() {
    this.setState({ ...this.props.endereco, ...this.props.contaBancaria })
    if (this.props.status)
      this.setState({ codigoStatus: nextProps.status === 'Ativo' ? 'BASEATIVA' : 'BASEINATIVA' })
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps.endereco, ...nextProps.contaBancaria })
    if (nextProps.status)
      this.setState({ codigoStatus: nextProps.status === 'Ativo' ? 'BASEATIVA' : 'BASEINATIVA' })
  }
  resetState() {
    const keys = Object.keys(this.state)
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {})
    this.setState({ ...stateReset, ...initialState }, () => this.props.clearForm(this.props.context))
    this.setState({ isModalOpen: false })
  }
  handleTabs(key) {

    this.setState({
      key
    })
  }

  handleTabsNav(e) {
    e.preventDefault()
    const { key } = this.state
    if (key !== 4) {
      this.setState({
        key: key + 1
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.endereco !== this.props.endereco)
      this.setState({ ...nextProps.endereco })

    if (nextProps.contaBancaria !== this.props.contaBancaria)
      this.setState({ ...nextProps.contaBancaria })

    if (nextProps.status && nextProps.status !== this.props.status) {
      this.setState({ codigoStatus: nextProps.status === 'Ativo' ? 'BASEATIVA' : 'BASEINATIVA' })
    }


  }

  componentWillUnmount() {
    this.resetState()
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    })
    this.props.onFieldChange(e)
  }


  render() {

    return (
      <form role="form">
        <div className="alert alert-danger alert-dismissable" style={{ display: (this.props.errorMessage ? "block" : "none") }}>
          {this.props.errorMessage}
        </div>
        <Tabs id="baseFormTabs" activeKey={this.state.key} onSelect={this.handleTabs}>
          <Tab eventKey={1} title="Dados Cadastrais">
            {'  '}
            
            
            <div className="form-group">
              <label>Nome Fantasia</label>
              <input className="form-control" disabled={this.props.readonlyDetails} value={this.props.nomeFantasia} type="text" onChange={this.props.onFieldChange} id="nomeFantasia" />
            </div>
            <div className="form-group">
              <label>Razão Social</label>
              <input className="form-control" disabled={this.props.readonlyNames} value={this.props.razaoSocial} onChange={this.props.onFieldChange} id="razaoSocial" />
            </div>
            <div className="row custom-tab">
              <div className="col-md-3">

              </div>
              <div className="col-md-9">
                <label>Nº Documento</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="radio-inline">
                  <label>
                    <input type='radio' value='CNPJ' id='tipoDocumento' name='CNPJ'
                      checked={this.props.tipoDocumento === 'CNPJ' || !this.props.tipoDocumento}
                      onChange={this.handleChange}
                    />
                    CNPJ</label>
                </div>
                <div className="radio-inline">
                  <label>
                    <input type='radio' value='CPF' id='tipoDocumento' name='CPF'
                      checked={this.props.tipoDocumento === 'CPF'}
                      onChange={this.handleChange}
                    />
                    CPF</label>
                </div>
              </div>
              <div className="col-md-9">
                <div className="form-group">
                  {
                    this.state.tipoDocumento === 'CNPJ'
                      ? <MaskedInput className="form-control" disabled={this.props.readonlyDetails} value={this.props.documento} onChange={this.props.onFieldChange} id="documento" mask="99.999.999/9999-99" />
                      : <MaskedInput className="form-control" disabled={this.props.readonlyDetails} value={this.props.documento} onChange={this.props.onFieldChange} id="documento" mask="999.999.999-99" />
                  }
                </div>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label>Celular: </label>
              <MaskedInput className="form-control" disabled={this.props.readonlyDetails} value={this.props.telefoneCelular} onChange={this.props.onFieldChange} id="telefoneCelular" mask="(99) 9999tt999?" formatChars={{ "9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]" }} maskChar={null} />
            </div>
            <div className="form-group col-md-6">
              <label>Fixo: </label>
              <MaskedInput className="form-control" disabled={this.props.readonlyDetails} value={this.props.telefoneFixo} onChange={this.props.onFieldChange} id="telefoneFixo" mask="(99) 9999tt999?" formatChars={{ "9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]" }} maskChar={null} />
            </div>
            <div className="form-group">
              <label>Status</label>
              <select className="form-control" id='codigoStatus' onChange={(event) => {
                this.handleChange(event)
              }} >
                <option selected={this.state.codigoStatus && this.state.codigoStatus === 'BASEATIVA' ? true : false} value='BASEATIVA'>Ativo</option>
                <option selected={this.state.codigoStatus && this.state.codigoStatus === 'BASEINATIVA' ? true : false} value='BASEINATIVA'>Inativo</option>

              </select>
            </div>
            <div className="form-group" style={{ display: (this.props.readonlyDetails ? "none" : "block") }}>
              <button className="btn btn-block btn-primary" type="submit" onClick={this.handleTabsNav}>Próximo</button>
            </div>
          </Tab>
          <Tab eventKey={2} title="Endereço">
            <div className="form-group row custom-tab">
              <div className="col-md-10">
                <label>Logradouro</label>
                <input className="form-control" disabled={this.props.readonlyNames} value={this.state.logradouro} type="text" onChange={this.handleChange} id="logradouro" />
              </div>
              <div className="col-md-2">
                <label>Número</label>
                {' '}
                <input className="form-control" disabled={this.props.readonlyDetails} value={this.state.numero} type="number" onChange={this.handleChange} id="numero" />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6">
                <label>Complemento</label>
                <input className="form-control" disabled={this.props.readonlyDetails} value={this.state.complemento} type="text" onChange={this.handleChange} id="complemento" />
              </div>
              <div className="col-md-6">
                <label>Bairro</label>
                <input className="form-control" disabled={this.props.readonlyDetails} value={this.state.bairro} type="text" onChange={this.handleChange} id="bairro" />
              </div>
            </div>

            <div className="form-group">
              <label>Cidade</label>
              <input className="form-control" disabled={this.props.readonlyDetails} value={this.state.cidade} type="text" onChange={this.handleChange} id="cidade" />
            </div>
            <div className="form-group row">
              <div className="col-md-3">
                <label>CEP</label>
                <MaskedInput className="form-control" disabled={this.props.readonlyDetails} value={this.state.cep} onChange={this.handleChange} id="cep" mask="99.999-999" />
              </div>
              <div className="col-md-3">
                <label>Pais</label>
                <input className="form-control" disabled={this.props.readonlyDetails} value={this.state.pais} type="text" onChange={this.handleChange} id="pais" />
              </div>
              <div className="col-md-6">
                <label>Estado</label>
                <input className="form-control" disabled={this.props.readonlyDetails} value={this.state.estado} type="text" onChange={this.handleChange} id="estado" maxLength={2} />
              </div>
            </div>
            <div className="form-group custom-tab" style={{ display: (this.props.readonlyDetails ? "none" : "block") }}>
              <button className="btn btn-block btn-primary" type="submit" onClick={this.handleTabsNav}>Próximo</button>
            </div>
          </Tab>
          <Tab eventKey={3} title="Dados Bancários">
            <div className="form-group row custom-tab">
              <div className="col-md-3">
                <label>Nº Banco</label>
                <input className="form-control" maxLength={3} disabled={this.props.readonlyNames} value={this.state.bankNumber} type="text" onChange={this.handleChange} id="bankNumber" />
              </div>
              <div className="col-md-9">
                <label>Nome do Banco</label>
                <input className="form-control" disabled={this.props.readonlyNames} value={this.state.bankName} type="text" onChange={this.handleChange} id="bankName" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-9">
                <label>Nº Agência</label>
                {' '}
                <input className="form-control" maxLength={4} disabled={this.props.readonlyDetails} value={this.state.agencyNumber} type="text" onChange={this.handleChange} id="agencyNumber" />
              </div>

              <div className="col-md-3">
                <label>D. Agência</label>
                <input className="form-control" maxLength={1} disabled={this.props.readonlyDetails} value={this.state.agencyCheckNumber} type="text" onChange={this.handleChange} id="agencyCheckNumber" />
              </div>
            </div>


            <div className="form-group row">
              <div className="col-md-9">
                <label>Nº da Conta</label>
                <input className="form-control" maxLength={10} disabled={this.props.readonlyDetails} value={this.state.accountNumber} type="text" onChange={this.handleChange} id="accountNumber" />
              </div>

              <div className="col-md-3">
                <label>D. Conta</label>
                <input className="form-control" maxLength={1} disabled={this.props.readonlyDetails} value={this.state.accountCheckNumber} type="text" onChange={this.handleChange} id="accountCheckNumber" />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>Nome Completo</label>
                <input className="form-control" disabled={this.props.readonlyDetails} value={this.state.holderFullname} type="text" onChange={this.handleChange} id="holderFullname" />
              </div>
            </div>
            <div className="form group row">
              <div className="col-md-3">
                <div className="radio-inline">
                  <label>
                    <input type='radio' value='CNPJ' id='holderTypeDocument' name='holderTypeDocumentCNPJ'
                      checked={this.state.holderTypeDocument === 'CNPJ'}
                      onChange={this.handleChange}
                    />
                    CNPJ</label>
                </div>
                <div className="radio-inline">
                  <label>
                    <input type='radio' value='CPF' id='holderTypeDocument' name='holderTypeDocumentCPF'
                      checked={this.state.holderTypeDocument === 'CPF'}
                      onChange={this.handleChange}
                    />
                    CPF</label>
                </div>
              </div>
              <div className="col-md-9">
                <div className="form-group">
                  {
                    this.state.holderTypeDocument === 'CNPJ'
                      ? <MaskedInput className="form-control" disabled={this.props.readonlyDetails} value={this.state.holderNumberDocument} onChange={this.handleChange} id="holderNumberDocument" mask="99.999.999/9999-99" />
                      : <MaskedInput className="form-control" disabled={this.props.readonlyDetails} value={this.state.holderNumberDocument} onChange={this.handleChange} id="holderNumberDocument" mask="999.999.999-99" />
                  }
                </div>
              </div>
            </div>

            <div className="form-group custom-tab" style={{ display: (this.props.readonlyDetails ? "none" : "block") }}>
              <button className="btn btn-block btn-primary" type="submit" onClick={this.handleTabsNav}>Próximo</button>
            </div>
          </Tab>


          <Tab eventKey={4} title="Usuário">
            {'  '}
            <div className="form-group custom-tab">
              <label>Email</label>
              <input className="form-control" disabled={this.props.readonlyDetails} value={this.props.email} type="email" onChange={this.props.onFieldChange} id="email" />
            </div>
            <div className="form-group">
              <label>Login</label>
              <input className="form-control" disabled={this.props.readonlyNames} value={this.props.login} onChange={this.props.onFieldChange} id="login" />
            </div>
            <div className="form-group" style={{ display: (!this.props.isEditing ? "block" : "none") }}>
              <label>Senha</label>
              <input className="form-control" type="password" disabled={this.props.readonlyNames} value={this.props.senha} onChange={this.props.onFieldChange} id="senha" />
            </div>

            {this.props.children}

            <div className="form-group" style={{ display: (this.props.readonlyDetails ? "none" : "block") }}>
              <button style={{ display: (!this.props.isEditing ? "block" : "none") }} className="btn btn-block btn-primary" type="submit" onClick={this.props.onSave}>Criar Novo</button>
              <button style={{ display: (this.props.isEditing ? "block" : "none") }} className="btn btn-block btn-primary" type="submit" onClick={this.props.onUpdate}>Atualizar</button>
            </div>
          </Tab>
        </Tabs>
      </form>
    )
  }
}



const mapStateToProps = state => (
  {

  })

const mapDispatchToProps = dispatch => bindActionCreators({
  
}, dispatch)

BaseFormView = reduxForm({
  enableReinitialize: true,
  form: 'registerPartner'
})(BaseFormView)

export default connect(mapStateToProps, mapDispatchToProps)(BaseFormView)