import axios from 'axios';

/* DOCS: http://vs-soon.falesempremais.com.br:8880/doc */

const API_URL = 'https://vs-soon.falesempremais.com.br/services';

export async function dialAgent(phoneNumber) {
  return axios.post(`${API_URL}/V1/dialAgent`, {
    login: window.localStorage.getItem('username'),
    extension: phoneNumber,
    queue: 'saintes',
    reason: 0,
  });
}
