import {connect} from 'react-redux';

import AutoComplete from '../common/AutoComplete';
import {MIN_CHARS_TO_CALL_SEARCH} from '../../config.js';
import {onValueChanged} from '../../actions/model_autocomplete_actions';
import {
  onOptionSelected,
  onInputCleared,
  onInternalFieldChanged,
} from '../../actions/autocomplete_actions';

const getMarcaModelo = (localState) => {
  let marca = localState && localState.selectedItem && localState.selectedItem.marca;
  let modelo = localState && localState.selectedItem && localState.selectedItem.modelo;

  if (!marca && !modelo) {
    marca = localState && localState.marca;
    modelo = localState && localState.modelo;
  }

  if (marca) return marca + ' ' + modelo;

  return '';
};

const mapStateToProps = (state, ownProps) => {
  let localState = state.autocomplete[ownProps.context];

  if (!localState) {
    localState = state.forms[ownProps.context];
  }

  return {
    filterOption: 'value',
    maxVisible: 5,
    ...ownProps,
    ...localState,
    inputValue: getMarcaModelo(localState),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onOptionSelected: (newValue) => {
      dispatch(onOptionSelected(ownProps.context, newValue, ownProps.afterOptionSelected));
    },
    onClear: () => {
      dispatch(onInputCleared(ownProps.context, ownProps.afterClear));
    },
    onChange: (event) => {
      if (event.target.value && event.target.value.length >= MIN_CHARS_TO_CALL_SEARCH) {
        dispatch(
          onValueChanged(
            ownProps.context,
            event.target.value,
            event.target.value,
            ownProps.afterValueChanged,
            ownProps.auth,
          ),
        );
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete);
