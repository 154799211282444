import { DESTINATION_FORM_CHANGED, FAVORITE_DESTINATIONS_LIST_CHANGED, ADDREESS_TYPE_CHANGED } from '../actions/address_actions'

const INITIAL_STATE = {
  favoriteDestinationsList: []
}

const address_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FAVORITE_DESTINATIONS_LIST_CHANGED:
      return {
        ...state,
        favoriteDestinationsList: action.value
      }
    case ADDREESS_TYPE_CHANGED:
      return {
        ...state,
        tipoEndereco: action.value
      }
    case DESTINATION_FORM_CHANGED:
      return {
        ...state,
        [action.field]: {
          logradouro: action.value && action.value.label,
          numero: action.value && action.value.street_number,
          bairro: action.value && action.value.area,
          cidade: action.value && action.value.city,
          latitude: action.value && action.value.location && action.value.location.lat,
          longitude: action.value && action.value.location && action.value.location.lng
        }
      }
    default:
      return state
  }
}

export default address_reducer
