import React from "react";
import ReactTooltip from "react-tooltip";

const statusColor = {
  CONCLUIDA: "deepSkyBlue",
  AGUARDANDOPROPOSTA: "darkOrange",
  EMATENDIMENTO: "green",
  AGENDADA: "purple",
  EXPIRADA: "yellow",
  CANCELADA: "red",
  INTERROMPIDA: "red",
};

export const statusCircle = (cell, row) => (
  <div
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <ReactTooltip id="flag" />
    <div
      className="statusFlag"
      data-for="flag"
      data-tip={row.status.descricao || "Indefinido"}
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "50%",
        background: statusColor[row.status.codigo] || "gray"
      }}
    />
  </div>
);
