import {TIMER_START, TIMER_STOP} from '../actions/timer_actions'

const timer_reducer = (state = {}, action) => {
  switch(action.type){
    case TIMER_START:
      return {
        ...state,
        [action.id]: action.reference
      }
    case TIMER_STOP:
      return {
        ...state,
        [action.id]: undefined
      }
    default:
      return state
  }
}

export default  timer_reducer
