import React, { Component } from "react";
import ReactDOM from "react-dom";

class SearchField extends Component {
  constructor(props) {
    super(props);
    //this.getValue = this.getValue.bind( this )
    //this.setValue = this.setValue.bind( this )
  }
  getValue() {
    return ReactDOM.findDOMNode(this).value;
  }

  // It's necessary to implement setValue
  setValue(value) {
    ReactDOM.findDOMNode(this).value = value;
  }

  render() {
    const { search, defaultValue, placeholder, clearSearch } = this.props;
    return (
      <div className="input-group">
        <input
          className="form-control"
          type="text"
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={e => search(e.target.value)}
          ref={input => (this.input = input)}
        />
        <span className="input-group-btn">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              clearSearch();
              this.input.value = "";
            }}
          >
            Limpar
          </button>
        </span>
      </div>
    );
  }
}

export default SearchField;
