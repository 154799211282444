import React, { Component } from "react";
import { putBaseHistory } from "../actions/base_actions";
import CustomModal from "../components/common/CustomModal";
import BaseTableContainer from "../components/containers/BaseTableContainer";

import { connect } from "react-redux";

import RelatoriosLog from "../components/common/RelatoriosLog";

class NewBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBaseCode: 0,
      log: "",
    };
    this.handleLog = this.handleLog.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.prePutLog = this.prePutLog.bind(this);
    this.handleBaseCode = this.handleBaseCode.bind(this);
  }

  handleBaseCode(currentBaseCode) {
    this.setState({ currentBaseCode });
  }
  prePutLog(log, visibility) {
    this.props.putLog(this.state.currentBaseCode, this.state.log, visibility);
    this.setState({ log: "" });
  }
  handleLog(e) {
    this.setState({ log: e.target.value });
  }
  handleKeyUp(e) {
    const { putLog } = this.props;
    const { log } = this.state;
    if (e.key === "Enter" && e.ctrlKey) {
      putLog(this.state.currentBaseCode, log);
      this.setState({ log: "" });
    }
  }

  render() {
    return (
      <div>
        <CustomModal title="Histórico da Base" context={"CONTEXT_BASE_HISTORY"}>
          <RelatoriosLog
            logList={this.props.logList}
            handleLog={this.handleLog}
            log={this.state.log}
            putLog={this.prePutLog}
            onKeyUp={this.handleKeyUp}
            profileId={this.props.login.user_info.usuario.tipoUsuario}
          />
        </CustomModal>
        <BaseTableContainer handleBaseCode={this.handleBaseCode} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bases: state,
    logList: state.base.baseHistory,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stopSolicitationsLoading: () => {
      dispatch({
        type: `STOP_ALL_TIMERS`,
      });
    },
    putLog: (codigo, log) => {
      dispatch(putBaseHistory(codigo, log));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBase);
