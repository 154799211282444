import React, {Component} from 'react';
import {CSVLink} from 'react-csv';
import {getAttendances, getAttendancesCsv} from '../../api/attendence_helper';
import {assistanceReportCsvHeaders} from '../../reducers/constants';
import {
  attendanceTranslationMap,
  formatDateFields,
  translateObjectKeys,
} from '../../utils/translationUtils';

class CSVDownloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvData: [],
      isCsvReady: false,
      isLoading: false,
    };
  }

  fetchCsv = async () => {
    this.setState({isLoading: true});
    const response = await getAttendances(this.props.filtros, 1, 3000);

    if (response) {
      const data = response.attendances.map((result) => {
        Object.keys(result).forEach((key) => {
          if (result[key] === null) {
            delete result[key];
          }
        });
        return {
          ...translateObjectKeys(result, attendanceTranslationMap),
          totalCount: response.totalCount,
        };
      });

      const formattedSolicitations = data.map((item) => formatDateFields(item));

      if (formattedSolicitations) {
        this.setState({
          csvData: formattedSolicitations,
          isCsvReady: true,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const {csvData, isCsvReady, isLoading} = this.state;
    return (
      <div>
        {!isCsvReady && (
          <button className="btn btn-primary" onClick={this.fetchCsv} disabled={isLoading}>
            {isLoading ? (
              <span>
                <i className="fas fa-sync fa-spin" /> Carregando...
              </span>
            ) : (
              <span>
                <i className="fas fa-file-export" />
                <span style={{marginLeft: '2px'}}>Exportar .csv</span>
              </span>
            )}
          </button>
        )}
        {isCsvReady && (
          <CSVLink
            filename={`relatorio-${Date.now()}.csv`}
            headers={assistanceReportCsvHeaders}
            data={csvData.map((data) => {
              const newData = {...data};
              if (data.dataAgendado) {
                newData.agendado = 'Sim';
              } else {
                newData.agendado = 'Não';
              }

              newData.requisicao =
                newData.requisicao &&
                newData.requisicao
                  .replace(/;/g, '')
                  .replace(/"/g, "'")
                  .replace(/(\r\n|\n|\r)/gm, '');

              if (newData.partNumber) {
                newData.partNumber = newData.partNumber
                  .replace(/;/g, '')
                  .replace(/"/g, "'")
                  .replace(/(\r\n|\n|\r)/gm, '');
              }
              return newData;
            })}
            className="btn btn-success btn-soon">
            <i className="fas fa-file-export" />
            <span style={{marginLeft: '2px'}}>Exportar .csv</span>
          </CSVLink>
        )}
      </div>
    );
  }
}
export default CSVDownloader;
