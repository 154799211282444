import { SELECT_FIELD_CHANGED } from '../actions/partner_service_action'
import { PARTNER_FIELD_CHANGED } from '../actions/partner_service_action'

const INITIAL_STATE = {
  serviceList: []
}

const partner_service_reducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case SELECT_FIELD_CHANGED:

      return {
        ...state,
        serviceList: action.service
      }
    case PARTNER_FIELD_CHANGED:
      return {
        ...state,
        partnerToSolicitation: action.partner
      }
      case 'PARTNER_SERVICES_NEXT':
        return{
          ...state,
          next: action.next
        }
/*
    case PARTNER_INSTANCE_CHANGED:
      let newList = state.partnerList.map( oldPartner => {
        return oldPartner.codigo === action.partnerId? action.partnerData : oldPartner
      });

      return {
        ...state,
        partnerList: newList
      }
*/

    default:
      return state
  }
}

export default  partner_service_reducer
