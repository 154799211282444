import React from 'react';
import PropTypes from 'prop-types';
import { ServiceTypes } from '../../../config';

function IconBadge({ color, icon, description, ...rest }) {
  return (
    <span {...rest} className={'badge badge-'}>
      <i className={icon}> </i> {description}
    </span>
  );
}

function ServicesTab({ contract: { frequencias = [] } }) {
  return (
    <div>
      <table className="table table-hovered">
        <thead>
          <tr>
            <th>Serviços</th>
            <th>Periodicidade</th>
            <th>Franquia Km</th>
            <th>Consumo por período</th>
          </tr>
        </thead>
        <tbody>
          {frequencias.map((frequencia, index) => (
            <tr key={index}>
              <td>
                {frequencia.servicos && frequencia.servicos.length > 0
                  ? frequencia.servicos.map((servico, index) => (
                      <div key={index}>
                        <IconBadge
                          style={{ margin: '3px', padding: '7px' }}
                          icon={ServiceTypes[servico.codigo].icon}
                          description={servico.codigo}
                        />
                      </div>
                    ))
                  : ''}
              </td>
              <td>{frequencia.periodicidade.descricao}</td>
              <td>{frequencia.franquiaKm || 'Ilimitada'}</td>
              <td>{frequencia.usoPorPeriodo || 'Ilimitado'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ServicesTab.propTypes = {
  contract: PropTypes.shape({
    frequencias: PropTypes.array.isRequired,
  }),
};

export default ServicesTab;
