import React from 'react';

import {CSVLink} from 'react-csv';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import PropTypes from 'prop-types';

import CSVDownloader from './CsvExportAttendance';
import {hasCompanyProfile, hasReboqueMeProfile} from '../../utils/check_permission';
import {assistanceReportCsvHeaders} from '../../reducers/constants';

function AssistanceTable(props) {
  function setTableLoader() {
    if (props.isFetching === false) {
      return `Nenhum registro encontrado`;
    } else {
      return (
        <div className="spiner-example">
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1" />
            <div className="sk-double-bounce2" />
          </div>
        </div>
      );
    }
  }

  const options = {
    noDataText: setTableLoader(),
    page: props.page,
    sizePerPageList: [10, 25, 30, 50],
    sizePerPage: props.sizePerPage,
    onPageChange: props.onPageChange,
  };
  const totalSize =
    props.assistanceList && props.assistanceList.length > 0
      ? props.assistanceList[0].totalCount
      : 0;
  const origemRequisicaoColumn = (row, cel) => {
    if (!row) return;

    return <div>{row.descricao}</div>;
  };

  const veiculoColumn = (row, cel) => {
    if (!row) return;

    return (
      <div>
        {row.placa} : {row.cliente.nome}
      </div>
    );
  };

  const estagioColumn = (row) => {
    if (!row) {
      return;
    }

    return (
      <h2>
        <span className="badge badge-primary">{row.descricao}</span>
      </h2>
    );
  };

  const statusColumn = (row, cel) => {
    const colors = {
      STATUSATENDIMENTOABERTO: 'badge-warning',
      STATUSATENDIMENTOFECHADO: 'badge-primary',
      STATUSATENDIMENTOPROGRAMADO: 'badge-danger',
    };

    const color = colors.hasOwnProperty(row.codigo) ? colors[row.codigo] : 'badge-default';

    let description = row.descricao.split(' ')[1];
    description += cel.dataParaAcordar ? ` até ${cel.dataParaAcordar}` : '';

    return (
      <h2>
        <span className={`badge ${color}`}>{description}</span>
        {cel.dataAgendado && (
          <h2>
            <span
              className={`badge badge-warning`}>{`Agendado para ${cel.dataAgendado}`}</span>
          </h2>
        )}
      </h2>
    );
  };

  const tipoRequisicaoColumn = (row, cel) => {
    return <div>{row.descricao}</div>;
  };

  const tipoMotivoColumn = (row, cel) => {
    if (!row || !row.codigo || !row.codigo.includes('ASSISTENCIA') || !row.descricao) {
      return <div> - </div>;
    }
    let motivoDescription = row.descricao;

    return (
      <div
        data-toggle="tooltip"
        data-placement="top"
        title={motivoDescription}
        style={{
          textOverflow: ' ellipsis',
          whiteSpace: ' nowrap',
          overflow: ' hidden',
        }}>
        {motivoDescription}
      </div>
    );
  };

  const usuarioResponsavelColumn = (row, cel) => {
    if (row) {
      return <div>{row.nome}</div>;
    }
  };

  const actionColumn = (row, cel) => {
    return (
      <div>
        <button
          className="btn btn-sm btn-primary"
          style={{marginRight: '5px'}}
          onClick={() => props.showDetails(cel)}>
          <i className="fa fa-list-alt" />
        </button>
      </div>
    );
  };

  return (
    <div className="ibox-content">
      <BootstrapTable
        data={props.assistanceList}
        pagination
        hover
        fetchInfo={{dataTotalSize: totalSize}}
        condensed
        remote
        options={options}
        striped
        bordered={false}>
        <TableHeaderColumn width="150" dataField="dataCadastro">
          Data de criação
        </TableHeaderColumn>
        <TableHeaderColumn dataFormat={veiculoColumn} width="150" isKey dataField="veiculo">
          Veículo/cliente
        </TableHeaderColumn>
        <TableHeaderColumn
          dataFormat={tipoRequisicaoColumn}
          width="170"
          dataField="tipoRequisicao">
          Tipo
        </TableHeaderColumn>
        <TableHeaderColumn dataFormat={tipoMotivoColumn} width="250" dataField="tipoMotivo">
          Motivo
        </TableHeaderColumn>
        {/*  <TableHeaderColumn
          dataFormat={origemRequisicaoColumn}
          dataField="origemRequisicao"
        >
          Origem
        </TableHeaderColumn> */}
        <TableHeaderColumn
          width="160"
          dataFormat={usuarioResponsavelColumn}
          dataField="usuarioResponsavel">
          Responsável
        </TableHeaderColumn>
        <TableHeaderColumn width="220" dataFormat={statusColumn} dataField={'status'}>
          Status
        </TableHeaderColumn>
        <TableHeaderColumn width="120" dataFormat={estagioColumn} dataField="estagio">
          Estágio
        </TableHeaderColumn>
        <TableHeaderColumn width="50" dataFormat={actionColumn} />
      </BootstrapTable>
      {props.assistanceList.length > 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span className="ordersTotal">
            Total: <strong>{props.assistanceList[0].totalCount}</strong>
          </span>
          {(hasReboqueMeProfile() || hasCompanyProfile()) && (
            <div>
              <CSVDownloader filtros={props.filtros} />
            </div>
          )}
          {/*      <div>
            {(hasReboqueMeProfile() || hasCompanyProfile()) && (
              <CSVLink
                filename={`relatorio-${Date.now()}.csv`}
                headers={assistanceReportCsvHeaders}
                data={props.assistanceList.map((data) => {
                  const newData = {...data};
                  if (data.dataAgendado) {
                    newData.agendado = 'Sim';
                  } else {
                    newData.agendado = 'Não';
                  }

                  newData.requisicao =
                    newData.requisicao &&
                    newData.requisicao
                      .replace(/;/g, '')
                      .replace(/"/g, "'")
                      .replace(/(\r\n|\n|\r)/gm, '');

                  if (newData.partNumber) {
                    newData.partNumber = newData.partNumber
                      .replace(/;/g, '')
                      .replace(/"/g, "'")
                      .replace(/(\r\n|\n|\r)/gm, '');
                  }
                  return newData;
                })}
                className="btn btn-primary">
                <i className="fas fa-file-export" />
                <span style={{marginLeft: '2px'}}>Exportar .csv</span>
              </CSVLink>
            )}
          </div>     */}
        </div>
      ) : null}
    </div>
  );
}

AssistanceTable.propTypes = {
  assistanceList: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showDetails: PropTypes.func.isRequired,
};

export default AssistanceTable;
