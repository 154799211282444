import React, {Component} from 'react';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {USUARIO_TIPO_REBOQUEME} from '../../reducers/constants';
import NewAttendanceSolicitation from '../../components/common/assistance/newAttendanceSolicitation';
import AssistanceSolicitationTable from '../../components/common/assistance/AssistanceSolicitationTable';
import {getFavoriteAttendanceDestinations} from '../../api/attendence_helper';
import {
  setNewAttendanceSolicitationVisibility,
  findAttendanceSolicitation,
} from '../../actions/attendence_actions';
import {FAVORITE_DESTINATIONS_LIST_CHANGED} from '../../actions/address_actions';
import {loadFavoriteDestiantionList} from '../../actions/address_actions';

const codigoDAF = '2322661870558778503';
class SolicitationAssistanceTab extends Component {
  constructor(props) {
    super(props);

    this.addShowInfo = this.addShowInfo.bind(this);
    this.getFavoriteAddress = this.getFavoriteAddress.bind(this);
  }

  componentDidMount() {
    this.props.loadFavoriteDestiantionList();
  }

  componentWillMount() {
    const {codeAssistance, findSolicitation} = this.props;
    findSolicitation(codeAssistance);
  }

  async getFavoriteAddress(codigoEmpresa) {
    const response = await getFavoriteAttendanceDestinations(codigoEmpresa);
    let favoriteDestinations = [];

    if (response.listFavoriteDestinations && response.listFavoriteDestinations.length > 0) {
      favoriteDestinations = response.listFavoriteDestinations;
    }

    this.props.setFavoriteAddress(favoriteDestinations);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.attendance !== this.props.attendance) {
      const {empresa: {codigo = ''} = {}} = nextProps.attendance;

      if (codigo === codigoDAF) {
        this.getFavoriteAddress(codigo);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearFavoriteAddress();
  }

  addShowInfo(e) {
    const {
      showCreateSolicitation,
      setSolicitationVisibilty,
      loadFavoriteDestiantionList,
      attendance,
    } = this.props;
    loadFavoriteDestiantionList(attendance.empresa.codigo);

    setSolicitationVisibilty(!showCreateSolicitation);
  }

  render() {
    const {showCreateSolicitation} = this.props;
    return (
      <div className="ibox-content">
        <div>
          <div className="col-md-10" />
          <div
            className="col-md-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 0,
            }}>
            <button onClick={this.addShowInfo} className="btn btn-primary">
              Adicionar
            </button>
          </div>
        </div>
        <hr />
        <NewAttendanceSolicitation
          codigoAtendimento={this.props.codeAssistance}
          showInfo={showCreateSolicitation}
        />
        <AssistanceSolicitationTable showInfo={!showCreateSolicitation} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showCreateSolicitation: state.attendance.showCreateSolicitation,
    attendance: state.attendance.attendanceTab,
    profileId: state.login.user_info.usuario.tipoUsuario,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSolicitationVisibilty: (visible) => {
      dispatch(setNewAttendanceSolicitationVisibility(visible));
    },
    findSolicitation: (id) => {
      dispatch(findAttendanceSolicitation(id));
    },
    clearFavoriteAddress: () => {
      dispatch({type: FAVORITE_DESTINATIONS_LIST_CHANGED, value: []});
    },
    setFavoriteAddress: (addressList) => {
      dispatch({
        type: FAVORITE_DESTINATIONS_LIST_CHANGED,
        value: addressList,
      });
    },
    loadFavoriteDestiantionList: (companyId) => {
      dispatch(loadFavoriteDestiantionList(companyId));
    },
  };
};

SolicitationAssistanceTab.propTypes = {
  codeAssistance: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SolicitationAssistanceTab);
