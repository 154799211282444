import React, {Component} from 'react';

import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import {hasOnlyCompanyMonitoringProfile} from '../../utils/check_permission';
import './style/followUpStyle.css';
import Toggle from 'react-toggle-button';
import Card from '../common/Card';
import FollowUpItemButtons from './FollowUpItemButtons';
import RenderList from '../common/RenderList';
import {
  getSolicitationResponse,
  acceptSolicitation,
  stopPartner,
} from '../../api/solicitation_helper';
import FollowUpPartnerItem from './FollowUpPartnerItem';
import {ServiceTypes} from '../../config';
import FollowUpExtendTime from './FollowUpExtendTime';

import CustomModal, {ModalButton, doOpenModal} from '../common/CustomModal';
import CallPartnerForm from '../forms/callPartnerForm';
import ReactTooltip from 'react-tooltip';
import {dialAgent} from '../../api/fale-sempre-mais/index';

import PaymentMethod from '../common/paymentMethod';
import {toastWarning, toastError, toastSuccess} from '../../actions/toast_actions';
import {APP_PROFILES, USUARIO_TIPO_BASE} from '../../reducers/constants';
import {
  hasAssistanceAccess,
  hasBaseProfile,
  hasProfiles,
  hasReboqueMeProfile,
  hasTegmaProfile,
  hasCompanyProfile,
  hasMAPFREProfile,
  hasLocalizaProfile,
  hasKoviProfile,
  hasKavakProfile,
} from '../../utils/check_permission';
import ClosestPartnersModal from './closestPartners/closestPartnersModal';
import CopyToClipboard from '../common/copyToClipboard/CopyToClipboard';
import DialAgent from '../common/dialAgent/DialAgent';
import {Link} from 'react-router';

const MODAL_CALL_PARTNER = 'MODAL_CALL_PARTNER';

const placeHolder = (
  <div className="ibox-content">
    <div className="spiner-example">
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-bounce1" />
        <div className="sk-double-bounce2" />
      </div>
    </div>
  </div>
);

const simplePlaceHolder = (
  <div class="sk-spinner sk-spinner-double-bounce">
    <div class="sk-double-bounce1" />
    <div class="sk-double-bounce2" />
  </div>
);

export default class FollowUpPendingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: undefined,
      extraTime: '',
      activeMap: false,
      enderecoDestino: this.props.solicitation.enderecoDestino,
      acionamentoManual: !this.props.solicitation.acionamentoManual,
      loginFiltered: false,
      hasPermission: false,
    };
  }

  applyLoginFilter = () => {
    this.setState({loginFiltered: true});
  };

  removeLoginFilter = () => {
    this.setState({loginFiltered: false});
  };

  /*
  handleStopPartner = async () => {
    if (!this.state.acionamentoManual) return;

    try {
      const response = await stopPartner(this.props.solicitation.codigo);

      if (response.codigoRetorno === 400) {
        toastError(response.data.mensagemCliente);
      } else {
        toastSuccess(response.mensagemCliente);
        this.setState({
          acionamentoManual: false,
        });
      }
    } catch (err) {
      toastError(err.mensagemCliente);
    }
  };
 */
  componentDidMount() {
    const permission = hasOnlyCompanyMonitoringProfile();
    this.setState({hasPermission: permission});
  }

  canViewDrivenWinches = () => {
    let canView = false;

    if (hasReboqueMeProfile() || hasBaseProfile()) {
      canView = true;
    }

    return canView;
  };

  getParceiro = () => {
    let parceiro = <span />;
    let response = this.props.response || this.props.solicitation.respostaAceita;
    if (response && response.parceiro) {
      parceiro = (
        <div className="text-center" style={{marginTop: '130px'}}>
          <h4 style={{marginBottom: '-10px'}}>Parceiro</h4>
          <br />
          {response.parceiro.nomeFantasia}({response.parceiro.contato}) /{' '}
          {response.parceiro.telefoneCelular}
          <br />
          <strong>
            Tempo de Espera: {response.tempoEspera} min
            <br />
            Valor: R$ {(response.valorCorrida || 0).toFixed(2)}
          </strong>
          <br />
          <br />
        </div>
      );
    }
    return parceiro;
  };

  renderListItem = (partner, key) => {
    return (
      <FollowUpPartnerItem
        partner={partner}
        key={key}
        showNotification={this.props.solicitation.statusSolicitacao == 'Aguardando Proposta'}
        notifyPartner={this.props.notifyPartner}
        handleAcceptSolicitation={this.props.acceptSolicitation}
        removeNotify={this.props.removeNotify}
        solicitationCode={this.props.solicitation.codigo}
        openWpp={this.props.openWpp}
        login={this.props.login}
      />
    );
  };
  handlePressExtraTime = (value) => {
    this.setState({extraTime: value}, () => {
      this.props.extendSolicitationTime(this.state.extraTime);
    });
  };

  handleExpireSolicitation = () => {
    this.props.expireSolicitation();
  };

  render() {
    const parceiro = this.getParceiro();
    const {
      openModal,
      solicitation,
      height,
      onClose,
      calledPartners,
      onFilter,
      onItemSelect,
      onResponsavelUpdate,
      onResponsavelRemove,
      onSolve,
      onCancel,
      onCancelCustomized,
      onReopen,
      onAccept,
      onSelectReason,
      isFetchingCalledPartners,
      isFetchingSolicitation,
      acceptNotification,
      login,
      startScheduledSolicitation,
      acionamentoManual,
    } = this.props;
    const {cliente, veiculoCliente, enderecoOrigem, statusSolicitacao, empresa} = solicitation;

    const {enderecoDestino} = this.state;

    const response = this.props.response || this.props.solicitation.respostaAceita;
    this.rideToolTip = `Reboque.me: R$${parseFloat(solicitation.comissaoReboqueme).toFixed(2)}
    <br> Parceiro: R$${parseFloat(solicitation.valorParceiro).toFixed(2)} `;

    const paymentInfo = solicitation.formaPagamento ? (
      <PaymentMethod
        method={solicitation.formaPagamento.codigo}
        style={{marginRight: '30px'}}
      />
    ) : null;

    const situacoes = solicitation.situacaoVeiculo
      ? Object.values(solicitation.situacaoVeiculo)
      : [];

    const canViewDrivenWinches = this.canViewDrivenWinches();

    return (
      <div className="full-height-scroll animated fadeIn">
        <Card
          title={`Solicitação: ${
            isFetchingSolicitation === true ? '' : solicitation.codigo
          } - Protocolo: ${isFetchingSolicitation === true ? '' : solicitation.ownId || ''}`}
          contentClassName="no-padding"
          onClose={onClose}>
          <ReactPlaceholder
            type="text"
            rows={15}
            ready={!isFetchingSolicitation}
            customPlaceholder={placeHolder}
            showLoadingAnimation>
            <ul
              className="list-group"
              style={{
                overflow: 'auto',
                display: 'table-cell',
                verticalAlign: 'middle',
              }}>
              <li className="list-group-item">
                <div style={{backgroundColor: 'white'}}>
                  <div className="row">
                    <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                      <div className="row">
                        <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                          <span>
                            {solicitation.atendimento ? (
                              <div style={{marginBottom: '6px'}}>
                                <strong>Contato Solicitação:</strong>
                              </div>
                            ) : null}
                            <div style={{width: '100%', clear: 'both'}}>
                              <address style={{paddingTop: '10px'}}>
                                <i
                                  className="fa fa-user fa-2x"
                                  style={{float: 'left', marginTop: '-5px'}}
                                />
                                <p className="text-center">
                                  <strong>
                                    {cliente.nome} {cliente.sobreNome}
                                  </strong>
                                </p>
                                {hasReboqueMeProfile() && (
                                  <p className="text-center">
                                    <DialAgent phoneNumber={cliente.telefoneCelular} />
                                    <CopyToClipboard
                                      style={{marginLeft: 5}}
                                      textToBeCopied={cliente.telefoneCelular}
                                      title={cliente.telefoneCelular}
                                    />
                                  </p>
                                )}
                              </address>
                            </div>
                          </span>
                        </div>
                        {solicitation.atendimento ? (
                          <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                            <span>
                              <div style={{marginBottom: '6px'}}>
                                <strong>Contato Atendimento:</strong>
                              </div>
                              <div style={{width: '100%', clear: 'both'}}>
                                <address style={{paddingTop: '10px'}}>
                                  <i
                                    className="fa fa-user fa-2x"
                                    style={{float: 'left', marginTop: '-5px'}}
                                  />
                                  <p className="text-center">
                                    <strong>{solicitation.atendimento.contato}</strong>
                                  </p>
                                  {hasReboqueMeProfile && (
                                    <p className="text-center">
                                      <DialAgent
                                        phoneNumber={solicitation.atendimento.telefone}
                                      />
                                      <CopyToClipboard
                                        style={{marginLeft: 5}}
                                        textToBeCopied={solicitation.atendimento.telefone}
                                        title={solicitation.atendimento.telefone}
                                      />
                                    </p>
                                  )}
                                </address>
                              </div>
                            </span>
                          </div>
                        ) : null}
                        {solicitation.telefoneMotorista ? (
                          <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                            <span>
                              <div style={{marginBottom: '6px'}}>
                                <strong>Contato Motorista:</strong>
                              </div>
                              <div style={{width: '100%', clear: 'both'}}>
                                <address style={{paddingTop: '10px'}}>
                                  <i
                                    className="fa fa-user fa-2x"
                                    style={{float: 'left', marginTop: '-5px'}}
                                  />
                                  <p className="text-center">
                                    <strong>{solicitation.telefoneMotorista}</strong>
                                  </p>
                                  {hasReboqueMeProfile() && (
                                    <p className="text-center">
                                      <DialAgent
                                        phoneNumber={solicitation.telefoneMotorista}
                                      />
                                      <CopyToClipboard
                                        style={{marginLeft: 5}}
                                        textToBeCopied={solicitation.telefoneMotorista}
                                        title={solicitation.telefoneMotorista}
                                      />
                                    </p>
                                  )}
                                </address>
                              </div>
                            </span>
                          </div>
                        ) : null}
                        <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <address style={{paddingTop: '10px'}}>
                              <i
                                className="fa fa-building fa-2x"
                                style={{float: 'left', marginTop: '-5px'}}
                              />
                              <p className="text-center">
                                <strong>{empresa ? empresa.nomeFantasia : null} </strong>
                              </p>
                              <p className="text-center">{solicitation.usuario}</p>
                            </address>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <address style={{paddingTop: '10px'}}>
                              <i
                                className="fa fa-map-marker fa-2x"
                                style={{float: 'left', marginTop: '-5px'}}
                              />
                              <p className="text-center">
                                <strong>{'Localização'}</strong>
                              </p>
                              <p className="text-center">{`${enderecoOrigem.logradouro}`}</p>
                              {enderecoOrigem.numero ? (
                                <p className="text-center">{`Número: ${enderecoOrigem.numero}`}</p>
                              ) : null}
                            </address>
                          </div>
                        </div>
                        {enderecoDestino ? (
                          <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                            <div style={{width: '100%', clear: 'both'}}>
                              <div className="text-center" style={{lineHeight: '40px'}}>
                                <address style={{paddingTop: '10px'}}>
                                  <i
                                    className="fa fa-map fa-2x"
                                    style={{float: 'left', marginTop: '-5px'}}
                                  />
                                  <p className="text-center">
                                    <strong>{'Destino'}</strong>
                                  </p>
                                  <p className="text-center">
                                    {`${enderecoDestino.logradouro}`}
                                  </p>
                                  {enderecoDestino.numero ? (
                                    <p className="text-center">{`Número: ${enderecoDestino.numero}`}</p>
                                  ) : null}
                                </address>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-12 col-md-5 col-sm-7 col-xs-12">
                          <div style={{width: '100%', clear: 'both'}}>
                            <div className="text-center" style={{lineHeight: '40px'}}>
                              <address style={{paddingTop: '10px'}}>
                                {hasReboqueMeProfile() && <ReactTooltip multiline={true} />}
                                <h2 role="button" className="text-center">
                                  {paymentInfo}
                                  {!hasBaseProfile() ? (
                                    <strong data-tip={this.rideToolTip}>
                                      R${' '}
                                      {`${solicitation.valorCorrida
                                        .toFixed(2)
                                        .replace('.', ',')}`}
                                    </strong>
                                  ) : solicitation.formaPagamento.codigo === 'DINHEIRO' ? (
                                    <strong data-tip={this.rideToolTip}>
                                      R${' '}
                                      {`${solicitation.valorCorrida
                                        .toFixed(2)
                                        .replace('.', ',')}`}
                                    </strong>
                                  ) : (
                                    <strong data-tip={this.rideToolTip}>
                                      R${' '}
                                      {`${solicitation.valorParceiro
                                        .toFixed(2)
                                        .replace('.', ',')}`}
                                    </strong>
                                  )}
                                  <i
                                    className="fa fa-question-circle"
                                    data-tip={this.rideToolTip}
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: '15px',
                                      verticalAlign: 'top',
                                    }}
                                  />
                                </h2>

                                {solicitation.observacao ? (
                                  <p className="text-center">{`${solicitation.observacao}`}</p>
                                ) : (
                                  false
                                )}

                                {situacoes.indexOf(true) === -1 ? null : (
                                  <div className="marginIconSituacao">
                                    <i
                                      className="fa fa-car fa-2x"
                                      style={{
                                        float: 'left',
                                        marginTop: '-5px',
                                        marginRight: '7px',
                                      }}
                                    />
                                    <span
                                      style={{
                                        float: 'left',
                                        marginLeft: '7px',
                                      }}>
                                      <strong>Situação do veículo</strong>
                                    </span>

                                    <br />

                                    <table
                                      style={{
                                        float: 'left',
                                        marginTop: '18px',
                                      }}>
                                      <tr className="marginSituacao">
                                        {solicitation.situacaoVeiculo.capotado ? (
                                          <th>&#8226; Capotado</th>
                                        ) : null}

                                        {solicitation.situacaoVeiculo.garagem ? (
                                          <th>&#8226; Garagem</th>
                                        ) : null}

                                        {solicitation.situacaoVeiculo.semRodas ? (
                                          <th>&#8226; Sem Rodas</th>
                                        ) : null}

                                        {solicitation.situacaoVeiculo.rodasTravadas ? (
                                          <th>
                                            &#8226;{' '}
                                            {solicitation.situacaoVeiculo
                                              .quantidadeRodasTravadas ? (
                                              <span>
                                                {
                                                  solicitation.situacaoVeiculo
                                                    .quantidadeRodasTravadas
                                                }{' '}
                                                Rodas Travadas
                                              </span>
                                            ) : (
                                              <span>Rodas Travadas</span>
                                            )}
                                          </th>
                                        ) : null}

                                        {solicitation.situacaoVeiculo.blindado ? (
                                          <th>&#8226; Blindado</th>
                                        ) : null}
                                      </tr>
                                    </table>
                                  </div>
                                )}
                              </address>
                            </div>
                          </div>
                        </div>

                        {hasCompanyProfile() &&
                          !hasMAPFREProfile() &&
                          !hasKoviProfile() &&
                          !hasKavakProfile() &&
                          !hasLocalizaProfile() && (
                            <div className="col-md-12 col-md-12">
                              <button
                                className="btn btn-block btn-primary"
                                onClick={() => {
                                  openModal('CONTEXT_FOLLOWUP');
                                }}>
                                Histórico
                              </button>
                            </div>
                          )}

                        {hasKavakProfile() && statusSolicitacao === 'Agendada' && (
                          <div className="col-md-12 col-md-12">
                            <button
                              className="btn btn-block btn-primary"
                              onClick={() => {
                                this.handlePressExtraTime(30, solicitation.code);
                              }}>
                              Extender Data de Expiração
                            </button>
                          </div>
                        )}

                        {statusSolicitacao === 'Aguardando Proposta' && hasCompanyProfile() ? (
                          <FollowUpExtendTime
                            {...this.props}
                            handlePress={this.handlePressExtraTime}
                            expireSolicitation={this.handleExpireSolicitation}
                            extraTime={this.state.extraTime}
                            onFieldChange={this.onChangeExtraTime}
                          />
                        ) : null}

                        {(hasReboqueMeProfile() || hasTegmaProfile()) && (
                          <div>
                            {parceiro}

                            {statusSolicitacao === 'Aguardando Proposta' ? (
                              <div className="col-md-12" style={{marginTop: 20 + 'px'}}>
                                {hasTegmaProfile() && (
                                  <>
                                    <div className="col-md-12 col-md-12 center-button">
                                      <ModalButton
                                        className="btn btn-block btn-primary"
                                        extraClick={() => {
                                          this.props.handleClosestPartners(
                                            this.props.solicitation.codigo,
                                          );
                                        }}
                                        context={'CLOSEST_PARTNERS_MODAL'}
                                        icon="fa fa-truck"
                                        text="Acionar Parceiro"
                                      />
                                    </div>

                                    {!solicitation.usuarioResponsavelAcionamento &&
                                      hasProfiles([
                                        APP_PROFILES.ManagerAcionamento,
                                        APP_PROFILES.Acionamento,
                                      ]) && (
                                        <div className="col-md-12 col-md-12 center-button">
                                          <button
                                            className="btn btn-block btn-primary"
                                            onClick={() => onResponsavelUpdate()}>
                                            Pegar Acionamento
                                          </button>
                                        </div>
                                      )}

                                    {solicitation.usuarioResponsavelAcionamento &&
                                      hasProfiles([APP_PROFILES.ManagerAcionamento]) && (
                                        <div className="col-md-12 col-md-12 center-button">
                                          <button
                                            className="btn btn-block btn-warning"
                                            onClick={() => onResponsavelRemove()}>
                                            Remover Responsável
                                          </button>
                                        </div>
                                      )}

                                    {solicitation.codigoAtendimento &&
                                    hasAssistanceAccess() ? (
                                      <div className="col-md-12">
                                        <Link
                                          className="btn btn-block btn-primary"
                                          to={`/assistance/${solicitation.codigoAtendimento}/details`}>
                                          Ver Atendimento
                                        </Link>
                                      </div>
                                    ) : null}

                                    <div className="col-md-6 col-md-6 left-button">
                                      <button
                                        className="btn btn-block btn-warning"
                                        onClick={() => {
                                          openModal('ALTER_DESTINATION');
                                        }}>
                                        Alterar Destino
                                      </button>
                                    </div>

                                    <div className="col-md-6 col-md-6 right-button">
                                      <button
                                        type="button"
                                        className="btn btn-block btn-warning"
                                        onClick={() => doOpenModal('CONTEXT_EDITINFO')}>
                                        Alterar Valores
                                      </button>
                                    </div>
                                    <div className="col-md-6 col-md-6 left-button">
                                      <button
                                        className="btn btn-block btn-primary"
                                        onClick={() => {
                                          openModal('CONTEXT_FOLLOWUP');
                                        }}>
                                        Histórico
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : statusSolicitacao === 'Expirada' ||
                              statusSolicitacao === 'Interrompida' ? (
                              <div className="col-md-12" style={{marginTop: 10 + 'px'}}>
                                <div className="form-group">
                                  <h3>Ação tomada</h3>
                                  <select
                                    className="form-control m-b col-md-12"
                                    name="solutionCode"
                                    id="solutionCode"
                                    onChange={onSelectReason}>
                                    <option value="SOLUCAO1">Vou chamar outro por fora</option>
                                    <option value="SOLUCAO2">
                                      Vou recriar a solicitação novamente
                                    </option>
                                    <option value="SOLUCAO3">
                                      O cliente vai agendar para outro dia
                                    </option>
                                    <option value="SOLUCAO4">
                                      A solicitação não é mais necessária
                                    </option>
                                  </select>
                                  <br />

                                  <div className="marginBtnR">
                                    <button
                                      className="btn btn-block btn-warning col-md-12"
                                      onClick={onSolve}>
                                      Resolver Solicitação
                                    </button>
                                  </div>

                                  <div className="marginBtnR">
                                    <button
                                      className="btn btn-block btn-primary col-md-12"
                                      onClick={onReopen}>
                                      Recriar Solicitação
                                    </button>
                                  </div>
                                  {solicitation.codigoAtendimento && hasAssistanceAccess() ? (
                                    <div className="marginBtnR">
                                      <Link
                                        className="btn btn-block btn-primary col-md-12"
                                        to={`/assistance/${solicitation.codigoAtendimento}/details`}>
                                        Ver Atendimento
                                      </Link>
                                    </div>
                                  ) : null}

                                  <div className="marginBtnR">
                                    <button
                                      className="btn btn-block btn-primary col-md-12"
                                      onClick={() => {
                                        openModal('CONTEXT_FOLLOWUP');
                                      }}>
                                      Histórico
                                    </button>
                                  </div>
                                  <div className="marginBtnR">
                                    <button
                                      className="btn btn-block btn-primary col-md-12"
                                      onClick={() => {
                                        openModal('ALTER_DESTINATION');
                                      }}>
                                      Alterar Destino
                                    </button>
                                  </div>
                                  <div className="marginBtnR">
                                    <button
                                      style={{marginTop: '0'}}
                                      className="btn btn-block btn-primary col-md-12"
                                      onClick={() => {
                                        openModal('CONTEXT_EDITINFO');
                                      }}>
                                      Alterar Valores
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            <div>
                              <div style={{width: '100%', clear: 'both'}}>
                                <div className="text-center" style={{lineHeight: '40px'}}>
                                  <address style={{paddingTop: '10px'}}>
                                    <FollowUpItemButtons
                                      solicitation={solicitation}
                                      onSolve={onSolve}
                                      onCancel={onCancel}
                                      onCancelCustomized={onCancelCustomized}
                                      onReopen={onReopen}
                                      startScheduledSolicitation={startScheduledSolicitation}
                                      onAccept={onAccept}
                                      onSelectReason={onSelectReason}
                                      onChangeValues={() => doOpenModal('CONTEXT_EDITINFO')}
                                      onChangeDestination={() =>
                                        doOpenModal('ALTER_DESTINATION')
                                      }
                                      profileId={this.props.profileId}
                                    />
                                  </address>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {hasCompanyProfile() && !hasTegmaProfile() && (
                          <div>
                            {statusSolicitacao === 'Aguardando Proposta' ? (
                              <div className="col-md-12" style={{marginTop: 20 + 'px'}}>
                                <div className="col-md-12 col-md-12">
                                  <button
                                    className="btn btn-block btn-primary"
                                    onClick={() => {
                                      openModal('CONTEXT_FOLLOWUP');
                                    }}>
                                    Histórico
                                  </button>
                                </div>
                                <div className="col-md-6 col-md-6 left-button">
                                  <button
                                    className="btn btn-block btn-warning"
                                    onClick={() => {
                                      openModal('ALTER_DESTINATION');
                                    }}>
                                    Alterar Destino
                                  </button>
                                </div>
                                <FollowUpExtendTime
                                  {...this.props}
                                  handlePress={this.handlePressExtraTime}
                                  expireSolicitation={this.handleExpireSolicitation}
                                  extraTime={this.state.extraTime}
                                  onFieldChange={this.onChangeExtraTime}
                                />
                              </div>
                            ) : statusSolicitacao === 'Expirada' ||
                              statusSolicitacao === 'Interrompida' ? (
                              <div className="col-md-12" style={{marginTop: 10 + 'px'}}>
                                <div className="form-group">
                                  <h3>Ação tomada</h3>
                                  <select
                                    className="form-control m-b col-md-12"
                                    name="solutionCode"
                                    id="solutionCode"
                                    onChange={onSelectReason}>
                                    <option value="SOLUCAO1">Vou chamar outro por fora</option>
                                    <option value="SOLUCAO2">
                                      Vou recriar a solicitação novamente
                                    </option>
                                    <option value="SOLUCAO3">
                                      O cliente vai agendar para outro dia
                                    </option>
                                    <option value="SOLUCAO4">
                                      A solicitação não é mais necessária
                                    </option>
                                  </select>
                                  <br />
                                  <div className="marginBtnR">
                                    <button
                                      className="btn btn-block btn-warning col-md-12"
                                      onClick={onSolve}>
                                      Resolver Solicitação
                                    </button>
                                  </div>
                                  <div className="marginBtnR">
                                    <button
                                      className="btn btn-block btn-primary col-md-12"
                                      onClick={() => {
                                        openModal('CONTEXT_FOLLOWUP');
                                      }}>
                                      Histórico
                                    </button>
                                  </div>
                                  {!this.state.hasPermission && (
                                    <div className="marginBtnR">
                                      <button
                                        className="btn btn-block btn-primary col-md-12"
                                        onClick={onReopen}>
                                        Recriar Solicitação
                                      </button>
                                    </div>
                                  )}
                                  {!this.state.hasPermission && (
                                    <div className="marginBtnR">
                                      <button
                                        className="btn btn-block btn-primary col-md-12"
                                        onClick={() => {
                                          openModal('ALTER_DESTINATION');
                                        }}>
                                        Alterar Destino
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null}

                            <div>
                              <div style={{width: '100%', clear: 'both'}}>
                                <div className="text-center" style={{lineHeight: '40px'}}>
                                  <address style={{paddingTop: '10px'}}>
                                    <FollowUpItemButtons
                                      solicitation={solicitation}
                                      onSolve={onSolve}
                                      onCancel={onCancel}
                                      onCancelCustomized={onCancelCustomized}
                                      onReopen={onReopen}
                                      startScheduledSolicitation={startScheduledSolicitation}
                                      onAccept={onAccept}
                                      onSelectReason={onSelectReason}
                                      onChangeValues={() => doOpenModal('CONTEXT_EDITINFO')}
                                      onChangeDestination={() =>
                                        doOpenModal('ALTER_DESTINATION')
                                      }
                                      profileId={this.props.profileId}
                                    />
                                  </address>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {canViewDrivenWinches && (
                      <div
                        className="col-md-6 col-md-7 col-sm-5 col-xs-12"
                        id="guinchosAcionados">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <div>
                            {isFetchingCalledPartners === false ? (
                              <h4>{`Guinchos Acionados: ${calledPartners.length} `}</h4>
                            ) : (
                              <h4>{`Guinchos Acionados`}</h4>
                            )}
                          </div>

                          <div
                            style={{
                              visibility:
                                this.props.solicitation.statusSolicitacao ==
                                  'Aguardando Proposta' && hasReboqueMeProfile()
                                  ? 'visible'
                                  : 'hidden',
                            }}>
                            <ReactTooltip id="acionamento-automatico" />
                            <i
                              data-tip="Solicitação com acionamento automático"
                              data-for="acionamento-automatico">
                              <div
                                className={`status-circle ${
                                  acionamentoManual ? 'status-circle-off' : ''
                                }`}>
                                {acionamentoManual ? 'Manual' : 'Automatico'}
                              </div>
                            </i>
                          </div>
                        </div>

                        <ReactPlaceholder
                          type="text"
                          ready={!isFetchingCalledPartners}
                          customPlaceholder={placeHolder}
                          showLoadingAnimation>
                          <div>
                            {isFetchingCalledPartners === false ? (
                              <RenderList
                                hideSearch
                                msgEmpty="Nenhum guincheiro foi acionado para essa solicitação."
                                renderListItem={this.renderListItem}
                                height={'80vh'}
                                login={login}
                                onFilter={onFilter}
                                list={calledPartners}
                              />
                            ) : null}
                          </div>
                        </ReactPlaceholder>
                      </div>
                    )}

                    {/*MODAL*/}
                    <CustomModal title="Acionar Parceiro" context={MODAL_CALL_PARTNER}>
                      <CallPartnerForm
                        onChange={this.props.onFieldChange}
                        context={MODAL_CALL_PARTNER}
                        solicitation={solicitation}
                        partnerLoading={this.props.partnerLoading}
                        handleClosestPartners={this.props.handleClosestPartners}
                      />
                    </CustomModal>
                    <ClosestPartnersModal
                      title="Acionar Parceiro"
                      context="CLOSEST_PARTNERS_MODAL"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </ReactPlaceholder>
        </Card>
      </div>
    );
  }
}
