import {getNotifications} from "../api/notification_helper"

export function loadNotifications(){
  return (dispatch, getState) => {
    getNotifications().then( (notifications) => {
      dispatch({type: NOTIFICATIONS_UPDATE, pendingNotifications: notifications.listAlerts})
    })
  }
}

export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE'
