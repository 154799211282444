import React from 'react';

const IconBadge = ({ typeList, type, customType }) => {
  let typeInfo = {};
  if (customType) typeInfo = customType;
  else {
    typeInfo = typeList[type] || {
      color: 'primary',
      icon: 'fas fa-eye',
      description: type,
    };
  }

  return (
    <span className={'badge badge-' + typeInfo.color}>
      <i className={typeInfo.icon}> </i> {typeInfo.description}
    </span>
  );
};

export default IconBadge;
