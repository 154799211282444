import request from 'superagent'
import {MAPS_KEY} from '../../config'
import jsonp from 'superagent-jsonp'

export function calcTimeOfArrival(origin, destination){
  return new Promise((resolve, reject) => {
    request
      .get("https://maps.googleapis.com/maps/api/distancematrix/json")
      .send({
        key: MAPS_KEY,
        origins: origin,
        destinations: destination
      })
      .end( (error, result) => {
        if(error)
          reject(error)
        else{
          resolve({
            distance: result.rows[0].elements[0].distance.text,
            time: result.rows[0].elements[0].duration.text
          })
        }
      }
    )
  })
}

export function getAddressSuggestions(address){
  return new Promise((resolve, reject) => {
    request
      .get("https://maps.googleapis.com/maps/api/geocode/json")
      .send({
        key: MAPS_KEY,
        address: address
      })
      .end( (error, result) => {
        if(error || result.status == "ZERO_RESULTS")
          reject(error)
        else{
          let resultList = result.results.map((place) => {
            return {
              address: place.formatted_address,
              coordinates: place.geometry.location
            }
          })

          resolve(resultList)
        }
      }
    )
  })
}
