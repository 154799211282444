import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, FieldArray} from 'redux-form';
import TextField from './formComponents/TextField';
import {
  maxLength,
  minLength,
  required,
  phoneValidator,
} from '../common/formComponents/FormValidation';
import PhoneField from './formComponents/PhoneField';
import CpfField from './formComponents/CpfField';
import SelectField from './formComponents/SelectField';
import Wizard, {Step} from './WizardAdapter';
import {
  createNewBase,
  updateExistingBase,
  resetBasePassword,
  createNewLead,
} from '../../actions/base_actions';
import {browserHistory} from 'react-router';
import {Button} from 'react-bootstrap';
import AddressAutoComplete from '../containers/AddressAutoComplete';
import {renderPriceTable} from './formComponents/RenderPriceTable';

const minLength3 = minLength(3);
const minLength9 = minLength(9);
const minLength4 = minLength(4);
const minLength1 = minLength(1);
const maxLength100 = maxLength(100);

class RegisterBase extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidatePix = this.handleValidatePix.bind(this);
    this.state = {index: 0, usuarioAtivacao: ''};
  }

  async handleSubmit(values, reset) {
    const {createNewBase, type, updateExistingBase, createNewLead} = this.props;
    if (type === 'create')
      createNewBase('CONTEXT_BASE', (codigo) =>
        browserHistory.push(`/bases/${codigo}/partners`),
      );
    else if (type === 'createLead') {
      createNewLead('CONTEXT_BASE', reset);
    } else
      updateExistingBase('CONTEXT_BASE', (codigo, usuarioAtivacao) => {
        if (usuarioAtivacao) this.setState({usuarioAtivacao});
        return browserHistory.push(`/bases/${codigo}/partners`);
      });
  }

  handleValidatePix(value) {
    if (value.length > 100) return;
    if (/\s/.test(value)) return;
    return value;
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      change,
      type,
      initialValues,
    } = this.props;
    const {
      tipoDocumento,
      holderTypeDocument,
      codigo,
      logradouro,
      latitude,
      longitude,
      numero,
      bairro,
      cidade,
      estado,
      pais,
    } = initialValues;
    const {index} = this.state;
    const {pendenciaFiscal, usuarioAtivacao, inadimplente} = initialValues;

    return (
      <div className="ibox">
        <div className="ibox-content" style={{overflow: 'auto'}}>
          <form onSubmit={handleSubmit((v) => this.handleSubmit(v, reset))}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6 form-group base-register-container">
                  <div className="col-sm-12">
                    <TextField
                      name={'contato'}
                      label={'Contato'}
                      validate={[required]}
                      placeholder={'João da Silva'}
                      icon={'fas fa-user'}
                    />
                  </div>
                  <div className="col-sm-12">
                    <TextField
                      name={'nomeFantasia'}
                      label={'Nome Fantasia'}
                      validate={[required]}
                      placeholder={'Reboque.Me CallCenter'}
                      icon={'fas fa-mask'}
                    />
                  </div>
                  {type !== 'createLead' ? (
                    <div className="col-sm-12">
                      <TextField
                        name={'razaoSocial'}
                        label={'Razão Social'}
                        validate={[required]}
                        placeholder={'Oliveira Transportodora'}
                        icon={'fas fa-building'}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="col-sm-6">
                    <PhoneField
                      name={'telefoneCelular'}
                      label={'Telefone Celular'}
                      validate={[required, phoneValidator]}
                      icon={'fas fa-mobile'}
                      placeholder={'(99) 9 9999-9999'}
                    />
                  </div>
                  <div className="col-sm-6">
                    <PhoneField
                      name={'telefoneFixo'}
                      label={'Telefone Fixo'}
                      validate={[]}
                      icon={'fas fa-phone'}
                      placeholder={'(99) 9 9999-9999'}
                    />
                  </div>
                </div>
                {type !== 'createLead' ? (
                  <div className="col-sm-6 form-group base-register-container">
                    <div className="col-sm-6">
                      <CpfField
                        type="number"
                        name={'documento'}
                        label={'Documento'}
                        validate={[required, minLength9]}
                        icon={'fas fa-id-card'}
                        placeholder={'99.999.999/9999-99'}
                        change={change}
                        fieldName="tipoDocumento"
                        type={tipoDocumento}
                      />
                    </div>
                    <div className="col-sm-6">
                      <SelectField
                        name={'codigoStatus'}
                        label={'Status'}
                        icon={'fas fa-align-left'}
                        validate={[required]}>
                        <option>Selecionar</option>
                        <option value="BASEPENDENTEATIVACAO">Aguardando</option>
                        <option value="BASEATIVA">Ativo</option>
                        <option value="BASEINATIVA">Inativo</option>
                      </SelectField>
                    </div>
                    <div className="col-sm-12">
                      <TextField
                        name={'email'}
                        label={'Email'}
                        icon={'far fa-envelope-open'}
                        placeholder={'reboqueme@reboque.me'}
                        type="email"
                        validate={[required]}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextField
                        name={'login'}
                        label={'Login Base'}
                        icon={'fas fa-sign-in-alt'}
                        placeholder={'hju0157'}
                        validate={[required]}
                      />
                    </div>
                    {type === 'create' ? (
                      <div className="col-sm-6">
                        <TextField
                          name={'senha'}
                          label={'Senha'}
                          icon={'fas fa-key'}
                          type="password"
                          validate={[required]}
                        />
                      </div>
                    ) : (
                      <div className="col-md-6">
                        <Button
                          bsStyle="primary"
                          className="btn btn-block btn-primary"
                          id="btnResetPassword"
                          onClick={(e) => {
                            this.props.resetBasePassword(codigo);
                          }}
                          style={{marginTop: '25px'}}>
                          <i
                            className="fas fa-key"
                            style={{
                              fontSize: 16,
                              paddingRight: 3,
                              paddingLeft: 3,
                            }}
                          />
                          resetar senha
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}

                <div className="col-md-4">
                  <div className="form-group custom-tab col-md-12">
                    {pendenciaFiscal && (
                      <p style={{fontWeight: 'bold'}} className="text-danger">
                        * Possui Pendência Envio de NF
                      </p>
                    )}
                    {inadimplente && (
                      <p style={{fontWeight: 'bold'}} className="text-danger">
                        * Base Inadimplente
                      </p>
                    )}
                    {this.state.usuarioAtivacao ? (
                      <p style={{fontWeight: 'bold'}}>
                        * Ativado por {this.state.usuarioAtivacao}
                      </p>
                    ) : (
                      usuarioAtivacao && (
                        <p style={{fontWeight: 'bold'}}>
                          * Ativado por {usuarioAtivacao.nome}
                        </p>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <Wizard
                  index={index}
                  handleTab={(index) =>
                    this.setState({
                      index,
                    })
                  }>
                  {type !== 'createLead' && (
                    <Step title="Dados Bancários">
                      <div className="row">
                        <div className="col-sm-12 base-register-container">
                          <div className="row">
                            <div className="col-sm-4">
                              <TextField
                                type={'number'}
                                name={'bankNumber'}
                                label={'Nº Banco'}
                                icon={'fas fa-piggy-bank'}
                                placeholder={'001'}
                                validate={[required, minLength3]}
                                maxLength={3}
                              />
                            </div>
                            <div className="col-sm-8">
                              <TextField
                                name={'bankName'}
                                label={'Nome do Banco'}
                                icon={'fas fa-piggy-bank'}
                                placeholder={'Inter'}
                                validate={[required]}
                                maxLength={25}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-8">
                              <TextField
                                type="number"
                                name={'agencyNumber'}
                                label={'Nº Agência'}
                                icon={'fas fa-id-card'}
                                placeholder={'0001'}
                                validate={[required, minLength4]}
                                maxLength={4}
                              />
                            </div>
                            <div className="col-sm-4">
                              <TextField
                                type="number"
                                name={'agencyCheckNumber'}
                                label={'D.Agência'}
                                icon={'fas fa-id-card'}
                                placeholder={'1'}
                                validate={[required]}
                                maxLength={1}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-8">
                              <TextField
                                type="number"
                                name={'accountNumber'}
                                label={'Nº da Conta'}
                                icon={'fas fa-id-card'}
                                placeholder={'001'}
                                validate={[required, minLength3]}
                                maxLength={10}
                              />
                            </div>
                            <div className="col-sm-4">
                              <TextField
                                type="number"
                                name={'accountCheckNumber'}
                                label={'D.Conta'}
                                icon={'fas fa-id-card'}
                                placeholder={'1'}
                                validate={[required, minLength1]}
                                maxLength={1}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <TextField
                                name={'holderFullname'}
                                label={'Nome do Beneficiário'}
                                icon={'fas fa-id-card'}
                                placeholder={'GUILHERME R FARIA LIMA'}
                                validate={[required, minLength3]}
                              />
                            </div>
                            <div className="col-sm-6">
                              <CpfField
                                type="number"
                                name={'holderNumberDocument'}
                                label={'Documento do Beneficiário'}
                                validate={[required, minLength9]}
                                icon={'fas fa-id-card'}
                                placeholder={'99.999.999/9999-99'}
                                change={change}
                                fieldName="holderTypeDocument"
                                type={holderTypeDocument}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <TextField
                                name={'chavePix'}
                                label={'Chave Pix'}
                                icon={'fas fa-money-check'}
                                placeholder={''}
                                normalize={this.handleValidatePix}
                                validate={[maxLength100]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Step>
                  )}
                  <Step title="Endereço">
                    <div className="row" style={{marginBottom: '2rem'}}>
                      <div className="col-sm-8">
                        <label htmlFor="Endereço">Endereço</label>
                        <AddressAutoComplete
                          placeholder="Informe o endereço com número"
                          id="baseEndereco"
                          initialValue={
                            !logradouro
                              ? ''
                              : longitude && latitude
                              ? logradouro
                              : `${logradouro}, ${numero} - ${bairro}, ${cidade} - ${estado}, ${pais}`
                          }
                        />
                      </div>
                    </div>
                    <div className="row" style={{marginBottom: '2rem'}}>
                      <div className="col-sm-6">
                        <TextField
                          name={'complemento'}
                          label={'Complemento'}
                          icon={'fas fa-id-card'}
                          placeholder={'Ap 302'}
                        />
                      </div>
                    </div>
                  </Step>
                  <Step title="Tabela de Preços">
                    <FieldArray name={'tabela'} component={renderPriceTable} />
                  </Step>
                </Wizard>
              </div>

              <div className="control-buttons col-md-4">
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-block  btn-warning"
                      disabled={pristine || submitting}
                      onClick={reset}>
                      Reset
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="submit"
                      className="btn btn-block btn-primary"
                      disabled={pristine || submitting}>
                      {type === 'create' || type === 'createLead' ? 'Cadastrar' : 'Atualizar'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.type === 'create') {
    return {
      initialValues: {
        tipoDocumento: state.base.currentBase.tipoDocumento,
        holderTypeDocument: state.base.currentBase.contaBancaria.holderTypeDocument,
      },
    };
  } else if (props.type === 'createLead') {
    return {
      initialValues: {},
    };
  }

  return {
    initialValues: {
      pendenciaFiscal: state.base.currentBase.pendenciaFiscal,
      inadimplente: state.base.currentBase.inadimplente,
      usuarioAtivacao: state.base.currentBase.usuarioAtivacao,
      contato: state.base.currentBase.contato,
      nomeFantasia: state.base.currentBase.nomeFantasia,
      razaoSocial: state.base.currentBase.razaoSocial,
      documento: state.base.currentBase.documento,
      tipoDocumento: state.base.currentBase.tipoDocumento,
      telefoneFixo: state.base.currentBase.telefoneFixo,
      telefoneCelular: state.base.currentBase.telefoneCelular,
      codigo: state.base.currentBase.codigo,
      email: state.base.currentBase.email,
      login: state.base.currentBase.login,
      codigoStatus: state.base.currentBase.status.codigo,
      logradouro: state.base.currentBase.endereco.logradouro,
      complemento: state.base.currentBase.endereco.complemento,
      bairro: state.base.currentBase.endereco.bairro,
      cidade: state.base.currentBase.endereco.cidade,
      numero: state.base.currentBase.endereco.numero,
      cep: state.base.currentBase.endereco.cep,
      pais: state.base.currentBase.endereco.pais,
      estado: state.base.currentBase.endereco.estado,
      longitude: state.base.currentBase.endereco.longitude,
      latitude: state.base.currentBase.endereco.latitude,
      bankName: state.base.currentBase.contaBancaria.bankName,
      bankNumber: state.base.currentBase.contaBancaria.bankNumber,
      agencyNumber: state.base.currentBase.contaBancaria.agencyNumber,
      agencyCheckNumber: state.base.currentBase.contaBancaria.agencyCheckNumber,
      accountNumber: state.base.currentBase.contaBancaria.accountNumber,
      accountCheckNumber: state.base.currentBase.contaBancaria.accountCheckNumber,
      holderFullname: state.base.currentBase.contaBancaria.holderFullname,
      holderTypeDocument: state.base.currentBase.contaBancaria.holderTypeDocument,
      holderNumberDocument: state.base.currentBase.contaBancaria.holderNumberDocument,
      chavePix: state.base.currentBase.contaBancaria.chavePix,
      tabela: state.base.currentBase.tabela,
    },
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewBase,
      updateExistingBase,
      resetBasePassword,
      createNewLead,
    },
    dispatch,
  );

RegisterBase = reduxForm({
  form: 'registerBase',
  destroyOnUnmount: true,
})(RegisterBase);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterBase);
