import { LOAD_DASHBOARDS } from '../actions/financeiro_actions'

const INITIAL_STATE = {
  dashboards: []
}

const financeiro_reducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case LOAD_DASHBOARDS:
      return {
        ...state,
        dashboards: action.value
      }
/*
    case CUSTOMER_INSTANCE_CHANGED:
      let newList = state.customerList.map( oldCustomer => {
        return oldCustomer.codigo === action.customerId? action.customerData : oldCustomer
      });

      return {
        ...state,
        customerList: newList
      }
*/

    default:
      return state
  }
}

export default  financeiro_reducer