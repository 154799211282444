const INITIAL_STATE = {
    balancesList: [],
    invoices:[],
    invoiceId: 0,
    balancesError:null,
    balancesInvoiceError: null,
    balanceDetailsError: null,
    balanceResume: {
        atendimentos: 0,
        valorAPagar: 0,
        valorAReceber: 0,
        resultado: 0
    },
    invoiceLinkError:null,
    invoiceNotaLink:null,
    emailsSent:null,
    emailsSentError:null,
    emailSentId: 0,
    invoiceClosed: 0,
    invoiceClosedError: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'BALANCES_FETCHED':
            return { ...state, balancesList: action.payload }
        case 'BALANCE_INVOICES_FETCHED':
            return { ...state, invoices: action.payload }
        case 'BALANCE_INVOICES_FETCHING':
            return { ...state, isInvoicesFetching: action.payload }
        case 'BALANCE_ID_FETCHED':
            return { ...state, invoiceId: action.payload }
        case 'BALANCES_ERROR':
            return { ...state, balancesError: action.payload }
        case 'BALANCE_INVOICES_ERROR':
            return { ...state, balancesInvoiceError: action.payload }
        case 'BALANCE_RESUME_FETCHED':
            return { ...state, balanceResume: action.payload }
        case 'BALANCE_RESUME_CLEAR': 
            return {...state, balanceResume:{
                atendimentos: 0,
                valorAPagar: 0,
                valorAReceber: 0,
                resultado: 0
            }}
        case 'BALANCE_DETAILS_ERROR':
            return { ...state, balanceDetailsError: action.payload }
        case 'INVOICE_BILLET_LINK_PUT':
            return { ...state, invoiceBilletLink: action.payload }
        case 'INVOICE_BILLET_LINK_PUT_ERROR':
            return { ...state, invoiceBilletLinkError: action.payload }
        case 'INVOICE_NOTA_LINK_PUT':
            return { ...state, invoiceNotaLink: action.payload }
        case 'INVOICE_NOTA_LINK_PUT_ERROR':
            return { ...state, invoiceNotaLinkError: action.payload }
        case 'EMAILS_SENT':
            return { ...state, invoiceLink: action.payload }
        case 'EMAIL_SENT':
            return { ...state, emailSentId: action.payload }
        case 'EMAILS_SENT_ERROR':
            return { ...state, emailsSentError: action.payload }
        case 'INVOICE_CLOSED':
            return { ...state, invoiceClosed: action.payload }
        case 'INVOICE_CLOSED_ERROR':
            return { ...state, invoiceClosed: action.payload }
        default:
            return state
    }
}
