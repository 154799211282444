import React, { Component } from 'react';

import { updateExistingPartner } from '../actions/partner_actions';

import ListPartners from '../components/common/listPartners';

class PartnersView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { location } = this.props;
    return (
      <div>
        <ListPartners
          updatePartnersAction={updateExistingPartner}
          location={location}
        />
      </div>
    );
  }
}

export default PartnersView;
