import React, { Component } from "react";
import {
  putBaseHistory
} from "../actions/base_actions";
import AssistanceTableContainer from '../components/containers/AssistanceTableContainer';

import { connect } from "react-redux";
//import { updateBaseAction } from '../actions/base_actions'


class AllAssistants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBaseCode: 0,
      log: ""
    };
    this.handleLog = this.handleLog.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.prePutLog = this.prePutLog.bind(this);
    this.handleBaseCode = this.handleBaseCode.bind(this);
  }

  componentWillMount() {
    // this.props.stopSolicitationsLoading();
  }

  handleBaseCode(currentBaseCode) {
    this.setState({ currentBaseCode });
  }

  prePutLog(log, visibility) {
    this.props.putLog(this.state.currentBaseCode, this.state.log, visibility);
    this.setState({ log: "" });
  }

  handleLog(e) {
    this.setState({ log: e.target.value });
  }

  handleKeyUp(e) {
    const { putLog } = this.props;
    const { log } = this.state;
    if (e.key === "Enter" && e.ctrlKey) {
      putLog(this.state.currentBaseCode, log);
      this.setState({ log: "" });
    }
  }

  render() {
    return (
      <div>
        <AssistanceTableContainer
          handleBaseCode={this.handleBaseCode}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bases: state,
    logList: state.base.baseHistory,
    login: state.login
  };
};

const mapDispatchToProps = dispatch => {
  return {
    stopSolicitationsLoading: () => {
      dispatch({
        type: `STOP_ALL_TIMERS`
      });
    },
    putLog: (codigo, log) => {
      dispatch(putBaseHistory(codigo, log));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllAssistants);
