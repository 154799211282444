import { doGet, doPost, doPut, doDelete } from './base/base_api_helper';

export function getVehicles(filter, company, status, cliente) {
  let query = {};

  if (filter != '') {
    query._filter = filter;
  }

  if (company) {
    query._empresa = company;
  }

  if (status) {
    query._status = status;
  }

  if (cliente) {
    query._cliente = cliente;
  }

  return doGet('subscriptions', query);
}

export function saveVehicles(
  cliente,
  veiculo,
  vigencia = {},
  codigoProduto,
  codigoEmpresa
) {
  const { dataInicioVigencia, dataFinalVigencia } = vigencia;
  return doPost('subscriptions', {
    cliente,
    veiculo,
    dataInicioVigencia,
    dataFinalVigencia,
    codigoProduto,
    codigoEmpresa,
  });
}

export function getSingleVehicle(customerCode, vehicleCode, auth) {
  return doGet(`customers/${customerCode}/vehicles/${vehicleCode}`, null, auth);
}

export function createVehicle(customerCode, carData, auth) {
  return doPost(
    `customers/${customerCode}/vehicles/`,
    getCreateValues(carData),
    auth
  );
}

export function addVehicle(customerCode, veiculo, cobertura, auth) {
  return doPost(
    `subscriptions/${customerCode}/vehicles/`,
    {
      veiculo,
      ...cobertura,
    },
    auth
  );
}

export function updateVehicle(customerCode, carData, auth) {
  return doPut(
    `customers/${customerCode}/vehicles/${carData.codigo}`,
    getUpdateValues(carData),
    auth
  );
}

export function deleteVehicle(customerCode, carCode) {
  return doDelete(`customers/${customerCode}/vehicles/${carCode}`);
}

function getCreateValues(car) {
  return {
    codigo: car.codigo,
    marca: car.marca,
    modelo: car.modelo,
    cor: car.cor,
    ano: car.ano,
    placa: car.placa,
    tipoVeiculo: car.tipoVeiculo ? car.tipoVeiculo : 'AUTOMOVEL',
  };
}

function getUpdateValues(car) {
  return {
    codigo: car.codigo,
    marca: car.marca,
    modelo: car.modelo,
    cor: car.cor,
    placa: car.placa,
    ano: car.ano,
    tipoVeiculo: car.tipoVeiculo ? car.tipoVeiculo : 'AUTOMOVEL',
  };
}
