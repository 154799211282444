import React, { Component } from "react";

export const Step = ({ title, children }) => <div>{children}</div>;

export default class Wizard extends Component {
  constructor(props) {
    super(props);
    this.mapHeader = this.mapHeader.bind(this);
    this.mapContent = this.mapContent.bind(this);
    this.state = {
      content: []
      // key: 0
    };
  }

  mapHeader() {
    const { children, disabled, index, handleTab } = this.props;
    // const { key } = this.state;
    return children.filter(child => child).map((child, i) => (
      <li
        key={`stepHeader${i}`}
        role="tab"
        style={{ display: child.props.hide? 'none': 'block'}}
        className={index === i ? "current" : "done"}
      >
        <a
          id={`wizard-t-${i}`}
          href="#"
          aria-controls={`wizard-p-${i}`}
          onClick={e => {
            e.preventDefault();
            !disabled && handleTab(i);
          }}
        >
          {child.props.title}
        </a>
      </li>
    ));
  }


  mapContent() {
    const { children } = this.props;
    this.setState({
      content: children.filter(child => child).map((child, i) => (
        <div
          key={`child${i}`}
          className="step-content body current"
          id="wizard-p-0"
          role="tabpanel"
          aria-labelledby="wizard-h-0"
          aria-hidden="false"
        >
          {child}
        </div>
      ))
    });
  }
  componentDidMount() {
    this.mapContent();
  }
  // handleTab(key) {
  //   this.setState({ key });
  // }

  render() {
    const { content } = this.state;
    const { contentColor, index } = this.props;
    return (
      <div id="wizard" role="application" className="wizard clearfix">
        <div className="steps clearfix" style={{ width: "124%", marginLeft: '4%', textAlign: 'center' }}>
          <ul role="tablist">{this.mapHeader()}</ul>
        </div>

        <div
          className="content clearfix"
          style={{ backgroundColor: contentColor ? contentColor : "#eee" }}
        >
          {content[parseInt(index)]}
        </div>
      </div>
    );
  }
}
