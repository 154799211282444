import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="pull-right">
          <strong>Copyright</strong> Soon Tecnologia &copy; 2022-2023
        </div>
      </div>
    );
  }
}

export default Footer;
