import React, { Component } from 'react';
import { toastError, toastSuccess } from '../../../actions/toast_actions';
import { dialAgent } from '../../../api/fale-sempre-mais';
import {
  APP_PROFILES,
  USUARIO_TIPO_REBOQUEME,
} from '../../../reducers/constants';

export default class DialAgent extends Component {
  constructor(props) {
    super(props);
    this.handleDialAgent = this.handleDialAgent.bind(this);
  }

  handleDialAgent(event) {
    event.preventDefault();
    event.stopPropagation();

    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const hasAccessToDial =
      userInfo.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;

    if (hasAccessToDial && this.props.phoneNumber) {
      dialAgent(this.props.phoneNumber.match(/[0-9]/g).join(""))
        .then((result) => {
          toastSuccess(`Discado com sucesso!`);
        })
        .catch((error) => {
          toastError(
            error.response.data.mensagem || "Não foi possível discar o agente"
          );
        });
    }
  }

  render() {
    const { phoneNumber, ...rest } = this.props;

    return (
      <a {...rest} onClick={this.handleDialAgent}>
        <i className="fas fa-phone" style={{ fontSize: '15px' }} />
      </a>
    );
  }
}
