import {
  SOLICITATION_SELECT,
  SOLICITATION_UNSELECT,
  SOLICITATION_SOLUTION_CHANGED,
  SOLICITATION_RESPONSE,
  PENDING_SOLICITATIONS_CHANGED,
  PENDING_SOLICITATIONS_FILTERED,
  CONCLUDED_SOLICITATIONS_FILTERED,
  SOLICITATION_AGUARDANDOPROPOSTA,
  IN_PROGRESS_SOLICITATIONS_FILTERED,
  SOLICITATION_MONITORING,
  IN_PROGRESS_SOLICITATIONS_CHANGED,
  CONCLUDED_SOLICITATIONS_CHANGED,
  CALLED_PARTINERS_CHANGED,
  SCHEDULED_SOLICITATIONS_CHANGED,
  SCHEDULED_SOLICITATIONS_FILTERED,
  SET_STABLE_FILTERED_SOLICITATIONS,
} from '../actions/followup_actions';

import {AGUARDANDOACEITE, AGUARDANDOPROPOSTA} from '../config';
const INITIAL_STATE = {
  inProgressSolicitationQuery: '',
  inProgressSolicitations: [],
  pendingSolicitationQuery: '',
  pendingSolicitations: [],
  //concludedSolicitationQuery:'',
  //concludedSolicitations: [],
  scheduledSolicitationQuery: '',
  scheduledSolicitations: [],
  calledPartners: [],
  solutionCode: 'SOLUCAO1',
  log: [],
  stableFilteredSolicitations: [],
};
const followup_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CALLED_PARTINERS_CHANGED: {
      return {
        ...state,
        calledPartners: action.calledPartners,
      };
    }
    case SOLICITATION_AGUARDANDOPROPOSTA: {
      return {
        ...state,
        currentSolicitation: {
          ...state.currentSolicitation,
          statusSolicitacao: AGUARDANDOPROPOSTA,
        },
      };
    }
    case SOLICITATION_SOLUTION_CHANGED:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SOLICITATION_SELECT:
      return {
        ...state,
        currentSolicitation: action.solicitation,
      };
    case 'SOLICITATION_LOG_FETCHED':
      return {
        ...state,
        log: action.payload,
      };

    case SOLICITATION_UNSELECT:
      return {
        ...state,
        calledPartners: [],
        currentSolicitation: undefined,
        currentResponse: undefined,
        currentStatus: undefined,
        filteredSolicitations: state.pendingSolicitations,
      };
    case PENDING_SOLICITATIONS_CHANGED:
      return {
        ...state,
        pendingSolicitations: action.solicitationList,
      };
    case IN_PROGRESS_SOLICITATIONS_CHANGED:
      return {
        ...state,
        inProgressSolicitations: action.solicitationList,
      };
    case SCHEDULED_SOLICITATIONS_CHANGED:
      return {
        ...state,
        scheduledSolicitations: action.solicitationList,
      };
    /*case CONCLUDED_SOLICITATIONS_CHANGED:
      return {
        ...state,
        concludedSolicitations: action.solicitationList
      }  */
    case PENDING_SOLICITATIONS_FILTERED:
      return {
        ...state,
        filteredPendingSolicitations: action.solicitationList,
        pendingSolicitationQuery: action.solicitationQuery,
      };
    case IN_PROGRESS_SOLICITATIONS_FILTERED:
      return {
        ...state,
        filteredInProgressSolicitations: action.solicitationList,
        pendingSolicitationQuery: action.solicitationQuery,
      };
    case SCHEDULED_SOLICITATIONS_FILTERED:
      return {
        ...state,
        filteredScheduledSolicitations: action.solicitationList,
        scheduledSolicitationQuery: action.solicitationQuery,
      };
    /*case CONCLUDED_SOLICITATIONS_FILTERED:
      return {
        ...state,
        filteredConcludedSolicitations: action.solicitationList,
        concludedSolicitationQuery: action.solicitationQuery
    }*/
    case SOLICITATION_RESPONSE:
      return {
        ...state,
        currentResponse: action.response,
        currentSolicitation: {
          ...state.currentSolicitation,
          statusSolicitacao: AGUARDANDOACEITE,
        },
      };
    case SOLICITATION_MONITORING:
      return {
        ...state,
        currentSolicitation: action.partnerStatus,
      };
    case `FETCHING_SOLICITATION`:
      return {
        ...state,
        isFetchingSolicitation: true,
        isFetchingCalledPartners: true,
      };
    case `SOLICITATION_SELECT_SUCCESS`:
      return {
        ...state,
        isFetchingSolicitation: false,
        isFetchingCalledPartners: true,
      };
    case `CALLED_PARTNERS_SUCCESS`:
      return {
        ...state,
        isFetchingCalledPartners: false,
      };
    case SET_STABLE_FILTERED_SOLICITATIONS:
      const isReset =
        (action.solicitationList.length === 0 && action.reset) || action.query === '';
      return {
        ...state,
        stableFilteredSolicitations: isReset ? [] : action.solicitationList,
      };
    default:
      return state;
  }
};

export default followup_reducer;
