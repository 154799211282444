import React from 'react'
import { connect } from 'react-redux'
import { doCloseModal } from '../common/CustomModal'
import MaskedInput from '../common/MaskedInput'

import { sendSMSFindMe } from '../../api/solicitation_helper'
import { toastSuccess } from '../../actions/toast_actions'

class FindForm extends React.Component {
    constructor(props) {
        super(props)
        this.showForm = this.showForm.bind(this)
        this.onClose = this.onClose.bind(this)
    }

    showForm(event) {
        const value = event.target.checked.replace(/[^0-9]/g, '')
        this.props.onChange({ target: { id: event.target.id, value } })
    }

    handleSubmit() {
        console.log(this.props)
    }

    onClose(event) {
        event.preventDefault()
        doCloseModal(this.props.context)
    }

    render() {
        return (
            <div className="row-fluid" style={{ minHeight: "120px" }}>
                <form ref="form" onSubmit={this.onClose}>
                    <div className="form-group row custom-tab">
                        <div className="col-md-2">
                            <label>DDD</label>
                            <MaskedInput className="form-control" value={this.props.ddd} onChange={this.props.onChange} id="ddd" mask="99" />
                        </div>
                        <div className="col-md-10">
                            <label>Telefone</label>
                            <MaskedInput className="form-control" value={this.props.telefoneSMS} onChange={this.props.onChange} id="telefoneSMS" />
                        </div>
                    </div>
                    <button className="btn btn-block btn-block btn-primary" onClick={
                        (e) => {
                            e.preventDefault()
                            this.handleSubmit()
                        }
                    }><i className="fa fa-check"></i> Enviar</button>
                </form>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ddd: state.service.ddd,
        telefoneSMS: state.service.telefoneSMS
    }
}


export default connect(mapStateToProps, { toastSuccess })(FindForm)
