import React from 'react';
import { connect } from 'react-redux';
import { onFormFieldChange } from '../../actions/form_actions';
import MaskedInput from '../common/MaskedInput';

class CustomerFormView extends React.Component {
  render() {
    let nome =
      this.props.nome && this.props.name
        ? this.props.name.replace(/undefined/g, '')
        : null;
    return (
      <form role="form">
        <div
          className="alert alert-danger alert-dismissable"
          style={{ display: this.props.errorMessage ? 'block' : 'none' }}
        >
          {this.props.errorMessage}
        </div>
        <div className="form-group">
          <label>Nome</label>
          <input
            className="form-control"
            disabled={this.props.readonlyNames}
            value={this.props.nome || ''}
            onChange={this.props.onFieldChange}
            id="nome"
          />
        </div>
        {/*
        <div className="form-group">
          <label>Sobrenome</label>
          <input className="form-control" disabled={this.props.readonlyNames} value={this.props.sobreNome} onChange={this.props.onFieldChange} id="sobreNome"/>
        </div>*/}
        <div className="form-group row">
          <div className="col-md-6">
            <label>Telefone Celular</label>
            <MaskedInput
              className="form-control"
              disabled={this.props.readonlyDetails}
              value={this.props.telefoneCelular || ''}
              onChange={this.props.onFieldChange}
              id="telefoneCelular"
              mask="(99) 9999tt999?"
              formatChars={{ '9': '[0-9]', t: '[0-9-]', '?': '[0-9 ]' }}
              maskChar={null}
            />
          </div>
          <div className="col-md-6">
            <label>CPF</label>
            <MaskedInput
              className="form-control"
              disabled={this.props.readonlyDetails}
              value={this.props.cpf}
              onChange={this.props.onFieldChange}
              id="cpf"
              mask="999.999.999-99"
            />
          </div>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            className="form-control"
            disabled={this.props.readonlyDetails}
            value={this.props.email || ''}
            type="email"
            onChange={this.props.onFieldChange}
            id="email"
          />
        </div>

        {this.props.children}

        <div
          className="form-group"
          style={{ display: this.props.readonlyDetails ? 'none' : 'block' }}
        >
          <button
            style={{ display: !this.props.isEditing ? 'block' : 'none' }}
            className="btn btn-block btn-primary"
            type="submit"
            onClick={this.props.onSave}
          >
            Criar Cliente
          </button>
          <button
            style={{ display: this.props.isEditing ? 'block' : 'none' }}
            className="btn btn-block btn-primary"
            type="submit"
            onClick={this.props.onUpdate}
          >
            Atualizar Cliente
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let contextState = state.forms && state.forms[ownProps.context];

  return {
    ...ownProps,
    ...contextState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFieldChange: (event) => {
      dispatch(
        onFormFieldChange(ownProps.context, event.target.id, event.target.value)
      );
    },
    onSave: (event) => {
      event.preventDefault();
      dispatch(ownProps.onSaveDispatch);
    },
    onUpdate: (event) => {
      event.preventDefault();
      dispatch(ownProps.onUpdateDispatch);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerFormView);
