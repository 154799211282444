import React, { Component } from "react";
import FileUploader from "../../common/fileUploader/FileUploader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  findAttendacesDocuments,
  postAttendanceDocument,
  removeAttendanceDocument
} from "../../../actions/attendence_actions"

import { Tabs, Tab } from "react-bootstrap";
import {
  onModalOpen,
  unbindEvents,
  onModalClose,
} from "../../common/CustomModal";
import { toastError } from "../../../actions/toast_actions";

class DocumentTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.getImages = this.getImages.bind(this);
    this.getDocumentListByType = this.getDocumentListByType.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
  }
  componentDidMount() {
    const {assistanceCode, findAttendacesDocuments } = this.props;
    findAttendacesDocuments(assistanceCode);
  }

  handleFile(files, type) {
    const { postAttendanceDocument, assistanceCode } = this.props;
    postAttendanceDocument(assistanceCode, files, type);
  }

  getImages() {
    const { findAttendacesDocuments, assistanceCode } = this.props;
    findAttendacesDocuments(assistanceCode);
  }

  handleTabs(key) {
    this.setState({
      key,
    });
  }
  getDocumentListByType(documentList, type) {
    return documentList.filter((d, i) => d.tipoDocumento.codigo === type);
  }

  deleteDocument(documentConde) {
    const { assistanceCode, removeAttendanceDocument } = this.props;
    removeAttendanceDocument(assistanceCode, documentConde);
  }

  render() {
    const { documentList, documentListFetching } = this.props;
    return (
      <div
        className="ibox float-e-margins ibox-title"
        style={{
          minHeight: "487px",
        }}
      >
        <FileUploader
          key={`fileUploader-DOCUMENTOGERALATENDIMENTO`}
          upload={this.handleFile}
          type={"DOCUMENTOGERALATENDIMENTO"}
          getImages={this.getImages}
          documentList={documentList}
          documentListFetching={documentListFetching}
          deleteImage={this.deleteDocument}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  imageUploaded: state.attendance.attendancesUploaded,
  documentListFetching: state.attendance.attendancesUploaded.isFetching,
  documentList: state.attendance.attendancesUploaded.actualUploads,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeAttendanceDocument,
      findAttendacesDocuments, postAttendanceDocument },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentTab);
