import React from 'react';

import {Route, Router, IndexRedirect, browserHistory} from 'react-router';

import ServiceView from '../views/Service';
import RelatorioView from '../views/Relatorios';
import RedeView from '../views/redeView';
import PendenciesView from '../views/PendenciesView';
import PartnersView from '../views/Partners';
import OperationalForm from '../views/OperationalForm';
import NewBaseView from '../views/newBaseView';
import MainView from '../views/Main';
import LoginView from '../views/Login';
import ForgotPassView from '../views/ForgotPass';
import FollowUpView from '../views/FollowUp';
import AllInvoices from '../views/AllInvoices';
import AllAssistants from '../views/AllAssistants';
import Main from '../components/layouts/Main';
import Patio from '../components/gestaoPatio/patio';
import Dashboard from '../components/dashBoard/Dashboard';
import ShowAttendence from '../components/common/ShowAttendence';
import RegisterCompany from '../components/common/RegisterCompany';
import CreateLead from '../components/common/CreateLead';
import CreateBase from '../components/common/CreateBase';
import CreateAttendence from '../components/common/CreateAttendence';
import Invoice from '../components/balance/invoice';
import Balance from '../components/balance/balance';

export default (
  <Router history={browserHistory}>
    <Route path="/" component={Main}>
      <Route path="operational-form" component={OperationalForm} />
      <IndexRedirect to="login" />
      <Route path="main" component={MainView} />
      <Route path="dashboard" component={Dashboard} />
      <Route path="patio" component={Patio} />
      <Route path="balance" component={Balance} />
      <Route path="balance/:id" component={Invoice} />
      <Route path="pendencies" component={PendenciesView} />
      <Route path="invoices" component={AllInvoices} />
      <Route path="assistance" component={AllAssistants} />
      <Route path="assistance/relatorios" component={AllAssistants} />
      <Route path="assistance/create" component={CreateAttendence} />
      <Route path="assistance/:id/details" component={ShowAttendence} />
      <Route path="followup" component={FollowUpView} />
      <Route path="followup" component={FollowUpView}>
        <Route path=":id" component={FollowUpView} />
      </Route>
      <Route path="bases" component={NewBaseView} />
      <Route path="bases/:id/partners" component={PartnersView} />
      <Route path="bases/createBase" component={CreateBase} />
      <Route path="bases/createLead" component={CreateLead} />

      <Route path="service" component={ServiceView} />

      {/* <Route path="rede/tracking" component={RedeView} /> */}
      <Route path="solicitacoes/relatorios" component={RelatorioView} />
    </Route>

    <Route path="login" component={LoginView} />
    <Route path="forgotPassword" component={ForgotPassView} />
    <Route path="registerCompany" component={RegisterCompany} />
  </Router>
);
