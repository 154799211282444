import React, { Component } from 'react';

import { Accordion, Panel, Form, Button, Table } from 'react-bootstrap';
import { partnerClick } from '../../actions/partner_actions';
import './partnersAccordion.css';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import _ from 'underscore';
import { connect } from 'react-redux';
import CopyToClipboard from '../common/copyToClipboard/CopyToClipboard';
import DialAgent from '../common/dialAgent/DialAgent';

class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
    };
    this.handlePanelHeader = this.handlePanelHeader.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleServices = this.handleServices.bind(this);
  }
  managementIcon(management) {
    if (management) {
      return (
        <p>
          <i
            style={{ color: '#f8ac59', paddingRight: 5 }}
            className="fas fa-crown"
          />
          Gestor
        </p>
      );
    }
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }
  componentDidMount() {
    $(this.panel).off('click');
  }
  handleServices(service) {
    if (service.servico.codigo === 'REBOQUE') {
      return <td>{service.tipoVeiculoAtendido.descricao}</td>;
    } else if (service.servico.codigo === 'TROCAPNEU') {
      return <td>{service.tipoVeiculoAtendido.descricao}</td>;
    } else if (service.servico.codigo === 'BATERIA') {
      return <td>{service.tipoVeiculoAtendido.descricao}</td>;
    } else {
      return <td>{service.tipoVeiculoAtendido.descricao}</td>;
    }
  }
  handlePanelHeader(header, flag, pos, tel, veiculo, partner, gestor) {
    return (
      <span>
        <label className="h6">
          <Table responsive>
            <thead className="partners-table">
              <tr>
                <th>
                  {flag && flag === 'Descanso' ? (
                    <span className="btn btn-warning btn-xs  custom-button partner-inative">
                      {flag}
                    </span>
                  ) : (
                    false
                  )}
                  {flag && flag === 'Inativo' ? (
                    <span className="btn btn-danger btn-xs custom-button partner-inative">
                      {flag}
                    </span>
                  ) : (
                    false
                  )}
                  {flag && flag === 'Ativo' ? (
                    <span className="btn custom-primary  btn-xs custom-button">
                      {flag}
                    </span>
                  ) : (
                    false
                  )}
                  {flag && flag === 'Em Atendimento' ? (
                    <span className="btn btn-success btn-xs custom-button">
                      {flag}
                    </span>
                  ) : (
                    false
                  )}
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>Motorista</strong>
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>Data da última posição</strong>
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>Telefone Celular</strong>
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>CNH</strong>
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>Marca</strong>
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>Modelo</strong>
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>Placa</strong>
                </th>
                <th className="custom-header-panel-label h6">
                  <strong>Tipo</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="h6" style={{ textAlign: 'center' }}>
                  {this.managementIcon(gestor)}
                </td>
                <td className="h6">{header}</td>
                <td className="h6">{pos}</td>
                <td className="h6">
                  {tel ? (
                    <span>
                      <DialAgent phoneNumber={tel} />
                      <CopyToClipboard
                        textToBeCopied={tel}
                        title={tel}
                        style={{ marginLeft: 5 }}
                      />
                    </span>
                  ) : (
                    ''
                  )}
                </td>

                <td className="h6">
                  {partner.linkCnh ? (
                    <a
                      href={partner.linkCnh}
                      target="_blank"
                      onClick={() => window.open(partner.linkCnh, '_blank')}
                    >
                      Ver CNH
                    </a>
                  ) : (
                    ''
                  )}
                </td>
                <td className="h6">
                  {veiculo ? (veiculo.marca ? veiculo.marca : '') : null}
                </td>
                <td className="h6">
                  {veiculo ? (veiculo.modelo ? veiculo.modelo : '') : null}
                </td>
                <td className="h6">
                  {veiculo ? (veiculo.placa ? veiculo.placa : '') : null}
                </td>
                <td className="h6">
                  {veiculo
                    ? veiculo.tipoVeiculo
                      ? veiculo.tipoVeiculo.descricao
                      : ''
                    : null}
                </td>
              </tr>
            </tbody>
          </Table>
        </label>
        {'  '}
        <Button
          ref={this.alterButton}
          bsStyle="warning"
          className="btn btn-sm btn-warning pull-right"
          id="btnAlterar"
          title="Editar"
          onClick={(e) => {
            this.props.onClickFunction(partner);
          }}
        >
          <i className="fas fa-pencil-alt" />
        </Button>
        <Button
          ref={this.alterButton}
          bsStyle="primary"
          className="btn btn-sm btn-primary pull-right"
          id="btnAlterar"
          title="Resetar Localização"
          onClick={(e) => {
            this.props.onClickResetLocationFunction(partner);
          }}
          style={{ marginRight: '5px' }}
        >
         <i className="fas fa-map-marker" />
        </Button>
        <Button
          ref={this.alterButton}
          bsStyle="primary"
          className="btn btn-sm btn-primary pull-right"
          id="btnResetPassword"
          title="Resetar senha"
          onClick={(e) => {
            this.props.onClickResetFunction(partner);
          }}
          style={{ marginRight: '5px' }}
        >
          <i className="fas fa-key" />
        </Button>
      </span>
    );
  }
  render() {
    const { partners } = this.props;
    const formatter = (date) =>
      moment(date, 'DD/MM/YYYY HH:mm:ss', true).format('"DD/MM/YYYY"');

    return (
      <Accordion>
        {_.map(partners, (partner, key) => {
          const { veiculo } = partner;
          return (
            <Panel
              key={`panel${key}`}
              onClick={() => {
                this.props.partnerClick(partner);
              }}
              ref={(ref) => (this.panel = ref)}
              header={this.handlePanelHeader(
                partner.contato,
                partner.status.descricao,
                partner.dataUltimaPosicao,
                partner.telefoneCelular,
                veiculo,
                partner,
                partner.gestor
              )}
              eventKey={key}
            >
              <h3 className="text-center">Parceiro</h3>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Login</th>
                    <th>Nascimento</th>
                    <th>CPF</th>
                    <th>CNH</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{partner.email}</td>
                    <td>{partner.login}</td>
                    <td>
                      {partner.dataNascimento
                        ? formatter(partner.dataNascimento)
                        : ''}
                    </td>
                    <td>{partner.cpf}</td>
                    <td>{partner.cnh}</td>
                  </tr>
                </tbody>
              </Table>
              <h3 className="text-center">Veículo</h3>
              {partner.veiculo ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Ano</th>
                      <th>Cor</th>
                      <th>Segurado</th>
                      <th>Patins</th>
                      <th>Asa Delta</th>
                      <th>Tamanho</th>
                      <th>Capacidadede carga</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {veiculo.ano && veiculo.ano !== 'undefined'
                          ? veiculo.ano
                          : false}
                      </td>
                      <td>
                        {veiculo.cor && veiculo.cor !== 'undefined'
                          ? veiculo.cor
                          : false}
                      </td>
                      {veiculo.segurado === 1 ? (
                        <td className="text-success">
                          Possui seguro da carga.
                        </td>
                      ) : (
                        <td className="text-danger">
                          Não possui seguro da carga.
                        </td>
                      )}
                      <td>
                        {veiculo.patins && veiculo.patins !== 'undefined'
                          ? veiculo.patins
                          : 'Não possui'}
                      </td>
                      {veiculo.asaDelta === 1 ? (
                        <td className="text-success">Possui asa delta</td>
                      ) : (
                        <td className="text-danger">Não possui asa delta</td>
                      )}
                      <td>
                        {veiculo.tamanhoPlataforma &&
                        veiculo.tamanhoPlataforma !== 'undefined'
                          ? `${veiculo.tamanhoPlataforma} m`
                          : false}
                      </td>
                      <td>
                        {veiculo.capacidadeCarga &&
                        veiculo.capacidadeCarga !== 'undefined'
                          ? `${veiculo.capacidadeCarga} Kg`
                          : false}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <div>
                  <h5 className="text-center text-warning">
                    Nenhum veículo cadastrado para este parceiro.
                  </h5>
                </div>
              )}
              {
                <div>
                  <h3 className="text-center">Serviços</h3>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Serviço</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="th">Reboque</td>
                        {partner.veiculo && partner.veiculo.listaServicos
                          ? _.map(partner.veiculo, (servico) => {
                              return _.isArray(servico) === true
                                ? _.map(servico, (item) => {
                                    return item.servico.codigo === 'REBOQUE' ? (
                                      <td className="text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        />{' '}
                                        {item.tipoVeiculoAtendido.descricao}
                                      </td>
                                    ) : (
                                      false
                                    );
                                  }) // fim do map
                                : false;
                              // fim do if
                            })
                          : false}
                        {/*FIM DO BLOCO*/}
                      </tr>
                      <tr>
                        <td>Troca de Pneu</td>
                        {partner.veiculo && partner.veiculo.listaServicos
                          ? _.map(partner.veiculo, (servico) => {
                              return _.isArray(servico) === true
                                ? _.map(servico, (item) => {
                                    return item.servico.codigo ===
                                      'TROCAPNEU' ? (
                                      <td className="text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        />{' '}
                                        {item.tipoVeiculoAtendido.descricao}
                                      </td>
                                    ) : (
                                      false
                                    );
                                  }) // fim do map
                                : false;
                              // fim do if
                            })
                          : false}
                        {/*FIM DO BLOCO*/}
                      </tr>
                      <tr>
                        <td>Bateria</td>
                        {partner.veiculo && partner.veiculo.listaServicos
                          ? _.map(partner.veiculo, (servico) => {
                              return _.isArray(servico) === true
                                ? _.map(servico, (item) => {
                                    return item.servico.codigo === 'BATERIA' ? (
                                      <td className="text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        />{' '}
                                        {item.tipoVeiculoAtendido.descricao}
                                      </td>
                                    ) : (
                                      false
                                    );
                                  }) // fim do map
                                : false;
                              // fim do if
                            })
                          : false}
                        {/*FIM DO BLOCO*/}
                      </tr>
                      <tr>
                        <td>Pane Seca</td>
                        {partner.veiculo && partner.veiculo.listaServicos
                          ? _.map(partner.veiculo, (servico) => {
                              return _.isArray(servico) === true
                                ? _.map(servico, (item) => {
                                    return item.servico.codigo ===
                                      'PANESECA' ? (
                                      <td className="text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        />{' '}
                                        {item.tipoVeiculoAtendido.descricao}
                                      </td>
                                    ) : (
                                      false
                                    );
                                  }) // fim do map
                                : false;
                              // fim do if
                            })
                          : false}
                        {/*FIM DO BLOCO*/}
                      </tr>
                      <tr>
                        <td>Guincho Subsolo</td>
                        {partner.veiculo && partner.veiculo.listaServicos
                          ? _.map(partner.veiculo, (servico) => {
                              return _.isArray(servico) === true
                                ? _.map(servico, (item) => {
                                    return item.servico.codigo === 'SUBSOLO' ? (
                                      <td className="text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        />{' '}
                                        {item.tipoVeiculoAtendido.descricao}
                                      </td>
                                    ) : (
                                      false
                                    );
                                  }) // fim do map
                                : false;
                              // fim do if
                            })
                          : false}
                        {/*FIM DO BLOCO*/}
                      </tr>
                      <tr>
                        <td>Chaveiro</td>
                        {partner.veiculo && partner.veiculo.listaServicos
                          ? _.map(partner.veiculo, (servico) => {
                              return _.isArray(servico) === true
                                ? _.map(servico, (item) => {
                                    return item.servico.codigo ===
                                      'CHAVEIRO' ? (
                                      <td className="text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        />{' '}
                                        {item.tipoVeiculoAtendido.descricao}
                                      </td>
                                    ) : (
                                      false
                                    );
                                  }) // fim do map
                                : false;
                              // fim do if
                            })
                          : false}
                        {/*FIM DO BLOCO*/}
                      </tr>
                      <tr>
                        <td>Mecânico</td>
                        {partner.veiculo && partner.veiculo.listaServicos
                          ? _.map(partner.veiculo, (servico) => {
                              return _.isArray(servico) === true
                                ? _.map(servico, (item) => {
                                    return item.servico.codigo ===
                                      'MECANICO' ? (
                                      <td className="text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        />{' '}
                                        {item.tipoVeiculoAtendido.descricao}
                                      </td>
                                    ) : (
                                      false
                                    );
                                  }) // fim do map
                                : false;
                              // fim do if
                            })
                          : false}
                        {/*FIM DO BLOCO*/}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              }
            </Panel>
          );
        })}
      </Accordion>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ partnerClick }, dispatch);
export default connect(
  null,
  mapDispatchToProps
)(Partner);
