import axios from 'axios';

import {USUARIO_TIPO_BASE, USUARIO_TIPO_REBOQUEME} from '../../reducers/constants';
import {BASE_URL} from '../../config';
import {doPutV2} from '../../api/base/base_api_v2_helper';
import {doGet, doPost} from '../../api/base/base_api_helper';
import {toastSuccess, toastError} from '../../actions/toast_actions';

export function getDocumentsToApproval(page = 1, afterSuccess) {
  return (dispatch) => {
    dispatch({type: 'IS_FETCHING_DOCUMENTS', value: true});
    doGet(`documents/documentsToAccept?page=${page}`)
      .then((resp) => {
        dispatch({
          type: 'DOCUMENTS_FETCHED',
          payload: {
            documents: resp.listSolicitacoes,
            totalItems: resp.totalItems,
          },
        });
        if (typeof afterSuccess === 'function') {
          afterSuccess(resp.listSolicitacoes);
        }
      })
      .catch((err) => toastError(err.mensagemCliente))
      .finally(() => dispatch({type: 'IS_FETCHING_DOCUMENTS', value: false}));
  };
}

export function getParkingVehicles() {
  return (dispatch) => {
    dispatch({type: 'IS_FETCHING_PARKING_VEHICLES', value: true});
    doGet('general/veiculosNoPatio')
      .then((resp) => {
        const value = resp.listPernoites ? resp.listPernoites : [];
        dispatch({type: 'PARKING_VEHICLES_FETCHED', value});
      })
      .finally(() => dispatch({type: 'IS_FETCHING_PARKING_VEHICLES', value: false}));
  };
}

export function updateObservationSolicitation(codeSolicitation, observation, address) {
  return (dispatch) => {
    dispatch({type: 'IS_UPDATING_SOLICITATION', value: true});

    const isAddressEmpty =
      address && Object.values(address).every((value) => value === undefined);

    const body = {note: observation};
    if (!isAddressEmpty) {
      body.requestedDestinyAddress = address;
    }

    doPutV2(`overnight/${codeSolicitation}`, body)
      .then(() => {
        dispatch(getParkingVehicles());
      })
      .finally(() => {
        dispatch({type: 'IS_UPDATING_SOLICITATION', value: false});
      });
  };
}

export function getDocumentAssetsFromSolicitation(codeSolicitation) {
  return (dispatch) => {
    doGet(`documents/documentsToAccept/${codeSolicitation}`)
      .then((resp) => {
        dispatch({type: 'DOCUMENTS_ASSETS_FETCHED', value: resp.listDocumentos});
      })
      .catch((err) => toastError(err.mensagemCliente));
  };
}

export function getUserPerformance(startDate, endDate) {
  return (dispatch) => {
    dispatch({type: 'USER_PERFORMANCE_FETCHING', payload: true});
    doGet('dashboards/usersPerformance', {
      _createdAtFrom: startDate,
      _createdAtTo: endDate,
    })
      .then((resp) => {
        dispatch([
          {type: 'USER_PERFORMANCE_FETCHED', payload: resp.users},
          {type: 'USER_PERFORMANCE_FETCHING', payload: false},
        ]);
      })
      .catch((e) => {
        console.log(e);
        dispatch({type: 'USER_PERFORMANCE_FETCHING', payload: false});
      });
  };
}
function getTimeStats(startDate, endDate) {
  return new Promise((fulfill, reject) => {
    doGet('dashboards/timePerformance/', {
      _createdAtFrom: startDate,
      _createdAtTo: endDate,
    })
      .then((resp) => fulfill(resp))
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}

export function removeDocument(codigo) {
  return (dispatch) => {
    dispatch({type: 'REMOVE_DOCUMENT', value: codigo});
  };
}

export function removeAssetsDocuments(codigo) {
  return {
    type: 'REMOVE_DOCUMENTS_ASSETS',
    value: codigo,
  };
}

function getTotalPartners() {
  return new Promise((fulfill, reject) => {
    doGet('dashboards/totalPartners/')
      .then((resp) => fulfill(resp))
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}
function getTotalCustomers(startDate, endDate) {
  return new Promise((fulfill, reject) => {
    doGet('dashboards/totalCustomers/')
      .then((resp) => fulfill(resp))
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}
function getTimeLine() {
  return new Promise((fulfill, reject) => {
    doGet('dashboards/timelineTotalSolicitations/', {_meses: 12})
      .then((resp) => fulfill(resp))
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}
export function getLocationCluster(startDate, endDate) {
  return (dispatch) => {
    dispatch({type: 'DASHBOARD_CLUSTER_FETCHING', payload: true});
    doGet('dashboards/locationCluster/', {
      _createdAtFrom: startDate,
      _createdAtTo: endDate,
    })
      .then((resp) => {
        dispatch([
          {type: 'DASHBOARD_CLUSTER_FETCHED', payload: resp.locations || []},
          {type: 'DASHBOARD_CLUSTER_FETCHING', payload: false},
        ]);
      })
      .catch((e) => {
        console.log(e);
        dispatch({type: 'DASHBOARD_CLUSTER_FETCHING', payload: false});
      });
  };
}

export function getCompanyCredits() {
  return (dispatch, getState) => {
    const code = getState().login.user_info.empresa.codigo;
    dispatch({type: 'DASHBOARD_CREDITS_FETCHING', payload: true});
    doGet(`companies/${code}/getBalance`)
      .then((resp) => {
        dispatch([
          {type: 'DASHBOARD_CREDITS_FETCHED', payload: resp.saldo},
          {type: 'DASHBOARD_CREDITS_FETCHING', payload: false},
        ]);
      })
      .catch((e) => {
        console.log(e);
        dispatch({type: 'DASHBOARD_CREDITS_FETCHED', payload: false});
      });
  };
}
export function buyCredits(amount, method) {
  return (dispatch, getState) => {
    const code = getState().login.user_info.empresa.codigo;
    dispatch({type: 'BUY_CREDITS_FETCHING', payload: true});
    doPost(`companies/${code}/buyCredits`, {amount, method})
      .then((resp) => {
        toastSuccess('Pedido de compra recebido');
        dispatch([
          {type: 'BUY_CREDITS_FETCHED', payload: true},
          {type: 'BUY_CREDITS_FETCHING', payload: false},
        ]);
        var win = window.open(resp.urlBoleto, '_blank');
        win.focus();
      })
      .catch((e) => {
        console.log(e);
        dispatch({type: 'BUY_CREDITS_FETCHING', payload: false});
        toastError(e.mensagemCliente);
      });
  };
}

export function getDashboardStats(startDate, endDate) {
  return (dispatch, getState) => {
    const promisses = [];
    dispatch({type: 'DASHBOARD_STATS_FETCHING', payload: true});
    promisses.push(getTimeStats(startDate, endDate));

    promisses.push(getTotalCustomers());
    promisses.push(getTimeLine());

    const onlyAdminAndPartner =
      getState().login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
      getState().login.user_info.usuario.tipoUsuario === USUARIO_TIPO_BASE;

    onlyAdminAndPartner && promisses.push(getTotalPartners());
    Promise.all(promisses)
      .then((resp) => {
        dispatch([
          {type: 'DASHBOARD_TIME_STATS_FETCHED', payload: resp[0]},
          {type: 'DASHBOARD_TOTAL_CUSTOMERS', payload: resp[1].total},
          {type: 'DASHBOARD_TIMELINE', payload: resp[2].solicitacoesPeriodo},
          {type: 'DASHBOARD_STATS_FETCHING', payload: false},
          onlyAdminAndPartner && {
            type: 'DASHBOARD_TOTAL_PARTNERS',
            payload: resp[3].total,
          },
        ]);
      })
      .catch((e) => {
        console.log(e);
        dispatch({type: 'DASHBOARD_STATS_FETCHING', payload: false});
      });
  };
}
