import {BUSINESS_LIST_CHANGED, BUSINESS_INSTANCE_CHANGED, FILTER_BUSINESS_TEXT} from '../actions/business_actions'

const business_reducer = (state = {}, action) => {
  switch(action.type){
    case BUSINESS_LIST_CHANGED:
      return {
        ...state,
        businessList: action.value
      }
    case FILTER_BUSINESS_TEXT:
      return {
        ...state,
        businessFilter: action.value
      }
/*
    case BUSINESS_INSTANCE_CHANGED:
      let newList = state.businessList.map( oldBase => {
        return oldBase.codigo === action.businessId? action.businessData : oldBase
      });

      return {
        ...state,
        businessList: newList
      }
*/

    default:
      return state
  }
}

export default  business_reducer
