import toastr from 'toastr';

const getAccountList2 = () => JSON.parse(localStorage.getItem('accountList')) || []

export function addAccount(name, username, password) {
    return dispatch => {
        const accountList = getAccountList2()
        accountList.push({ name, username, password })
        localStorage.setItem('accountList', JSON.stringify(accountList))
        dispatch([{ type: 'ACCOUNT_ADDED', payload: true }, getAccountList()])
        toastr.success("Conta adicionada", "Sucesso", { "positionClass": "toast-bottom-right",})
    }

}
export function getAccountList() {
    return dispatch => dispatch({ type: 'ACCOUNT_LIST_FETCHED', payload: getAccountList2() })
}


export function removeAccount(i){
    return dispatch => {
        const newAccountList = getAccountList2().filter((acc, j) => j != i)
        localStorage.setItem('accountList', JSON.stringify(newAccountList))
        dispatch(getAccountList())
        toastr.success("Conta removida", "Sucesso", { "positionClass": "toast-bottom-right", })
    }

      
    
}

