import {
  CONCLUDED_SOLICITATIONS_FILTERED,
  CONCLUDED_SOLICITATIONS_CHANGED,
  CONCLUDED_SOLICITATIONS_CVS_CHANGED,
  CONCLUDED_SOLICITATIONS_CVS_PROGRESS,
  CONCLUDED_SOLICITATIONS_CVS_CLEAR,
} from '../actions/followup_actions';

const INITIAL_STATE = {
  concludedSolicitationQuery: '',
  concludedSolicitations: [],
  concludedSolicitationsForCVSExport: [],
  totalItems: 0,
  pages: 0,
  userPerformanceFetching: false,
  userPerformance: [],
  isFetching: false,
  isFetchingCVS: false,
  fetchingCVSProgress: 0,
  timeStats: {
    tma: 0,
    tmc: 0,
  },
  totalCustomers: 0,
  totalPartners: 0,
  timeLine: [],
  locations: [],
  clusterFetching: false,
  cluster: [],
  companyCredits: 0,
  companyCreditsFetching: false,
  billetLink: null,
  buyCreditsFetching: false,
  documents: [],
  documentsAssets: [],
  documentsFetching: false,
  parkingVehiclesFetching: false,
  isUpdatingSolicitation: false,
  parkingVehicles: [],
  totalItems: 0,
};
const dashboardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONCLUDED_SOLICITATIONS_CHANGED:
      return {
        ...state,
        concludedSolicitations: action.solicitationList,
        totalItems: action.totalItems,
        pages: action.pages,
      };

    case CONCLUDED_SOLICITATIONS_CVS_CHANGED:
      return {
        ...state,
        concludedSolicitationsForCVSExport: [
          ...state.concludedSolicitationsForCVSExport,
          ...action.solicitationList,
        ],
      };

    case CONCLUDED_SOLICITATIONS_CVS_CLEAR:
      return {
        ...state,
        concludedSolicitationsForCVSExport: [],
      };

    case CONCLUDED_SOLICITATIONS_CVS_PROGRESS:
      return {
        ...state,
        fetchingCVSProgress: action.progress,
      };

    case CONCLUDED_SOLICITATIONS_FILTERED:
      return {
        ...state,
        filteredConcludedSolicitations: action.solicitationList,
        concludedSolicitationQuery: action.solicitationQuery,
      };
    case `IS_FETCHING_DASHBOARD`:
      return {
        ...state,
        isFetching: action.value,
      };
    case `IS_FETCHING_DASHBOARD_CVS`:
      return {
        ...state,
        isFetchingCVS: action.value,
      };
    case `IS_FETCHING_PARKING_VEHICLES`:
      return {
        ...state,
        parkingVehiclesFetching: action.value,
      };
    case `IS_UPDATING_SOLICITATION`:
      return {
        ...state,
        isUpdatingSolicitation: action.value,
      };
    case 'PARKING_VEHICLES_FETCHED':
      return {
        ...state,
        parkingVehicles: action.value,
      };
    case 'IS_FETCHING_DOCUMENTS':
      return {
        ...state,
        documentsFetching: action.value,
      };
    case 'REMOVE_DOCUMENT':
      return {
        ...state,
        documents: state.documents.filter((document) => document.codigo !== action.value),
      };
    case 'REMOVE_DOCUMENTS_ASSETS':
      return {
        ...state,
        documentsAssets: state.documentsAssets.filter(
          (asset) => asset.codigo !== action.value,
        ),
      };
    case `DOCUMENTS_FETCHED`:
      return {
        ...state,
        documents: action.payload.documents,
        totalItems: action.payload.totalItems,
      };
    case `DOCUMENTS_ASSETS_FETCHED`:
      return {
        ...state,
        documentsAssets: action.value,
      };
    case 'USER_PERFORMANCE_FETCHING':
      return {
        ...state,
        userPerformanceFetching: action.payload,
      };
    case 'USER_PERFORMANCE_FETCHED':
      return {
        ...state,
        userPerformance: action.payload,
      };
    case 'DASHBOARD_TIME_STATS_FETCHED':
      return {
        ...state,
        timeStats: action.payload,
      };
    case 'DASHBOARD_TOTAL_CUSTOMERS':
      return {
        ...state,
        totalCustomers: action.payload,
      };
    case 'DASHBOARD_TOTAL_PARTNERS':
      return {
        ...state,
        totalPartners: action.payload,
      };
    case 'DASHBOARD_TIMELINE':
      return {
        ...state,
        timeLine: action.payload,
      };
    case 'DASHBOARD_CLUSTER_FETCHED':
      return {
        ...state,
        cluster: action.payload,
      };
    case 'DASHBOARD_CLUSTER_FETCHING':
      return {
        ...state,
        clusterFetching: action.payload,
      };
    case 'DASHBOARD_CREDITS_FETCHED':
      return {
        ...state,
        companyCredits: action.payload,
      };
    case 'DASHBOARD_CREDITS_FETCHING':
      return {
        ...state,
        companyCreditsFetching: action.payload,
      };
    case 'BUY_CREDITS_FETCHED':
      return {
        ...state,
        billetLink: action.payload,
      };
    case 'BUY_CREDITS_FETCHING':
      return {
        ...state,
        buyCreditsFetching: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardsReducer;
