import React, {Component} from 'react';

import toastr from 'toastr';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';

import IconBadge from './IconBadge';
import FastButton from './fastButton';
import {CustomModalWithoutTabIndex, doCloseModal, doOpenModal} from './CustomModal';
import {
  hasBaseProfile,
  hasCompanyProfile,
  isFromCompany,
  hasAccessToPublicHistoryPost,
} from '../../utils/check_permission';
import {getEstagios} from '../../utils/attendance_helper';
import {
  CODIGO_DAF,
  CODIGO_KOVI,
  HISTORICO_MODO_PRIVADO,
  HISTORICO_MODO_PUBLICO,
} from '../../reducers/constants/index';
import userIcon from '../../assets/img/user-icon.png';

const MODAL_NAME = 'modal-new-historic';
const MODAL_NAME_IFRAME = 'modal-new-iframe';

const tipoMotivoDAFOptions = [
  {
    value: 'TIPOASSISTENCIAMOTOR',
    label: 'Motor',
  },
  {
    value: 'TIPOASSISTENCIAPOSTRATAMENTO',
    label: 'Sistema de pós tratamento',
  },
  {
    value: 'TIPOASSISTENCIATRANSMISSAO',
    label: 'Transmissão',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAELETRICO',
    label: 'Sistema elétrico',
  },
  {
    value: 'TIPOASSISTENCIACABINE',
    label: 'Cabine',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAAR',
    label: 'Sistema de ar',
  },
  {
    value: 'TIPOASSISTENCIAEIXOTRASEIRO',
    label: 'Eixo Traseiro',
  },
  {
    value: 'TIPOASSISTENCIAEIXODIANTEIRO',
    label: 'Eixo Dianteiro',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEFREIO',
    label: 'Sistema de freio',
  },
  {
    value: 'TIPOASSISTENCIAOUTROS',
    label: 'Outros',
  },
];

const tipoMotivoOptions = [
  {
    value: 'TIPOASSISTENCIAEIXOTRACAO',
    label: 'Eixo Tração',
  },
  {
    value: 'TIPOASSISTENCIAMOTOR',
    label: 'Motor',
  },
  {
    value: 'TIPOASSISTENCIAPOSTRATAMENTO',
    label: 'Sistema de pós tratamento',
  },
  {
    value: 'TIPOASSISTENCIATRANSMISSAO',
    label: 'Transmissão',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAELETRICO',
    label: 'Sistema elétrico',
  },
  {
    value: 'TIPOASSISTENCIACABINE',
    label: 'Cabine',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEESCAPE',
    label: 'Sistema de escape',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAAR',
    label: 'Sistema de ar',
  },
  {
    value: 'TIPOASSISTENCIAEIXOTRASEIRO',
    label: 'Eixo Traseiro',
  },
  {
    value: 'TIPOASSISTENCIAEIXODIANTEIRO',
    label: 'Eixo Dianteiro',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEFREIO',
    label: 'Sistema de freio',
  },
  {
    value: 'TIPOASSISTENCIAOUTROS',
    label: 'Outros',
  },
];

const defeitos = {
  TIPOASSISTENCIAEIXOTRACAO: [
    {
      value: 'DEFEITO_RETENTOR_RODA',
      label: 'Retentor de roda',
    },
    {
      value: 'DEFEITO_CARDAN',
      label: 'Cardan',
    },
    {
      value: 'DEFEITO_ABS',
      label: 'ABS',
    },
    {
      value: 'DEFEITO_EIXO_TRACAO_OUTROS',
      label: 'Eixo Tração',
    },
  ],
  TIPOASSISTENCIAMOTOR: [
    {value: 'DEFEITO_BOMBA_DAGUA', label: 'Bomba d’água'},
    {value: 'DEFEITO_CORREIA_MICRO_V', label: 'Correia micro V'},
    {
      value: 'DEFEITO_COLETOR_DE_ADMISSAO',
      label: 'Coletor de admissão',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_CARTER',
      label: 'Carter',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_TAMPA_CABECOTE',
      label: 'Tampa de cabeçote',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_CABECOTE',
      label: 'Cabeçote',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_MANGUEIRA_COMBUSTIVEL',
      label: 'Mangueiras de combustível',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_SENSOR_RESERVATORIO_OLEO',
      label: 'Sensor do reservatório de óleo',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_BOMBA_OLEO',
      label: 'Bomba de óleo',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_VALVULA_TERMOSTATICA',
      label: 'Válvula termostática',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_EMBREAGEM_VISCOSA',
      label: 'Embreagem viscosa',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_ABRACADEIRA_INTERCOOLER',
      label: 'Abraçadeira do intercooler',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_TURBINA',
      label: 'Turbina',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_COMPRESSOR_AR_CONDICIONADO',
      label: 'compressor do ar condicionado',
    },
    {
      value: 'DAF_ASSISTENCIA_MOTOR_VTG',
      label: 'VTG',
    },
    {value: 'DEFEITO_COMMON_RAIL', label: 'Sensor do common rail'},
    {
      value: 'DEFEITO_ESTICADOR_DE_CORREIA',
      label: 'Esticador de correia',
    },
    {value: 'DEFEITO_RADIADOR_DE_AGUA', label: 'Radiador de água'},
    {
      value: 'DEFEITO_MANGUEIRA_DE_AGUA_INFERIOR_DO_MOTOR',
      label: 'Mangueira de água inferior do motor',
    },
    {
      value: 'DEFEITO_INTERCOOLER',
      label: 'intercooler',
    },
    {
      value: 'DEFEITO_MANGUEIRA_DO_INTERCOOLER_VERMELHO',
      label: 'Mangueira do intercooler vermelho',
    },
    {
      value: 'DEFEITO_FILTROS_DE_OLEO_DO_MOTOR',
      label: 'Filtros de óleo do motor',
    },
    {
      value: 'DEFEITO_FILTRO_DIESEL_RARO',
      label: 'Filtro diesel raro',
    },
    {
      value: 'DEFEITO_FILTRO_DIESEL_MOTOR',
      label: 'Filtro diesel motor',
    },
    {
      value: 'DEFEITO_FILTRO_DE_AR_DO_MOTOR',
      label: 'Filtro de ar do motor',
    },
    {
      value: 'DEFEITO_POLIA_DO_VENTILADOR',
      label: 'Polia do ventilador',
    },
    {
      value: 'DEFEITO_POLIA_DIANTEIRA_DO_VIRABREQUIM',
      label: 'Polia dianteira do Virabrequim',
    },
    {value: 'DEFEITO_VALVULA_BPV', label: 'Válvula BPV'},
    {value: 'DEFEITO_VALVULA_EGR', label: 'Válvula EGR'},
    {
      value: 'DEFEITO_MANGUEIRA_DO_FILTRO_DE_COMBUSTIVEL',
      label: 'Mangueira do filtro de combustível',
    },
    {
      value: 'DEFEITO_BOMBA_DE_ELEVACAO_DE_PRESSAO_DIESEL',
      label: 'Bomba de elevação de pressão diesel',
    },
    {value: 'DEFEITO_BICO_INJETOR', label: 'Bico injetor'},
    {
      value: 'DEFEITO_RESERVATORIO_DE_AGUA',
      label: 'Reservatório de água',
    },
    {value: 'DEFEITO_MOTOR_SENSOR', label: 'Sensor do motor'},
    {value: 'DEFEITO_MOTOR_OUTROS', label: 'Motor'},
    {value: 'DEFEITO_MOTOR_RUIDO_INTERNO', label: 'Ruído interno motor'},
    {value: 'DEFEITO_MOTOR_DTC_VERMELHO', label: 'DTC vermelho motor'},
    {value: 'DEFEITO_MOTOR_VAZAMENTO_OLEO', label: 'Vazamento de óleo'},
    {value: 'DEFEITO_MOTOR_VAZAMENTO_DIESEL', label: 'Vazamento de diesel'},
    {value: 'DEFEITO_MOTOR_NIVEL_BAIXO_OLEO', label: 'Nível baixo óleo do motor'},
    {value: 'DEFEITO_MOTOR_REDUCAO_POTENCIA', label: 'Redução de potência'},
    {value: 'DEFEITO_MOTOR_MODULO_VENTILACAO_CARTER', label: 'Módulo de ventilação do cárter'},
    {value: 'DEFEITO_MOTOR_COLETOR_ESCAPE', label: 'Coletor de escape'},
    {value: 'DEFEITO_MOTOR_LIMPEZA_SISTEMA_COMBUSTIVEL', label: 'Limpeza sistema combustível'},
    {value: 'DEFEITO_MOTOR_CALCO_HIDRAULICO', label: 'Calço hidráulico'},
    {value: 'DEFEITO_MOTOR_BLOCO', label: 'Bloco do motor'},
    {value: 'DEFEITO_MOTOR_TUCHO', label: 'Tucho'},
    {value: 'DEFEITO_MOTOR_REGULAGEM_VALVULAS', label: 'Regulagem de válvulas'},
    {
      value: 'DEFEITO_MOTOR_VENTILADOR_RADIADOR_QUEBRADO',
      label: 'Ventilador do radiador quebrado',
    },
    {value: 'DEFEITO_MOTOR_POLIA_VENTILADOR_SOLTA', label: 'Polia do ventilador solta'},
    {value: 'DEFEITO_MOTOR_MODULO', label: 'Módulo do motor'},
    {value: 'DEFEITO_MOTOR_QUALIDADE_DIESEL', label: 'Qualidade do diesel'},

    {
      value: 'DEFEITO_MOTOR_MANGUEIRA_ENTRADA_AGUA_MOTOR',
      label: 'Mangueira de entrada água motor',
    },
    {
      value: 'DEFEITO_MOTOR_MANGUEIRA_FILTRO_COMBUSTIVEL',
      label: 'Mangueira do filtro de combustível ',
    },
    {value: 'DEFEITO_MOTOR_MANGUEIRA_RETORNO_AGUA', label: 'Mangueira retorno água motor'},
    {
      value: 'DEFEITO_MOTOR_MANGUEIRA_ENTRADA_INTERCOOLER',
      label: 'Mangueira de entrada intercooler',
    },
    {
      value: 'DEFEITO_MOTOR_MANGUEIRA_RETORNO_INTERCOOLER',
      label: 'Mangueira retorno intercooler',
    },
    {value: 'DEFEITO_MOTOR_PANE_SECA', label: 'Pane seca'},
    {value: 'DEFEITO_MOTOR_POLAINA', label: 'Polaina'},
    {value: 'DEFEITO_MOTOR_ORCAMENTO_NAO_APROVADO', label: 'Orçamento não aprovado '},
    {value: 'DEFEITO_MOTOR_SEGUIU_CONCESSIONARIA', label: 'Seguiu para concessionária'},
    {value: 'DEFEITO_MOTOR_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_MOTOR_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente '},
    {value: 'DEFEITO_MOTOR_RESET_CHAVE_GERAL', label: 'Reset chave geral '},

    {
      value: 'DEFEITO_MOTOR_BARULHO_PARTE_SUPERIOR',
      label: 'Barulho na parte superior do motor ',
    },
    {value: 'DEFEITO_MOTOR_ROLAMENTO_ALTERNADOR', label: 'Rolamento do alternador'},
    {value: 'DEFEITO_MOTOR_RETENTOR_VOLANTE', label: 'Retentor do volante do motor'},
    {value: 'DEFEITO_MOTOR_QUALIDADE_COMBUSTIVEL', label: 'Qualidade do combustivel'},
    {
      value: 'DEFEITO_MOTOR_ANEL_VEDACAO_FILTRO_OLEO',
      label: 'Anel de vedação do filtro de óleo',
    },
    {value: 'DEFEITO_MOTOR_SUPORTE_X_RADIADOR', label: 'Suporte X "Radiador"'},
    {value: 'DEFEITO_MOTOR_BLOCO', label: 'Bloco do motor'},
    {value: 'DEFEITO_MOTOR_CABECOTE_FILTRO_RACCOR', label: 'Cabeçote do filtro raccor'},
    {
      value: 'DEFEITO_MOTOR_MANGUEIRA_AGUA_SUPERIOR',
      label: 'Mangueira de água superior do motor',
    },
    {
      value: 'DEFEITO_MOTOR_MANGUEIRA_INTERCOOLER_PRETA',
      label: 'Mangueira do intercooler preta',
    },
  ],
  TIPOASSISTENCIAPOSTRATAMENTO: [
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_QUALIDADE_ARLA',
      label: 'Qualidade do Arla',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_SENSOR_TANQUE_ARLA',
      label: 'Sensor do tanque do arla',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_DPF',
      label: 'DPF',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_CHICOTE_POS_TRATAMENTO',
      label: 'Chicote do Pós tratamento',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_SENSOR_NOX',
      label: 'Sensor de Nox',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_SENSOR_TEMPERATURA',
      label: 'Sensor de temperatura',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_CATALISADOR_COMPLETO',
      label: 'Catalisador completo',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_TUBULACAO_ARLA',
      label: 'Tubulação arla',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_DOSADORA',
      label: 'Dosadora de arla',
    },
    {
      value: 'DAF_ASSISTENCIA_POS_TRATAMENTO_BOMBA_ARLA',
      label: 'Bomba de arla',
    },
    {
      value: 'DEFEITO_REGENERACAO_ASSISTIDA',
      label: 'Regeneração assistida',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_OUTROS',
      label: 'Sistema de pós tratamento',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_VAZAMENTO_ARLA',
      label: 'Vazamento de Arla',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_SUPORTE_ARLA_SOLTO',
      label: 'Suporte do Arla solto',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_ARLA_CRISTALIZADO',
      label: 'Arla cristalizado',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_TANQUE_ARLA',
      label: 'Tanque do Arla',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_BOIA_TANQUE_ARLA',
      label: 'Boia do tanque de Arla',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_FILTRO_BOMBA_ARLA',
      label: 'Filtro da bomba do Arla',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_FILTRO_DOSADORA_ARLA',
      label: 'Filtro da dosadora de Arla',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_FILTROS_BOMBA_DOSADORA_ARLA',
      label: 'Filtros da bomba e dosadora de Arla',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_REGENERACAO_DAVIE',
      label: 'Regeneração davie',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_REGENERACAO_ESTACIONARIA',
      label: 'Regeneração estacionária',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_TUBOS_ESCAPE',
      label: 'Tubos de escape',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_MODULO_POS_TRATAMENTO',
      label: 'Módulo de pós-tratamento',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_SENSOR_DPF',
      label: 'Sensor do DPF',
    },
    {
      value: 'DEFEITO_POS_TRATAMENTO_ESCOVAMENTO_SISTEMA',
      label: 'Escovamento do sistema',
    },
    {value: 'DEFEITO_POS_TRATAMENTO_ORCAMENTO_NAO_APROVADO', label: 'Orçamento não aprovado '},
    {
      value: 'DEFEITO_POS_TRATAMENTO_SEGUIU_CONCESSIONARIA',
      label: 'Seguiu para concessionária',
    },
    {value: 'DEFEITO_POS_TRATAMENTO_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_POS_TRATAMENTO_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente '},
    {value: 'DEFEITO_POS_TRATAMENTO_RESET_CHAVE_GERAL', label: 'Reset chave geral '},
    {
      value: 'DEFEITO_POS_TRATAMENTO_MANGUEIRA_RESERVATORIO_ARLA',
      label: 'Mangueira do reservatório do arla',
    },
  ],

  TIPOASSISTENCIATRANSMISSAO: [
    {value: 'DEFEITO_CAIXA_DE_CAMBIO', label: 'Caixa de câmbio'},
    {value: 'DAF_TRANSMISSAO_SELETOR_PAINEL', label: 'Seletor do painel'},
    {value: 'DEFEITO_TRANSMISSAO_DRENAGEM_BALAO_AR', label: 'Drenagem dos balões de ar'},
    {value: 'DAF_TRANSMISSAO_BLOCO_VALVULA', label: 'Bloco de válvulas'},
    {value: 'DAF_TRANSMISSAO_BLOCO_SENSOR', label: 'Bloco de sensores'},
    {value: 'DAF_TRANSMISSAO_SELETOR_PORTA', label: 'Seletor de porta'},
    {value: 'DAF_TRANSMISSAO_AVARIA_MECANICA', label: 'Avaria mecânica'},
    {
      value: 'DAF_TRANSMISSAO_SENSOR_VELOCIDADE_TRANSMISSAO',
      label: 'Sensor velocidade da transmissão',
    },
    {
      value: 'DAF_TRANSMISSAO_SENSOR_VELOCIDADE_TACOGRAFO',
      label: 'Sensor de velocidade tacógrafo',
    },
    {
      value: 'DEFEITO_ATUADOR_DA_EMBREAGEM',
      label: 'Atuador da embreagem',
    },
    {value: 'DEFEITO_EMBREAGEM', label: 'Embreagem da transmissão'},
    {value: 'DEFEITO_MODULO_DA_CAIXA', label: 'Modulo da caixa'},
    {value: 'DEFEITO_CAPA_SECA_DA_CAIXA', label: 'Capa seca da caixa'},
    {value: 'DEFEITO_FLANGE_DE_SAIDA', label: 'Flange de saída'},
    {
      value: 'DEFEITO_TOMADA_DE_FORCA/PTO',
      label: 'Tomada de força/PTO',
    },
    {
      value: 'DEFEITO_TRANSMISSAO_OUTROS',
      label: 'Transmissão',
    },
    {
      value: 'DEFEITO_TRANSMISSAO_RUIDO_INTERNO',
      label: 'Ruído interno transmissão',
    },
    {
      value: 'DEFEITO_TRANSMISSAO_DTC_VERMELHO',
      label: 'DTC vermelho transmissão',
    },
    {
      value: 'DEFEITO_TRANSMISSAO_CHICOTE',
      label: 'Chicote da transmissão',
    },
    {
      value: 'DEFEITO_TRANSMISSAO_ROLAMENTO_VOLANTE',
      label: 'Rolamento do volante',
    },
    {
      value: 'DEFEITO_TRANSMISSAO_TRAVADA_NEUTRO',
      label: 'Transmissão travada no neutro',
    },
    {
      value: 'DEFEITO_TRANSMISSAO_MANGUEIRA_AR_TRANSMISSAO',
      label: 'Mangueira de ar da transmissão',
    },
    {value: 'DEFEITO_TRANSMISSAO_ORCAMENTO_NAO_APROVADO', label: 'Orçamento não aprovado '},
    {
      value: 'DEFEITO_TRANSMISSAO_SEGUIU_CONCESSIONARIA',
      label: 'Seguiu para concessionária',
    },
    {value: 'DEFEITO_TRANSMISSAO_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_TRANSMISSAO_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente'},
    {value: 'DEFEITO_TRANSMISSAO_RESET_CHAVE_GERAL', label: 'Reset chave geral'},
    {value: 'DEFEITO_TRANSMISSAO_CILINDRO_DA_EMBREAGEM', label: 'Cilindro da Embreagem'},
  ],
  TIPOASSISTENCIASISTEMAELETRICO: [
    {value: 'DEFEITO_ALTERNADOR', label: 'Alternador'},
    {value: 'DEFEITO_BATERIAS', label: 'Baterias'},
    {value: 'DEFEITO_CHAVE_GERAL', label: 'Chave Geral'},
    {value: 'DEFEITO_FUSIVEIS', label: 'Fusíveis'},
    {value: 'DEFEITO_RELES', label: 'Relés'},
    {
      value: 'DEFEITO_CONECTORES_ELETRICOS',
      label: 'Conectores elétricos',
    },
    {value: 'DEFEITO_CHICOTE_TRASEIRO', label: 'Chicote traseiro'},
    {value: 'DEFEITO_CHICOTE_CENTRAL', label: 'Chicote central'},
    {value: 'DEFEITO_CHICOTE_FRONTAL', label: 'Chicote frontal'},
    {value: 'DEFEITO_CHICOTE_CABINE', label: 'Chicote cabine'},
    {value: 'DEFEITO_LUZES_DIANTEIRA', label: 'Luzes dianteira'},
    {value: 'DEFEITO_LUZES_TRASEIRAS', label: 'Luzes traseiras'},
    {
      value: 'DEFEITO_CENTRAL_DE_FUSIVEIS',
      label: 'Central de fusíveis',
    },
    {
      value: 'DEFEITO_UNIDADE_CENTRAL_DE_LUZES_DIANTEIRA',
      label: 'Unidade central de luzes dianteira',
    },
    {
      value: 'DEFEITO_UNIDADE_CENTRAL_DE_LUZES_TRASEIRA',
      label: 'Unidade central de luzes traseira',
    },
    {value: 'DEFEITO_MOTOR_DE_PARTIDA', label: 'Motor de partida'},
    {value: 'DEFEITO_SISTEMA_ELETRICO_SENSOR', label: 'Sensor'},
    {
      value: 'DEFEITO_SISTEMA_ELETRICO_OUTROS',
      label: 'Sistema elétrico',
    },
    {
      value: 'DEFEITO_SISTEMA_ELETRICO_ORCAMENTO_NAO_APROVADO',
      label: 'Orçamento não aprovado ',
    },
    {
      value: 'DEFEITO_SISTEMA_ELETRICO_SEGUIU_CONCESSIONARIA',
      label: 'Seguiu para concessionária',
    },
    {value: 'DEFEITO_SISTEMA_ELETRICO_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_SISTEMA_ELETRICO_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente'},
    {value: 'DEFEITO_SISTEMA_ELETRICO_RESET_CHAVE_GERAL', label: 'Reset chave geral'},
    {value: 'DEFEIRO_SISTEMA_ELETRICO_COMUTADOR_PARTIDA', label: 'Comutador de partida'},
  ],
  TIPOASSISTENCIACABINE: [
    {value: 'DEFEITO_TACOGRAFO', label: 'Tacógrafo'},
    {
      value: 'DAF_ASSISTENCIA_CABINE_PEDAL_ACELERADOR',
      label: 'Pedal de acelerador',
    },
    {
      value: 'DEFEITO_UNIDADE_CENTRAL_DO_VEICULO',
      label: 'Unidade central do veículo',
    },
    {value: 'DEFEITO_LUZES_INTERNA', label: 'Luzes interna'},
    {
      value: 'DEFEITO_LIMPADOR_DO_PARA_BRISA',
      label: 'Limpador do para-brisa',
    },
    {value: 'DEFEITO_UNIDADE_DO_VEICULO', label: 'Unidade do veículo'},
    // {value: 'DEFEITO_RASTREADOR', label: 'Rastreador'},
    {value: 'DEFEITO_AR_CONDICIONADO', label: 'Ar condicionado'},
    {value: 'DEFEITO_GELADEIRA', label: 'Geladeira'},
    {
      value: 'DEFEITO_CABINE_OUTROS',
      label: 'Cabine',
    },
    {
      value: 'DEFEITO_CABINE_FAROL',
      label: 'Farol',
    },
    {
      value: 'DEFEITO_CABINE_PORTA',
      label: 'Porta',
    },
    {
      value: 'DEFEITO_CABINE_ENTRADA_AGUA',
      label: 'Entrada de água na cabine',
    },
    {
      value: 'DEFEITO_CABINE_ENTRADA_POEIRA',
      label: 'Entrada de poeira na cabine',
    },
    {
      value: 'DEFEITO_CABINE_BOMBA_DIRECAO',
      label: 'Bomba de direção',
    },
    {
      value: 'DEFEITO_CABINE_PINTURA',
      label: 'Pintura',
    },
    {
      value: 'DEFEITO_CABINE_RASTREADOR',
      label: 'Rastreador',
    },
    {
      value: 'DEFEITO_CABINE_CINTA_TANQUE_COMBUSTIVEL',
      label: 'Cinta do tanque de combustível',
    },
    {
      value: 'DEFEITO_CABINE_ORCAMENTO_NAO_APROVADO',
      label: 'Orçamento não aprovado ',
    },
    {
      value: 'DEFEITO_CABINE_SEGUIU_CONCESSIONARIA',
      label: 'Seguiu para concessionária',
    },
    {value: 'DEFEITO_CABINE_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_CABINE_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente'},
    {value: 'DEFEITO_CABINE_RESET_CHAVE_GERAL', label: 'Reset chave geral'},
    {value: 'DEFEITO_CABINE_TANQUE_COMBUSTIVEL', label: 'Tanque de Combustível'},
  ],

  TIPOASSISTENCIASISTEMAAR: [
    {
      value: 'DEFEITO_SUSPENSOR_EIXO',
      label: 'Suspensor do eixo',
    },
    {
      value: 'DEFEITO_VALVULA_SAC',
      label: 'Válvula SAC',
    },

    {
      value: 'DEFEITO_ENGATE_CARRETA',
      label: 'Engate de carreta',
    },
    {
      value: 'DEFEITO_AR_OUTROS',
      label: 'Sistema de ar',
    },
    {
      value: 'DEFEITO_AR_VAZAMENTO_AR_MANGUEIRAS',
      label: 'Vazamento de ar nas mangueiras',
    },
    {
      value: 'DEFEITO_AR_VAZAMENTO_AR_VALVULAS',
      label: 'Vazamento de ar nas válvulas',
    },
    {
      value: 'DEFEITO_AR_DRENAGEM_SISTEMA_AR',
      label: 'Drenagem do sistema de ar',
    },
    {
      value: 'DEFEITO_AR_DRENAGEM_BALOES_AR',
      label: 'Drenagem dos balões de ar',
    },
    {
      value: 'DEFEITO_AR_TUBULACAO_COMPRESSOR',
      label: 'Tubulação compressor até PU/SAC',
    },
    {
      value: 'DEFEITO_AR_ORCAMENTO_NAO_APROVADO',
      label: 'Orçamento não aprovado ',
    },
    {
      value: 'DEFEITO_AR_SEGUIU_CONCESSIONARIA',
      label: 'Seguiu para concessionária',
    },
    {value: 'DEFEITO_AR_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_AR_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente'},
    {value: 'DEFEITO_AR_RESET_CHAVE_GERAL', label: 'Reset chave geral'},
    {value: 'DEFEITO_AR_CONEXAO_BALAO_AR', label: 'Conexão do balão de ar'},
  ],
  TIPOASSISTENCIAEIXOTRASEIRO: [
    {value: 'DEFEITO_BARRA_EM_V', label: 'Barra em V'},
    {value: 'DEFEITO_TIRANTE', label: 'Tirante'},
    {
      value: 'DEFEITO_DIFERENCIAL',
      label: 'Diferencial',
    },
    {
      value: 'DEFEITO_SEGUNDO_DIFERENCIAL',
      label: 'Segundo Diferencial',
    },
    {value: 'DEFEITO_CARDAN_PRINCIPAL', label: 'Cardan principal'},
    {value: 'DEFEITO_CARDAN_ENTRE_EIXOS', label: 'Cardan entre eixos'},
    {
      value: 'DEFEITO_EIXO_TRASEIRO_CUBO_RODA_EIXO_DIANTEIRO',
      label: 'Cubo de roda eixo dianteiro',
    },

    {
      value: 'DEFEITO_CUBO_DE_RODA_PRIMEIRO_DIFERENCIAL',
      label: 'Cubo de roda Primeiro diferencial',
    },
    {
      value: 'DEFEITO_CUBO_DE_RODA_SEGUNDO_DIFERENCIAL',
      label: 'Cubo de roda Segundo Diferencial',
    },
    {value: 'DEFEITO_EIXO_TRASEIRO_FEIXE_DE_MOLAS', label: 'Feixe de molas'},
    {value: 'DEFEITO_EIXO_TRASEIRO_RETENTOR_DE_CUBO', label: 'Retentor de cubo'},
    {
      value: 'DEFEITO_EIXO_TRASEIRO_OUTROS',
      label: 'Eixo traseiro',
    },
    {
      value: 'DEFEITO_EIXO_TRASEIRO_PNEU_TRASEIRO_DESGASTADO',
      label: 'Pneu traseiro desgastado',
    },
    {
      value: 'DEFEITO_EIXO_TRASEIRO_ORCAMENTO_NAO_APROVADO',
      label: 'Orçamento não aprovado ',
    },
    {
      value: 'DEFEITO_EIXO_TRASEIRO_SEGUIU_CONCESSIONARIA',
      label: 'Seguiu para concessionária',
    },
    {value: 'DEFEITO_EIXO_TRASEIRO_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_EIXO_TRASEIRO_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente'},
    {value: 'DEFEITO_EIXO_TRASEIRO_RESET_CHAVE_GERAL', label: 'Reset chave geral'},
    {value: 'DEFEITO_EIXO_TRASEIRO_FOLGA_CUBO_RODA', label: 'Folga no cubo de roda'},
    {
      value: 'DEFEITO_EIXO_TRASEIRO_VAZAMENTO_OLEO_DIFERENCIAL',
      label: 'Vazamento de óleo diferencial',
    },
  ],
  TIPOASSISTENCIAEIXODIANTEIRO: [
    {value: 'DEFEITO_EIXO_DIANTEIRO_CUBO_DE_RODA', label: 'Cubo de roda'},
    {value: 'DEFEITO_CAIXA_DE_DIRECAO', label: 'Caixa de direção'},
    {
      value: 'DEFEITO_TUBULACAO_DA_DIRECAO',
      label: 'Tubulação da direção',
    },
    {
      value: 'DEFEITO_RESERVATORIO_DE_OLEO_DA_DIRECAO',
      label: 'Reservatório de óleo da direção',
    },
    {
      value: 'DEFEITO_BOMBA_HIDRAULICA_DIRECAO',
      label: 'Bomba hidráulica direção',
    },
    {value: 'DEFEITO_EIXO_DIANTEIRO_FEIXE_DE_MOLAS', label: 'Feixe de molas'},
    {value: 'DEFEITO_EIXO_DIANTEIRO_RETENTOR_DE_CUBO', label: 'Retentor de cubo'},
    {
      value: 'DEFEITO_EIXO_DIANTEIRO_OUTROS',
      label: 'Eixo dianteiro',
    },
    {
      value: 'DEFEITO_EIXO_DIANTEIRO_PNEU_DIANTEIRO_DESGASTADO',
      label: 'Pneu dianteiro desgastado',
    },
    {
      value: 'DEFEITO_EIXO_DIANTEIRO_ORCAMENTO_NAO_APROVADO',
      label: 'Orçamento não aprovado ',
    },
    {
      value: 'DEFEITO_EIXO_DIANTEIRO_SEGUIU_CONCESSIONARIA',
      label: 'Seguiu para concessionária',
    },
    {value: 'DEFEITO_EIXO_DIANTEIRO_ATUALIZACAO_SOFTWARE', label: 'Atualização do Software'},
    {value: 'DEFEITO_EIXO_DIANTEIRO_REBOCADO_PELO_CLIENTE', label: 'Rebocado pelo cliente'},
    {value: 'DEFEITO_EIXO_DIANTEIRO_RESET_CHAVE_GERAL', label: 'Reset chave geral'},
    {value: 'DEFEITO_EIXO_DIANTEIRO_FOLGA_CUBO_RODA', label: 'Folga no cubo de roda'},
    {value: 'DEFEITO_EIXO_DIANTEIRO_TERMINAL_DIRECAO', label: 'Terminal de direção'},
    {value: 'DEFEITO_EIXO_DIANTEIRO_BARRA_ESTABILIZADORA', label: 'Barra estabilizadora'},
  ],
  TIPOASSISTENCIASISTEMADEFREIO: [
    {value: 'DEFEITO_VALVULA_SAC/PU', label: 'Válvula SAC/PU'},
    {value: 'DEFEITO_COMPRESSOR_DE_AR', label: 'Compressor de ar'},
    {
      value: 'DEFEITO_TAMBOR_DE_FREIO_PRIMEIRO_EIXO_TRASEIRO',
      label: 'Tambor de freio primeiro eixo traseiro',
    },
    {
      value: 'DEFEITO_TAMBOR_DE_FREIO_SEGUNDO_EIXO_TRASEIRO',
      label: 'Tambor de freio segundo eixo traseiro',
    },
    {
      value: 'DEFEITO_TAMBOR_DE_FREIO_TRASEIRO',
      label: 'Tambor de freio traseiro',
    },
    {
      value: 'DEFEITO_TAMBOR_DE_FREIO_DIANTEIRO',
      label: 'Tambor de freio dianteiro',
    },
    {value: 'DEFEITO_VALVULAS_DE_ALIVIO', label: 'Válvulas de alivio'},
    {
      value: 'DEFEITO_VALVULA_DE_TRANSFERENCIA_DE_AR_CAVALO/CARRETA',
      label: 'Válvula de transferência de ar Cavalo/Carreta',
    },
    {
      value: 'DEFEITO_TUBULACAO_DE_FREIO_DIANTEIRA',
      label: 'Tubulação de freio dianteira',
    },
    {
      value: 'DEFEITO_TUBULACAO_DE_FREIO_TRASEIRA',
      label: 'Tubulação de freio traseira',
    },
    {value: 'DEFEITO_BALOES_DE_AR', label: 'Balões de ar'},
    {
      value: 'DEFEITO_CATRACA_DE_FREIO_TRASEIRA',
      label: 'Catraca de freio Traseira',
    },
    {
      value: 'DEFEITO_CATRACA_DE_FREIO_DIANTEIRA',
      label: 'Catraca de freio dianteira',
    },
    {value: 'DEFEITO_FALHA_ABS', label: 'Falha ABS'},
    {value: 'DEFEITO_FALHA_EBS', label: 'Falha EBS'},
    {value: 'DEFEITO_SENSOR_ABS', label: 'Sensor ABS'},
    {value: 'DEFEITO_EQUALIZACAO_FREIOS', label: 'Equalização dos freios'},
    {value: 'DEFEITO_LONAS_FREIO_DIANTEIRA', label: 'Lonas de freio dianteira'},
    {value: 'DEFEITO_LONAS_FREIO_TRASEIRA', label: 'Lonas de freio traseira'},
    {
      value: 'DEFEITO_SISTEMA_DE_FREIO_OUTROS',
      label: 'Sistema de freio',
    },
  ],
  TIPOASSISTENCIAOUTROS: [
    {
      value: 'DEFEITO_OUTROS',
      label: 'Outros',
    },
  ],
};

class NewStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conclusionTab: {
        tipoMotivo: undefined,
        tipoDefeito: undefined,
        defeito: undefined,
      },
    };
    this.getEmails = this.getEmails.bind(this);
  }

  getEmails() {
    if (
      this.props.attendanceTab.enderecoFavorito &&
      this.props.attendanceTab.enderecoFavorito.contatosEnderecoFavorito
    ) {
      return this.props.attendanceTab.enderecoFavorito.contatosEnderecoFavorito.map(
        (contato) => ({
          nivelPrioridade: contato.nivelPrioridade,
          value: contato.email,
          jobTitle: contato.cargo,
        }),
      );
    }
    return [];
  }

  addEmail() {
    email;
  }
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  handleEmail(emailList) {
    if (emailList.charAt(emailList.length - 1) === ';') {
      emailList = emailList.substring(0, emailList.length - 1);
    }
    let emails = emailList.replace(',', ';').split(';');
    const allValid = emails.every(this.validateEmail);
    return allValid;
    // return this.props.handleAtendimento({email:emailList})
  }

  isDAFUser() {
    const {login} = this.props;

    const dafId = '2322661870558778503';
    return login.user_info.empresa.codigo === dafId;
  }

  isDAFAttendence() {
    const {attendanceTab} = this.props;

    if (attendanceTab && attendanceTab.empresa && attendanceTab.empresa.codigo) {
      const dafId = '2322661870558778503';
      return attendanceTab.empresa.codigo === dafId;
    }

    return false;
  }

  render() {
    const {
      atendimento = {},
      handleAtendimento,
      putLog,
      attendanceTab,
      assistanceCode,
      finishAttendance,
      log,
    } = this.props;

    const isAssistencia =
      attendanceTab &&
      attendanceTab.tipoRequisicao &&
      attendanceTab.tipoRequisicao.codigo === 'REQUISICAOTIPOASSISTENCIA';

    let tipoMotivoReal = this.state.conclusionTab.tipoMotivo;
    if (!tipoMotivoReal && this.props.attendanceTab && this.props.attendanceTab.tipoMotivo) {
      tipoMotivoReal = tipoMotivoOptions.find(
        ({value}) => value === this.props.attendanceTab.tipoMotivo.codigo,
      );
    }

    let defeitoOptions = [];
    if (this.state.conclusionTab.tipoMotivo) {
      defeitoOptions = defeitos[this.state.conclusionTab.tipoMotivo.value];
    }
    if (tipoMotivoReal) {
      defeitoOptions = defeitos[tipoMotivoReal.value];
    }

    const {
      willSleep,
      mecanicoChegou,
      estagio,
      sendMail,
      email,
      adormecerAte,
      dataAgendado,
      kmTotalRodadoMecanico,
      kmAtualValidado,
    } = atendimento;

    const tipoRequisicao =
      attendanceTab && attendanceTab.tipoRequisicao && attendanceTab.tipoRequisicao.codigo;
    const estagios = getEstagios();

    let estagiosFormatted = [];

    if (tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO') {
      estagiosFormatted = [
        {label: 'Validado', value: 'ESTAGIOVALIDADO', isDisabled: true},
        {label: '1. Agendando', value: 'AGENDANDO'},
        {label: '2. Agendado', value: 'ESTAGIOOPERADOR'},
        {label: '3. Concluído', value: 'CONCLUIDO'},
      ];
    } else if (
      tipoRequisicao === 'REQUISICAOTIPOINFORMACAO' ||
      tipoRequisicao === 'REQUISICAOTIPOSUPORTE'
    ) {
      estagiosFormatted = [
        {label: 'Validado', value: 'ESTAGIOVALIDADO', isDisabled: true},
        {label: 'Operador', value: 'ESTAGIOOPERADOR'},
        {label: 'Concluído', value: 'CONCLUIDO'},
      ];
    } else if (tipoRequisicao === 'REQUISICAOTIPORECUSA') {
      estagiosFormatted = [
        {label: 'Validado', value: 'ESTAGIOVALIDADO', isDisabled: true},
        {label: 'Operador', value: 'ESTAGIOOPERADOR'},
        {label: 'Concluído', value: 'CONCLUIDO'},
      ];
    } else {
      estagiosFormatted = estagios.map((estagio) => {
        if (estagio.value === 'ESTAGIOVALIDADO') {
          if (!estagio.isDisabled) {
            estagio.label = `${estagio.label} (Status adicionado ao abrir o atendimento)`;
          }
          estagio.isDisabled = true;
        }

        return estagio;
      });
    }
    const options = estagiosFormatted;
    const estagioSelected = options.filter((option) => option.value === estagio);
    const estagioSelectedValue =
      Array.isArray(estagioSelected) && estagioSelected.length && estagioSelected[0].value;

    const fieldEmail = {
      title: 'Enviar e-mail para',
      placeholder: 'example@gosoon.co',
    };
    if (
      (tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
        estagioSelectedValue === 'ESTAGIOOPERADOR') ||
      estagioSelectedValue === 'ESTAGIOMECANICO'
    ) {
      fieldEmail.title = 'Enviar e-mail para concessionária';
      fieldEmail.placeholder = 'Adicionar e-mail em cópia (cc)';
    }

    Array.isArray(estagioSelected) && estagioSelected.length && estagioSelected[0].value;
    console.log(this.props.currentEstagio);
    const isShowForm =
      tipoRequisicao === 'REQUISICAOTIPOASSISTENCIA' &&
      estagioSelectedValue === 'CONCLUIDO' &&
      (this.props.currentEstagio.codigo === 'ESTAGIOOPERADOR' ||
        this.props.currentEstagio.codigo === 'ESTAGIOMECANICO' ||
        this.props.currentEstagio.codigo === 'ESTAGIOTELECONFERENCIA');

    let showFieldEmail =
      (estagioSelectedValue === 'CONCLUIDO' &&
        tipoRequisicao === 'REQUISICAOTIPOINFORMACAO') ||
      (estagioSelectedValue !== 'AGENDANDO' && estagioSelectedValue !== 'CONCLUIDO');

    if (
      tipoRequisicao === 'REQUISICAOTIPOASSISTENCIA' &&
      estagioSelectedValue !== 'ESTAGIOMECANICO' &&
      estagioSelectedValue !== 'ESTAGIOGUINCHO'
    ) {
      showFieldEmail = false;
    }
    if (tipoRequisicao === 'REQUISICAOTIPORECUSA' && estagioSelectedValue === 'CONCLUIDO') {
      showFieldEmail = false;
    }
    return (
      <div>
        <CustomModalWithoutTabIndex
          title={'Novo Histórico de Atendimento'}
          customClass={isShowForm ? 'modal-test' : ''}
          context={MODAL_NAME}>
          <div style={{display: 'flex', gap: '24px'}}>
            <div
              style={{
                width: isShowForm ? '50%' : '100%',
              }}>
              <div className="row">
                <div className="col-sm-12 margin-bottom-15">
                  <label>Estágio do Atendimento</label>
                  <Select
                    options={options}
                    placeholder="Selecione um estágio"
                    onChange={(e) => handleAtendimento({estagio: e.value})}
                    value={estagioSelected}
                    styles={selectCustomStyle}
                  />
                </div>
              </div>
              {tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                estagioSelectedValue === 'ESTAGIOOPERADOR' && (
                  <div className="row">
                    <div className="col-sm-12 margin-bottom-15">
                      <label>Data e horário do agendamento</label>
                      <input
                        type="datetime-local"
                        tabIndex="3"
                        placeholder=""
                        value={dataAgendado}
                        className="form-control"
                        onChange={(e) =>
                          handleAtendimento({
                            dataAgendado: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )}

              {!(
                (tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                  estagioSelectedValue === 'ESTAGIOOPERADOR') ||
                estagioSelectedValue === 'CONCLUIDO'
              ) && (
                <div className="row">
                  <div className="col-sm-12 margin-bottom-15">
                    <input
                      type="checkbox"
                      checked={willSleep}
                      onChange={(e) => handleAtendimento({willSleep: !willSleep})}
                      value={willSleep}
                    />
                    <label>Adormecer até</label>
                    <input
                      type="datetime-local"
                      tabIndex="3"
                      placeholder=""
                      className="form-control"
                      disabled={!willSleep}
                      value={adormecerAte}
                      onChange={(e) => handleAtendimento({adormecerAte: e.target.value})}
                      format={moment().local().format('YYYY-MM-DD HH:mm')}
                    />
                  </div>
                </div>
              )}

              {showFieldEmail && (
                <div className="row">
                  <div className="col-sm-12 margin-bottom-15">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleAtendimento({
                          sendMail: !sendMail,
                        })
                      }
                      value={sendMail}
                      checked={sendMail}
                    />
                    <label>{fieldEmail.title}</label>
                    <input
                      type="email"
                      placeholder={fieldEmail.placeholder}
                      className="form-control"
                      disabled={!sendMail}
                      onChange={(e) => handleAtendimento({email: e.target.value})}
                      value={email}
                    />
                    <FastButton
                      labels={this.getEmails()}
                      btnClick={(value) => {
                        sendMail ? handleAtendimento({email: `${email} ${value};`}) : null;
                      }}
                    />
                  </div>
                </div>
              )}

              {estagioSelectedValue === 'ESTAGIOMECANICO' && (
                <div>
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleAtendimento({
                            mecanicoChegou: !mecanicoChegou,
                          })
                        }
                        value={mecanicoChegou}
                        checked={mecanicoChegou}
                      />
                      <label>
                        Mecânico chegou?{' '}
                        {attendanceTab.kmAtualValidado || attendanceTab.kmTotalRodadoMecanico
                          ? 'SIM'
                          : 'NÃO'}
                      </label>
                    </div>
                  </div>

                  <div className="row margin-bottom-15">
                    <div className="col-sm-6">
                      <input
                        type="number"
                        placeholder="Confirmação km cliente"
                        className="form-control"
                        disabled={!mecanicoChegou}
                        onChange={(e) => handleAtendimento({kmAtualValidado: e.target.value})}
                        value={kmAtualValidado || attendanceTab.kmAtualValidado}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        placeholder="Km que mecânico percorreu"
                        className="form-control col-md-6"
                        disabled={!mecanicoChegou}
                        onChange={(e) =>
                          handleAtendimento({kmTotalRodadoMecanico: e.target.value})
                        }
                        value={kmTotalRodadoMecanico || attendanceTab.kmTotalRodadoMecanico}
                      />
                    </div>
                  </div>
                </div>
              )}

              {estagioSelectedValue === 'CONCLUIDO' &&
                !isFromCompany(CODIGO_KOVI) &&
                isAssistencia && (
                  <div>
                    <div className="row margin-bottom-15">
                      <div className="col-sm-12">
                        <label>Motivo do atendimento</label>
                        <Select
                          options={
                            this.isDAFUser() || this.isDAFAttendence()
                              ? tipoMotivoDAFOptions
                              : tipoMotivoOptions
                          }
                          placeholder="Selecione o tipo de assistência"
                          styles={{margin: '5px 0', ...selectCustomStyle}}
                          onChange={(tipoMotivo) => {
                            this.setState({
                              conclusionTab: {
                                ...this.state.conclusionTab,
                                tipoMotivo: tipoMotivo,
                                tipoDefeito: null,
                              },
                            });
                          }}
                          value={tipoMotivoReal}
                        />
                      </div>
                    </div>
                    <div className="row margin-bottom-15">
                      <div className="col-sm-12">
                        <label>Tipo do defeito</label>

                        <Select
                          options={defeitoOptions}
                          placeholder="Selecione o tipo do defeito"
                          onChange={(tipoDefeito) => {
                            this.setState({
                              conclusionTab: {
                                ...this.state.conclusionTab,
                                tipoDefeito,
                              },
                            });
                          }}
                          styles={{margin: '5px 0', ...selectCustomStyle}}
                          value={this.state.conclusionTab.tipoDefeito}
                        />
                      </div>
                    </div>
                    <div className="row margin-bottom-15">
                      <div className="col-sm-12">
                        <label>Solução do atendimento com o código das peças</label>

                        <textarea
                          rows="5"
                          cols="50"
                          style={{width: '100%', fontSize: '15px'}}
                          onChange={(e) => {
                            this.setState({
                              conclusionTab: {
                                ...this.state.conclusionTab,
                                defeito: e.target.value,
                              },
                            });
                          }}
                          value={this.state.conclusionTab.defeito}
                        />
                      </div>
                    </div>
                  </div>
                )}

              <div className="pull-right margin-bottom-15">
                <button
                  className="btn btn-primary"
                  onClick={async () => {
                    if (sendMail) {
                      const isValid = this.handleEmail(email);
                      if (!isValid) {
                        toastr.error('Um ou mais emails não são válidos', 'Erro de validação');
                        return;
                      }
                    }
                    // return;
                    if (estagioSelectedValue === 'CONCLUIDO') {
                      if (
                        tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                        !attendanceTab.dataAgendado
                      ) {
                        const canGenerate = await Swal.fire({
                          title:
                            'Tem certeza que deseja concluir o atendimento sem informar a data do agendamento?',
                          showCancelButton: true,
                          icon: 'warning',
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Concluir',
                        })
                          .then((value) => value.dismiss !== 'cancel')
                          .catch((error) => console.log(error));

                        if (!canGenerate) {
                          return;
                        }
                      }
                      let rest;
                      try {
                        rest = {
                          tipoMotivo: tipoMotivoReal.value,
                          tipoDefeito: this.state.conclusionTab.tipoDefeito.value,
                          partNumber: this.state.conclusionTab.defeito,
                        };
                      } catch (error) {}

                      finishAttendance(assistanceCode, log, email, _, rest)
                        .then((res) => {
                          if (res.sucesso) {
                            doCloseModal(MODAL_NAME);
                            doCloseModal(MODAL_NAME_IFRAME);
                            browserHistory.push(`/assistance`);
                          }
                        })
                        .catch((error) => console.log(error));
                    } else {
                      if (
                        tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                        estagioSelectedValue === 'ESTAGIOOPERADOR' &&
                        !dataAgendado
                      ) {
                        toastr.error(
                          'É necessário informar a data do agendamento',
                          'Erro de validação',
                        );
                        return;
                      }

                      await putLog().then((res) => {
                        if ((res && res.sucesso) || !res) {
                          doCloseModal(MODAL_NAME);
                          doCloseModal(MODAL_NAME_IFRAME);
                        }
                      });
                    }
                  }}>
                  {estagioSelectedValue === 'CONCLUIDO' ? 'Concluir' : 'Salvar'}
                </button>
              </div>
            </div>
            {isShowForm && (
              <div>
                <iframe
                  src="https://forms.office.com/Pages/ResponsePage.aspx?id=hc2i_s659EeeJN6QNpvDbfNIp0sSIwZMnLJ1xO7pTghUN0NBSzhCNVlQVVc2VjhXV0Y0MVZUM1pVOSQlQCN0PWcu"
                  height="680px"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  align="left"
                  width="680px"
                  className="modal-iframe">
                  carregando...
                </iframe>
              </div>
            )}
          </div>
        </CustomModalWithoutTabIndex>
      </div>
    );
  }
}

const mountLogList = (logList, isScreenAtendimento) =>
  logList
    .sort(
      (a, b) =>
        moment(b.dataCadastro, 'DD/MM/YYYY HH:mm:ss') -
        moment(a.dataCadastro, 'DD/MM/YYYY HH:mm:ss'),
    )
    .map((log, i) => {
      const direction = log.usuario.login === getUserInfo().usuario.login ? 'right' : 'left';

      return (
        <div className="chat-element" key={'log' + i}>
          <div
            style={{
              display: 'flex',
              flexDirection: direction === 'right' ? 'row-reverse' : 'row',
            }}>
            <div
              className="image-cntainer"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <a href="#" className={`float-${direction}`}>
                <img
                  alt="image"
                  className="rounded-circle"
                  src={log.usuario.linkFotoPerfil ? log.usuario.linkFotoPerfil : userIcon}
                />
              </a>
              {isScreenAtendimento && (
                <IconBadge
                  customType={{
                    color: 'success',
                    icon: '',
                    description: log.estagio,
                  }}
                />
              )}
            </div>

            <div className={`media-body text-${direction}`}>
              <strong>{log.usuario.nome}</strong>
              <p className="m-b-xs">{log.descricao}</p>
              {log.visibilidade && log.visibilidade.codigo === HISTORICO_MODO_PRIVADO && (
                <small className="text-warning" style={{marginRight: '4px'}}>
                  Privada
                </small>
              )}
              <small className="text-muted">
                {moment(log.dataCadastro, 'DD/MM/YYYY HH:mm').format('LLLL')}
              </small>
            </div>
          </div>
          <small className={`text-${direction} pull-left`}>
            {log.email && (
              <div>
                <small className="text-muted" style={{marginLeft: 5, marginTop: 7}}>
                  * E-mail enviado para: {log.email}
                </small>
              </div>
            )}
            {log.adormecerAte && (
              <div>
                <small className="text-muted" style={{marginLeft: 5, marginTop: 7}}>
                  * Adormecido até{' '}
                  {moment(log.adormecerAte, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}
                </small>
              </div>
            )}
          </small>
        </div>
      );
    });

const getUserInfo = () => JSON.parse(localStorage.getItem('user_info'));

const confirmPublicLog = (callback, log) => {
  Swal.fire({
    title: 'Tem certeza que deseja enviar um histórico público?',
    text:
      'O histórico público ficará disponível para todos os usuários da plataforma, inclusive a empresa.',
    showCancelButton: true,
    icon: 'warning',
    reverseButtons: true,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Enviar',
    input: 'checkbox',
    inputValue: 0,
    inputPlaceholder: 'Estou ciente que o histórico será público',
    inputValidator: (result) => {
      if (!result) {
        return 'Você precisa confirmar que está ciente que o histórico será público';
      } else {
        callback(log);
      }
    },
  });
};

class RelatoriosLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  onChangeModal(modal) {
    this.setState({showModal: modal});
  }

  render() {
    const {
      putLog,
      logList,
      handleLog,
      log,
      onKeyUp,
      visibility,
      onChangeVisibility,
      atendimento,
      handleAtendimento,
      isScreenAtendimento,
      currentEstagio = {descricao: ''},
      attendanceTab,
      assistanceCode,
      finishAttendance,
      login,
      disableButton = false,
    } = this.props;

    const tipoRequisicao =
      attendanceTab && attendanceTab.tipoRequisicao && attendanceTab.tipoRequisicao.codigo;
    tipoRequisicao;

    const estagioFormatted =
      tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
      attendanceTab &&
      attendanceTab.dataAgendado
        ? `Agendado para ${attendanceTab.dataAgendado}`
        : currentEstagio.descricao;

    return (
      <div className="col-md-12" style={{marginBottom: '10px'}}>
        {isScreenAtendimento && (
          <div className="pull-right margin-bottom-15">
            <h4>
              Estágio atual: &nbsp;
              <IconBadge
                customType={{
                  color: 'primary',
                  icon: '',
                  description: estagioFormatted,
                }}
              />
            </h4>
          </div>
        )}
        <NewStatusModal
          atendimento={atendimento}
          handleAtendimento={handleAtendimento}
          putLog={putLog}
          attendanceTab={attendanceTab}
          assistanceCode={assistanceCode}
          finishAttendance={finishAttendance}
          currentEstagio={currentEstagio}
          log={log}
          login={login}
        />
        <div className="ibox logChat">
          <div className="ibox-content">
            <div>
              <div className="chat-activity-list">
                {mountLogList(logList ? logList : [], isScreenAtendimento)}
              </div>
            </div>
          </div>
          {!hasBaseProfile() && (
            <div className="chat-form logChat-input">
              <div className="form-group">
                <div className="col-md-11">
                  <textarea
                    onKeyUp={log.length <= 4000 ? onKeyUp : null}
                    className="form-control"
                    placeholder="Ctrl + enter para enviar mensagem"
                    value={log}
                    onChange={handleLog}
                  />
                </div>
                <div className="col-md-1" style={{display: 'flex', gap: '8px'}}>
                  <button
                    disabled={disableButton ? true : log.length > 4000}
                    className="btn btn-sm btn-primary m-t-n-xs btn-chat"
                    id="focusButton"
                    onClick={() => {
                      if (log) {
                        if (isScreenAtendimento) {
                          doOpenModal(MODAL_NAME);
                          doOpenModal(MODAL_NAME_IFRAME);
                        } else if (
                          visibility === HISTORICO_MODO_PUBLICO &&
                          !hasCompanyProfile()
                        ) {
                          confirmPublicLog(() => putLog(), log);
                        } else {
                          putLog();
                        }
                      } else {
                        toastr.error(
                          'É necessário escrever alguma mensagem para criar um histórico',
                          'Erro de validação',
                        );
                      }
                    }}>
                    Enviar
                  </button>
                </div>

                {visibility &&
                  !hasCompanyProfile() &&
                  hasAccessToPublicHistoryPost() &&
                  !hasBaseProfile() && (
                    <div style={{marginTop: '5px'}}>
                      <label
                        className="form-check-label"
                        style={{marginLeft: '15px', marginTop: '5px'}}>
                        <input
                          type="radio"
                          className="form-check-input"
                          name="visibility"
                          value={HISTORICO_MODO_PRIVADO}
                          onChange={onChangeVisibility}
                          checked={visibility === HISTORICO_MODO_PRIVADO}
                        />
                        <span>Privada</span>
                      </label>
                      <label
                        className="form-check-label"
                        style={{marginLeft: '15px', marginTop: '5px'}}>
                        <input
                          type="radio"
                          name="visibility"
                          className="form-check-input"
                          value={HISTORICO_MODO_PUBLICO}
                          onChange={onChangeVisibility}
                          checked={visibility === HISTORICO_MODO_PUBLICO}
                        />
                        <span>Pública</span>
                      </label>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attendanceTab: state.attendance.attendanceTab,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAttendance: (code, stats, doAfterSucess) => {
      dispatch(finishAttendance(code, stats, doAfterSucess));
    },
  };
};

RelatoriosLog.propTypes = {
  visibility: PropTypes.string,
  log: PropTypes.string.isRequired,
  handleLog: PropTypes.func,
  putLog: PropTypes.func.isRequired,
  currentEstagio: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatoriosLog);
