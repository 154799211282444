import React from 'react';
import {connect} from 'react-redux';
import {
  onChangeFailureDescription,
  setAttendanceTypeOfRevision,
  onChangeActualKm,
  setAttendanceChargeType,
} from '../../actions/attendence_actions';
import './failureTab.css';
import Select from 'react-select';

export const tipoCargaOptions = [
  {label: 'Açaí', value: 'TIPO_CARGA_ACAI'},
  {label: 'Aço', value: 'TIPO_CARGA_ACO'},
  {label: 'Açúcar', value: 'TIPO_CARGA_ACUCAR'},
  {label: 'Adubo', value: 'TIPO_CARGA_ADUBO'},
  {label: 'Água', value: 'TIPO_CARGA_AGUA'},
  {label: 'Álcool', value: 'TIPO_CARGA_ALCOOL'},
  {label: 'Algodão', value: 'TIPO_CARGA_ALGODAO'},
  {label: 'Alimentício', value: 'TIPO_CARGA_ALIMENTICIO'},
  {label: 'Amianto', value: 'TIPO_CARGA_AMIANTO'},
  {label: 'Areia', value: 'TIPO_CARGA_AREIA'},
  {label: 'Argamassa', value: 'TIPO_CARGA_ARGAMASSA'},
  {label: 'Argila', value: 'TIPO_CARGA_ARGILA'},
  {label: 'Arroz', value: 'TIPO_CARGA_ARROZ'},
  {label: 'Azeite', value: 'TIPO_CARGA_AZEITE'},
  {label: 'Baia para cavalo', value: 'TIPO_CARGA_BAIA_PARA_CAVALO'},
  {label: 'Barras de ferro', value: 'TIPO_CARGA_BARRAS_DE_FERRO'},
  {label: 'Barro', value: 'TIPO_CARGA_BARRO'},
  {label: 'Baterias', value: 'TIPO_CARGA_BATERIAS'},
  {label: 'baú', value: 'TIPO_CARGA_BAU'},
  {label: 'Bebidas', value: 'TIPO_CARGA_BEBIDAS'},
  {label: 'Bobina de aço', value: 'TIPO_CARGA_BOBINA_DE_ACO'},
  {label: 'Bobinas', value: 'TIPO_CARGA_BOBINAS'},
  {label: 'Brita', value: 'TIPO_CARGA_BRITA'},
  {label: 'Caçamba', value: 'TIPO_CARGA_CACAMBA'},
  {label: 'Caçamba bitrem', value: 'TIPO_CARGA_CACAMBA_BITREM'},
  {label: 'Café', value: 'TIPO_CARGA_CAFE'},
  {label: 'Cal', value: 'TIPO_CARGA_CAL'},
  {label: 'Cal líquido', value: 'TIPO_CARGA_CAL_LIQUIDO'},
  {label: 'Calcário', value: 'TIPO_CARGA_CALCARIO'},
  {label: 'Câmara fria', value: 'TIPO_CARGA_CAMARA_FRIA'},
  {label: 'Carga Refrigerada', value: 'TIPO_CARGA_CARGA_REFRIGERADA'},
  {label: 'Carga Seca', value: 'TIPO_CARGA_CARGA_SECA'},
  {label: 'Carga Viva', value: 'TIPO_CARGA_CARGA_VIVA'},
  {label: 'Carne', value: 'TIPO_CARGA_CARNE'},
  {label: 'Carne Refrigerada', value: 'TIPO_CARGA_CARNE_REFRIGERADA'},
  {label: 'Carne tipo Charque', value: 'TIPO_CARGA_CARNE_TIPO_CHARQUE'},
  {label: 'Carvão', value: 'TIPO_CARGA_CARVAO'},
  {label: 'Catchup e Maionese', value: 'TIPO_CARGA_CATCHUP_E_MAIONESE'},
  {label: 'Cavaco', value: 'TIPO_CARGA_CAVACO'},
  {label: 'cavaco madeira', value: 'TIPO_CARGA_CAVACO_MADEIRA'},
  {label: 'Cebola', value: 'TIPO_CARGA_CEBOLA'},
  {label: 'Cegonha', value: 'TIPO_CARGA_CEGONHA'},
  {label: 'Celulose', value: 'TIPO_CARGA_CELULOSE'},
  {label: 'Cevada', value: 'TIPO_CARGA_CEVADA'},
  {label: 'Chapa de aço', value: 'TIPO_CARGA_CHAPA_DE_ACO'},
  {label: 'Cianeto', value: 'TIPO_CARGA_CIANETO'},
  {label: 'Cimento', value: 'TIPO_CARGA_CIMENTO'},
  {label: 'Colheitadeira', value: 'TIPO_CARGA_COLHEITADEIRA'},
  {label: 'Combustível', value: 'TIPO_CARGA_COMBUSTIVEL'},
  {label: 'Compostagem', value: 'TIPO_CARGA_COMPOSTAGEM'},
  {label: 'Composto orgânico', value: 'TIPO_CARGA_COMPOSTO_ORGANICO'},
  {label: 'Concreto', value: 'TIPO_CARGA_CONCRETO'},
  {label: 'Congelados', value: 'TIPO_CARGA_CONGELADOS'},
  {label: 'Container', value: 'TIPO_CARGA_CONTAINER'},
  {label: 'Coque', value: 'TIPO_CARGA_COQUE'},
  {label: 'Cosmético', value: 'TIPO_CARGA_COSMETICO'},
  {label: 'Couro', value: 'TIPO_CARGA_COURO'},
  {label: 'Detergente', value: 'TIPO_CARGA_DETERGENTE'},
  {label: 'Diversos', value: 'TIPO_CARGA_DIVERSOS'},
  {label: 'Eixos de caminhão', value: 'TIPO_CARGA_EIXOS_DE_CAMINHAO'},
  {label: 'Eletrodomesticos', value: 'TIPO_CARGA_ELETRODOMESTICOS'},
  {label: 'Eletrônicos', value: 'TIPO_CARGA_ELETRONICOS'},
  {label: 'Embalagens', value: 'TIPO_CARGA_EMBALAGENS'},
  {label: 'Empilhadeira', value: 'TIPO_CARGA_EMPILHADEIRA'},
  {label: 'Ensacados', value: 'TIPO_CARGA_ENSACADOS'},
  {label: 'Enxofre', value: 'TIPO_CARGA_ENXOFRE'},
  {label: 'Escoria', value: 'TIPO_CARGA_ESCORIA'},
  {label: 'Esponja', value: 'TIPO_CARGA_ESPONJA'},
  {label: 'Esterco', value: 'TIPO_CARGA_ESTERCO'},
  {label: 'Eucalipto', value: 'TIPO_CARGA_EUCALIPTO'},
  {label: 'Farelo', value: 'TIPO_CARGA_FARELO'},
  {label: 'Farelo de soja', value: 'TIPO_CARGA_FARELO_DE_SOJA'},
  {label: 'Farinha', value: 'TIPO_CARGA_FARINHA'},
  {label: 'Feijão', value: 'TIPO_CARGA_FEIJAO'},
  {label: 'Ferragens', value: 'TIPO_CARGA_FERRAGENS'},
  {label: 'Ferro', value: 'TIPO_CARGA_FERRO'},
  {label: 'Fertilizante', value: 'TIPO_CARGA_FERTILIZANTE'},
  {label: 'Florestal', value: 'TIPO_CARGA_FLORESTAL'},
  {label: 'Fracionado', value: 'TIPO_CARGA_FRACIONADO'},
  {label: 'frango', value: 'TIPO_CARGA_FRANGO'},
  {label: 'Frigorifico', value: 'TIPO_CARGA_FRIGORIFICO'},
  {label: 'Frutas', value: 'TIPO_CARGA_FRUTAS'},
  {label: 'Gás', value: 'TIPO_CARGA_GAS'},
  {label: 'Gesso', value: 'TIPO_CARGA_GESSO'},
  {label: 'Gordura animal', value: 'TIPO_CARGA_GORDURA_ANIMAL'},
  {label: 'Grade baixa', value: 'TIPO_CARGA_GRADE_BAIXA'},
  {label: 'Graneleiro', value: 'TIPO_CARGA_GRANELEIRO'},
  {label: 'Grãos', value: 'TIPO_CARGA_GRAOS'},
  {label: 'Inflamável', value: 'TIPO_CARGA_INFLAMAVEL'},
  {
    label: 'Insumo para fabricação de pneus',
    value: 'TIPO_CARGA_INSUMO_PARA_FABRICACAO_DE_PNEUS',
  },
  {label: 'itens de aço e motor elétrico', value: 'TIPO_CARGA_ITENS_DE_ACO_E_MOTOR_ELETRICO'},
  {label: 'Legumes', value: 'TIPO_CARGA_LEGUMES'},
  {label: 'Leite', value: 'TIPO_CARGA_LEITE'},
  {label: 'Líquido', value: 'TIPO_CARGA_LIQUIDO'},
  {label: 'Lixo', value: 'TIPO_CARGA_LIXO'},
  {label: 'Madeira', value: 'TIPO_CARGA_MADEIRA'},
  {label: 'Malte', value: 'TIPO_CARGA_MALTE'},
  {label: 'Mangueiras', value: 'TIPO_CARGA_MANGUEIRAS'},
  {label: 'Manta e tapete', value: 'TIPO_CARGA_MANTA_E_TAPETE'},
  {label: 'Máquina Agrícola', value: 'TIPO_CARGA_MAQUINA_AGRICOLA'},
  {label: 'Máquina de escavação', value: 'TIPO_CARGA_MAQUINA_DE_ESCAVACAO'},
  {label: 'Maquinário', value: 'TIPO_CARGA_MAQUINARIO'},
  {label: 'Matéria prima', value: 'TIPO_CARGA_MATERIA_PRIMA'},
  {label: 'Material picado', value: 'TIPO_CARGA_MATERIAL_PICADO'},
  {label: 'Material reciclável', value: 'TIPO_CARGA_MATERIAL_RECICLAVEL'},
  {label: 'MDF', value: 'TIPO_CARGA_MDF'},
  {label: 'Medicamento', value: 'TIPO_CARGA_MEDICAMENTO'},
  {label: 'Meia carga', value: 'TIPO_CARGA_MEIA_CARGA'},
  {label: 'Milheto', value: 'TIPO_CARGA_MILHETO'},
  {label: 'Milho', value: 'TIPO_CARGA_MILHO'},
  {label: 'Minério', value: 'TIPO_CARGA_MINERIO'},
  {label: 'Mix produtos', value: 'TIPO_CARGA_MIX_PRODUTOS'},
  {label: 'Móveis', value: 'TIPO_CARGA_MOVEIS'},
  {label: 'Não informado', value: 'TIPO_CARGA_NAO_INFORMADO'},
  {label: 'Óleo', value: 'TIPO_CARGA_OLEO'},
  {label: 'Óleo lubrificante', value: 'TIPO_CARGA_OLEO_LUBRIFICANTE'},
  {label: 'Óleo Vegetal', value: 'TIPO_CARGA_OLEO_VEGETAL'},
  {label: 'Outros', value: 'TIPO_CARGA_OUTROS'},
  {label: 'Palete', value: 'TIPO_CARGA_PALETE'},
  {label: 'Peça de carro', value: 'TIPO_CARGA_PECA_DE_CARRO'},
  {label: 'Pedra de Mármore', value: 'TIPO_CARGA_PEDRA_DE_MARMORE'},
  {label: 'Perecível', value: 'TIPO_CARGA_PERECIVEL'},
  {label: 'Petróleo bruto', value: 'TIPO_CARGA_PETROLEO_BRUTO'},
  {label: 'Pisos', value: 'TIPO_CARGA_PISOS'},
  {label: 'Pluma', value: 'TIPO_CARGA_PLUMA'},
  {label: 'Pneus', value: 'TIPO_CARGA_PNEUS'},
  {label: 'Pó de Pedra', value: 'TIPO_CARGA_PO_DE_PEDRA'},
  {label: 'polietileno', value: 'TIPO_CARGA_POLIETILENO'},
  {label: 'Potassio', value: 'TIPO_CARGA_POTASSIO'},
  {label: 'Prancha', value: 'TIPO_CARGA_PRANCHA'},
  {label: 'Querosene', value: 'TIPO_CARGA_QUEROSENE'},
  {label: 'Químico', value: 'TIPO_CARGA_QUIMICO'},
  {label: 'Ração', value: 'TIPO_CARGA_RACAO'},
  {label: 'Raspa de Couro', value: 'TIPO_CARGA_RASPA_DE_COURO'},
  {label: 'Sabão em pó', value: 'TIPO_CARGA_SABAO_EM_PO'},
  {label: 'Sal', value: 'TIPO_CARGA_SAL'},
  {label: 'Sandálias', value: 'TIPO_CARGA_SANDALIAS'},
  {label: 'Sementes', value: 'TIPO_CARGA_SEMENTES'},
  {label: 'Sider', value: 'TIPO_CARGA_SIDER'},
  {label: 'Sider Bebidas', value: 'TIPO_CARGA_SIDER_BEBIDAS'},
  {label: 'Soja', value: 'TIPO_CARGA_SOJA'},
  {label: 'Sorgo', value: 'TIPO_CARGA_SORGO'},
  {label: 'Sucata', value: 'TIPO_CARGA_SUCATA'},
  {label: 'Suco fresco', value: 'TIPO_CARGA_SUCO_FRESCO'},
  {label: 'Suínos', value: 'TIPO_CARGA_SUINOS'},
  {label: 'Tanque', value: 'TIPO_CARGA_TANQUE'},
  {label: 'Tinta', value: 'TIPO_CARGA_TINTA'},
  {label: 'Trigo', value: 'TIPO_CARGA_TRIGO'},
  {label: 'Tubo de aço', value: 'TIPO_CARGA_TUBO_DE_ACO'},
  {label: 'Ureia', value: 'TIPO_CARGA_UREIA'},
  {label: 'Vasilhame', value: 'TIPO_CARGA_VASILHAME'},
  {label: 'Vasos', value: 'TIPO_CARGA_VASOS'},
  {label: 'Vazio', value: 'TIPO_CARGA_VAZIO'},
  {label: 'Vazio Atrelado', value: 'TIPO_CARGA_VAZIO_ATRELADO'},
  {label: 'Vazio Caçamba', value: 'TIPO_CARGA_VAZIO_CACAMBA'},
  {label: 'Vazio Graneleiro', value: 'TIPO_CARGA_VAZIO_GRANELEIRO'},
  {label: 'Vazio Porta container', value: 'TIPO_CARGA_VAZIO_PORTA_CONTAINER'},
  {label: 'Vazio Prancha', value: 'TIPO_CARGA_VAZIO_PRANCHA'},
  {label: 'Verduras', value: 'TIPO_CARGA_VERDURAS'},
  {label: 'Vergalhão', value: 'TIPO_CARGA_VERGALHAO'},
  {label: 'Vidros', value: 'TIPO_CARGA_VIDROS'},
  {label: 'Vinho', value: 'TIPO_CARGA_VINHO'},
];

class FailureTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      failure: '',
      tipoMotivoCarga: {
        label: 'Selecione o tipo de carga',
        value: '',
      },
    };
  }
  componentDidMount() {
    this.setDafCarga();
  }

  setDafCarga() {
    const {cargaDaf} = this.props;
    if (cargaDaf) {
      this.setState((prevState) => ({
        tipoMotivoCarga: {
          ...prevState.tipoMotivoCarga,
          label: cargaDaf.descricao,
          value: cargaDaf.codigo,
        },
      }));
    }
  }
  isDAFSelected() {
    const {company} = this.props;

    if (company && company.value) {
      const dafId = '2322661870558778503';
      return company.value === dafId;
    }

    return false;
  }

  render() {
    const {onChangeFailure, onChangeRevisionType, veiculoCliente} = this.props;

    return (
      <div className="row">
        <div className="col-sm-12 base-register-container">
          <div className="form-group">
            {veiculoCliente && (
              <div>
                <div className="col-sm-4">
                  <label className="font-normal p-w-xs">Km Atual</label>
                  <div style={{marginBottom: '20px'}} className="input-group">
                    <span className="input-group-addon">
                      <i className="far fa-id-card" />
                    </span>
                    <div className="input-group-append">
                      <input
                        type="number"
                        value={this.props.actualKm}
                        onChange={(event) => this.props.onChangeActualKm(event.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                {this.props.attendanceTab.tipoCarga ? (
                  <div className="col-sm-4">
                    <label className="font-normal p-w-xs">Tipo de Carga</label>
                    <div style={{marginBottom: '20px'}} className="input-group">
                      <span className="input-group-addon">
                        <i className="fas fa-truck" />
                      </span>
                      <div className="input-group-append">
                        <input
                          type="text"
                          value={this.props.tipoCarga}
                          onChange={(event) =>
                            this.props.onChangeChargeType(event.target.value)
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                ) : this.isDAFSelected() ? (
                  <div className="col-sm-4">
                    <label className="font-normal p-w-xs">Tipo de Carga:</label>
                    <div style={{marginBottom: '20px'}} className="input-group">
                      <span className="input-group-addon">
                        <i className="fas fa-truck" />
                      </span>
                      <Select
                        options={tipoCargaOptions}
                        placeholder="Selecione o tipo de carga"
                        onChange={(selectedOption) => {
                          this.setState({
                            tipoMotivoCarga: selectedOption,
                          });
                          this.props.onChangeChargeType(selectedOption.value);
                        }}
                        value={this.state.tipoMotivoCarga}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-4">
                    <label className="font-normal p-w-xs">Tipo de Carga</label>
                    <div style={{marginBottom: '20px'}} className="input-group">
                      <span className="input-group-addon">
                        <i className="fas fa-truck" />
                      </span>
                      <div className="input-group-append">
                        <input
                          type="text"
                          value={this.props.tipoCarga}
                          onChange={(event) =>
                            this.props.onChangeChargeType(event.target.value)
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.props.showTipoRevisao && (
              <div className="col-sm-4">
                <label className="font-normal p-w-xs">Tipo Revisão</label>
                <div style={{marginBottom: '20px'}} className="input-group">
                  <span className="input-group-addon">
                    <i className="far fa-check-square" />
                  </span>
                  <div className="input-group-append">
                    <datalist id="revisoes-default">
                      <option value="Simples" />
                      <option value="Básica" />
                      <option value="Completa" />
                    </datalist>
                    <input
                      type="text"
                      value={this.props.tipoRevisao}
                      onChange={(e) => onChangeRevisionType(e.target.value)}
                      list="revisoes-default"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="form-group col-sm-12">
              <label className="font-normal p-w-xs">Descrição / Solicitação</label>
              <textarea
                id="failureTextarea"
                value={this.props.failure}
                onChange={(e) => {
                  onChangeFailure(e.target.value);
                }}
                rows="6"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    failure: state.attendance.createFailureTab,
    actualKm: state.attendance.actualKm,
    tipoCarga: state.attendance.tipoCarga,
    tipoRevisao: state.attendance.tipoRevisao,
    showTipoRevisao: state.attendance.showTipoRevisao,
    attendanceTab: state.attendance.attendanceTab,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeFailure: (text) => dispatch(onChangeFailureDescription(text)),
  onChangeActualKm: (text) => dispatch(onChangeActualKm(text)),
  onChangeChargeType: (text) => dispatch(setAttendanceChargeType(text)),
  onChangeRevisionType: (text) => dispatch(setAttendanceTypeOfRevision(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FailureTab);
