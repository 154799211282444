import React, {Component} from 'react';

import {tipoCargaOptions} from './components/common/failureTab';

class ObservationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      altura: '',
      comprimento: '',
      carregado: false,
      carga: '',
      eletrico: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  handleCheckboxChange(event) {
    const {name, checked} = event.target;
    this.setState({[name]: checked}, () => this.updateObservation(name, checked));
  }

  handleInputChange(event) {
    const {name, value} = event.target;

    if (this.state[name] !== '') {
      this.removeObservation(name, this.state[name]);
    }
    this.setState({[name]: value}, () => this.updateObservation(name, true));
  }

  removeObservation(changedField, value) {
    let observationText = '';
    switch (changedField) {
      case 'altura':
        observationText = `Remover Altura: ${value}m;`;
        break;
      case 'comprimento':
        observationText = `Remover Comprimento: ${value}m;`;
        break;
      case 'carga':
        observationText = `Remover Tipo de Carga: ${value};`;
        break;
      default:
        break;
    }
    this.props.handleObservation(observationText, false);
  }

  updateObservation(changedField, isAdding) {
    const {altura, comprimento, carregado, carga, eletrico} = this.state;

    let observationText = '';

    switch (changedField) {
      case 'altura':
        observationText = `Altura: ${altura}m; `;
        break;
      case 'comprimento':
        observationText = `Comprimento: ${comprimento}m; `;
        break;
      case 'carregado':
        observationText = carregado ? 'Carregado; ' : 'Remover Carregado;';
        break;
      case 'carga':
        observationText = `Tipo de Carga: ${carga}; `;
        break;
      case 'eletrico':
        observationText = eletrico ? 'Elétrico; ' : 'Remover Elétrico;';
        break;
      default:
        break;
    }

    this.props.handleObservation(observationText, isAdding);
  }

  render() {
    const {altura, comprimento, carregado, carga, eletrico} = this.state;

    const checkboxGroupStyle = {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      marginBottom: '8px',
      marginTop: '4px',
    };

    const labelStyle = {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    };

    const inputStyle = {
      margin: '0px',
    };

    return (
      <div>
        <div className="form-group">
          <div className="col-md-12" style={checkboxGroupStyle}>
            <label style={labelStyle}>
              <input
                style={inputStyle}
                type="checkbox"
                name="carregado"
                checked={carregado}
                onChange={this.handleCheckboxChange}
              />
              Carregado
            </label>
            <label style={labelStyle}>
              <input
                style={inputStyle}
                type="checkbox"
                name="eletrico"
                checked={eletrico}
                onChange={this.handleCheckboxChange}
              />
              Elétrico
            </label>
          </div>
          <div className="col-md-3">
            <label>Altura</label>
            <select
              name="altura"
              value={altura}
              onChange={this.handleInputChange}
              className="form-control">
              <option value="">Altura</option>
              {[...Array(30)].map((_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1} m
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>Comprimento</label>
            <select
              name="comprimento"
              value={comprimento}
              onChange={this.handleInputChange}
              className="form-control">
              <option value="">Comprimento</option>
              {[...Array(30)].map((_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1} m
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>Carga</label>
            <select
              name="carga"
              value={carga}
              onChange={this.handleInputChange}
              className="form-control">
              <option value="">Tipo de Carga</option>
              {tipoCargaOptions.map((option) => (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default ObservationForm;
