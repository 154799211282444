import React from 'react';

// const getPriceList = prices =>(
//     prices.map(p=>{
//         return(

//         )
//     })
// )

const ServiceFloatingPanel = ({carPrice, suvPrice, vanPrice, time, service, copy}) => {
  return (
    <div
      className="widget-head-color-box navy-bg p-lg text-center"
      style={{
        padding: `10px`,
        marginTop: `0px`,
        height: '110px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div className="row" style={{marginTop: '10px'}}>
        <div className="col-xs-12" style={{textAlign: 'right'}}>
          <h2
            className="font-bold"
            style={{textAlign: 'center', marginRight: '5px', marginTop: '0px'}}>
            Preencha os campos, avance e então visualize o valor da corrida :)
          </h2>
        </div>
      </div>
    </div>
  );

  /* return (
        <div className="widget-head-color-box navy-bg p-lg text-center" style={{ padding: `10px`, marginTop: `0px` }}>
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-6" style={{ textAlign: 'right' }}>
                    <h2 className="font-bold" style={{ marginRight: '5px', marginTop: '0px' }}>
                        {service}
                    </h2>
                </div>
                <div className="col-xs-6" style={{ textAlign: 'left' }}>
                    <div className="row vertical-align" >
                        <div className="" style={{ marginLeft: '5px' }}>
                            <h2 className="font-bold">{
                                time !== 0
                                    ? `${time} - ${parseInt(time * 1.3)} min`
                                    : `-`
                            }</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-xs-12">
                    <div className="widget style1 navy-bg" style={{ marginBottom: '-10px', marginTop: '6px', paddingTop: `0px`, paddingBottom: `0px`, paddingRight: `0px` }}>
                        <div className="row" style={{ marginTop: '-15px' }}>
                            <div className="col-xs-4 copy-btn" data-tip="Copiar" onClick={()=>copy(carPrice)}>
                                <div style={{ padding: '10px' }}>
                                    <h2 className="m-xs">$ {carPrice}</h2>
                                    <h3 className="font-bold no-margins">
                                        Carro / Moto
                                        </h3>
                                </div>
                            </div>
                            <div className="col-xs-4 copy-btn" data-tip="Copiar" onClick={() => copy(suvPrice)}>
                                <div style={{ padding: '10px' }}>
                                    <h2 className="m-xs">$ {suvPrice}</h2>
                                    <h3 className="font-bold no-margins">
                                        SUV
                                        </h3>
                                </div>
                            </div>
                            <div className="col-xs-4 copy-btn" data-tip="Copiar" onClick={() => copy(vanPrice)}>
                                <div style={{ padding: '10px' }}>
                                    <h2 className="m-xs">$ {vanPrice}</h2>
                                    <h3 className="font-bold no-margins">
                                        VAN
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) */
};

export default ServiceFloatingPanel;
