import React, {Component} from 'react';

import {connect} from 'react-redux';
import {onSelectChange} from '../../actions/partner_service_action';

import {onServicesNext} from '../../actions/partner_service_action';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';

import Select from 'react-select';
import chroma from 'chroma-js';

// reboque

const dataBase = {
  chaveiroMoto: {
    servico: {
      codigo: 'CHAVEIRO',
      descricao: 'Chaveiro para Moto',
    },
    tipoVeiculoAtendido: {
      codigo: 'MOTO',
      descricao: 'Moto',
    },
  },
  chaveiroAutomovel: {
    servico: {
      codigo: 'CHAVEIRO',
      descricao: 'Chaveiro para Automóveis',
    },
    tipoVeiculoAtendido: {
      codigo: 'AUTOMOVEL',
      descricao: 'Automóvel',
    },
  },
  chaveiroSuv: {
    servico: {
      codigo: 'CHAVEIRO',
      descricao: 'Chaveiro para Caminhonete/SUV',
    },
    tipoVeiculoAtendido: {
      codigo: 'SUV',
      descricao: 'Caminhonete/SUV',
    },
  },
  chaveiroVan: {
    servico: {
      codigo: 'CHAVEIRO',
      descricao: 'Chaveiro para Van/Outros',
    },
    tipoVeiculoAtendido: {
      codigo: 'VAN',
      descricao: 'Van/Outros',
    },
  },
  chaveiroPesado: {
    servico: {
      codigo: 'CHAVEIRO',
      descricao: 'Chaveiro para Pesados',
    },
    tipoVeiculoAtendido: {
      codigo: 'PESADO',
      descricao: 'Pesado',
    },
  },
  chaveiroExtraPesado: {
    servico: {
      codigo: 'CHAVEIRO',
      descricao: 'Chaveiro para Extra-Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'EXTRAPESADO',
      descricao: 'Extra-Pesado',
    },
  },

  mecanicoMoto: {
    servico: {
      codigo: 'MECANICO',
      descricao: 'Mecânico para Moto',
    },
    tipoVeiculoAtendido: {
      codigo: 'MOTO',
      descricao: 'Moto',
    },
  },
  mecanicoAutomovel: {
    servico: {
      codigo: 'MECANICO',
      descricao: 'Mecânico para Automóvel',
    },
    tipoVeiculoAtendido: {
      codigo: 'AUTOMOVEL',
      descricao: 'Automóvel',
    },
  },
  mecanicoSuv: {
    servico: {
      codigo: 'MECANICO',
      descricao: 'Mecânico para Caminhonete/SUV',
    },
    tipoVeiculoAtendido: {
      codigo: 'SUV',
      descricao: 'Caminhonete/SUV',
    },
  },
  mecanicoVan: {
    servico: {
      codigo: 'MECANICO',
      descricao: 'Mecânico para Van/Outros',
    },
    tipoVeiculoAtendido: {
      codigo: 'VAN',
      descricao: 'Van/Outros',
    },
  },
  mecanicoPesado: {
    servico: {
      codigo: 'MECANICO',
      descricao: 'Mecânico para Pesados',
    },
    tipoVeiculoAtendido: {
      codigo: 'PESADO',
      descricao: 'Pesado',
    },
  },
  mecanicoExtraPesado: {
    servico: {
      codigo: 'MECANICO',
      descricao: 'Mecânico para Extra-Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'EXTRAPESADO',
      descricao: 'Extra-Pesado',
    },
  },

  reboquePesado: {
    servico: {
      codigo: 'REBOQUE',
      descricao: 'Reboque de Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'PESADO',
      descricao: 'Pesado',
    },
  },
  reboqueExtraPesado: {
    servico: {
      codigo: 'REBOQUE',
      descricao: 'Reboque de Extra Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'EXTRAPESADO',
      descricao: 'Extra-Pesado',
    },
  },
  reboqueMoto: {
    servico: {
      codigo: 'REBOQUE',
      descricao: 'Reboque de Moto',
    },
    tipoVeiculoAtendido: {
      codigo: 'MOTO',
      descricao: 'Moto',
    },
  },
  reboqueAutomovel: {
    servico: {
      codigo: 'REBOQUE',
      descricao: 'Reboque de Automovel',
    },
    tipoVeiculoAtendido: {
      codigo: 'AUTOMOVEL',
      descricao: 'Automóvel',
    },
  },
  reboqueCaminhonete: {
    servico: {
      codigo: 'REBOQUE',
      descricao: 'Reboque de Caminhonete/SUV',
    },
    tipoVeiculoAtendido: {
      codigo: 'SUV',
      descricao: 'Caminhonete/SUV',
    },
  },
  reboqueVan: {
    servico: {
      codigo: 'REBOQUE',
      descricao: 'Reboque de Van/Outros',
    },
    tipoVeiculoAtendido: {
      codigo: 'VAN',
      descricao: 'Van/Outros',
    },
  },
  subsoloMoto: {
    servico: {
      codigo: 'SUBSOLO',
      descricao: 'Guincho Subsolo Moto',
    },
    tipoVeiculoAtendido: {
      codigo: 'MOTO',
      descricao: 'Moto',
    },
  },
  subsoloAutomovel: {
    servico: {
      codigo: 'SUBSOLO',
      descricao: 'Guincho Subsolo Automóvel',
    },
    tipoVeiculoAtendido: {
      codigo: 'AUTOMOVEL',
      descricao: 'Automóvel',
    },
  },
  subsoloCaminhonete: {
    servico: {
      codigo: 'SUBSOLO',
      descricao: 'Guincho Subsolo Caminhonete/SUV',
    },
    tipoVeiculoAtendido: {
      codigo: 'SUV',
      descricao: 'Caminhonete/SUV',
    },
  },
  subsoloVan: {
    servico: {
      codigo: 'SUBSOLO',
      descricao: 'Guincho Subsolo VAN/Outros',
    },
    tipoVeiculoAtendido: {
      codigo: 'VAN',
      descricao: 'Van/Outros',
    },
  },
  bateriaPesado: {
    servico: {
      codigo: 'BATERIA',
      descricao: 'Bateria Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'PESADO',
      descricao: 'Pesado',
    },
  },
  bateriaExtraPesado: {
    servico: {
      codigo: 'BATERIA',
      descricao: 'Bateria Extra Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'EXTRAPESADO',
      descricao: 'Extra-Pesado',
    },
  },
  bateriaMoto: {
    servico: {
      codigo: 'BATERIA',
      descricao: 'Bateria Moto',
    },
    tipoVeiculoAtendido: {
      codigo: 'MOTO',
      descricao: 'Moto',
    },
  },
  bateriaAutomovel: {
    servico: {
      codigo: 'BATERIA',
      descricao: 'Bateria Automóvel',
    },
    tipoVeiculoAtendido: {
      codigo: 'AUTOMOVEL',
      descricao: 'Automóvel',
    },
  },
  bateriaCaminhonete: {
    servico: {
      codigo: 'BATERIA',
      descricao: 'Bateria Caminhonete/SUV',
    },
    tipoVeiculoAtendido: {
      codigo: 'SUV',
      descricao: 'Caminhonete/SUV',
    },
  },
  bateriaVan: {
    servico: {
      codigo: 'BATERIA',
      descricao: 'Bateria VAN/Outros',
    },
    tipoVeiculoAtendido: {
      codigo: 'VAN',
      descricao: 'Van/Outros',
    },
  },
  trocaPneuPesado: {
    servico: {
      codigo: 'TROCAPNEU',
      descricao: 'Troca Pneu Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'PESADO',
      descricao: 'Pesado',
    },
  },
  trocaPneuExtraPesado: {
    servico: {
      codigo: 'TROCAPNEU',
      descricao: 'Troca Pneu Extra Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'EXTRAPESADO',
      descricao: 'Extra-Pesado',
    },
  },
  trocaPneuMoto: {
    servico: {
      codigo: 'TROCAPNEU',
      descricao: 'Troca Pneu Moto',
    },
    tipoVeiculoAtendido: {
      codigo: 'MOTO',
      descricao: 'Moto',
    },
  },
  trocaPneuAutomovel: {
    servico: {
      codigo: 'TROCAPNEU',
      descricao: 'Troca Pneu Automóvel',
    },
    tipoVeiculoAtendido: {
      codigo: 'AUTOMOVEL',
      descricao: 'Automóvel',
    },
  },
  trocaPneuCaminhonete: {
    servico: {
      codigo: 'TROCAPNEU',
      descricao: 'Troca Pneu Caminhonete/SUV',
    },
    tipoVeiculoAtendido: {
      codigo: 'SUV',
      descricao: 'Caminhonete/SUV',
    },
  },
  trocaPneuVan: {
    servico: {
      codigo: 'TROCAPNEU',
      descricao: 'Troca Pneu VAN/Outros',
    },
    tipoVeiculoAtendido: {
      codigo: 'VAN',
      descricao: 'Van/Outros',
    },
  },
  paneSecaPesado: {
    servico: {
      codigo: 'PANESECA',
      descricao: 'Pane Seca Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'PESADO',
      descricao: 'Pesado',
    },
  },
  paneSecaExtraPesado: {
    servico: {
      codigo: 'PANESECA',
      descricao: 'Pane Seca Extra Pesado',
    },
    tipoVeiculoAtendido: {
      codigo: 'EXTRAPESADO',
      descricao: 'Extra-Pesado',
    },
  },
  paneSecaMoto: {
    servico: {
      codigo: 'PANESECA',
      descricao: 'Pane Seca Moto',
    },
    tipoVeiculoAtendido: {
      codigo: 'MOTO',
      descricao: 'Moto',
    },
  },
  paneSecaAutomovel: {
    servico: {
      codigo: 'PANESECA',
      descricao: 'Pane Seca Automóvel',
    },
    tipoVeiculoAtendido: {
      codigo: 'AUTOMOVEL',
      descricao: 'Automóvel',
    },
  },
  paneSecaCaminhonete: {
    servico: {
      codigo: 'PANESECA',
      descricao: 'Pane Seca Caminhonete',
    },
    tipoVeiculoAtendido: {
      codigo: 'SUV',
      descricao: 'Caminhonete/SUV',
    },
  },
  paneSecaVan: {
    servico: {
      codigo: 'PANESECA',
      descricao: 'Pane Seca Van/Outros',
    },
    tipoVeiculoAtendido: {
      codigo: 'VAN',
      descricao: 'Van/Outros',
    },
  },
};

const colourStyles = {
  control: (styles) => ({...styles, backgroundColor: 'white'}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles, {data}) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

class TableServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multi: true,
      value: [],
      next: false,
    };
    this.onChange = this.onChange.bind(this);
    this.mapServices = this.mapServices.bind(this);
    this.getColor = this.getColor.bind(this);
    this.servicosPadrao = Object.keys(dataBase).map((type, i) => {
      return {
        value: Object.keys(dataBase)[i],
        label: dataBase[type].servico.descricao,
        color: this.getColor(dataBase[type].servico.codigo),
      };
    });
  }

  mapServices(services) {
    const resp = [];
    for (var key in dataBase) {
      services.forEach((service, i) => {
        if (
          dataBase[key].servico.codigo === service.servico.codigo &&
          dataBase[key].tipoVeiculoAtendido.codigo === service.tipoVeiculoAtendido.codigo
        ) {
          resp.push({
            value: key,
            label: dataBase[key].servico.descricao,
            color: this.getColor(dataBase[key].servico.codigo),
          });
        }
      });
    }
    return resp;
  }

  getColor(type) {
    if (type === 'REBOQUE') return '#00B8D9';
    else if (type === 'SUBSOLO') return '#FF5630';
    else if (type === 'BATERIA') return '#FF8B00';
    else if (type === 'TROCAPNEU') return '#36B37E';
    else if (type === 'PANESECA') return '#666666';
    else if (type === 'CHAVEIRO') return '#9a009a';
    else if (type === 'MECANICO') return '#4d009a';
    else return '#9a9a00';
  }

  onChange(value) {
    this.setState(
      {
        value,
        next: true,
      },
      () => {
        this.props.onNext(
          value.map((element, i) => {
            return {label: element.label, value: dataBase[element.value]};
          }),
        );
      },
    );
  }
  componentWillUnmount() {
    this.setState({
      multi: true,
      value: [],
      next: false,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.partner) {
      if (nextProps.partner.errorMessage) {
        this.setState({...this.state, ...nextProps.partner.errorMessage});
      } else {
        if (nextProps.isEditing) {
          if (nextProps.next) {
            this.setState({
              ...nextProps,
              ...this.state,
            });
          } else {
            this.setState({
              ...this.state,
              value: this.mapServices(nextProps.partner.veiculo.listaServicos || []),
            });
          }
        } else {
          if (nextProps.next) {
            this.setState({...nextProps, ...this.state});
          } else this.setState({...nextProps, value: []});
        }
      }
    }
  }

  render() {
    const {value} = this.state;
    return (
      <div className="form-group">
        <div className="custom-tab">
          <label>Serviços</label>
          <Select
            isMulti
            onChange={this.onChange}
            options={this.servicosPadrao}
            closeMenuOnSelect={false}
            value={value}
            styles={{...colourStyles, ...selectCustomStyle}}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    partner: state.partner.currentPartner,
    next: state.services.next,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNext: (services) => {
      dispatch(onSelectChange(services));
      dispatch(onServicesNext(true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableServices);
