import React from 'react';
import CustomModal from '../common/CustomModal';
import {connect} from 'react-redux';
import {
  onFormFieldChange,
  setFormValues,
  clearFormValues,
  setReadOnlyMode,
} from '../../actions/form_actions';

class EmitInvoiceModal extends React.Component {
  render() {
    const {
      title,
      context,
      baseCalculo,
      cofins,
      csll,
      deducoes,
      ir,
      iss,
      pis,
      issRetido,
      codigoNotaFiscal,
    } = this.props;

    return (
      <CustomModal title={title} context={context}>
        <div className="form">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Valor</label>
                <input
                  type="number"
                  value={this.props.valor ? this.props.valor : ''}
                  onChange={this.props.onFieldChange}
                  id="valor"
                  disabled
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Deduções</label>
                <input
                  type="number"
                  value={this.props.deducoes}
                  onChange={(event) => {
                    this.props.onFieldChange({
                      target: {
                        id: 'deducoes',
                        value: event.target.value,
                      },
                    });
                    this.props.onFieldChange({
                      target: {
                        id: 'baseCalculo',
                        value: this.props.valor - event.target.value,
                      },
                    });
                  }}
                  id="deducoes"
                  className="form-control"
                  min="0"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Base cálculo</label>
                <input
                  type="number"
                  value={this.props.valor - this.props.deducoes}
                  onChange={this.props.onFieldChange}
                  disabled
                  id="baseCalculo"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>IR</label>
                <input
                  type="number"
                  value={this.props.ir}
                  onChange={this.props.onFieldChange}
                  id="ir"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>PIS</label>
                <input
                  type="number"
                  value={this.props.pis}
                  onChange={this.props.onFieldChange}
                  id="pis"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Cofins</label>
                <input
                  type="number"
                  value={this.props.cofins}
                  onChange={this.props.onFieldChange}
                  id="cofins"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>CSLL</label>
                <input
                  type="number"
                  value={this.props.csll}
                  onChange={this.props.onFieldChange}
                  id="csll"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>ISS</label>
                <input
                  type="number"
                  value={this.props.iss}
                  onChange={this.props.onFieldChange}
                  id="iss"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <input
                type="checkbox"
                checked={this.props.issRetido}
                onChange={(event) =>
                  this.props.onFieldChange({
                    target: {
                      id: 'issRetido',
                      value: event.target.checked,
                    },
                  })
                }
                id="issRetido"
                style={{marginRight: '5px'}}
              />
              <label>ISS Retido</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="float-right">
                <button
                  style={{marginRight: '10px'}}
                  onClick={() =>
                    this.props.handleUpdateNF(codigoNotaFiscal, {
                      valorBaseCalculo: baseCalculo ? baseCalculo : 0,
                      valorCofins: cofins ? cofins : 0,
                      valorCsll: csll ? csll : 0,
                      valorDeducoes: deducoes ? deducoes : 0,
                      valorIr: ir ? ir : 0,
                      valorIss: iss ? iss : 0,
                      valorPis: pis ? pis : 0,
                      issRetido,
                    })
                  }
                  className="btn btn-primary">
                  Salvar
                </button>
                <button
                  onClick={() => this.props.confirmBuildNF(this.props.codigo)}
                  className="btn btn-primary">
                  Emitir
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let contextState = state.forms && state.forms[ownProps.context];

  return {
    ...ownProps,
    ...contextState,
    isFetchingNewInvoice: state.base.isfetchingNewInvoice,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFieldChange: (event) => {
      dispatch(onFormFieldChange(ownProps.context, event.target.id, event.target.value));
    },
    clear: (context) => {
      dispatch(clearFormValues(context));
    },
    clearAutoComplete: (context) => {
      dispatch(onInputCleared(context));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmitInvoiceModal);
