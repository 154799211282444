import React, {Component} from 'react';

import withReactContent from 'sweetalert2-react-content';
import swal from 'sweetalert2';
import DateTime from 'react-datetime';
import {TableHeaderColumn, BootstrapTable} from 'react-bootstrap-table';
import moment from 'moment';

const MySwal = withReactContent(swal);
import '../components/dashBoard/Dashboard.css';

import MaskedInput from '../components/common/MaskedInput';
import {
  CustomModalWithoutTabIndex,
  doOpenModal,
  doCloseModal,
} from '../components/common/CustomModal';
import {doGet, doPut} from '../api/base/base_api_helper';
import {toastError, toastSuccess} from '../actions/toast_actions';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ReactTooltip from 'react-tooltip';

export default class PendenciesView extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      invoices: [],
      isFetching: true,
      closeDate: new Date(),
      codigoInvoice: '',
      closeId: null,
      desconto: 9.7,
      acrescimo: 0,
      afterAction: false,
      afterActionApproved: false,
      sortPendingInvoicesOrder: 'desc',
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.handleReprove = this.handleReprove.bind(this);
    this.getClosesModal = this.getClosesModal.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.handleCloseDate = this.handleCloseDate.bind(this);
    this.closeInvoice = this.closeInvoice.bind(this);
  }
  toggleSortPendingInvoicesOrder() {
    this.setState((prevState) => ({
      sortPendingInvoicesOrder: prevState.sortPendingInvoicesOrder === 'desc' ? 'asc' : 'desc',
    }));
  }

  async fetchInvoices() {
    if (!this.state.isFetching) {
      this.setState({isFetching: true});
    }

    doGet('invoices', {_processada: false})
      .then((result) => {
        const newData = result.listInvoice.map((invoice) => {
          return {
            tipo: invoice.base ? 'base' : 'empresa',
            nomeFantasia: invoice.base
              ? invoice.base.nomeFantasia
              : invoice.empresa.nomeFantasia,
            codigo: invoice.codigo,
            valor: parseFloat(invoice.valor),
            dataVencimento: invoice.lancamentoFinanceiro.dataVencimento,
            dataCadastro: invoice.lancamentoFinanceiro.dataCadastro,
            usuarioNotaFiscalAprovada: invoice.usuarioNotaFiscalAprovada
              ? invoice.usuarioNotaFiscalAprovada.nome
              : '',
            notaFiscalTomada: invoice.linkNotaFiscalTomada || '',
            notaFiscalAprovada: invoice.notaFiscalAprovada || false,
            boleto: invoice.lancamentoFinanceiro.linkBoleto || '',
          };
        });

        this.setState({
          afterAction: false,
          afterActionApproved: false,
          invoices: result.listInvoice,
          data: newData,
          isFetching: false,
        });
      })
      .catch((e) => {
        this.setState({
          isFetching: false,
          afterAction: false,
          afterActionApproved: false,
        });

        toastError(e.mensagemCliente || e.status);
      });
  }

  componentDidMount() {
    this.fetchInvoices();
  }

  handleCloseDate(closeDate) {
    this.setState({closeDate});
  }

  getTotal() {
    const {closeId, desconto, acrescimo, invoices} = this.state;
    const closeInfo = invoices.filter(
      (invoice) => invoice.lancamentoFinanceiro.codigo === closeId,
    )[0];
    const {valor} = closeInfo;
    const {lancamentoFinanceiro} = closeInfo;
    const {processada} = lancamentoFinanceiro;

    if (processada) {
      return lancamentoFinanceiro.total.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      if (valor > 0) {
        return parseFloat(valor - desconto + acrescimo).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return parseFloat(valor + (desconto - acrescimo)).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
  }

  valueFiled(_, row) {
    return (
      <div style={row.valor > 0 ? {color: '#4ab858'} : {color: 'red'}}>
        R${' '}
        {parseFloat(row.valor).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    );
  }

  typeIcon(_, row) {
    return <i className={row.tipo === 'empresa' ? 'fas fa-building' : 'fas fa-truck'} />;
  }

  nfIcon(_, row) {
    const tooltipContent = row.usuarioNotaFiscalAprovada
      ? `Aprovada por: ${row.usuarioNotaFiscalAprovada}`
      : '';
    return row.notaFiscalTomada ? (
      <div>
        <button
          data-tip={tooltipContent}
          data-for={tooltipContent ? 'invoice-tooltip' : undefined}
          className="btn btn-sm btn-default"
          onClick={() => window.open(row.notaFiscalTomada, '_blank')}>
          <i
            style={row.notaFiscalAprovada ? {color: '#4ab858'} : null}
            className="fas fa-file-invoice-dollar"
          />
        </button>
        {tooltipContent && <ReactTooltip id="invoice-tooltip" place="top" effect="solid" />}
      </div>
    ) : null;
  }
  PendingInvoices(_, row) {
    return !row.notaFiscalAprovada ? (
      row.notaFiscalTomada ? (
        <i className={`${row.notaFiscalTomada ? '' : ''}`} />
      ) : null
    ) : null;
  }

  billetIcon(_, row) {
    return row.boleto ? (
      <button
        data-tip="Ver boleto"
        className="btn btn-default"
        onClick={() => window.open(row.boleto, 'blank')}>
        {row.dataVencimento > new Date() ? (
          <i className="fas fa-barcode" />
        ) : (
          <i style={{color: '#e08d07'}} className="fas fa-barcode" />
        )}
      </button>
    ) : null;
  }

  dueDate(_, row) {
    const data = moment(row.dataCadastro, 'DD-MM-YYYY');
    const dia = data.date();

    if (dia <= 15) {
      data.add(1, 'months').date(5);
    } else {
      data.add(1, 'months').date(20);
    }
    const formattedDate = data.format('DD/MM/YYYY');
    return formattedDate;
  }

  setTableLoader() {
    if (this.state.isFetching === false) {
      return `Nenhum registro encontrado`;
    }
    return (
      <div className="spiner-example">
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1" />
          <div className="sk-double-bounce2" />
        </div>
      </div>
    );
  }

  onRowClick(row) {
    this.setState(
      {
        codigoInvoice: row.codigo,
        closeId: this.state.invoices.filter((invoice) => invoice.codigo === row.codigo)[0]
          .lancamentoFinanceiro.codigo,
      },
      () => doOpenModal('CONTEXT_BASE'),
    );
  }

  async handleApprove() {
    if (this.state.codigoInvoice) {
      await doPut(`invoices/${this.state.codigoInvoice}/aprovarNf`, {
        approved: true,
      })
        .then(() => {
          toastSuccess('Aprovado com sucesso');
          this.setState({afterAction: true, afterActionApproved: true});
          this.fetchInvoices();
        })
        .catch((error) => {
          toastError(error.mensagemCliente || error.status);
        });
    }
  }

  handleReprove() {
    MySwal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja reprovar a nota fiscal ?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo da rejeição',
      icon: 'warning',
      width: '80',
      showCancelButton: true,
      confirmButtonText: 'Reprovar',
      confirmButtonColor: '#25f4af',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      preConfirm: (item) => {
        if (!item) {
          MySwal.showValidationMessage(`Por favor preencha o motivo da rejeição.`);
        }
      },
      inputAttributes: {
        style: 'font-size: 14px;',
      },
    }).then((result) => {
      if (result.value) {
        doPut(`invoices/${this.state.codigoInvoice}/aprovarNf`, {
          approved: false,
          motivoRecusa: result.value,
        })
          .then(() => {
            toastSuccess('Reprovado com sucesso');
            this.setState({afterAction: true});
            this.fetchInvoices();
          })
          .catch((error) => {
            toastError(error.mensagemCliente || error.status);
          });
      }
    });
  }

  closeInvoice(invoiceId, data, balanceId, descontos, acrescimos) {
    doPut(`lancamentos/${invoiceId}/processed`, {data, descontos, acrescimos})
      .then((resp) => doCloseModal('CONTEXT_BASE'), toastSuccess('Sucesso'))
      .catch((e) => toastError(e.mensagemCliente || 'Error'));
  }

  getClosesModal() {
    const {closeId, closeDate, desconto, acrescimo, invoices} = this.state;

    const closeInfo = invoices.filter(
      (invoice) => invoice.lancamentoFinanceiro.codigo === closeId,
    )[0];

    const {
      valor,
      valorNotaFiscalTomada,
      linkNotaFiscalTomada,
      codigoFechamento,
      notaFiscalAprovada,
      usuarioNotaFiscalAprovada,
    } = closeInfo;
    const nomeAprovada = usuarioNotaFiscalAprovada ? usuarioNotaFiscalAprovada.nome : '';
    const {
      dataMovimento,
      dataVencimento,
      tipoMovimento,
      descricao,
      processada,
      descontos,
      acrescimos,
      dataProcessada,
    } = closeInfo.lancamentoFinanceiro;
    let bankName = '',
      bankNumber = '',
      agencyNumber = '',
      agencyCheckNumber = '',
      accountNumber = '',
      accountCheckNumber = '',
      holderFullname = '',
      holderTypeDocument = '',
      holderNumberDocument = '',
      chavePix = '';

    let baseOrCompanyDocument = '';
    let baseOrCompanyType = '';
    let pendenciaFiscal = '';

    if (closeInfo.empresa) {
      baseOrCompanyDocument = closeInfo.empresa.cnpj;
      baseOrCompanyType = 'CNPJ';
    } else if (closeInfo.base) {
      baseOrCompanyType = closeInfo.base.tipoDocumento;
      baseOrCompanyDocument = closeInfo.base.documento;
      pendenciaFiscal = closeInfo.base.pendenciaFiscal;
    }

    if (closeInfo.base && closeInfo.base.contaBancaria) {
      bankName = closeInfo.base.contaBancaria.bankName;
      bankNumber = closeInfo.base.contaBancaria.bankNumber;
      (agencyNumber = closeInfo.base.contaBancaria.agencyNumber),
        (agencyCheckNumber = closeInfo.base.contaBancaria.agencyCheckNumber);
      accountNumber = closeInfo.base.contaBancaria.accountNumber;
      accountCheckNumber = closeInfo.base.contaBancaria.accountCheckNumber;
      holderFullname = closeInfo.base.contaBancaria.holderFullname;
      holderTypeDocument = closeInfo.base.contaBancaria.holderTypeDocument;
      holderNumberDocument = closeInfo.base.contaBancaria.holderNumberDocument;
      chavePix = closeInfo.base.contaBancaria.chavePix;
    }

    const tipoBase = closeInfo.base ? 'base' : 'empresa';
    const baseName = closeInfo.base
      ? closeInfo.base.nomeFantasia
      : closeInfo.empresa.nomeFantasia;
    const notaFiscalPrestador = valorNotaFiscalTomada;
    const valorAcumulado = closeInfo.valorAcumulado;

    this.footer = (
      <div className="form-group">
        <button
          className="btn btn-primary"
          onClick={() =>
            this.closeInvoice(
              closeId,
              moment(closeDate).format('YYYY-MM-DD HH:mm:ss'),
              codigoFechamento,
              desconto,
              acrescimo,
            )
          }
          type="submit">
          Dar Baixa
        </button>
      </div>
    );
    return (
      <div>
        <div className="col-md-8" style={{borderRight: '1px solid #e5e5e5'}}>
          <div className="form-group custom-tab">
            <label>Descricao</label>
            <br />
            <span>{descricao ? descricao : 'Sem descricao'}</span>
          </div>
          <div className="row">
            <div className="col-md-6 form-group custom-tab">
              <label>Base</label>
              <br />
              <span>{baseName ? baseName : 'Sem descricao'}</span>
            </div>
            <div className="col-md-6 form-group custom-tab">
              <label>CNPJ/CPF</label>
              <br />
              <span>
                {baseOrCompanyDocument && baseOrCompanyType === 'CNPJ' ? (
                  <MaskedInput
                    className="no-border copy-btn"
                    value={baseOrCompanyDocument}
                    mask="99.999.999/9999-99"
                  />
                ) : (
                  <MaskedInput
                    className="no-border copy-btn"
                    value={baseOrCompanyDocument}
                    mask="999.999.999-99"
                  />
                )}
              </span>
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-6">
              <label>Tipo</label>
              <span className="form-control">
                {tipoMovimento ? tipoMovimento.descricao : 'Sem tipo'}
              </span>
            </div>
            <div className="form-group custom-tab col-md-6">
              <label>Data Movimento</label>
              <span className="form-control">
                {dataMovimento
                  ? moment(dataMovimento, 'DD/MM/YY HH:mm').local().format('DD/MM/YYYY HH:mm')
                  : 'Sem data movimento'}
              </span>
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-6">
              <label>Data Vencimento</label>
              <span className="form-control">
                {dataVencimento
                  ? moment(dataVencimento, 'DD/MM/YY HH:mm').local().format('DD/MM/YYYY HH:mm')
                  : 'Sem data vencimento'}
              </span>
            </div>
            <div className="form-group custom-tab col-md-6">
              <label>Data da Baixa</label>
              <DateTime
                onChange={this.handleCloseDate}
                value={
                  processada
                    ? moment(dataProcessada, 'DD/MM/YY HH:mm')
                        .local()
                        .format('DD/MM/YYYY HH:mm')
                    : moment(closeDate, 'DD/MM/YY HH:mm').local().format('DD/MM/YYYY HH:mm')
                }
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-4">
              <label>Descontos / Taxas</label>
              <input
                type="number"
                disabled={processada}
                value={processada ? descontos : this.state.desconto}
                onChange={(e) => this.setState({desconto: parseFloat(e.target.value) || 0})}
                className="form-control"
              />
            </div>
            <div className="form-group custom-tab col-md-4">
              <label>Juros / Multa</label>
              <input
                type="number"
                disabled={processada}
                value={processada ? acrescimos : this.state.acrescimo}
                onChange={(e) =>
                  this.setState({
                    acrescimo: parseFloat(e.target.value) || 0,
                  })
                }
                className="form-control"
              />
            </div>
            <div className="form-group custom-tab col-md-4">
              <div>
                <label>Nota Fiscal Prestador</label>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                  <input
                    type="number"
                    disabled
                    value={notaFiscalPrestador || 0}
                    onChange={(e) =>
                      this.setState({
                        desconto: parseFloat(e.target.value) || 0,
                      })
                    }
                    className="form-control"
                  />
                  <button
                    disabled={!linkNotaFiscalTomada}
                    onClick={() => window.open(linkNotaFiscalTomada, '_blank')}
                    style={
                      this.state.afterActionApproved || notaFiscalAprovada
                        ? {color: '#4ab858'}
                        : {marginLeft: '10px'}
                    }
                    className="btn btn-default">
                    <i className="fas fa-file-invoice" />
                  </button>
                </div>
                {usuarioNotaFiscalAprovada && (
                  <p style={{marginTop: '3px'}}>Aprovada por: {nomeAprovada} </p>
                )}
              </div>
            </div>
            {tipoBase === 'base' &&
              !this.state.afterAction &&
              !notaFiscalAprovada &&
              linkNotaFiscalTomada && (
                <div
                  className="form-group custom-tab col-md-4"
                  style={{
                    float: 'right',
                    width: '100%',
                    maxWidth: '150px',
                    marginRight: '15px',
                    padding: 0,
                  }}>
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <button
                      style={{marginRight: '10px'}}
                      onClick={() => this.handleApprove()}
                      className="btn btn-primary">
                      <i className="fas fa-check" />
                    </button>
                    <button onClick={() => this.handleReprove()} className="btn btn-danger">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group custom-tab col-md-6">
            <label>Valor</label>
            <br />
            <h2 style={{margin: '5px 0px -10px'}}>{`${valor.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</h2>
          </div>
          <div className="form-group custom-tab col-md-6">
            <label>Total</label>
            <br />
            <h2
              className="copy-btn"
              ref={(r) => (this.totalContainer = r)}
              style={{margin: '5px 0px -10px'}}>{`${this.getTotal()}`}</h2>
          </div>
          {valorAcumulado !== 0 && (
            <div className="form-group custom-tab col-md-12">
              <p className="text-danger">* Possui um valor acumulado.</p>
            </div>
          )}
          {pendenciaFiscal && (
            <div className="form-group custom-tab col-md-12">
              <p className="text-danger">* Possui Pendência Envio de NF</p>
            </div>
          )}

          {chavePix && (
            <div className="form-group custom-tab col-md-12">
              <label>Chave Pix</label>
              <br />
              <span>{chavePix}</span>
            </div>
          )}

          <div className="form-group custom-tab col-md-12">
            <label>Banco</label>
            <br />
            <span>{`${bankNumber} - ${bankName}`}</span>
          </div>

          <div className="form-group custom-tab col-md-6">
            <label>Agência</label>
            <br />
            <span className="copy-btn">{`${agencyNumber} - ${agencyCheckNumber}`}</span>
          </div>
          <div className="form-group custom-tab col-md-6">
            <label>Nº Conta</label>
            <br />
            <span className="copy-btn">{`${accountNumber} - ${accountCheckNumber}`}</span>
          </div>
          <div className="form-group custom-tab col-md-12">
            <label>Nome Favorecido</label>
            <br />
            <span className="copy-btn">{holderFullname}</span>
          </div>

          <div className="form-group custom-tab col-md-12 copy-btn">
            <label>CPF/ CNPJ Favorecido</label>
            <br />
            <div className="copy-btn">
              {holderTypeDocument === 'CNPJ' ? (
                <MaskedInput
                  className="no-border copy-btn"
                  value={holderNumberDocument}
                  mask="99.999.999/9999-99"
                />
              ) : (
                <MaskedInput
                  className="no-border copy-btn"
                  value={holderNumberDocument}
                  mask="999.999.999-99"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {data, closeId, isFetching} = this.state;

    const modalBody = closeId ? this.getClosesModal() : null;

    const options = {
      noDataText: this.setTableLoader(),
      onRowClick: this.onRowClick,
      defaultSortName: 'notaFiscalTomada',
      defaultSortOrder: 'desc',
    };

    return (
      <div className="row">
        <CustomModalWithoutTabIndex
          title="Baixa Lançamento"
          context={'CONTEXT_BASE'}
          customClass="big-modal"
          footer={this.footer}>
          {modalBody}
        </CustomModalWithoutTabIndex>
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Pendências</h5>
              </div>
              <div className="ibox-content">
                <BootstrapTable
                  search
                  searchPlaceholder="Pesquisar por Nome Fantasia..."
                  pagination
                  striped
                  hover
                  condensed
                  data={!isFetching ? data : []}
                  options={options}
                  trStyle={{cursor: 'pointer'}}
                  bordered={false}>
                  <TableHeaderColumn
                    dataField={'tipo'}
                    dataFormat={this.typeIcon}
                    dataSort={true}
                    width="40px">
                    Tipo
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    isKey={true}
                    dataField={'nomeFantasia'}
                    width="200px"
                    dataSort={true}>
                    Nome Fantasia
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField={'valor'}
                    width="110px"
                    dataSort={true}
                    dataFormat={this.valueFiled}>
                    Valor
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField={'dataVencimento'}
                    dataFormat={this.dueDate}
                    width="110px"
                    dataSort={true}>
                    Vencimento
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField={'notaFiscalTomada'}
                    width="80px"
                    dataFormat={this.nfIcon}
                    dataSort={true}>
                    NF Recebida
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField={'notaFiscalAprovada'}
                    dataFormat={this.PendingInvoices}
                    width="130px"
                    dataSort={true}>
                    Pendentes/Aprovadas
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField={'boleto'}
                    width="80px"
                    dataSort={true}
                    dataFormat={this.billetIcon}>
                    Boleto
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
