import { connect } from 'react-redux';
import AutoComplete from '../common/AutoComplete';
import { onOptionSelected, onInputCleared } from '../../actions/autocomplete_actions'
import { onValueChanged } from '../../actions/customer_autocomplete_actions'

const getCustomerFullName = (localState) => {
  let nome = localState && localState.selectedItem && localState.selectedItem.nome
  //let name = localState && localState.selectedItem && localState.selectedItem.name
  let sobreNome = localState && localState.selectedItem && localState.selectedItem.sobreNome

  if (nome)
    //return nome + ' ' + sobreNome !== undefined ? sobreNome : ''
    return `${nome} ${sobreNome ? sobreNome : ''}`
  return ''
}

const mapStateToProps = (state, ownProps) => {
  let localState = state.autocomplete[ownProps.context]

  return {
    ...ownProps,
    ...localState,
    inputValue: getCustomerFullName(localState)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onOptionSelected: (newValue) => {
      dispatch(onOptionSelected(ownProps.context, newValue, ownProps.afterOptionSelected))
    },
    onClear: () => {
      dispatch(onInputCleared(ownProps.context, ownProps.afterClear))
    },
    onChange: (event) => {
      dispatch(onValueChanged(ownProps.context, event.target.value, ownProps.afterValueChanged, ownProps.auth))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete)
