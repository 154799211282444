export const selectCustomStyle = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? '#FFF' : styles.color,
    backgroundColor: state.isSelected ? '#5c40ff' : styles.color,
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#5c40ff',
    },
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? '#5c40ff' : '#CED4DA',
    '&:hover': {
      borderColor: state.isFocused ? '#5c40ff' : '#CED4DA',
    },
  }),
};
