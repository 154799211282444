import React, { Component } from "react";
import { Link } from "react-router";
import Griddle from "griddle-react";

import GriddlePager from "./GriddlePager";

import _ from "underscore";

const TableButton = props => {
  let icon = props.metadata.icon;
  let text = props.metadata.text;
  let className = props.metadata.className
    ? props.metadata.className
    : "btn btn-sm btn-success";
  let customClickEvent = props.metadata.onClick;

  let onClick = event => {
    event.preventDefault();
    customClickEvent(props.rowData);
  };

  return (
    <button onClick={onClick} className={className}>
      <i className={icon}></i> {text}
    </button>
  );
};

class TableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: ""
    };
    this.customFilterComponent = this.customFilterComponent.bind(this);
  }

  /* componentDidUpdate() {
    this.searchInput.focus();
  } */

  componentWillMount() {
    console.log(this.props);
  }

  customFilterComponent() {
    let getDefaultProps = () => {
      return { query: "" };
    };
    let searchChange = e => {
      this.props.filterFunction(e.target.value);
    }; //props.changeFilter(event.target.value) }
    //searchInput.focus()
    return (
      <div className="row m-b-sm m-t-sm">
        <div className="col-md-12">
          <input
            ref={input => {
              this.searchInput = input;
            }}
            type="text"
            name="search"
            placeholder="Procurar..."
            className="input-sm form-control"
            onChange={searchChange}
            value={this.props.baseFilter}
          />
        </div>
      </div>
    );
  }
  render() {
    const { currentPage, pageSize, recordCount, filterFunction } = this.props;
    let rowData = this.props.data;
    let { columnMetadata } = this.props;

    let visibleColumns = [];

    _.map(rowData, data => {
      data.visualizar ? false : (data["visualizar"] = "---");
      data.telefoneFixo ? false : (data["telefoneFixo"] = "---");
    });

    for (let column of columnMetadata) {
      visibleColumns.push(column.columnName);
    }

    return (
      <Griddle
        useGriddleStyles={false}
        useCustomPagerComponent={true}
        customPagerComponent={GriddlePager}
        nextText={"Próximo"}
        previousText={"Anterior"}
        tableClassName="footable table table-striped table-hover"
        columnMetadata={columnMetadata}
        columns={visibleColumns}
        results={rowData}
        showFilter={this.props.showFilter}
        useCustomFilterComponent={this.props.showFilter}
        customFilterComponent={this.customFilterComponent}
        noDataMessage={"Nenhum registro encontrado para o termo pesquisado"}
        resultsPerPage={10}
      />
    );
  }
}

export { TableWrapper as default, TableButton };
