import React from 'react';

import {correctHeight, detectBody} from './Helpers';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import TopHeader from '../common/TopHeader';
// import Progress from '../common/Progress';
import ProfileImageModal from '../common/ProfileImageModal';
import Navigation from '../common/Navigation';
import Footer from '../common/Footer';
import CustomModal from '../common/CustomModal';
import Auth from '../auth/Auth';
class Main extends React.Component {
  render() {
    let wrapperClass = 'gray-bg ' + this.props.location.pathname.replace('/', '');

    return (
      <div id="wrapper">
        <Auth />

        <CustomModal title="Alterar Senha" context="changePassword">
          <ChangePasswordForm />
        </CustomModal>
        <CustomModal context="ADD_PROFILE_IMAGE" title="Alterar Foto">
          <ProfileImageModal />
        </CustomModal>

        {/* <Progress /> */}

        <div className={wrapperClass}>{this.props.children}</div>
      </div>
    );
  }

  componentDidMount() {
    // Run correctHeight function on load and resize window event
    $(window).bind('load resize', function () {
      // correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $('.metismenu a').click(() => {
      setTimeout(() => {
        // correctHeight();
      }, 300);
    });
  }
}

export default Main;
