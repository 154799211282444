import React, {Component} from 'react';

import {connect} from 'react-redux';

const userFilter = (e, isLoginFiltered, login) => {
  if (isLoginFiltered) {
    return `${e.target.value} ${login.nome}`;
  }
  return e.target.value;
};

class RenderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFilterOn: false,
      filteredList: [],
      lastInProgress: JSON.parse(localStorage.getItem('inProgress')),
    };
    this.toggleFilterByUser = this.toggleFilterByUser.bind(this);
    this.checkInProgressChanges = this.checkInProgressChanges.bind(this);
  }
  componentDidMount() {
    this.interval = setInterval(this.checkInProgressChanges, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkInProgressChanges() {
    const {stableFilteredSolicitations} = this.props;
    const currentInProgress = JSON.parse(localStorage.getItem('inProgress'));
    const filterOn = stableFilteredSolicitations.length > 0;
    if (this.state.lastInProgress.length !== currentInProgress.length && !filterOn) {
      this.props.onRefresh();
      this.setState({lastInProgress: currentInProgress});
    } else {
      return;
    }
  }

  filterListByUser() {
    const filteredList = this.props.list.filter((item) => {
      if (item.usuarioResponsavelAcionamento) {
        const userId = JSON.parse(localStorage.getItem('user_info')).usuario.id;
        return item.usuarioResponsavelAcionamento.id === userId;
      }
      return false;
    });
    return filteredList;
  }
  toggleFilterByUser() {
    if (this.state.userFilterOn) {
      this.setState({userFilterOn: false, filteredList: this.props.list});
    } else {
      const filteredList = this.filterListByUser();
      this.setState({userFilterOn: true, filteredList});
    }
  }
  sortListByDate(list) {
    return list.sort((a, b) => {
      const dateA = new Date(
        a.dataCadastro.replace(
          /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
          '$3-$2-$1T$4:$5:$6',
        ),
      );
      const dateB = new Date(
        b.dataCadastro.replace(
          /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
          '$3-$2-$1T$4:$5:$6',
        ),
      );
      return dateA - dateB;
    });
  }
  render() {
    const {
      list = [],
      onFilter = () => {},
      onRefresh,
      height = 0,
      hideSearch = false,
      renderListItem = () => {},
      applyLoginFilter = () => {},
      login = {},
      isLoginFiltered = false,
      msgEmpty = '',
      buttonLayout = false,
      renderButton = () => {},
      stableFilteredSolicitations,
    } = this.props;
    const {lastInProgress} = this.state;

    const isFiltered = stableFilteredSolicitations.length > 0;
    let listToRender;

    if (this.state.userFilterOn) {
      listToRender = this.state.filteredList;
    } else if (isFiltered) {
      listToRender = stableFilteredSolicitations;
    } else {
      listToRender = list;
    }
    listToRender = this.sortListByDate(listToRender);
    return (
      <div className="full-height-scroll">
        <div className="ibox ibox-content no-padding">
          <div style={{display: hideSearch ? 'none' : 'block'}}>
            <div className="input-group">
              <span className="input-group-addon">
                <i className="fa fa-search" />
              </span>
              <input
                className="form-control"
                type="text"
                autoFocus
                onChange={(e) => onFilter(userFilter(e, isLoginFiltered, login))}
              />

              <span
                style={{cursor: 'pointer'}}
                onClick={() => onRefresh()}
                className="input-group-addon">
                <i className="fas fa-sync-alt" />
              </span>
              <span
                style={{cursor: 'pointer', background: this.state.userFilterOn && '#25f4af'}}
                onClick={(e) => this.toggleFilterByUser()}
                className="input-group-addon">
                <i className="fas fa-user" />
              </span>
            </div>
          </div>
          <ul
            className="list-group"
            style={{
              height: height - (buttonLayout ? 100 : 65) + 'px',
              overflow: 'auto',
            }}>
            {listToRender.map((item, key) => {
              return renderListItem(item, key);
            })}
            {listToRender.length == 0 ? (
              <p style={{textAlign: 'center', margin: '32px'}}>{msgEmpty}</p>
            ) : (
              <span />
            )}
          </ul>
          {renderButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stableFilteredSolicitations: state.followup.stableFilteredSolicitations,
});
export default connect(mapStateToProps)(RenderList);
