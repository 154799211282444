import React from 'react'

const TitleButton = ({active, onClick, label, type})=>{
    return(                  
        <button 
            className={`btn btn-xs btn-white ${active && 'active'}`}
            type="button" 
            onClick={()=>onClick(type)}>{label}
        </button>
    )
}

export default TitleButton