import { getAllSolicitations, sendMapNotification } from "../api/heatMapHelper";

import { getAllPartnersTracking } from "../api/partner_helper";

export function loadHeatMapData(data) {
  return {
    type: LOAD_HEAT_MAP_DATA,
    data
  };
}

export function loadPartnersData() {
  return dispatch => {
    getAllPartnersTracking()
      .then(res => {
        dispatch({ type: LOAD_PARTNERS_DATA, data: res.listPartners });
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export const LOAD_HEAT_MAP_DATA = "LOAD_HEAT_MAP_DATA";
export const LOAD_PARTNERS_DATA = "LOAD_PARTNERS_DATA";
