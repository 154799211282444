
import { getCustomers } from '../api/customer_helper'
import { INPUT_VALUE_CHANGED, INPUT_OPTIONS_CHANGED } from './autocomplete_actions'

export function onValueChanged(context, newValue, doAfterSuccess = () => { }, auth) {
  return (dispatch, getState) => {

    dispatch({ type: INPUT_VALUE_CHANGED, context: context, value: newValue })
    doAfterSuccess(newValue)

    if (newValue.length > 1) {
      getCustomers(newValue, auth, getState().service.company.value).then((results) => {
        let customerList = results.listCustomers ? results.listCustomers.map((customer) => {
          return { codigo: customer.codigo, name: customer.nome + " " + customer.sobreNome, cpf: customer.cpf, ...customer }
        }):[{}]
        dispatch({ type: INPUT_OPTIONS_CHANGED, context: context, value: customerList })
      })
    }
    else {
      dispatch({ type: INPUT_OPTIONS_CHANGED, context: context, value: [] })
    }
  }
}
