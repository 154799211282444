import { LOAD_HEAT_MAP_DATA, LOAD_PARTNERS_DATA } from '../actions/heatMapAction'

const heatMapReducer = ( state = {}, action ) => {
  switch( action.type ) {
    case LOAD_HEAT_MAP_DATA:
      return {
        ...state,
        heatMapData: action.data
      }
    case LOAD_PARTNERS_DATA:
      return {
        ...state,
        partnersTracking: action.data
      }
    case `PIN_SOLICITATION_ON_MAP`:
      return{
        ...state,
        solicitationToPin: action.value,
        pinSolicitation: true,
      }
    case `REMOVE_SOLICITATION_ON_MAP`:
      return {
        ...state,
        pinSolicitation: false
      }
      default:
        return state
  }
}

export default heatMapReducer
