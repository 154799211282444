import {
  CAR_LIST_CHANGED,
  CAR_LIST_FETCHING,
  CAR_LIST_CLEAR
} from "../actions/vehicle_actions";

const initialState = {
  isFetching: false,
  vehicleList: []
};

const vehicle_reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
    case CUSTOMER_INSTANCE_CHANGED:
      let newList = state.customerList.map( oldCustomer => {
        return oldCustomer.codigo === action.customerId? action.customerData : oldCustomer
      });

      return {
        ...state,
        customerList: newList
      }
*/
    case CAR_LIST_CHANGED:
      return {
        ...state,
        vehicleList: action.value
      };
    case CAR_LIST_CLEAR:
      return {
        ...state,
        vehicleList: []
      }
    case CAR_LIST_FETCHING:
      return { ...state, isFetching: action.value };
    default:
      return state;
  }
};

export default vehicle_reducer;
