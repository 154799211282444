const INITIAL_STATE = {
    financialList: [],
    financialFetching:false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'FINANCIAL_FETCHED':
            return { ...state, financialList: action.payload }     
        case 'FINANCIAL_FETCHING':
            return { ...state, financialFetching: action.payload }     
        default:
            return state
    }
}
