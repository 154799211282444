import React from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';
import CustomModal from '../common/CustomModal';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {doCloseModal} from '../common/CustomModal';
import {toastError, toastSuccess} from '../../actions/toast_actions';
import AsyncSelect from 'react-select/lib/Async';
import {updateScheduledSolicitation} from '../../api/solicitation_helper';
import {doGet} from '../../api/base/base_api_helper';
import {
  onSolicitationSelect,
  loadScheduledSolicitationsList,
} from '../../actions/followup_actions';
import AlterScheduledMap from '../common/AlterScheduleMap';

class AlterScheduledModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finishDate: this.props.finishDate || moment(),
      options: [],
      value: '',
      firstDate: true,
    };
  }

  onChange = (value) => {
    this.setState({
      value: value,
    });
    //this.props.updatePartner(value)
  };

 /* onChangeFinishDate = (e) => {
    this.setState({
      ...this.state,
      finishDate: e,
    });
    this.setState({
      ...this.state,
      firstDate: false,
    });
  };
*/
   onChangeFinishDate = (e) => {
  this.setState({
    ...this.state,
    finishDate: e,
    firstDate: false,
  });
};

  reset = () => {
    this.setState({
      finishDate: this.props.finishDate || moment(),
      value: '',
    });
  };

  onChangeMotivoConclusao = (motivoConclusao) => {
    this.setState({
      ...this.state,
      motivoConclusao,
    });
  };

  reload = () => {
    const {
      currentSolicitation: {codigo},
    } = this.props;
    this.props.loadSchedule();
    this.props.onItemSelect(codigo);
  };

  handleFinishService = async (e) => {
    e.preventDefault();

    try {
      const {
        currentSolicitation: {codigo},
        context,
      } = this.props;

      const finishDate = moment.utc(this.state.finishDate, moment.ISO_8601);

      if (!this.state.value) {
        const response = await updateScheduledSolicitation(codigo, finishDate);
        toastSuccess(response.mensagemCliente);
      } else {
        const {
          value: {value: updatedPartner},
        } = this.state;

        const response = await updateScheduledSolicitation(
          codigo,
          finishDate,
          updatedPartner.codigo,
        );
        toastSuccess(response.mensagemCliente);
      }

      doCloseModal(context);
      this.reset();
      this.reload();
    } catch (err) {
      if (err.mensagemRetorno) {
        toastError(err.mensagemRetorno);
      }
      console.log(err);
    }
  };

  getOtherOptions = (input) => {
    const {options} = this.state;
    if (!input) {
      return Promise.resolve(options);
    }
    return doGet(`partners?_filter=${input}&&_status=PARCEIROATIVO,PARCEIROEMDESCANSO`)
      .then((res) => {
        if (res.sucesso === true) {
          if (res.listPartners) {
            return res.listPartners.map((partner) => {
              const formatNotEqualZero = () => {
                let output = '';

                if (partner.veiculo.patins !== 0) {
                  output += `| ${partner.veiculo.patins} Patin(s)`;
                }

                if (partner.veiculo.tamanhoPlataforma !== 0) {
                  const {tamanhoPlataforma} = partner.veiculo;
                  output += `| ${tamanhoPlataforma.toString().replace('.', ',')} Metro(s)`;
                }

                return output;
              };

              return {
                value: partner,
                label: `${partner.contato} - ${partner.nomeFantasia} ${
                  partner.veiculo.asaDelta === 1 ? '| Com ASA' : ''
                } ${formatNotEqualZero()}`,
              };
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    let requestOrigin = null;
    let requestDestination = null;
    if (this.props.currentSolicitation && this.props.currentSolicitation.enderecoOrigem) {
      requestOrigin = {
        lat: this.props.currentSolicitation.enderecoOrigem.latitude,
        lng: this.props.currentSolicitation.enderecoOrigem.longitude,
      };
    }
    if (this.props.currentSolicitation && this.props.currentSolicitation.enderecoDestino) {
      requestDestination = {
        lat: this.props.currentSolicitation.enderecoDestino.latitude,
        lng: this.props.currentSolicitation.enderecoDestino.longitude,
      };
    }
    const serviceType = this.props.currentSolicitation
      ? this.props.currentSolicitation.tipoServico.codigo
      : null;
    return (
      <CustomModal
        title="Alterar Agendamento"
        customClass="width-80vw"
        context={this.props.context}>
        <form onSubmit={this.handleFinishService}>
          <div class="row">
            <div className="form-group col-md-6">
              <label className="form-label">Nova data do agendamento</label>
              <DateTime
              className='form-color'
                onChange={this.onChangeFinishDate}
                value={
                  this.state.firstDate
                    ? moment(this.state.finishDate).subtract(3, 'hours')
                    : moment(this.state.finishDate)
                }
                timeFormat="HH:mm"
                dateFormat="DD-MM-YYYY"
                closeOnSelect
                utc
              />
            </div>
            <div className="form-group col-md-6">
              <label className="form-label">Parceiro</label>
              <AsyncSelect
                loadOptions={this.getOtherOptions}
                onChange={this.onChange}
                value={this.state.value}
                defaultOptions
                isClearable
              />
            </div>
          </div>
          <AlterScheduledMap
            id={`#map-${this.props.currentSolicitation.codigo}`}
            requestOrigin={requestOrigin}
            requestDestination={requestDestination}
            panel="PANEL_MAPS"
            tipoServico={serviceType}
          />
          <div style={{paddingTop: '1em'}}>
            <button className="btn btn-block btn-success" type="submit">
              <small>Alterar</small>
            </button>
          </div>
        </form>
      </CustomModal>
    );
  }
}

AlterScheduledModal.propTypes = {
  context: PropTypes.string.isRequired,
  options: PropTypes.array,
  isOpen: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentSolicitation: state.followup.currentSolicitation,
});

const mapDispatchToProps = (dispatch) => ({
  handleClosestPartners: (solicitationCode) => {
    dispatch({
      type: `HANDLE_CLOSEST_PARTNERS`,
      value: solicitationCode,
    });
  },
  onItemSelect: (solicitationCode) => {
    dispatch(onSolicitationSelect(solicitationCode));
  },
  loadSchedule: () => {
    dispatch(loadScheduledSolicitationsList());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AlterScheduledModal);
