const INITIAL_STATE = {
    imageUploaded:{
        FRENTEVEICULO: [],
        TRASEIRAVEICULO:[],
        LATERALESQUERDAVEICULO:[],
        LATERALDIREITAVEICULO:[],
        CHECKLISTVEICULO:[],
        DOCUMENTOSDIVERSOSVEICULO:[]
    },
    documentList:[],
    documentListFetching:false,
    checklistDocuments: {}
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SOLICITATION_IMAGE_UPLOADED':
            let newImg = state.imageUploaded
            newImg[action.typeDocument][action.documentIndex] = action.payload
            return { ...state, imageUploaded: newImg }   
        case 'SOLICITATION_IMAGES_FETCHED':
            return { ...state, documentList: action.payload ? action.payload : INITIAL_STATE.documentList }  
        case 'SOLICITATION_IMAGES_FETCHING':
            return { ...state, documentListFetching: action.payload } 
        case 'CLEAR_CHECKLIST_DOCUMENTS':
            return {...state, checklistDocuments: {}} 
        case 'CHECKLIST_DOCUMENTS_FETCHED':
            return {...state, checklistDocuments: {...state.checklistDocuments, [action.checklistType]: action.value }}
        default:
            return state
    }
}