import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ParkingTable, {
  NO_PATIO,
  A_CAMINHO,
  SAINDO_PATIO,
  PRONTO_PRA_SAIR,
} from '../dashBoard/parkingtable';
import {getParkingVehicles} from '../dashBoard/dashboardActions';
import TitleButton from '../common/titleButtons/TitleButton';
import {hasCompanyProfile, hasReboqueMeProfile} from '../../utils/check_permission';
class Patio extends Component {
  getStatsParking() {
    return this.props.parkingVehicles.reduce(
      (total, acum) => {
        if (acum.status.codigo === A_CAMINHO) {
          return {...total, incoming: total.incoming + 1};
        } else if (acum.status.codigo === NO_PATIO) {
          return {...total, patio: total.patio + 1};
        } else if (acum.status.codigo === PRONTO_PRA_SAIR) {
          return {...total, readyToGo: total.readyToGo + 1};
        } else if (acum.status.codigo === SAINDO_PATIO) {
          return {...total, leaving: total.leaving + 1};
        }
        return total;
      },
      {
        incoming: 0,
        patio: 0,
        leaving: 0,
        readyToGo: 0,
      },
    );
  }

  showVehiclesPerCompany() {
    if (!this.props.parkingVehicles || this.props.parkingVehicles.length === 0) {
      return;
    }

    const {parkingVehicles} = this.props;
    let groupedVehicles = [];

    parkingVehicles.forEach((vehicle) => {
      if (
        !vehicle.solicitacaoEntrada ||
        !vehicle.solicitacaoEntrada.empresa ||
        !vehicle.solicitacaoEntrada.empresa.nomeFantasia
      )
        return;

      const {
        solicitacaoEntrada: {
          empresa: {nomeFantasia},
        },
      } = vehicle;

      const respSearch = groupedVehicles.filter((r) => r.nomeFantasia === nomeFantasia);
      if (respSearch.length <= 0) {
        const amount = parkingVehicles.filter(
          (v) =>
            v.solicitacaoEntrada &&
            v.solicitacaoEntrada.empresa &&
            v.solicitacaoEntrada.empresa.nomeFantasia === nomeFantasia,
        ).length;
        groupedVehicles.push({nomeFantasia, amount});
      }
    });

    if (groupedVehicles.length === 0) return;

    return (
      <div className="tagContainer">
        {groupedVehicles.map((vehicle) => {
          return (
            <div className="companyTagContainer">
              <span>
                <strong>{vehicle.nomeFantasia} :</strong> {vehicle.amount}{' '}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  componentDidMount() {
    if (hasReboqueMeProfile() || hasCompanyProfile()) {
      this.props.getParkingVehicles();
    }
  }
  render() {
    const {patio, incoming, leaving, readyToGo} = this.getStatsParking();
    return (
      <div>
        {(hasReboqueMeProfile() || hasCompanyProfile()) && (
          <div className="ibox" style={{marginTop: '20px'}}>
            <div
              className="ibox-title"
              style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <strong>Veículos no Pátio</strong>
                <div style={{marginLeft: '4px'}}>
                  <div title="No Pátio" className={`label badge-primary-adapted`}>
                    {patio}
                  </div>

                  <div
                    title="Saindo do Pátio"
                    style={{marginLeft: '5px'}}
                    className={`label label-primary`}>
                    {readyToGo}
                  </div>
                  <div
                    title="Saindo do Pátio"
                    style={{marginLeft: '5px'}}
                    className={`label label-gray`}>
                    {leaving}
                  </div>
                  <div
                    title="A Caminho"
                    style={{marginLeft: '5px'}}
                    className={`label label-warning`}>
                    {incoming}
                  </div>
                </div>
                <div>{hasReboqueMeProfile() && this.showVehiclesPerCompany()}</div>
              </div>
              <div className="float-right">
                <TitleButton
                  label="Atualizar"
                  onClick={() => this.props.getParkingVehicles()}
                  className="btn btn-sm btn-default"
                />
              </div>
            </div>
            <div className="ibox-content">
              <ParkingTable getParkingVehicles={this.props.getParkingVehicles} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  parkingVehicles: state.dashboard.parkingVehicles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getParkingVehicles,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Patio);
