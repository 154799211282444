import React from 'react'
import ReactTooltip from "react-tooltip";

const getButtons = buttonList => buttonList.map(btn => 
    <li key={btn.label} className="dropDown-li" data-tip={btn.label}>
        <a className="dropdown-item balance-dropDown-a" href="#"
            onClick={(e)=>{e.preventDefault(); btn.func()}}>
        <i className={btn.icon}></i></a>
    </li>) 


const SideDropDown = ({buttonList, last}) =>{
   
    return(
        <div className={`btn-group show ${open}`}>
            <ReactTooltip multiline={true} html={true}/>
            <button data-toggle={`dropdown`}
               
                className={`btn btn-default dropdown-toggle hover-dropdown`} aria-expanded="false"><i className="fas fa-barcode"></i></button>
            <ul className="dropdown-menu sideDropDown" style={{ position: 'absolute', top:`${last? '-5px': '33px'}`, left: "-60px", ' willChange': 'top, left' }}>
                {getButtons(buttonList)}

            </ul>
        </div>
    )

} 

export const SideDropDownAdapter = ({buttonList, last, icon}) =>{
   
    return(
        <div className={`btn-group show ${open}`}>
            <ReactTooltip multiline={true} html={true}/>
            <button data-toggle={`dropdown`}
               
                className={`btn btn-default dropdown-toggle hover-dropdown`} aria-expanded="false"><i className={icon}></i></button>
            <ul className="dropdown-menu sideDropDown" style={{ position: 'absolute', top:`${last? '-5px': '33px'}`, left: "-60px", ' willChange': 'top, left' }}>
                {getButtons(buttonList)}

            </ul>
        </div>
    )
} 

export default SideDropDown