import toastr from 'toastr';

export function toastSuccess(message) {
  toastr.options = TOAST_CONFIG;
  toastr.success(message);
}

export function toastInfo(message) {
  toastr.options = TOAST_CONFIG;
  toastr.info(message);
}

export function customToastError(message) {
  toastr.options = CUSTOM_TOAST_CONFIG;
  toastr.error(message);
}

export function toastWarning(message) {
  toastr.options = TOAST_CONFIG;
  toastr.warning(message);
}

export function toastError(message) {
  toastr.options = TOAST_CONFIG;
  toastr.error(message);
}

const CUSTOM_TOAST_CONFIG = {
  closeButton: true,
  debug: false,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: '400',
  hideDuration: '1000',
  timeOut: '10000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const TOAST_CONFIG = {
  closeButton: true,
  debug: false,
  progressBar: true,
  preventDuplicates: true,
  positionClass: 'toast-top-right',
  onclick: null,
  showDuration: '400',
  hideDuration: '1000',
  timeOut: '3000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};
