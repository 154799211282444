import axios from 'axios'

import { toastSuccess, toastError } from '../actions/toast_actions'

import { BASE_URL } from '../config'

export const getAllSolicitations = ( action ) => {
  axios.get( `${BASE_URL}solicitations?_limit=2800&_status=CONCLUIDA`, {
    auth: {
      username: localStorage.getItem("username"),
      password: localStorage.getItem("password")
    }
  } )
    .then( ( res ) => {
      action( res.data.listSolicitations )
    } )
    .catch( ( error ) => {
      console.error( error )
    } )
}

export const sendMapNotification = ( codigo, mensagem ) => {
  axios.get(`${BASE_URL}partners/${codigo}/sendPushNotification`, {
    params: {
      _msg: mensagem
    },
    auth: {
      username: localStorage.getItem("username"),
      password: localStorage.getItem("password")
    }
  })
  .then(( res ) => {
    toastSuccess(res.data.mensagemRetorno)
  })
  .catch(( err ) => {
    toastError(err)
  })
}
