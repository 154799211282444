import React from 'react';
import IconBadge from '../common/IconBadge';
import SendNotification from '../common/SendNotification';
import RemoveNotification from '../common/RemoveNotification';
import swal from 'sweetalert';
import {StatusTypes} from '../../config';
import CopyToClipboard from '../common/copyToClipboard/CopyToClipboard';
import DialAgent from '../common/dialAgent/DialAgent';
import {hasReboqueMeProfile} from '../../utils/check_permission';

const FollowUpPartnerItem = ({
  partner,
  notifyPartner,
  removeNotify,
  showNotification,
  solicitationCode,
  handleAcceptSolicitation,
  login,
}) => {
  const {parceiro} = partner;

  const handleSendNotification = () => {
    notifyPartner(parceiro.codigo);
  };

  const handleRemoveNotification = () => {
    swal(
      {
        title: 'Deseja excluir o prestador?',
        showCancelButton: true,
        closeOnConfirm: true,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
        type: 'warning',
      },
      () => removeNotify(solicitationCode, parceiro.codigo),
    );
  };

  const sendApprovalNotification = () => {
    handleAcceptSolicitation(solicitationCode, parceiro.codigo);
  };

  const handleApprovalNotification = () => {
    swal(
      {
        title: 'Deseja aceitar para o prestador?',
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
        type: 'warning',
      },
      sendApprovalNotification,
    );
  };

  return (
    <li className="list-group-item solicitation-item" style={{padding: '15px'}}>
      {partner.recusada ? <IconBadge typeList={StatusTypes} type={'Recusada'} /> : null}
      {showNotification ? (
        <div className="pull-right">
          <SendNotification
            typeList={StatusTypes}
            type={'Aceita'}
            handlePress={handleApprovalNotification}
          />
          {hasReboqueMeProfile() ? (
            <span>
              <SendNotification
                typeList={StatusTypes}
                type={'EnviarNotificacao'}
                handlePress={handleSendNotification}
              />
              <RemoveNotification
                typeList={StatusTypes}
                type={'RemoverNotificação'}
                handlePress={handleRemoveNotification}
              />
            </span>
          ) : null}
        </div>
      ) : null}
      <strong>{` ${parceiro.contato}`}</strong>
      <br />
      {parceiro.nomeFantasia}

      {partner.valorRealParceiro && hasReboqueMeProfile(login) ? (
        <span>
          <br />
          Valor roteirizado: R$ ${partner.valorRealParceiro}
        </span>
      ) : null}
      <br />

      {hasReboqueMeProfile(login) && <DialAgent phoneNumber={parceiro.telefoneCelular} />}
      <CopyToClipboard
        textToBeCopied={parceiro.telefoneCelular}
        title={parceiro.telefoneCelular}
        style={{marginLeft: 5}}
      />
      <br />
      {!partner.valorRealParceiro ? <br /> : null}
      {parceiro.linkCnh ? (
        <a
          style={{position: 'absolute', bottom: '35px', right: '15px'}}
          href={parceiro.linkCnh}
          target="_blank">
          Ver CNH
        </a>
      ) : null}

      <small style={{position: 'absolute', bottom: '16px', right: '15px'}}>
        {partner.distaciadoCliente} Km
      </small>
      {hasReboqueMeProfile(login) && partner.usuario && partner.usuario.nome && (
        <small style={{position: 'absolute', bottom: '2px', right: '15px'}}>
          Acionado por {partner.usuario.nome}
        </small>
      )}
    </li>
  );
};

export default FollowUpPartnerItem;
