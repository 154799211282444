import swal from 'sweetalert';
import Swal from 'sweetalert2';

export function confirmDialog(options, onConfirm, onCancel) {
  swal(
    {
      ...CONFIRM_CONFIG,
      ...options,
    },
    function(isConfirm) {
      if (isConfirm && onConfirm) onConfirm();

      if (!isConfirm && onCancel) onCancel();
    }
  );
}

export async function confirmDialogCustomized(options, onConfirm) {
  const { value: reason } = await Swal.fire({
    ...CONFIRM_CONFIG,
    ...options,
    inputValidator: function(value) {
      return new Promise((resolve, reject) => {
        if (value !== '') {
          resolve();
        } else {
          resolve('Você precisa selecionar um motivo.');
        }
      });
    },
  });

  if (reason) onConfirm(reason);
}

export function confirmDialogSMS(options, onConfirm, onCancel) {
  swal(
    {
      ...CONFIRM_CONFIG,
      ...options,
    },
    function(isConfirm) {
      if (isConfirm && onConfirm) onConfirm();

      if (!isConfirm && onCancel) onCancel();
    }
  );
}
const CONFIRM_CONFIG = {
  title: 'Confirmação de Exclusão',
  showCancelButton: true,
  confirmButtonColor: '#ec4758',
  confirmButtonText: 'Confirmar',
  cancelButtonText: 'Cancelar',
  cancelButtonColor: '#d9534f',
  closeOnConfirm: true,
};
