import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import RegisterAttendence from './RegisterAttendence';
import SolicitationAttendence from '../containers/SolicitationAssistanceTab';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  fetchOneAttendance,
  createAttendanceHistory,
  finishAttendance,
} from '../../actions/attendence_actions';
import RelatoriosLog from '../common/RelatoriosLog';
import {
  CODIGO_DAF,
  HISTORICO_MODO_PUBLICO,
  USUARIO_TIPO_EMPRESA,
  USUARIO_TIPO_REBOQUEME,
} from '../../reducers/constants';
import DocumentTab from '../../components/attendance/tabs/DocumentTab';
import ConclusionTab from '../../components/attendance/tabs/ConclusionTab';
const initialState = {
  key: 1,
  log: '',
  atendimento: {
    estagio: null,
    log: null,
    sendMail: false,
    willSleep: false,
    mecanicoChegou: false,
    email: '',
    visibilidade: 'public',
    adormecerAte: null,
    dataAgendado: null,
    kmAtualValidado: null,
    kmTotalRodadoMecanico: null,
  },
  visibility: HISTORICO_MODO_PUBLICO,
};

class ShowAttendence extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.prePutLog = this.prePutLog.bind(this);
    this.handleLog = this.handleLog.bind(this);
    this.handleAtendimento = this.handleAtendimento.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onChangeVisbility = this.onChangeVisbility.bind(this);
  }

  componentWillMount() {
    const {location} = this.props;
    const parts = location.pathname.split('/');

    this.id = parts[2];
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.fetchOneAttendance();
  }

  fetchOneAttendance() {
    const {fetchOneAttendance} = this.props;
    fetchOneAttendance(this.id);
  }

  handleTabs = (key) => {
    this.setState({...this.state, key});
  };

  handleLog(e) {
    this.setState({log: e.target.value});
  }

  handleAtendimento(e) {
    const {atendimento} = this.state;
    let newAtendimento = {};

    if (e.estagio) {
      newAtendimento = {...atendimento, estagio: e.estagio};
    } else if (typeof e.sendMail === 'boolean') {
      newAtendimento = {...atendimento, sendMail: e.sendMail};
    } else if (typeof e.email === 'string') {
      newAtendimento = {...atendimento, email: e.email};
    } else if (typeof e.willSleep === 'boolean') {
      newAtendimento = {...this.state.atendimento, willSleep: e.willSleep};
    } else if (typeof e.mecanicoChegou === 'boolean') {
      newAtendimento = {
        ...this.state.atendimento,
        mecanicoChegou: e.mecanicoChegou,
      };
    } else if (e.adormecerAte) {
      newAtendimento = {
        ...this.state.atendimento,
        adormecerAte: e.adormecerAte,
      };
    } else if (e.dataAgendado) {
      newAtendimento = {
        ...this.state.atendimento,
        dataAgendado: e.dataAgendado,
      };
    } else if (e.kmTotalRodadoMecanico) {
      newAtendimento = {
        ...this.state.atendimento,
        kmTotalRodadoMecanico: e.kmTotalRodadoMecanico,
      };
    } else if (e.kmAtualValidado) {
      newAtendimento = {
        ...this.state.atendimento,
        kmAtualValidado: e.kmAtualValidado,
      };
    }

    this.setState({atendimento: newAtendimento});
  }

  handleKeyUp(e) {
    // const { putLog } = this.props;
    const {log, atendimento} = this.state;
    const {adormecerAte, sendEmail, email, estagio, willSleep, visibility} = atendimento;

    if (e.key === 'Enter' && e.ctrlKey) {
      // putLog(this.state.baseCode, log);
      const {createAttendanceHistory} = this.props;
      createAttendanceHistory(
        this.id,
        log,
        visibility,
        sendEmail,
        email,
        estagio,
        adormecerAte,
      );
      this.setState({log: '', atendimento: {}});
    }
  }

  async prePutLog() {
    const {atendimento, log} = this.state;
    let {
      adormecerAte,
      email,
      estagio,
      sendMail,
      kmAtualValidado,
      kmTotalRodadoMecanico,
      dataAgendado,
    } = atendimento;
    const {createAttendanceHistory} = this.props;

    if (estagio === 'AGENDANDO') {
      estagio = 'ESTAGIOOPERADOR';
    }

    const res = await createAttendanceHistory(
      this.id,
      log,
      this.state.visibility,
      sendMail,
      email,
      estagio,
      adormecerAte,
      dataAgendado,
      kmAtualValidado,
      kmTotalRodadoMecanico,
    );

    if ((res && res.sucesso) || !res) {
      this.props.fetchOneAttendance(this.id);
      this.setState({log: '', atendimento: initialState.atendimento});
    }
    return res;
  }

  onChangeVisbility(event) {
    this.setState({
      ...this.state,
      visibility: event.target.value,
    });
  }

  showSolicitation() {
    const {attendanceTab: {tipoRequisicao: {codigo: requisicaoCodigo} = {}} = {}} = this.props;

    if (requisicaoCodigo === 'REQUISICAOTIPOASSISTENCIA') {
      return true;
    }

    return false;
  }

  isKovi() {
    const {login} = this.props;
    const koviId = '2200248530130441273';
    return login.user_info.empresa.codigo === koviId;
  }

  isJustos() {
    const {login} = this.props;
    const justosId = '3251918332755973547';
    return login.user_info.empresa.codigo === justosId;
  }

  isDaf() {
    const {login} = this.props;
    const dafId = CODIGO_DAF;
    return login.user_info.empresa.codigo === dafId;
  }

  disableSendMessageButton() {
    const {login} = this.props;
    console.log(login);
    const isSoon = login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;

    return !isSoon && !this.isKovi() && !this.isJustos() && !this.isDaf();
  }

  updateAtendimentoTab = () => {
    this.props.fetchOneAttendance(this.id);
  };

  render() {
    const {attendanceTab, login} = this.props;
    const {log, atendimento} = this.state;
    const estagio = attendanceTab && attendanceTab.estagio && attendanceTab.estagio.codigo;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div
                className="ibox-title"
                style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <h5>{`Atendimento ${this.id}`}</h5>
                </div>
              </div>
              <div className="ibox-content">
                <Tabs
                  id="general-base-tabs"
                  activeKey={this.state.key}
                  onSelect={this.handleTabs}>
                  <Tab eventKey={0} title="Protocolo">
                    <RegisterAttendence
                      type="atualizar"
                      attendanceId={this.id}
                      updateAtendimentoTab={this.updateAtendimentoTab}
                    />
                  </Tab>
                  <Tab eventKey={1} title="Atendimento">
                    {this.state.key === 1 && (
                      <div className="ibox-content" style={{overflow: 'auto'}}>
                        <RelatoriosLog
                          assistanceCode={this.id}
                          finishAttendance={this.props.finishAttendance}
                          isScreenAtendimento={true}
                          atendimento={atendimento}
                          handleAtendimento={this.handleAtendimento}
                          logList={attendanceTab.historico || []}
                          currentEstagio={attendanceTab.estagio || {}}
                          attendanceTab={attendanceTab}
                          handleLog={this.handleLog}
                          log={log}
                          putLog={this.prePutLog}
                          onKeyUp={this.handleKeyUp}
                          disableButton={this.disableSendMessageButton()}
                        />
                      </div>
                    )}
                  </Tab>
                  {this.showSolicitation() && (
                    <Tab eventKey={2} title="Solicitações">
                      <SolicitationAttendence codeAssistance={this.id} />
                    </Tab>
                  )}
                  <Tab eventKey={3} title="Documentos">
                    <DocumentTab assistanceCode={this.id} />
                  </Tab>
                  {(estagio === 'ESTAGIOCONFIRMADO' || estagio === 'ESTAGIOCONCLUIDO') && (
                    <Tab eventKey={4} title="Conclusão">
                      <ConclusionTab
                        actualKm={attendanceTab.kmAtualValidado}
                        totalKm={attendanceTab.kmTotalRodadoMecanico}
                        // dataAgendada={attendanceTab.dataAgendado}
                        attendanceCode={this.id}
                      />
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    attendanceTab: state.attendance.attendanceTab,
    showConclusionTab: state.attendance.showConclusionTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      finishAttendance,
      fetchOneAttendance,
      createAttendanceHistory,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAttendence);
