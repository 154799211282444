import React, {Component} from 'react';
import {connect} from 'react-redux';
import {onAddressChange} from '../../../actions/address_actions';
import {onAddressSelect, onFieldChange} from '../../../actions/service_actions';
import {toastError, toastSuccess} from '../../../actions/toast_actions';
import {clearAddress} from '../../../actions/address_actions';
import moment from 'moment';
import axios from 'axios';

import {ContextMenu} from '../../../components/common/contextMenu';
import {BASE_URL} from '../../../config';

class AttendanceSolicitationMap extends Component {
  constructor(props) {
    super(props);
    this.infowindow2 = new google.maps.InfoWindow();
    this.infowindow = new google.maps.InfoWindow();
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    this.getPartners = this.getPartners.bind(this);
    this.rotatePartners = this.rotatePartners.bind(this);
    this.populateMarkers = this.populateMarkers.bind(this);
    this.initMarkers = this.initMarkers.bind(this);
    this.initMap = this.initMap.bind(this);
    this.state = {
      hasDirections: false,
    };
    this.rotateInterval = null;
    window.test = this.sendNotification.bind(this);
  }
  map = undefined;
  allMarkers = [];

  sendNotification() {
    const {selectedPartner} = this.state;
    axios
      .get(`${BASE_URL}partners/${selectedPartner}/sendPushNotification`, {
        params: {
          _msg: 'Esta disponivel? Chamado proximo a voce.',
        },
        auth: {
          username: localStorage.getItem('username'),
          password: localStorage.getItem('password'),
        },
      })
      .then((res) => {
        toastSuccess(res.data.mensagemRetorno);
      })
      .catch((err) => {
        toastError(err);
      });
  }

  setToCurrentLocation(map) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        map.setCenter(pos);
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.style) !== JSON.stringify(nextProps.style) ||
      JSON.stringify(this.props.requestOrigin) !== JSON.stringify(nextProps.requestOrigin) ||
      JSON.stringify(this.props.requestDestination) !==
        JSON.stringify(nextProps.requestDestination) ||
      this.props.tipoServico !== nextProps.tipoServico ||
      JSON.stringify(this.props.partners) !== JSON.stringify(nextProps.partners) ||
      this.props.mapType === 'RELATORIOS' ||
      this.props.showCreateSolicitation !== nextProps.showCreateSolicitation
    ) {
      return true;
    }

    return false;
  }

  handleGeocoding(lat, lng, id) {
    const geocoder = new google.maps.Geocoder();
    const latLng = {lat, lng};
    let address;
    if ((lat, lng)) {
      geocoder.geocode({location: latLng}, (result, status) => {
        if (status === 'OK') {
          address = {
            field: id,
            location: {
              lat: result[0].geometry.location.lat(),
              lng: result[0].geometry.location.lng(),
            },
            label: result[0].formatted_address,
          };
          this.props.markerChanged(address, id);
        }
      });
    }
  }

  handleGeocodingMenu(latLng, id) {
    const geocoder = new google.maps.Geocoder();
    let address;
    if (latLng) {
      geocoder.geocode({location: latLng}, (result, status) => {
        if (status === 'OK') {
          address = {
            field: id,
            location: {
              lat: result[0].geometry.location.lat(),
              lng: result[0].geometry.location.lng(),
            },
            label: result[0].formatted_address,
          };
          this.props.markerChanged(address, id);
        }
      });
    }
  }

  handleSimpleLine(map) {
    const {requestOrigin, requestDestination, linePath} = this.state;

    if (linePath) {
      linePath.setMap(null);
    }

    const newLinePath = new google.maps.Polyline({
      path: [
        {lat: requestOrigin.lat, lng: requestOrigin.lng},
        {lat: requestDestination.lat, lng: requestDestination.lng},
      ],
      geodesic: true,
      strokeColor: '#271bb3',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    newLinePath.setMap(map);
    this.setState({linePath: newLinePath});
  }

  handleDirections(service, display, origin, destination) {
    let requestOrigin = 0;
    let requestDestination = 0;
    if (origin && destination) {
      requestOrigin = origin;
      requestDestination = destination;
    } else {
      requestOrigin = this.state.requestOrigin;
      requestDestination = this.state.requestDestination;
    }
    service.route(
      {
        origin: {...requestOrigin},
        destination: {...requestDestination},
        travelMode: 'DRIVING',
        avoidTolls: false,
      },
      (response, status) => {
        if (status === 'OK') {
          display.setDirections(response);
          const stepMiddle = Math.round(response.routes[0].legs[0].steps.length / 2) - 1;
          this.infowindow2.setContent(
            response.routes[0].legs[0].distance.text +
              '<br>' +
              response.routes[0].legs[0].duration.text +
              ' ',
          );
          this.infowindow2.setPosition(
            response.routes[0].legs[0].steps[stepMiddle].end_location,
          );
          this.infowindow2.open(this.map);
          this.props.changeField('distance', response.routes[0].legs[0].distance.value);
        } else {
          this.props.routeErr('Erro ao montar rota', status);
        }
      },
    );
  }

  removeMarker(markerId) {
    const marker = this.allMarkers[markerId];
    google.maps.event.clearListeners(marker, 'click');
    marker.setMap(null);
  }

  componentWillReceiveProps(nextProps) {
    google.maps.event.trigger(this.map, 'resize');
    const {requestOrigin, requestDestination} = nextProps;
    if (requestOrigin) {
      this.setState({
        requestOrigin,
      });
    }
    if (requestDestination) {
      this.setState({
        requestDestination,
      });
    }
    if (
      nextProps.mapType === 'RELATORIOS' ||
      nextProps.requestOrigin !== this.props.requestOrigin ||
      (requestDestination !== this.props.requestDestination &&
        this.props.panel === 'PANEL_MAPS')
    ) {
      nextProps.tipoServico === `REBOQUE`
        ? this.setState({
            hasDirections: true,
          })
        : this.setState({
            hasDirections: false,
          });
    }
    if (
      (this.props.requestOrigin !== undefined && requestOrigin === undefined) ||
      (this.props.requestDestination !== undefined &&
        requestDestination === undefined &&
        this.state.hasDirections === true)
    ) {
      this.setState({
        hasDirections: false,
        requestOrigin,
        requestDestination,
      });
      this.directionsDisplay.set('directions', null);
      this.infowindow2.close();
      nextProps.requestDestination === undefined
        ? this.props.onClear(`attendanceEnderecoDestino`)
        : false;
      nextProps.requestOrigin === undefined
        ? this.props.onClear(`attendanceEnderecoOrigem`)
        : false;
    }

    if (nextProps.tipoServico !== this.props.tipoServico) {
      this.setState({
        hasDirections: false,
        requestOrigin,
        requestDestination,
      });
      this.directionsDisplay.set('directions', null);
      this.infowindow2.close();
      if (nextProps.tipoServico !== `REBOQUE`) {
        this.props.onClear(`attendanceEnderecoDestino`);
      }
      nextProps.requestOrigin === undefined
        ? this.props.onClear(`attendanceEnderecoOrigem`)
        : false;
    }
  }

  rotatePartners() {
    const {partners} = this.props;
    for (let i = 1; i < partners.length; i++) {
      $('#markerLayer')
        .children()
        .eq(i)
        .css({
          transform: 'rotate(' + 90 + 'deg)',
        });
    }
    google.maps.event.clearListeners(this.map, 'idle');
  }

  componentWillUnmount() {
    clearInterval(this.rotateInterval);
    google.maps.event.clearListeners(this.map, 'idle');
  }

  getPartners() {
    const {partners} = this.props;
    return partners;
  }

  initMap() {
    const {id, requestOrigin, requestDestination} = this.props;
    this.map = new google.maps.Map(document.getElementById(id), {
      center: requestOrigin ? requestOrigin : {lat: -22.915, lng: -43.197},
      zoom: 13,
      gestureHandling: 'greedy',
      controlSize: 24,
      disableDefaultUI: true,
    });
    var overlay = new google.maps.OverlayView();
    overlay.draw = function () {
      this.getPanes().markerLayer.id = 'markerLayer';
    };
    overlay.setMap(this.map);

    this.showContextMenu();
    this.initMarkers(requestOrigin, requestDestination);
  }

  initMarkers(requestOrigin, requestDestination) {
    let bounds = new google.maps.LatLngBounds();
    // const {
    //   markList = [],
    //   requestOrigin,
    //   requestDestination,
    //   partners,
    //   partnerLocation,
    //   loadedMap = false,
    //   setLoadedMap = () => {},
    // } = this.props;
    // console.log(requestOrigin, requestDestination);
    // if (!requestOrigin && !requestDestination && !markList) {
    //   return;
    // }
    // if (markList) {
    //   markList.map((position) => {
    //     const requestMarker = new google.maps.Marker({
    //       position,
    //       map: this.map,
    //       title: "Solicitação",
    //       icon:
    //         "http://icons.iconarchive.com/icons/icons8/windows-8/32/Transport-Car-icon.png",
    //     });
    //     bounds.extend(requestMarker.position);
    //     //this.handleBounds(requestMarker.position)
    //     this.allMarkers.push(requestMarker);
    //   });
    // }
    if (requestOrigin) {
      const {lng, lat, logradouro} = requestOrigin;
      const requestMarker = new google.maps.Marker({
        position: {lng, lat},
        map: this.map,
        title: 'Solicitação',
        icon: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',
        draggable: true,
      });
      bounds.extend(requestMarker.position);
      //   //this.handleBounds(requestMarker.position)
      this.allMarkers.push(requestMarker);
      //   // requestMarker.addListener('click', () => {
      //   //     this.infowindow.open(this.map, requestMarker);
      //   // });
      requestMarker.addListener('dragend', (point) => {
        this.handleGeocoding(
          point.latLng.lat(),
          point.latLng.lng(),
          'attendanceEnderecoOrigem',
        );
      });
    }
    if (requestDestination) {
      const {lng, lat} = requestDestination;
      const destinationMarker = new google.maps.Marker({
        position: {lng, lat},
        map: this.map,
        animation: google.maps.Animation.BOUNCE,
        title: 'Destino',
        icon: 'http://icons.iconarchive.com/icons/fatcow/farm-fresh/24/flag-finish-icon.png',
        draggable: true,
      });
      bounds.extend(destinationMarker.position);
      //this.handleBounds(destinationMarker.position)
      this.allMarkers.push(destinationMarker);
      // destinationMarker.addListener('click', () => {
      //     this.infowindow.open(this.map, destinationMarker);
      // });
      destinationMarker.addListener('dragend', (point) => {
        this.handleGeocoding(
          point.latLng.lat(),
          point.latLng.lng(),
          'attendanceEnderecoDestino',
        );
      });
    }
    // if (partnerLocation) {
    //   const partnerLocationMarker = new google.maps.Marker({
    //     position: partnerLocation,
    //     map: this.map,
    //     title: "Destino",
    //     icon:
    //       "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carGreen.png",
    //   });
    //   bounds.extend(partnerLocationMarker.position);
    //   //this.handleBounds(destinationMarker.position)
    //   this.allMarkers.push(partnerLocationMarker);
    // }
    // if (partners && partners.length > 0) {
    //   partners.forEach((p, i) => {
    //     let newPartner = new google.maps.Marker({
    //       position: { lat: p.associado.latitude, lng: p.associado.longitude },
    //       map: this.map,
    //       title: "guincho" + i,
    //       icon: this.handlePartnerIcon(p.associado),
    //     });
    //     if (this.props.isAdmin) {
    //       newPartner.addListener("click", () => {
    //         this.infowindow.setContent(
    //           this.getInfoWindowContent("partner", p.associado)
    //         );
    //         this.infowindow.open(this.map, newPartner);
    //       });
    //     }
    //     newPartner.set("id", "guincho" + i);
    //     this.allMarkers.push(newPartner);
    //   });
    //   //bounds.extend({ lat: partners[partners.length - 1].associado.latitude, lng: partners[partners.length - 1].associado.longitude})
    // }
    // // clearInterval(this.rotateInterval)
    // // google.maps.event.clearListeners(this.map, 'idle');
    // // if (partners && partners.length > 0) {
    // //     google.maps.event.addListenerOnce(this.map, 'idle', () => {
    // //         this.rotateInterval = setInterval(this.rotatePartners, 200)
    // //     });
    // // }
    // Don't zoom in too far on only one marker
    // if (!markList.length || !loadedMap) {
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01,
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01,
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    // if (markList.length || requestOrigin || !loadedMap) {
    //   this.map.fitBounds(bounds);
    //   google.maps.event.trigger(this.map, "resize");
    // }
    // if (!loadedMap) {
    //   setLoadedMap();
    // }
    if (requestOrigin && requestDestination) {
      this.handleSimpleLine(this.map);
    }
    // }
  }

  showContextMenu() {
    let bounds = new google.maps.LatLngBounds();

    const {requestOrigin, requestDestination} = this.props;
    //const directionsRendererOptions={}
    //directionsRendererOptions.draggable=true
    //directionsRendererOptions.hideRouteList=true;
    //directionsRendererOptions.suppressMarkers=true
    //directionsRendererOptions.preserveViewport=false
    //const directionsRenderer= new google.maps.DirectionsRenderer(directionsRendererOptions)
    //const directionsService= new google.maps.DirectionsService()

    const contextMenuOptions = {};
    contextMenuOptions.classNames = {
      menu: 'context_menu h5',
      menuSeparator: 'context_menu_separator',
    };

    //	create an array of ContextMenuItem objects
    //	an 'id' is defined for each of the four directions related items
    const menuItems = [];
    menuItems.push({
      className: 'context_menu_item',
      eventName: 'directions_origin_click',
      id: 'directionsOriginItem',
      label: 'Definir posição do cliente',
    });
    menuItems.push({
      className: 'context_menu_item',
      eventName: 'directions_destination_click',
      id: 'directionsDestinationItem',
      label: 'Informar endereço de destino',
    });
    menuItems.push({
      className: 'context_menu_item',
      eventName: 'get_directions_click',
      id: 'getDirectionsItem',
      label: 'Montar rota',
    });
    //	a menuItem with no properties will be rendered as a separator
    //menuItems.push({});
    //menuItems.push({className:'context_menu_item', eventName:'zoom_in_click', label:'Zoom +'});
    //menuItems.push({className:'context_menu_item', eventName:'zoom_out_click', label:'Zoom -'});
    //menuItems.push({});
    //menuItems.push({className:'context_menu_item', eventName:'center_map_click', label:'Centralizar o mapa aqui'});
    contextMenuOptions.menuItems = menuItems;

    const contextMenu = new ContextMenu(this.map, contextMenuOptions);

    google.maps.event.addListener(this.map, 'rightclick', (mouseEvent) => {
      contextMenu.show(mouseEvent.latLng);
    });

    //	create markers to show directions origin and destination
    //	both are not visible by default

    //const {lng, lat, logradouro} = requestOrigin // aqui que eu vou alterar
    //const { lng, lat, logradouro } = this.state.requestOrigin
    //const infowindow = new google.maps.InfoWindow({
    //content: `<p>${logradouro}</p>`
    //});
    const requestMarker = new google.maps.Marker({
      position: new google.maps.LatLng(0, 0),
      map: null,
      title: 'Solicitação',
      icon: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',
      draggable: true,
    });
    bounds.extend(requestMarker.position);
    //this.handleBounds(requestMarker.position)
    this.removeAnMarker(requestMarker);
    this.allMarkers.push(requestMarker);

    // requestMarker.addListener('click', () => {
    //     this.infowindow.open(this.map, requestMarker)
    // })
    requestMarker.addListener('dragend', (point) => {
      this.handleGeocoding(point.latLng.lat(), point.latLng.lng(), 'attendanceEnderecoOrigem');
    });

    // aqui o marker de requisição de origem

    const destinationMarker = new google.maps.Marker({
      position: new google.maps.LatLng(0, 0),
      map: null,
      title: 'Solicitação',
      icon: 'http://icons.iconarchive.com/icons/fatcow/farm-fresh/24/flag-finish-icon.png',
      animation: google.maps.Animation.BOUNCE,
      draggable: true,
    });
    bounds.extend(requestMarker.position);
    //this.handleBounds(destinationMarker.position)
    this.removeAnMarker(destinationMarker);
    this.allMarkers.push(destinationMarker);

    // destinationMarker.addListener('click', () => {
    //     this.infowindow.open(this.map, destinationMarker)
    // });
    destinationMarker.addListener('dragend', (point) => {
      this.handleGeocoding(
        point.latLng.lat(),
        point.latLng.lng(),
        'attendanceEnderecoDestino',
      );
    });

    //	listen for the ContextMenu 'menu_item_selected' event
    google.maps.event.addListener(contextMenu, 'menu_item_selected', (latLng, eventName) => {
      switch (eventName) {
        case 'directions_origin_click':
          this.handleGeocodingMenu(latLng, 'attendanceEnderecoOrigem');
          requestMarker.setPosition(latLng);
          if (!requestMarker.getMap()) {
            requestMarker.setMap(this.map);
          }
          break;
        case 'directions_destination_click':
          this.handleGeocodingMenu(latLng, 'attendanceEnderecoDestino');
          destinationMarker.setPosition(latLng);
          if (!destinationMarker.getMap()) {
            destinationMarker.setMap(this.map);
          }
          break;
        case 'get_directions_click':
          this.handleDirections(this.directionsService, this.directionsDisplay);
          break;
        /*case 'zoom_in_click':
  				this.map.setZoom(this.map.getZoom()+1);
  				break;
  			case 'zoom_out_click':
  				this.map.setZoom(this.map.getZoom()-1);
  				break;
  			case 'center_map_click':
  				this.map.panTo(latLng);
  				break;*/
      }
      if (
        requestMarker.getMap() &&
        destinationMarker.getMap() &&
        document.getElementById('getDirectionsItem').style.display === ''
      ) {
        //	display the 'Get directions' menu item if it is not visible and both directions origin and destination have been selected
        document.getElementById('getDirectionsItem').style.display = 'block';
      }
    });
  }

  handleBounds(bound) {
    bounds.extend(bound);
  }

  handleTimeInactivity(start) {
    let timeOfInactivity;
    let timeToArrive;
    const startDate = moment(start, 'D HH:mm');
    const end = moment();
    const endDate = moment(end, 'D HH:mm');

    // const timeToArrive = moment.duration( startDate.diff( endDate ) ).minutes( ) * -1
    if (startDate && endDate.diff(startDate) < 0) {
      timeToArrive = endDate.diff(startDate, 'minutes', true) * -1;
    }
    if (startDate && endDate.diff(startDate) >= 0) {
      timeToArrive = endDate.diff(startDate, 'minutes', true);
    }
    if (timeToArrive) {
      timeOfInactivity = Math.floor(timeToArrive);
      return timeOfInactivity;
    }
  }

  handlePartnerIcon(partner) {
    const inactivity = this.handleTimeInactivity(partner.dataUltimaPosicao);
    if (inactivity && inactivity >= 30)
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png';
    else if (partner.status.codigo === 'PARCEIROATIVO')
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carBlue.png';
    else if (partner.status.codigo === 'PARCEIROEMATENDIMENTO')
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carGreen.png';
    else if (partner.status.codigo === 'PARCEIROEMDESCANSO')
      return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carYellow.png';
    else return 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png';
  }

  acessoriesTab = (partner) =>
    `${
      partner.veiculo.patins ||
      partner.veiculo.asaDelta ||
      partner.veiculo.capacidadeCarga ||
      partner.veiculo.tamanhoPlataforma
        ? `<div class='acessoriesTab'>
        ${
          partner.veiculo.patins > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner.veiculo.patins}</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/patins.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.asaDelta > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner.veiculo.asaDelta}</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/asa-delta.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.capacidadeCarga > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.capacidadeCarga / 1000
              }k</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carga.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.tamanhoPlataforma > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner.veiculo.tamanhoPlataforma}m</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/tamanho.png"/></div>`
            : '<div></div>'
        }
      </div>`
        : '<div></div>'
    }`;

  getInfoWindowContent(type, marker) {
    this.setState({selectedPartner: marker.codigo});
    if (type === 'partner') {
      return (
        '<div class="form-group">' +
        '<h3>' +
        marker.contato +
        '</h3>' +
        '<h4>' +
        marker.nomeFantasia +
        '</h4>' +
        `<div>${this.acessoriesTab(marker)}</div>` +
        `<a href="http://api.whatsapp.com/send?l=pt_br&phone=55${marker.telefoneCelular
          .match(/[0-9]/g)
          .join('')}" target="blank"> <i class="fab fa-whatsapp"></i> ` +
        marker.telefoneCelular +
        '</a><br/>' +
        '<p>' +
        marker.dataUltimaPosicao +
        '</p>' +
        '<p>' +
        'Versão do App: ' +
        marker.versaoApp +
        '</p>' +
        `<input id=${marker.codigo} onclick="window.test()" type="button" class="btn btn-xs btn-success custom-button-popup" value ="Disponível?"/>` +
        '</div>'
      );
    } else return `<p>teste</p>`;
  }

  populateMarkers() {
    let bounds = new google.maps.LatLngBounds();

    const {
      markList = [],
      requestOrigin,
      requestDestination,
      partners,
      partnerLocation,
      loadedMap = false,
      setLoadedMap = () => {},
    } = this.props;
    if (!requestOrigin && !requestDestination && !markList) {
      return;
    }

    if (markList) {
      markList.map((position) => {
        const requestMarker = new google.maps.Marker({
          position,
          map: this.map,
          title: 'Solicitação',
          icon:
            'http://icons.iconarchive.com/icons/icons8/windows-8/32/Transport-Car-icon.png',
        });
        bounds.extend(requestMarker.position);
        //this.handleBounds(requestMarker.position)
        this.allMarkers.push(requestMarker);
      });
    }

    if (requestOrigin) {
      //const {lng, lat, logradouro} = requestOrigin // aqui que eu vou alterar
      const {lng, lat, logradouro} = this.state.requestOrigin;

      const requestMarker = new google.maps.Marker({
        position: {lng, lat},
        map: this.map,
        title: 'Solicitação',
        icon: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',
        draggable: true,
      });
      bounds.extend(requestMarker.position);
      //this.handleBounds(requestMarker.position)
      this.allMarkers.push(requestMarker);

      // requestMarker.addListener('click', () => {
      //     this.infowindow.open(this.map, requestMarker);
      // });
      requestMarker.addListener('dragend', (point) => {
        this.handleGeocoding(
          point.latLng.lat(),
          point.latLng.lng(),
          'attendanceEnderecoOrigem',
        );
      });
    }

    if (requestDestination) {
      // const {lng, lat, logradouro} = requestDestination // aqui eu mudei
      const {lng, lat, logradouro} = this.state.requestDestination;

      const destinationMarker = new google.maps.Marker({
        position: {lng, lat},
        map: this.map,
        animation: google.maps.Animation.BOUNCE,
        title: 'Destino',
        icon: 'http://icons.iconarchive.com/icons/fatcow/farm-fresh/24/flag-finish-icon.png',
        draggable: true,
      });

      bounds.extend(destinationMarker.position);
      //this.handleBounds(destinationMarker.position)
      this.allMarkers.push(destinationMarker);

      // destinationMarker.addListener('click', () => {
      //     this.infowindow.open(this.map, destinationMarker);
      // });
      destinationMarker.addListener('dragend', (point) => {
        this.handleGeocoding(
          point.latLng.lat(),
          point.latLng.lng(),
          'attendanceEnderecoDestino',
        );
      });
    }

    if (partnerLocation) {
      const partnerLocationMarker = new google.maps.Marker({
        position: partnerLocation,
        map: this.map,
        title: 'Destino',
        icon: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carGreen.png',
      });

      bounds.extend(partnerLocationMarker.position);
      //this.handleBounds(destinationMarker.position)
      this.allMarkers.push(partnerLocationMarker);
    }
    if (partners && partners.length > 0) {
      partners.forEach((p, i) => {
        let newPartner = new google.maps.Marker({
          position: {lat: p.associado.latitude, lng: p.associado.longitude},
          map: this.map,
          title: 'guincho' + i,
          icon: this.handlePartnerIcon(p.associado),
        });
        if (this.props.isAdmin) {
          newPartner.addListener('click', () => {
            this.infowindow.setContent(this.getInfoWindowContent('partner', p.associado));
            this.infowindow.open(this.map, newPartner);
          });
        }
        newPartner.set('id', 'guincho' + i);
        this.allMarkers.push(newPartner);
      });
      //bounds.extend({ lat: partners[partners.length - 1].associado.latitude, lng: partners[partners.length - 1].associado.longitude})
    }
    // clearInterval(this.rotateInterval)
    // google.maps.event.clearListeners(this.map, 'idle');
    // if (partners && partners.length > 0) {
    //     google.maps.event.addListenerOnce(this.map, 'idle', () => {
    //         this.rotateInterval = setInterval(this.rotatePartners, 200)
    //     });
    // }

    // Don't zoom in too far on only one marker
    if (!markList.length || !loadedMap) {
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01,
        );
        const extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01,
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      if (markList.length || requestOrigin || !loadedMap) {
        this.map.fitBounds(bounds);
        google.maps.event.trigger(this.map, 'resize');
      }
      if (!loadedMap) {
        setLoadedMap();
      }
      if (
        this.state.hasDirections === true &&
        this.state.requestOrigin &&
        this.state.requestDestination
      ) {
        this.handleSimpleLine(this.map);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showCreateSolicitation != prevProps.showCreateSolicitation) {
      this.initMap();
      this.directionsDisplay.setMap(this.map);
    } else {
      this.removeAllMarkers(this.map, this.allMarkers);
      this.populateMarkers();
    }
  }

  removeAllMarkers(map, allMarkers) {
    for (var marker of allMarkers) {
      marker.setMap(null);
    }
  }

  removeAnMarker(marker) {
    marker.setMap(null);
  }

  render() {
    return <div className="requestMap" id={this.props.id} style={this.props.style} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    selectedItem: state.address[ownProps.id],
    favoriteDestinationsList: state.address.favoriteDestinationsList,
    showCreateSolicitation: state.attendance.showCreateSolicitation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    markerChanged: (address, id) => {
      dispatch(onAddressChange(id, address));
      dispatch(onAddressSelect());
    },
    routeErr: (msg, err) => {
      dispatch(toastError(msg));
    },
    onClear: (context) => {
      dispatch(clearAddress(context));
    },
    changeField: (field, value) => dispatch(onFieldChange(field, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceSolicitationMap);
