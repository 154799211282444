import React from "react";

const IconBadge = ({ typeList, type, handlePress }) => {
  let typeInfo = typeList[type] || {
    color: "primary",
    icon: "eye",
    description: type
  };

  return (
    <span
      className={"badge badge-" + typeInfo.color}
      style={{
        marginLeft: "3px"
      }}
    >
      <button
        onClick={handlePress}
        style={{ background: "transparent", border: "0px", outline: "0px" }}
      >
        <i className={"fa fa-" + typeInfo.icon}> </i> {typeInfo.description}
      </button>
    </span>
  );
};

export default IconBadge;
