import React from 'react';
import PropTypes from 'prop-types';
export const Step = ({title, children}) => <div>{children}</div>;

class WizardAdapter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {disabled, index, handleTab, contentColor} = this.props;

    return (
      <div id="wizard" role="application" className="wizard clearfix">
        <div
          className="steps clearfix"
          style={{
            width: '100%',
            marginLeft: '1%',
            textAlign: 'center',
          }}>
          <ul role="tablist">
            {this.props.children
              .filter((child) => child)
              .map((child, i) => {
                if (child) {
                  return (
                    <li
                      key={`stepHeader${i}`}
                      role="tab"
                      style={{display: child.props.hide ? 'none' : 'block'}}
                      className={index === i ? 'current' : 'done'}>
                      <a
                        id={`wizard-t-${i}`}
                        href="#"
                        aria-controls={`wizard-p-${i}`}
                        onClick={(e) => {
                          e.preventDefault();
                          !disabled && handleTab(i);
                        }}>
                        {child.props.title}
                      </a>
                    </li>
                  );
                }
              })}
          </ul>
        </div>
        <div
          className="content clearfix"
          style={{
            backgroundColor: contentColor ? contentColor : '#eee',
            paddingBottom: '70px',
          }}>
          {React.Children.toArray(this.props.children)
            .filter((child, i) => i === index)
            .map((child, i) => (
              <div
                style={{paddingBottom: '70px'}}
                key={`child${i}`}
                className="step-content body current"
                id="wizard-p-0"
                role="tabpanel"
                aria-labelledby="wizard-h-0"
                aria-hidden="false">
                {child}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

WizardAdapter.propTypes = {
  index: PropTypes.number,
  handleTab: PropTypes.func,
  disabled: PropTypes.bool,
};

export default WizardAdapter;
