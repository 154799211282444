import React, {Component} from 'react';

class Main extends Component {
  render() {
    return (
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center m-t-lg">
              <h1>Welcome in INSPINIA ReactJS Seed Project</h1>
              <small>
                It is an application skeleton for a typical web app. You can use it to quickly
                bootstrap your webapp projects.
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
