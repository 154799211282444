export const translationMap = {
  createdAt: 'dataCadastro',
  updatedAt: 'dataAlteracao',
  customerArrivalDate: 'dataChegadadoParceiro',
  concludedAt: 'dataConcluida',
  accessoryPriceWithoutFee: 'valorAcessorios',
  downtimePriceWithoutFee: 'valorHoraParada',
  justServicePriceWithoutFee: 'valorServico',
  justServicePriceByPartner: 'valorServicoParceiro',
  tollPrice: 'valorPedagio',
  totalServicePriceByPartner: 'valorParceiro',
  totalServicePriceWithoutFee: 'valorCorrida',
  workingTimePriceWithoutFee: 'valorHoraParada',
  comission: 'comissaoReboqueme',
  name: 'nome',
  hasAccessChat: 'acessoChat',
  photoUrl: 'linkFotoPerfil',
  customer: 'cliente',
  code: 'codigo',
  cellPhone: 'telefoneCelular',
  manualConclusion: 'acionamentoManual',
  company: 'empresa',
  fantasyName: 'nomeFantasia',
  vehicle: 'veiculoCliente',
  brand: 'marca',
  plate: 'placa',
  model: 'modelo',
  service: 'tipoServico',
  description: 'descricao',
  acceptedAnswer: 'respostaAceita',
  acceptedAt: 'dataAceite',
  expectedArrival: 'dataPrevisaoChegada',
  partner: 'parceiro',
  dateLastPosition: 'dataUltimaPosicao',
  companyName: 'nomeFantasia',
  destinationAddress: 'enderecoDestino',
  city: 'cidade',
  country: 'pais',
  place: 'logradouro',
  district: 'bairro',
  streetNumber: 'numero',
  state: 'estado',
  complement: 'complemento',
  zipCode: 'cep',
  sourceAddress: 'enderecoOrigem',
  observation: 'observacao',
  attendance: 'atendimento',
  request: 'requisicao',
  reason: 'tipoMotivo',
  description: 'descricao',
  departments: 'departamento',
  distanceFromCustomer: 'distanciaSolicitante',
  totalDistance: 'distanciaTotal',
  createdAtFrom: 'dataCadastro',
  ownerUser: 'usuarioResponsavel',
  requestType: 'tipoRequisicao',
  stage: 'estagio',
  vechicle: 'veiculo',
  address: 'endereco',
  requestOrigin: 'origemRequisicao',
  phone: 'telefone',
  user: 'usuario',
  contact: 'contato',
};
export const attendanceTranslationMap = {
  createdAt: 'dataCadastro',
  updatedAt: 'dataAlteracao',
  customerArrivalDate: 'dataChegadadoParceiro',
  concludedAt: 'dataConcluida',
  accessoryPriceWithoutFee: 'valorAcessorios',
  downtimePriceWithoutFee: 'valorHoraParada',
  justServicePriceWithoutFee: 'valorServico',
  justServicePriceByPartner: 'valorServicoParceiro',
  tollPrice: 'valorPedagio',
  totalServicePriceByPartner: 'valorParceiro',
  totalServicePriceWithoutFee: 'valorCorrida',
  workingTimePriceWithoutFee: 'valorHoraParada',
  comission: 'comissaoReboqueme',
  name: 'nome',
  hasAccessChat: 'acessoChat',
  photoUrl: 'linkFotoPerfil',
  customer: 'cliente',
  code: 'codigo',
  cellPhone: 'telefoneCelular',
  manualConclusion: 'acionamentoManual',
  company: 'empresa',
  fantasyName: 'nomeFantasia',
  brand: 'marca',
  plate: 'placa',
  model: 'modelo',
  service: 'tipoServico',
  description: 'descricao',
  acceptedAnswer: 'respostaAceita',
  acceptedAt: 'dataAceite',
  expectedArrival: 'dataPrevisaoChegada',
  partner: 'parceiro',
  dateLastPosition: 'dataUltimaPosicao',
  companyName: 'nomeFantasia',
  destinationAddress: 'enderecoDestino',
  city: 'cidade',
  country: 'pais',
  place: 'logradouro',
  district: 'bairro',
  streetNumber: 'numero',
  state: 'estado',
  complement: 'complemento',
  zipCode: 'cep',
  sourceAddress: 'enderecoOrigem',
  observation: 'observacao',
  attendance: 'atendimento',
  request: 'requisicao',
  reason: 'tipoMotivo',
  description: 'descricao',
  departments: 'departamento',
  distanceFromCustomer: 'distanciaSolicitante',
  totalDistance: 'distanciaTotal',
  createdAtFrom: 'dataCadastro',
  ownerUser: 'usuarioResponsavel',
  requestType: 'tipoRequisicao',
  stage: 'estagio',
  vehicle: 'veiculo',
  address: 'endereco',
  requestOrigin: 'origemRequisicao',
  phone: 'telefone',
  user: 'usuario',
  contact: 'contato',
  warrantyFinalDate: 'dataFinalGarantia',
  extendedWarrantyDescription1: 'dataFinalGarantiaEstendida1',
  extendedWarrantyDescription2: 'descricaoGarantiaEstendida2',
  extendedWarrantyFinalDate1: 'dataFinalGarantiaEstendida1',
  extendedWarrantyFinalDate2: 'dataFinalGarantiaEstendida2',
  favoriteAddress: 'enderecoFavorito',
  distanceUntilCustomer: 'distanciaAteoCliente',
  defectType: 'tipoDefeito',
  currentKm: 'kmAtual',
  truckLoad: 'tipoCarga',
  idTruckLoad: 'tipoCargaDaf',
  timeUntilCustomer: 'tempoAteoCliente',
  wakeUpDate: 'dataParaAcordar',
  complaintType: 'tipoReclamacao',
};
export const baseTranslationMap = {
  createdAt: 'dataCadastro',
  contact: 'contato',
  document: 'documento',
  status: 'statusId',
  companyName: 'razaoSocial',
  tradingName: 'nomeFantasia',
  cellPhone: 'telefoneCelular',
  code: 'codigo',
};

export function translateObjectKeys(obj, translationMap) {
  return Object.entries(obj).reduce((newObj, [key, value]) => {
    const newKey = translationMap[key] || key;
    newObj[newKey] =
      typeof value === 'object' && value !== null && !Array.isArray(value)
        ? translateObjectKeys(value, translationMap)
        : value;
    return newObj;
  }, {});
}

export function formatDateFields(obj) {
  const keysToFormat = [
    'dataAlteracao',
    'dataCadastro',
    'dataChegadadoParceiro',
    'dataConcluida',
    'dataAceite',
    'dataPrevisaoChegada',
    'dataFinalGarantia',
    'dataParaAcordar',
  ];

  return Object.entries(obj).reduce((newObj, [key, value]) => {
    if (
      keysToFormat.includes(key) &&
      (value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value))))
    ) {
      let date = new Date(value);
      newObj[key] = date.toLocaleDateString('pt-BR') + ' ' + date.toLocaleTimeString('pt-BR');
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      newObj[key] = formatDateFields(value);
    } else {
      newObj[key] = value;
    }
    return newObj;
  }, {});
}

export function formatData(datetime) {
  return `${datetime.slice(5, 7)}/${datetime.slice(8, 10)}/${datetime.slice(
    0,
    4,
  )} ${datetime.slice(11, 19)}`;
}
