import React from 'react'
import { Field } from 'redux-form'
import { renderField } from "./FormValidation";

const TextField = ({ name, label, validate, placeholder, icon, type, ...otherProps}) =>{
    return(
        <Field
            name={name}
            type={type || 'text'}
            component={renderField}
            label={label}
            validate={validate}
            placeholder={placeholder}
            icon={icon}
            //pattern={type === 'number' ? "\/d*": null}
            {...otherProps}
        />
    )
}
export default TextField
