import React, {Component} from 'react';
import './style/followUpStyle.css';

export default class FollowUpExtendTime extends Component {
  constructor(props) {
    super(props);
    this.state = {show: false};
  }

  render() {
    const {handlePress, expireSolicitation} = this.props;
    return (
      <div>
        <div className="col-md-12 col-md-12">
          <button className="btn btn-block btn-primary" onClick={() => handlePress(30)}>
            Estender +30
          </button>
        </div>
        <div className="col-md-12 col-md-12">
          <button className="btn btn-block btn-danger" onClick={() => expireSolicitation()}>
            Expirar
          </button>
        </div>
      </div>
    );
  }
}

const nearButton = {
  padding: '3px',
};
