import { getDashboards } from '../api/financeiro_helper'

export function loadDashboards ( startDate, endDate ) {
  return dispatch => {
    getDashboards( startDate, endDate )
    .then(( response ) => {
      dispatch({
        type: LOAD_DASHBOARDS,
        value: response.listBase
      })
    })
  }
}

export function createDashboards ( rows ) {
  return dispatch => {
    postDashboards( rows )
    .then(( response ) => {
      console.log(response)
    })
  }
}

export const LOAD_DASHBOARDS = `LOAD_DASHBOARDS`
export const CREATE_DASHBOARDS = `CREATE_DASHBOARDS`