import React from 'react';
import InputElement from 'react-input-mask'

export default class MaskedInput extends React.Component {

  componentWillReceiveProps(nextProps){
    if(!nextProps.value)
      this.refs.inputElement.setState({value: ""})
  }

  render() {
    return (
      <InputElement autoComplete="new-password" ref="inputElement" {...this.props}/>
    )
  }
}
