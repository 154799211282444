import React from "react";
import { doCloseModal } from "../common/CustomModal";
import MaskedInput from "react-input-mask";

class DriverForm extends React.Component {
  constructor(props) {
    super(props);
    this.showForm = this.showForm.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  showForm(event) {
    this.props.onChange({
      target: { id: event.target.id, value: event.target.checked },
    });
  }

  onClose(event) {
    event.preventDefault();
    doCloseModal(this.props.context);
  }

  handleNomeMotorista = event => {
    if (/\d/.test(event.target.value)) return;
    if (event.target.value.length > 45) return;
    this.props.onChange(event);
  }

  render() {
    
    return (
      <div className="row-fluid">
        <form  className="form-horizontal" onSubmit={this.onClose}>
          <div className="form-group">
            <div className="col-xs-6">
              <input
                value={this.props.nomeMotorista || ""}
                className="form-control"
                id="nomeMotorista"
                type="text"
                pattern="[A-Za-z]+"
                onChange={this.handleNomeMotorista}
                placeholder="Nome do motorista"
              />
            </div>
            <div className="col-xs-6">
              <MaskedInput
                id="telefoneCelularMotorista"
                onChange={this.props.onChange}
                placeholder="Telefone do motorista"
                className="form-control"
                value={this.props.telefoneCelularMotorista}
                mask="(99) 9 9999-9999"
              />
              {/* <input value={this.props.telefoneCelularMotorista} className="form-control" id="telefoneCelularMotorista" onChange={this.props.onChange}  placeholder="Telefone do motorista"/> */}
            </div>
          </div>
          {/* <button type="submit" className="btn btn-block btn-block btn-primary"><i className="fa fa-check"></i> Confirmar</button> */}
        </form>
      </div>
    );
  }
}

export default DriverForm;
