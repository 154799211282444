import React from 'react';

function InvoiceItem({invoice, handleInvoiceInput}) {
  return (
    <div className="col-md-12" style={{padding: '3px'}}>
      <div className="col-md-1 form-group">
        <span style={invoice.nfLink ? {color: '#4ab858'} : {color: 'inherit'}}>
          <i className={invoice.type === 'empresa' ? 'fas fa-building' : 'fas fa-truck'} />
        </span>
      </div>
      <div className="col-md-3 form-group">
        <span style={{whiteSpace: 'nowrap'}} title={invoice.nomeFantasia}>
          {invoice.nomeFantasia.length > 20
            ? invoice.nomeFantasia.substr(0, 20) + '...'
            : invoice.nomeFantasia}
        </span>
      </div>
      <div className="col-md-6 form-group" style={{whiteSpace: 'nowrap'}}>
        <input
          type="file"
          className="form-control"
          // placeholder={invoice.nfLink ? invoice.nfLink : ''}
          // value={this.state.invoiceLink[i] || ''}
          onChange={(event) => handleInvoiceInput(event, invoice.code)}
        />
      </div>
    </div>
  );
}

export default InvoiceItem;
