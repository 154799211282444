import React from 'react';

import swal from 'sweetalert';
import Toggle from 'react-toggle-button';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import WarningAttendance from './WarningAttendance';
import AttendanceSolicitationMap from './AttendanceSolicitationMap';
import CustomModal, {doCloseModal, doOpenModal} from '../CustomModal';
import VehicleStatusForm from '../../forms/VehicleStatusForm';
import AddressAutoComplete from '../../containers/AddressAutoComplete';
import OptionChooser from '../../common/OptionChooser';
import {
  CODIGO_JUSTOS,
  USUARIO_TIPO_REBOQUEME,
  USUARIO_TIPO_EMPRESA,
  CODIGO_DAF,
} from '../../../reducers/constants';
import ObservationForm from '../../../ObservationForm';
import {getPricePrediction} from '../../../api/solicitation_helper';
import {getFavoriteAttendanceDestinations} from '../../../api/attendence_helper';
import {toastError} from '../../../actions/toast_actions';
import {onFieldChange} from '../../../actions/service_actions';
import {CompaniesContracts} from '../../../actions/relatorio_actions';
import {
  setAttendanceTypeService,
  setAttendanceVehicleState,
  newAttendanceSolicitation,
  getPriceAndTimePrediction,
  setNewAttendanceSolicitationVisibility,
  findAttendanceSolicitation,
} from '../../../actions/attendence_actions';
import {updateMotivoPatio} from '../../../actions/attendence_actions';
import {clearAddress, onAddressChange} from '../../../actions/address_actions';

import './newAttendanceSolicitation.css';

class NewAttendanceSolicitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      protocol: '',
      observation: '',
      franchise: this.props.attendance.empresa.codigo === CODIGO_JUSTOS,
      agendamento: null,
      origemCleanup: false,
      destinyCleanup: false,
      motivoPatio: '',
      showMotivoPatio: false,
      isAgendado: false,
      dataAgendamento: null,
      serviceType: 'car',
      enderecoDestino: '',
      AddressAfterPatio: true,
    };
    this.switchServiceType = this.switchServiceType.bind(this);
    this.onChangeProtocol = this.onChangeProtocol.bind(this);
    this.doServiceChange = this.doServiceChange.bind(this);
    this.onChangeObservation = this.onChangeObservation.bind(this);
    this.onChangeFranchise = this.onChangeFranchise.bind(this);
    this.onChangeVehicleForm = this.onChangeVehicleForm.bind(this);
    this.onChangeRoller = this.onChangeRoller.bind(this);
    this.saveSolicitation = this.saveSolicitation.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.doAfterCreate = this.doAfterCreate.bind(this);
    this.bookSolicitation = this.bookSolicitation.bind(this);
    this.prepareBookSolicitation = this.prepareBookSolicitation.bind(this);
    this.toggleStartAddres = this.toggleStartAddres.bind(this);
  }

  doServiceChange(newValue) {
    this.props.setVehicleState({id: 'blindado', value: false});
    this.props.setVehicleState({id: 'rodasTravadas', value: false});
    this.props.setVehicleState({id: 'quantidadeRodasTravadas', value: 0});

    const {setServiceType} = this.props;
    setServiceType(newValue);
  }
  switchServiceType(type) {
    this.setState({serviceType: type});
  }

  doAfterCreate(response) {
    const {
      codigoAtendimento,
      findAttendanceSolicitation,
      setNewSolicitationVisibility,
    } = this.props;

    this.clearFields();
    findAttendanceSolicitation(codigoAtendimento);
    setNewSolicitationVisibility(false);
  }

  onChangeProtocol(event) {
    this.setState({
      ...this.state,
      protocol: event.target.value,
    });
  }
  toggleStartAddres(value) {
    this.setState({
      AddressAfterPatio: !value,
    });
    this.props.onCheckChange('AddressAfterPatio', !value);
  }
  clearFields() {
    const {attendance, enderecoOrigem} = this.props;
    this.props.setServiceType('REBOQUE');
    this.props.setVehicleState({id: 'blindado', value: false});
    this.props.setVehicleState({id: 'rodasTravadas', value: false});
    this.props.setVehicleState({id: 'quantidadeRodasTravadas', value: 0});
    // const originChanged = attendance.endereco.logradouro !== enderecoOrigem;

    const originChanged = attendance.endereco.logradouro !== enderecoOrigem;

    this.props.clearAddressField('attendanceEnderecoDestino');
    // this.props.clearAddressField('attendanceEnderecoOrigem');

    this.setState((prevState) => ({
      ...this.state,
      // origemCleanup: originChanged? !prevState.origemCleanup: prevState.origemCleanup,
      destinyCleanup: !prevState.destinyCleanup,
      protocol: '',
      observation: '',
      franchise: this.props.attendance.empresa.codigo === CODIGO_JUSTOS,
    }));
  }

  async bookSolicitation() {
    const {dataAgendamento} = this.state;
    if (dataAgendamento) {
      this.prepareBookSolicitation(dataAgendamento);
    } else {
      swal(
        {
          title: 'Agendar atendimento',
          text: 'Horário que prestador deve chegar ao cliente',
          type: 'input',
          inputType: 'datetime-local',
          inputValue: moment().local().format('YYYY-MM-DDTHH:mm'),
          showCancelButton: true,
          closeOnConfirm: false,
          animation: 'slide-from-top',
          //showLoaderOnConfirm: true
        },
        (inputValue) => {
          if (inputValue === false) return false;

          if (inputValue === '') {
            swal.showInputError('Por favor selecione uma data!');
            return false;
          }

          const agendamento = moment(inputValue).format('YYYY-MM-DD HH:mm:ss');
          this.setState({dataAgendamento: agendamento});
          this.prepareBookSolicitation(agendamento);
        },
      );
    }
  }

  async prepareBookSolicitation(Date) {
    const {
      codigoAtendimento,
      rodasTravadas,
      blindado,
      attendance,
      tipoServico,
      getTimeAndPredition,
      enderecoOrigem,
      quantidadeRodasTravadas,
    } = this.props;
    const {motivoPatio} = this.props;
    const {dataAgendamento} = this.state;
    const dataAgendada = dataAgendamento ? dataAgendamento : Date;
    const pedido = {
      situacaoVeiculo: {
        rodasTravadas: rodasTravadas ? rodasTravadas : false,
        blindado: blindado ? blindado : null,
        capotado: null,
        garagem: null,
        semRodas: null,
        quantidadeRodasTravadas: quantidadeRodasTravadas ? quantidadeRodasTravadas : 0,
      },
      agendado: true,
      dataAgendada,
      codigoCliente: attendance.veiculo ? attendance.veiculo.cliente.codigo : null,
      ownId: this.state.protocol,
      observacao: this.state.observation,
      usarFranquia: this.state.franchise,
      codigoEmpresa: attendance.empresa.codigo,
      enderecoOrigem: enderecoOrigem ? enderecoOrigem : attendance.endereco,
      enderecoDestino: this.props.enderecoDestino,
      veiculoCliente: attendance.veiculo ? attendance.veiculo.codigo : null,
      tipoServico,
      codigoAtendimento,
      motivoPatio,
    };
    this.setState({
      isAgendado: true,
      showMotivoPatio: false,
    });
    try {
      const response = await getPricePrediction({
        ...pedido,
        company: {value: attendance.empresa.codigo},
        rodasTravadas,
        blindado,
        veiculoCliente: attendance.veiculo,
        quantidadeRodasTravadas,
      });

      pedido.price = response.valor;
      pedido.time = response.tempoEspera;
      pedido.kmExcedente = response.kmExcedente;
      // this.props.saveSolicitation(codigoAtendimento, pedido);
      getTimeAndPredition(pedido, this.doAfterCreate);
    } catch (err) {
      toastError(err.mensagemCliente);
    }
  }
  handleMotivoPatioChange(event) {
    this.props.updateMotivoPatio(event.target.value);
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.showMotivoPatio && this.props.showMotivoPatio) {
      this.setState({showMotivoPatio: true});
      doOpenModal('CONTEXT_MOTIVO_PATIO');
    }
  }
  handleSubmitMotivoPatio() {
    this.saveSolicitation();
    this.setState({showMotivoPatio: false});
  }
  async saveSolicitation() {
    const {
      codigoAtendimento,
      rodasTravadas,
      blindado,
      attendance,
      tipoServico,
      getTimeAndPredition,
      enderecoOrigem,
      quantidadeRodasTravadas,
    } = this.props;
    const {motivoPatio} = this.props;
    const {isAgendado} = this.state;
    const pedido = {
      situacaoVeiculo: {
        rodasTravadas: rodasTravadas ? rodasTravadas : false,
        blindado: blindado ? blindado : null,
        capotado: null,
        garagem: null,
        semRodas: null,
        quantidadeRodasTravadas: quantidadeRodasTravadas ? quantidadeRodasTravadas : 0,
      },
      codigoCliente: attendance.veiculo ? attendance.veiculo.cliente.codigo : null,
      ownId: this.state.protocol,
      observacao: this.state.observation,
      usarFranquia: this.state.franchise,
      codigoEmpresa: attendance.empresa.codigo,
      enderecoOrigem: enderecoOrigem ? enderecoOrigem : attendance.endereco,
      enderecoDestino: this.props.enderecoDestino,
      veiculoCliente: attendance.veiculo ? attendance.veiculo.codigo : null,
      tipoServico,
      codigoAtendimento,
      motivoPatio: motivoPatio,
    };
    if (this.state.showMotivoPatio) pedido.motivoPatio = this.props.motivoPatio;
    if (this.state.AddressAfterPatio) {
      pedido.enderecoDestinoAposPatio = {
        bairro: this.props.bairro,
        cep: this.props.cep,
        cidade: this.props.cidade,
        estado: this.props.estado,
        latitude: this.props.latDest,
        logradouro: this.props.logradouro,
        longitude: this.props.longDest,
        numero: this.props.numero,
        pais: this.props.pais,
      };
    }

    try {
      const response = await getPricePrediction({
        ...pedido,
        company: {value: attendance.empresa.codigo},
        rodasTravadas,
        blindado,
        veiculoCliente: attendance.veiculo,
        quantidadeRodasTravadas,
        agendado: isAgendado || false,
      });

      pedido.price = response.valor;
      pedido.time = response.tempoEspera;
      pedido.kmExcedente = response.kmExcedente;
      // this.props.saveSolicitation(codigoAtendimento, pedido);
      getTimeAndPredition(pedido, this.doAfterCreate);
      doCloseModal('CONTEXT_MOTIVO_PATIO');
      this.setState({showMotivoPatio: false});
    } catch (err) {
      toastError(err.mensagemCliente);
    }
  }
  handleButtonClick() {
    if (this.state.isAgendado) {
      this.bookSolicitation();
    } else {
      this.handleSubmitMotivoPatio();
    }
  }
  onChangeRoller(id, value) {
    this.props.setVehicleState({id, value});
  }

  onChangeVehicleForm(id, value) {
    if (id === 'rodasTravadas') {
      this.onChangeRoller('quantidadeRodasTravadas', 1);
    }
    this.props.setVehicleState({id, value});
  }

  onChangeObservation(event) {
    this.setState({
      ...this.state,
      observation: event.target.value,
    });
  }
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  handleObservationPesado(text, isAdding) {
    let {observation} = this.state;
    if (isAdding) {
      observation += text;
    } else {
      const removeText = text.replace('Remover ', '');
      observation = observation.replace(new RegExp(this.escapeRegExp(removeText), 'g'), '');
    }

    this.setState({observation});
  }

  componentWillUnmount() {
    this.clearFields();
    doCloseModal('CONTEXT_MOTIVO_PATIO');
  }

  onChangeFranchise(event) {
    this.setState({
      ...this.state,
      franchise: event.target.checked,
    });
  }

  handleDisabledSolicitation() {
    const {enderecoOrigem, enderecoDestino} = this.props;
    if (!enderecoOrigem) return true;
    if (!enderecoDestino) return true;

    if (!enderecoOrigem.hasOwnProperty('logradouro')) return true;
    if (!enderecoDestino.hasOwnProperty('logradouro')) return true;

    return false;
  }

  disableMechanicButton() {
    const {userInfo} = this.props;
    const dafCode = '2322661870558778503';

    const isSoonUser = userInfo.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;
    const isDafUser = userInfo.empresa.codigo === dafCode;

    return !isSoonUser && !isDafUser;
  }

  canCreateSolicitation() {
    const {userInfo} = this.props;

    const koviId = '2200248530130441273';
    const justosId = '3251918332755973547';
    const dafId = CODIGO_DAF;

    const isSoonUser = userInfo.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;
    const isKovi = userInfo.empresa.codigo === koviId;
    const isJustos = userInfo.empresa.codigo === justosId;
    const isDaf = userInfo.empresa.codigo === dafId;

    return isSoonUser || isKovi || isJustos || isDaf;
  }

  render() {
    const {showInfo, attendance, tipoServico, userInfo} = this.props;
    const {doServiceChange} = this;
    const codigoEmpresa = attendance.empresa.codigo;
    const contractType = this.props.attendance.empresa.tipoContrato.codigo;
    const client = attendance.cliente ? attendance.cliente.nome : '';
    const vehicle = attendance.veiculo ? attendance.veiculo.modelo : '';
    const contrato = attendance.veiculo ? attendance.veiculo.contrato : '';
    const enderecoFavorito = this.props.enderecoDestino;
    const isSoonUser = userInfo.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;
    return (
      <div className={classnames({hide: !showInfo})}>
        <div className="row">
          <div className="col-md-4">
            <h3>Cliente</h3>
            <p>{client}</p>
          </div>
          <div className="col-md-4">
            <h3>Veículo</h3>
            <p>{vehicle}</p>
          </div>
          <div className="col-md-4">
            <h3>Produto</h3>
            <p>{contrato ? contrato.produto.descricao : ''}</p>
          </div>
        </div>
        <hr />
        <div style={{marginTop: '20px'}} className="row">
          <div className="col-md-6">
            <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
              <h4>Serviços</h4>
              <div className="row">
                <div className="col-md-12">
                  <p style={{textAlign: 'left', lineHeight: '35px'}}>
                    <OptionChooser
                      context="attendance"
                      keyField="tipoServico"
                      optionKey="REBOQUE"
                      text="Reboque"
                      onOptionChanged={doServiceChange}
                      icon="fa fa-truck"
                    />
                    <OptionChooser
                      context="attendance"
                      keyField="tipoServico"
                      optionKey="BATERIA"
                      text="Bateria"
                      onOptionChanged={doServiceChange}
                      icon="fa fa-battery-quarter"
                    />
                    <OptionChooser
                      context="attendance"
                      keyField="tipoServico"
                      optionKey="PANESECA"
                      text="Pane Seca"
                      onOptionChanged={doServiceChange}
                      icon="fa fa-flask"
                    />
                    <OptionChooser
                      context="attendance"
                      keyField="tipoServico"
                      optionKey="TROCAPNEU"
                      text="Pneu"
                      onOptionChanged={doServiceChange}
                      icon="far fa-circle"
                    />
                    <OptionChooser
                      context="attendance"
                      keyField="tipoServico"
                      optionKey="SUBSOLO"
                      text="Subsolo"
                      onOptionChanged={doServiceChange}
                      icon="fa fa-truck"
                    />
                    <OptionChooser
                      context="attendance"
                      keyField="tipoServico"
                      optionKey="MECANICO"
                      text="Mecânico"
                      disabled={this.disableMechanicButton()}
                      onOptionChanged={doServiceChange}
                      icon="fas fa-wrench"
                    />
                    <OptionChooser
                      context="attendance"
                      keyField="tipoServico"
                      optionKey="CHAVEIRO"
                      text="Chaveiro"
                      onOptionChanged={doServiceChange}
                      icon="fa fa-key"
                    />
                    {codigoEmpresa === CODIGO_JUSTOS && (
                      <OptionChooser
                        context="attendance"
                        keyField="tipoServico"
                        optionKey="VIDROSAUTO"
                        text="Vidraceiro Automotivo"
                        onOptionChanged={doServiceChange}
                        doAfterChange={this.props.clearRoller}
                        icon="fas fa-car-side"
                      />
                    )}
                    {codigoEmpresa === CODIGO_JUSTOS && (
                      <OptionChooser
                        context="attendance"
                        keyField="tipoServico"
                        optionKey="TAXI"
                        text="Transporte de Passageiro "
                        onOptionChanged={doServiceChange}
                        doAfterChange={this.props.clearRoller}
                        icon="fa fa-taxi"
                      />
                    )}
                    {codigoEmpresa === CODIGO_JUSTOS && (
                      <OptionChooser
                        context="attendance"
                        keyField="tipoServico"
                        optionKey="CARRORESERVA"
                        text="Carro Reserva"
                        onOptionChanged={doServiceChange}
                        doAfterChange={this.props.clearRoller}
                        icon="fa fa-shuttle-van"
                      />
                    )}
                    {codigoEmpresa === CODIGO_JUSTOS && (
                      <OptionChooser
                        context="attendance"
                        keyField="tipoServico"
                        optionKey="VIDROSAUTO"
                        text="Vidraceiro Automotivo"
                        onOptionChanged={doServiceChange}
                        doAfterChange={this.props.clearRoller}
                        icon="fas fa-car-side"
                      />
                    )}
                    {codigoEmpresa === CODIGO_JUSTOS && (
                      <div style={{marginTop: '10px'}}>
                        <h4>Serviços Residenciais</h4>
                        <p style={{textAlign: 'left', lineHeight: '35px'}}>
                          <OptionChooser
                            context="attendance"
                            keyField="tipoServico"
                            optionKey="CHAVEIROHOME"
                            text="Chaveiro Residencial"
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fa fa-key"
                            isALgo="CHAVEIROHOME"
                          />
                          <OptionChooser
                            context="attendance"
                            keyField="tipoServico"
                            optionKey="HIDRAULICAHOME"
                            text="Hidráulica Residencial"
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fa fa-wrench"
                            isALgo="HIDRAULICAHOME"
                          />
                          <OptionChooser
                            context="attendance"
                            keyField="tipoServico"
                            optionKey="VIDROSHOME"
                            text="Vidraceiro Residencial"
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fa fa-window-maximize"
                          />
                          <OptionChooser
                            context="attendance"
                            keyField="tipoServico"
                            optionKey="ELETRICAHOME"
                            text="Elétrica Residencial "
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fa fa-bolt"
                          />
                        </p>
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </form>

            <div className="row" style={{marginTop: '5px'}}>
              <div className="col-md-12">
                <h4>Endereço Origem</h4>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fas fa-location-arrow" />
                  </span>
                  <AddressAutoComplete
                    placeholder="Endereço Origem"
                    id="attendanceEnderecoOrigem"
                    favoriteDestinations
                    initialValue={
                      this.props.enderecoOrigem
                        ? this.props.enderecoOrigem.logradouro
                        : this.props.service.enderecoDestino.logradouro
                    }
                    contAlter={this.state.origemCleanup}
                  />
                </div>
              </div>

              <div
                className={classnames({
                  'col-md-12': tipoServico === 'REBOQUE',
                  hide: tipoServico !== 'REBOQUE',
                })}>
                <h4>Endereço Destino</h4>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fas fa-flag" />
                  </span>
                  <AddressAutoComplete
                    placeholder="Endereço Destino"
                    id="attendanceEnderecoDestino"
                    favoriteDestinations
                    initialValue={enderecoFavorito ? enderecoFavorito.logradouro : null}
                    contAlter={this.state.destinyCleanup}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <h4>Observação</h4>
                <textarea
                  id="observacao"
                  onChange={this.onChangeObservation}
                  value={this.state.observation}
                  className="form-control"
                />
                <WarningAttendance
                  tipoServico={this.props.tipoServico}
                  containerStyle={{marginTop: '12px'}}
                />
              </div>
              {/* EXIGIR CAMPOS E MUDAR LANÇA, MOSTRAR TAMBÉM NO NOVA SOLICITAÇÃO */}
              {(attendance.veiculo.tipoVeiculo === 'PESADO' ||
                attendance.veiculo.tipoVeiculo === 'EXTRAPESADO') && (
                <ObservationForm handleObservation={this.handleObservationPesado} />
              )}

              <div className="col-md-12">
                <h4>Protocolo</h4>
                <input
                  value={this.state.protocol}
                  onChange={this.onChangeProtocol}
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="col-md-12">
                <div className="row-fluid">
                  <form ref="form" className="form-horizontal" onSubmit={this.onClose}>
                    <h4>Opções</h4>

                    {tipoServico === 'REBOQUE' && (
                      <div>
                        <div className="form-group col-xs-4">
                          <div className="">
                            <span>
                              <input
                                type="checkbox"
                                checked={this.props.rodasTravadas}
                                onChange={(e) =>
                                  this.onChangeVehicleForm(e.target.id, e.target.checked)
                                }
                                id="rodasTravadas"
                              />{' '}
                              <label htmlFor="rodasTravadas"> As rodas estão travadas? </label>
                            </span>
                          </div>
                          {this.props.rodasTravadas && (
                            <div className="">
                              <label htmlFor="quantidadeRodasTravadas">
                                Quantidade de rodas travadas:
                              </label>{' '}
                              <select
                                type="select"
                                value={this.props.quantidadeRodasTravadas || 1}
                                onChange={(e) => {
                                  this.onChangeRoller(e.target.id, parseInt(e.target.value));
                                }}
                                id="quantidadeRodasTravadas">
                                <option value={1} selected>
                                  1
                                </option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                              </select>
                            </div>
                          )}
                        </div>
                        <div className="form-group col-xs-4">
                          <span>
                            <input
                              type="checkbox"
                              checked={this.props.blindado}
                              onChange={(e) =>
                                this.onChangeVehicleForm(e.target.id, e.target.checked)
                              }
                              id="blindado"
                            />{' '}
                            <label htmlFor="blindado"> O carro é blindado? </label>
                          </span>
                        </div>
                      </div>
                    )}
                    {contractType === CompaniesContracts.FREQUENCIA && (
                      <div className="form-group col-xs-4">
                        <span>
                          <input
                            type="checkbox"
                            checked={this.state.franchise}
                            onChange={this.onChangeFranchise}
                            id="franchise"
                          />{' '}
                          <label htmlFor="franchise"> Usar franquia? </label>
                        </span>
                      </div>
                    )}
                  </form>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-6" style={{marginTop: '6px'}}>
                    <button
                      className="btn btn-primary"
                      style={{width: '100%'}}
                      onClick={() => {
                        this.setState({dataAgendamento: null}, this.bookSolicitation);
                      }}
                      disabled={!this.canCreateSolicitation()}>
                      Agendar
                    </button>
                  </div>
                  <div className="col-sm-6" style={{marginTop: '6px'}}>
                    <button
                      className="btn btn-primary"
                      style={{width: '100%'}}
                      onClick={this.saveSolicitation}
                      disabled={!this.canCreateSolicitation()}>
                      Solicitar Atendimento
                    </button>
                  </div>
                  {/*Modal*/}
                  <CustomModal title="Motivo Patio" context={'CONTEXT_MOTIVO_PATIO'}>
                    {this.props.showMotivoPatio && (
                      <div className="col-md-12" style={{textAlign: 'center'}}>
                        <h4>Informe o motivo de enviar para o pátio:</h4>
                        <textarea
                          className="form-control"
                          rows="3"
                          value={this.props.motivoPatio}
                          onChange={this.handleMotivoPatioChange}
                        />
                        {isSoonUser && (
                          <div style={{marginBottom: '2px', marginTop: '10px'}}>
                            <label>Destino Solicitado?</label>
                            <div className="form-group toggle-group">
                              <Toggle
                                inactiveLabel={'Não'}
                                activeLabel={'Sim'}
                                colors={{
                                  activeThumb: {base: `#FFF`},
                                  active: {base: `#5c40ff`},
                                  inactiveThumb: {base: `#FFF`},
                                  inactive: {base: `#FFF`},
                                }}
                                inactiveLabelStyle={{color: `#2f4050`}}
                                value={this.state.AddressAfterPatio}
                                thumbStyle={{
                                  borderRadius: 2,
                                  borderColor: `#5c40ff`,
                                }}
                                trackStyle={{borderRadius: 2}}
                                onToggle={this.toggleStartAddres}
                              />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              {this.state.AddressAfterPatio && (
                                <div style={{flexGrow: 1}}>
                                  <AddressAutoComplete
                                    initialValue={this.state.enderecoDestino}
                                    placeholder="Endereço de Destino"
                                    id="enderecoDestino"
                                    onOptionSelected={(endereco) =>
                                      this.setState({enderecoDestino: endereco})
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <button className="btn-motivoPatio" onClick={this.handleButtonClick}>
                          Solicitar Atendimento
                        </button>
                      </div>
                    )}
                  </CustomModal>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{marginTop: '6px'}}>
            <AttendanceSolicitationMap
              id="serviceMap"
              style={{height: '500px'}}
              requestOrigin={this.props.requestOrigin}
              requestDestination={this.props.requestDestination}
              panel={this.props.rightPanel}
              tipoServico={this.props.tipoServico}
              isAdmin={this.props.login.user_info.usuario.tipoUsuario === 1}
            />
          </div>
        </div>
      </div>
    );
  }
}

NewAttendanceSolicitation.defaultProps = {
  showInfo: true,
};

NewAttendanceSolicitation.propTypes = {
  showInfo: PropTypes.bool,
  codigoAtendimento: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  attendance: state.attendance.attendanceTab,
  tipoServico: state.attendance.tipoServico,
  showMotivoPatio: state.attendance.showMotivoPatio,
  motivoPatio: state.service.motivoPatio,
  rodasTravadas: state.attendance.rodasTravadas,
  blindado: state.attendance.blindado,
  quantidadeRodasTravadas: state.attendance.quantidadeRodasTravadas,
  service: state.service,
  logradouro: state.service.enderecoDestino.logradouro,
  latDest: state.service.enderecoDestino.latitude,
  longDest: state.service.enderecoDestino.longitude,
  bairro: state.service.enderecoDestino.bairro,
  cep: state.service.enderecoDestino.cep,
  cidade: state.service.enderecoDestino.cidade,
  estado: state.service.enderecoDestino.estado,
  numero: state.service.enderecoDestino.numero,
  pais: state.service.enderecoDestino.pais,
  enderecoDestino: state.service.attendanceEnderecoDestino,
  enderecoOrigem: state.service.attendanceEnderecoOrigem,
  requestOrigin:
    state.service.attendanceEnderecoOrigem && state.service.attendanceEnderecoOrigem.latitude
      ? {
          lat: state.service.attendanceEnderecoOrigem.latitude,
          lng: state.service.attendanceEnderecoOrigem.longitude,
        }
      : {
          lat: state.attendance.attendanceTab.endereco.latitude,
          lng: state.attendance.attendanceTab.endereco.longitude,
        },
  requestDestination:
    state.service.attendanceEnderecoDestino && state.service.attendanceEnderecoDestino.latitude
      ? {
          lat: state.service.attendanceEnderecoDestino.latitude,
          lng: state.service.attendanceEnderecoDestino.longitude,
        }
      : null,
  profileId: state.login.user_info.usuario.tipoUsuario,
  userInfo: state.login.user_info,
  login: state.login,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setServiceType: (value) => dispatch(setAttendanceTypeService(value)),
    setVehicleState: (payload) => {
      dispatch(setAttendanceVehicleState(payload));
    },
    onCheckChange: (name, value) => {
      dispatch(onFieldChange(name, value));
    },
    updateMotivoPatio: (motivoPatio) => dispatch(updateMotivoPatio(motivoPatio)),
    saveSolicitation: (codigoAtendimento, order, motivoPatio) => {
      dispatch(newAttendanceSolicitation(codigoAtendimento, order, motivoPatio));
    },
    newAttendanceSolicitation: (codigoAtendimento, pedido) =>
      dispatch(newAttendanceSolicitation(codigoAtendimento, pedido)),
    getTimeAndPredition: (requestInfo, auth) => {
      dispatch(getPriceAndTimePrediction(requestInfo, auth));
    },
    setNewSolicitationVisibility: (value) => {
      dispatch(setNewAttendanceSolicitationVisibility(value));
    },
    findAttendanceSolicitation: (codigoAtendimento) => {
      dispatch(findAttendanceSolicitation(codigoAtendimento));
    },
    clearAddressField: (field) => {
      dispatch(clearAddress(field));
    },
    setAddressValue: (field, value) => {
      dispatch(onAddressChange(field, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAttendanceSolicitation);
