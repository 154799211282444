import React from 'react';
import CustomModal from "../common/CustomModal";
import InvoiceItem from './SendInvoiceItem';


class SendInvoiceModal extends React.Component {
    render() {
        const { 
            title, 
            context,
            invoices,
            handleInvoiceInput,
            handleBuildNF
        } = this.props;
        return (
            <CustomModal title={title} context={context}>
                { 
                    invoices.map(invoice => <InvoiceItem 
                        handleBuildNF={handleBuildNF}
                        handleInvoiceInput={handleInvoiceInput}
                        invoice={invoice} key={invoice.code}/>)
                }
            </CustomModal>
        )
    }
}



export default SendInvoiceModal;