import React from "react";
import Dropzone from "react-dropzone";
import {
  img,
  baseStyle,
  activeStyle,
  rejectStyle,
  dropzoneContainer,
  thumb,
} from "./fileUploaderStyle";
import { connect } from "react-redux";
import { UtilityBox } from "./UtilityBox";

class FileUploader extends React.Component {
  constructor() {
    super();
    this.state = {
      files: [],
      serverFiles: [],
      mergedFiles: [],
      filled: false,
    };
    this.mountDropZone = this.mountDropZone.bind(this);
    this.setServerFiles = this.setServerFiles.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setServerFiles(nextProps.documentList);
  }

  onDrop(files) {
    const { type, upload } = this.props;
    this.setState(
      {
        files: files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      },
      () => {
        upload(files, type);
      }
    );
  }

  setServerFiles(files) {
    this.setState({
      serverFiles: files,
    });
  }

  mountDropZone() {
    const { deleteImage } = this.props;
    const { serverFiles } = this.state;
    return (
      <Dropzone
        multiple={true}
        accept="image/*,application/pdf"
        onDrop={this.onDrop.bind(this)}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
        }) => {
          let styles = { ...baseStyle };
          styles = isDragActive ? { ...styles, ...activeStyle } : styles;
          styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

          return (
            <div {...getRootProps()} style={styles}>
              <input {...getInputProps()} />
              <div>
                {serverFiles.length > 0 ? (
                  serverFiles.map((f, i) => (
                    <div className="col-md-3" key={`img-preview${i}`}>
                      <UtilityBox deleteImage={deleteImage} image={f} />
                      <a
                        href={f.urlLocation}
                        download
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div style={thumb}>
                          <img
                            className={"img-responsive"}
                            src={
                              f.urlLocation &&
                              f.urlLocation.substr(f.urlLocation.length - 3) !==
                                "pdf"
                                ? f.urlLocation
                                : require("../../../assets/img/pdf.png")
                            }
                            style={img}
                            alt="teste"
                          />
                        </div>
                      </a>
                    </div>
                  ))
                ) : null
                }
              </div>
              {isDragReject && <div>Arquivo não suportado</div>}
            </div>
          );
        }}
      </Dropzone>
    );
  }

  render() {
    return <div style={dropzoneContainer}>{this.mountDropZone()}</div>;
  }
}

const mapStateToProps = (state) => ({
  imageUploaded: state.checkList.imageUploaded,
});

export default connect(
  mapStateToProps,
  null
)(FileUploader);
