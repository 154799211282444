import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {doOpenModal} from '../../common/CustomModal';
import ChoiceModal from '../../documents/choiceModal';
import {reduxForm, change} from 'redux-form';
import {connect} from 'react-redux';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './style.css';
import {getDocumentsToApproval} from '../dashboardActions';

export const DOCUMENTS_AVALIATION = 'DOCUMENTS_AVALIATION';

class DocumentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      selectedRowCode: -1,
      row: {},
      tabIndex: 1,
      currentPage: 1,
    };

    this.onChangeTabIndex = this.onChangeTabIndex.bind(this);
  }

  resetRow = (documents = []) => {
    if (documents.length === 0) return;
    const actualRow = documents.find((document) => document.codigo === this.state.row.codigo);
    this.setState({row: actualRow});

    this.props.changeForm('editInfo', 'valorServico', actualRow.valorServico);
    this.props.changeForm('editInfo', 'valorServicoParceiro', actualRow.valorServicoParceiro);
    this.props.changeForm('editInfo', 'acessorios', actualRow.acessorios);
    this.props.changeForm('editInfo', 'valorPedagio', actualRow.valorPedagio);
    this.props.changeForm('editInfo', 'horaParada', actualRow.horaParada);
    this.props.changeForm('editInfo', 'horaTrabalhada', actualRow.horaTrabalhada);
  };

  setTableLoader = () => {
    if (this.props.isFetching === false) {
      return `Nenhum registro encontrado`;
    } else {
      return (
        <div className="spiner-example">
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1" />
            <div className="sk-double-bounce2" />
          </div>
        </div>
      );
    }
  };

  formatPlaca = (row, cel) => {
    if (!cel.veiculoCliente) return null;
    return cel.veiculoCliente.placa;
  };

  formatTelefone = (row, cel) => {
    if (!cel.respostaAceita || !cel.respostaAceita.parceiro) return null;
    return cel.respostaAceita.parceiro.telefoneCelular;
  };

  formatPrestador = (row, cel) => {
    if (!cel.respostaAceita || !cel.respostaAceita.parceiro) return null;
    return cel.respostaAceita.parceiro.contato;
  };

  formatActions = (row, cel) => {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <button
          onClick={() => {
            this.props.loadAssets(cel.codigo);
            doOpenModal(DOCUMENTS_AVALIATION);
          }}
          className="margin-right btn btn-primary">
          <i className="fas fa-eye" />
        </button>

        <button
          onClick={() => this.props.handleDecision(cel.codigo, false)}
          className="margin-right btn btn-danger">
          <i className="fas fa-times" />
        </button>
        <button
          onClick={() => this.props.handleDecision(cel.codigo, true)}
          className="margin-right btn btn-primary">
          <i className="fas fa-check" />
        </button>
      </div>
    );
  };

  handleClickRow = (row, index) => {
    this.setState({
      title: `Autorizar Documento : Placa ${row.veiculoCliente.placa}`,
      row,
      tabIndex: 1,
      selectedRowCode: index,
    });
    this.props.loadAssets(row.codigo);
    doOpenModal(DOCUMENTS_AVALIATION);
  };

  trStyle = (row, rowIndex) => {
    return {cursor: 'pointer'};
  };

  onChangeTabIndex(key) {
    this.setState({...this.state, tabIndex: key});
  }
  handlePageChange = (page, sizePerPage) => {
    this.setState({currentPage: page});
    this.props.fetchDocuments(page);
  };
  render() {
    const options = {
      noDataText: this.setTableLoader(),
      page: this.state.currentPage,
      sizePerPageList: [10],
      paginationSize: 10,
      sizePerPage: 10,
      onPageChange: this.handlePageChange,
      onRowClick: (row, _, index) => this.handleClickRow(row, index),
      onRowDoubleClick: null,
    };

    const {profileId} = this.props;

    return (
      <div>
        <ChoiceModal
          title={this.state.title}
          handleDecision={this.props.handleDecision}
          approveDocument={this.props.approveDocument}
          row={this.state.row}
          resetRow={this.resetRow}
          handleChangeTabIndex={this.onChangeTabIndex}
          tabIndex={this.state.tabIndex}
          context={DOCUMENTS_AVALIATION}
          profileId={profileId}
        />
        <BootstrapTable
          options={options}
          data={this.props.documents}
          fetchInfo={{dataTotalSize: this.props.totalItem}}
          remote
          condensed
          trStyle={this.trStyle}
          bordered={false}
          striped
          hover>
          <TableHeaderColumn dataField="codigo" isKey hidden />
          <TableHeaderColumn width="145" dataField="dataCadastro">
            Data
          </TableHeaderColumn>
          <TableHeaderColumn width="80" dataFormat={this.formatPlaca}>
            Placa
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.formatPrestador}>Prestador</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.formatTelefone} width="120">
            Telefone
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

DocumentsTable.defaultProps = {
  isFetching: false,
};

DocumentsTable.propsTypes = {
  documents: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  handleDecision: PropTypes.func,
  approveDocument: PropTypes.func,
  profileId: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    profileId: state.login.user_info.usuario.tipoUsuario,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeForm: (form, field, value) => {
      dispatch(change(form, field, value));
    },
    fetchDocuments: (page) => dispatch(getDocumentsToApproval(page)),
  };
};

const reduxFormDocumentsTable = reduxForm({
  enableReinitialize: true,
  form: 'relatorio',
})(DocumentsTable);

export default connect(mapStateToProps, mapDispatchToProps)(reduxFormDocumentsTable);
