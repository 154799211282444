import React from "react";
import ContentLoader from "react-content-loader";

const ClusterLoader = props => {
  return (
    <ContentLoader speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <circle cx="10" cy="20" r="8" />
      <rect x="25" y="15" rx="5" ry="5" width="120" height="10" />
      <circle cx="10" cy="50" r="8" />
      <rect x="25" y="45" rx="5" ry="5" width="120" height="10" />
      <circle cx="10" cy="80" r="8" />
      <rect x="25" y="75" rx="5" ry="5" width="120" height="10" />
      <circle cx="10" cy="110" r="8" />
      <rect x="25" y="105" rx="5" ry="5" width="120" height="10" />
      <rect x="160" y="14" rx="0" ry="0" width="175" height="0" />
      <rect x="157" y="16" rx="0" ry="0" width="295" height="103" />
    </ContentLoader>
  );
};
export default ClusterLoader;
