import {isNumber, isString} from 'lodash';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import './balance.css';

export default (props) => {
  const {indicator, onClick, prefix, description} = props;
  return (
    <div className="col-md-3">
      <div className={`widget style1 ${props.cClass}`}>
        <div className="row">
          <div className="col-md-4">
            <i className={`${props.icon} fa-4x`}></i>
          </div>
          <div className="col-md-8 text-right">
            {onClick ? (
              <a href="#" onClick={onClick} className="cardLink">
                <span>
                  <i className={'fas fa-plus'}></i> <strong>{props.title} </strong>{' '}
                </span>
              </a>
            ) : description ? (
              <span>
                <strong>{props.title} </strong>{' '}
                <a data-tip data-for={`${props.title}-info`}>
                  <i
                    className={'fas fa-info-circle'}
                    style={{
                      color: '#797979',
                      cursor: 'pointer',
                      fontSize: '1.2em',
                      marginLeft: '5px',
                    }}
                  />
                </a>
                <ReactTooltip
                  id={`${props.title}-info`}
                  type="info"
                  effect="solid"
                  className="tooltip-dashboard">
                  <span>{description}</span>
                </ReactTooltip>
              </span>
            ) : (
              <span>
                <strong>{props.title} </strong>{' '}
              </span>
            )}

            <h2 className="font-bold">
              {indicator && !isNaN(indicator) && (
                <div
                  className={`stat-percent font-bold  ${
                    indicator >= 0 ? 'old-text-navy' : 'text-danger'
                  }`}>
                  {Math.abs(indicator)}%{' '}
                  <i
                    className={
                      indicator >= 0 ? 'fas fa-level-down-alt' : 'fas fa-level-up-alt'
                    }></i>
                </div>
              )}
              {prefix && <small style={{color: '#FFF'}}>{' ' + prefix}</small>}
              {props.value === 'NaN' ? 'N/A' : props.value}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
