import React from 'react'
import { Field } from 'redux-form'
import { renderField } from "./FormValidation";

const PhoneField = ({ name, label, validate, placeholder, icon, mask }) => {
    return (
        <Field
            name={name}
            type='tel'
            component={renderField}
            label={label}
            validate={validate}
            placeholder={placeholder}
            icon={icon}
            mask={mask ? mask : '(99) 9 9999-9999' }
        />
    )
}
export default PhoneField
