export const isTwoRouteService = (service) => {
  if (service.tipoServico === null || service.tipoServico === undefined) {
    throw new Error('Tipo serviço null ou undefined');
  }

  return service.tipoServico === `REBOQUE` || service.tipoServico === 'SUBSOLO';
};

export const isThreeRouteService = (service) => {
  if (service.tipoServico === null || service.tipoServico === undefined) {
    throw new Error('Tipo serviço null ou undefined');
  }

  return service.tipoServico === `LEVAETRAZ`;
};

export const isTwoSolicitationService = (service) => {
  if (service.tipoServico === null || service.tipoServico === undefined) {
    throw new Error('Tipo serviço null ou undefined');
  }

  return service.tipoServico === `LEVAETRAZ` || service.tipoServico === 'SUBSOLO';
};
