import React from 'react';

import {isThreeRouteService, isTwoRouteService} from '../../utils/service_type_helper';

const FloatingInfo = ({enderecoOrigem, enderecoDestino, enderecoCliente, tipoServico}) => {
  return (
    <div className="widget-text-box" style={{paddingBottom: '0'}}>
      <div style={{width: '100%', clear: 'both'}}>
        <div>
          <div className="text-center" style={{float: 'left', minWidth: '45px'}}>
            <i className="fa fa-location-arrow fa-lg" style={{lineHeight: '20px'}}></i>
          </div>
          <address>
            <p>{enderecoOrigem ? enderecoOrigem : 'Preencha o endereço de origem...'}</p>
          </address>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          clear: 'both',
          display: isThreeRouteService({tipoServico}) ? `block` : `none`,
        }}>
        <div>
          <div className="text-center" style={{float: 'left', minWidth: '45px'}}>
            <i className="fa fa-car fa-lg" style={{lineHeight: '20px'}}></i>
          </div>
          <address>
            <p>{enderecoCliente ? enderecoCliente : 'Preencha o endereço do cliente...'}</p>
          </address>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          clear: 'both',
          display:
            isTwoRouteService({tipoServico}) || isThreeRouteService({tipoServico})
              ? `block`
              : `none`,
        }}>
        <div>
          <div className="text-center" style={{float: 'left', minWidth: '45px'}}>
            <i className="fa fa-flag-checkered fa-lg" style={{lineHeight: '20px'}}></i>
          </div>
          <address>
            <p>{enderecoDestino ? enderecoDestino : 'Preencha o endereço de destino...'}</p>
          </address>
        </div>
      </div>
    </div>
  );
};

export default FloatingInfo;
