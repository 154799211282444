import React, {Component} from 'react';
import {connect} from 'react-redux';
import AddressModal from './AddressModal';

function formatTooltip(
  email,
  contato,
  telefoneContato,
  contato2,
  telefoneContato2,
  contato3,
  telefoneContato3,
) {
  let formattedTooltip = '';

  if (email) {
    formattedTooltip += `${email}\n`;
  }

  if (contato && telefoneContato) {
    formattedTooltip += `${contato} ${telefoneContato}`;
  }

  if (contato2 && telefoneContato2) {
    formattedTooltip += ` | ${contato2} ${telefoneContato2}`;
  }

  if (contato3 && telefoneContato3) {
    formattedTooltip += ` | ${contato3} ${telefoneContato3}`;
  }

  return formattedTooltip;
}

class ValidationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
    };
  }

  // const toolTipText = formatTooltip(
  //   email,
  //   contato,
  //   telefoneContato,
  //   contato2,
  //   telefoneContato2,
  //   contato3,
  //   telefoneContato3
  // );

  render() {
    const {
      premium,
      concessionaria,
      chassi,
      dataFinalGarantia,
      dataFinalGarantiaEstendida1,
      descricaoGarantiaEstendida1,
      dataFinalGarantiaEstendida2,
      descricaoGarantiaEstendida2,
      contatosEnderecoFavorito,
      email,
      contato,
      telefoneContato,
      contato2,
      telefoneContato2,
      contato3,
      telefoneContato3,
      attendence,
    } = this.props;

    function getLocais() {
      try {
        const locais =attendence.validateInfo.contatosEnderecoFavorito ||
        attendence.attendanceTab.veiculo.enderecoFavorito.contatosEnderecoFavorito || [];
        return locais;
      } catch (error) {
        return [];
      }
    }


    const locais = getLocais();

    return (
      <div>
        {this.state.isModalOpened && (
          <AddressModal close={() => this.setState({isModalOpened: false})} tabs={locais} />
        )}

        <div className="row">
          <div className="col-sm-4">
            <h3>Data Final Garantia</h3>
            <p>{dataFinalGarantia ? dataFinalGarantia : 'N/A'}</p>
          </div>
        </div>
        {dataFinalGarantiaEstendida1 && descricaoGarantiaEstendida1 && (
          <div className="row">
            <div className="col-sm-2">
              <h3>Descrição garantia estendida</h3>
              <p>{descricaoGarantiaEstendida1}</p>
            </div>
            <div className="col-sm-2">
              <h3>Data Final garantia estendida</h3>
              <p>{dataFinalGarantiaEstendida1}</p>
            </div>
          </div>
        )}
        {dataFinalGarantiaEstendida2 && descricaoGarantiaEstendida2 && (
          <div className="row">
            <div className="col-sm-2">
              <h3>Descrição garantia estendida</h3>
              <p>{descricaoGarantiaEstendida2}</p>
            </div>
            <div className="col-sm-2">
              <h3>Data Final garantia estendida</h3>
              <p>{dataFinalGarantiaEstendida2}</p>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-sm-4">
            <h3>Prime?</h3>
            <p>{premium ? 'Sim' : 'Não'}</p>
          </div>
        </div>
        <h3>
          Concessionária de Compra{' '}
          {!!locais.length && (
            <span
              style={{cursor: 'pointer'}}
              onClick={() => this.setState({isModalOpened: true})}>
              <i className="fa fa-info-circle" />
            </span>
          )}
        </h3>
        <div className="row">
          <section className="col-sm-12">
            <p>{concessionaria ? concessionaria : 'N/A'}</p>
          </section>
        </div>
        <h3>Chassi</h3>
        <p>{chassi ? chassi : 'N/A'}</p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    chassi: state.attendance.validateInfo.chassi,
    premium: state.attendance.validateInfo.premium,
    originAddress: state.attendance.originAddress,
    concessionaria: state.attendance.validateInfo.concessionaria,
    dataFinalGarantia: state.attendance.validateInfo.dataFinalGarantia,
    dataFinalGarantiaEstendida1: state.attendance.validateInfo.dataFinalGarantiaEstendida1,
    descricaoGarantiaEstendida1: state.attendance.validateInfo.descricaoGarantiaEstendida1,
    dataFinalGarantiaEstendida2: state.attendance.validateInfo.dataFinalGarantiaEstendida2,
    descricaoGarantiaEstendida2: state.attendance.validateInfo.descricaoGarantiaEstendida2,
    email: state.attendance.validateInfo.email,
    contato: state.attendance.validateInfo.contato,
    telefoneContato: state.attendance.validateInfo.telefoneContato,
    contato2: state.attendance.validateInfo.contato2,
    telefoneContato2: state.attendance.validateInfo.telefoneContato2,
    contato3: state.attendance.validateInfo.contato3,
    telefoneContato3: state.attendance.validateInfo.telefoneContato3,
    attendence: state.attendance,
  };
};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps)(ValidationTab);
