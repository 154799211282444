import { doGet, doPost, doPut, doDelete } from "./base/base_api_helper";
import GoogleMapReact from "google-map-react";
import moment from "moment";

export function getCustomers(filter, auth, codigoEmpresa) {
  return doGet(
    "customers",
    {
      _filter: filter,
      _empresa: codigoEmpresa
    },
    auth
  );
}

export function getSingleCustomer(customerCode, auth) {
  return doGet(`customers/${customerCode}`, null, auth);
}

export function createCustomer(customerData, codigoEmpresa) {
  return doPost("customers", getCustomerApiValues(customerData, codigoEmpresa));
}

export function updateCustomer(customerCode, customerData, codigoEmpresa) {
  return doPut(
    `customers/${customerCode}`,
    getCustomerApiValues(customerData, codigoEmpresa)
  );
}

export function deleteCustomer(customerCode) {
  return doDelete(`customers/${customerCode}`);
}

export function getCustomerVehicles(customer, auth) {
  return doGet(
    `customers/${(customer && customer.id) || "0"}/vehicles`,
    null,
    auth
  );
  /*
  ,{
    _filter: filter
  })
*/
}

export function getCustomerApiValues(customer = {}, codigoEmpresa) {
  return {
    nome: customer.nome,
    sobreNome: customer.sobreNome,
    telefoneCelular: customer.telefoneCelular,
    cpf: customer.cpf && customer.cpf.replace(/[^0-9]/g, ""),
    email: customer.email,
    ownId: customer.ownId,
    codigoEmpresa: codigoEmpresa,
  };
}

export function editCompanyofCustomer(params) {
  return new Promise(resolve => setTimeout(() => resolve({ mensagemCliente: 'Empresa salva com sucesso!'})), 2000);
}


export function editCustomerDetails(customer={}) {
  const {codigo} = customer;
  const cliente = {
    nome: customer.nome,
    telefoneCelular: customer.telefoneCelular,
    cpf: customer.cpf && customer.cpf.replace(/[^0-9]/g, ""),
    email: customer.email,
    premium: customer.premium,
    
  }

  return doPut(`subscriptions/${codigo}`, { cliente, codigoEmpresa: customer.codigoEmpresa });
}

export function editVehicleOfCustomer(codigoCustomer, codeVehicle, params) {
  
  const body = {
    veiculo: {
      placa: params.placa,
      ano: params.ano,
      cor: params.cor,
      dataFinalGarantia: moment(params.dataFinalGarantia, "DD/MM/YYYY HH:mm:ss"),
      chassis: params.chassis,
      modelo: params.modelo,
      marca: params.marca,
      tipoVeiculo: params.tipoVeiculo,
      enderecoFavorito: {
        codigo: params.enderecoFavorito
      },
    },
    codigoEmpresa: params.codigoEmpresa
  }

  return doPut(`subscriptions/${codigoCustomer}/vehicles/${codeVehicle}`, body);
}

export function editCoverPlan(params) {
  return new Promise(resolve => setTimeout(() => resolve({ mensagemCliente: 'Plano salvo com sucesso!'})), 2000);
}