import {push} from 'react-router-redux';

import {doPost} from '../api/base/base_api_helper';
import {toastSuccess, toastError} from '../actions/toast_actions';

export function getCNPJInfo(cnpj) {
  return (dispatch) => {
    dispatch({type: 'COMPANY_CNPJ_FETCHED', payload: companyCnpj});
  };
}

export function registerCompany(values) {
  return (dispatch) => {
    const formatObj = {
      cnpj: values.cnpj.replace(/\D/g, ''),
      nomeFantasia: values.nomeFantasia,
      razaoSocial: values.razaoSocial,
      telefoneCelular: values.telefoneCelular,
      contato: values.contato,
      email: values.email,
      senha: values.senha,
      endereco: {
        logradouro: values.logradouro,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        numero: values.numero,
        cep: values.cep,
        estado: values.estado,
      },
    };

    dispatch({type: 'COMPANY_REGISTER_FETCHING', payload: true});
    doPost('companies', formatObj)
      .then((resp) => {
        dispatch([
          {type: 'COMPANY_REGISTER_DONE', payload: true},
          {type: 'COMPANY_REGISTER_FETCHING', payload: false},
          toastSuccess('Empresa cadastrada com sucesso'),
          {type: 'LOGIN_ERROR', message: resp.mensagemCliente},
          push('/login'),
        ]);
      })
      .catch((e) => {
        console.log(e);
        dispatch([
          {type: 'COMPANY_REGISTER_ERROR', payload: e.mensagemCliente},
          {type: 'COMPANY_REGISTER_FETCHING', payload: false},
        ]);
      });
  };
}
