import React, { Component } from "react";
import { connect } from "react-redux";

import CpfField from "../../components/common/formComponents/CpfField";

import {
  createNewPartner,
  updateExistingPartner,
} from "../../actions/partner_actions";

import { Tabs, Tab } from "react-bootstrap";

import SelectHookTable from "../common/SelectHookTable";
import { reduxForm, Field } from "redux-form";
import { bindActionCreators } from "redux";
import TextField from "../common/formComponents/TextField";
import {
  minLength,
  required,
  phoneValidator,
  maxLength,
} from "../common/formComponents/FormValidation";
import PhoneField from "../common/formComponents/PhoneField";
import SelectField from "../common/formComponents/SelectField";
import PlateField from "../common/formComponents/PlateField";
import DateFormField from "../common/formComponents/DateField";
import { doCloseModal } from "../common/CustomModal";

const minLength9 = minLength(9);

const initialState = {
  ano: "",
  asaDelta: 0,
  capacidadeCarga: 0,
  cor: "",
  bearing: 0,
  codigo: "",
  corequiredntato: "",
  email: "",
  errorMessage: false,
  login: "",
  marca: "",
  modelo: "",
  nomeFantasia: "",
  patins: 0,
  placa: "",
  segurado: -1,
  speed: 0,
  status: {},
  tamanhoPlataforma: 0,
  telefoneCelular: "",
  tipoVeiculo: "",
  veiculo: {},
  key: 1,
  gestor: false,
  quantidadeEixos: 2,
};

class PartnerFormView extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleTabs = this.handleTabs.bind(this);
    this.handleTabsNav = this.handleTabsNav.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  renderCheckbox({ input }) {
    return (
      <div>
        <label htmlFor="gestor" style={{ margin: 5 }}>
          {" "}
          Gestor?{" "}
        </label>
        <input type="checkbox" checked={input.value} {...input} id="gestor" />
      </div>
    );
  }

  handleTabs(key) {
    this.setState({
      key,
    });
  }

  handleTabsNav(e) {
    e.preventDefault();
    const { key } = this.state;
    if (key !== 5) {
      this.setState({
        key: key + 1,
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  handleCreate() {
    const {
      createNewPartner,
      reset,
      isEditing,
      link,
      updateExistingPartner,
    } = this.props;

    !isEditing
      ? createNewPartner(link, () => {
          doCloseModal("CONTEXT_PARTNER");
          reset();
        })
      : updateExistingPartner(link, () => {
          doCloseModal("CONTEXT_PARTNER");
          reset();
        });
  }

  render() {
    const { handleSubmit, submitting, isEditing } = this.props;

    return (
      <form onSubmit={handleSubmit(() => this.handleCreate())}>
        <div
          className="alert alert-danger alert-dismissable"
          style={{ display: this.props.errorMessage ? "block" : "none" }}
        >
          {this.props.errorMessage}
        </div>
        <Tabs
          id="partnerTab"
          activeKey={this.state.key}
          onSelect={this.handleTabs}
        >
          <Tab eventKey={1} title="Dados">
            <div className="partner-register-container">
              <div className="row">
                <div className="col-sm-8">
                  <TextField
                    name={"contato"}
                    label={"Contato"}
                    validate={[required]}
                    placeholder={"João da Silva"}
                    icon={"fas fa-user"}
                  />
                </div>
                <div className="col-sm-4">
                  <PhoneField
                    name={"telefoneCelular"}
                    label={"Telefone Celular"}
                    validate={[required, phoneValidator]}
                    icon={"fas fa-mobile"}
                    placeholder={"(99) 9 9999-9999"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <TextField
                    name={"email"}
                    label={"Email"}
                    validate={[required]}
                    placeholder={"guincho@reboque.me"}
                    icon={"fas fa-envelope"}
                  />
                </div>
                <div className="col-sm-4">
                  <DateFormField
                    name="dataNascimento"
                    label="Data de Nascimento"
                    icon={"fas fa-birthday-cake"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <CpfField
                    type="number"
                    name={"cpf"}
                    label={"CPF"}
                    validate={[required, minLength9]}
                    icon={"fas fa-id-card"}
                    placeholder={"999.999.999-99"}
                    type={"CPF"}
                  />
                </div>
                <div className="col-sm-4">
                  <TextField
                    name={"cnh"}
                    label={"CNH"}
                    placeholder={
                      "Número da CNH" // validate={[required]}
                    }
                    icon={"fas fa-id-badge"}
                  />
                </div>
                <div className="col-sm-4">
                  <TextField
                    name={"linkCnh"}
                    label={"Link CNH"}
                    placeholder="https://s3.amazonaws.com/reboque.me/img/cnh_joao.png"
                    icon={
                      "fas fa-portrait" // validate={[required]}
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <TextField
                    name={"login"}
                    label={"Login"}
                    validate={[required]}
                    placeholder={"hju0157"}
                    icon={"fas fa-user"}
                  />
                </div>
                {!isEditing && (
                  <div className="col-sm-4">
                    <TextField
                      name={"senha"}
                      label={"Senha"}
                      validate={[required]}
                      placeholder={"Senha"}
                      icon={"fas fa-key"}
                    />
                  </div>
                )}
                <div className="col-sm-4">
                  <SelectField
                    name={"codigoStatus"}
                    label={"Status"}
                    icon={"fas fa-align-left"}
                    validate={[required]}
                  >
                    <option>Selecionar</option>
                    <option value="PARCEIROATIVO">Ativo</option>
                    <option value="PARCEIROINATIVO">Inativo</option>
                    <option value="PARCEIROEMDESCANSO">Descanso</option>
                    <option value="PARCEIROEMATENDIMENTO">
                      Em Atendimento
                    </option>
                  </SelectField>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12" style={{ marginBottom: 15 }}>
                  <Field
                    name={"gestor"}
                    component={this.renderCheckbox}
                    icon={"fas fa-mobile"}
                  />
                </div>
              </div>
            </div>
          </Tab>

          {/*SEÇÃO DE VEICULO*/}

          <Tab eventKey={2} title="Veículo">
            <div className="partner-register-container">
              <div className="col-sm-6">
                <TextField
                  name={"marca"}
                  label={"Marca"}
                  validate={[required]}
                  placeholder={"Ford"}
                  icon={"fas fa-user"}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  name={"modelo"}
                  label={"Modelo"}
                  validate={[required]}
                  placeholder={"Ford"}
                  icon={"fas fa-user"}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  name={"ano"}
                  label={"Ano"}
                  validate={[required]}
                  placeholder={"2019"}
                  icon={"fas fa-user"}
                  maxLength={4}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  name={"cor"}
                  label={"Cor"}
                  validate={[required]}
                  placeholder={"Azul"}
                  icon={"fas fa-user"}
                />
              </div>
              <div className="col-sm-6">
                <PlateField
                  name={"placa"}
                  label={"Placa"}
                  validate={[required]}
                  placeholder={"HJU-0157"}
                  icon={"fas fa-user"}
                />
              </div>
              <div className="col-sm-6">
                <SelectField
                  name={"segurado"}
                  label={"Segurado"}
                  icon={"fas fa-align-left"}
                  validate={[required]}
                >
                  <option>Selecionar</option>
                  <option value={0}>Não Segurado</option>
                  <option value={1}>Segurado</option>
                </SelectField>
              </div>
              <div className="col-sm-12">
                <SelectHookTable
                  vehicle={[]}
                  isEditing={this.props.isEditing}
                />
              </div>
            </div>
          </Tab>

          {/*Acessorios*/}

          <Tab eventKey={3} title="Acessórios">
            <div className="partner-register-container">
              <div className="col-sm-6">
                <SelectField
                  name={"tipoVeiculo"}
                  label={"Categoria"}
                  icon={"fas fa-align-left"}
                  validate={[required]}
                >
                  <option>Selecionar</option>
                  <option value="VEICULOPARCEIROMOTOCICLETA">
                    Motocicleta
                  </option>
                  <option value="VEICULOPARCEIROLEVE">Plataforma Leve</option>
                  <option value="VEICULOPARCEIROUTILITARIO">
                    Plataforma Utilitária
                  </option>
                  <option value="VEICULOPARCEIROPESADO">
                    Plataforma Pesada
                  </option>
                  <option value="VEICULOPARCEIROLANCA">Lança</option>
                </SelectField>
              </div>

              <div className="col-sm-6">
                <SelectField
                  name={"patins"}
                  label={"Patins"}
                  icon={"fas fa-align-left"}
                  validate={[required]}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </SelectField>
              </div>
              <div className="col-sm-6">
                <SelectField
                  name={"asaDelta"}
                  label={"Asa Delta"}
                  icon={"fas fa-align-left"}
                  validate={[required]}
                >
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </SelectField>
              </div>

              <div className="col-sm-6">
                <TextField
                  type={"number"}
                  name={"tamanhoPlataforma"}
                  label={"Tamanho da Plataforma (m)"}
                  icon={"fas fa-align-left"}
                  placeholder={"Exemplo: 12.5"}
                  validate={[required, minLength(1)]}
                  minLength={1}
                  maxLength={20}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  type={"number"}
                  name={"capacidadeCarga"}
                  label={"Capacidade de Carga (KG)"}
                  validate={[required]}
                  placeholder={"5500"}
                  icon={"fas fa-user"}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  type={"number"}
                  name={"quantidadeEixos"}
                  label={"Quantidade de Eixos"}
                  validate={[required]}
                  placeholder={"2"}
                  icon={"fas fa-align-left"}
                />
              </div>
            </div>
          </Tab>

          <div className="form-group">
            <button
              className="btn btn-block btn-primary"
              type="submit"
              disabled={submitting}
            >
              {!isEditing ? "Criar" : "Atualizar"}
            </button>{" "}
          </div>
        </Tabs>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.isEditing) {
    let quantidadeEixos = 2;

    if (state.partner.currentPartner.veiculo.quantidadeEixos) {
      quantidadeEixos = state.partner.currentPartner.veiculo.quantidadeEixos;
    }
    return {
      initialValues: {
        codigo: state.partner.currentPartner.codigo,
        gestor: state.partner.currentPartner.gestor,
        contato: state.partner.currentPartner.contato,
        telefoneCelular: state.partner.currentPartner.telefoneCelular,
        codigoStatus: state.partner.currentPartner.status.codigo,
        email: state.partner.currentPartner.email,
        dataNascimento: state.partner.currentPartner.dataNascimento,
        login: state.partner.currentPartner.login,
        marca: state.partner.currentPartner.veiculo.marca,
        modelo: state.partner.currentPartner.veiculo.modelo,
        ano: state.partner.currentPartner.veiculo.ano,
        cor: state.partner.currentPartner.veiculo.cor,
        placa: state.partner.currentPartner.veiculo.placa,
        segurado: state.partner.currentPartner.veiculo.segurado,
        tipoVeiculo: state.partner.currentPartner.veiculo.tipoVeiculo.codigo,
        patins: state.partner.currentPartner.veiculo.patins,
        asaDelta: state.partner.currentPartner.veiculo.asaDelta,
        tamanhoPlataforma:
          state.partner.currentPartner.veiculo.tamanhoPlataforma,
        capacidadeCarga: state.partner.currentPartner.veiculo.capacidadeCarga,
        cpf: state.partner.currentPartner.cpf,
        cnh: state.partner.currentPartner.cnh,
        linkCnh: state.partner.currentPartner.linkCnh,
        quantidadeEixos,
      },
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewPartner,
      updateExistingPartner,
    },
    dispatch
  );

PartnerFormView = reduxForm({
  enableReinitialize: true,
  form: "registerPartner",
})(PartnerFormView);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerFormView);
