import React, {Component} from 'react';
import _ from 'lodash';

export default class AddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: _.groupBy(this.props.tabs, 'tipoContato.descricao'),
      activeTab: Object.keys(_.groupBy(this.props.tabs, 'tipoContato.descricao'))[0],
    };
  }

  render() {
    return (
      <div class="panel panel-default">
        <div
          style={{
            background: 'black',
            opacity: '20%',
            position: 'fixed',
            zIndex: 10000,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            cursor: 'pointer',
          }}
          onClick={this.props.close}
        />

        <div
          class="panel-body"
          style={{
            background: 'white',
            borderRadius: 8,
            minHeight: '50%',
            maxHeight: '65%',
            left: '50%',
            position: 'fixed',
            top: '50%',
            transform: 'Translate(-50%,-50%)',
            width: '50%',
            zIndex: 10001,
            padding: 20,
            overflowY: 'scroll',
          }}>
          {/* <button type="button" class="btn btn-default" onClick={this.props.close}>Fechar Modal</button> */}
          <ul className="nav nav-tabs">
            {Object.keys(this.state.tabs).map((tab) => {
              return (
                <li
                  onClick={() => this.setState({activeTab: tab})}
                  role="presentation"
                  className={this.state.activeTab === tab && 'active'}>
                  <a href="#">{tab}</a>
                </li>
              );
            })}
          </ul>
          {this.state.tabs[this.state.activeTab].map((info) => {
            return (
              <div style={{margin: 20, fontSize: 16, wordBreak: 'break-all'}}>
                <p>Contato: {info.contato}</p>
                <p>Cargo: {info.cargo}</p>
                <p>Telefone: {info.telefone}</p>
                <p>Email: {info.email}</p>
                <div style={{width: '100%', height: 2, background: '#dddddd'}} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
