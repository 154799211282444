export function fullTextSearch(itemList, query, isInprogress = false) {
  const upperQuery = query.toString().toUpperCase();
  const queryTerms = upperQuery.split(/\s+/);

  if (isInprogress) {
    return itemList.filter((item) => {
      const searchFields = [
        item.cliente.nome,
        item.observacao,
        item.usuario,
        item.veiculoCliente.modelo,
        item.veiculoCliente.placa,
        item.veiculoCliente.marca,
        item.empresa.nomeFantasia,
        item.empresa.razaoSocial,
        item.statusSolicitacao,
        item.tipoServico.descricao,
        item.respostaAceita.parceiro.contato,
        item.respostaAceita.parceiro.nomeFantasia,
      ].map((field) => (field ? field.toUpperCase() : ''));
      return queryTerms.every((term) => searchFields.some((field) => field.includes(term)));
    });
  }
  {
    return itemList.filter(function (item) {
      return JSON.stringify(item).toUpperCase().indexOf(query.toString().toUpperCase()) >= 0;
      //findOcurrencesInObject(item, query)
    });
  }
}

function findOcurrencesInObject(item, query) {
  return (
    item &&
    Object.keys(item).some(function (key) {
      if (typeof item[key] == 'object') return findOcurrencesInObject(item[key], query);
      else return item[key] && item[key].toString().indexOf(query) !== -1;
    })
  );
}
