const INITIAL_STATE = {
  companyRegisterFetching: false,
  companyRegisterDone: false,
  errorMessage: null
};

const companyReduder = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "COMPANY_REGISTER_FETCHING":
      return {
        ...state,
        companyRegisterFetching: action.payload
      };
    case "COMPANY_REGISTER_DONE":
      return {
        ...state,
        companyRegisterDone: action.payload
      };
    case "COMPANY_REGISTER_ERROR":
      return {
        ...state,
        errorMessage: action.payload
      };

    default:
      return state;
  }
};

export default companyReduder;
