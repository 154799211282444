import React, {Component} from 'react';
import {doCloseModal} from '../common/CustomModal';
import CustomModal from '../common/CustomModal';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';
import {onFormFieldChange, clearFormValues} from '../../actions/form_actions';
import {doPost} from '../../api/base/base_api_helper';
import DateTime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';
import {toastSuccess, toastError, toastWarning} from '../../actions/toast_actions';
import {onInputCleared} from '../../actions/autocomplete_actions';
export const VEHICLE_FORM_MODEL_INPUT = 'VEHICLE_FORM_MODEL_INPUT';
import {newInvoice} from '../../actions/base_actions';

class NewInvoiceModal extends Component {
  constructor(props) {
    super(props);
  }

  clearFormInput = () => {
    this.props.clear(this.props.context);

    this.props.onFieldChange({
      target: {
        id: 'codigoEmpresa',
        value: '',
      },
    });

    this.props.onFieldChange({
      target: {
        id: 'tipoMovimento',
        value: '',
      },
    });

    this.props.onFieldChange({
      target: {
        id: 'descricao',
        value: '',
      },
    });

    this.props.onFieldChange({
      target: {
        id: 'tipoInvoice',
        value: '',
      },
    });
  };

  componentDidMount() {
    this.clearFormInput();
  }

  saveInvoice = async () => {
    if (this.props.isFetchingNewInvoice) {
      toastWarning('Uma requisição já está em andamento!!');
      return;
    }
    const invoice = {};

    if (this.props.codigoEmpresa !== undefined) {
      invoice.codigoEmpresa = this.props.codigoEmpresa.value;
    }

    if (this.props.valor !== undefined) {
      invoice.valor = this.props.valor;
    }

    if (this.props.dataMovimento !== undefined) {
      invoice.dataMovimento = this.props.dataMovimento.format('YYYY-MM-DD hh:mm');
    }

    if (this.props.dataCompetencia !== undefined) {
      invoice.dataCompetencia = this.props.dataCompetencia.format('YYYY-MM-DD hh:mm');
    }

    if (this.props.dataVencimento !== undefined) {
      invoice.dataVencimento = this.props.dataVencimento.format('YYYY-MM-DD hh:mm');
    }

    if (this.props.descricao !== undefined) {
      invoice.descricao = this.props.descricao;
    }

    if (this.props.tipoInvoice !== undefined) {
      invoice.tipoInvoice = this.props.tipoInvoice.value;
    }

    if (this.props.tipoMovimento !== undefined) {
      invoice.tipoMovimento = this.props.tipoMovimento.value;
    }

    if (this.props.enviarEmail !== undefined) {
      invoice.enviarEmail = this.props.enviarEmail;
    } else {
      invoice.enviarEmail = false;
    }

    if (this.props.servico !== undefined) {
      invoice.servico = this.props.servico.value;
    }

    try {
      this.props.setFetchingNewInvoice(true);
      const response = await doPost(`invoices`, invoice);

      this.clearFormInput();

      doCloseModal(this.props.context);

      if (response.sucesso) {
        toastSuccess(response.mensagemCliente);
      } else {
        toastError(response.mensagemRetorno);
      }
    } catch (err) {
      toastError(err.mensagemRetorno);
    } finally {
      this.props.setFetchingNewInvoice(false);
      if (this.props.invoices.length > 0) {
        this.props.refreshInvoice();
      }
    }
  };

  render() {
    return (
      <CustomModal title={this.props.title} context={this.props.context}>
        <div className="form">
          <div className="row">
            <div className="col-md-6">
              <label>Empresa</label>
              <Select
                placeholder=""
                onChange={(value) =>
                  this.props.onFieldChange({
                    target: {
                      id: 'codigoEmpresa',
                      value,
                    },
                  })
                }
                value={this.props.codigoEmpresa}
                id="codigoEmpresa"
                closeMenuOnSelect={false}
                options={this.props.companiesOptions}
                styles={selectCustomStyle}
              />
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Valor</label>
                <input
                  type="number"
                  value={this.props.valor ? this.props.valor : ''}
                  onChange={this.props.onFieldChange}
                  id="valor"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Data do Movimento</label>
                <DateTime
                  onChange={(value) =>
                    this.props.onFieldChange({
                      target: {
                        value,
                        id: 'dataMovimento',
                      },
                    })
                  }
                  value={
                    this.props.dataMovimento
                      ? moment(this.props.dataMovimento).local().format('DD/MM/YYYY hh:mm')
                      : null
                  }
                  closeOnSelect
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Vencimento</label>
                <DateTime
                  onChange={(value) =>
                    this.props.onFieldChange({
                      target: {
                        value,
                        id: 'dataVencimento',
                      },
                    })
                  }
                  value={
                    this.props.dataVencimento
                      ? moment(this.props.dataVencimento).local().format('DD/MM/YYYY hh:mm')
                      : null
                  }
                  closeOnSelect
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="font-normal p-w-xs">Serviço</label>
              <Select
                closeMenuOnSelect={false}
                onChange={(value) =>
                  this.props.onFieldChange({
                    target: {
                      id: 'servico',
                      value,
                    },
                  })
                }
                value={this.props.servico}
                options={this.props.serviceList}
                styles={selectCustomStyle}
              />
            </div>
            <div className="col-md-6">
              <label className="font-normal p-w-xs">Tipo do Movimento</label>
              <Select
                placeholder=""
                closeMenuOnSelect={false}
                onChange={(value) =>
                  this.props.onFieldChange({
                    target: {
                      id: 'tipoMovimento',
                      value,
                    },
                  })
                }
                value={this.props.tipoMovimento}
                styles={selectCustomStyle}
                options={this.props.movesList}
              />
            </div>
          </div>
          <div className="row" style={{marginTop: '10px'}}>
            <div className="col-md-6">
              <div className="form-group">
                <label>Descrição</label>
                <input
                  value={this.props.descricao}
                  onChange={this.props.onFieldChange}
                  id="descricao"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="font-normal p-w-xs">Tipo de Invoice</label>
              <Select
                placeholder=""
                closeMenuOnSelect={false}
                onChange={(value) =>
                  this.props.onFieldChange({
                    target: {
                      id: 'tipoInvoice',
                      value,
                    },
                  })
                }
                styles={selectCustomStyle}
                value={this.props.tipoInvoice}
                options={this.props.typeList}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <input
                type="checkbox"
                checked={this.props.enviarEmail}
                onChange={(event) =>
                  this.props.onFieldChange({
                    target: {
                      id: 'enviarEmail',
                      value: event.target.checked,
                    },
                  })
                }
                id="enviarEmail"
                style={{marginRight: '5px'}}
              />
              <label>Enviar E-mail</label>
            </div>
          </div>
        </div>

        <button
          onClick={this.saveInvoice}
          style={{marginTop: '10px'}}
          className="btn btn-primary float-right">
          Criar
        </button>
      </CustomModal>
    );
  }
}

NewInvoiceModal.propTypes = {
  title: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  typeList: PropTypes.array.isRequired,
  serviceList: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let contextState = state.forms && state.forms[ownProps.context];

  return {
    ...ownProps,
    ...contextState,
    isFetchingNewInvoice: state.base.isfetchingNewInvoice,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFieldChange: (event) => {
      dispatch(onFormFieldChange(ownProps.context, event.target.id, event.target.value));
    },
    clear: (context) => {
      dispatch(clearFormValues(context));
    },
    clearAutoComplete: (context) => {
      dispatch(onInputCleared(context));
    },
    createInvoice: (attributes) => {
      dispatch(newInvoice(attributes));
    },
    setFetchingNewInvoice: (value) => {
      dispatch({type: 'NEW_INVOICE_REQUESTED', value});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoiceModal);
