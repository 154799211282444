import {push} from 'react-router-redux';
import {
  getVehicles,
  getSingleVehicle,
  createVehicle,
  updateVehicle,
  deleteVehicle,
  saveVehicles,
  addVehicle,
} from '../api/vehicle_helper';
import {getCustomerVehicles} from '../api/customer_helper';
import {onFormError, onFormSuccess, setFormValues} from './form_actions';
import {toastError, toastSuccess} from '../actions/toast_actions';

export function loadCustomerVehicles(customerId) {
  return (dispatch) => {
    getCustomerVehicles({id: customerId})
      .then((results) => {
        dispatch({type: CAR_LIST_CHANGED, value: results.listVehicles});
      })
      .catch((err) => toastError(err.mensagemCliente));
  };
}

export function clearVehiclesList() {
  return {
    type: CAR_LIST_CLEAR,
  };
}

export function loadVehiclesList(filter = '', company, status, cliente) {
  return (dispatch) => {
    dispatch([
      {type: CAR_LIST_FETCHING, value: true},
      {type: CAR_LIST_CHANGED, value: []},
    ]);
    getVehicles(filter, company, status, cliente)
      .then((results) => {
        dispatch([
          {type: CAR_LIST_CHANGED, value: results.listVehicles},
          {type: CAR_LIST_FETCHING, value: false},
        ]);
      })
      .catch((err) => {
        toastError(err.mensagemCliente);
      })
      .finally(() => dispatch({type: CAR_LIST_FETCHING, value: false}));
  };
}

export function saveVehicle(cliente, veiculo, vigencia, codigoProduto, codigoEmpresa) {
  return (dispatch) => {
    if (!cliente || !cliente.cpf || !cliente.email || !cliente.nome) {
      toastError('É necessário preencher os dados do cliente');
      return false;
    }

    if (!veiculo || !veiculo.ano || !veiculo.marca || !veiculo.modelo || !veiculo.placa) {
      toastError('É necessário preencher os dados do veículo');
      return false;
    }

    if (
      !vigencia ||
      !vigencia.dataFinalVigencia ||
      !vigencia.dataInicioVigencia ||
      !codigoProduto
    ) {
      toastError('É necessário preencher os dados da cobertura');
      return false;
    }

    /* if (!codigoEmpresa) {
      console.log("vehicle_actions - saveVehicle :86");
      toastError("É necessário preencher os dados da empresa");
      return false;
    } */

    return saveVehicles(cliente, veiculo, vigencia, codigoProduto, codigoEmpresa)
      .then((res) => {
        if (!res.sucesso) {
          toastError(res.mensagemCliente || res.mensagemRetorno);
        } else {
          toastSuccess(res.mensagemCliente);
          dispatch(loadVehiclesList('', codigoEmpresa));
        }

        return res.sucesso;
      })
      .catch((err) => {
        toastError(err.mensagemCliente || err.mensagemRetorno);
        return false;
      });
  };
}

export function setVehicleDetails(updateAction, context, customerCode, vehicleCode, auth) {
  return (dispatch, getState) => {
    getSingleVehicle(customerCode, vehicleCode, auth)
      .then((result) => {
        dispatch(updateAction(context, result.clienteVeiculo));
        dispatch(onFormSuccess(context));
      })
      .catch((error) => {
        dispatch(onFormError(context, error.message || error));
      });
  };
}

export function createNewVehicle(
  vehicleFormContext,
  customerInpuContext,
  doAfterSuccess,
  auth,
) {
  return (dispatch, getState) => {
    dispatch(onFormSuccess(vehicleFormContext));

    let vehicleData = getState().forms[vehicleFormContext] || {};
    const placa = vehicleData.placa || '';
    const placaRegex = /^[A-Z]{3}-[0-9][A-Z][0-9]{2}|[A-Z]{3}-[0-9]{4}$/;
    if (!placaRegex.test(placa)) {
      dispatch(
        onFormError(
          vehicleFormContext,
          'Placa inválida. Formato esperado: AAA-1234 ou ABD1E23',
        ),
      );
      return;
    }

    let customerCode =
      getState().autocomplete[customerInpuContext] &&
      getState().autocomplete[customerInpuContext].selectedItem &&
      getState().autocomplete[customerInpuContext].selectedItem.codigo;

    if (!customerCode) {
      dispatch(onFormError(vehicleFormContext, 'É necessário informar um cliente'));
      return;
    }

    createVehicle(customerCode, vehicleData, auth)
      .then((result) => {
        doAfterSuccess(customerCode, result.clienteVeiculo, auth);
      })
      .catch((error) => {
        dispatch(onFormError(vehicleFormContext, error.message || error));
      });
  };
}

// here
export function addNewVehicle(customerCode, veiculo, coverage, auth) {
  return (dispatch) => {
    addVehicle(customerCode, veiculo, coverage, auth)
      .then((result) => {
        toastSuccess(result.mensagemCliente);
      })
      .catch((error) => {
        toastError(error.message || error);
      });
  };
}

export function updateExistingVehicle(
  vehicleFormContext,
  customerInpuContext,
  doAfterSuccess,
  auth,
) {
  return (dispatch, getState) => {
    dispatch(onFormSuccess(vehicleFormContext));

    let vehicleData = getState().forms[vehicleFormContext];

    let customerCode =
      getState().autocomplete[customerInpuContext] &&
      getState().autocomplete[customerInpuContext].selectedItem &&
      getState().autocomplete[customerInpuContext].selectedItem.codigo;

    if (!customerCode) {
      dispatch(onFormError(vehicleFormContext, 'É necessário informar um cliente'));
      return;
    }

    updateVehicle(
      customerCode,
      {
        ...vehicleData,
        codigo: getState().service.veiculoCliente.codigo
          ? getState().service.veiculoCliente.codigo
          : getState().autocomplete.VEHICLE_AUTOCOMPLETE.selectedItem.codigo,
      },
      auth,
    )
      .then((result) => {
        doAfterSuccess(customerCode, result.clienteVeiculo, auth);
      })
      .catch((error) => {
        dispatch(onFormError(vehicleFormContext, error.message || error));
      });
  };
}

export function deleteExistingVehicle(car, doAfterSuccess) {
  return (dispatch, getState) => {
    deleteVehicle(car.codigoCliente, car.codigo)
      .then((result) => {
        dispatch(loadVehiclesList());
        doAfterSuccess();
      })
      .catch((error) => {
        toastError(error.message || error); //dispatch( onFormError(context, error.message) )
      });
  };
}

export const CAR_LIST_CHANGED = 'CAR_LIST_CHANGED';
export const CAR_LIST_FETCHING = 'CAR_LIST_FETCHING';
export const CAR_LIST_CLEAR = 'CAR_LIST_CLEAR';
