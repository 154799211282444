import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

const marker = (position, icon, title, animation = null, map = null) => {
  if (position) {
    return new google.maps.Marker({
      position: position || { lat: -22.915, lng: -43.197 },
      map,
      animation,
      title,
      icon,
      draggable: false,
    });
  }
  return null;
};

const handleTimeInactivity = (start) => {
  let timeOfInactivity;
  let timeToArrive;
  const startDate = moment(start, "D HH:mm");
  const end = moment();
  const endDate = moment(end, "D HH:mm");

  // const timeToArrive = moment.duration( startDate.diff( endDate ) ).minutes( ) * -1
  if (startDate && endDate.diff(startDate) < 0) {
    timeToArrive = endDate.diff(startDate, "minutes", true) * -1;
  }
  if (startDate && endDate.diff(startDate) >= 0) {
    timeToArrive = endDate.diff(startDate, "minutes", true);
  }
  if (timeToArrive) {
    timeOfInactivity = Math.floor(timeToArrive);
    return timeOfInactivity;
  }
};

const handlePartnerIcon = (partner) => {
  const { associado } = partner;
  const inactivity = handleTimeInactivity(associado.dataUltimaPosicao);
  if (inactivity && inactivity >= 30)
    return "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png";
  else if (associado.status.codigo === "PARCEIROATIVO")
    return "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carBlue.png";
  else if (associado.status.codigo === "PARCEIROEMATENDIMENTO")
    return "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carGreen.png";
  else if (associado.status.codigo === "PARCEIROEMDESCANSO")
    return "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carYellow.png";
  return "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png";
};

const acessoriesTab = (partner) =>
  `${
    partner.veiculo.patins ||
    partner.veiculo.asaDelta ||
    partner.veiculo.capacidadeCarga ||
    partner.veiculo.tamanhoPlataforma
      ? `<div class='acessoriesTab'>
        ${
          partner.veiculo.patins > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.patins
              }</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/patins.png"/></div>`
            : "<div></div>"
        }
        ${
          partner.veiculo.asaDelta > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.asaDelta
              }</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/asa-delta.png"/></div>`
            : "<div></div>"
        }
        ${
          partner.veiculo.capacidadeCarga > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner
                .veiculo.capacidadeCarga /
                1000}k</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carga.png"/></div>`
            : "<div></div>"
        }
        ${
          partner.veiculo.tamanhoPlataforma > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.tamanhoPlataforma
              }m</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/tamanho.png"/></div>`
            : "<div></div>"
        }
      </div>`
      : "<div></div>"
  }`;

const getInfoWindowContent = (type, marker) => {
  if (type === "partner") {
    return (
      '<div class="form-group">' +
      "<h3>" +
      marker.contato +
      "</h3>" +
      "<h4>" +
      marker.nomeFantasia +
      "</h4>" +
      `<div>${acessoriesTab(marker)}</div>` +
      `<a href="http://api.whatsapp.com/send?l=pt_br&phone=55${marker.telefoneCelular
        .match(/[0-9]/g)
        .join("")}" target="blank"> <i class="fab fa-whatsapp"></i> ` +
      marker.telefoneCelular +
      "</a><br/>" +
      "<p>" +
      marker.dataUltimaPosicao +
      "</p>" +
      "<p>" +
      "Versão do App: " +
      marker.versaoApp +
      "</p>" +
      "</div>"
    );
  } else return `<p>teste</p>`;
};

export class AlterScheduleMap extends Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.handleDirections = this.handleDirections.bind(this);
    this.initMarkers = this.initMarkers.bind(this);
    this.callDirectionService = this.callDirectionService.bind(this);
    this.setMapOnAllMarkers = this.setMapOnAllMarkers.bind(this);
    this.clearMarkers = this.clearMarkers.bind(this);
    this.populateClosestPartnersMarkers = this.populateClosestPartnersMarkers.bind(
      this
    );
    this.infowindow = new google.maps.InfoWindow();
    this.partnerInfowindow = new google.maps.InfoWindow();
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    this.state = {
      directions: null,
      markers: [
        marker(
          this.props.requestOrigin,
          "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg",
          "Origem"
        ),
        marker(
          this.props.requestDestination,
          "http://icons.iconarchive.com/icons/fatcow/farm-fresh/24/flag-finish-icon.png",
          "Destino",
          google.maps.Animation.BOUNCE
        ),
      ],
    };
  }

  setMapOnAllMarkers(map) {
    this.state.markers.map((marker) => {
      if (marker) {
        marker.setMap(map);
      }
    });
  }

  clearMarkers() {
    this.setMapOnAllMarkers(null);
  }

  initMap() {
    const { id, requestOrigin, requestDestination } = this.props;

    this.map = new google.maps.Map(document.getElementById(`map-${id}`), {
      center: requestOrigin,
      gestureHandling: "greedy",
      zoom: 15,
      controlSize: 24,
      disableDefaultUI: true,
    });
    this.initMarkers(requestOrigin, requestDestination);

    const overlay = new google.maps.OverlayView();
    overlay.draw = function() {
      this.getPanes().markerLayer.id = "markerLayer";
    };
    overlay.setMap(this.map);

    this.setMapOnAllMarkers(this.map);
    this.directionsDisplay.setMap(this.map);
    this.callDirectionService(this.directionsService);
  }

  initMarkers(requestOrigin, requestDestination) {
    const markers = [];

    if (requestOrigin) {
      const { lng, lat } = requestOrigin;
      const requestMarker = new google.maps.Marker({
        position: { lng, lat },
        map: this.map,
        title: "Solicitação",
        icon:
          "https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg",
        draggable: true,
      });
      markers.push(requestMarker);
    }
    if (requestDestination) {
      const { lng, lat } = requestDestination;
      const destinationMarker = new google.maps.Marker({
        position: { lng, lat },
        map: this.map,
        animation: google.maps.Animation.BOUNCE,
        title: "Destino",
        icon:
          "http://icons.iconarchive.com/icons/fatcow/farm-fresh/24/flag-finish-icon.png",
        draggable: true,
      });
      markers.push(destinationMarker);
    }
    this.setState({ markers });
  }

  populateClosestPartnersMarkers = (partners) => {
    partners.map((partner) => {
      const position = {
        lat: partner.associado.latitude,
        lng: partner.associado.longitude,
      };
      const partnerMarker = marker(
        position,
        handlePartnerIcon(partner),
        "Parceiro",
        null,
        this.map
      );
      partnerMarker.addListener("click", () => {
        this.partnerInfowindow.setContent(
          getInfoWindowContent("partner", partner.associado)
        );
        this.partnerInfowindow.open(this.map, partnerMarker);
      });
      const markers = [...this.state.markers, partnerMarker];
      this.setState({ markers });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.directions !== prevState.directions &&
      this.state.directions
    ) {
      this.handleDirections(this.directionsDisplay, this.state.directions);
    }
    if (this.props.closestPartners != prevProps.closestPartners) {
      this.initMap();
      this.populateClosestPartnersMarkers(this.props.closestPartners);
    }
  }

  handleDirections(display, directions) {
    display.setDirections(directions);
    const stepMiddle =
      Math.round(directions.routes[0].legs[0].steps.length / 2) - 1;
    this.infowindow.setContent(
      `${directions.routes[0].legs[0].distance.text}<br>${
        directions.routes[0].legs[0].duration.text
      }`
    );
    this.infowindow.setPosition(
      directions.routes[0].legs[0].steps[stepMiddle].end_location
    );
    this.infowindow.open(this.map);
  }

  callDirectionService(service) {
    service.route(
      {
        origin: this.props.requestOrigin,
        destination: this.props.requestDestination,
        travelMode: "DRIVING",
        avoidTolls: false,
      },
      (response, status) => {
        if (status === "OK") {
          this.setState({ directions: response });
        } else {
          this.props.routeErr("Erro ao montar rota", status);
        }
      }
    );
  }

  componentWillUnmount() {
    this.clearMarkers();
  }

  render() {
    return (
      <div
        id={`map-${this.props.id}`}
        style={{ height: "65vh", width: "100%" }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    closestPartners: state.service.partners,
  };
};

export default connect(
  mapStateToProps,
  null
)(AlterScheduleMap);
