import React, { PureComponent } from "react";
import "./observacoesTab.css";

export default class ObservacoesTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { observacao, situacaoVeiculo, motivoConclusaoManual } = this.props;
    const situacoes = situacaoVeiculo ? Object.values(situacaoVeiculo) : [];

    return (
      <div className="col-md-12 marginAcert">
        <br />
        <div className="row form-group">
          <label>Observação: </label>
          <br />
          <span>{observacao ? observacao : "Solicitação sem observação"} </span>
        </div>

        <hr className="divisorTab" />

        <div className="row form-group">
          <label>Situação do Veículo: </label>
          <br />
          {situacoes.indexOf(true) === -1 ? (
            <span>Normal</span>
          ) : (
            <div className="text-center">
              <table
                style={{
                  float: "left",
                  marginTop: "18px"
                }}
              >
                <tr className="marginSituacao">
                  {situacaoVeiculo.capotado ? <th>&#8226; Capotado</th> : null}

                  {situacaoVeiculo.garagem ? <th>&#8226; Garagem</th> : null}

                  {situacaoVeiculo.semRodas ? <th>&#8226; Sem Rodas</th> : null}

                  {situacaoVeiculo.rodasTravadas ? (
                    <th>&#8226; Rodas Travadas</th>
                  ) : null}

                  {situacaoVeiculo.blindado ? <th>&#8226; Blindado</th> : null}
                </tr>
              </table>
            </div>
          )}
          <br />
        </div>
        {motivoConclusaoManual && (
          <div>
            <hr className="divisorTab" />
            <div className="row form-group">
              <label>Motivo da Conclusão Manual: </label>
              <br />
              <span>{motivoConclusaoManual.descricao}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
