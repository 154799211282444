import { doGet, doPost, doPut, doDelete } from './base/base_api_helper';
import moment from 'moment';
import { doPutV2 } from './base/base_api_v2_helper';

export function getPartners(id) {
  let link;
  if (id) {
    link = (id) => id.replace(/^.{0,1}/g, '');
    return doGet(link(id));
  }
}
export function resetPassword(partnerCode) {
  return doPut(`partners/${partnerCode}/setDefaultPassword`);
}
export function resetLocation(partnerCode) {
  return doPutV2(`partners/resetPosition`,{codigo:partnerCode})
}
export function getAllPartners() {
  return doGet(`partners/`);
}

export function getAllPartnersTracking() {
  return doGet(`partners/tracking`);
}

export function getSinglePartner(partnerCode) {
  return doGet(`partners/${partnerCode}`);
}

export function createPartner(partnerData, services, id) {
  const link = (id) => id.replace(/^.{0,1}/g, '');

  return new Promise(async (resolve, reject) => {
    try {
      const response = await doPost(
        link(id),
        getPartnerApiValues(partnerData, services)
      );
      resolve(response);
    } catch (error) {
      reject({ message: error.message });
    }
  });
}

export function updatePartner(partnerCode, partnerData, services, id) {
  const link = (id) => id.replace(/^.{0,1}/g, '');
  const updateLink = link(id);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await doPut(
        `${updateLink}/${partnerCode}`,
        getPartnerApiValues(partnerData, services)
      );
      resolve(response);
    } catch (error) {
      reject({ message: error.message });
    }
  });
}

export function deletePartner(partnerCode) {
  return doDelete(`partners/${partnerCode}`);
}

export function getPartnerVehicles(partner) {
  return doGet(`partners/${(partner && partner.id) || '0'}/vehicles`);
  /*
  ,{
    _filter: filter
  })
*/
}

export function getClosestPartners(codigo, fantasma) {
  let url = `solicitations/${codigo}/closestPartners`;
  if (fantasma === true) url += `?_fantasmas=${fantasma}`;
  return doGet(url);
}

export function getPartnerApiValues(partner = {}, services) {
  let listaServicos = [];
  let status;
  let segurado = 0;
  let marca;
  let placa;
  let modelo;
  let cor;
  let senha;
  let ano;
  let endereco;
  let email;
  let login;
  let telefoneCelular;
  let jsonFinal = {};
  let veiculo = {};

  if (partner.contato) {
    jsonFinal['contato'] = partner.contato;
  }
  if (partner.telefoneCelular) {
    jsonFinal['telefoneCelular'] = partner.telefoneCelular;
  }
  if (partner.login) {
    jsonFinal['login'] = partner.login;
  }
  if (partner.senha) {
    jsonFinal['senha'] = partner.senha;
  }
  if (partner.email) {
    jsonFinal['email'] = partner.email;
  }

  if (partner.dataNascimento) {
    jsonFinal['dataNascimento'] = moment(
      partner.dataNascimento,
      'DD/MM/YYYY 00:00'
    ).format('YYYY-MM-DD 00:00');
  }

  if (typeof partner.gestor === 'boolean') {
    jsonFinal['gestor'] = partner.gestor;
  }

  _.map(services, (servico) => {
    listaServicos.push(servico.value);
    if (listaServicos.length === services.lenght) {
      return listaServicos;
    }
  });

  if (listaServicos.length > 0 && veiculo) {
    veiculo['listaServicos'] = listaServicos;
  }

  if (partner.codigoStatus) {
    jsonFinal['status'] = partner.codigoStatus;
  } else {
    jsonFinal['status'] = 'PARCEIROATIVO';
  }

  if (partner.cpf) {
    let cpf = partner.cpf.replace(/[^0-9]+/g, '');
    if (cpf.length === 11) {
      cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpf.length === 14) {
      cpf = cpf.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    } else {
      throw new Error('CPF/CNPJ Inválido');
    }

    jsonFinal['cpf'] = cpf;
  }

  if (partner.cnh) {
    jsonFinal['cnh'] = partner.cnh;
  }

  if (partner.linkCnh) {
    jsonFinal['linkCnh'] = partner.linkCnh;
  }

  if (partner.segurado) {
    veiculo['segurado'] = parseInt(partner.segurado);
  }

  if (!partner.segurado) {
    veiculo['segurado'] = 0;
  }
  if (!partner.isEditing) {
    jsonFinal['senha'] = partner.senha;
  }
  if (partner.marca) {
    veiculo['marca'] = partner.marca;
  }
  if (partner.modelo) {
    veiculo['modelo'] = partner.modelo;
  }
  if (partner.placa) {
    veiculo['placa'] = partner.placa;
  }
  if (partner.ano) {
    veiculo['ano'] = partner.ano;
  }
  if (partner.cor) {
    veiculo['cor'] = partner.cor;
  }
  if (partner.asaDelta) veiculo['asaDelta'] = parseInt(partner.asaDelta);
  else veiculo['asaDelta'] = 0;
  if (partner.capacidadeCarga)
    veiculo['capacidadeCarga'] = parseFloat(partner.capacidadeCarga);
  if (partner.patins) veiculo['patins'] = parseInt(partner.patins);
  else veiculo['patins'] = 0;
  if (partner.tamanhoPlataforma)
    veiculo['tamanhoPlataforma'] = parseFloat(partner.tamanhoPlataforma);
  else veiculo['tamanhoPlataforma'] = 4.5;

  if (partner.tipoVeiculo) {
    if (partner.tipoVeiculo.codigo) {
      veiculo['tipoVeiculo'] = partner.tipoVeiculo;
    } else {
      veiculo['tipoVeiculo'] = { codigo: partner.tipoVeiculo };
    }
  } else {
    veiculo['tipoVeiculo'] = { codigo: 'VEICULOPARCEIROLEVE' };
  }

  if (partner.quantidadeEixos) {
    veiculo.quantidadeEixos = partner.quantidadeEixos;
  } else {
    veiculo.quantidadeEixos = 2;
  }

  if (veiculo) {
    jsonFinal['veiculo'] = veiculo;
  }

  return {
    ...jsonFinal,
  };
}
