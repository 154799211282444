import React, { Component } from 'react';

import { connect } from 'react-redux';

import _ from 'underscore';

import moment from 'moment';

import L from 'leaflet';

import 'leaflet-search/dist/leaflet-search.src.js';

import 'leaflet-search/dist/leaflet-search.src.css';

import './heatMap.css';
import './customControlMap.css';
// import iconPARCEIROATIVO from '../../../public/img/reboque.svg'
// import iconPARCEIROINATIVO from '../../../public/img/reboquered.png'
// import iconPARCEIROEMATENDIMENTO from '../../../public/img/reboquegreen.png'
// import iconPARCEIROEMDESCANSO from '../../../public/img/carYellow.png'

import { sendMapNotification } from '../../api/heatMapHelper';
import { hasReboqueMeProfile } from '../../utils/check_permission';

export default class HeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markers: [],
      allMarkers: [],
    };

    //this.handleDrawTools = this.handleDrawTools.bind( this )
    //this.handlePolygon = this.handlePolygon.bind( this )
    this.handleTimeInactivity = this.handleTimeInactivity.bind(this);
    this.handleMapLoad = this.handleMapLoad.bind(this);
    this.handlePosition = this.handlePosition.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleMarkers = this.handleMarkers.bind(this);
    this.handleSolicitationToPin = this.handleSolicitationToPin.bind(this);
    this.handleChangeLayer = this.handleChangeLayer.bind(this);
    this.handleSearchBox = this.handleSearchBox.bind(this);
    this.acessoriesTab = this.acessoriesTab.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { partnersTracking, solicitationToPin } = nextProps;
    const {
      allMarkers,
      layerAtivos,
      layerDescanso,
      layerAtendimento,
      layerInativos,
    } = this.state;

    _.map(partnersTracking, (partner) => {
      _.map(allMarkers, (marker) => {
        if (marker.options.login === partner.login) {
          const latLng = new L.latLng(
            partner.latitude || 0,
            partner.longitude || 0
          );
          marker.setLatLng(latLng);
          marker.setIcon(this.handleIcon(partner));
          marker.options.status = this.handleStatus(partner);
          this.handleChangeLayer();
        }
      });
    });
  }

  componentWillUnmount() {
    this.props.clearSolicitationToPin();
  }

  handleSearchBox(map) {
    const { allMarkers } = this.state;
    const markersLayer = new L.featureGroup();

    map.addLayer(markersLayer);

    _.map(allMarkers, (marker) => {
      return markersLayer.addLayer(marker);
    });

    function customTip(text, val) {
      const { status } = val.layer.options;
      const { nomeFantasia } = val.layer.options;
      function setBackground(background) {
        if (background === `PARCEIROATIVO`) {
          return `#428bca`;
        } else if (background === `PARCEIROEMDESCANSO`) {
          return `#FFEE58`;
        } else if (background === `PARCEIROEMATENDIMENTO`) {
          return `#5cb85c`;
        } else {
          return `#d9534f`;
        }
      }
      return `<a href='#' style='background: rgba( 255, 255, 255, 0.8 ); min-width:280px; max-width: 350px'>
      ${text} - ${nomeFantasia}
      <em style='background: ${setBackground(
        status
      )}; width: 12px; height: 12px; float: right; border-radius: 6px; margin-top: 3px'></em>
    </a>`;
    }

    map.addControl(
      new L.Control.Search({
        position: 'topleft',
        layer: markersLayer,
        buildTip: customTip,
        textPlaceholder: 'Digite para buscar um parceiro...',
        zoom: 16,
      })
    );
  }

  handleChangeLayer() {
    const {
      allMarkers,
      layerAtivos,
      layerDescanso,
      layerAtendimento,
      layerInativos,
    } = this.state;

    const groupMarkers = (marker) => {
      if (marker.options.status === 'PARCEIROEMATENDIMENTO') {
        layerAtendimento.addLayer(marker);
      } else if (marker.options.status === 'PARCEIROEMDESCANSO') {
        layerDescanso.addLayer(marker);
      } else if (marker.options.status === 'PARCEIROATIVO') {
        layerAtivos.addLayer(marker);
      } else {
        layerInativos.addLayer(marker);
      }
    };

    _.map(layerDescanso._layers, (layer) => {
      if (layer.options.status !== 'PARCEIROEMDESCANSO') {
        layerDescanso.removeLayer(layer._leaflet_id);
        groupMarkers(layer);
      }
    });
    _.map(layerAtivos._layers, (layer) => {
      if (layer.options.status !== 'PARCEIROATIVO') {
        layerAtivos.removeLayer(layer._leaflet_id);
        groupMarkers(layer);
      }
    });
    _.map(layerAtendimento._layers, (layer) => {
      if (layer.options.status !== 'PARCEIROEMATENDIMENTO') {
        layerAtendimento.removeLayer(layer._leaflet_id);
        groupMarkers(layer);
      }
    });
    _.map(layerInativos._layers, (layer) => {
      if (layer.options.status !== 'PARCEIROINATIVO') {
        layerInativos.removeLayer(layer._leaflet_id);
        groupMarkers(layer);
      }
    });
  }

  handlePosition = (lat, lng) => {
    let latLng = [0, 0];
    if (lat && lng) {
      latLng = [parseFloat(lat), parseFloat(lng)];
    }
    return latLng;
  };

  handleIcon = (marker) => {
    let icon;
    const inactivity = this.handleTimeInactivity(marker.dataUltimaPosicao);

    if (inactivity && inactivity >= 30) {
      icon = L.divIcon({
        className: 'customDivIcon',
        html: `<img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png" style="-webkit-transform: rotate(${
          marker.bearing
        }deg)" />`,
      });
    } else if (marker.status.codigo === 'PARCEIROATIVO') {
      icon = L.divIcon({
        className: 'customDivIcon',
        html: `<img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carBlue.png" style="-webkit-transform: rotate(${
          marker.bearing
        }deg)" />`,
      });
    } else if (marker.status.codigo === 'PARCEIROEMATENDIMENTO') {
      icon = L.divIcon({
        className: 'customDivIcon',
        html: `<img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carGreen.png" style="-webkit-transform: rotate(${
          marker.bearing
        }deg)" />`,
      });
    } else if (marker.status.codigo === 'PARCEIROEMDESCANSO') {
      icon = L.divIcon({
        className: 'customDivIcon',
        html: `<img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carYellow.png" style="-webkit-transform: rotate(${
          marker.bearing
        }deg)" />`,
      });
    } else if (marker.status.codigo === 'PARCEIROINATIVO') {
      icon = L.divIcon({
        className: 'customDivIcon',
        html: `<img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carRed.png" style="-webkit-transform: rotate(${
          marker.bearing
        }deg)" />`,
      });
    } else {
      return false;
    }
    return icon;
  };

  acessoriesTab = (partner) => {
    if (!partner.veiculo) return `<div></div>`;

    return `${
      partner.veiculo.patins ||
      partner.veiculo.asaDelta ||
      partner.veiculo.capacidadeCarga ||
      partner.veiculo.tamanhoPlataforma
        ? `<div class='acessoriesTab'>
        ${
          partner.veiculo.patins > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.patins
              }</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/patins.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.asaDelta > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.asaDelta
              }</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/asa-delta.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.capacidadeCarga > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${partner
                .veiculo.capacidadeCarga /
                1000}k</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carga.png"/></div>`
            : '<div></div>'
        }
        ${
          partner.veiculo.tamanhoPlataforma > 0
            ? `<div class='acessorieContainer'><div class='acessoriesNumber label label-warning'>${
                partner.veiculo.tamanhoPlataforma
              }m</div><img src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/tamanho.png"/></div>`
            : '<div></div>'
        }
      </div>`
        : '<div></div>'
    }`;
  };

  handleStatus = (marker) => {
    let status;
    const inactivity = this.handleTimeInactivity(marker.dataUltimaPosicao);

    if (inactivity && inactivity >= 30) {
      status = 'PARCEIROINATIVO';
    } else {
      status = marker.status.codigo;
    }
    return status;
  };

  handleSolicitationToPin(map) {
    const { solicitationToPin } = this.props;

    if (solicitationToPin.tipoServico.codigo === `REBOQUE`) {
      const originIcon = L.icon({
        iconUrl:
          'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',

        iconSize: [17, 15], // size of the icon
      });

      const originInfoWindow =
        '<div class="form-group">' +
        '<h3>' +
        solicitationToPin.cliente.nome +
        '</h3>' +
        '<h4>' +
        solicitationToPin.cliente.telefoneCelular +
        '</h4>' +
        '<p>' +
        solicitationToPin.enderecoOrigem.logradouro +
        '<p>' +
        '<p>' +
        'Tipo de Serviço: ' +
        solicitationToPin.tipoServico.codigo +
        '<p>' +
        '</div>';

      const contentOrigin = document.createElement('div');
      contentOrigin.width = '300px';

      contentOrigin.innerHTML = originInfoWindow;

      const originMarker = L.marker(
        this.handlePosition(
          solicitationToPin.enderecoOrigem.latitude,
          solicitationToPin.enderecoOrigem.longitude
        ),
        {
          icon: originIcon,
          title: solicitationToPin.enderecoOrigem.logradouro,
        }
      ).addTo(map);

      originMarker.bindPopup(contentOrigin, {
        minWidth: '200',
      });

      const destinationIcon = L.icon({
        iconUrl:
          'https://icons.iconarchive.com/icons/fatcow/farm-fresh/32/flag-finish-icon.png',

        iconSize: [17, 15], // size of the icon
      });

      const destinationInfoWindow =
        '<div class="form-group">' +
        '<h3>' +
        solicitationToPin.cliente.nome +
        '</h3>' +
        '<h4>' +
        solicitationToPin.cliente.telefoneCelular +
        '</h4>' +
        '<p>' +
        solicitationToPin.enderecoDestino.logradouro +
        '<p>' +
        '<p>' +
        'Tipo de Serviço: ' +
        solicitationToPin.tipoServico.codigo +
        '<p>' +
        '</div>';

      const contentDestination = document.createElement('div');
      contentDestination.width = '300px';

      contentDestination.innerHTML = destinationInfoWindow;

      const bounds = L.latLngBounds([
        this.handlePosition(
          solicitationToPin.enderecoOrigem.latitude,
          solicitationToPin.enderecoOrigem.longitude
        ),
        this.handlePosition(
          solicitationToPin.enderecoDestino.latitude,
          solicitationToPin.enderecoDestino.longitude
        ),
      ]);

      const destinationMarker = L.marker(
        this.handlePosition(
          solicitationToPin.enderecoDestino.latitude,
          solicitationToPin.enderecoDestino.longitude
        ),
        {
          icon: destinationIcon,
          title: solicitationToPin.enderecoDestino.logradouro,
        }
      ).addTo(map);

      destinationMarker.bindPopup(contentDestination, {
        minWidth: '200',
      });

      if (bounds) {
        map.flyToBounds(bounds, {
          animate: true,
          duration: 2.0,
        });
      }
    } else {
      const originIcon = L.icon({
        iconUrl:
          'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',

        iconSize: [17, 15], // size of the icon
      });

      const originInfoWindow =
        '<div class="form-group">' +
        '<h3>' +
        solicitationToPin.cliente.nome +
        '</h3>' +
        '<h4>' +
        solicitationToPin.cliente.telefoneCelular +
        '</h4>' +
        '<p>' +
        solicitationToPin.enderecoOrigem.logradouro +
        '<p>' +
        '<p>' +
        'Tipo de Serviço: ' +
        solicitationToPin.tipoServico.codigo +
        '<p>' +
        '</div>';

      const contentOrigin = document.createElement('div');
      contentOrigin.width = '300px';

      contentOrigin.innerHTML = originInfoWindow;

      const originMarker = L.marker(
        this.handlePosition(
          solicitationToPin.enderecoOrigem.latitude,
          solicitationToPin.enderecoOrigem.longitude
        ),
        {
          icon: originIcon,
          title: solicitationToPin.enderecoOrigem.logradouro,
        }
      ).addTo(map);

      originMarker.bindPopup(contentOrigin, {
        minWidth: '205',
      });

      const bounds = L.latLngBounds([
        this.handlePosition(
          solicitationToPin.enderecoOrigem.latitude,
          solicitationToPin.enderecoOrigem.longitude
        ),
      ]);
      if (bounds) {
        map.flyToBounds(bounds, {
          animate: true,
          duration: 2.0,
        });
      }
    }
  }

  handleMarkers(map) {
    const { partnersTracking } = this.props;
    let {
      allMarkers,
      layerAtivos,
      layerAtendimento,
      layerDescanso,
      layerInativos,
    } = this.state;
    let ativos = [];
    let inativos = [];
    let atendimento = [];
    let descanso = [];
    _.map(partnersTracking, (partner) => {
      let pendenciaFiscal = '';
      let inadimplencia = '';
      let tipoDocumento = '';

      if (partner.pendenciaFiscal === true) {
        pendenciaFiscal = `<p class="text-danger">
            * Possui Pendência Envio de NF
          </p>`;
      }

      if (partner.tipoDocumento !== undefined) {
        tipoDocumento = `<p>Tipo de Documento: ${partner.tipoDocumento}</p>`;
      }

      if (partner.inadimplencia === true) {
        inadimplencia = `<p class="text-danger">
              * Está em inadimplência.
          </p>
        `;
      }

      const handleInfoWindow =
        '<div class="form-group">' +
        '<h3>' +
        partner.contato +
        '</h3>' +
        '<h4>' +
        partner.nomeFantasia +
        '</h4>' +
        `<div>${this.acessoriesTab(partner)}</div>` +
        `<a href="http://api.whatsapp.com/send?l=pt_br&phone=55${partner.telefoneCelular
          .match(/[0-9]/g)
          .join('')}" target="blank"> <i class="fab fa-whatsapp"></i> ` +
        partner.telefoneCelular +
        '</a>' +
        '<p>' +
        partner.dataUltimaPosicao +
        '</p>' +
        tipoDocumento +
        inadimplencia +
        pendenciaFiscal +
        '<p>' +
        'Versão do App: ' +
        partner.versaoApp +
        '</p>' +
        '</div>';

      const self = this;
      const content = document.createElement('div');
      content.width = '300px';
      const formGroup = document.createElement('div');
      formGroup.className = 'form-group';
      let button;
      content.innerHTML = handleInfoWindow;

      button = document.createElement('input');
      button.type = 'button';
      button.className = 'btn btn-xs btn-success custom-button-popup';
      button.value = 'Atualizar App';

      let buttonAtd;
      buttonAtd = document.createElement('input');
      buttonAtd.type = 'button';
      buttonAtd.className =
        'btn btn-xs btn-primary pull-right custom-button-popup';
      buttonAtd.value = 'Disponível?';

      if (hasReboqueMeProfile()) {
        content.appendChild(button);
        content.appendChild(buttonAtd);
      }

      const marker = L.marker(
        this.handlePosition(partner.latitude, partner.longitude),
        {
          icon: this.handleIcon(partner),
          status: this.handleStatus(partner),
          login: partner.login,
          title: partner.contato,
          nomeFantasia: partner.nomeFantasia,
        }
      );
      allMarkers.push(marker);
      marker.bindPopup(content, {
        minWidth: '205',
      });
      L.DomEvent.on(button, 'click', function() {
        sendMapNotification(partner.codigo, 'Atualizar App Reboque.Me!');
      });
      L.DomEvent.on(buttonAtd, 'click', function() {
        sendMapNotification(
          partner.codigo,
          'Esta disponivel? Chamado proximo a voce.'
        );
      });
      //const markers = L.markerClusterGroup()
      //markers.addLayer( marker )
      //map.addLayer( markers )
    }); // fim da funcao de map

    const groupMarkers = (markers) => {
      _.map(markers, (marker) => {
        if (marker.options.status === 'PARCEIROEMATENDIMENTO') {
          atendimento.push(marker);
        } else if (marker.options.status === 'PARCEIROEMDESCANSO') {
          descanso.push(marker);
        } else if (marker.options.status === 'PARCEIROATIVO') {
          ativos.push(marker);
        } else {
          inativos.push(marker);
        }
      });
    };

    groupMarkers(allMarkers);

    layerAtivos = L.layerGroup(ativos);
    layerInativos = L.layerGroup(inativos);
    layerAtendimento = L.layerGroup(atendimento);
    layerDescanso = L.layerGroup(descanso);

    this.setState({
      allMarkers,
      layerAtivos,
      layerInativos,
      layerDescanso,
      layerAtendimento,
    });

    map.addLayer(layerAtivos);
    map.addLayer(layerInativos);
    map.addLayer(layerAtendimento);
    map.addLayer(layerDescanso);

    const controlAtivos = L.Control.extend({
      options: {
        position: 'topright',
        //control position - allowed: 'topleft', 'topright', 'bottomleft', 'bottomright'
      },

      onAdd: function(map) {
        const btnAtivos = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control leaflet-control-custom custom-menu'
        );

        btnAtivos.style.backgroundColor = '#428bca';
        btnAtivos.style.width = '30px';
        btnAtivos.style.height = '30px';

        btnAtivos.onclick = function(e) {
          e.preventDefault();
          if (map.hasLayer(layerAtivos)) {
            $(this).removeClass('selected');
            map.removeLayer(layerAtivos);
          } else {
            map.addLayer(layerAtivos);
            $(this).addClass('selected');
          }
        };

        return btnAtivos;
      },
    });

    const controlDescanso = L.Control.extend({
      options: {
        position: 'topright',
      },
      onAdd: function(map) {
        const btnDescanso = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control leaflet-control-custom custom-menu'
        );

        btnDescanso.style.backgroundColor = '#FFEE58';
        btnDescanso.style.width = '30px';
        btnDescanso.style.height = '30px';

        btnDescanso.onclick = function(e) {
          e.preventDefault();
          if (map.hasLayer(layerDescanso)) {
            $(this).removeClass('selected');
            map.removeLayer(layerDescanso);
          } else {
            map.addLayer(layerDescanso);
            $(this).addClass('selected');
          }
        };
        return btnDescanso;
      },
    });

    const controlAtendimento = L.Control.extend({
      options: {
        position: 'topright',
      },
      onAdd: function(map) {
        const btnAtendimento = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control leaflet-control-custom custom-menu'
        );

        btnAtendimento.style.backgroundColor = '#5cb85c';
        btnAtendimento.style.width = '30px';
        btnAtendimento.style.height = '30px';

        btnAtendimento.onclick = function(e) {
          e.preventDefault();
          if (map.hasLayer(layerAtendimento)) {
            $(this).removeClass('selected');
            map.removeLayer(layerAtendimento);
          } else {
            map.addLayer(layerAtendimento);
            $(this).addClass('selected');
          }
        };
        return btnAtendimento;
      },
    });

    const controlInativos = L.Control.extend({
      options: {
        position: 'topright',
      },
      onAdd: function(map) {
        const btnInativo = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control leaflet-control-custom custom-menu'
        );

        btnInativo.style.backgroundColor = '#d9534f';
        btnInativo.style.width = '30px';
        btnInativo.style.height = '30px';

        btnInativo.onclick = function(e) {
          e.preventDefault();
          if (map.hasLayer(layerInativos)) {
            $(this).removeClass('selected');
            map.removeLayer(layerInativos);
          } else {
            map.addLayer(layerInativos);
            $(this).addClass('selected');
          }
        };
        return btnInativo;
      },
    });

    const createCityControl = (info) => {
      return L.Control.extend({
        options: {
          position: 'bottomright',
        },
        onAdd: function(map) {
          const btnInativo = L.DomUtil.create(
            'div',
            'leaflet-bar leaflet-control leaflet-control-custom custom-menu'
          );

          btnInativo.style.backgroundColor = 'white';
          btnInativo.style.width = '30px';
          btnInativo.style.height = '30px';
          btnInativo.style.display = 'flex';
          btnInativo.style.justifyContent = 'center';
          btnInativo.style.alignItems = 'center';
          btnInativo.textContent = info.sigla;

          const activeClass = 'active-city-control-map';

          if (info.defaultValue) {
            btnInativo.classList.add(activeClass);
          }

          btnInativo.onclick = function(e) {
            e.preventDefault();
            document
              .querySelectorAll(`.${activeClass}`)
              .forEach((el) => el.classList.remove(activeClass));

            e.target.classList.add(activeClass);

            map.setView([info.lat, info.lng], info.zoom);
          };
          return btnInativo;
        },
      });
    };

    const controlMG = createCityControl({
      lat: -19.841644,
      lng: -43.986511,
      zoom: 11,
      sigla: 'MG',
    });

    const controlSP = createCityControl({
      lat: -23.5489,
      lng: -46.6388,
      zoom: 11,
      sigla: 'SP',
      defaultValue: true,
    });

    const controlCuritiba = createCityControl({
      lat: -25.4284,
      lng: -49.2733,
      zoom: 11,
      sigla: 'PR',
    });

    const controlPortoAlegre = createCityControl({
      lat: -30.0277,
      lng: -51.2287,
      zoom: 11,
      sigla: 'RS',
    });

    const controlRioDeJaneiro = createCityControl({
      lat: -22.9035,
      lng: -43.2096,
      zoom: 11,
      sigla: 'RJ',
    });

    map.addControl(new controlAtivos());
    map.addControl(new controlAtendimento());
    map.addControl(new controlDescanso());
    map.addControl(new controlInativos());

    map.addControl(new controlCuritiba());
    map.addControl(new controlPortoAlegre());
    map.addControl(new controlRioDeJaneiro());
    map.addControl(new controlMG());
    map.addControl(new controlSP());
  }

  handleMapLoad() {
    const self = this;
    const {
      partnersTracking,
      center,
      id,
      solicitationToPin,
      pinSolicitation,
    } = this.props;
    let {
      layerAtivos,
      layerAtendimento,
      layerDescanso,
      layerInativos,
    } = this.state;
    const osmUrl =
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}';
    self.map = L.map(id).setView(center, 13);

    if (self.map) {
      L.tileLayer(osmUrl, {
        maxZoom: 19,
        id,
      }).addTo(self.map);
      L.control.scale().addTo(self.map);
    }

    if (self.map && partnersTracking) {
      this.handleMarkers(self.map);
      this.handleSearchBox(self.map);
    }

    if (self.map && solicitationToPin && pinSolicitation === true) {
      this.handleSolicitationToPin(self.map);
    }
  }

  componentDidMount() {
    this.handleMapLoad();
  }

  handleTimeInactivity(start) {
    let timeOfInactivity;
    let timeToArrive;
    const startDate = moment(start, 'D HH:mm');
    const end = moment();
    const endDate = moment(end, 'D HH:mm');

    // const timeToArrive = moment.duration( startDate.diff( endDate ) ).minutes( ) * -1
    if (startDate && endDate.diff(startDate) < 0) {
      timeToArrive = endDate.diff(startDate, 'minutes', true) * -1;
      //timeToArrive = endDate.diff( startDate, 'minutes', true ) * -1
      //timeToArrive = moment.utc(moment( endDate, 'DD/MM/YY HH:mm:ss' ).diff(moment( startDate, 'DD/MM/YY HH:mm:ss' ))).format('mm') * -1//moment(endDate.diff( startDate, 'minutes' )).format('m') * -1
    }
    if (startDate && endDate.diff(startDate) >= 0) {
      timeToArrive = endDate.diff(startDate, 'minutes', true);
      //timeToArrive = endDate.diff( startDate, 'minutes', true )
      //timeToArrive = moment.utc(moment( endDate, 'DD/MM/YY HH:mm:ss' ).diff(moment( startDate, 'DD/MM/YY HH:mm:ss' ))).format('mm')
    }
    if (timeToArrive) {
      //console.log('TEMPO', Math.floor( timeToArrive / 60 ), Math.floor( timeToArrive % 60 ))
      timeOfInactivity = Math.floor(timeToArrive);
      return timeOfInactivity;
    }
  }

  render() {
    const { id, style, center, zoom, overlayStyle } = this.props;
    const { allMarkers } = this.state;
    //const { loadingMap } = this.state

    //this.handleLoadDataPromise()// mapeia a store de solicitações num objeto lat-lng do google

    return <div id={id} style={style} />;
  }
}
