export function getArea(address) {
  let return_value;
  if (!address || !address.gmaps || !address.gmaps.address_components)
    return null;
  address.gmaps.address_components.map((component) => {
    if (
      component.types.indexOf('sublocality') > -1 ||
      component.types.indexOf('locality') > -1
    ) {
      return_value = component.short_name;
    }
  });

  return return_value;
}

export function getCity(address) {
  let return_value;
  if (!address || !address.gmaps || !address.gmaps.address_components)
    return null;
  address.gmaps.address_components.map((component) => {
    if (component.types.indexOf('administrative_area_level_2') > -1) {
      return_value = component.short_name;
    }
  });

  return return_value;
}

export function getStreetNumber(address) {
  let return_value;
  if (!address || !address.gmaps || !address.gmaps.address_components)
    return null;
  address.gmaps.address_components.map((component) => {
    if (component.types.indexOf('street_number') > -1) {
      return_value = component.short_name;
    }
  });

  return return_value;
}

export function getCountry(address) {
  let return_value;

  if (!address || !address.gmaps || !address.gmaps.address_components)
    return null;
  address.gmaps.address_components.map((component) => {
    if (component.types.indexOf('country') > -1) {
      return_value = component.long_name;
    }
  });

  return return_value;
}

export function getState(address) {
  let return_value;

  if (!address || !address.gmaps || !address.gmaps.address_components)
    return null;
  address.gmaps.address_components.map((component) => {
    if (component.types.indexOf('administrative_area_level_1') > -1) {
      return_value = component.short_name;
    }
  });
  return return_value;
}

export function getPostalCode(address) {
  let return_value;

  if (!address || !address.gmaps || !address.gmaps.address_components)
    return null;
  address.gmaps.address_components.map((component) => {
    if (component.types.indexOf('postal_code', component.long_name) > -1) {
      return_value = component.long_name;
    }
  });
  return return_value;
}
