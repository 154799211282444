
export function toTextPlainTime(totalInSeconds) {
    const hours = Math.round(totalInSeconds / 3600);
    const minutes = Math.round((totalInSeconds - (hours * 3600)) / 60);
    const seconds = Math.round(totalInSeconds % 60);

    let displayTime = '';

    if (hours === 1) {
        displayTime += `${hours} hora`;
    } else if( hours > 1) {
        displayTime += `${hours} horas`;
    } 

    if (minutes > 0) {

        if (hours > 0) {
            displayTime += ' e '
        }

        displayTime += `${minutes} minutos`
    }

    if (minutes === 0 && hours === 0) return `${seconds} segundos`;

    return displayTime;
}

export const secondsToMinutes = (seconds) => {
    if (typeof seconds === 'string') {
      return Number.parseInt(seconds) / 60;
    } 

    return seconds / 60;
}

export const minutesToSeconds = (minutes) => {
    if (typeof minutes === 'string') {
        return Number.parseInt(minutes) / 60;
    }

    return minutes * 60;
}