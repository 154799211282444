import React, {Component} from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';

export default class BalanceForm extends Component {
  constructor(props) {
    super(props);
    const startDate = new moment().subtract(15, 'days');
    const endDate = new moment();
    const vencimentoInvoiceParceiro = new moment();
    this.state = {
      startDate,
      endDate,
      desc: '',
      vencimentoInvoiceParceiro,
      loading: false,
    };
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handleDesc = this.handleDesc.bind(this);
    this.handleVencimento = this.handleVencimento.bind(this);
  }

  handleStart(startDate) {
    this.setState({
      startDate,
    });
  }
  handleEnd(endDate) {
    this.setState({
      endDate,
    });
  }
  handleDesc(e) {
    this.setState({desc: e.target.value});
  }
  handleVencimento(vencimentoInvoiceParceiro) {
    this.setState({vencimentoInvoiceParceiro});
  }

  render() {
    const {startDate, endDate, vencimentoInvoiceParceiro} = this.state;
    return (
      <div>
        <form className="" style={{textAlign: 'center'}}>
          <div className="form-inline">
            <div className="form-group">
              <DateTime
                onChange={this.handleStart}
                value={moment(startDate).local().format('DD/MM/YYYY 00:00')}
                closeOnSelect
              />
            </div>
            <div className="form-group">
              <label className="font-normal p-w-xs">Até</label>
            </div>
            <div className="form-group">
              <DateTime
                onChange={this.handleEnd}
                value={moment(endDate).local().format('DD/MM/YYYY 23:59')}
                closeOnSelect
              />
            </div>
          </div>
          <div className="form-group col-md-12" style={{padding: '20px'}}>
            <textarea
              className="form-control"
              onChange={this.handleDesc}
              rows={5}
              placeholder="Descrição"
            />
          </div>
          <div className="form-group">
            <label>Vencimento:</label>
            <DateTime
              onChange={this.handleVencimento}
              value={moment(vencimentoInvoiceParceiro).local().format('DD/MM/YYYY 23:59')}
              closeOnSelect
            />
          </div>
          <div className="form-group">
            <button
              disabled={this.state.loading}
              className="btn btn-block btn-primary"
              onClick={async (e) => {
                e.preventDefault();
                this.setState((prevstate) => ({...prevstate, loading: true}));
                await this.props.createBalance(
                  this.state.startDate,
                  this.state.endDate,
                  this.state.desc,
                  this.state.vencimentoInvoiceParceiro,
                );
                this.setState((prevstate) => ({
                  ...prevstate,
                  loading: false,
                }));
              }}>
              Criar Fechamento
            </button>
          </div>
        </form>
      </div>
    );
  }
}
