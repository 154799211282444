import React from 'react'

const getStars = (stars)=>{
    const nodes = []
    for(let i = 0; i <5; i++){
        if(i >= stars)
            nodes.push(
                <div className='rateStar rateStar-full' key={`star${i}`}><i className="far fa-star"></i></div>
            )
        else
            nodes.push(
                <div className='animated tada rateStar'  key={`star${i}`} ><i className="fas fa-star"></i></div>
            )
    }
    return nodes
}

const Rate = ({stars, avaliado})=>{
    return(
        <div style={{width: '100%'}}>
        {
            avaliado ? 
                getStars(stars) : 
                <span>-</span>
        }        
  
        </div>
    )
}

export default Rate