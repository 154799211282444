import React, { Component } from 'react'
import MarkerClusterer from '@google/markerclusterer'


const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';



export default class ClusterMap extends Component {

    constructor(props) {
        super(props)
        this.markers = []
        this.markerCluster = null
    }

    componentDidMount() {

        
        this.initMap()
        this.mountClusters()

    }

    componentDidUpdate() {
        this.mountClusters()
    }

    mountClusters() {
        const { data } = this.props
        this.markers = data.map(function (location, i) {
            return new google.maps.Marker({
                position: location,
            });
        });
        this.markerCluster = new MarkerClusterer(this.map, this.markers,
            { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' })
    }

    initMap() {

        const { id, requestOrigin } = this.props
        this.map = new google.maps.Map(document.getElementById(id), {
            center: { lat: -22.915, lng: -43.197 },
            zoom: 5,
            gestureHandling: 'greedy',
            controlSize: 24,
            disableDefaultUI: true
        })
        var overlay = new google.maps.OverlayView()
        overlay.draw = function () {
            this.getPanes().markerLayer.id = 'markerLayer'
        }
        overlay.setMap(this.map)

    }

    render() {
        return (
            <div className="routeMap"
                id={this.props.id}
                style={this.props.style} />
        )
    }
}