import React, {Component} from 'react';

import {connect} from 'react-redux';

import 'react-table/react-table.css';
import {USUARIO_TIPO_REBOQUEME, USUARIO_TIPO_BASE} from '../reducers/constants';
import ReactTableCommon from '../components/common/ReactTable';
import {clearServiceState} from '../actions/service_actions';
import {getBasesRelatorios, sendIndividualEmail} from '../actions/relatorio_actions';
import {
  selectSolicitationByCode,
  updateSolicitaionValues,
  getCompanies,
  getUsuariosRelatorios,
  getPrestadoresRelatorios,
} from '../actions/relatorio_actions';
import {
  loadConcludedSolicitationsList,
  openWhatsApp,
  loadConcludedSolicitationsListCVSExport,
} from '../actions/followup_actions';

class RelatorioView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      companies: '',
      usuarios: '',
      status: '',
      conclusaoManual: '',
      servicos: '',
      parceiros: '',
      base: '',
      baseid: [],
      vehicleCondition: '',
    };
    $.fn.modal.Constructor.prototype.enforceFocus = function () {};
    this.handleCompanies = this.handleCompanies.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleConclusaoManual = this.handleConclusaoManual.bind(this);
    this.handleServicos = this.handleServicos.bind(this);
    this.handleUsuarios = this.handleUsuarios.bind(this);
    this.handleParceiros = this.handleParceiros.bind(this);
    this.handleBases = this.handleBases.bind(this);
  }

  componentWillMount() {
    const userType = this.props.login.user_info.usuario.tipoUsuario;
    const {getAllCompanies, getAllUsuarios, getAllPrestadores, getAllBases} = this.props;

    getAllCompanies();
    getAllUsuarios();

    if (userType === USUARIO_TIPO_REBOQUEME || userType === USUARIO_TIPO_BASE) {
      getAllBases();
    }
  }

  handleStatus(e) {
    this.setState({status: e.map((status) => status.value).toString()});
  }

  handleConclusaoManual(e) {
    this.setState({conclusaoManual: e.map((tipo) => tipo.value).toString()});
  }

  handleServicos(e) {
    this.setState({servicos: e.map((servico) => servico.value).toString()});
  }

  handleCompanies(e) {
    this.setState({companies: e.map((company) => company.value).toString()});
  }

  handleUsuarios(e) {
    this.setState({usuarios: e.map((user) => user.value).toString()});
  }

  handleParceiros(e) {
    this.setState({parceiros: e.map((parceiro) => parceiro.value).toString()});
  }

  handleBases(e) {
    this.setState({
      base: e.map((base) => base.value),
      baseid: e.map((base) => base.baseid),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {baseid} = this.state;
    if (prevState.base !== this.state.base) {
      this.props.getAllPrestadores(baseid);
    }
  }

  componentWillReceiveProps(nextProps) {
    nextProps.solicitations
      ? this.setState({
          data: nextProps.solicitations,
        })
      : false;
  }

  componentWillUnmount() {
    this.props.resetSolicitatonsList();
    this.props.clearServiceState();
    this.props.resetSolicitatonsListCsvExport();
  }

  render() {
    let compiledCompanies = this.state.companies;
    let compiledUsuarios = this.state.usuarios;

    const {
      selectSolicitation,
      selectedSolicitation,
      selectedSolicitationFetching,
      updateSolicitation,
      solicitationUpdated,
      solicitationUpdateFetching,
      companies,
      usuarios,
      bases,
    } = this.props;

    return (
      <div className="row p-h-sm">
        <div className="col-md-12">
          <div>
            <div className="ibox-title">
              <h5>Relatório de Solicitações</h5>
            </div>
            <div className="ibox-content" style={{height: '1200px'}}>
              <div className="row">
                <ReactTableCommon
                  openWpp={this.props.openWpp}
                  updateSolicitation={updateSolicitation}
                  solicitationUpdated={solicitationUpdated}
                  solicitationUpdateFetching={solicitationUpdateFetching}
                  sendEmail={this.props.sendEmail}
                  data={this.props.solicitations || []}
                  dataCVSExport={this.props.solicitationCVSExport || []}
                  fetchingCVSProgress={this.props.fetchingCVSProgress}
                  loadData={this.props.loadConcludedSolicitations}
                  loadCVSExportData={this.props.loadConcludedSolicitationsCvsExport}
                  isFetching={this.props.isFetching}
                  isFetchingCVS={this.props.isFetchingCVS}
                  selectSolicitation={selectSolicitation}
                  selectedSolicitation={selectedSolicitation}
                  selectedSolicitationFetching={selectedSolicitationFetching}
                  companies={companies}
                  usuarios={usuarios || []}
                  parceiros={this.state.parceiros}
                  bases={this.state.base}
                  allCompanies={this.props.companies
                    .map((company) => company.codigo)
                    .toString()}
                  allUsuarios={this.props.usuarios.map((u) => u.id).toString()}
                  allParceiros={this.props.parceiros}
                  allBases={bases}
                  compiledCompanies={compiledCompanies}
                  compiledUsuarios={compiledUsuarios}
                  handleCompanies={this.handleCompanies}
                  handleUsuarios={this.handleUsuarios}
                  handleParceiros={this.handleParceiros}
                  handleBases={this.handleBases}
                  handleStatus={this.handleStatus}
                  handleConclusaoManual={this.handleConclusaoManual}
                  handleVehicleConditions={this.handleVehicleConditions}
                  vehicleCondition={this.vehicleCondition}
                  handleServicos={this.handleServicos}
                  status={this.state.status}
                  servicos={this.state.servicos}
                  conclusaoManual={this.state.conclusaoManual}
                  profileId={this.props.login.user_info.usuario.tipoUsuario}
                  userInfo={this.props.login.user_info}
                  resetSolicitatonsList={this.props.resetSolicitatonsList}
                  resetSolicitatonsListCsvExport={this.props.resetSolicitatonsListCsvExport}
                  location={this.props.location}
                  totalItems={this.props.totalItems}
                  pages={this.props.pages}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RelatorioView.defaultProps = {
  usuarios: [],
};

const mapStateToProps = (state) => {
  return {
    solicitations: state.dashboard.filteredConcludedSolicitations,
    solicitationCVSExport: state.dashboard.concludedSolicitationsForCVSExport,
    fetchingCVSProgress: state.dashboard.fetchingCVSProgress,
    isFetching: state.dashboard.isFetching,
    isFetchingCVS: state.dashboard.isFetchingCVS,
    selectedSolicitation: state.relatorio.selectedSolicitation,
    selectedSolicitationFetching: state.relatorio.selectedSolicitationFetching,
    solicitationUpdated: state.relatorio.solicitationUpdated,
    solicitationUpdateFetching: state.relatorio.solicitationUpdateFetching,
    companies: state.relatorio.companies,
    usuarios: state.relatorio.usuarios,
    parceiros: state.relatorio.parceiros,
    bases: state.relatorio.bases,
    login: state.login,
    pages: state.dashboard.pages,
    totalItems: state.dashboard.totalItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearServiceState: () => {
      dispatch(clearServiceState());
    },
    updateSolicitation: (solicitaionCode, values) =>
      dispatch(updateSolicitaionValues(solicitaionCode, values)),
    selectSolicitation: (solicitaionCode, values) => {
      dispatch(selectSolicitationByCode(solicitaionCode));
    },
    loadConcludedSolicitations: (
      startDate,
      endDate,
      sizePerPage,
      empresa,
      status,
      usuario,
      veiculo,
      parceiro,
      bases,
      servicos,
      conclusaoManual,
      codigo,
      page,
      ownId,
      vehicleCondition,
    ) => {
      dispatch(
        loadConcludedSolicitationsList(
          startDate,
          endDate,
          sizePerPage,
          empresa,
          status,
          usuario,
          veiculo,
          parceiro,
          bases,
          servicos,
          conclusaoManual,
          codigo,
          page,
          ownId,
          vehicleCondition,
        ),
      );
    },
    loadConcludedSolicitationsCvsExport: (
      startDate,
      endDate,
      sizePerPage,
      empresa,
      status,
      usuario,
      veiculo,
      parceiro,
      servicos,
      bases,
      conclusaoManual,
      codigo,
      page,
      vehicleCondition,
    ) => {
      dispatch(
        loadConcludedSolicitationsListCVSExport(
          startDate,
          endDate,
          sizePerPage,
          empresa,
          status,
          usuario,
          veiculo,
          parceiro,
          servicos,
          bases,
          conclusaoManual,
          codigo,
          page,
          vehicleCondition,
        ),
      );
    },
    sendEmail: (solicitaionCode) => {
      dispatch(sendIndividualEmail(solicitaionCode));
    },
    getAllCompanies: () => {
      dispatch(getCompanies());
    },
    getAllUsuarios: () => {
      dispatch(getUsuariosRelatorios());
    },
    getAllPrestadores: (partnersIds) => {
      dispatch(getPrestadoresRelatorios(partnersIds));
    },
    getAllBases: () => {
      dispatch(getBasesRelatorios());
    },
    stopAllTimers: () => {
      dispatch({
        type: `STOP_ALL_TIMERS`,
      });
    },
    resetSolicitatonsList: () => {
      dispatch({
        type: 'CONCLUDED_SOLICITATIONS_FILTERED',
        action: [],
      });
    },
    resetSolicitatonsListCsvExport: () => {
      dispatch({
        type: 'CONCLUDED_SOLICITATIONS_CVS_CLEAR',
      });
    },
    openWpp: (number, text) => {
      dispatch(openWhatsApp(number, text));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatorioView);
