import swal from 'sweetalert';

import {toastError, toastSuccess} from './toast_actions';
import {
  getSendPushNotification,
  putAddExpirationTime,
  getRemoveNotifyPartner,
  acceptSolicitation,
  expireSolicitation as expireSolicitationHelper,
  startScheduled,
  putUpdateDeductible,
} from '../api/solicitation_helper';

export function expireSolicitation(solicitationCode) {
  return (dispatch, getState) => {
    swal(
      {
        title: 'Deseja expirar a solicitação?',
        showCancelButton: true,
        closeOnConfirm: true,
        animation: 'slide-from-top',
        showLoaderOnConfirm: true,
        type: 'warning',
      },
      () => {
        expireSolicitationHelper(getState().followup.currentSolicitation.codigo)
          .then((result) => {
            toastSuccess(`Solicitação expirada com sucesso!`);
          })
          .catch((error) => {
            toastError(error.mensagemRetorno);
          });
      },
    );
  };
}

export function addExpirationTime(time) {
  return (dispatch, getState) => {
    if (time === 0) {
      swal(
        {
          title: 'Deseja expirar a solicitação?',
          showCancelButton: true,
          closeOnConfirm: true,
          animation: 'slide-from-top',
          showLoaderOnConfirm: true,
          type: 'warning',
        },
        () => {
          putAddExpirationTime(getState().followup.currentSolicitation.codigo, time)
            .then((result) => {
              toastSuccess(`Tempo de expiração estendido em ${time} minutos.`);
            })
            .catch((error) => {
              toastError(error.mensagemRetorno);
            });
        },
      );
    } else {
      putAddExpirationTime(getState().followup.currentSolicitation.codigo, time)
        .then((result) => {
          toastSuccess(`Tempo de expiração estendido em ${time} minutos.`);
        })
        .catch((error) => {
          toastError(error.mensagemRetorno);
        });
    }
  };
}

export function sendPushNotification(codePartner) {
  return (dispatch) => {
    getSendPushNotification(codePartner)
      .then((result) => {
        toastSuccess('Notificação enviada com sucesso.');
      })
      .catch((error) => {
        toastError(error.mensagemRetorno);
      });
  };
}

export function acceptSolicitationPartner(solicitationCode, codePartner) {
  return (dispatch) => {
    acceptSolicitation(solicitationCode, codePartner)
      .then((result) => {
        toastSuccess(result.mensagemCliente);
        // dispatch(goForward());
        // TODO: melhorar forma de redirecionar para solicitação em atendimento
        window.location.reload();
      })
      .catch((error) => {
        toastError(error.mensagemRetorno);
        return error.mensagemRetorno;
      });
  };
}

export function startScheduledSolicitation(solicitationCode) {
  return (dispatch) => {
    startScheduled(solicitationCode)
      .then((result) => {
        toastSuccess(result.mensagemCliente);
        // dispatch(goForward());
        // TODO: melhorar forma de redirecionar para solicitação em atendimento
        window.location.reload();
      })
      .catch((error) => {
        toastError(error.mensagemRetorno);
      });
  };
}

export function removeNotifyPartner(solicitationCode, codePartner) {
  return (dispatch) => {
    getRemoveNotifyPartner(solicitationCode, codePartner)
      .then((result) => {
        toastSuccess(result.mensagemCliente);
      })
      .catch((error) => {
        toastError(error.mensagemRetorno);
      });
  };
}

export async function updateDeductible(body) {
  return putUpdateDeductible(body)
    .then((result) => {
      toastSuccess(result.mensagemCliente);
    })
    .catch((error) => {
      toastError(error.mensagemRetorno);
    });
}
