
export function startTimer(timerID, interval, timerFunction){
  return (dispatch, getState) => {
    let timer = getState().timer[timerID]

    if(!timer) {
      timer = setInterval(() => {
        timerFunction()
      }, interval)  

      timerFunction()
    }

    dispatch( {type: TIMER_START, id: timerID, reference: timer} )
  }
}

export function stopTimer(timerID){
  return (dispatch, getState) => {
    let timer = getState().timer[timerID]
    if (timer){
      clearInterval(timer)
    }
    dispatch( {type: TIMER_STOP, id: timerID, reference: timer} )
  }
}

export function stopAllTimers(){
  return (dispatch, getState) => {
    let timers = getState().timer
    for (var timerID in timers)
      dispatch(stopTimer(timerID))
  }
}

export const TIMER_START = 'TIMER_START'
export const TIMER_STOP = 'TIMER_STOP'
