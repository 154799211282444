import React from 'react';

const Card = ({title, children, contentClassName, onClose}) => {
  return (
    <div className="ibox float-e-margins">
      <div className="ibox-title" style={{display: (title ? "block" : "none")}}>
        <h5>{title}</h5>
        <div className="ibox-tools" style={{display: (onClose ? "block" : "none")}}>
            <a className="close-link" onClick={onClose}>
                <i className="fa fa-times"></i>
            </a>
        </div>
      </div>
      <div className={"ibox-content " + contentClassName}>
        {children}
      </div>
    </div>
  )
}

export default Card
