import React from 'react';
import {connect} from 'react-redux';
import {
  onFormFieldChange,
  setFormValues,
  clearFormValues,
  setReadOnlyMode,
} from '../../actions/form_actions';
import {createNewVehicle, updateExistingVehicle} from '../../actions/vehicle_actions';
import CustomerAutoComplete from '../containers/CustomerAutoCompleteStateful';
import MaskedInput from '../common/MaskedInput';
import ModelAutoComplete from '../containers/ModelAutoCompleteStateful';

export const MODEL_AUTOCOMPLETE = 'MODEL_AUTOCOMPLETE';
export const VEHICLE_FORM = 'VEHICLE_FORM';
export const VEHICLE_FORM_MODEL_INPUT = 'VEHICLE_FORM_MODEL_INPUT';
export const VEHICLE_FORM_CUSTOMER_INPUT = 'VEHICLE_FORM_CUSTOMER_INPUT';

class VehicleFormView extends React.Component {
  focusVehicleInput = () => {
    $('#VEHICLE_FORM_MODEL_INPUT').focus();
  };

  render() {
    return (
      <form role="form">
        <div
          className="alert alert-danger alert-dismissable"
          style={{display: this.props.errorMessage ? 'block' : 'none'}}>
          {this.props.errorMessage}
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <CustomerAutoComplete
              context={VEHICLE_FORM_CUSTOMER_INPUT}
              iconName="fa fa-user"
              placeholder="Nome do Cliente"
              disabled={this.props.isEditing || this.props.isCustomerDisabled}
              afterOptionSelected={this.props.afterCustomerSelected}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <label>Marca / Modelo</label>
            <ModelAutoComplete
              id={VEHICLE_FORM_MODEL_INPUT}
              context={VEHICLE_FORM_MODEL_INPUT}
              iconName="fa fa-car"
              placeholder="Ex: Ford Ka"
              disabled={this.props.readonlyDetails}
              afterOptionSelected={this.props.handleModelSelected}
              afterClear={() => {
                this.props.handleModelSelected(false);
                this.focusVehicleInput();
              }}
              auth={this.props.auth}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-4">
            <label>Placa</label>
            <MaskedInput
              disabled={this.props.readonlyDetails}
              value={this.props.placa}
              onChange={this.props.onFieldChange}
              className="form-control"
              id="placa"
              mask="***-****"
            />
          </div>
          <div className="form-group col-md-4">
            <label>Ano</label>
            <MaskedInput
              className="form-control"
              disabled={this.props.readonlyDetails}
              value={this.props.ano}
              onChange={this.props.onFieldChange}
              id="ano"
              value={this.props.ano}
              onChange={this.props.onFieldChange}
              mask="9999"
            />
          </div>
          <div className="form-group col-md-4">
            <label>Cor</label>
            <input
              disabled={this.props.readonlyDetails}
              value={this.props.cor}
              onChange={this.props.onFieldChange}
              className="form-control"
              id="cor"
            />
          </div>
        </div>

        {this.props.children}
        <div
          className="form-group"
          style={{
            display: this.props.readonly || this.props.readonlyDetails ? 'none' : 'block',
          }}>
          <button
            style={{display: !this.props.isEditing ? 'block' : 'none'}}
            className="btn btn-block btn-primary"
            type="submit"
            onClick={this.props.onSave}>
            Criar Veículo
          </button>
          <button
            style={{display: this.props.isEditing ? 'block' : 'none'}}
            className="btn btn-block btn-primary"
            type="submit"
            onClick={this.props.onUpdate}>
            Atualizar Veículo
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let formState = state.forms[ownProps.context];
  let customerState = state.autocomplete[VEHICLE_FORM_CUSTOMER_INPUT];

  if (formState && formState.placa) {
    formState.placa = formState.placa.toUpperCase();
  }

  return {
    ...ownProps,
    ...formState,
    model: state.forms[MODEL_AUTOCOMPLETE],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    afterCustomerSelected: (customer) => {
      dispatch(ownProps.afterCustomerSelected(customer));
      //dispatch( onFormFieldChange(ownProps.context, 'codigoCliente', customer.codigo) )
    },
    onFieldChange: (event) => {
      dispatch(onFormFieldChange(ownProps.context, event.target.id, event.target.value));
    },
    onSave: (event) => {
      event.preventDefault();
      dispatch(
        createNewVehicle(
          ownProps.context,
          VEHICLE_FORM_CUSTOMER_INPUT,
          ownProps.afterCreate,
          ownProps.auth,
        ),
      );
    },
    onUpdate: (event) => {
      event.preventDefault();
      dispatch(
        updateExistingVehicle(
          ownProps.context,
          VEHICLE_FORM_CUSTOMER_INPUT,
          ownProps.afterUpdate,
          ownProps.auth,
        ),
      );
    },

    onModelSelected: (selectedValue) => {
      if (selectedValue) {
        dispatch(onFormFieldChange(ownProps.context, 'modelo', selectedValue.modelo));
        dispatch(onFormFieldChange(ownProps.context, 'marca', selectedValue.marca));
        dispatch(
          onFormFieldChange(ownProps.context, 'tipoVeiculo', selectedValue.tipoVeiculo),
        );
      }
      dispatch(setFormValues(MODEL_AUTOCOMPLETE, selectedValue)); // Vehicle Form
      dispatch(setReadOnlyMode(MODEL_AUTOCOMPLETE, true)); // Vehicle Form
      // dispatch( onVehicleSelect(selectedValue) ) // update this service state
      // dispatch( setAddressFocus() ) // focus on OriginAddress
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleFormView);
