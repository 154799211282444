import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

export default class CopyToClipboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      title,
      textToBeCopied,
      icon,
      dataTip = 'Copiado!',
      dataPlace = 'top',
      ...rest
    } = this.props;

    return (
      <a
        {...rest}
        ref={(ref) => (this.fooRef = ref)}
        data-tip={dataTip}
        data-event
        data-place={dataPlace}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigator.clipboard.writeText(this.props.textToBeCopied);
          ReactTooltip.show(this.fooRef);
          setTimeout(() => ReactTooltip.hide(this.fooRef), 1500);
        }}
      >
        {icon ? <i className={icon} /> : ''} {title}
      </a>
    );
  }
}
