import React from 'react';
import {connect} from 'react-redux';
import {onAddressChange, clearAddress} from '../../actions/address_actions';
import {onAddressSelect} from '../../actions/service_actions';
import Geosuggest from 'react-geosuggest';

class GooglePlacesAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      initialValue:
        this.props.initialValue || this.props.currentAddress
          ? this.props.initialValue || this.props.currentAddress
          : '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.focus !== nextProps.fozcus) {
      setTimeout(() => {
        this.googleRef.focus();
      }, 1);
    }
    if (nextProps.initialValue) {
      this.setState({
        initialValue: nextProps.initialValue,
      });
    } else {
      this.setState({
        initialValue: '',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contAlter !== this.props.contAlter) this.googleRef.clear();
  }

  handleChange() {
    this.googleRef.focus();
  }

  getFavoriteDestinarions = () => {
    let favoriteDestinations;

    if (this.props.favoriteDestinations && this.props.favoriteDestinations.length > 0) {
      favoriteDestinations = this.props.favoriteDestinations;
    } else {
      favoriteDestinations = this.props.favoriteDestinationsList;
    }

    if (!Array.isArray(favoriteDestinations)) {
      return;
    }

    return favoriteDestinations.map((destination) => {
      return {
        className: 'favorite-address',
        label: '✭  ' + destination.descricao,
        location: {
          lat: destination.endereco.latitude,
          lng: destination.endereco.longitude,
        },
        ...destination.endereco,
        type: 'favorite',
      };
    });
  };

  render() {
    const { isDaf } = this.props;
    let country = ''
    if (isDaf) {
      country = ['br', 'py', 'bo', 'ar', 'cl']
    } else {
      country = 'br'
    }

    return (
      <Geosuggest
        ref={(ref) => (this.googleRef = ref)}
        country={ country }
        maxFixtures={15}
        inputClassName="form-control"
        placeholder={this.props.placeholder}
        fixtures={this.props.favoriteDestinations ? this.getFavoriteDestinarions() : []}
        initialValue={this.state.initialValue}
        onSuggestSelect={
          this.props.actionSelected ? this.props.actionSelected : this.props.onOptionSelected
        }
        onFocus={this.props.onFocus}
        onChange={(e) => {
          e === `` ? this.props.onClear() : null;
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    selectedItem: state.address[ownProps.id],
    favoriteDestinationsList: state.address.favoriteDestinationsList,
    currentAddress: state.base.currentBase.endereco.logradouro,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onOptionSelected: (address) => {
      dispatch(onAddressChange(ownProps.id, address));
    },
    onFocus: () => {
      dispatch(onAddressSelect());
    },
    onClear: () => {
      dispatch(clearAddress(ownProps.id));
    },
  };
};

const AddressAutoComplete = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GooglePlacesAutoComplete);

export default AddressAutoComplete;
