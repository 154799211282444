import React, {Component} from 'react';

import withReactContent from 'sweetalert2-react-content';
import swal from 'sweetalert2';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import UserPerformance from './UserPerformance/UserPerformance';

import './Dashboard.css';
import {Tabs, Tab} from 'react-bootstrap';

const MySwal = withReactContent(swal);

import TimeLinePerformance from './TimeLinePerformance/TimeLinePerformance';
import ParkingTable, {NO_PATIO, A_CAMINHO, SAINDO_PATIO} from './parkingtable';
import ClusterLoader from './loaders/ClusterLoader';
import DocumentsTable, {DOCUMENTS_AVALIATION} from './documentstable';
import {
  getUserPerformance,
  getDashboardStats,
  getLocationCluster,
  getCompanyCredits,
  buyCredits,
  getDocumentsToApproval,
  removeAssetsDocuments,
  getDocumentAssetsFromSolicitation,
  getParkingVehicles,
} from './dashboardActions';
import TitleButton from '../common/titleButtons/TitleButton';

import moment from 'moment';
import jwt from 'jsonwebtoken';

import OptionChooser from '../common/OptionChooser';
import LastLost from '../common/LastLost';
import CustomModal, {doOpenModal, doCloseModal} from '../common/CustomModal';
import ClusterMap from '../common/ClusterMap';
import BasicList from '../common/basicList/BasicList';
import {default as InvoiceCard} from '../balance/invoiceCard';
import {
  hasAdminAccess,
  hasBaseProfile,
  hasCompanyProfile,
  hasDafProfile,
  hasDafTvProfile,
  hasProfiles,
  hasReboqueMeProfile,
  isFromCompany,
} from '../../utils/check_permission';
import {
  APP_PROFILES,
  USUARIO_TIPO_REBOQUEME,
  USUARIO_TIPO_EMPRESA,
  CODIGO_KAVAK,
} from '../../reducers/constants';
import {DAF_DEALERSHIP} from '../../config';
import logoSoon from '../../assets/img/soon-logo-minimal-purple.png';
import {doPut} from '../../api/base/base_api_helper';
import {toastSuccess, toastError} from '../../actions/toast_actions';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {type: 'this', creditsToBuy: 0};
    this.requestApproval = this.requestApproval.bind(this);
    this.setStatusDocument = this.setStatusDocument.bind(this);
    this.approveDocument = this.approveDocument.bind(this);
    this.renderDefaultDashboard = this.renderDefaultDashboard.bind(this);
  }

  componentDidMount() {
    const {
      getUserPerformance,
      getDashboardStats,
      getLocationCluster,
      getCompanyCredits,
    } = this.props;

    getDashboardStats(this.getStartDate('this'), this.getEndDate('this'));
    if (hasCompanyProfile()) {
      getCompanyCredits();
    }

    if (hasAdminAccess()) {
      getLocationCluster(
        moment().subtract(1, 'year').local().format('YYYY-MM-DD 00:00'),
        this.getEndDate(this),
      );
      getUserPerformance(this.getStartDate('this'), this.getEndDate('this'));
      this.props.getDocumentsToApproval();
    }
  }

  mapData() {
    const {userPerformance} = this.props;
    return userPerformance.map((u) => {
      return {
        link: u.usuario.linkFotoPerfil,
        name: u.usuario.nome,
        tenders: u.quantidadeOrcamentos,
        solicitations: u.quantidadeSolicitacoes,
      };
    });
  }
  getUserPerformance(startDate, endDate) {
    const {getUserPerformance} = this.props;
    getUserPerformance(startDate, endDate);
  }
  getStartDate(type) {
    if (type === 'this')
      return `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01 00:00`;
    else return `${new Date().getFullYear()}-${new Date().getMonth()}-01 00:00`;
  }
  getEndDate(type) {
    if (type === 'this')
      return `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()} 23:59`;
    else
      return `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date(
        2019,
        new Date().getMonth(),
        0,
      ).getDate()} 23:59`;
  }
  mapTimeLine() {
    const {timeLine} = this.props;

    const data = [];
    let resp = [];
    const date = new Date();
    timeLine.forEach((t, i) => {
      let groupedByMonth = timeLine.filter((item) => item.mes === t.mes);
      if (data.filter((r) => r[0].mes === groupedByMonth[0].mes).length <= 0)
        data.push(groupedByMonth);
    });
    data.forEach((d, i) => {
      if (d.length === 1) {
        if (d[0].ano === date.getFullYear()) {
          let placeHolder = {
            ano: date.getFullYear() - 1,
            mes: d[0].mes,
            solicitacoes: 0,
            tempoMedioChegada: 0,
            tempoMedioRespostadaRede: 0,
            ticketMedioServicos: 0,
            valorTotalServicos: 0,
          };
          d.push(placeHolder);
        } else {
          let placeHolder = {
            ano: date.getFullYear(),
            mes: d[0].mes,
            solicitacoes: 0,
            tempoMedioChegada: 0,
            tempoMedioRespostadaRede: 0,
            ticketMedioServicos: 0,
            valorTotalServicos: 0,
          };
          d.push(placeHolder);
        }
      }
    });
    resp = data.map((d, t) => {
      let current = null,
        last = null;
      if (d[0].ano > d[1].ano) {
        current = d[0];
        last = d[1];
      } else {
        current = d[1];
        last = d[0];
      }
      return {
        month: d[0].mes,
        current: current.solicitacoes,
        last: last.solicitacoes,
      };
    });

    return resp;
  }

  getIndicator(value, anchor) {
    const result = value / anchor;
    return parseFloat((1 - result) * 100).toFixed(0);
  }

  mapCluster() {
    const {cluster} = this.props;
    return cluster.map((c) => ({
      lat: c.latitude,
      lng: c.longitude,
    }));
  }

  requestApproval(codeDocument, body) {
    doPut(`documents/${codeDocument}/acceptDocument`, body)
      .then((res) => {
        toastSuccess(res.mensagemCliente);
      })
      .catch((err) => toastError(err.mensagemCliente));
  }

  requestApprovalapprove(codeDocument, body) {
    doPut(`documents/${codeDocument}/acceptDocument`, body)
      .then((res) => {
        toastSuccess(res.mensagemCliente);
        this.props.removeAssetsDocuments(codeDocument);
        if (this.props.documentsAssets.length === 0) {
          this.props.getDocumentsToApproval();
          doCloseModal(DOCUMENTS_AVALIATION);
        }
      })
      .catch((err) => toastError(err.mensagemCliente));
  }

  approveDocument(codeDocument) {
    this.requestApprovalapprove(codeDocument, {approved: true});
  }

  getDafDealershipDashboard() {
    if (!this.props.login.user_info.base) return;
    const dealership = DAF_DEALERSHIP[this.props.login.user_info.base.codigo];
    if (!dealership) return;

    const METABASE_SITE_URL = 'https://soon.metabaseapp.com';
    const METABASE_SECRET_KEY =
      '495eedfce43b6eee2c6f1993e707a2379f562614dccf0af5bbfd770d478f4d37';
    const DASHBOARD_ID = 84;

    const payload = {
      resource: {dashboard: DASHBOARD_ID},
      params: {
        'concession%C3%A1ria': dealership,
      },
      exp: (Math.round(Date.now() / 1000) + 10 * 60) * 6, // 60 minutes expiration
    };
    const token = jwt.sign(payload, METABASE_SECRET_KEY);

    const iframeUrl = METABASE_SITE_URL + '/embed/dashboard/' + token;
    return iframeUrl;
  }

  getStatsParking() {
    return this.props.parkingVehicles.reduce(
      (total, acum) => {
        if (acum.status.codigo === A_CAMINHO) {
          return {...total, incoming: total.incoming + 1};
        } else if (acum.status.codigo === NO_PATIO) {
          return {...total, patio: total.patio + 1};
        } else if (acum.status.codigo === SAINDO_PATIO) {
          return {...total, leaving: total.leaving + 1};
        }
        return total;
      },
      {
        incoming: 0,
        patio: 0,
        leaving: 0,
      },
    );
  }

  async setStatusDocument(codeDocument, accept) {
    if (accept) {
      MySwal.fire({
        title: 'Você tem certeza?',
        text: 'Deseja aprovar o documento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#25f4af',
        confirmButtonText: 'Aprovar',
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
        focusCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.requestApproval(codeDocument, {approved: true});
          this.props.removeAssetsDocuments(codeDocument);

          if (this.props.documentsAssets.length === 0) {
            this.props.getDocumentsToApproval();
            doCloseModal(DOCUMENTS_AVALIATION);
          }
        }
      });
    } else {
      MySwal.fire({
        title: 'Você tem certeza?',
        text: 'Deseja rejeitar o documento',
        input: 'textarea',
        inputPlaceholder: 'Descreva o motivo da rejeição',
        icon: 'warning',
        width: '80',
        showCancelButton: true,
        confirmButtonText: 'Rejeitar',
        confirmButtonColor: '#25f4af',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        preConfirm: (item) => {
          if (!item) {
            MySwal.showValidationMessage(`Por favor preencha o motivo da rejeição.`);
          }
        },
        inputAttributes: {
          style: 'font-size: 14px;',
        },
      }).then((result) => {
        if (result.value) {
          const body = {approved: false, motivoRecusa: result.value};
          this.requestApproval(codeDocument, body);
          this.props.removeAssetsDocuments(codeDocument);

          if (this.props.documentsAssets.length === 0) {
            this.props.getDocumentsToApproval();
            doCloseModal(DOCUMENTS_AVALIATION);
          }
        }
      });
    }
  }

  mapCities() {
    const {cluster} = this.props;
    const resp = [];
    cluster.forEach((c, i) => {
      let respSearch = resp.filter((r) => r.city === c.cidade);
      if (respSearch.length <= 0) {
        let city = c.cidade;
        let amount = cluster.filter((cl) => cl.cidade === city).length;
        let percentage = ((amount / cluster.length) * 100).toFixed(2);
        resp.push({city, amount, percentage});
      }
    });

    return resp.sort((a, b) => b.amount - a.amount).filter((r, i) => i < 10);
  }

  buyCredits() {
    const {creditsToBuy} = this.state;
    const {buyCredits, buyCreditsFetching} = this.props;
    return (
      <div style={{'paddingBottom:': '20px'}}>
        <div className="form-group custom-tab col-md-12">
          <label>Créditos</label>
          <input
            type="number"
            value={creditsToBuy}
            onChange={(e) => this.setState({creditsToBuy: e.target.value})}
            className="form-control"
          />
        </div>
        <div className="form-group custom-tab col-md-12">
          <label>Forma de Pagamento</label>
          <div className="col-md-12" style={{marginLeft: '-15px', marginTop: '10px'}}>
            {' '}
            <OptionChooser
              context="service"
              keyField="tipoServico"
              optionKey="REBOQUE"
              text="Boleto Bancário"
              icon="fas fa-file-invoice-dollar"
            />
          </div>
        </div>
        <div className="col-md-4" style={{float: 'right'}}>
          {buyCreditsFetching ? (
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1" />
              <div className="sk-double-bounce2" />
            </div>
          ) : (
            <button
              className="btn btn-block btn-warning"
              onClick={() => buyCredits(creditsToBuy, 'BOLETO')}>
              Comprar
            </button>
          )}
        </div>
      </div>
    );
  }
  showPerformance() {
    if (hasReboqueMeProfile()) {
      return hasProfiles([APP_PROFILES.Administrador]);
    } else {
      return true;
    }
  }

  showCharts() {
    if (hasReboqueMeProfile()) {
      return hasProfiles([
        APP_PROFILES.Administrador,
        APP_PROFILES.FinanceiroSoon,
        APP_PROFILES.ManagerAcionamento,
        APP_PROFILES.ManagerAtendimento,
        APP_PROFILES.ManagerMonitoramento,
        APP_PROFILES.SupervisorRede,
      ]);
    }

    return true;
  }

  showVehiclesPerCompany() {
    if (!this.props.parkingVehicles || this.props.parkingVehicles.length === 0) {
      return;
    }

    const {parkingVehicles} = this.props;
    let groupedVehicles = [];

    parkingVehicles.forEach((vehicle) => {
      if (
        !vehicle.solicitacaoEntrada ||
        !vehicle.solicitacaoEntrada.empresa ||
        !vehicle.solicitacaoEntrada.empresa.nomeFantasia
      )
        return;

      const {
        solicitacaoEntrada: {
          empresa: {nomeFantasia},
        },
      } = vehicle;

      const respSearch = groupedVehicles.filter((r) => r.nomeFantasia === nomeFantasia);
      if (respSearch.length <= 0) {
        const amount = parkingVehicles.filter(
          (v) =>
            v.solicitacaoEntrada &&
            v.solicitacaoEntrada.empresa &&
            v.solicitacaoEntrada.empresa.nomeFantasia === nomeFantasia,
        ).length;
        groupedVehicles.push({nomeFantasia, amount});
      }
    });

    if (groupedVehicles.length === 0) return;

    return (
      <div className="tagContainer">
        {groupedVehicles.map((vehicle) => {
          return (
            <div className="companyTagContainer">
              <span>
                <strong>{vehicle.nomeFantasia} :</strong> {vehicle.amount}{' '}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  renderDefaultDashboard() {
    const {type} = this.state;
    const {
      timeStats,
      totalCustomers,
      totalPartners,
      clusterFetching,
      companyCredits,
      documents,
      documentsFetching,
      totalItems,
    } = this.props;

    const privilegiosPerformance =
      this.props.login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
      this.props.login.user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA;

    const {patio, incoming, leaving} = this.getStatsParking();
    return (
      <span>
        <CustomModal
          title="Comprar Créditos"
          context={'CONTEXT_CREDITS'}
          customClass={'credits-modal'}
          hideClass>
          {this.buyCredits()}
        </CustomModal>
        <div className="row">
          <InvoiceCard
            indicator={this.getIndicator(timeStats.tmc, 30)}
            icon={'fa fa-trophy'}
            value={parseFloat(timeStats.tmc).toLocaleString(undefined, {
              maximumFractionDigits: 1,
            })}
            title={'T.M.C'}
            description={'Tempo médio em minutos para chegar ao cliente.'}
          />

          <InvoiceCard
            value={parseFloat(timeStats.tma).toLocaleString(undefined, {
              maximumFractionDigits: 1,
            })}
            title={'T.M.A'}
            icon={'fa fa-bell'}
            cClass={'yellow-bg'}
            description={
              'Tempo médio em minutos para aceitar uma solicitação enviada no aplicativo.'
            }
          />

          <InvoiceCard
            icon={'fa fa-users'}
            value={totalCustomers.toLocaleString()}
            title={'Clientes'}
            cClass={'navy-green'}
          />
          {hasReboqueMeProfile() || hasBaseProfile() ? (
            <InvoiceCard
              icon={'fas fa-truck-moving'}
              value={totalPartners.toLocaleString()}
              title={'Parceiros'}
              cClass={'navy-green'}
              description={
                'Número de logins cadastrados que conseguem utilizar o aplicativo para realizar serviços.'
              }
            />
          ) : (
            <InvoiceCard
              icon={'fas fa-coins'}
              value={`${companyCredits.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`}
              onClick={() => doOpenModal('CONTEXT_CREDITS')}
              title={'Comprar créditos'}
              cClass={'navy-green'}
              prefix={'R$'}
            />
          )}
        </div>

        {this.showCharts() ? (
          <div>
            <div className="ibox">
              <div className="ibox-title">
                <strong>Atendimentos</strong>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div>
                    <TimeLinePerformance data={this.mapTimeLine()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {hasAdminAccess() ? (
          <div>
            <div className="ibox">
              <div className="ibox-title">
                <strong>Análise de localização dos últimos 30 dias</strong>
              </div>
              <div className="ibox-content">
                <div className="row">
                  {clusterFetching ? (
                    <ClusterLoader />
                  ) : (
                    <div>
                      <div className="col-md-4">
                        <BasicList data={this.mapCities()} />
                      </div>
                      <div className="col-md-8">
                        <ClusterMap
                          id="regionsMap"
                          style={{height: '400px'}}
                          data={this.mapCluster()}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {hasAdminAccess() && (
          <div>
            <div className="ibox-title">
              <strong>Performance por operador</strong>
              <div className="float-right">
                <div className="btn-group">
                  <TitleButton
                    label={'Mês Passado'}
                    type={'last'}
                    active={type === 'last'}
                    onClick={(type) => {
                      this.setState({type: 'last'});
                      this.getUserPerformance(this.getStartDate(type), this.getEndDate(type));
                    }}
                  />
                  <TitleButton
                    label={'Mês Atual'}
                    type={'this'}
                    active={type === 'this'}
                    onClick={(type) => {
                      this.setState({type: 'this'});
                      this.getUserPerformance(this.getStartDate(type), this.getEndDate(type));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="ibox-content">
              <div className="row">
                <div>
                  <UserPerformance data={this.mapData()} />
                </div>
              </div>
            </div>
          </div>
        )}

        {hasAdminAccess() && (
          <div className="ibox" style={{marginTop: '20px'}}>
            <div className="ibox-title">
              <strong>Documentos para aprovação</strong>
              <div className="float-right">
                <TitleButton
                  label="Atualizar"
                  onClick={() => this.props.getDocumentsToApproval()}
                  className="btn btn-sm btn-default"
                />
              </div>
            </div>
            <div className="ibox-content">
              <DocumentsTable
                isFetching={documentsFetching}
                documents={documents}
                totalItem={this.props.totalItems}
                loadAssets={this.props.getDocumentAssetsFromSolicitation}
                handleDecision={this.setStatusDocument}
                approveDocument={this.approveDocument}
              />
            </div>
          </div>
        )}
      </span>
    );
  }

  render() {
    if (isFromCompany(CODIGO_KAVAK)) {
      return (
        <div className="wrapper wrapper-content">
          <iframe
            src="https://soon.metabaseapp.com/public/dashboard/818d033f-6187-48e4-9bd9-6c9595d33d57"
            frameBorder="0"
            width="100%"
            height="2142px"
            allowtransparency>
            carregando...
          </iframe>
        </div>
      );
    }

    if (hasDafTvProfile()) {
      return (
        <div className="wrapper wrapper-content">
          <Tabs id="daf-dashboard-tabs" activeKey={this.state.key} onSelect={this.handleTabs}>
            <Tab eventKey={1} title="Chamados">
              <iframe
                src="https://soon.metabaseapp.com/public/dashboard/c2534c6a-7778-4017-a3a0-4b7a89d27041"
                frameBorder="0"
                width="100%"
                height="2142px"
                allowtransparency>
                carregando...
              </iframe>
            </Tab>
            <Tab eventKey={2} title="Guincho">
              <iframe
                src="https://soon.metabaseapp.com/public/dashboard/fae21573-5d6d-47d1-88c4-ffbca36966ef"
                frameBorder="0"
                width="100%"
                height="2142px"
                allowtransparency>
                carregando...
              </iframe>
            </Tab>
          </Tabs>
          <img src={logoSoon} className="logo-img-soon"></img>
        </div>
      );
    }

    if (hasDafProfile()) {
      return (
        <div className="wrapper wrapper-content">
          <Tabs id="daf-dashboard-tabs" activeKey={this.state.key} onSelect={this.handleTabs}>
            <Tab eventKey={1} title="DAF SAC">
              <iframe
                src="https://soon.metabaseapp.com/public/dashboard/5b8087b4-748b-460c-baa0-d514bba98fb8"
                frameBorder="0"
                width="100%"
                height="2142px"
                allowtransparency>
                carregando...
              </iframe>
            </Tab>
            <Tab eventKey={2} title="DAF Funil">
              <iframe
                src="https://soon.metabaseapp.com/public/dashboard/425e724c-8eff-4ae7-b2d9-ec3375df273c"
                frameBorder="0"
                width="100%"
                height="2142px"
                allowtransparency>
                carregando...
              </iframe>
            </Tab>
            <Tab eventKey={3} title="DAF Guinchos">
              <iframe
                src="https://soon.metabaseapp.com/public/dashboard/fae21573-5d6d-47d1-88c4-ffbca36966ef"
                frameBorder="0"
                width="100%"
                height="2142px"
                allowtransparency>
                carregando...
              </iframe>
            </Tab>
            <Tab eventKey={4} title="Analistas">
              <iframe
                src="https://soon.metabaseapp.com/public/dashboard/9163430c-7d44-4009-97f9-cb0980828012"
                frameBorder="0"
                width="100%"
                height="2142px"
                allowtransparency>
                carregando...
              </iframe>
            </Tab>
          </Tabs>
        </div>
      );
    }

    if (hasBaseProfile()) {
      return (
        <Tabs id="daf-dashboard-tabs" activeKey={this.state.key} onSelect={this.handleTabs}>
          <Tab eventKey={1} title="Dashboard">
            {this.renderDefaultDashboard()}
          </Tab>
          <Tab eventKey={2} title="Últimas Perdidas">
            <h5 style={{padding: '5px'}}>Solicitações perdidas (Últimos 7 dias)</h5>
            <LastLost baseCode={this.props.login.user_info.base.codigo} />
          </Tab>
          {this.getDafDealershipDashboard() && (
            <Tab eventKey={3} title="DAF Assistance">
              <iframe
                src={this.getDafDealershipDashboard()}
                frameBorder="0"
                width="100%"
                height="2142px"
                allowtransparency>
                carregando...
              </iframe>
            </Tab>
          )}
        </Tabs>
      );
    }

    return <div className="wrapper wrapper-content">{this.renderDefaultDashboard()}</div>;
  }
}

const mapStateToProps = (state) => ({
  userPerformance: state.dashboard.userPerformance,
  userPerformanceFetching: state.dashboard.userPerformanceFetching,
  timeStats: state.dashboard.timeStats,
  totalCustomers: state.dashboard.totalCustomers,
  totalPartners: state.dashboard.totalPartners,
  timeLine: state.dashboard.timeLine,
  cluster: state.dashboard.cluster,
  clusterFetching: state.dashboard.clusterFetching,
  profiles: state.login.profiles,
  companyCredits: state.dashboard.companyCredits,
  buyCreditsFetching: state.dashboard.buyCreditsFetching,
  login: state.login,
  documents: state.dashboard.documents,
  totalItems: state.dashboard.totalItems,
  documentsAssets: state.dashboard.documentsAssets,
  documentsFetching: state.dashboard.documentsFetching,
  parkingVehicles: state.dashboard.parkingVehicles,
});

getDashboardStats;
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserPerformance,
      getDashboardStats,
      getLocationCluster,
      getCompanyCredits,
      buyCredits,
      getDocumentsToApproval,
      removeAssetsDocuments,
      getDocumentAssetsFromSolicitation,
      getParkingVehicles,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
