import React, {Component, PureComponent} from 'react';

import swal from 'sweetalert2';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Tabs, Tab} from 'react-bootstrap';

import FileUploader from '../fileUploader/FileUploader';
import {onModalOpen, unbindEvents, onModalClose} from '../CustomModal';
import {hasProfiles} from '../../../utils/check_permission';
import {toastError} from '../../../actions/toast_actions';
import {
  postImage,
  getSolicitationDocuments,
  deleteSolicitationDocuments,
} from '../../../actions/checkListUploadActions';

class CheckListUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      frenteCont: 1,
      traseiraCont: 1,
      ladoEsquerdoCont: 1,
      ladoDireitoCont: 1,
      checkListCont: 1,
      diversosCont: 1,
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.getImages = this.getImages.bind(this);
    this.getDocumentListByType = this.getDocumentListByType.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
  }
  componentDidMount() {
    const {context, solicitationCode, getSolicitationDocuments} = this.props;
    getSolicitationDocuments(solicitationCode);
  }

  handleFile(files, type) {
    const {postImage, solicitationCode} = this.props;
    postImage(solicitationCode, files, type);
  }

  getImages() {
    const {getSolicitationDocuments, solicitationCode} = this.props;
    getSolicitationDocuments();
  }

  handleTabs(key) {
    this.setState({
      key,
    });
  }
  getDocumentListByType(documentList, type) {
    return documentList.filter((d, i) => d.tipoDocumento.codigo === type);
  }

  deleteDocument(documentCode) {
    const {solicitationCode, deleteSolicitationDocuments} = this.props;
    swal
      .fire({
        title: 'Tem certeza que deseja deletar o documento?',
        showCancelButton: true,
        icon: 'warning',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Deletar',
        reverseButtons: true,
      })
      .then((value) => {
        if (value.dismiss === 'cancel') {
          return;
        }
        deleteSolicitationDocuments(solicitationCode, documentCode);
      });
  }

  render() {
    const {solicitationCode, documentList, documentListFetching} = this.props;
    return (
      <div>
        <Tabs
          className="checklistTab"
          id="service-tabs"
          activeKey={this.state.key}
          onSelect={this.handleTabs}>
          <Tab eventKey={1} title="Todos">
            <FileUploader
              key={`fileUploader-TODOS`}
              upload={() => toastError('Não é possível adicionar documentos na aba todos')}
              type={'FRENTEVEICULO'}
              getImages={this.getImages}
              documentList={documentList}
              documentListFetching={documentListFetching}
              deleteImage={(code) =>
                !hasProfiles([APP_PROFILES.Administrador, APP_PROFILES.FinanceiroSoon])
                  ? toastError('Não é possível excluir documentos na  aba todos')
                  : this.deleteDocument(code)
              }
            />
          </Tab>

          <Tab eventKey={2} title="Frente">
            <FileUploader
              key={`fileUploader-FRENTEVEICULO`}
              upload={this.handleFile}
              type={'FRENTEVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'FRENTEVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={3} title="Traseira">
            <FileUploader
              key={`fileUploader-TRASEIRAVEICULO`}
              upload={this.handleFile}
              type={'TRASEIRAVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'TRASEIRAVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>

          <Tab eventKey={4} title="Lateral Esquerda">
            <FileUploader
              key={`fileUploader-LATERALESQUERDAVEICULO`}
              upload={this.handleFile}
              type={'LATERALESQUERDAVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'LATERALESQUERDAVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={5} title="Lateral Direita">
            <FileUploader
              key={`fileUploader-LATERALDIREITAVEICULO`}
              upload={this.handleFile}
              type={'LATERALDIREITAVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'LATERALDIREITAVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={6} title="Checklist">
            <FileUploader
              key={`fileUploader-CHECKLISTVEICULO`}
              upload={this.handleFile}
              type={'CHECKLISTVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'CHECKLISTVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={7} title="Assinatura">
            <FileUploader
              key={`fileUploader-ASSINATURALAUDO`}
              upload={this.handleFile}
              type={'ASSINATURALAUDO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'ASSINATURALAUDO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={8} title="Diversos">
            <FileUploader
              key={`fileUploader-DOCUMENTOSDIVERSOSVEICULO`}
              upload={this.handleFile}
              type={'DOCUMENTOSDIVERSOSVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(
                documentList,
                'DOCUMENTOSDIVERSOSVEICULO',
              )}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={9} title="Avarias">
            <FileUploader
              key={`fileUploader-AVARIASVEICULO`}
              upload={this.handleFile}
              type={'AVARIASVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'AVARIASVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={10} title="Patins e Acessórios">
            <FileUploader
              key={`fileUploader-ACESSORIOSUTILIZADOSVEICULO`}
              upload={this.handleFile}
              type={'ACESSORIOSUTILIZADOSVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(
                documentList,
                'ACESSORIOSUTILIZADOSVEICULO',
              )}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={11} title="Pedágios">
            <FileUploader
              key={`fileUploader-PEDAGIOCORRIDA`}
              upload={this.handleFile}
              type={'PEDAGIOCORRIDA'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'PEDAGIOCORRIDA')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={12} title="Bateria">
            <FileUploader
              key={`fileUploader-BATERIAVEICULO`}
              upload={this.handleFile}
              type={'BATERIAVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'BATERIAVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={13} title="Interior">
            <FileUploader
              key={`fileUploader-PAINELVEICULO`}
              upload={this.handleFile}
              type={'PAINELVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'PAINELVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={14} title="Estepe/Macaco">
            <FileUploader
              key={`fileUploader-STEPVEICULO`}
              upload={this.handleFile}
              type={'STEPVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'STEPVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
          <Tab eventKey={15} title="Pneus">
            <FileUploader
              key={`fileUploader-PNEUVEICULO`}
              upload={this.handleFile}
              type={'PNEUVEICULO'}
              getImages={this.getImages}
              documentList={this.getDocumentListByType(documentList, 'PNEUVEICULO')}
              documentListFetching={documentListFetching}
              deleteImage={this.deleteDocument}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  imageUploaded: state.checkList.imageUploaded,
  documentList: state.checkList.documentList,
  documentListFetching: state.checkList.documentListFetching,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {postImage, getSolicitationDocuments, deleteSolicitationDocuments},
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckListUpload);
