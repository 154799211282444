import React from 'react';

import {cancelAttendenceSolicitation} from '../../../api/solicitation_helper';

class CancelAttendenceSolicitation extends React.Component {
  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.state = {cancel: props.defaultValue ? 'Sim' : 'Não', isLoading: false};
  }

  async handleOk() {
    this.setState({isLoading: true});
    try {
      const deductible = this.state.cancel === 'Sim' ? true : false;
      await cancelAttendenceSolicitation({solicitationId: this.props.row.codigo});
      this.props.onUpdate(deductible);
    } finally {
      this.setState({isLoading: false});
    }
  }

  render() {
    return (
      <div className="flex flex-col items-center">
        <p>Confirmar cancelamento?</p>
        <div className="flex gap-1">
          <button
            className="btn btn-primary"
            onClick={() => this.handleOk()}
            disabled={this.state.isLoading}>
            confirmar
          </button>
        </div>
      </div>
    );
  }
}

export const createCancelAttendenceSolicitation = (onUpdate, props) => (
  <CancelAttendenceSolicitation onUpdate={onUpdate} {...props} />
);
