import React, {Component} from 'react';
import Card from '../common/Card';
import FollowUpItemButtons from './FollowUpItemButtons';
import {AGUARDANDOPROPOSTA} from '../../config.js';
import ReactTooltip from 'react-tooltip';
import PaymentMethod from '../common/paymentMethod';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
  CODIGO_MAPFRE,
  CODIGO_LOCALIZA,
  CODIGO_DAF,
  CODIGO_KOVI,
  CODIGO_JUSTOS,
  CODIGO_LOOVI,
} from '../../reducers/constants/index';
import ClocklIndicator from '../common/ClockIndicator';
import moment from 'moment';
import {APP_PROFILES} from '../../reducers/constants';
import CopyToClipboard from '../common/copyToClipboard/CopyToClipboard';
import DialAgent from '../common/dialAgent/DialAgent';
import {
  hasBaseProfile,
  hasManagerAcess,
  hasProfiles,
  hasReboqueMeProfile,
} from '../../utils/check_permission';
import Swal from 'sweetalert2';

const iconEnabled = {
  background: '#25f4af',
  color: '#fff',
};
const iconDisabled = {
  background: '#5c40ff',
  color: '#fff',
};

export default class FollowUpItemDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {response: undefined, extraTime: ''};

    this.sendWebapp = this.sendWebapp.bind(this);
    this.sendWebapp = this.sendWebapp.bind(this);
  }

  sendSMSWebapp() {
    Swal.fire({
      title: 'Confirme o Telefone do prestador:',
      input: 'text',
      inputPlaceholder: '11999888111',
      width: '80',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#25f4af',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      inputValue: this.props.solicitation.respostaAceita.parceiro.telefoneCelular,
      inputAttributes: {
        style: 'font-size: 14px;',
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Insira um telefone válido!';
        }
      },
    }).then((result) => {
      if (result && result.value) {
        this.props.sendPartnerWebappSMS(result.value);
      }
    });
  }

  sendEmailWebapp() {
    Swal.fire({
      title: 'Informe o Email do prestador:',
      input: 'email',
      inputPlaceholder: 'email@gmail.com',
      width: '80',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#25f4af',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      inputValue: this.props.solicitation.respostaAceita.parceiro.email,
      inputAttributes: {
        style: 'font-size: 14px;',
      },
      inputValidator: (value) => {
        if (!value || (value && !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))) {
          return 'Insira um email válido!';
        }
      },
    }).then((result) => {
      if (result && result.value) {
        this.props.sendPartnerWebappEmail(result.value);
      }
    });
  }

  sendWebapp() {
    Swal.fire({
      title: `Como deseja enviar o Web App ?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#27dfa2',
      confirmButtonText: 'Continuar',
      icon: 'question',
      width: '32em',
      reverseButtons: true,
      input: 'radio',
      inputOptions: {
        email: 'Email',
        sms: 'SMS',
        chat: 'Chat',
        copiar: 'Copiar',
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Escolha uma das opções acima!';
        }
      },
    }).then((value) => {
      switch (value.value) {
        case 'email':
          this.sendEmailWebapp();
          break;

        case 'sms':
          this.sendSMSWebapp();
          break;

        case 'chat':
          this.props.sendPartnerWebappChat();
          break;

        case 'copiar':
          this.props.copyWebAppLinkToClipboard();
          break;
      }
    });
  }

  // Finalizar os endpoints
  // Adiciona-los as funcoes acima no switch

  getParceiro = () => {
    const {solicitation} = this.props;
    const parceiroAcionado = this.findParceiroAcionado(solicitation);

    function calcularDiferencaEmMinutos(data1, data2) {
      const dataInicial = moment(data1, 'DD/MM/YYYY HH:mm:ss');
      const dataFinal = moment(data2, 'DD/MM/YYYY HH:mm:ss');
      // Verifica se as datas são iguais
      if (dataFinal.isSame(dataInicial)) {
        return 0;
      }
      // Calcula a diferença em minutos
      const diffMinutos = dataFinal.diff(dataInicial, 'minutes');
      return diffMinutos;
    }
    const agendadoDiff =
      solicitation &&
      (solicitation.dataCadastroAgendamento || solicitation.dataAgendada) &&
      solicitation.respostaAceita &&
      solicitation.dataCadastro
        ? calcularDiferencaEmMinutos(
            solicitation.dataAgendada,
            solicitation.respostaAceita.dataCadastro,
          )
        : null;
    const aceitoAbertoDiff =
      solicitation.respostaAceita && solicitation.dataCadastro
        ? calcularDiferencaEmMinutos(
            solicitation.dataCadastro,
            solicitation.respostaAceita.dataCadastro,
          )
        : '-';

    const chegadaAceitoDiff =
      solicitation.dataChegadadoParceiro && solicitation.respostaAceita
        ? calcularDiferencaEmMinutos(
            solicitation.respostaAceita.dataCadastro,
            solicitation.dataChegadadoParceiro,
          )
        : '-';
    const conclusaoChegadaDiff =
      solicitation.dataConcluida && solicitation.dataChegadadoParceiro
        ? calcularDiferencaEmMinutos(
            solicitation.dataChegadadoParceiro,
            solicitation.dataConcluida,
          )
        : '-';
    let parceiro = <span />;
    let response = this.props.response || this.props.solicitation.respostaAceita;
    let valorCorrida = this.props.solicitation.valorCorrida || response.valorCorrida;
    if (
      response &&
      response.parceiro &&
      this.props.solicitation.statusSolicitacao != AGUARDANDOPROPOSTA
    ) {
      parceiro = (
        <div>
          <div style={{width: '100%', clear: 'both'}}>
            <div>
              <div
                className="text-center"
                style={{float: 'left', minWidth: '60px', marginTop: '5px'}}>
                <i className="fas fa-truck-loading fa-2x" />
              </div>
              <address style={{position: 'relative'}} title={response.parceiro.contato}>
                {hasReboqueMeProfile() &&
                  !hasManagerAcess() &&
                  !solicitation.usuarioResponsavelMonitoramento && (
                    <div
                      style={{
                        backgroundColor: '#ffffff00',
                        width: '100%',
                        height: '100%',
                        backdropFilter: 'blur(4px)',
                        position: 'absolute',
                        right: 0,
                        display: 'grid',
                        placeItems: 'center',
                        fontWeight: 600,
                        fontSize: '18px',
                      }}>
                      Se vincule como responsável para visualizar!
                    </div>
                  )}
                <span style={{maxWidth: '50px'}}>
                  <strong>
                    {response.parceiro.nomeFantasia.length +
                      response.parceiro.contato.length <=
                    31
                      ? `${response.parceiro.nomeFantasia} / ${response.parceiro.contato}`
                      : response.parceiro.contato + '...'}
                  </strong>
                </span>
                <br />
                {solicitation.respostaAceita &&
                  solicitation.respostaAceita.parceiro &&
                  solicitation.respostaAceita.parceiro.veiculo && (
                    <span style={{maxWidth: '50px'}}>
                      Placa: {solicitation.respostaAceita.parceiro.veiculo.placa}
                    </span>
                  )}
                <br />
                {hasReboqueMeProfile() && (
                  <span>
                    <div style={{marginLeft: '60px'}}>
                      Parceiro:
                      <CopyToClipboard
                        textToBeCopied={response.parceiro.telefoneCelular}
                        title={response.parceiro.telefoneCelular}
                        style={{marginLeft: 5}}
                      />
                      <DialAgent
                        style={{marginLeft: 5}}
                        phoneNumber={response.parceiro.telefoneCelular}
                      />
                    </div>

                    {response.parceiro.baseTelefoneCelular ? (
                      <div style={{marginLeft: '60px'}}>
                        Base Celular:
                        <CopyToClipboard
                          textToBeCopied={response.parceiro.baseTelefoneCelular}
                          title={response.parceiro.baseTelefoneCelular}
                          style={{marginLeft: 5}}
                        />
                        <DialAgent
                          style={{marginLeft: 5}}
                          phoneNumber={response.parceiro.baseTelefoneCelular}
                        />
                      </div>
                    ) : null}

                    {response.parceiro.baseTelefoneFixo ? (
                      <div style={{marginLeft: '60px'}}>
                        Base Fixo:
                        <CopyToClipboard
                          textToBeCopied={response.parceiro.baseTelefoneFixo}
                          title={response.parceiro.baseTelefoneFixo}
                          style={{marginLeft: 5}}
                        />
                        <DialAgent
                          style={{marginLeft: 5}}
                          phoneNumber={response.parceiro.baseTelefoneFixo}
                        />
                      </div>
                    ) : null}
                    {hasManagerAcess() ? (
                      <div style={{marginLeft: '60px'}} onClick={() => this.sendWebapp()}>
                        <a>Enviar webapp para o prestador</a>
                      </div>
                    ) : null}
                  </span>
                )}
                {response.parceiro.linkCnh ? (
                  <div style={{marginLeft: '60px'}}>
                    <a href={response.parceiro.linkCnh} target="_blank">
                      Ver CNH
                    </a>
                  </div>
                ) : null}
                {hasReboqueMeProfile() &&
                  parceiroAcionado &&
                  parceiroAcionado.usuario &&
                  parceiroAcionado.usuario.nome && (
                    <div style={{marginLeft: '60px'}}>
                      Acionado por {parceiroAcionado.usuario.nome}
                    </div>
                  )}
              </address>
            </div>
          </div>
          <div>
            <VerticalTimeline layout="1-column">
              {agendadoDiff ? (
                <span>
                  <ClocklIndicator top={'95px'} text={agendadoDiff} />
                  <ClocklIndicator top={'170px'} text={chegadaAceitoDiff} />
                  <ClocklIndicator top={'235px'} text={conclusaoChegadaDiff} />
                </span>
              ) : (
                <span>
                  <ClocklIndicator top={'30px'} text={aceitoAbertoDiff} />
                  <ClocklIndicator top={'90px'} text={chegadaAceitoDiff} />
                  <ClocklIndicator top={'163px'} text={conclusaoChegadaDiff} />{' '}
                </span>
              )}

              {solicitation.dataCadastroAgendamento && (
                <VerticalTimelineElement
                  className="vertical-timeline-element--work time-line-enabled"
                  iconStyle={iconEnabled}>
                  <span>
                    <strong>Aberto em</strong>
                  </span>
                  <br />
                  <span>{`${solicitation.dataCadastroAgendamento}`}</span>
                </VerticalTimelineElement>
              )}

              <VerticalTimelineElement
                className="vertical-timeline-element--work time-line-enabled"
                iconStyle={iconEnabled}>
                <span>
                  <strong>Aberto em</strong>
                </span>
                <br />
                <span>{`${solicitation.dataCadastro}`}</span>
              </VerticalTimelineElement>

              {solicitation.dataAgendada && (
                <VerticalTimelineElement
                  className="vertical-timeline-element--work time-line-enabled"
                  iconStyle={iconEnabled}>
                  <span>
                    <strong>Agendado para</strong>
                  </span>
                  <br />
                  <span>{`${solicitation.dataAgendada}`}</span>
                </VerticalTimelineElement>
              )}
              <VerticalTimelineElement
                className="vertical-timeline-element--work time-line-enabled"
                iconStyle={iconEnabled}>
                <span>
                  <strong>Aceito em</strong>
                </span>
                <br />
                <span>{solicitation.respostaAceita.dataCadastro}</span>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={solicitation.dataChegadadoParceiro ? iconEnabled : iconDisabled}>
                <span>
                  <strong>Chegada do Parceiro</strong>
                </span>
                <br />
                <span>
                  {solicitation.dataChegadadoParceiro
                    ? solicitation.dataChegadadoParceiro
                    : `Prévia: ${solicitation.respostaAceita.tempoEspera} minutos`}
                  {!solicitation.dataChegadadoParceiro &&
                    hasReboqueMeProfile() &&
                    hasProfiles([
                      APP_PROFILES.Monitoramento,
                      APP_PROFILES.ManagerMonitoramento,
                    ]) && (
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        style={{
                          position: 'absolute',
                          right: '12px',
                          top: '8px',
                          backgroundColor: '#A3A3A3',
                          border: 'none',
                        }}
                        onClick={() => this.props.updatePrevia()}>
                        <i className="far fa-edit" />
                      </button>
                    )}
                </span>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={iconDisabled}>
                <span>
                  <strong>Conclusão</strong>
                </span>
                <br />
                <span>
                  {solicitation.dataChegadadoParceiro && solicitation.tempoClienteDestino
                    ? `Prévia: ${solicitation.tempoClienteDestino} minutos`
                    : '...'}
                </span>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </div>
      );
    }
    return parceiro;
  };

  onChangeExtraTime = (event) => {
    this.setState({
      extraTime: event.target.value,
    });
  };

  handlePressExtraTime = () => {
    this.props.extendSolicitationTime(this.state.extraTime);
    this.setState({extraTime: ''});
  };

  findParceiroAcionado = (solicitation) => {
    if (
      solicitation &&
      solicitation.respostaAceita &&
      solicitation.respostaAceita.acionamento
    ) {
      return solicitation.respostaAceita.acionamento.filter((item) => {
        return item.parceiro.codigo === solicitation.respostaAceita.parceiro.codigo;
      })[0];
    }
    return null;
  };

  formatParceiroPrice = (solicitation) => {
    if (!solicitation.valorParceiro) return '';
    const formatterMoney = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return `Parceiro: ${formatterMoney.format(solicitation.valorParceiro)}`;
  };

  formatReboqueMePrice = (solicitation) => {
    if (!solicitation.comissaoReboqueme) return '';
    const formatterMoney = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return `Reboque.me: ${formatterMoney.format(solicitation.comissaoReboqueme)}`;
  };

  render() {
    let parceiro = this.getParceiro();
    const {
      solicitation,
      onServiceCompleted,
      onInformArrival,
      onRestartSolicitation,
    } = this.props;

    const {empresa, valorCorrida} = solicitation;
    const {cliente} = solicitation;
    const formatterMoney = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    this.rideToolTip = `${this.formatReboqueMePrice(solicitation)}
    <br> ${this.formatParceiroPrice(solicitation)}`;
    const paymentInfo = solicitation.formaPagamento ? (
      <PaymentMethod
        method={solicitation.formaPagamento.codigo}
        style={{marginRight: '30px'}}
      />
    ) : null;

    function getCopyLink() {
      if (solicitation.empresa.codigo === CODIGO_MAPFRE) {
        if (solicitation.ownId && solicitation.ownId.toLowerCase().startsWith('m')) {
          return `https://mapfre.soon.com.vc/monitoring/${solicitation.codigo}`;
        }
        if (solicitation.ownId && solicitation.ownId.toLowerCase().startsWith('b')) {
          return `https://bbseguros.soon.com.vc/monitoring/${solicitation.codigo}`;
        }
        return `https://mapfre.soon.com.vc/monitoring/${solicitation.codigo}`;
      }
      if (solicitation.empresa.codigo === CODIGO_LOCALIZA) {
        return `https://localiza.soon.com.vc/monitoring/${solicitation.codigo}`;
      }
      if (solicitation.empresa.codigo === CODIGO_DAF) {
        return `https://daf.soon.com.vc/monitoring/${solicitation.codigo}`;
      }
      if (solicitation.empresa.codigo === CODIGO_KOVI) {
        return `http://kovi.soon.com.vc/monitoring/${solicitation.codigo}`;
      }
      if (solicitation.empresa.codigo === CODIGO_JUSTOS) {
        return `https://justos.soon.com.vc/monitoring/${solicitation.codigo}`;
      }
      if (solicitation.empresa.codigo === CODIGO_LOOVI) {
        return `https://loovi.soon.com.vc/monitoring/${solicitation.codigo}`;
      }
      return `https://web.reboque.me/monitoring/${solicitation.codigo}`;
    }
    return (
      <div className="full-height-scroll">
        <Card
          title={`Solicitação: ${solicitation.codigo} - Protocolo: ${
            solicitation.ownId || ''
          } `}
          contentClassName="no-padding"
          onClose={this.props.onClose}>
          <ul
            className="list-group"
            style={{height: this.props.height - 50 + 'px', overflow: 'auto'}}>
            <li className="list-group-item">
              <CopyToClipboard
                textToBeCopied={getCopyLink()}
                title="link de rastreamento"
                dataTip="Link copiado!"
                icon="far fa-copy"
                dataPlace="right"
                style={{marginLeft: 10}}
              />
            </li>
            <li className="list-group-item">
              <div style={{backgroundColor: 'white'}}>
                <div>
                  <div style={{marginBottom: '6px'}}>
                    <strong>Contato Solicitação:</strong>
                  </div>
                  <div style={{width: '100%', clear: 'both'}}>
                    <div>
                      <div
                        className="text-center"
                        style={{
                          float: 'left',
                          minWidth: '60px',
                          marginTop: '5px',
                        }}>
                        <i className="fa fa-user fa-2x" />
                      </div>
                      <address>
                        <span>
                          <strong>
                            {cliente.nome} {cliente.sobreNome}
                          </strong>
                        </span>
                        <br />
                        <DialAgent phoneNumber={cliente.telefoneCelular} />
                        <CopyToClipboard
                          textToBeCopied={cliente.telefoneCelular}
                          title={cliente.telefoneCelular}
                          style={{marginLeft: 5}}
                        />
                      </address>
                    </div>
                  </div>
                  {solicitation.atendimento ? (
                    <span>
                      <div style={{marginBottom: '6px'}}>
                        <strong>Contato Atendimento:</strong>
                      </div>
                      <div style={{width: '100%', clear: 'both'}}>
                        <div>
                          <div
                            className="text-center"
                            style={{
                              float: 'left',
                              minWidth: '60px',
                              marginTop: '5px',
                            }}>
                            <i className="fa fa-user fa-2x" />
                          </div>
                          <address>
                            <span>
                              <strong>{solicitation.atendimento.contato}</strong>
                            </span>
                            <br />
                            <DialAgent phoneNumber={solicitation.atendimento.telefone} />
                            <CopyToClipboard
                              textToBeCopied={solicitation.atendimento.telefone}
                              title={solicitation.atendimento.telefone}
                              style={{marginLeft: 5}}
                            />
                          </address>
                        </div>
                      </div>
                    </span>
                  ) : null}

                  {solicitation.telefoneMotorista ? (
                    <span>
                      <div style={{marginBottom: '6px'}}>
                        <strong>Contato Motorista:</strong>
                      </div>
                      <div style={{width: '100%', clear: 'both'}}>
                        <div>
                          <div
                            className="text-center"
                            style={{
                              float: 'left',
                              minWidth: '60px',
                              marginTop: '5px',
                            }}>
                            <i className="fa fa-user fa-2x" />
                          </div>
                          <address>
                            <span>
                              <strong>{solicitation.telefoneMotorista}</strong>
                            </span>
                            <br />
                            <DialAgent phoneNumber={solicitation.telefoneMotorista} />
                            <CopyToClipboard
                              textToBeCopied={solicitation.telefoneMotorista}
                              title={solicitation.telefoneMotorista}
                              style={{marginLeft: 5}}
                            />
                          </address>
                        </div>
                      </div>
                    </span>
                  ) : null}

                  <div style={{width: '100%', clear: 'both'}}>
                    <div>
                      <div
                        className="text-center"
                        style={{
                          float: 'left',
                          minWidth: '60px',
                          marginTop: '5px',
                        }}>
                        <i className="fa fa-car fa-2x" />
                      </div>
                      <address>
                        <span>
                          <strong>
                            {solicitation.veiculoCliente.marca}{' '}
                            {solicitation.veiculoCliente.modelo}
                          </strong>
                        </span>
                        <br />
                        <span>
                          {solicitation.veiculoCliente.placa}
                          {solicitation.veiculoCliente.cor
                            ? '/ ' + solicitation.veiculoCliente.cor
                            : null}
                          {solicitation.veiculoCliente.ano
                            ? `/ ${solicitation.veiculoCliente.ano}`
                            : null}
                        </span>
                      </address>
                    </div>
                  </div>

                  <div style={{width: '100%', clear: 'both'}}>
                    <div>
                      <div
                        className="text-center"
                        style={{
                          float: 'left',
                          minWidth: '60px',
                          marginTop: '5px',
                        }}>
                        <i className="fa fa-building fa-2x" />
                      </div>
                      <address>
                        <span>
                          <strong>{empresa ? empresa.nomeFantasia : null}</strong>
                        </span>
                        <br />
                        <span>{solicitation.usuario}</span>
                      </address>
                    </div>
                  </div>

                  <div className="col-md-12 col-md-5 col-sm-7 col-xs-12">
                    <div style={{width: '100%', clear: 'both'}}>
                      <div className="text-center" style={{lineHeight: '40px'}}>
                        <address>
                          {hasReboqueMeProfile() && <ReactTooltip multiline={true} />}
                          <h2 role="button" className="text-center">
                            {paymentInfo}
                            {!hasBaseProfile() ? (
                              <strong data-tip={this.rideToolTip}>
                                {formatterMoney.format(valorCorrida)}
                              </strong>
                            ) : solicitation.formaPagamento.codigo === 'DINHEIRO' ? (
                              <strong data-tip={this.rideToolTip}>
                                {formatterMoney.format(valorCorrida)}
                              </strong>
                            ) : (
                              <strong data-tip={this.rideToolTip}>
                                {formatterMoney.format(solicitation.valorParceiro)}
                              </strong>
                            )}
                            <i
                              className="fa fa-question-circle"
                              data-tip={this.rideToolTip}
                              style={{
                                marginLeft: '10px',
                                fontSize: '15px',
                                verticalAlign: 'top',
                              }}
                            />
                          </h2>
                          {solicitation.observacao ? (
                            <p className="text-center">{`${solicitation.observacao}`}</p>
                          ) : null}
                        </address>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{clear: 'both'}}>
                  <hr />
                  {parceiro}
                  {
                    <FollowUpItemButtons
                      solicitation={this.props.solicitation}
                      onSolve={this.props.onSolve}
                      onCancel={this.props.onCancel}
                      onReopen={this.props.onReopen}
                      onAccept={this.props.onAccept}
                      onResponsavelUpdate={this.props.onResponsavelUpdate}
                      onResponsavelRemove={this.props.onResponsavelRemove}
                      onServiceCompleted={onServiceCompleted}
                      onInformArrival={onInformArrival}
                      onSelectReason={this.props.onSelectReason}
                      onRestartSolicitation={onRestartSolicitation}
                      openModal={this.props.openModal}
                      profileId={this.props.profileId}
                    />
                  }
                </div>
              </div>
            </li>
          </ul>
        </Card>
      </div>
    );
  }
}
