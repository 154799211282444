import {INPUT_OPTION_SELECTED, INPUT_CLEARED, INPUT_VALUE_CHANGED, INPUT_OPTIONS_CHANGED, INPUT_INTERNAL_FIELD_CHANGED} from '../actions/autocomplete_actions'

const autocomplete_reducer = (state = {}, action) => {
  switch(action.type){

    case INPUT_OPTION_SELECTED:
      return {
        ...state,
        [action.context]: {
          ...state[action.context],
          selectedItem: action.value,
        }
      }
    case INPUT_CLEARED:
      return {
        ...state,
        [action.context]: {
            ...state[action.context],
            itemList: undefined,
            selectedItem: undefined,
            inputValue: "",
         }
      }
    case INPUT_VALUE_CHANGED:
      return {
        ...state,
        [action.context]: {
            ...state[action.context],
            selectedItem: undefined,
            inputValue: action.value,
          }
      }
    case INPUT_OPTIONS_CHANGED:
      return {
        ...state,
        [action.context]: {
            ...state[action.context],
            itemList: action.value,
          }
      }
    default:
      return state
  }
}

export default  autocomplete_reducer
