import {
  CUSTOMER_LIST_CHANGED,
  CUSTOMER_INSTANCE_CHANGED,
  FETCHING_CUSTOMER_CHANGED
} from "../actions/customer_actions";

const initialState = {
  isFetching: false
};

const customer_reducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_CHANGED:
      return {
        ...state,
        customerList: action.value
      };
    /*
    case CUSTOMER_INSTANCE_CHANGED:
      let newList = state.customerList.map( oldCustomer => {
        return oldCustomer.codigo === action.customerId? action.customerData : oldCustomer
      });

      return {
        ...state,
        customerList: newList
      }
*/
    case FETCHING_CUSTOMER_CHANGED:
      return {
        ...state,
        isFetching: action.value
      };
    default:
      return state;
  }
};

export default customer_reducer;
