import { findDOMNode } from "react-dom";
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

export default class GoogleMarker extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClick, onBlur, key, lat, lng, address, children } = this.props;
    return (
      <div onBlur={() => (onBlur ? onBlur(false) : null)}>
        {/* <ReactTooltip
                    globalEventOff={'click'}
                    id='marker' getContent={(dataTip) => <div><h3>This little buddy is {dataTip}</h3><p>Put mouse here</p>
                        <button>Copy </button><button onClick={() => {
                            ReactTooltip.hide(findDOMNode(this.refs.mapTip))
                        }}>Close </button>
                    </div>

                    }
                    border={true}
                    type={'light'}
                    effect={'solid'} /> */}

        <img
          className="maps-marker"
          src={this.props.icon}
          role="presentation"
          style={{
            transform: `translate(-50%, -50%) rotate(${
              this.props.bearing ? this.props.bearing : 0
            }deg) `,
            position: "absolute",
          }}
          role="button"
          onClick={() => (onClick ? onClick(key, lat, lng, address) : null)}
          onBlur={() => onBlur(false)}
        />
        {children}
      </div>
    );
  }
}
