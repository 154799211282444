import React, {Component} from 'react';

import {Link} from 'react-router';
import {connect} from 'react-redux';

import NumberBadge from './NumberBadge';
import {ModalLink} from './CustomModal';
import {
  hasActivationAccess,
  hasAnaliseDeRedeAccess,
  hasAnaliseDeRedeBaseAccess,
  hasCreateLeadAccess,
  hasAssistanceAccess,
  hasBaseProfile,
  hasBasesAccess,
  hasChatAccess,
  hasCustomerAccess,
  hasDocumentsAccess,
  hasFechamentoAndPendenciasAccess,
  hasInvoicesAccess,
  hasLocalsAccess,
  hasReboqueMeProfile,
  hasSolicitationAccess,
  hasSsPendingAccess,
  hasVehicleHistoryAccess,
} from '../../utils/check_permission';
import userIcon from '../../assets/img/user-icon.png';
import {doLogout} from '../../actions/login_actions';
import {store} from '../..';

class NavigationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userPhoto: userIcon,
    };
    this.secondLevelActive = this.secondLevelActive.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
  }
  componentDidUpdate() {
    if (store) {
      $(this.menu).metisMenu();
    }
  }
  componentDidMount() {
    this.setState({userPhoto: this.props.userPhoto});
  }

  activeRoute(routeName) {
    return this.props.location.pathname === routeName ? 'active' : '';
  }

  secondLevelActive(routeName) {
    return routeName.find((route) => route === this.props.location.pathname)
      ? 'nav nav-second-level collapse in'
      : 'nav nav-second-level collapse';
  }

  componentWillReceiveProps(nextProps) {
    this.setState({userPhoto: nextProps.userPhoto});
  }

  render() {
    const {userPhoto} = this.state;
    const {login} = this.props;

    if (!login) return null;

    return (
      <nav className="navbar-default navbar-static-side" role="navigation">
        <div className="sidebar-collapse">
          <a className="close-canvas-menu">
            <i className="fa fa-times" />
          </a>
          <ul className="nav metismenu" id="side-menu" ref={(ref) => (this.menu = ref)}>
            <li className="nav-header">
              <div className="dropdown profile-element " style={{textAlign: 'center'}}>
                <img
                  src={this.props.userPhoto ? this.props.userPhoto : userIcon}
                  className="rounded-circle"
                  width="80"
                />

                <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                  <span className="clear">
                    {' '}
                    <span className="block m-t-xs">
                      {' '}
                      <strong className="font-bold">{this.props.username}</strong>
                    </span>{' '}
                    <span className="text-muted text-xs block">
                      {hasBaseProfile()
                        ? login.user_info.base.nomeFantasia
                        : this.props.company}
                      <b className="caret" />
                    </span>{' '}
                  </span>{' '}
                </a>
                <ul className="dropdown-menu animated flipInX m-t-xs">
                  <li>
                    <ModalLink href="#" context="changePassword">
                      {' '}
                      Alterar Senha
                    </ModalLink>
                  </li>
                  <li>
                    <ModalLink href="#" context="ADD_PROFILE_IMAGE">
                      {' '}
                      Alterar Foto
                    </ModalLink>
                  </li>
                  <li>
                    <a href="#" onClick={this.props.onLogout}>
                      {' '}
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
              <div className="logo-element">
                <img src={userPhoto} className="logo-img-nav rounded-circle" />
              </div>
            </li>

            {
              <li className={this.activeRoute('/dashboard')}>
                <Link
                  to="/dashboard"
                  aria-expanded="false"
                  style={{display: 'flex', alignItems: 'center'}}>
                  <i style={{marginRight: '5px'}} className="fas fa-tachometer-alt" />
                  <span className="nav-label" style={{width: '100%'}}>
                    Dashboard
                  </span>
                  <i style={{marginRight: '5px'}} className="fas fa-chevron-down" />
                </Link>
                <ul
                  className="nav nav-second-level collapse"
                  aria-expanded="false"
                  style={{height: '0px'}}>
                  <li style={{marginLeft: '15px'}} className={this.activeRoute('/dashboard')}>
                    <Link to="/dashboard">
                      <span className="nav-label">Dashboard</span>
                    </Link>
                  </li>
                  {hasVehicleHistoryAccess() && (
                    <li
                      style={{marginLeft: '15px'}}
                      className={this.activeRoute('/v2/monitoring/history')}>
                      <a href={`${process.env.REACT_APP_URL}/v2/monitoring/history`}>
                        <span className="nav-label">Historico do Veículo</span>
                      </a>
                    </li>
                  )}
                  <li style={{marginLeft: '15px'}} className={this.activeRoute('/patio')}>
                    <Link to="/patio">
                      <span className="nav-label">Gestão de Pátio</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {hasAssistanceAccess() ? (
              <li className={this.activeRoute('/assistance')}>
                <a href={`${process.env.REACT_APP_URL}/v2/monitoring/attendances`}>
                  <i style={{marginRight: '5px'}} className="fas fa-headset" />
                  <span className="nav-label">Atendimento</span>
                </a>
              </li>
            ) : null}

            {hasSolicitationAccess() ? (
              <li className={this.activeRoute('/service')}>
                <Link to="/service">
                  <i style={{marginRight: '5px'}} className="fa fa-scroll" />
                  <span className="nav-label">Nova Solicitação</span>
                </Link>
              </li>
            ) : null}

            <li className={this.activeRoute('/followup')}>
              <Link to="/followup">
                <i style={{marginRight: '5px'}} className="fa fa-map" />
                <span className="nav-label">Acompanhamento</span>{' '}
                <NumberBadge number={this.props.pendingSolicitations} color="warning" />
              </Link>
            </li>

            {hasBasesAccess() ? (
              <li className={this.activeRoute('/bases')}>
                <Link to="/bases">
                  <i style={{marginRight: '5px'}} className="fas fa-warehouse" />
                  <span className="nav-label">Bases</span>
                </Link>
              </li>
            ) : null}

            {hasAnaliseDeRedeAccess() ||
            hasCreateLeadAccess() ||
            hasActivationAccess() ||
            hasAnaliseDeRedeBaseAccess() ? (
              <li>
                <Link aria-expanded="false" style={{display: 'flex', alignItems: 'center'}}>
                  <i style={{marginRight: '5px'}} className="fa fa-globe" />
                  <span className="nav-label" style={{width: '100%'}}>
                    Rede
                  </span>
                  <i style={{marginRight: '5px'}} className="fas fa-chevron-down" />
                </Link>
                <ul
                  className="nav nav-second-level collapse"
                  aria-expanded="false"
                  style={{height: '0px'}}>
                  {hasActivationAccess() ? (
                    <li style={{marginLeft: '15px'}}>
                      <a href={`${process.env.REACT_APP_URL}/v2/tracking/activation`}>
                        <span className="nav-label">Acionamento</span>
                      </a>
                    </li>
                  ) : null}
                  {hasAnaliseDeRedeAccess() ? (
                    <li style={{marginLeft: '15px'}}>
                      <a href={`${process.env.REACT_APP_URL}/v2/tracking/network`}>
                        <span className="nav-label">Análise de Rede</span>
                      </a>
                    </li>
                  ) : null}
                  {hasAnaliseDeRedeBaseAccess() ? (
                    <li style={{marginLeft: '15px'}}>
                      <a href={`${process.env.REACT_APP_URL}/v2/tracking/base-network`}>
                        <span className="nav-label">Análise de Rede</span>
                      </a>
                    </li>
                  ) : null}
                  {hasCreateLeadAccess() ? (
                    <li style={{marginLeft: '15px'}}>
                      <a href={`${process.env.REACT_APP_URL}/bases/createLead`}>
                        <span className="nav-label">Cotação Base</span>
                      </a>
                    </li>
                  ) : null}
                  {hasSsPendingAccess() ? (
                    <li style={{marginLeft: '15px'}}>
                      <a href={`${process.env.REACT_APP_URL}/v2/tracking/ss`}>
                        <span className="nav-label">SS Pendente</span>
                      </a>
                    </li>
                  ) : null}
                </ul>
              </li>
            ) : null}

            {hasCustomerAccess() ? (
              <li className={this.activeRoute('/customers')}>
                <a href={`${process.env.REACT_APP_URL}/v2/customers`}>
                  <i style={{marginRight: '5px'}} className="fas fa-user-alt" />
                  <span className="nav-label">Clientes</span>
                </a>
              </li>
            ) : null}

            {hasFechamentoAndPendenciasAccess() || hasDocumentsAccess() ? (
              <li>
                <Link
                  to="/balance"
                  aria-expanded="false"
                  style={{display: 'flex', alignItems: 'center'}}>
                  <i style={{marginRight: '5px'}} className="fas fa-hand-holding-usd" />
                  <span className="nav-label" style={{width: '100%'}}>
                    Gestão Soon
                  </span>
                  <i style={{marginRight: '5px'}} className="fas fa-chevron-down" />
                </Link>
                <ul
                  className={this.secondLevelActive(['/balance', '/pendencies'])}
                  aria-expanded="false"
                  style={{height: '0px'}}>
                  {hasFechamentoAndPendenciasAccess() ? (
                    <li style={{marginLeft: '15px'}} className={this.activeRoute('/balance')}>
                      <Link to="/balance">
                        <span className="nav-label">Fechamento</span>
                      </Link>
                    </li>
                  ) : null}
                  {hasFechamentoAndPendenciasAccess() ? (
                    <li
                      style={{marginLeft: '15px'}}
                      className={this.activeRoute('/pendencies')}>
                      <Link to="/pendencies">
                        <span className="nav-label">Pendências</span>
                      </Link>
                    </li>
                  ) : null}
                  {hasDocumentsAccess() ? (
                    <li
                      style={{marginLeft: '15px'}}
                      className={this.activeRoute('/v2/documents')}>
                      <a href={`${process.env.REACT_APP_URL}/v2/documents`}>
                        <span className="nav-label">Documentos</span>
                      </a>
                    </li>
                  ) : null}
                </ul>
              </li>
            ) : null}

            {hasInvoicesAccess() ? (
              <li className={this.activeRoute('/invoices')}>
                <Link to="/invoices">
                  <i style={{marginRight: '5px'}} className="fas fa-file-invoice-dollar" />
                  <span className="nav-label">Invoices</span>
                </Link>
              </li>
            ) : null}

            {
              <li>
                <Link
                  to="/solicitacoes/relatorios"
                  aria-expanded="false"
                  style={{display: 'flex', alignItems: 'center'}}>
                  <i style={{marginRight: '5px'}} className="fa fa-chart-line" />
                  <span className="nav-label" style={{width: '100%'}}>
                    Relatórios
                  </span>
                  <i style={{marginRight: '5px'}} className="fas fa-chevron-down" />
                </Link>
                <ul
                  className="nav nav-second-level collapse"
                  aria-expanded="false"
                  style={{height: '0px'}}>
                  {hasAssistanceAccess() ? (
                    <li
                      style={{marginLeft: '15px'}}
                      className={this.activeRoute('/assistance/relatorios')}>
                      <Link to="/assistance/relatorios">
                        <span className="nav-label">Atendimentos</span>
                      </Link>
                    </li>
                  ) : null}
                  <li
                    style={{marginLeft: '15px'}}
                    className={this.activeRoute('/solicitacoes/relatorios')}>
                    <Link to="/solicitacoes/relatorios">
                      <span className="nav-label">Solicitações</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {hasLocalsAccess() ? (
              <li className={this.activeRoute('/addresses')}>
                <a href={`${process.env.REACT_APP_URL}/v2/locations`}>
                  <i style={{marginRight: '5px'}} className="fa fa-map-marker-alt" />
                  <span className="nav-label">Locais</span>
                </a>
              </li>
            ) : null}

            {hasChatAccess() ? (
              <li>
                <a href={`${process.env.REACT_APP_URL}/chat`} target="_blank" rel="noreferrer">
                  <i className="fas fa-comment-dots" style={{marginRight: '5px'}} />{' '}
                  <span className="nav-label">Chat</span>
                </a>
              </li>
            ) : null}

            {hasReboqueMeProfile() ? (
              <li>
                <Link to={`${process.env.REACT_APP_URL}/operational-form`}>
                  <i className="fas fa-file-invoice" style={{marginRight: '5px'}} />{' '}
                  <span className="nav-label">Ajustes Atendimentos</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.login.loggedUser,
    company: state.login.loggedUserCompany,
    pendingSolicitations: state.followup.pendingSolicitations.length,
    login: state.login,
    userPhoto: state.login.userPhoto,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...ownProps,
    onPasswordClick: (event) => {
      event.preventDefault();
      dispatch(showPasswordChangeBox());
    },
    onLogout: (event) => {
      event.preventDefault();
      dispatch(doLogout());
    },
  };
};

const Navigation = connect(mapStateToProps, mapDispatchToProps)(NavigationView);

export default Navigation;
