import React, {Component} from 'react';
import {BootstrapTable} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './balance.css';

// import DateFilter from '../common/DataFilter'
import SearchField from '../common/SearchField';

class ReactTableCommon extends Component {
  constructor(props) {
    super(props);
    this.createCustomToolBar = this.createCustomToolBar.bind(this);
    this.renderCustomSearchPanel = this.renderCustomSearchPanel.bind(this);

    //this.handleRowSelect = this.handleRowSelect.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.setTableLoader = this.setTableLoader.bind(this);
    this.state = {
      data: [],
      selectedRows: [],
      isFetching: false,
      filteredData: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
      isFetching: nextProps.isFetching,
      filteredData: nextProps.data,
    });
  }
  componentDidMount() {}
  componentWillMount() {
    this.setState({
      data: this.props.data,
      isFetching: this.props.isFetching,
      filteredData: this.props.data,
    });
  }
  createCustomToolBar = (props) => {
    return (
      <div className="custom-toolbar">
        <div>{props.components.btnGroup}</div>
        <div className="toolbar-searchPanel">{props.components.searchPanel}</div>
      </div>
    );
  };

  createCustomClearButton = (onClick) => {
    return (
      <button className="btn btn-sm btn-primary" onClick={onClick}>
        Limpar
      </button>
    );
  };

  renderCustomSearchPanel = (props) => {
    /**
     *  This function only pass one argument, is props object has following properties
     *  {
     *    searchField,  // the search input field JSX
     *    clearBtn,  // clear search button JSX
     *    defaultValue,  // the default value for searching
     *    placeholder,  // placeholder for search input field
     *    clearBtnClick,  // the callback for triggering clear button click event
     *    search  // the callback function for triggering search event, call it with a string text for searching
     *  }
     ***/
    return props.searchField;
  };

  // handleRowSelect(row, isSelected, e) {
  //     const { selectedRows } = this.state

  //     if (isSelected === true) {
  //         selectedRows.push(row)
  //         this.setState({
  //             selectedRows,
  //         })
  //     } else {
  //         const allRows = () => selectedRows.filter((stateRow) => {
  //             return stateRow.codigo !== row.codigo
  //         })
  //         this.setState({
  //             selectedRows: allRows()
  //         })
  //     }
  // }

  handleSelectAll(isSelected, rows) {
    const {selectedRows} = this.state;

    if (isSelected === true) {
      selectedRows.push(...rows);
      this.setState({
        selectedRows,
      });
    } else {
      this.setState({
        selectedRows: [],
      });
    }
  }

  setTableLoader() {
    if (this.state.isFetching === false) {
      return `Nenhum registro encontrado`;
    } else {
      return (
        <div className="spiner-example">
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1"></div>
            <div className="sk-double-bounce2"></div>
          </div>
        </div>
      );
    }
  }

  createCustomModalHeader(onClose, onSave) {
    const headerStyle = {
      fontWeight: 'bold',
      fontSize: 'large',
      textAlign: 'center',
      backgroundColor: '#eeeeee',
    };
    return (
      <div className="modal-header" style={headerStyle}>
        <h3>That is my custom header</h3>
        <button className="btn btn-info" onClick={onClose}>
          Close it!
        </button>
      </div>
    );
  }

  createCustomModalHeader(onClose, onSave) {
    const headerStyle = {
      fontWeight: 'bold',
      fontSize: 'large',
      textAlign: 'center',
      backgroundColor: '#eeeeee',
    };
    return (
      <div className="modal-header" style={headerStyle}>
        <h3>That is my custom header</h3>
        <button className="btn btn-info" onClick={onClose}>
          Close it!
        </button>
      </div>
    );
  }
  render() {
    const {filteredData} = this.state;
    const {clearSearch, customBtn, hideSearch} = this.props;
    const options = {
      insertModalHeader: this.createCustomModalHeader,
      clickToSelect: false,
      clearSearch: true,
      clearBtn: () => this.createCustomClearButton(clearSearch),
      toolBar: this.createCustomToolBar,
      searchPosition: `left`,
      btnGroup: customBtn,
      searchField: (props) =>
        hideSearch ? (
          <span />
        ) : (
          <SearchField
            {...props}
            placeholder="Filtrar registros..."
            search={this.props.onSearchChange}
            clearSearch={this.props.clearSearch}
          />
        ),
      searchPanel: this.renderCustomSearchPanel,
      noDataText: this.setTableLoader(), //'Nenhum registro encontrado.'
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
      ],
      sizePerPage: 10, // which size per page you want to locate as default
    };

    // const selectRow = {
    //     mode: 'checkbox',
    //     bgColor: `rgba(0, 187, 255, 0.3)`,
    //     onSelect: this.handleRowSelect,
    //     onSelectAll: this.handleSelectAll
    // }

    return (
      <div>
        <BootstrapTable
          ref={(tableRefs) => (this.refs = tableRefs)}
          data={filteredData}
          // selectRow={selectRow}
          onPageChange
          search
          multiColumnSearch
          options={options}
          pagination
          striped
          hover
          bordered={false}>
          {this.props.children}
        </BootstrapTable>
      </div>
    );
  }
}

export default ReactTableCommon;
